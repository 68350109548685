import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserStatusCountInfo } from "../../../../api/employee/model/userStatusCountInfo";
import { EmployeeService } from "../../../../services/employee.service";
import { StatusCountService } from "../../../../services/status-count.service";
import { selectDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";
import CardLoader from "../common/card-loader";

//EmployeesService
const EmployeesStatusCount: React.FC<{
  setCurTbl: (tbl: TStatusCountInfo) => void;
  curTbl: TStatusCountInfo | null;
  reload?: boolean;
  setReload?: () => void;
  newlyAdded?: boolean;
}> = ({
  setCurTbl,
  curTbl,
  reload = false,
  newlyAdded = false,
  setReload = () => {},
}) => {
  const { page, card } = useSelector(selectDashboardRedirect);
  const [counts, setCounts] = useState<Array<TStatusCountInfo>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const run = async () => {
      if (reload) {
        setIsLoading(true);
        const empStatusCount = await EmployeeService.employeeCounts();
        setIsLoading(false);
        if (isSuccess(empStatusCount)) {
          if (empStatusCount.value.successful) {
            if ((empStatusCount.value.data || []).length > 0) {
              const allCounts = StatusCountService.addAllEmployeeCount(
                empStatusCount.value.data || []
              );
              setCounts(allCounts);
              if (card == "") setCurTbl(allCounts?.[0]);
              else {
                let cur = allCounts.filter((i) => i.status === card)?.[0];
                if (cur) setCurTbl(cur);
              }
            }
          }
        } else {
          setErr(true);
          console.log(err);
        }
        setReload();
      }
      // else if (isFailure(empStatusCount)) {
      //   // alert(empStatusCount?.error?.outcome)
      //   // console.log("detaxCounts: ", empStatusCount?.error?.outcome);
      // }
    };
    run();
  }, [reload]);

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        <>
          {isLoading && !newlyAdded ? (
            <CardLoader />
          ) : (
            <div className="card-counts my-4">
              {counts.map((item, key) => (
                <div
                  key={key}
                  onClick={() => setCurTbl(item)}
                  className="card-count"
                >
                  <WhiteCard
                    className={classNames("p-2 w-75 status-dot-card", {
                      color4: item.status == "All",
                      color6: item.status == "INACTIVE",
                      color7: item.status == "ACTIVE",
                      color8: item.status == "BLOCKED",
                      active:
                        card && card != ""
                          ? curTbl?.status == card && card == item.status
                          : +(curTbl?.status == item.status),
                    })}
                  >
                    <div className="status-dot"></div>
                    <h2 className="text-black text-center mb-0 fw-bold">
                      {item.count}
                    </h2>
                    <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                      {item.label}
                    </p>
                  </WhiteCard>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EmployeesStatusCount;
