import { getOrElse, Option } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { fromPublic } from "../../utils/common.utils";

const User = fromPublic("/images/user.svg");
const phone = fromPublic("/images/phone.svg");
const EmailIcon = fromPublic("/images/icons/email-solid.svg");

const UserCard: React.FC<{
  name: Option<string>;
  mobile: Option<string>;
  email: Option<string>;
}> = ({ name, mobile, email }) => {
  return (
    <div className="row card border-1 border-light rounded-15 mx-0 mb-2 ">
      <div className="col-12 col-md-12 d-flex mt-1">
        <div className="col-2">
          <img src={User} className="img-fluid  w-20" alt="icon" />
        </div>
        <div className="col-10">
          <h6 className="mt-1 neg-margin fs-small">
            {pipe(
              name,
              getOrElse(() => "")
            )}
          </h6>
        </div>
      </div>
      <div className="col-12 d-flex">
        <div className="col-5 d-flex">
          <div className="col-2">
            <img src={phone} className="img-fluid  w-40" alt="icon" />
          </div>
          <div className="col-3  review-col-4">
            <h6 className="fs-xsmall mt-2">
              +91{" "}
              {pipe(
                mobile,
                getOrElse(() => "")
              )}
            </h6>
          </div>
        </div>
        <div className="col-7 d-flex ">
          <div className="col-2">
            <img src={EmailIcon} className="img-fluid  w-40" alt="icon" />
          </div>
          <div className="col-5  review-col-4">
            <h6 className="fs-xsmall mt-6 text-truncate" title={pipe(email, getOrElse(() => ""))}>
              {pipe(
                email,
                getOrElse(() => "")
              )}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
