import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable } from "fp-ts/lib/Option";
import { NcaishFxApiService } from "../api/fx/ncaishFxApi.service";
import { CurrencyGraphResponse } from "../model/fx/currencyGraphResponse";
import { FetchCurrencyGraphRequest } from "../model/fx/fetchCurrencyGraphRequest";
import { FxCalculatorData } from "../model/fx/fxCalculatorData";
import { FxConversionResponse } from "../model/fx/fxConversionResponse";
import { FxDashboardResponse } from "../model/fx/fxDashboardResponse";
import { FxSavingResponse } from "../model/fx/fxSavingResponse";
import { RealTimePriceResponse } from "../model/fx/realTimePriceResponse";
import { SupportedSavingCurrency } from "../model/fx/supportedSavingCurrency";
import { TFxCalculatorData } from "../types/fx/fx-calculator-data.type";
import { TFxConversionRequest } from "../types/fx/fx-conversion-request.type";
import { TFxConversionResponse } from "../types/fx/fx-conversion-response.type";
import { TFxDashboardResponse } from "../types/fx/fx-dashboard-response.type";
import { TRealTimePriceResponse } from "../types/fx/fx-realtime-price-response.type";
import { TFxSavingRequest } from "../types/fx/fx-saving-request.type";
import { TFxSavingResponse } from "../types/fx/fx-saving-response.type";

export namespace FxService {
  /**
   * function to fetch fx-online dashboard data. this should be call on component load.
   */
  export const getDashboardData = async () => {
    const result = await NcaishFxApiService.dashboard();
    return remoteData.map(result, parseDashboardData);
  };
  // currency conversion
  export const convertCurrency = async (request: TFxConversionRequest) => {
    const result = await NcaishFxApiService.convert(request);
    return remoteData.map(
      result,
      (res: FxConversionResponse): TFxConversionResponse => ({
        successful: fromNullable(res.successful),
        message: fromNullable(res.message),
        amount: fromNullable(res.amount),
      })
    );
  };

  export const calculateSaving = async (request: TFxSavingRequest) => {
    const result = await NcaishFxApiService.calculateSaving(request);
    return remoteData.map(
      result,
      (res: FxSavingResponse): TFxSavingResponse => ({
        successful: fromNullable(res.successful),
        message: fromNullable(res.message),
        amount: fromNullable(res.amount),
        dayRange: fromNullable(res.dayRange),
      })
    );
  };

  export const fetchGraphData = async (
    interval: FetchCurrencyGraphRequest.IntervalEnum,
    code: FetchCurrencyGraphRequest.CodeEnum
  ) => {
    const result = await NcaishFxApiService.fetchGraph({ interval, code });
    return remoteData.map(result, (res: CurrencyGraphResponse) => ({
      successful: res.successful ? res.successful : false,
      message: res.message ? res.message : "",
      data: res.data ? res.data : [],
      min: res.min ? res.min : 0,
      max: res.max ? res.max : 0,
    }));
  };

  export const realTimePrice = async () => {
    const result = await NcaishFxApiService.realTimePrice();
    return remoteData.map(
      result,
      (res: RealTimePriceResponse): TRealTimePriceResponse => ({
        successful: res.successful || false,
        message: res.message || "",
        prices: res.prices || {},
      })
    );
  };

  const parseDashboardData = (
    res: FxDashboardResponse
  ): TFxDashboardResponse => ({
    successful: res.successful ? res.successful : false,
    message: res.message ? res.message : "",
    savingCalData: parseSavingCalData(res.savingCalData),
    prices: res.prices ? res.prices : {},
    // ? res.prices.map((p) => ({
    //     code: p.code || "USD_INR",
    //     title: p.title || "",
    //     price: p.price || 0,
    //     high: p.high || 0,
    //     low: p.low || 0,
    //     changeValue: p.changeValue || 0,
    //     changePer: p.changePer || 0,
    //     scale: p.scale || 0,
    //     time: p.time || "",
    //     history: p.history || {},
    //   }))
    // : [],
    currencies: res.currencies
      ? res.currencies.map((c) => ({
          code: c.code || "INR",
          title: c.title || "",
        }))
      : [],
  });

  const parseSavingCalData = (res?: FxCalculatorData): TFxCalculatorData => {
    return res
      ? {
          lsDate: res.lsDate ? res.lsDate : "",
          dayRange: res.dayRange ? res.dayRange : "",
          rateCurrency: res.rateCurrency ? res.rateCurrency : {},
          sdate: res.sdate ? res.sdate : "",
        }
      : {
          lsDate: "",
          dayRange: "",
          rateCurrency: {},
          sdate: "",
        };
  };

  //    const parseRateCurrency = (res?: { [key: string]: SupportedSavingCurrency; }) => {
  //        return res ? {res.key} : {};

  //    }
}
