// export function debounce(func, timeout = 300){
//     let timer;
//     return (...args) => {
//       clearTimeout(timer);
//       timer = setTimeout(() => { func.apply(this, args); }, timeout);
//     };
//   }

import { option } from "fp-ts";
import { DetaxBatchInfo } from "../api/detax/model/detaxBatchInfo";
import { MakeOption } from "../models/make-option";
import { BaseResponse } from "../model/baseResponse";

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: NodeJS.Timeout;

  const debounced = (...args: Parameters<F>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const fromPublic = (path: string): string => {
  return `${path}`;
};

export const pathName = (screen: string) => {
  return `/${screen.toLowerCase()}`;
};

export const validateName = (name: string): boolean => {
  return /^[a-zA-Z\s\.]{1,255}$/gi.test(name);
};
export const validateEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi.test(
    email
  );
};
export const validateMobile = (mobile: string | number): boolean => {
  if (!mobile) return false;

  return /^[0-9]{10}$/gi.test(mobile.toString());
};
export const validatePRAN = (pran: string | number): boolean => {
  if (!pran) return false;

  return /^[0-9]{12}$/gi.test(pran.toString());
};

export const validateAmount = (amount: string): boolean => {
  if (!amount) return false;

  return /^[0-9]*$/gi.test(amount);
};

export const validatePincode = (pincode: string | number): boolean => {
  if (!pincode) return false;

  return /^[0-9]{6}$/gi.test(pincode.toString());
};
export const validateGender = (pincode: string | number): boolean => {
  return /^(MALE|FEMALE|TRANSGENDER)$/gi.test(pincode.toString());
};
export const validateMembershipType = (membershipType: string): boolean => {
  if (
    membershipType.localeCompare("Detax") == 0 ||
    membershipType.localeCompare("Detax+") == 0
  )
    return true;
  else return false;
};

export const adminTableHeight = (pagination = false) => {
  let height = 0;
  if (document) {
    let body = document.querySelector("body");
    let el1 = document.querySelector("#sub-header");
    let el2 = document.querySelector(".page-heading-container");
    let el3 = document.querySelector("#custom-search");
    let el4 = document.querySelector(".card-counts");
    height =
      (body?.clientHeight || 0) -
      ((el1?.clientHeight || 0) +
        (el2?.clientHeight || 0) +
        (el3?.clientHeight || 0) +
        (el4?.clientHeight || 0));
  }
  // let extra = 16+8
  let minusHeight = pagination ? 170 : 150;
  let res = height - minusHeight;
  if (height - minusHeight > 0) return res + "px";
  else return "auto";
};

export const downloadImage = (file: any) => {
  console.log(file);
  fetch(file, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = file.replace(/\?.*/gi, "").split("/");
        fileName = fileName[fileName.length - 1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export function titleCase(inputStr: string) {
  let str = inputStr.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function getTitleCase(inputStr: string) {
  return titleCase(inputStr).replaceAll("_", " ");
}

export function getTitleCaseToStatus(inputStr: string) {
  return inputStr.trim().toUpperCase().trim().replaceAll(" ", "_");
}

export const getName = (name: string) => {
  let fname = "";
  let lname = "";
  let arr = name.split(" ");
  fname = arr[0].trim();
  let tmpLast = arr.filter((i, k) => k != 0);
  lname = tmpLast.join(" ").trim();
  return [fname, lname];
};

export const convertJSDate = (date: string) => {
  // input date formate would be dd-MM-yyyy
  if (date) {
    const dt = date.substring(0, 10);
    const dtArr = dt.split("-");
    return new Date(+dtArr[2], +dtArr[1] - 1, +dtArr[0]);
  } else {
    return new Date();
  }
};
/* export const debounce = (cb: any, delay = 250) => {
  let timeout: any

  return (...args: any) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      cb(...args)
    }, delay)
  }
} */

export const getDisplayValue = (item: MakeOption<Required<DetaxBatchInfo>>) => {
  const status = option.isSome(item.status) ? String(item.status.value) : "";
  console.log("Batch: ", status);
  switch (status) {
    case DetaxBatchInfo.StatusEnum.CREATED:
      return item.createdAt;
    case DetaxBatchInfo.StatusEnum.SUBMITTED:
      return item.initiatedAt;
    case DetaxBatchInfo.StatusEnum.PAYMENTCONFIRMED:
      return item.initiatedAt;
    case DetaxBatchInfo.StatusEnum.EXECUTING:
      return item.initiatedAt;
    case DetaxBatchInfo.StatusEnum.FAILED:
      return item.cancelledAt;
    case DetaxBatchInfo.StatusEnum.PARTIALLYEXECUTED:
      return item.executedAt;
    case DetaxBatchInfo.StatusEnum.EXECUTED:
      return item.executedAt;

    case DetaxBatchInfo.StatusEnum.ONHOLD:
      return item.cancelledAt;
    case DetaxBatchInfo.StatusEnum.CANCELLED:
      return item.cancelledAt;
    case DetaxBatchInfo.StatusEnum.REJECTED:
      return item.cancelledAt;
    default:
      return option.none;
  }
};

export const fixNumberInput = () => {
  if (document) {
    let els = document.querySelectorAll(".number-input");
    els.forEach((el) => {
      let val = (el as HTMLInputElement).value;
      (el as HTMLInputElement).value = val.split(/\D/).join("");
    });
  }
};

export const timerSeconds = (num: number) => {
  if (num === 0) return "";
  else {
    let str = num + "";
    if (str.length === 1) str = "0" + num;
    return "00:" + str;
  }
};

export const convertToDoc = (
  res: Blob | ArrayBuffer,
  name: string,
  type: "pdf" | "csv"
) => {
  const url = window.URL.createObjectURL(new Blob([res]));
  const link = document.createElement("a");
  link.href = url;
  const fileName = `${name}.${type}`; // whatever your file name .
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const capitalizeHyphenatedString = (str: string) => {
  // if (!str) return str;

  // Split the string into an array of words separated by hyphens
  const words = str.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  console.log("Tests", capitalizedWords.join(" "));

  // Join the words back into a string with hyphens
  return capitalizedWords.join(" ");
};
