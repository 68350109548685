/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ClaimCountInfo {
  status?: ClaimCountInfo.StatusEnum;
  label?: string;
  count?: number;
}
export namespace ClaimCountInfo {
  export type StatusEnum =
    | "PENDING"
    | "REVIEW_PENDING"
    | "APPROVED"
    | "REJECTED"
    | "IN_PROCESS"
    | "REIMBURSED"
    | "CANCELLED";
  export const StatusEnum = {
    PENDING: "PENDING" as StatusEnum,
    REVIEWPENDING: "REVIEW_PENDING" as StatusEnum,
    APPROVED: "APPROVED" as StatusEnum,
    REJECTED: "REJECTED" as StatusEnum,
    INPROCESS: "IN_PROCESS" as StatusEnum,
    REIMBURSED: "REIMBURSED" as StatusEnum,
    CANCELLED: "CANCELLED" as StatusEnum,
  };
}
