import React, { useEffect } from "react";
import { useAppSelector } from "../../../../../hooks/hooks";
import { useGetRealTimePriceQuery } from "../../../../../hooks/use-realtime-price";
import { selectSelectedPriceCurrency } from "../../../../../state/slice/fx.slice";

import PriceCard from "./price-card";

const RealTimePrice: React.FC = () => {
  const selectedCurrency = useAppSelector(selectSelectedPriceCurrency);
  const {
    isLoading,
    isSuccess,
    isError,
    data: prices,
  } = useGetRealTimePriceQuery();

  let dataKeys = [
    "USD_INR",
    "USD_JPY", //"JPY_INR",
    "GBP_USD",
    "GBP_INR",
    "EUR_USD",
    "EUR_INR",
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Something went wrong. Please re-login</div>;
  }

  return (
    <>
      {prices
        ? dataKeys.map((dataKey, index) => {
            const price = prices[dataKey];

            if (price) {
              return (
                <PriceCard
                  key={dataKey}
                  price={price}
                  dataKey={dataKey}
                  selectedCurrency={selectedCurrency}
                />
              );
            }
          })
        : null}
    </>
  );
};

export default RealTimePrice;
