import { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ClaimCountInfo } from "../../../../api/detax/model/claimCountInfo";
import { ClaimInfo } from "../../../../model/claimInfo";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import DateRangeDropdown from "../common/date-range-dropdown";
import Heading from "../common/heading";
import ClaimsExport from "./claims-export";
import ClaimsStatusCount from "./claims-status-count";
import ClaimsTable from "./claims-table";

interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const DashboardClaims = () => {
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  const [data, setData] = useState<ClaimInfo[] | []>([]);
  const [searchVal, setSearchVal] = useState("");
  const [filterDate, setFilterDate] = useState<IDateRange>({});

  //{
  //  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  //  endDate: new Date(),
  // }
  return (
    <>
      <PageTitle title="Claims" />
      <Heading title="Claims" />

      {/* <WhiteCard className="mb-4 mt-2 p-1 rounded">
        <div className="d-flex justify-content-between">
          <CustomSearchBar
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="70%"
            text="Search by Claim Code/Employee Name/Mobile "
          />
          <div className="d-flex align-items-center ">
            {curTbl && <ClaimsExport curTbl={curTbl} />}

            <DateRangeDropdown
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(data: IDateRange) => setFilterDate(data)}
            />
          </div>
        </div>
      </WhiteCard> */}
      <ClaimsStatusCount
        setCurTbl={setCurTbl}
        curTbl={curTbl}
        filterDate={filterDate}
      />
      <div
        className="d-flex align-items-center justify-content-end m-1"
        // style={{ border: "1px solid black" }}
      >
        {/* {curTbl && <ClaimsExport curTbl={curTbl} />} */}

        <DateRangeDropdown
          startDate={filterDate.startDate}
          endDate={filterDate.endDate}
          onChange={(data: IDateRange) => setFilterDate(data)}
        />
      </div>
      {curTbl && (
        <ClaimsTable
          searchVal={searchVal}
          setData={(d: any) => setData(d)}
          curTbl={curTbl}
          filterDate={filterDate}
        />
      )}
    </>
  );
};
export default DashboardClaims;
