import classNames from "classnames";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AddEmployeeApiRequest } from "../../../api/employee/model/addEmployeeApiRequest";
import {
  validateEmail,
  validateMobile,
  validateName,
} from "../../../utils/common.utils";
import { IEmployeeModal } from "../../pages/dashboard/employees/employee-modal-types";

const inputDate = (dt: any) => {
  try {
    dt = dt.replace(/-/g, "/");
    return format(new Date(dt), "yyyy-MM-dd");
  } catch (error) {}
  return "";
};

interface ISalaryModal {
  show: boolean;
  data: {
    salary: number;
    basicSalary: number;
    allowance: number;
  };
}

interface Iprops /* extends React.InputHTMLAttributes<HTMLInputElement> */ {
  title?: string;
  // modal: ISalaryModal;
  closeModal: () => void;
  handleSubmit: (data: any) => React.FormEventHandler<HTMLFormElement>;
  onSubmit: (data: any) => void;
  register: any;
  getValues: any;
  setValue: any;
  errors: any;
  actionState?: boolean;
}

const EmployeeSalaryForm: React.FC<Iprops> = ({
  title,
  // modal,
  closeModal,
  handleSubmit,
  onSubmit,
  register,
  getValues,
  setValue,
  errors,
  actionState = false,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      {isLoaded ? (
        <Modal show={true} onHide={() => closeModal()} size="lg" centered>
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title className=" fs-5 fw-bold">{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="fw-bold">Salary Information</p>
                <hr />
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="salaryInfo.salary" className="text-muted">
                      Salary
                    </label>
                    <input
                      // defaultValue={modal.data.salary}
                      {...register("salaryInfo.salary", {
                        required: "Salary is required!",
                        validate: (val: string) =>
                          parseFloat(val) > 0 || "Salary is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.salaryInfo?.salary?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.salaryInfo?.salary?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label
                      htmlFor="salaryInfo.basicSalary"
                      className="text-muted"
                    >
                      Basic Salary
                    </label>
                    <input
                      // defaultValue={modal.data.basicSalary}
                      {...register("salaryInfo.basicSalary", {
                        required: "Basic salary is required!",
                        validate: (val: string) =>
                          parseFloat(val) > 0 || "Basic salary is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.salaryInfo?.basicSalary?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.salaryInfo?.basicSalary?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4">
                    <label
                      htmlFor="salaryInfo.allowance"
                      className="text-muted"
                    >
                      Flexible Allowance
                    </label>
                    <input
                      // defaultValue={modal.data.allowance}
                      {...register("salaryInfo.allowance", {
                        required: "Flexible Allowance is required!",
                        validate: (val: string) =>
                          parseFloat(val) > 0 ||
                          "Flexible allowance is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.salaryInfo?.allowance?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.salaryInfo?.allowance?.message}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className="bg-dark btn bg-opacity-10 border-0 px-3 py-2"
                >
                  Cancel
                </button>
                <button
                  disabled={actionState}
                  type="submit"
                  className="primary-bg btn text-white border-0 px-3 py-2"
                >
                  {actionState && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    ></span>
                  )}
                  Submit
                </button>
              </Modal.Footer>
            </form>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default EmployeeSalaryForm;
