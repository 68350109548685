import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { UserStatusCountInfo } from "../../../../model/userStatusCountInfo";
import { setDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { getTitleCase } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import CusomtomGraphs from "../../../graph/graph";
import WhiteCard from "../../../white-card/white-card";

const DashboardEmployee: React.FC<{
  data: UserStatusCountInfo[] | [];
}> = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let totalCount = () => {
    let total = 0;
    if (data.length > 0) {
      data.map((item) => {
        total = total + (item?.count || 0);
      });
    }
    return total;
  };

  const color = (status: UserStatusCountInfo.StatusEnum | null) => {
    let color = "#ddd";
    if (status === "INACTIVE") color = "#ffbd36";
    else if (status === "ACTIVE") color = "#23c212";
    else if (status === "BLOCKED") color = "#ff255f";
    return color;
  };

  return (
    <>
      <WhiteCard>
        <h4 className="page-sub-heading"> Employees</h4>
        {data.length > 0 ? (
          <>
            <div className="col">
              <div className="h-100 text-center pb-3 d-block">
                <CusomtomGraphs
                  color="#ECD0EF"
                  width="100%"
                  height="60"
                  value={totalCount()}
                />
                <h4 className="pt-1 mt-1 mb-0"> Total Employees</h4>
                <div className="row">
                  {data.map((item, key) => (
                    <div
                      onClick={() => {
                        dispatch(
                          setDashboardRedirect({
                            page: RoutePaths.EmployeesAdd,
                            card: item?.status,
                          })
                        );
                        navigate(RoutePaths.EmployeesAdd);
                      }}
                      key={key}
                      className="col-md-4 mt-4"
                    >
                      <div
                        style={{
                          width: "100%",
                          height: 70,
                          cursor: "pointer",
                        }}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <PieChart>
                            <Pie
                              data={[
                                {
                                  name: item.label,
                                  value: item.count,
                                  color: color(item?.status || null),
                                },
                                {
                                  name: "Other",
                                  value: totalCount() - (item.count || 0),
                                  color: "#e3e3e3",
                                },
                              ]}
                              nameKey="name"
                              dataKey="value"
                              innerRadius="60%"
                              outerRadius="80%"
                              startAngle={90}
                              endAngle={-270}
                              fill="#8884d8"
                            >
                              {[
                                {
                                  name: item.label,
                                  value: item.count || 0,
                                  color: color(item?.status || null),
                                },
                                {
                                  name: "Other",
                                  value: totalCount() - (item.count || 0),
                                  color: "#e3e3e3",
                                },
                              ].map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={entry.color}
                                />
                              ))}
                              <Label width={30} position="center">
                                {item.count || 0}
                              </Label>
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                        <div className="text-muted text-center">
                          <small>{getTitleCase(item.status || "")}</small>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </WhiteCard>
    </>
  );
};

export default DashboardEmployee;
