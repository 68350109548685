import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./settings.scss";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { NcaishDetaxSubscriptonApiService } from "../../../../api/detax/api/ncaishDetaxSubscriptonApi.service";
import {
  isSuccess,
  RemoteData,
  RemoteInitial,
} from "@devexperts/remote-data-ts";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { useStoreWithInitializer } from "../../../../state/storeHooks";
import { loadDashboardData } from "../../../../utils/dashboard.utils";
import { EmployeeService } from "../../../../services/employee.service";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";
import { NcaishClaimApiService } from "../../../../api/detax/api/ncaishClaimApi.service";
import { CreateClaimApproverApiRequest } from "../../../../api/detax/model/createClaimApproverApiRequest";
import { SetApprover } from "./SetApprover";
import { fromPublic } from "../../../../utils/common.utils";
import { Dispatch } from "../../../../state/dispatch";
import { unWrapOption } from "../../../../utils/option.utils";

interface Option2 {
  uid: string;
  name: string;
  value: string;
  label: string;
}
interface CustomOptionType {
  uid: string;
  name: string;
  value: string;
  label: string;
  mobile: string;
  email: string;
  department: string;
}
interface Option {
  value: string;
  label: string;
}
const NewApproverPopup: React.FC<{
  actionState1: boolean;
  show: boolean;
  closeModal: (data: any) => any;
  editApprover: any;
  setEditApprover: (data: any) => any;
}> = ({ show, closeModal, actionState1, editApprover, setEditApprover }) => {
  const [slide, setSlide] = useState(1);
  // const [actionState1, setActionState1] = useState(false);
  const [data, setData] = useState(0);

  const [department, setDepartment] = useState<BusinessUnit>();
  const [employees, setEmployees] = useState<DetaxCardSubscriptionInfo[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<
    DetaxCardSubscriptionInfo[]
  >([]);

  const [availableUsers, setAvailableUsers] = useState<
    DetaxCardSubscriptionInfo[]
  >([]);
  // console.log("TEST", availableUsers);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [errMsg, setErrMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const defaultData = () => {
    setSelectedUsers([]);
    // setAvailableUsers([]);
    setEditApprover(null);
    setSlide(1);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal(false);
          defaultData();
        }}
        centered
        // className="modal-xl"
        // size="xl"
      >
        <style>{`.modal-dialog { max-width: ${1200}px; }`}</style>
        <Modal.Header closeButton>
          <Modal.Title>
            {editApprover !== null ? "Edit Approver" : "Select New Approvers"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="maincon">
            <SetApprover
              defaultApproverName={""}
              defaultApproverEmail={""}
              slide={editApprover === null ? slide : 2}
              setSlide={(data: any) => setSlide(data)}
              btnView={false}
              editApprover={editApprover}
              setEditApprover={(data: any) => setEditApprover(data)}
              closeModal={(data: any) => closeModal(data)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <div className="text-danger">{errMsg !== "" ? errMsg : null}</div>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => {
              closeModal();
              defaultData();
              // setView(true);
            }}
          >
            Cancel
          </button>
          <button
            // disabled={
            //   actionState1 || selectedUsers?.length < 1 || slide === 2
            //     ? submitDisable()
            //     : false
            // }
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => {
              if (slide === 1) {
                setSlide(2);
              }
              // else {
              //   setView(false);
              // }
              if (slide === 2) {
                // onSubmit();
                // if (data !== selectedUsers?.length - 1) {
                //   onSubmit();
                //   console.log("Submit, not");
                // } else if (data === selectedUsers?.length - 1) {
                //   console.log("Submit, equal");
                // }
              }
            }}
            // onClick={() => onSubmit()}
          >
            {actionState1 && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            {/* {view === false ? "Next" : "Submit"} */}
          {/* {data === selectedUsers?.length - 1 ? "Submit" : "Next"}
          </button>  */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewApproverPopup;
