import React from "react";
import { CardTransactionInfo } from "../../../../../api/detax/model/cardTransactionInfo";
import { getTitleCase, titleCase } from "../../../../../utils/common.utils";
import Heading from "../../common/heading";
import { TExpense } from "./expense-card";
import { THead } from "./transaction-head";

const TransactionHeading: React.FC<{
  detail?: string;
  status?: CardTransactionInfo.ApprovalStatusEnum;
  setShowRejectPopup?: (show: boolean) => void;
  setShowApprovePopup?: (show: boolean) => void;
  breadcrum?: { label: string; url: string };
  expense: TExpense;
}> = ({
  detail = "",
  status = "",
  setShowRejectPopup = (show: boolean) => {},
  setShowApprovePopup = (show: boolean) => {},
  expense,
  breadcrum = undefined,
}) => {
  return (
    <div className="d-flex justify-content-between my-2" id="sub-header">
      <Heading
        title="Transaction Details"
        detail={getTitleCase(status)}
        breadcrum={breadcrum}
      />
      <div className="d-flex justify-content-center align-items-center">
        {status &&
          status === CardTransactionInfo.ApprovalStatusEnum.REVIEWPENDING &&
          !expense.surCharge && (
            <>
              <button
                onClick={(e) => setShowApprovePopup(true)}
                className="btn bg-success border-0 px-4 text-white  ms-2"
              >
                Approve
              </button>
              <button
                onClick={(e) => setShowRejectPopup(true)}
                className="btn bg-danger border-0 px-4 text-white  ms-2"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                type="button"
              >
                Reject
              </button>
            </>
          )}

        {status &&
          status === CardTransactionInfo.ApprovalStatusEnum.REVIEWPENDING &&
          expense.surCharge && (
            <p
              className="font-14 m-0"
              style={{
                fontWeight: "bold",
                textDecorationLine: "underline",
              }}
            >
              SURCHARGE TRANSACTION
            </p>
          )}
      </div>
    </div>
  );
};

export default TransactionHeading;
