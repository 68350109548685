
import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AAService } from "../../../../services/account-aggregator.service";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import "../account-aggregator-pages/aa.css";
import Heading from "../common/heading";

export const AAsubscribed = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState({
    isAnyConsentPresent: false,
    redirectionUrl: "",
  });
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const result = await AAService.initiateFiuRequest();
      if (isSuccess(result)) {
        if (result.value.successful) {
          setRedirect({
            isAnyConsentPresent: result.value.isAnyConsentPresent || false,
            redirectionUrl: result.value.redirectionUrl || "",
          });
        }
      }
      setIsLoading(false);
    };
    run();
  }, []);

  useEffect(() => {
    if (redirect.isAnyConsentPresent) navigate(redirect.redirectionUrl);
  }, [redirect]);


  return (
    <>
      <PageTitle title="Account Aggregator Subscribed" />

      <div className="shadow-sm text-center bg-white round-less margin-first pb-4 p-5">
        <img
          src={process.env.PUBLIC_URL + "/images/vector_subscribed_img.svg"}
          className="w-50 mb-3 img-fluid"
          alt=""
        />
        <div className="col-5 m-auto py-3">
          <h4 className="fb">Hello. You have not subscribed to this solution yet.
            Contact us at sales@ncash.ai to get access.
          </h4>
        </div>

      </div>
    </>
  );
};
