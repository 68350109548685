//claimCount

import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishClaimApiService } from "../api/detax/api/ncaishClaimApi.service";

import { ClaimApprovalApiRequest } from "../api/detax/model/claimApprovalApiRequest";
import { ClaimDetailApiResponse } from "../api/detax/model/claimDetailApiResponse";
import {
  ClaimResponseApprover,
  ClaimResponseEmployee,
} from "../api/detax/model/claimResponse";
import { ClaimStatusWiseCountApiResponse } from "../api/detax/model/claimStatusWiseCountApiResponse";
import { GetClaimsApiResponse } from "../api/detax/model/getClaimsApiResponse";
import { BaseResponse } from "../model/baseResponse";

export namespace ClaimsService {
  export const claimCount = async (
    from?: string,
    to?: string,
    uid?: string
  ) => {
    const result = await NcaishClaimApiService.claimCount(from, to, uid);
    return remoteData.map(
      result,
      (response: ClaimStatusWiseCountApiResponse) => response
    );
  };
  export const myClaimCounts = async (from = "", to = "") => {
    const result = await NcaishClaimApiService.claimCount(from, to); //  .myClaimCounts(from, to);
    return remoteData.map(
      result,
      (response: ClaimStatusWiseCountApiResponse) => response
    );
  };
  export const allClaim = async (
    status: string,
    from?: string,
    to?: string,
    uid?: string,
    pn?: number,
    rc?: number
  ) => {
    const statusString = status === "All" ? "" : status;
    const result = await NcaishClaimApiService.allClaim(
      statusString,
      from,
      to,
      uid,
      pn,
      rc
    );
    return remoteData.map(result, (response: GetClaimsApiResponse) => response);
  };
  export const allMyClaimsByStatus = async (
    status: string,
    from?: string,
    to?: string
  ) => {
    const result = await NcaishClaimApiService.allClaim(
      status,
      from,
      to,
      "",
      1,
      1000000
    ); //.allMyClaimsByStatus(status, from, to);
    return remoteData.map(result, (response: GetClaimsApiResponse) => response);
  };
  export const getClaimById = async (id: string) => {
    const result = await NcaishClaimApiService.getClaimById(id);
    return remoteData.map(
      result,
      (response: ClaimDetailApiResponse) => response
    );
  };

  export const updateClaim = async (
    body: ClaimApprovalApiRequest,
    claimId: string
  ) => {
    const result = await NcaishClaimApiService.approval(body, claimId);
    return remoteData.map(
      result,
      (response: ClaimDetailApiResponse) => response
    );
  };

  export const getClaimApprovers = async () => {
    const result = await NcaishClaimApiService.getClaimApprovers();
    return remoteData.map(
      result,
      (response: ClaimResponseApprover) => response
    );
  };

  export const createClaimApprover = async (body: any) => {
    const result = await NcaishClaimApiService.createClaimApprover(body);
    return remoteData.map(
      result,
      (response: ClaimResponseApprover) => response
    );
  };

  export const updateClaimApprover = async (body: any) => {
    const result = await NcaishClaimApiService.updateClaimApprover(body);
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const deleteClaimApprover = async (refId: string) => {
    const result = await NcaishClaimApiService.deleteClaimApprover(refId);
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getClaimEmployees = async () => {
    const result = await NcaishClaimApiService.getClaimEmployees();
    return remoteData.map(
      result,
      (response: ClaimResponseEmployee) => response
    );
  };
}
