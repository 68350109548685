import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EmployeeInfo } from "../../../../api/detax/model/employeeInfo";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import {
  CardDetailsTabs,
  Claims,
  FlexiAllowance,
  Transactions,
} from "../../../common/vertical-tablist/tabs/employee/tabs";
import VerticalTabs from "../../../common/vertical-tablist/tabs/employee/vertical-tablist";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import EmployeeDetails from "../common/employee-details";
import Heading from "../common/heading";

export const DashboardDetaxDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState("card");

  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const [employee, setEmployee] = useState<EmployeeInfo>();

  const empCode = employee ? employee.code || "" : "";
  const empName = employee ? employee.name || "" : "";
  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <PageTitle title={`De'tax details | ${empName}`} />
      <Heading
        title="De'tax details"
        detail={empName}
        status={""}
        links={[RoutePaths.DashboardDetax]}
      />

      {params.id && (
        <EmployeeDetails
          id={params.id}
          loadEmployee={(employee) => setEmployee(employee)}
          showAddress={false}
        />
      )}

      <div className="tab-content mt-3">
        <div className="tab-pane active">
          <div className="row">
            <div className="col-2">
              <WhiteCard className="mt-0 rounded shadow-none">
                <VerticalTabs
                  activeTab={activeTab}
                  onClick={(tab) => setActiveTab(tab)}
                />
              </WhiteCard>
            </div>
            <div className="col-10">
              {activeTab === "card" && <CardDetailsTabs uid={params?.id} />}
              {activeTab === "claims" && <Claims id={params?.id} />}
              {activeTab === "allowance" && <FlexiAllowance uid={params?.id} />}
              {activeTab === "transactions" && (
                <Transactions
                  id={params?.id}
                  callFrom="detax"
                  name={employee?.name}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
