import { Button, Modal } from "react-bootstrap";
// import "././reject-popup.scss";
const ErrorPopup: React.FC<{
  message?: string;
  show?: boolean;
  onClose: (show: boolean) => void;
}> = ({ message = "Error!", show = false, onClose }) => {
  return (
    <Modal show={show} onClose={() => onClose(false)} centered>
      <Modal.Header className="bg-danger d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <div className="text-light text-center">{message}</div>
      </Modal.Header>

      <Modal.Footer
        className="ncaish-bg-grey d-flex justify-content-center pointer"
        onClick={() => onClose(false)}
      >
        <p className="light fw-bold">OK</p>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorPopup;
