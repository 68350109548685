import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStoreWithInitializer } from "../../../../state/storeHooks";
import { loadDashboardData } from "../../../../utils/dashboard.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import HorizontalTabs from "../../../horizontal-tabs/horizontal-tabs";
import DashboardPageLoader from "../common/dashboard-page-loader";
import Heading from "../common/heading";
import DashboardClaim from "./dashboard-claim";
import DashboardDetax from "./dashboard-detax";
import DashboardEmployee from "./dashboard-employee";
import DashboardNps from "./dashboard-nps";
import DashboardTransaction from "./dashboard-transaction";
import Batch from "./Batch";
import GetStarted from "./get-started";
import WhiteCard from "../../../white-card/white-card";
import "./home.scss";

const DashboardHome: React.FC = () => {
  const {
    dashboardLoading,
    detaxInfo,
    npsInfo,
    employeeInfo,
    claimInfo,
    detaxCardTransactions,
    fba,
    ta,
    ea,
    nps,
    gc,
  } = useStoreWithInitializer(({ dashboard }) => dashboard, loadDashboardData);
  const [activeTab, setActiveTab] = useState("detaxCard");

  useEffect(() => {
    const interval = setInterval(() => loadDashboardData(), 10000);
    return () => clearInterval(interval);
  }, []);

  if (dashboardLoading) {
    return <DashboardPageLoader />;
  }

  /* added this function to check if its a new corporate with 0 
  employees to print welcome message */
  const hasExistingEmployees = () => {
    let total = 0;

    if (employeeInfo && employeeInfo.length > 0) {
      employeeInfo.map((item) => {
        total = total + (item?.count || 0);
      });
    }

    if (total === 0) return false;
    else return true;
  };

  return (
    <>
      <PageTitle title="Dashboard" />
      {hasExistingEmployees() ? null : <GetStarted />}
      {/*  {employeeInfo && employeeInfo.length > 0 ? null : <GetStarted />} */}
      <Heading title="Overview" />
      {/* <div onClick={async () => await loadDashboardData()}>
        <HorizontalTabs
          isDetaxCard
          isNPS={false}
          tab={activeTab}
          setTab={(tab) => setActiveTab(tab || "")}
        />
      </div> */}
      {/* <div className="mb-3"></div> */}
      <div className="row">
        <div className="col-lg-12 col-xl-12 col-xxl-12">
          {detaxInfo && activeTab == "detaxCard" && (
            <DashboardDetax data={detaxInfo} />
          )}
          {npsInfo && activeTab == "nps" && <DashboardNps data={npsInfo} />}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9">
          {" "}
          {detaxCardTransactions && (
            <DashboardTransaction
              monthlyTransactions={detaxCardTransactions}
              fba={fba}
              ta={ta}
              nps={nps}
              ea={ea}
              gc={gc}
            />
          )}
          {detaxCardTransactions && (
            <Batch
              monthlyTransactions={detaxCardTransactions}
              fba={fba}
              ta={ta}
              ea={ea}
              nps={nps}
              gc={gc}
            />
          )}
        </div>

        <div className="col-lg-3">
          <DashboardEmployee
            data={
              employeeInfo && employeeInfo.length > 0
                ? employeeInfo
                : [
                    { status: "ACTIVE", label: "Active", count: 0 },
                    { status: "INACTIVE", label: "Inactive", count: 0 },
                    { status: "BLOCKED", label: "Blocked", count: 0 },
                  ]
            }
          />
          <DashboardClaim
            data={
              claimInfo && claimInfo.length > 0
                ? claimInfo
                : [
                    { status: "PENDING", label: "Pending", count: 0 },
                    { status: "APPROVED", label: "Approved", count: 0 },
                    { status: "REJECTED", label: "Rejected", count: 0 },
                    { status: "REIMBURSED", label: "Reimbursed", count: 0 },
                  ]
            }
          />
        </div>
      </div>
    </>
  );
};
export default DashboardHome;
