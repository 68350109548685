import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React from "react";
import { InputGroup } from "../..";
import { TCorporateUser } from "../../../types/corporate-user.type";
import { fromPublic } from "../../../utils/common.utils";

const User = fromPublic("/images/icons/user.svg");

const DirectorForm: React.FC<{
  director: TCorporateUser;
  onRemove: (userId: string) => void;
  onEmailChange: (email: string, director: TCorporateUser) => void;
  onMobileChange: (mobile: string, director: TCorporateUser) => void;
  error: {
    email?: string;
    mobile?: string;
  };
  onBlur: (name: string, val: string, director: TCorporateUser) => void;
}> = ({ director, onRemove, onEmailChange, onMobileChange, error, onBlur }) => {
  return (
    <>
      <div className="mb-2 mt-2 row ">
        <div className="col-3 rounded-15 mx-auto">
          <img
            className=" img-fluid icons w-60 ms-3 ncash_bg-lightpink"
            src={User}
            alt="User"
          />
        </div>
        <div className="col-7 fw-bold fs-small mt-1">
          {pipe(
            director.name,
            getOrElse(() => "")
          )}
        </div>
        <div className="col-2 mt-1 ">
          <button
            type="button"
            className="border-0 bg-transparent d-flex justify-content-end cursor-pointer"
            onClick={() =>
              onRemove(
                pipe(
                  director.userId,
                  getOrElse(() => "")
                )
              )
            }
          >
            &#10006;
          </button>
        </div>
      </div>
      <div className="mb-2">
        <InputGroup
          icon="mail"
          type="text"
          name="email"
          color="bg-lightpurple"
          className="form-control fs-small fw-bold input-field p-2"
          placeholder="E-mail"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={pipe(
            director.email,
            getOrElse(() => "")
          )}
          onChange={(e) => {
            onEmailChange(e.target.value, director);
          }}
          onBlur={(e) => onBlur(e.target.name, e.target.value, director)}
        />
        {error.email != "" && (
          <div className="fs-small ncash_validate-error text-center mt-2">
            {error.email}
          </div>
        )}
      </div>
      <div className="mb-2">
        <InputGroup
          icon="phone"
          type="text"
          name="mobile"
          maxLength={10}
          color="bg-lightblue"
          className="form-control  fs-small fw-bold input-field p-2"
          placeholder="Enter your Mobile No"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={pipe(
            director.mobile,
            getOrElse(() => "")
          )}
          onChange={(e) => {
            onMobileChange(e.target.value, director);
          }}
          onBlur={(e) => onBlur(e.target.name, e.target.value, director)}
        />
        {error.mobile != "" && (
          <div className="fs-small ncash_validate-error text-center mt-2">
            {error.mobile}
          </div>
        )}
      </div>
    </>
  );
};

export default DirectorForm;
