import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { FiuConsentInfo } from "../../../../api/account-aggregator/model/fiuConsentInfo";
import { FiuTransactionEO } from "../../../../api/account-aggregator/model/fiuTransactionEO";
import { FiuTransactionInfo } from "../../../../api/account-aggregator/model/fiuTransactionInfo";
import { FiuTransactionsEO } from "../../../../api/account-aggregator/model/fiuTransactionsEO";
import { adminTableHeight } from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import StatusText from "../common/status-text";

const AAtrxTable: React.FC<{
  data?: FiuTransactionInfo[] | [];
}> = ({ data = [] }) => {
  return (
    <div className="custom-table" style={{ maxHeight: adminTableHeight() }}>
      <table onClick={() => console.log(data)}>
        <thead>
          <tr>
            <th scope="col">Transaction ID</th>
            <th scope="col">Bank Name</th>
            <th scope="col">Date</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((item, key) => (
              <tr key={key}>
                <td>{item.txnId === "" ? "-" : item.txnId} </td>
                <td>{item.mode === "" ? "-" : item.mode}</td>
                <td>{item.valueDate}</td>
                <td>
                  {item && item.amount ? currencyFormat(item.amount) : null}
                </td>
                <td className="text-center">
                  <StatusText
                    text={item.type}
                    className={classNames({
                      color6: item.type == "DEBIT",
                      color7: item.type == "CREDIT",
                    })}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AAtrxTable;
