import React from "react";
import classNames from "classnames";
import "../../../../components/horizontal-tabs/horizontal-tabs.scss";
import { Dispatch } from "../../../../state/dispatch";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../state/slice/detax.slice";

export enum TabTypeKind {
  SelectUser = "SelectUser",
  ReviewUser = "ReviewUser",
}
 
export const tabTypeData: TTabType[] = [
  { tabLabel: "Select Eligible User(s)", tabValue: TabTypeKind.SelectUser },
  { tabLabel: "Review Users", tabValue: TabTypeKind.ReviewUser },
];

export type TTabType = { tabLabel: string; tabValue: TabTypeKind };

type TabTypeProps = {
  tabs?: TTabType[];
};

const CreateBatchTabs: React.FC<TabTypeProps> = ({ tabs = tabTypeData }) => {
  const activeTab = useAppSelector(DetaxReduxStore.selectTabType);
  return (
    <div id="exTab2" className="p-0 border-bottom">
      <ul className="nav">
        {tabs.map((tab, index) => {
          return (
            <li className="nav-item cursor-pointer" key={index}>
              <a
                className={classNames("nav-link text-secondary fw-bold fs-5 ", {
                  active: tab.tabValue === activeTab,
                })}
                data-toggle="tab"
                onClick={() => {
                  DetaxDispatch.setTabType(tab.tabValue);
                }}
              >
                {tab.tabLabel}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CreateBatchTabs;
