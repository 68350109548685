import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { MembershipBatchTableInfo } from "../../../../api/detax/model/membershipbatchtableInfo";
import { DetaxService } from "../../../../services/detax.service";
import { MembershipService } from "../../../../services/membership.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import {
  adminTableHeight,
  getTitleCase,
  getTitleCaseToStatus,
} from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";

const MembershipBatchTable: React.FC<{
  searchVal?: string;
  curTbl?: MembershipBatchTableInfo | null;
}> = ({ searchVal = "", curTbl }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);

  const [items, setItems] = useState<Option<MembershipBatchTableInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<MembershipBatchTableInfo[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    let cardStatus =
      page === RoutePaths.DashboardMemberships ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {
      setErr(false);
      setItems(none);
      const tblResult = await MembershipService.getMembershipBatchTable(
        cardStatus
      );
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setPaging((p) => ({
            ...p,
            total: (tblResult.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setItems(some(tblResult.value.data || []));
          filterData(some(tblResult.value.data || []));
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();
    dispatch(resetDashboardRedirect());
  }, [curTbl]);

  const tblData = (tblData: MembershipBatchTableInfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  useEffect(() => filterData(items), [searchVal]);
  const filterData = (allData: Option<MembershipBatchTableInfo[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );
    if (searchVal) {
      let empCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as employee code
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let mobRegEx = new RegExp(searchVal + ".*", "gi");
      let filteredData = data.filter(
        (item) =>
          nameRegEx.test(item?.createdBy || "") ||
          // mobRegEx.test(item?.mobile || "") ||
          ""
        // empCodeRegEx.test(item.cinNumber || "")
      );
      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };

  const getStatusDate = (item: MembershipBatchTableInfo) => {
    let result: string = "-";
    let status = getTitleCaseToStatus(item.batchStatus || "");

    switch (status) {
      case "Requested":
        result = item.createdAt || "-";
        break;
      case "Initiated":
        result = item.createdAt || "-";
        break;
      case "Executed":
        result = item.createdAt || "-";
        break;
      case "Approved":
        result = item.createdAt || "-";
        break;
      case "Canceled":
        result = item.createdAt || "-";
        break;

      default:
        break;
    }
    return result;
  };

  if (isNone(items)) {
    if (err) {
      return <NoTableData />;
    }
    return <TableLoader />;
  }

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        pipe(
          items,
          fold(
            () => [<NoTableData />],
            (items) =>
              items.length > 0
                ? [
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">Membership Code</th>
                            <th scope="col">Corporate Name</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tblData(items).map((item, key) => (
                            <tr key={key}>
                              <td className="text-center">
                                <Link
                                  className="text-secondary ncash_nav-link"
                                  to={
                                    RoutePaths.DashboardMembershipsBatchDetail +
                                    "/" +
                                    item.batchCode
                                  }
                                >
                                  {item.batchCode === "" ? "-" : item.batchCode}
                                </Link>
                              </td>
                              <td className="text-center">
                                {item.corpName === "" ? "-" : item.corpName}{" "}
                              </td>
                              <td className="text-center text-nowrap">
                                {item.createdAt === "" ? "-" : item.createdAt}
                              </td>
                              <td className="text-start text-nowrap">
                                {item?.batchAmount != undefined &&
                                item?.batchAmount >= 0
                                  ? currencyFormat(item?.batchAmount || 0)
                                  : "-"}
                              </td>

                              <td className="text-center text-nowrap">
                                <StatusText
                                  text={
                                    item.batchStatus === "Canceled"
                                      ? "Cancelled"
                                      : item.batchStatus
                                  }
                                  className={classNames({
                                    color3: item.batchStatus == "Requested",
                                    color4: item.batchStatus == "Initiated",
                                    color7: item.batchStatus == "Executed",
                                    color12: item.batchStatus == "Approved",
                                    color14: item.batchStatus == "Canceled",
                                  })}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TablePagination>,
                  ]
                : [<NoTableData />]
          )
        )
      )}
    </>
  );
};

export default MembershipBatchTable;
