import { isSuccess, isFailure } from "@devexperts/remote-data-ts";
import WhiteCard from "../../../white-card/white-card";
import { SubmitHandler, useForm } from "react-hook-form";
import { ChangePasswordApiRequest } from "./../../../../api/employee/model/changePasswordApiRequest";
import classnames from "classnames";

import SuccessPopup from "./../common/succes-popup";
import { useState } from "react";
import PageTitle from "./../../../common/page-title";
import Heading from "../common/heading";
import { AuthService } from "../../../../services/auth.service";
import { option } from "fp-ts";
import { Option } from "fp-ts/lib/Option";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

interface IChangePassword {
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
}

const DashboardChangePassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IChangePassword>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const [actionState, setActionState] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [optError, setError] = useState<Option<string>>(option.none);

  const onSubmit: SubmitHandler<ChangePasswordApiRequest> = async (data) => {
    setActionState(true);
    const result = await AuthService.changePassword({
      password: data.password,
      newPassword: data.newPassword,
    });

    if (isSuccess(result)) {
      setActionState(false);
      if (result.value.successful) {
        setShowSuccess(true);
        setError(option.none);
      } else {
        setError(option.fromNullable(result.value.message));
      }
    } else {
      // handle error state
      //setError(option.some("Something went wrong."));
      setActionState(false);
    }
  };
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordInput2, setPasswordInput2] = useState("");

  const [passwordType3, setPasswordType3] = useState("password");
  const [passwordInput3, setPasswordInput3] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePasswordCon = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };
  const togglePasswordConp = () => {
    if (passwordType3 === "password") {
      setPasswordType3("text");
      return;
    }
    setPasswordType3("password");
  };

  console.log(optError);
  return (
    <>
      <PageTitle title={`Change Password`} />
      <Heading title="Change Password" />
      <WhiteCard className="p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          {option.isSome(optError) ? (
            <div className="row text-danger">{optError.value}</div>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="form-group col-md-4 mb-2">
              <label htmlFor="password">Current Password</label>
              <div className="inputWithButton">
                <input
                  type={passwordType}
                  {...register("password", {
                    required: "Current password is required!",
                  })}
                  className={classnames("form-control", {
                    "is-invalid": errors?.password?.message,
                  })}
                />
                <button
                  className="eye-button"
                  onClick={togglePassword}
                  type="button"
                >
                  {passwordType === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
              </div>
              <div className="text-danger">{errors?.password?.message}</div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 mb-2">
              <label htmlFor="newPassword">New Password</label>
              <div className="inputWithButton">
                <input
                  type={passwordType2}
                  {...register("newPassword", {
                    required: "New password is required!",
                  })}
                  className={classnames("form-control", {
                    "is-invalid": errors?.newPassword?.message,
                  })}
                />
                <button
                  className="eye-button"
                  onClick={togglePasswordCon}
                  type="button"
                >
                  {passwordType2 === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
              </div>
              <div className="text-danger">{errors?.newPassword?.message}</div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 mb-2">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="inputWithButton">
                <input
                  type={passwordType3}
                  {...register("confirmPassword", {
                    required: "Confirm password is required!",
                    validate: (val) =>
                      val === getValues("newPassword") ||
                      "Password not matching with new password",
                  })}
                  className={classnames("form-control", {
                    "is-invalid": errors?.confirmPassword?.message,
                  })}
                />
                <button
                  className="eye-button"
                  onClick={togglePasswordConp}
                  type="button"
                >
                  {passwordType3 === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
              </div>
              <div className="text-danger">
                {errors?.confirmPassword?.message}
              </div>
            </div>
          </div>
          <button
            disabled={actionState}
            type="submit"
            className="btn-gradiant-blue px-3 py-2"
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </form>
      </WhiteCard>
      <SuccessPopup
        message={`Password has changed successfully`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
    </>
  );
};
export default DashboardChangePassword;
