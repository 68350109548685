import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../state/slice/detax.slice";

import { unWrapOption } from "../../../../utils/option.utils";
import WhiteCard from "../../../white-card/white-card";
import NoTableData from "../common/no-table-data";
import ConfirmPopup from "../common/popups/confirm-popup";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import { TabTypeKind } from "./create-batch-tab";
import {
  useCreateTransactionBatchMutation,
  useCreateTransactionBatchMutationNps,
} from "./hooks/transaction.hooks.";
import { selectedUsers } from "./txn.utils";
import { option } from "fp-ts";
import { MdDelete } from "react-icons/md";
import { currencyFormat } from "../../../../utils/format.utils";
import { RewardsService } from "../../../../services/rewards.service";
import { CreateRewardApiResponse } from "../../../../api/detax/model/rewardsApiResponse";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
const SelectedEligibleUsers: React.FC<{
  selectUser: string;
  selected: TEligibleUserinfo[];
  setSelected: (val: TEligibleUserinfo[]) => void;
}> = ({ selectUser, selected, setSelected }) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  // const eligibleUsers = useAppSelector(DetaxReduxStore.reviewEligibleUsers);
  const eligibleUsers = useAppSelector(
    DetaxReduxStore.selectEligibleUsers
  ).filter((obj) => obj.isChecked);
  //.filter((user) => user.isChecked === true);
  console.log("Selected", selected);
  const filter = useAppSelector(DetaxReduxStore.selectFilter);

  const [actionState, setActionState] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    setPaging((p) => ({ ...p, limit: selected.length }));
  }, [selected.length]);

  const {
    mutateAsync: createTransactionBatch,
    isLoading: isUploadTransactionLoading,
  } = useCreateTransactionBatchMutation();

  const {
    mutateAsync: createTransactionBatchNps,
    isLoading: isUploadTransactionLoadingNps,
  } = useCreateTransactionBatchMutationNps();
  const monthYear = selectUser.split(" ");
  console.log("monthYear", monthYear);

  const rows = (data: TEligibleUserinfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            {activeTab === DetaxTypeKind.NPS ? (
              <>
                <td>{unWrapOption(item.pranNo, () => "")}</td>
                <td>{unWrapOption(item.name, () => "")}</td>
                <td>{currencyFormat(unWrapOption(item.npsAmount, () => 0))}</td>
                <td>
                  {currencyFormat(unWrapOption(item.amountToAdd, () => 0))}
                </td>
                <td>{unWrapOption(item.cboCode, () => "")}</td>
                <td>
                  {unWrapOption(item.arrearRemark, () => "-") === ""
                    ? "-"
                    : unWrapOption(item.arrearRemark, () => "-")}
                </td>
                <td>{unWrapOption(item.month, () => "")}</td>
                <td>
                  {currencyFormat(unWrapOption(item.totalCharges, () => 0))}
                </td>{" "}
                <td>
                  {currencyFormat(
                    unWrapOption(item.totalCharges, () => 0) +
                      unWrapOption(item.amountToAdd, () => 0) +
                      unWrapOption(item.npsAmount, () => 0)
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-outline-danger ms-2 "
                    onClick={() => {
                      setSelected(
                        selected.filter(
                          (obj) =>
                            obj.isChecked == item.isChecked &&
                            obj.uid !== item.uid &&
                            obj.month !== item.month &&
                            obj.year !== item.year
                        )
                      );

                      DetaxDispatch.checkUncheckEligibleUser({
                        check: false,
                        item,
                      });
                    }}
                  >
                    <MdDelete />
                  </button>
                </td>
              </>
            ) : (
              <>
                <td>{unWrapOption(item.name, () => "")}</td>
                <td>{unWrapOption(item.userCode, () => "")}</td>
                <td>{unWrapOption(item.email, () => "")}</td>
                <td>{unWrapOption(item.mobile, () => "")}</td>
              </>
            )}

            {activeTab !== DetaxTypeKind.NPS &&
              activeTab !== DetaxTypeKind.GC && (
                <>
                  <td>{unWrapOption(item.cardNo, () => "")}</td>
                  {activeTab !== DetaxTypeKind.EA && (
                    <td>{unWrapOption(item.limit, () => 0)}</td>
                  )}
                </>
              )}
            {activeTab !== DetaxTypeKind.NPS &&
            activeTab !== DetaxTypeKind.GC ? (
              <td>{unWrapOption(item.amountToAdd, () => 0)}</td>
            ) : (
              ""
            )}
            {activeTab === DetaxTypeKind.GC && (
              <>
                <td>{unWrapOption(item.rewardName, () => "")}</td>
                <td>{unWrapOption(item.rewardAmount, () => 0)}</td>
                <td>{unWrapOption(item.rewardMessage, () => "")}</td>
              </>
            )}
          </tr>
        ))
      : [
          <tr>
            <td
              className="text-secondary"
              colSpan={activeTab !== DetaxTypeKind.NPS ? 8 : 7}
            >
              <img
                src={process.env.PUBLIC_URL + "/images/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
                height="150"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TEligibleUserinfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  const handleCreateBatch = async () => {
    setActionState(true);
    const requestPayload = {
      data: selected
        .filter((user) => user.isChecked)
        .map((user) => ({
          uid: unWrapOption(user.uid, () => ""),
          amount: unWrapOption(user.amountToAdd, () => 0),
        })),
      type: filter.type,
    };
    const requestPayloadGC = {
      data: selected
        .filter((user) => user.isChecked)
        .map((user) => ({
          uid: unWrapOption(user.uid, () => ""),
          amount: unWrapOption(user.rewardAmount, () => 0),
          giftRewardDetail: {
            rewardName: unWrapOption(user.rewardName, () => ""),
            rewardMessage: unWrapOption(user.rewardMessage, () => ""),
          },
        })),
      type: filter.type,
    };
    const result = await createTransactionBatch(
      filter.type === "GC" ? requestPayloadGC : requestPayload
    );

    if (isSuccess(result)) {
      setActionState(false);
      if (unWrapOption(result.value.successful, () => false) === true) {
        setShowSuccess(true);
        setError("");
        // clear selected review eligible users.
        DetaxDispatch.clearSelectedEligibleUsers();
        DetaxDispatch.showPage("DEFAULT");
        setShowConfirmPopup(false);
      } else {
        setShowSuccess(false);
        setError(unWrapOption(result.value.message, () => ""));
      }
    } else {
      // handle error state
      setActionState(false);
      setShowSuccess(false);
      setError("Something went wrong.");
    }
  };

  const handleCreateBatchNps = async () => {
    setActionState(true);
    const requestPayload = {
      data: selected
        .filter((user) => user.isChecked)
        .map((user) => ({
          uid: unWrapOption(user.uid, () => ""),
          pran: unWrapOption(user.pranNo, () => ""),
          employerContribution: unWrapOption(user.npsAmount, () => 0),
          subContribution: unWrapOption(user.amountToAdd, () => 0),
          totalCharges: unWrapOption(user.totalCharges, () => 0),
          contriType: unWrapOption(user.cboCode, () => ""),
          arrearRemarks: unWrapOption(user.arrearRemark, () => ""),
          month: unWrapOption(user.month, () => ""),
          year: unWrapOption(user.year, () => ""),
        })),
      type: filter.type,
    };

    const result = await createTransactionBatchNps(requestPayload);

    if (isSuccess(result)) {
      setActionState(false);
      if (unWrapOption(result.value.successful, () => false) === true) {
        setShowSuccess(true);
        setError("");
        // clear selected review eligible users.
        DetaxDispatch.clearSelectedEligibleUsers();
        DetaxDispatch.showPage("DEFAULT");
        setShowConfirmPopup(false);
      } else {
        setShowSuccess(false);
        setError(unWrapOption(result.value.message, () => ""));
      }
    } else {
      // handle error state
      setActionState(false);
      setShowSuccess(false);
      setError("Something went wrong.");
    }
  };

  const isChecked = selectedUsers(selected).length;

  return (
    <>
      {error ? (
        <div className="row text-danger justify-content-center">{error}</div>
      ) : (
        <></>
      )}
      <WhiteCard className="mt-4">
        {selected && selected.length > 0 ? (
          <TablePagination
            data={paging}
            changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
            changeLimit={(data) =>
              setPaging((p) => ({ ...p, limit: data, page: 1 }))
            }
          >
            <table>
              <thead>
                <tr>
                  {activeTab === DetaxTypeKind.NPS ? (
                    <>
                      <th scope="col">PRAN Number</th>
                      <th scope="col">Subscriber Name</th>
                      <th scope="col">Employer Contribution</th>
                      <th scope="col">Subscriber's Contribution</th>
                      <th scope="col">Contribution Type</th>
                      <th scope="col">Arrear Remarks</th>
                      <th scope="col">Month</th>
                      <th scope="col">Total Charges</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Action</th>
                    </>
                  ) : (
                    <>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Employee Code</th>
                      <th scope="col">E-mail</th>
                      <th scope="col">Mobile</th>
                    </>
                  )}

                  {activeTab !== DetaxTypeKind.NPS &&
                  activeTab !== DetaxTypeKind.GC ? (
                    <>
                      <th scope="col">Card No.</th>
                      {activeTab !== DetaxTypeKind.EA && (
                        <th scope="col">Card Limit</th>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {activeTab === DetaxTypeKind.GC && (
                    <>
                      <th scope="col">Reward Name</th>
                      <th scope="col">Reward Amount </th>
                      <th scope="col">Reward Message </th>
                    </>
                  )}
                  {activeTab !== DetaxTypeKind.NPS &&
                    activeTab !== DetaxTypeKind.GC && (
                      <th scope="col">Amount To be Added</th>
                    )}

                  {/* <th scope="col">&nbsp;</th> */}
                </tr>
              </thead>
              <tbody>{selected && rows(tblData(selected))}</tbody>
            </table>
          </TablePagination>
        ) : (
          <NoTableData />
        )}
      </WhiteCard>
      {selected && selected.length > 0 && (
        <>
          <WhiteCard className="mt-4">
            <div className="row ">
              <div className="col-md-6">
                {activeTab !== "NPS" && (
                  <>
                    <h6 className="text-secondary">Total No. of Employees</h6>
                    <h4 className="text-black mb-0">{selected.length}</h4>
                  </>
                )}
              </div>
              <div className="col-md-6 text-end">
                <h6 className="text-secondary">Total Amount (INR)</h6>
                <h4 className="text-black mb-0">
                  {activeTab !== DetaxTypeKind.GC &&
                    currencyFormat(
                      selected.reduce(
                        (curr, acc) =>
                          curr +
                          unWrapOption(acc.amountToAdd, () => 0) +
                          unWrapOption(acc.npsAmount, () => 0) +
                          unWrapOption(acc.totalCharges, () => 0),
                        0
                      )
                    )}
                  {activeTab === DetaxTypeKind.GC &&
                    currencyFormat(
                      selected.reduce(
                        (curr, acc) =>
                          curr + unWrapOption(acc.rewardAmount, () => 0),
                        0
                      )
                    )}
                </h4>
              </div>
            </div>
          </WhiteCard>

          <div className="mt-4" style={{ textAlign: "right" }}>
            <button
              className="btn-ncaish-light text-white px-3 py-2"
              onClick={() => {
                // setSelected([]);
                DetaxDispatch.setTabType(TabTypeKind.SelectUser);
              }}
            >
              Back
            </button>
            <button
              style={{ marginLeft: "10px" }}
              className="btn-gradiant-blue text-white px-3 py-2"
              onClick={() => setShowConfirmPopup(true)}
              disabled={(!isChecked ? true : false) || actionState}
            >
              {actionState && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
              )}
              Create Batch
            </button>
          </div>
        </>
      )}

      <ConfirmPopup
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
        onConfirm={() => {
          {
            activeTab === DetaxTypeKind.NPS
              ? handleCreateBatchNps()
              : handleCreateBatch();
          }
        }}
      />
    </>
  );
};

export default SelectedEligibleUsers;
