import { isSuccess } from "@devexperts/remote-data-ts";
import { isSome, Option } from "fp-ts/lib/Option";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DetaxBatchInfo } from "../../../../../api/detax/model/detaxBatchInfo";
import { useAppSelector } from "../../../../../hooks/hooks";
import { BatchesService } from "../../../../../services/batches.service";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../../state/slice/detax.slice";
import { Dispatch } from "../../../../../state/dispatch";
import { RoutePaths } from "../../../../../utils/route.utils";
import "../../../dashboard/common/heading.scss";
import {
  useExecuteTransactionMutation,
  useInitiateNpsTransactionMutation,
  useInitiateTransactionMutation,
} from "../hooks/transaction.hooks.";
import { fetchBatchDetail, getModeOfPayment } from "../txn.utils";
import CancelPopup from "./cancel-popup";
import RejectPopup from "../../common/reject-popup";
import ApprovePopup from "../../common/approve-popup";
import ExecutePopup from "../../common/popups/execute-popup";
import { unWrapOption } from "../../../../../utils/option.utils";
import InitiateTrasactionPopup, {
  initiateRequest,
} from "./initiate-transaction-popup";
import { DetaxTypeKind } from "../../../../../models/detax-type-kind";
import { convertJSDate } from "../../../../../utils/common.utils";
import SuccessPopup from "../../common/succes-popup";
import { AlphaReduxStore } from "../../../../../state/slice/alpha.slice";

const TransactionDetailHeading: React.FC<{
  text: string;
  permissions: boolean;
}> = (props) => {
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const batchInfoOpt: Option<DetaxBatchInfo> = useAppSelector(
    DetaxReduxStore.selectBatchInfo
  );
  const [show, setShowPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);

  const [showReject, setShowRejectPopup] = useState(false);
  const [showApprove, setShowApprovePopup] = useState(false);
  const [showTransactionCancel, setShowTransactionCancelPopup] =
    useState(false);
  const [showTransactionExecute, setShowTransactionExecutePopup] =
    useState(false);

  const [cancelActionState, setCancelActionState] = React.useState(false);
  const [rejectActionState, setRejectActionState] = React.useState(false);
  const [approveActionState, setApproveActionState] = React.useState(false);

  const [initiateActionState, setInitiateActionState] = React.useState(false);
  const [executeActionState, setExecuteActionState] = React.useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = React.useState<{
    show: boolean;
    message: string;
  }>({ show: false, message: "" });

  const {
    mutateAsync: initiateTransactionBatch,
    isLoading: isinitiateTransactionLoading,
  } = useInitiateTransactionMutation();

  const {
    mutateAsync: initiateTransactionBatchNps,
    isLoading: isinitiateTransactionLoadingNps,
  } = useInitiateNpsTransactionMutation();

  let roleInitiate = true;

  const {
    mutateAsync: executeTransactionBatch,
    isLoading: isexecuteTransactionLoading,
  } = useExecuteTransactionMutation();

  const handleCancelBatch = async (batchId: string, remarks: string) => {
    if (batchId) {
      setCancelActionState(true);
      const result = await BatchesService.cancelBatch(batchId, remarks);

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          setShowSuccessPopup({
            show: true,
            message: "Cancel batch successfully!",
          });
          setCancelPopup(false);
          setShowRejectPopup(false);
          setShowTransactionCancelPopup(false);

          await fetchBatchDetail(batchId);
        } else {
          // Dispatch.App.setCommonError({
          //   showError: true,
          //   errorText: unWrapOption(result.value.message, () => ""),
          // });
        }
        setCancelActionState(false);
      } else {
        setCancelActionState(false);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };

  const handleNpsCancelBatch = async (batchId: string, remarks: string) => {
    if (batchId) {
      setCancelActionState(true);
      const result = await BatchesService.cancelNpsBatch(batchId, remarks);

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          setShowSuccessPopup({
            show: true,
            message: "Cancel batch successfully!",
          });
          setCancelPopup(false);
          setShowRejectPopup(false);
          setShowTransactionCancelPopup(false);

          await fetchBatchDetail(batchId);
        } else {
          // Dispatch.App.setCommonError({
          //   showError: true,
          //   errorText: unWrapOption(result.value.message, () => ""),
          // });
        }
        setCancelActionState(false);
      } else {
        setCancelActionState(false);
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };

  const handleInitiateTransaction = async (req: initiateRequest) => {
    //const dt = new Date(req.date);
    const batchId = isSome(batchInfoOpt)
      ? batchInfoOpt.value.batchId || ""
      : "";

    const requestPayload = {
      amount: parseFloat(req.amount),
      mode: getModeOfPayment(req.modeOfPayment),
      time: req.date,
      refId: req.referenceId,
      batchId,
    };
    setInitiateActionState(true);
    const result = await initiateTransactionBatch(requestPayload);

    if (isSuccess(result)) {
      setShowPopup(false);
      if (unWrapOption(result.value.successful, () => false) === true) {
        setShowSuccessPopup({
          show: true,
          message: "Initiated transaction successfully!",
        });
        if (batchId) {
          await fetchBatchDetail(batchId);
        }
      } else {
        setShowSuccessPopup({ show: false, message: "" });
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: unWrapOption(result.value.message, () => ""),
        // });
      }
      setInitiateActionState(false);
    } else {
      // Dispatch.App.setCommonError({
      //   showError: true,
      //   errorText: "Something went wrong!",
      // });
      setInitiateActionState(false);
    }
  };

  const handleInitiateNpsTransaction = async (req: initiateRequest) => {
    //const dt = new Date(req.date);
    const batchId = isSome(batchInfoOpt)
      ? batchInfoOpt.value.batchId || ""
      : "";

    const requestPayload = {
      amount: parseFloat(req.amount),
      mode: getModeOfPayment(req.modeOfPayment),
      time: req.date,
      refId: req.referenceId,
      batchId,
    };
    setInitiateActionState(true);
    const result = await initiateTransactionBatchNps(requestPayload);

    if (isSuccess(result)) {
      setShowPopup(false);
      if (unWrapOption(result.value.successful, () => false) === true) {
        setShowSuccessPopup({
          show: true,
          message: "Initiated transaction successfully!",
        });
        if (batchId) {
          await fetchBatchDetail(batchId);
        }
      } else {
        setShowSuccessPopup({ show: false, message: "" });
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: unWrapOption(result.value.message, () => ""),
        // });
      }
      setInitiateActionState(false);
    } else {
      // Dispatch.App.setCommonError({
      //   showError: true,
      //   errorText: "Something went wrong!",
      // });
      setInitiateActionState(false);
    }
  };

  const handleApproveBatch = async (batchId: string) => {
    if (batchId) {
      setApproveActionState(true);
      const result = await BatchesService.approveBatch(batchId);
      setApproveActionState(false);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          setShowSuccessPopup({
            show: true,
            message: "Approve transaction successfully!",
          });
          DetaxDispatch.setBatchInfo(result.value.data);
        } else {
          setShowSuccessPopup({ show: false, message: "" });
          // Dispatch.App.setCommonError({
          //   showError: true,
          //   errorText: unWrapOption(result.value.message, () => ""),
          // });
        }
        setShowApprovePopup(false);
      } else {
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: "Something went wrong!",
        // });
      }
    }
  };

  const handleExecuteTransaction = async () => {
    // const dt = new Date(req.date);
    const batchId = isSome(batchInfoOpt)
      ? batchInfoOpt.value.batchId || ""
      : "";

    const requestPayload = {
      // amount: parseInt(req.amount),
      // mode: getModeOfPayment(req.modeOfPayment),
      // time: dt.getTime(),
      // refId: req.referenceId,
      batchId,
    };
    setExecuteActionState(true);
    const result = await executeTransactionBatch(requestPayload);
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        setShowSuccessPopup({
          show: true,
          message: "Execute transaction successfully!",
        });
        if (batchId) {
          await fetchBatchDetail(batchId);
        }
      } else {
        // Dispatch.App.setCommonError({
        //   showError: true,
        //   errorText: unWrapOption(result.value.message, () => ""),
        // });
      }
      setExecuteActionState(false);
      setShowTransactionExecutePopup(false);
    } else {
      // Dispatch.App.setCommonError({
      //   showError: true,
      //   errorText: "Something went wrong!",
      // });
    }
  };
  console.log("Test amount", isSome(batchInfoOpt) && batchInfoOpt.value.amount);
  return (
    <>
      <div className="d-flex justify-content-between">
        <h1 className="page-heading">
          <Link to={RoutePaths.DashboardBatch}>
            <span className="title">Batch</span>
          </Link>{" "}
          {props.text}
        </h1>
        {isSome(batchInfoOpt) &&
          batchInfoOpt.value.status === "CREATED" &&
          activeTab !== DetaxTypeKind.NPS && (
            <div className="mb-3">
              <button
                className="btn-ncaish-light text-white px-3 py-2"
                onClick={() => setCancelPopup(true)}
                disabled={cancelActionState}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Cancel Batch
              </button>
              {props.permissions && (
                <button
                  style={{ marginLeft: "10px" }}
                  className="btn-gradiant-blue text-white px-3 py-2"
                  onClick={() => setShowPopup(true)}
                  disabled={initiateActionState}
                >
                  {initiateActionState && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    ></span>
                  )}
                  Initiate Transaction
                </button>
              )}
              <CancelPopup
                show={cancelPopup}
                onHide={() => setCancelPopup(false)}
                onCancel={(remarks: string) =>
                  handleCancelBatch(batchInfoOpt.value.batchId || "", remarks)
                }
              />
            </div>
          )}

        {/* When type NPS */}
        {/* {isSome(batchInfoOpt) &&
          batchInfoOpt.value.status === "PAYMENT_CONFIRMED" &&
          activeTab === DetaxTypeKind.NPS && (
            <div className="mb-3">
              <button
                className="btn-danger text-white px-3 py-2"
                onClick={() => setShowTransactionCancelPopup(true)}
                disabled={cancelActionState}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Cancel Batch
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn-success text-white px-3 py-2"
                onClick={() => setShowTransactionExecutePopup(true)}
                disabled={executeActionState}
              >
                {executeActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Execute Transaction
              </button>
            </div>
          )} */}

        {isSome(batchInfoOpt) &&
          batchInfoOpt.value.status === "CREATED" &&
          activeTab === DetaxTypeKind.NPS && (
            <div className="mb-3">
              <button
                className="btn-ncaish-light text-white px-3 py-2"
                onClick={() => setShowRejectPopup(true)}
              >
                {cancelActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Reject
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn-gradiant-blue text-white px-3 py-2"
                onClick={() => setShowPopup(true)}
              >
                {approveActionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Approve
              </button>
            </div>
          )}
      </div>
      {isSome(batchInfoOpt) && (
        <InitiateTrasactionPopup
          actionState={initiateActionState}
          amount={batchInfoOpt.value.amount}
          date={convertJSDate(batchInfoOpt.value.createdAt || "")}
          show={show}
          closeModal={() => setShowPopup(false)}
          onSubmit={
            activeTab === "NPS"
              ? handleInitiateNpsTransaction
              : handleInitiateTransaction
          }
        />
      )}

      <RejectPopup
        actionState={cancelActionState}
        show={showReject}
        onHide={() => setShowRejectPopup(false)}
        onReject={(remark) =>
          isSome(batchInfoOpt) &&
          (activeTab === "NPS"
            ? handleNpsCancelBatch(batchInfoOpt.value.batchId || "", remark)
            : handleCancelBatch(batchInfoOpt.value.batchId || "", remark))
        }
      />
      <ApprovePopup
        show={showApprove}
        onHide={() => setShowApprovePopup(false)}
        onApprove={() =>
          isSome(batchInfoOpt) &&
          handleApproveBatch(batchInfoOpt.value.batchId || "")
        }
      />

      <CancelPopup
        show={showTransactionCancel}
        onHide={() => setShowTransactionCancelPopup(false)}
        onCancel={() =>
          isSome(batchInfoOpt) &&
          (activeTab === "NPS"
            ? handleNpsCancelBatch(batchInfoOpt.value.batchId || "", "")
            : handleCancelBatch(batchInfoOpt.value.batchId || "", ""))
        }
      />
      {/* Execute transaction */}
      <ExecutePopup
        show={showTransactionExecute}
        onHide={() => setShowTransactionExecutePopup(false)}
        onApprove={() => handleExecuteTransaction()}
      />

      <SuccessPopup
        message={showSuccessPopup.message}
        show={showSuccessPopup.show}
        onClose={(flag) => setShowSuccessPopup({ show: flag, message: "" })}
      />
    </>
  );
};

export default TransactionDetailHeading;
