import React from "react";
import { Option, isNone, isSome, none, fold, some } from "fp-ts/lib/Option";
import { selectLoginUserCid } from "../app/app.slice";
import { useAppSelector } from "../../hooks/hooks";
import { CorporateService } from "../../services/corporate.service";
import { isSuccess } from "@devexperts/remote-data-ts";
import { unWrapOption } from "../../utils/option.utils";
import { currencyFormat } from "../../utils/format.utils";
import { useGetBalanceAmount } from "../../hooks/use-balance-amount";

const BalanceAmount: React.FC = () => {
  //const [balance, setBalance] = React.useState<Option<number>>(none);

  const {
    isLoading,
    isSuccess,
    isError,
    data: balance,
  } = useGetBalanceAmount();

  // React.useEffect(() => {
  //   const run = async () => {
  //     const result = await CorporateService.fetchMyBalance();
  //     if (isSuccess(result)) {
  //       if (result.value.successful) {
  //         // dispatch(setCompanyInfo(result.value.data));
  //         setBalance(some(result.value.balance || 0));
  //       } else {
  //         //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
  //       }
  //     } else {
  //       // send to retry page/ error page
  //     }
  //   };
  //   run();
  // }, []);
  if (!balance) {
    return <>loading...</>;
  }

  if (isNone(balance)) {
    return <>loading...</>;
  }
  return <div>{currencyFormat(balance.value)}</div>;
};

export default BalanceAmount;
