import classNames from "classnames";
import React from "react";
import "../horizontal-tabs/horizontal-tabs.scss";

type TProps = {
  isDetaxCard?: boolean;
  isNPS?: boolean;
  isFinancialServices?: boolean;
  setTab?: (tab?: string) => void;
  tab?: string;
};

const HorizontalTabs: React.FC<TProps> = ({
  isDetaxCard = true,
  isNPS = true,
  isFinancialServices = true,
  setTab = () => { },
  tab = ""
}) => {
  return (
    <div id="exTab2" className="p-0 border-bottom">
      <ul className="nav">
        {isDetaxCard && (
          <li className="nav-item" onClick={() => setTab('detaxCard')}>
            <div className={classNames("nav-link text-secondary fw-bold fs-6", { active: tab === 'detaxCard' })}>
              De'tax Card
            </div>
          </li>
        )}
        {isNPS && (
          <li className="nav-item px-5" onClick={() => setTab('nps')}>
            <div className={classNames("nav-link text-secondary fw-bold fs-6", { active: tab === 'nps' })} >
              NPS
            </div>
          </li>
        )}
        {/* {isFinancialServices && (
          <li className="nav-item px-5" onClick={() => setTab('')}>
            <div className={classNames("nav-link text-secondary fw-bold fs-6", { active: tab === '' })} >
              Financial Services
            </div>
          </li>
        )} */}
      </ul>
    </div>
  );
};
export default HorizontalTabs;
