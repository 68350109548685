import { fromNullable } from "fp-ts/lib/Option";
import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  endLoad,
  selectCurrentScreen,
  selectUserPermissions,
  setBadRequestError,
} from "./components/app/app.slice";
import DashboardLayout from "./components/layouts/dashboard-layout";
import {
  CompanyReviewPageNew,
  DigitalContractReviewPageNew,
  DigitalContractSuccessPageNew,
  SelectServicesPageNew,
  SignUpPageNew,
  SignUpVerificationPageNew,
  UserDetailsPageNew,
  ValidateCompanyPageNew,
} from "./components/pages";

import {
  CompanyReviewPage,
  DigitalContractPage,
  DigitalContractReviewPage,
  DigitalContractSuccessPage,
  ReviewApplicationPage,
  SelectServicesPage,
  SignUpPage,
  SignUpVerificationPage,
  UserDetailsPage,
  ValidateCompanyPage,
} from "./components/pages/cin-flow";
import {
  DashboardClaims,
  DashboardDetax,
  DashboardHome,
  DashboardNps,
} from "./components/pages/dashboard";
import DashboardAccountAggregate from "./components/pages/dashboard/account-aggregator/acc-agg";
import DashboardAAaccountSection from "./components/pages/dashboard/account-aggregator/acc-section";
import DashboardClaimsDetails from "./components/pages/dashboard/claims/claims-details";
import { DashboardDetaxDetails } from "./components/pages/dashboard/detax/detax-details";
import DetaxTransactionDetails from "./components/pages/dashboard/detax/transaction/detax-transaction-details";
import DashboardEmployees from "./components/pages/dashboard/employees/employees";
import EmployeesAdd from "./components/pages/dashboard/employees/employees-add";
import EmployeesDetail from "./components/pages/dashboard/employees/employees-detail";
import EmployeesSearch from "./components/pages/dashboard/employees/employees-search";
import { DashboardFxOnline } from "./components/pages/dashboard/fx-online/fx-online";
import { DashboardNpsDetails } from "./components/pages/dashboard/nps/nps-details";
import DashboardChangePassword from "./components/pages/dashboard/profile/ChangePassword";
import DashboardMyProfile from "./components/pages/dashboard/profile/Profile";
import TransactionDetail from "./components/pages/dashboard/upload-txn/detail/transaction-detail";
import UploadTXN from "./components/pages/dashboard/upload-txn/upload-txn";
import ForgotPasswordPage from "./components/pages/forgot-password";
import LoginUsingPasswordPage from "./components/pages/login-using-password.page";
import { LoginVerificationPage } from "./components/pages/login-verification-page";
import WelcomeBackPage from "./components/pages/welcome-back";
import { useAppSelector } from "./hooks/hooks";
import { Scope } from "./models/enum-scope";
import PageLoader from "./page-loader";
import ProtectedRoute from "./protected-route";
import { Dispatch } from "./state/dispatch";
import { setToken } from "./state/slice/token.slice";
import { store } from "./state/store";
import { useStoreWithInitializer } from "./state/storeHooks";
import { LocalStorageItemKeys, MyStorage } from "./utils/local-storage.utils";
import {
  DashboardScreens,
  GetCompletedScreen,
  RoutePaths,
} from "./utils/route.utils";
import mixpanel from "mixpanel-browser";

import MustChangePassword from "./components/pages/must-change-password";
import { AAsubscribed } from "./components/pages/dashboard/account-aggregator/aa-subscribed";
import { UserPermission } from "./model/auth/oauthLogin.model";
import { isPermissionToDetaxCard } from "./utils/auth.utils";
import LoginUsingToken from "./components/pages/login-using-token";
import DashboardTransactions from "./components/pages/dashboard/claims/transactions";
import { IdleTimeout } from "./components/idle-timeout/Idle-timeout";
import ErrorPopup from "./components/pages/dashboard/common/error-popup";
import { toast } from "react-toastify";
import ToastError from "./components/common/error-toast";
import MembershipMain from "./components/pages/dashboard/membership/membershipmain";
import { DashboardMembershipsDetails } from "./components/pages/dashboard/membership/membership-details";
import { DashboardMembershipsBatchDetails } from "./components/pages/dashboard/membership/membership-batch-table-details";
import Settings from "./components/pages/dashboard/settings/settings";
import SettingsRoles from "./components/pages/dashboard/settings/settings-roles";
import ClaimApprover from "./components/pages/dashboard/settings/settings-claim-approver";
import NpsBulkUpload from "./components/pages/dashboard/nps/nps-bulk-upload";
import BatchBulkUpload from "./components/pages/dashboard/upload-txn/batch-buk-upload";
import { ReviewApplicationPageNew } from "./components/pages/review-application-page";
import DigitalContractPageNew from "./components/pages/digital-contract.page";
//console.log('Mixpanel init');
mixpanel.init("921ffd57e8d11357cb16209f8e278bf9");

//http://localhost:3000/switch/eyJhdXRoIjoiQmFzaWMgWTI5eWNHOXlZWFJsWDJOc2FXVnVkRjlwWkRwelpXTnlaWFF0WVhCdyIsInVzZXJuYW1lIjoiYWRtaW5pc3RyYXRvckBuY2Fpc2guYWkiLCJwYXNzd29yZCI6IkZpZ2l0YWxAYWRtaW4iLCJjaWQiOiJhZmI2MWQzYS01ZTliLTQ2ZWUtOWE0YS0yMjRlOWM4YjZlZDYiLCJzd2l0Y2hUbyI6IkNPUlBPUkFURSJ9
const App: React.FC = () => {
  const notify = (err: string[]) =>
    toast.error(<ToastError errArray={err} />, {
      onClose: () =>
        Dispatch.App.setBadRequestError({ showError: false, errorText: [] }),
    });
  const location = useLocation();
  const navigate = useNavigate();

  const userPermissions = useAppSelector(selectUserPermissions);
  const { badRequestError, loading, isAuthenticated } = useStoreWithInitializer(
    ({ app }) => app,
    () => load(location, navigate, userPermissions)
  ); // load localstorage data into redux store

  useEffect(() => {
    GetCompletedScreen(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (badRequestError) {
      if (
        badRequestError.showError &&
        badRequestError.errorText &&
        badRequestError.errorText.length
      ) {
        notify(badRequestError.errorText);
      }
    }
  }, [badRequestError]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      {/* {badRequestError && <ErrorPopup message={badRequestError.errorText || "Bad Request"}  onClose={() => {store.dispatch(setBadRequestError({showError: false, errorText: ''}))}} show={badRequestError.showError} />} */}
      {/* {badRequestError && toast("Wow so easy!")} */}
      {isAuthenticated && <IdleTimeout />}
      <Routes>
        <Route path={RoutePaths.Index} element={<LoginUsingPasswordPage />} />
        <Route path={RoutePaths.SwitchAccount} element={<LoginUsingToken />} />

        {/* ////////////////////////////////////////////////////////////////////////// */}

        <Route
          path={RoutePaths.CompanyReviewNew}
          element={
            <ProtectedRoute isAuthenticated>
              <CompanyReviewPageNew />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutePaths.ValidateCompanyNew}
          element={<ValidateCompanyPageNew />}
        />

        <Route path={RoutePaths.SignUpNew} element={<SignUpPageNew />} />

        <Route
          path={RoutePaths.SignupVerificationNew}
          element={<SignUpVerificationPageNew />}
        />

        <Route
          path={RoutePaths.DigitalContractNew}
          element={
            <ProtectedRoute isAuthenticated>
              <DigitalContractPageNew />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutePaths.SelectServicesNew}
          element={
            <ProtectedRoute isAuthenticated>
              <SelectServicesPageNew />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutePaths.ReviewApplicationNew}
          element={
            <ProtectedRoute isAuthenticated>
              <ReviewApplicationPageNew />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutePaths.ApplicationSubmittedNew}
          element={
            <ProtectedRoute isAuthenticated>
              <DigitalContractReviewPageNew />
            </ProtectedRoute>
          }
        />

        {/* ////////////////////////////////////////////////////////////////////////// */}

        <Route
          path={RoutePaths.ValidateCompany}
          element={<ValidateCompanyPage />}
        />
        <Route path={RoutePaths.Login} element={<LoginUsingPasswordPage />} />
        <Route
          path={RoutePaths.ForgotPassword}
          element={<ForgotPasswordPage />}
        />
        <Route
          path={RoutePaths.LoginVerification}
          element={<LoginVerificationPage />}
        />
        <Route path={RoutePaths.SignUp} element={<SignUpPage />} />
        <Route
          path={RoutePaths.SignupVerification}
          element={<SignUpVerificationPage />}
        />
        <Route
          path={RoutePaths.UserDetails}
          element={
            isAuthenticated ? <UserDetailsPage /> : <Navigate replace to="/" />
          }
        />

        <Route
          path={RoutePaths.MustChangePassword}
          element={
            <ProtectedRoute isAuthenticated>
              <MustChangePassword />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutePaths.CompanyReview}
          element={
            <ProtectedRoute isAuthenticated>
              <CompanyReviewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.DigitalContract}
          element={
            <ProtectedRoute isAuthenticated>
              <DigitalContractPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.SelectServices}
          element={
            <ProtectedRoute isAuthenticated>
              <SelectServicesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.ReviewApplication}
          element={
            <ProtectedRoute isAuthenticated>
              <ReviewApplicationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.ApplicationSubmitted}
          element={
            <ProtectedRoute isAuthenticated>
              <DigitalContractReviewPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={RoutePaths.DigitalContractSuccess}
          element={
            <ProtectedRoute isAuthenticated>
              <DigitalContractSuccessPage />
            </ProtectedRoute>
          }
        />

        {/* // dashboard routes start */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAuthenticated>
              <DashboardLayout>
                <Outlet />
              </DashboardLayout>
            </ProtectedRoute>
          }
        >
          <Route
            path={RoutePaths.DashboardMyProfile}
            element={<DashboardMyProfile />}
          />
          <Route
            path={RoutePaths.DashboardChangePassword}
            element={<DashboardChangePassword />}
          />
          <Route path={RoutePaths.DashboardHome} element={<DashboardHome />} />
          <Route
            path={RoutePaths.DashboardFxOnline}
            element={<DashboardFxOnline />}
          />
          <Route
            path={RoutePaths.EmployeesSearch}
            element={<EmployeesSearch />}
          />
          <Route
            path={RoutePaths.DashboardEmployees}
            element={
              <ProtectedRoute isAuthenticated>
                <DashboardEmployees />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutePaths.DashboardNpsBulkUpload}
            element={
              <ProtectedRoute isAuthenticated>
                <NpsBulkUpload />
              </ProtectedRoute>
            }
          />
          <Route path={RoutePaths.EmployeesAdd} element={<EmployeesAdd />} />
          <Route
            path={RoutePaths.EmployeesDetail + "/:id"}
            element={<EmployeesDetail />}
          />
          <Route
            path={RoutePaths.DashboardClaims}
            element={<DashboardClaims />}
          />
          <Route
            path={RoutePaths.DashboardTransactions}
            element={<DashboardTransactions />}
          />
          <Route
            path={RoutePaths.DashboardClaimsDetail + "/:id"}
            element={<DashboardClaimsDetails />}
          />

          <Route path={RoutePaths.DashboardNps} element={<DashboardNps />} />
          <Route
            path={RoutePaths.DashboardNpsDetail + "/:id"}
            element={<DashboardNpsDetails />}
          />
          <Route
            path={RoutePaths.DashboardDetax}
            element={<DashboardDetax />}
          />
          <Route
            path={RoutePaths.DashboardDetaxDetail + "/:id"}
            element={<DashboardDetaxDetails />}
          />
          <Route
            path={RoutePaths.DashboardMembershipsBatchDetail + "/:id"}
            element={<DashboardMembershipsBatchDetails />}
          />
          <Route
            path={
              RoutePaths.DashboardDetaxTransactionDetail +
              "/:transactionId/:empId/:callFrom"
            }
            element={<DetaxTransactionDetails />}
          />
          <Route path={RoutePaths.DashboardBatch} element={<UploadTXN />} />
          <Route
            path={RoutePaths.DashboardBatch + "/:batchId"}
            element={<TransactionDetail />}
          />
          <Route
            path={RoutePaths.DashboardAA}
            element={<DashboardAccountAggregate />}
          />
          <Route
            path={RoutePaths.DashboardAAaccountSection}
            element={<DashboardAAaccountSection />}
          />
          <Route
            path={RoutePaths.DashboardSubscribe}
            element={<AAsubscribed />}
          />
          <Route
            path={RoutePaths.DashboardMemberships}
            element={<MembershipMain />}
          />
          <Route
            path={RoutePaths.DashboardMembershipsDetails + "/:id"}
            element={<DashboardMembershipsDetails />}
          />

          <Route path={RoutePaths.DashboardSettings} element={<Settings />} />
          <Route
            path={RoutePaths.DashboardSettingsRole}
            element={<SettingsRoles />}
          />
          <Route
            path={RoutePaths.DashboardClaimApprover}
            element={<ClaimApprover />}
          />

          <Route
            path={RoutePaths.DashboardBatchCreate}
            element={<BatchBulkUpload />}
          />

          <Route
            path="/dashboard"
            element={<Navigate replace to={RoutePaths.DashboardHome} />}
          />
        </Route>
        {/* // dashboard routes end */}
        <Route path={RoutePaths.WelcomeBack} element={<WelcomeBackPage />} />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};

const getGotoLocation = (location: any, navigate: any) => {
  // refresh token if live
  let pathname = location;
  if (
    new RegExp(RoutePaths.EmployeesDetail + ".*", "i").test(
      RoutePaths.EmployeesDetail
    )
  )
    pathname = RoutePaths.EmployeesDetail;
  else if (
    new RegExp(RoutePaths.DashboardClaimsDetail + ".*", "i").test(
      RoutePaths.DashboardClaimsDetail
    )
  )
    pathname = RoutePaths.DashboardClaimsDetail;

  const goToScreen = DashboardScreens.includes(pathname); // check if hard refresh on dashboards screens

  return location.pathname == "/"
    ? RoutePaths.DashboardHome
    : goToScreen
    ? location.pathname
    : RoutePaths.DashboardHome;
};

async function load(
  location: any,
  navigate: any,
  userPermissions: UserPermission.PermissionType[]
) {
  try {
    const currentScreenRoute =
      localStorage.getItem(LocalStorageItemKeys.currentScreenRoute) || "/";
    const draftId = localStorage.getItem(LocalStorageItemKeys.draftId);
    const loginId = localStorage.getItem(LocalStorageItemKeys.loginId);
    const authToken = MyStorage.AuthToken.LoadData();

    Dispatch.App.setDraftId(fromNullable(draftId));
    Dispatch.App.setLoginId(fromNullable(loginId));

    if (authToken) {
      const { accessToken, refreshToken, tokenExpiry, scope } = authToken;
      if (accessToken && refreshToken && tokenExpiry) {
        store.dispatch(
          setToken({ accessToken, refreshToken, tokenExpiry, scope })
        );
      }
      if (scope === Scope.CORPORATE_APPLICANT) {
        // TODO: Will enable as per log-in or sign-up flow once confirm with Praveen
        //await getNextScreenFromReview();
        navigate(RoutePaths.ApplicationSubmittedNew); // cin - flow
      } else if (scope === Scope.CORPORATE_ADMIN) {
        let goToPath = getGotoLocation(location, navigate);

        if (goToPath === RoutePaths.DashboardHome) {
          goToPath = isPermissionToDetaxCard(userPermissions)
            ? goToPath
            : RoutePaths.DashboardFxOnline;
        }
        navigate(goToPath);
      } else {
        navigate(RoutePaths.Login);
      }
    }

    store.dispatch(endLoad());
  } catch {
    store.dispatch(endLoad());
  }
}

export default App;
