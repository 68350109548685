import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { format } from "date-fns";
import { fromNullable, getOrElse } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch } from "../../hooks/hooks";
import { useValidateCin } from "../../hooks/use-validate-cin";
import { CreateCorporateOnboardingRequest } from "../../model/createCorporateOnboardingRequest";
import { OnboardingService } from "../../services/onboarding.service";
import { Dispatch } from "../../state/dispatch";
import { TCorporateUser } from "../../types/corporate-user.type";
import { TReceipientDto } from "../../types/update-receipient-request.type";
import {
  fromPublic,
  validateEmail,
  validateMobile,
  validateName,
} from "../../utils/common.utils";
import { setErrorMessages } from "../../utils/error.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import BrandLogoHeaderNew from "../brandlogo-header/brandlogo-header";
import FormikComponent from "../common/formik/formik-component/formik-component";
import FormikInputGroup from "../common/formik/formik-input-group/formik-input-group";
import Loader from "../common/loader/loader";
import AuthorizeSignatory from "../contract/authorize-signatory";
import {
  IDirectorErr,
  ISegnatoryErr,
  recipient,
} from "./digital-contract.page";

//const Image1 = fromPublic("/images/bg1-new.svg");
const Image1 = fromPublic("/images/bg1-new2.svg");
const close = fromPublic("/images/icons/Close.svg");
// while testing use this CIN: U74999MP2017PTC044578 // L65922MH1989PLC052257

interface IValues {
  cin: string;
  companyName: string;
  incDate: any;
  regNum: string;
  regAddress: string;
  rocCode: string;
  directors: Array<{ name: string; email: string; mobile: string }>;
}

const formInitialSchema: IValues = {
  companyName: "",
  cin: "",
  // incDate: new Date(new Date()).toISOString().split("T")[0],
  incDate: "",
  regNum: "",
  regAddress: "",
  rocCode: "",
  directors: [],
};
const validationSchema = yup.object().shape({
  cin: yup
    .string()
    .required("Enter Valid Company CIN")
    .matches(/^\S+$/, "Spaces are not allowed"),
  companyName: yup.string().required("Enter Company Name"),
  incDate: yup.date().required("Enter Incorporation Date").nullable(), // Nullable allows empty values
  regNum: yup.string().required("Enter Registration Number"),
  regAddress: yup.string().required("Enter Registration Address"),
  rocCode: yup.string().required("Enter ROC Code"),
  directors: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Enter Director's Name"),
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Enter Director's Email"),
      mobile: yup
        .string()
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
        .required("Enter Director's Mobile Number"),
    })
  ),
});

const ValidateCompanyPageNew: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const { mutateAsync: validateCin } = useValidateCin();
  const [selectedSignatories, setAddSignatories] = useState([recipient]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [directorErr, setDirectorErr] = useState<IDirectorErr>({
    email: [],
    mobile: [],
  });
  const [segnatoryErr, setSegnatoryErr] = useState<ISegnatoryErr>({
    name: [],
    email: [],
    mobile: [],
  });
  const [image, setImage] = useState<{ show: any; upload: any }>();
  const [imageErr, setImageErr] = useState<string>("");
  const [maxRecipientErr, setMaxRecipientErr] = useState<boolean>(false);
  const [noRecipientErr, setNoRecipientErr] = useState(false);
  const [selectedDirectors, setSelectedDirector] = useState<TCorporateUser[]>(
    []
  );

  useEffect(() => {
    mixpanel.track("Page load", { name: "Validate Company" });
  }, []);

  const handleFormSubmit1 = async (values: IValues) => {
    mixpanel.track("Button click", {
      name: "Verify CIN",
      CIN: values.cin,
    });
    const result = await validateCin(values.cin);
    //console.log(`result: `, result);
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        Dispatch.App.setDraftId(result.value.applicationId);
        const path = pipe(
          result.value.nextAction,
          getOrElse(() => "/")
        );
        navigate(path);
      } else {
        setError(unWrapOption(result.value.message, () => "Invalid CIN"));
      }
    } else if (isFailure(result)) {
      setError(setErrorMessages(result.error));
    }
  };

  const handleFormSubmit = async (values: IValues) => {
    console.log(values.incDate, new Date());
    if (isLoading) return;
    if (typeof image?.upload !== "object") {
      setImageErr("Upload Corporate Logo");
      return;
    }

    setNoRecipientErr(false);
    setError("");
    setIsLoading(true);
    const data: TReceipientDto[] = [];

    let dirErr: IDirectorErr = {
      email: [],
      mobile: [],
    };
    let sigErr: ISegnatoryErr = {
      name: [],
      email: [],
      mobile: [],
    };

    let sEmails = selectedSignatories
      .filter((i) => i.email != "")
      .map((i) => i.email);
    let dEmails = selectedDirectors
      .filter(
        (i) =>
          pipe(
            i.email,
            getOrElse(() => "")
          ) != ""
      )
      .map((i) =>
        pipe(
          i.email,
          getOrElse(() => "")
        )
      );
    let emails = [...dEmails, ...sEmails];

    let sMobiles = selectedSignatories
      .filter((i) => i.mobile != "")
      .map((i) => i.mobile);
    let dMobiles = selectedDirectors
      .filter(
        (i) =>
          pipe(
            i.mobile,
            getOrElse(() => "")
          ) != ""
      )
      .map((i) =>
        pipe(
          i.mobile,
          getOrElse(() => "")
        )
      );

    let mobiles = [...dMobiles, ...sMobiles];

    selectedDirectors.forEach((d, dk) => {
      let email = pipe(
        d.email,
        getOrElse(() => "")
      );
      let mobile = pipe(
        d.mobile,
        getOrElse(() => "")
      );

      if (email == "")
        dirErr.email.push({ index: dk, message: "Email ID is required!" });
      else if (!validateEmail(email))
        dirErr.email.push({ index: dk, message: "Invalid email ID" });
      else if (emails.filter((i) => i == email).length > 1) {
        if (!sEmails.includes(email)) {
          if (dk === dEmails.lastIndexOf(email))
            if (dirErr.email.filter((a, b) => a.index == dk).length == 0)
              dirErr.email.push({
                index: dk,
                message: "Email ID has already entered",
              });
            else
              dirErr.email = dirErr.email.map((a, b) => {
                if (a.index == dk) a.message = "Email ID has already entered";
                return a;
              });
        }
        /* dirErr.email.push({
          index: dk,
          message: "Email ID has already entered",
        }); */
      }

      if (mobile == "")
        dirErr.mobile.push({ index: dk, message: "Mobile no. is required!" });
      else if (!validateMobile(mobile))
        dirErr.mobile.push({ index: dk, message: "Invalid mobile no." });
      else if (mobiles.filter((i) => i == mobile).length > 1) {
        if (!sMobiles.includes(mobile)) {
          if (dk === dMobiles.lastIndexOf(mobile))
            if (dirErr.mobile.filter((a, b) => a.index == dk).length == 0)
              dirErr.mobile.push({
                index: dk,
                message: "Mobile no. has already entered",
              });
            else
              dirErr.mobile = dirErr.mobile.map((a, b) => {
                if (a.index == dk) a.message = "Mobile no. has already entered";
                return a;
              });
        }
        /* dirErr.mobile.push({
          index: dk,
          message: "Mobile no. has already entered",
        }); */
      }

      data.push({
        userId: pipe(
          d.userId,
          getOrElse(() => "")
        ),
        name: pipe(
          d.name,
          getOrElse(() => "")
        ),
        email,
        mobile,
        type: "DIRECTOR",
      });
    });
    setDirectorErr(dirErr);

    selectedSignatories.forEach((d, dk) => {
      if (d.name == "")
        sigErr.name.push({ index: dk, message: "Name is required!" });
      else if (!validateName(d.name))
        sigErr.name.push({ index: dk, message: "Invalid Name" });

      if (d.email == "")
        sigErr.email.push({ index: dk, message: "Email ID is required!" });
      else if (!validateEmail(d.email))
        sigErr.email.push({ index: dk, message: "Invalid email ID" });
      else if (emails.filter((i) => i == d.email).length > 1) {
        if (dk === sEmails.lastIndexOf(d.email))
          if (sigErr.email.filter((a, b) => a.index == dk).length == 0)
            sigErr.email.push({
              index: dk,
              message: "Email ID has already entered",
            });
          else
            sigErr.email = sigErr.email.map((a, b) => {
              if (a.index == dk) a.message = "Email ID has already entered";
              return a;
            });
        /* sigErr.email.push({
          index: dk,
          message: "Email ID has already entered",
        }); */
      }

      if (d.mobile == "")
        sigErr.mobile.push({ index: dk, message: "Mobile no. is required!" });
      else if (!validateMobile(d.mobile))
        sigErr.mobile.push({ index: dk, message: "Invalid mobile no." });
      else if (mobiles.filter((i) => i == d.mobile).length > 1) {
        if (dk === sMobiles.lastIndexOf(d.mobile))
          if (sigErr.mobile.filter((a, b) => a.index == dk).length == 0)
            sigErr.mobile.push({
              index: dk,
              message: "Mobile no. has already entered",
            });
          else
            sigErr.mobile = sigErr.mobile.map((a, b) => {
              if (a.index == dk) a.message = "Mobile no. has already entered";
              return a;
            });
        /* sigErr.mobile.push({
          index: dk,
          message: "Mobile no. has already entered",
        }); */
      }

      if (d.name) {
        data.push({
          userId: d.userId,
          name: d.name,
          email: d.email,
          mobile: d.mobile,
          type: "SIGNATORY",
        });
      }
    });
    setSegnatoryErr(sigErr);

    // setIsLoading(false);

    if (selectedSignatories.length + selectedDirectors.length < 1) {
      setNoRecipientErr(true);
    } else if (
      dirErr.email.length == 0 &&
      dirErr.mobile.length == 0 &&
      sigErr.name.length == 0 &&
      sigErr.email.length == 0 &&
      sigErr.mobile.length == 0
    ) {
      // console.log("Test", image.upload);
      let formData = new FormData();

      let body: CreateCorporateOnboardingRequest = {
        data: {
          cin: values.cin,
          name: values.companyName,
          incDate: format(new Date(values.incDate), "dd-MM-yyyy"),
          regNum: values.regNum,
          regAdd: values.regAddress,
          rocCode: values.rocCode,
          directors: selectedSignatories,
        },
      };
      formData.append("file", image.upload);
      formData.append("data", JSON.stringify(body));

      const result = await OnboardingService.createCorporateOnboarding(
        formData
      );
      //console.log(`result: `, result);
      if (isSuccess(result)) {
        if (result.value.successful) {
          Dispatch.App.setDraftId(
            fromNullable(result.value.data.applicationId)
          );
          // const path = pipe(
          //   fromNullable(result.value.nextAction),
          //   getOrElse(() => "/")
          // );
          // navigate(path);
          navigate(RoutePaths.SignUpNew);
        } else {
          // setError(unWrapOption(result.value.message, () => "Invalid CIN"));
        }
      } else if (isFailure(result)) {
        setError(setErrorMessages(result.error));
      }
    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const [date, setDate] = useState<string>("");

  const handleDrop = (acceptedFiles: File[]) => {
    setImageErr("");
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      // if (file.size > 10485760) {
      //   // 10 MB in bytes
      //   alert("File size exceeds the maximum limit of 10 MB.");
      //   return;
      // }

      if (!file.type.startsWith("image/")) {
        alert("Only image files (JPG, JPEG, PNG) are allowed.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          if (image.width !== 254 || image.height !== 61) {
            alert("Image dimensions must be exactly 254x61 pixels.");
            return;
          }

          setImage({ show: reader.result, upload: file });
        };
        image.src = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ncash</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <BrandLogoHeaderNew />
          {/* <Stepper /> */}
          <div className="mt-4 row d-none d-md-block">
            <div className="col-12">
              <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                <div className="row">
                  <div className="col-lg-8 col-md-6 ">
                    <MediumScreen>
                      <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <img
                          className="img-fluid"
                          width="791"
                          height="741"
                          src={Image1}
                          alt="Mobile"
                        />
                      </div>

                      <div className="mt-10 textDetail">
                        <Heading
                          className="mx-5 mb-3 mt-n85"
                          text="Onboarding First Step"
                          variant="small"
                        />

                        <p className="mx-5 fw-bolder text-secondary">
                          The journey to your employee’s financial wellness
                          begins now. Let’s get started with 7 simple steps.
                        </p>
                      </div>
                    </MediumScreen>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <MediumScreen>
                      <div className="container p-0 m-0">
                        <div className="p-3 border-0 shadow card bg-grey rounded-15">
                          {error && (
                            <div
                              className="text-center alert alert-danger fw-bold"
                              role="alert"
                            >
                              {error}
                            </div>
                          )}
                          <Heading
                            className="text-center mt-3"
                            text="Ncash welcomes you!"
                            variant="large"
                          />
                          {/* <p className="mt-4 mb-0 mb-1 text-center text-black d-none d-sm-none d-md-block fw-bold"></p> */}

                          <div className="mt-4 text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="cin"
                                type="text"
                                name="cin"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                placeholder="Enter your Company CIN"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="company"
                                type="text"
                                name="companyName"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                placeholder="Enter your Company Name"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="date"
                                type="date"
                                name="incDate"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChangeCapture={(e: any) => {
                                  console.log(
                                    "Test",
                                    e.nativeEvent.target.value
                                  );
                                  setDate(e.nativeEvent.target.value);
                                }}
                                style={{
                                  color: date.length > 0 ? "black" : "#d3d3d3",
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="reg"
                                type="text"
                                name="regNum"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                placeholder="Enter your Registration Number"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="address"
                                type="text"
                                textArea={true}
                                name="regAddress"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                placeholder="Enter your Registration Address"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="roc"
                                type="text"
                                name="rocCode"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                placeholder="Enter your ROC Code"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>

                            <div
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: 10,
                                // display: "flex",
                              }}
                              className="px-3 py-2"
                            >
                              <p
                                className="m-0"
                                style={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                Upload Company Logo
                              </p>
                              <p
                                className="m-0 mt-1"
                                style={{
                                  fontSize: 12,
                                  textAlign: "left",
                                }}
                              >
                                The size of the company logo should be strictly
                                254*61
                              </p>
                              {image && (
                                <div
                                  style={{ display: "flex" }}
                                  className="mt-1"
                                >
                                  <img
                                    src={image.show}
                                    style={{
                                      minHeight: 120,
                                      maxHeight: 120,
                                      width: "100%",
                                      border: "1px solid black",
                                    }}
                                  />
                                  <img
                                    src={close}
                                    style={{
                                      height: 10,
                                      width: 10,
                                      position: "absolute",
                                      zIndex: 99,
                                      right: 0,
                                      marginRight: 45,
                                      marginTop: 10,
                                    }}
                                    onClick={() => {
                                      setImage(undefined);
                                    }}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  minHeight: 120,
                                  maxHeight: 120,
                                  width: "98%",
                                  border: "2px dashed #d0d0db",
                                  display:
                                    typeof image === "object"
                                      ? "none"
                                      : undefined,
                                }}
                                className="mx-1 mt-3"
                              >
                                <Dropzone
                                  onDrop={handleDrop}
                                  accept=".jpg, .jpeg, .png"
                                  multiple={false}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section
                                      // className="p-1 text-center my-1 mx-auto wrap-file-upload "
                                      role="button"
                                    >
                                      <div {...getRootProps()}>
                                        <input
                                          {...getInputProps()}
                                          accept=".png"
                                        />
                                        <svg
                                          id="Group_2844"
                                          width="20"
                                          data-name="Group 2844"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 158.798 190.158"
                                        >
                                          <path
                                            id="Path_6474"
                                            data-name="Path 6474"
                                            d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                                            transform="translate(-157.192 -168.825)"
                                            fill="#cecece"
                                          />
                                          <rect
                                            id="Rectangle_365"
                                            data-name="Rectangle 365"
                                            width="158.798"
                                            height="21.5"
                                            rx="10.75"
                                            transform="translate(0 168.659)"
                                            fill="#cecece"
                                          />
                                        </svg>
                                        <div
                                          className="w-100 px-4 mx-auto py-2"
                                          style={{ fontSize: 12 }}
                                        >
                                          <p className="m-0 ">
                                            Drag and drop here{" "}
                                          </p>
                                          <p className="m-0">or</p>
                                          <p className="m-0">Browse Files</p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </div>
                            {imageErr && (
                              <div className="fs-small ncash_validate-error text-center mt-2">
                                {imageErr}
                              </div>
                            )}

                            <AuthorizeSignatory
                              devicetype="desktop"
                              signatories={selectedSignatories}
                              setAddSignatories={setAddSignatories}
                              errors={segnatoryErr}
                              setNoRecipientErr={setNoRecipientErr}
                              selectedDirectors={selectedDirectors}
                              maxRecipientErr={maxRecipientErr}
                              setMaxRecipientErr={(flag) =>
                                setMaxRecipientErr(flag)
                              }
                            />
                            {noRecipientErr && (
                              <div className="mt-2 text-center fs-small ncash_validate-error">
                                At least 1 recipient is required
                              </div>
                            )}
                            <span className="text-center fw-bold text-lightblue">
                              We need this to make your onboarding hassle free.
                            </span>
                            <div className="mt-3 ml-auto mr-auto text-center ">
                              <Button type="submit">
                                {isLoading ? (
                                  <Loader color="white" />
                                ) : (
                                  <>Continue</>
                                )}
                              </Button>

                              <p className="mt-4 mb-5 text-center fw-bolder">
                                Already have an account?
                                <Link
                                  className="ncash_nav-link"
                                  to={RoutePaths.Login}
                                >
                                  Login
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MediumScreen>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="border-0 shadow card bg-grey">
                  {error && (
                    <div
                      className="text-center alert alert-danger fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="p-3 img-fluid"
                      width="791"
                      height="741"
                      src={Image1}
                      alt="BG"
                    />
                  </div>
                  <h1 className="text-center fw-bold ">Ncash welcomes you!</h1>
                  <p className="mb-4 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small"></p>

                  <div className="p-2 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                    <FormikInputGroup
                      icon="cin"
                      type="text"
                      name="cin"
                      className="p-2 border-0 form-control fs-small fw-bolder input-field"
                      placeholder="Enter your Company CIN"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <div className="mb-3">
                      <FormikInputGroup
                        icon="company"
                        type="text"
                        name="companyName"
                        className="p-2 border-0 form-control fs-small fw-bolder input-field"
                        placeholder="Enter your Company Name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div className="mb-3">
                      <FormikInputGroup
                        icon="date"
                        type="date"
                        name="incDate"
                        className="p-2 border-0 form-control fs-small fw-bolder input-field"
                        // placeholder="Enter your Company Name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div className="mb-3">
                      <FormikInputGroup
                        icon="reg"
                        type="text"
                        name="regNum"
                        className="p-2 border-0 form-control fs-small fw-bolder input-field"
                        placeholder="Enter your Registration Number"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div className="mb-3">
                      <FormikInputGroup
                        icon="address"
                        type="text"
                        textArea={true}
                        name="regAddress"
                        className="p-2 border-0 form-control fs-small fw-bolder input-field"
                        placeholder="Enter your Registration Address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div className="mb-3">
                      <FormikInputGroup
                        icon="roc"
                        type="text"
                        name="rocCode"
                        className="p-2 border-0 form-control fs-small fw-bolder input-field"
                        placeholder="Enter your ROC Code"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 10,
                        // display: "flex",
                      }}
                      className="px-3 py-2"
                    >
                      <p
                        className="m-0"
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        Upload Company Logo
                      </p>
                      <p
                        className="m-0 mt-1"
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        The size of the company logo should be strictly 127*30
                      </p>
                      {image && (
                        <div style={{ display: "flex" }} className="mt-1">
                          <img
                            src={image.show}
                            style={{
                              minHeight: 120,
                              maxHeight: 120,
                              width: "100%",
                              border: "1px solid black",
                            }}
                          />
                          <img
                            src={close}
                            style={{
                              height: 10,
                              width: 10,
                              position: "absolute",
                              zIndex: 99,
                              right: 0,
                              marginRight: 45,
                              marginTop: 10,
                            }}
                            onClick={() => {
                              setImage(undefined);
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          minHeight: 120,
                          maxHeight: 120,
                          width: "98%",
                          border: "2px dashed #d0d0db",
                          display:
                            typeof image === "object" ? "none" : undefined,
                        }}
                        className="mx-1 mt-3"
                      >
                        <Dropzone
                          onDrop={handleDrop}
                          accept=".jpg, .jpeg, .png"
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section
                              // className="p-1 text-center my-1 mx-auto wrap-file-upload "
                              role="button"
                            >
                              <div {...getRootProps()}>
                                <input {...getInputProps()} accept=".png" />
                                <svg
                                  id="Group_2844"
                                  width="20"
                                  data-name="Group 2844"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 158.798 190.158"
                                >
                                  <path
                                    id="Path_6474"
                                    data-name="Path 6474"
                                    d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                                    transform="translate(-157.192 -168.825)"
                                    fill="#cecece"
                                  />
                                  <rect
                                    id="Rectangle_365"
                                    data-name="Rectangle 365"
                                    width="158.798"
                                    height="21.5"
                                    rx="10.75"
                                    transform="translate(0 168.659)"
                                    fill="#cecece"
                                  />
                                </svg>
                                <div
                                  className="w-100 px-4 mx-auto py-2"
                                  style={{ fontSize: 12 }}
                                >
                                  <p className="m-0 ">Drag and drop here </p>
                                  <p className="m-0">or</p>
                                  <p className="m-0">Browse Files</p>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                    {imageErr && (
                      <div className="fs-small ncash_validate-error text-center mt-2">
                        {imageErr}
                      </div>
                    )}
                    <AuthorizeSignatory
                      devicetype="desktop"
                      signatories={selectedSignatories}
                      setAddSignatories={setAddSignatories}
                      errors={segnatoryErr}
                      setNoRecipientErr={setNoRecipientErr}
                      selectedDirectors={selectedDirectors}
                      maxRecipientErr={maxRecipientErr}
                      setMaxRecipientErr={(flag) => setMaxRecipientErr(flag)}
                    />
                    {noRecipientErr && (
                      <div className="mt-2 text-center fs-small ncash_validate-error">
                        At least 2 recipient is required
                      </div>
                    )}
                    <span className="mt-2 mb-2 text-center fw-bold text-secondary fs-small">
                      We need this to make your onboarding hassle free.
                    </span>
                  </div>
                  <div className="mt-3 ml-auto mr-auto text-center">
                    <Button type="submit">
                      {isLoading ? <Loader color="white" /> : <>Continue</>}
                    </Button>
                    <p className="mt-3 mb-5 text-center fw-bolder ">
                      Already have an account?
                      <Link className="ncash_nav-link" to={RoutePaths.Login}>
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

export default ValidateCompanyPageNew;
