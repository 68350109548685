import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import PageLoader from "../../page-loader";
import { OnboardingService } from "../../services/onboarding.service";
import { Dispatch } from "../../state/dispatch";
import { TCorporateService } from "../../types/corporate-service.type";
import { fromPublic } from "../../utils/common.utils";
import { setErrorMessages } from "../../utils/error.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import { selectDraftId } from "../app/app.slice";
import Loader from "../common/loader/loader";
import ServiceCard from "../services/service-card";
import Stepper from "../stepper/stepper";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import BrandLogoHeader from "../brandlogo-header/brandlogo-header";
import BrandLogoHeaderNew from "../brandlogo-header/brandlogo-header";
import { CorporateServiceInfo } from "../../model/corporateServiceInfo";

const Image1 = fromPublic("/images/services.svg");
const cardIcon = fromPublic("/images/icons/card.svg");
const npsIcon = fromPublic("/images/icons/nps.svg");
const serviceIcon = fromPublic("/images/icons/service.svg");

const SelectServicesNew: React.FC = () => {
  const draftId = useAppSelector(selectDraftId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [services, setServices] = useState<TCorporateService[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Select Services" });

    const run = async () => {
      const result = await OnboardingService.getServices();
      mixpanel.track("Button click", {
        name: "Select Service",
        Services: result,
      });

      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          setServices(result.value.services || []);
        } else {
          setError(
            unWrapOption(result.value.message, () => "Services not found")
          );
        }
        setLoading(false);
      } else if (isFailure(result)) {
        setError(setErrorMessages(result.error));
        setLoading(false);
      }
    };
    run();
  }, []);

  const handleSubmit = async () => {
    if (selectedServices.length === 0) return;

    if (isLoading) return;

    setError("");
    setIsLoading(true);
    const result = await OnboardingService.updateServices(
      selectedServices,
      draftId
    );
    if (isSuccess(result)) {
      setIsLoading(false);
      Dispatch.App.setDraftId(result.value.draftId);

      const path = pipe(
        result.value.nextScreen,
        getOrElse(() => "/")
      );
      // navigate(path);
      navigate(RoutePaths.ReviewApplicationNew);
    } else if (isFailure(result)) {
      setIsLoading(false);
      setError("Service update failed");
    }
  };

  const handleServiceSelect = (
    type: CorporateServiceInfo.ServiceTypeEnum,
    isSelected: boolean
  ) => {
    setSelectedServices((prevState) => {
      if (isSelected && type) {
        return [...prevState, type];
      } else {
        return prevState.filter((id) => id !== type) || [];
      }
    });
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <div className="container p-0">
        <BrandLogoHeaderNew />
        {/* <Stepper /> */}
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row">
            <div className="col-lg-8 col-md-6 mt-2">
              <MediumScreen>
                <div className="mt-2 mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="350"
                    height="350"
                    src={Image1}
                    alt="Mobile"
                  />
                </div>
                <div className="mx-5 textDetail" style={{ marginTop: "-80px" }}>
                  <Heading
                    className="mx-5 mb-3"
                    text="De'Tax"
                    variant="small"
                  />

                  <p className="mx-5 fw-bolder text-secondary">
                    De’Tax solutions enable you to put your employees on the
                    path to financial wellness. Lifestyle spends are made
                    smarter and tax-efficient. Secure the financial wellness of
                    your most important asset - the employees!
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-2 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="p-3 border-0 shadow card bg-grey rounded-15">
                    <BackButton
                      text="Select our solutions"
                      path={RoutePaths.DigitalContractNew}
                    />
                    {error && (
                      <div
                        className="text-center alert alert-danger fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <p className="mt-2 mb-0 mb-3 text-center d-none d-sm-none d-md-block fw-bold text-secondary">
                      Select the solutions you want to apply to
                    </p>
                    <div className="text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                      <div className="container p-0 m-0">
                        {services.map((service, index) => (
                          <ServiceCard
                            service={service}
                            onSelect={handleServiceSelect}
                            isChecked={selectedServices.includes(
                              pipe(
                                service.type,
                                getOrElse(() => "")
                              )
                            )}
                            key={index}
                          />
                        ))}
                        <div className="mt-5 ml-auto mr-auto text-center">
                          <Button type="button" onClick={handleSubmit}>
                            {isLoading ? (
                              <Loader color="white" />
                            ) : (
                              <>Continue</>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-3 border-0 card bg-grey">
                <BackButton
                  text="Select our solutions"
                  path={RoutePaths.DigitalContractNew}
                />
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className=" d-none d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="BG"
                  />
                </div>

                <p className="mt-2 text-center d-md-none d-lg-none d-xl-none d-xxl-none fw-bold text-secondary ">
                  Select the solutions you want to apply to{" "}
                </p>

                <div className="mb-4 inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                  {services.map((service, index) => (
                    <ServiceCard
                      service={service}
                      onSelect={handleServiceSelect}
                      isChecked={selectedServices.includes(
                        pipe(
                          service.type,
                          getOrElse(() => "")
                        )
                      )}
                      key={index}
                    />
                  ))}
                </div>

                <div className="mb-5 ml-auto mr-auto text-center">
                  <Button type="button" onClick={handleSubmit}>
                    {isLoading ? <Loader color="white" /> : <>Continue</>}
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </div>
  );
};

export default SelectServicesNew;
