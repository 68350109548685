import React from "react";
import { getOrElse, Option } from "fp-ts/lib/Option";
import { fromPublic } from "../../utils/common.utils";
import { pipe } from "fp-ts/lib/function";
const Building = fromPublic("/images/building.svg");

const CompanyNameCard: React.FC<{
  name: Option<string>;
  cin: Option<string>;
}> = ({ name, cin }) => {
  return (
    <div className="card border-0  bg-white rounded-15 mt-3 p-3">
      <div className="row">
        <div className="col-2 col-md-2 text-right">
          <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto py-1">
            <img
              src={Building}
              className="img-fluid ml-auto mr-auto w-50"
              alt="icon"
            />
          </div>
        </div>
        <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
          <p className="mb-0 fs-small fw-bold text-uppercase">
            {pipe(
              name,
              getOrElse(() => "")
            )}
          </p>
          <h5 className="font-14 mb-0 pt-1">
            {" "}
            CIN :{" "}
            {pipe(
              cin,
              getOrElse(() => "")
            )}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default CompanyNameCard;
