import "./reuse-icons.scss";

type TReuseIcon = {
  className?: string;
  onClick?: () => void;
};

const ReuseIcon: React.FC<TReuseIcon> = ({
  className,
  children,
  onClick = () => {},
}) => {
  return (
    <div
      className={`wrap-icon ${className}`}
      id="reuseIcon"
      role="button"
      onClick={() => {
        onClick();
      }}
    >
      {children}
    </div>
  );
};

export default ReuseIcon;
