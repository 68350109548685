import React from "react";
import { Button } from "../..";
import { fromPublic } from "../../../utils/common.utils";

const Group = fromPublic("/images/icons/group-1.svg");
const ArrowUp = fromPublic("/images/icons/arrow-up.png");
const ArrowDown = fromPublic("/images/icons/arrow-down-square.svg");

interface Props {
  devicetype: string;
}
const DirectorHeader: React.FC<{
  toggle: boolean;
  onToggle: (toggle: boolean) => void;
}> = ({ toggle, onToggle }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="col-8 py-2">
        <span className="d-inline-block me-3">
          <img
            className="img-fluid rounded-6_i p-2"
            src={Group}
            alt="group"
            style={{
              height: "2.4rem",
              width: "2.4rem",
              background: "#f5f7fb"
            }}
          />
        </span>
        <span className="fs-6">Select Directors</span>
      </div>
      <div className="d-flex justify-content-end col-4">
        <span onClick={() => onToggle(!toggle)}>
          {toggle ? (
            <div className="d-flex align-items-center w-100">
              <Button className="btn-darkblue btn py-1 ms-3 me-2 my-auto font-14 text-white mt-1">
                Done
              </Button>
              <img
                src={ArrowDown}
                alt="arrow"
                style={{
                  cursor: 'pointer',
                  height: "1.5rem",
                  width: "1.5rem",
                }}
              />
            </div>
          ) : (
            <img
              src={ArrowDown}
              alt="arrow"
              style={{
                cursor: 'pointer',
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
          )}
        </span>
      </div>
      {/* <span
      "img-fluid mar-icon-left-115"
      </span> */}
    </div>
  );
};

export default DirectorHeader;
