import React from "react";

import "../claims/claims.scss";
import { titleCase } from "../../../../utils/common.utils";
import { CardTransactionInfo } from "../../../../api/detax/model/cardTransactionInfo";
import { currencyFormat } from "../../../../utils/format.utils";

const TransactionApprovedCard: React.FC<{ cardInfo?: CardTransactionInfo }> = (
  props
) => {
  if (!props.cardInfo) {
    return null;
  }

  return (
    <>
      {props.cardInfo.approvalStatus ===
        CardTransactionInfo.ApprovalStatusEnum.APPROVED ||
      props.cardInfo.approvalStatus ===
        CardTransactionInfo.ApprovalStatusEnum.REJECTED ||
      props.cardInfo.approvalStatus ===
        CardTransactionInfo.ApprovalStatusEnum.REVIEWPENDING ? (
        <div
          className={
            props.cardInfo.approvalStatus ===
            CardTransactionInfo.ApprovalStatusEnum.APPROVED
              ? `card text-dark success-claim-box`
              : props.cardInfo.approvalStatus ===
                CardTransactionInfo.ApprovalStatusEnum.REJECTED
              ? `card text-dark reject-claim-box`
              : `card text-dark pending-claim-box`
          }
        >
          <div className="card-body text-color pb-0">
            <p>
              {props.cardInfo.approvalStatus ===
              CardTransactionInfo.ApprovalStatusEnum.APPROVED
                ? " Approval Details"
                : props.cardInfo.approvalStatus ===
                  CardTransactionInfo.ApprovalStatusEnum.REJECTED
                ? "Rejected Details"
                : "Review Details"}
            </p>
          </div>
          <div className="card-body pt-0">
            {props.cardInfo.approvalStatus ===
              CardTransactionInfo.ApprovalStatusEnum.APPROVED ||
            props.cardInfo.approvalStatus ===
              CardTransactionInfo.ApprovalStatusEnum.REVIEWPENDING ? (
              <div className="row">
                <div className="col-12 col-md-6">
                  {" "}
                  <p> Approved Amount</p>
                </div>
                <div className="col-12 col-md-6">
                  <p> {currencyFormat(props.cardInfo.appAmt || 0)}</p>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-12 col-md-6">
                {" "}
                <p>
                  {" "}
                  {props.cardInfo.approvalStatus ===
                    CardTransactionInfo.ApprovalStatusEnum.APPROVED ||
                  props.cardInfo.approvalStatus ===
                    CardTransactionInfo.ApprovalStatusEnum.REVIEWPENDING
                    ? "Approved By"
                    : "Rejected By"}
                </p>
              </div>
              <div className="col-12 col-md-6">
                {" "}
                <p> {props.cardInfo.approvedBy?.name}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                {" "}
                <p>
                  {" "}
                  {props.cardInfo.approvalStatus ===
                    CardTransactionInfo.ApprovalStatusEnum.APPROVED ||
                  props.cardInfo.approvalStatus ===
                    CardTransactionInfo.ApprovalStatusEnum.REVIEWPENDING
                    ? "Approved At"
                    : "Rejected At"}
                </p>
              </div>
              <div className="col-12 col-md-6">
                {" "}
                <p> {props.cardInfo.approvedAt || "-"}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                {" "}
                <p> Remarks </p>
              </div>
              <div className="col-12 col-md-6">
                {" "}
                <p> {props.cardInfo.approverRemarks || "N/A"}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TransactionApprovedCard;
