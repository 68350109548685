/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { NpsAccountApiResponse } from "../model/npsAccountApiResponse";
import { NpsContributionResponse } from "../model/npsContributionResponse";
import { UpdateNpsAccountRequest } from "../model/updateNpsAccountRequest";
import { AddBulkNPSApiRequest } from "../../employee/model/addBulkEmployeeApiRequest";
import {
  EmployeeListApiResponse,
  NpsBulkApiResponse,
} from "../model/employeeListApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishNpsApiService {
  /**
   *
   * @param uid -
   */
  export const getContribution = async (
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsContributionResponse>> => {
    const path = `/secure/detax/nps/contribution/user/${encodeURIComponent(
      String(uid)
    )}`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const getContribution1 = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsContributionResponse>> => {
    const path = `/secure/detax/nps/contribution/me`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   */
  export const getUserAccount = async (
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsAccountApiResponse>> => {
    const path = `/secure/nps/users/account/getUserNpsDetails`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const myAccount = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsAccountApiResponse>> => {
    const path = `/secure/detax/nps/account/me`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   * @param request -
   */
  export const updateAccount = async (
    uid: string,
    request: UpdateNpsAccountRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsAccountApiResponse>> => {
    const path = `/secure/detax/nps/account/user/${encodeURIComponent(
      String(uid)
    )}/change/status`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));

    const queryParams = queryString.stringify(request);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  export const createNpsBulk = async (
    body: AddBulkNPSApiRequest,
    cid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsBulkApiResponse>> => {
    const path = `/secure/nps/users/interCraUpdate`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [{ key: "cid", value: cid }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
