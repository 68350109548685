export enum NextActionEnum {
  SIGNUP = "SIGNUP",
  SIGNUPVERIFICATION = "SIGNUP_VERIFICATION",
  COMPANYREVIEW = "COMPANY_REVIEW",
  CONTRACTRECEIPIENT = "CONTRACT_RECEIPIENT",
  SELECTSERVICES = "SELECT_SERVICES",
  APPLICATIONREVIEW = "APPLICATION_REVIEW",
  APPLICATIONSUBMITTED = "APPLICATION_SUBMITTED",
  DASHBOARD = "DASHBOARD",
  LOGIN = "LOGIN",
  LOGINVERIFICATION = "LOGIN_VERIFICATION",
  FXRETAIL = "FX_RETAIL",
  SENDREDIRECT = "SEND_REDIRECT",
}
