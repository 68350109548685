import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import OtpField from "react-otp-field";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { AuthService } from "../../services/auth.service";
import { fromPublic, timerSeconds } from "../../utils/common.utils";
import { RoutePaths } from "../../utils/route.utils";
import CardButton from "../common/button/card-button";
import FormikComponent from "../common/formik/formik-component/formik-component";

import SpinnerComponent from "../common/loader/spinner";
import { ForgotPassWithUsername } from "../form/forgot-password";
import SuccessPopup from "./dashboard/common/succes-popup";
import mixpanel from "mixpanel-browser";
import validator from "validator";
import { BrandHeader } from "../brandlogo-header/brandlogo-header";

import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const Image3 = fromPublic("/images/forgot_password.svg");
const Image4 = fromPublic("/images/verify_otp.svg");
const Image5 = fromPublic("/images/reset_password.svg");

interface IValues {
  mobile: string; // work as a username
}

interface IData {
  mobile: string;
  otpId: string;
  otpVal: string;
}

const formInitialSchema: IValues = {
  mobile: "",
};
const validationSchema = yup.object().shape({
  mobile: yup.string().required("Please enter your email"),
});

const Scrren1Page: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  setScreen: (data: number) => void;
}> = ({ data, setData, setScreen }) => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Forgot password" });
  }, []);

  const handleFormSubmit = async (values: IValues) => {
    setError("");
    setIsLoading(true);
    const result = await AuthService.sendOtpOnForgotPassword({
      username: values.mobile,
    });

    setIsLoading(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setScreen(1);
        let newData = data;
        newData.mobile = values.mobile;
        newData.otpId = result.value.otpId || "";
        setData(newData);
      } else {
        setError(result.value.message || "");
        //setError('Invalid Email Id');
      }
    } else {
      setError("OTP couldn't sent");
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - Ncaish</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <BrandHeader />
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="row align-items-center vh-100">
              <div className="col-lg-8 col-md-6 ">
                <MediumScreen>
                  <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="Mobile"
                    />
                  </div>
                  <div className="mt-5 textDetail">
                    {/* <Heading
                      className="mb-3 mt-85"
                      text="Forgot Password"
                      variant="small"
                    />

                    <p className="fw-bolder text-secondary">
                      Being a Fintech we understand the importance of Multi
                      Factor authentication prescribed by Reserve Bank of India
                      !
                    </p> */}
                  </div>
                </MediumScreen>
              </div>
              <div className="mt-4 col-lg-4 col-md-6">
                <MediumScreen>
                  <div className="container p-0 m-0">
                    <div className="p-3 border-0 shadow card bg-grey rounded-15">
                      {error && (
                        <div
                          className="text-center alert alert-danger fw-bold"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      <Heading
                        className="text-center mt-50"
                        text="Forgot Password"
                        variant="large"
                      />
                      {/* <p className="mt-4 mb-0 mb-4 text-center text-black d-none d-sm-none d-md-block fw-bold">
                        Kindly enter your username
                      </p> */}
                      <br />
                      <br />
                      <div className="text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                        <ForgotPassWithUsername />
                        <div className="mt-5 mb-3 ml-auto mr-auto text-center">
                          <Button type="submit">
                            {isLoading ? <SpinnerComponent /> : <>Send OTP</>}
                          </Button>
                        </div>
                      </div>

                      <p className="mt-1 mb-2 text-center fw-bolder text-secondary">
                        Back to
                        <Link className="ncash_nav-link" to={RoutePaths.Login}>
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </MediumScreen>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="p-3 border-0 shadow card bg-grey">
                  {error && (
                    <div
                      className="text-center alert alert-danger fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="BG"
                    />
                  </div>

                  <h1 className="mt-3 mb-4 text-center fw-bold heading fs-5">
                    Forgot Password
                  </h1>
                  {/* <p className="mb-0 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small">
                    Kindly enter your username
                  </p> */}
                  <br />
                  <br />

                  <div className="p-3 mt-4 mb-4 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                    <ForgotPassWithUsername />
                  </div>
                  <div className="mb-5 ml-auto mr-auto text-center">
                    <Button type="submit">
                      {isLoading ? <SpinnerComponent /> : <>Send OTP</>}
                    </Button>
                  </div>

                  <p className="mt-1 text-center fw-bolder text-secondary ">
                    Back to
                    <Link className="ncash_nav-link" to={RoutePaths.Login}>
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

const Scrren2Page: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  setScreen: (data: number) => void;
}> = ({ data, setData, setScreen }) => {
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState(false);

  const [otpCounter, setOtpCounter] = useState(25);

  useEffect(() => {
    setData({ ...data, otpVal: "" });
  }, []);

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpCounter]);

  const handleSubmit = async () => {
    if (data.otpVal.length === 6) {
      let payload = {
        otpId: data.otpId,
        otp: data.otpVal,
      };

      setIsLoading(true);
      const result = await AuthService.verifyForgotPasswordOtp(payload);
      setIsLoading(false);
      if (isSuccess(result)) {
        if (result.value.successful) {
          if (result.value.otpId) {
            setData({ ...data, otpId: result.value.otpId });
            setError("");
            setScreen(3);
          } else {
            //setError("Something went wrong");
          }
        } else {
          setError(result.value.message || "");
        }
      } else {
        //setError("Something went wrong");
      }
    } else {
      setError("Invalid OTP");
    }
  };

  const resendOTP = async () => {
    setError("");
    setIsLoading(true);
    setOtpCounter(25);
    const result = await AuthService.sendOtpOnForgotPassword({
      username: data.mobile,
    });
    // console.log(result);
    setIsLoading(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setScreen(1);
        setData({ ...data, otpId: result.value.otpId || "" });
      } else {
        setError(result.value.message || "");
      }
    } else {
      setError("OTP couldn't sent");
    }
  };

  var user = data.mobile;

  return (
    <>
      <Helmet>
        <title>Forgot Password - Ncaish</title>
      </Helmet>
      <div className="container p-0">
        <BrandHeader />
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image4}
                    alt="Mobile"
                  />
                </div>
                <div className="mt-5 textDetail">
                  {/* <Heading
                    className="mb-3 mt-85"
                    text="Forgot Password"
                    variant="small"
                  />

                  <p className="fw-bolder text-secondary">
                    Being a Fintech we understand the importance of Multi Factor
                    authentication prescribed by Reserve Bank of India !
                  </p> */}
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="px-3 py-2 border-0 shadow card bg-grey rounded-15">
                    <Heading
                      className="mt-5 text-center"
                      text="OTP Verification"
                      variant="large"
                    />
                    {error && (
                      <div
                        className="text-center alert alert-danger fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <span className="mt-3 mb-3 text-center text-black fw-bold ">
                      We have sent the verification code to your
                      {validator.isEmail(user)
                        ? " mobile number"
                        : " mobile number"}
                    </span>
                    {/* <span className="mt-3 mb-1 text-center text-black fw-bold fs-5"> */}
                    {/* +91-{data.mobile} &nbsp; */}
                    {/* {validator.isEmail(user)
                        ? data.mobile
                        : "+91-" + data.mobile}{" "}
                      &nbsp;
                      <CardButton icon={"edit"} onClick={() => setScreen(0)} />
                    </span> */}
                    <div
                      id="otp"
                      className="flex-row mt-3 mb-3 inputs d-flex justify-content-center"
                    >
                      <OtpField
                        classNames="d-flex "
                        value={data.otpVal}
                        onChange={(o: string) =>
                          setData({ ...data, otpVal: o })
                        }
                        numInputs={6}
                        onChangeRegex={/^([0-9]{0,})$/}
                        autoFocus
                        inputProps={{
                          className:
                            "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                          disabled: false,
                        }}
                      />
                    </div>
                    <p className="mt-1 mb-0 text-center fw-bolder text-secondary">
                      Did not receive OTP?
                    </p>
                    <Link
                      to=""
                      className={
                        "fw-bolder text-darkblue text-center nav-link " +
                        (otpCounter <= 0 ? " " : "disabled")
                      }
                      onClick={() => resendOTP()}
                    >
                      Resend Code{" "}
                      <span className="mt-2 text-danger">
                        {timerSeconds(otpCounter)}
                      </span>
                    </Link>

                    {/* <ResendButton /> */}

                    <div className="mt-3 mb-10 ml-auto mr-auto text-center">
                      <Button
                        type="button"
                        className={
                          data.otpVal.length !== 6
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!data.otpVal}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <SpinnerComponent /> : <>Continue</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-3 border-0 shadow card bg-grey">
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image4}
                    alt="BG"
                  />
                </div>

                <h1 className="mt-3 mb-4 text-center fw-bold heading fs-5">
                  OTP Verification
                </h1>
                <p className="mb-0 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small">
                  We have sent the verification code to your{" "}
                  {validator.isEmail(user)
                    ? " mobile number"
                    : " mobile number"}
                </p>

                <div className="p-3 mt-4 mb-4 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                  {/* <ForgotPassWithMobile /> */}
                  {/* <span className="mt-3 mb-1 text-center text-black fw-bold fs-5"> */}
                  {/* +91-{data.mobile} &nbsp; */}
                  {/* {validator.isEmail(user)
                      ? data.mobile
                      : "+91 -" + data.mobile}{" "}
                    &nbsp;
                    <CardButton icon={"edit"} onClick={() => setScreen(0)} />
                  </span> */}
                  <div
                    id="otp"
                    className="flex-row mt-3 mb-3 inputs d-flex justify-content-center"
                  >
                    <OtpField
                      classNames="d-flex "
                      value={data.otpVal}
                      onChange={(o: string) => setData({ ...data, otpVal: o })}
                      numInputs={6}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      inputProps={{
                        className:
                          "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                        disabled: false,
                      }}
                    />
                  </div>
                  <p className="mt-1 mb-0 text-center fw-bolder text-secondary">
                    Did not receive OTP?
                  </p>
                  <Link
                    to=""
                    className={
                      "fw-bolder text-darkblue text-center nav-link " +
                      (otpCounter <= 0 ? " " : "disabled")
                    }
                    onClick={() => resendOTP()}
                  >
                    Resend Code{" "}
                    <span className="mt-2 text-danger">
                      {timerSeconds(otpCounter)}
                    </span>
                  </Link>

                  {/* <ResendButton /> */}

                  <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                    <Button
                      type="button"
                      className={
                        data.otpVal.length !== 6
                          ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                          : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                      }
                      disabled={!data.otpVal}
                      onClick={handleSubmit}
                    >
                      {isLoading ? <SpinnerComponent /> : <>Continue</>}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </>
  );
};

const Scrren3Page: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  setScreen: (data: number) => void;
  setShowSuccess: (data: boolean) => void;
}> = ({ data, setData, setScreen, setShowSuccess }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordInput1, setPasswordInput1] = useState("");

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleSubmit = async () => {
    let payload = {
      otpId: data.otpId,
      password: newPassword,
    };
    // console.log(payload)
    setError("");
    if (newPassword != "") {
      if (newPassword === confirmPassword) {
        if (newPassword.length >= 6 && newPassword.length <= 30) {
          setIsLoading(true);
          const result = await AuthService.resetPassword(payload);
          setIsLoading(false);
          if (isSuccess(result)) {
            if (result.value.successful) {
              if (result.value.logout) {
                localStorage.clear();
                navigate("/login");
              }
              setShowSuccess(true);
            } else {
              setError(result.value.message || "");
            }
          } else {
            setError("Something went wrong");
          }
        } else {
          setError("Password must be between 6 and 30 characters");
        }
      } else {
        setError("Password and Confirm Password does not match");
      }
    } else {
      setError("Password is required");
    }
  };

  return (
    <>
      <Helmet>
        <title>Reset Password - Ncaish</title>
      </Helmet>
      <div className="container p-0">
        <BrandHeader />
        <div
          className="mt-1 d-flex flex-row justify-content-between"
          style={{ gap: "32px" }}
        ></div>
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image5}
                    alt="Mobile"
                  />
                </div>
                <div className="mt-5 textDetail">
                  {/* <Heading
                    className="mb-3 mt-85"
                    text="Reset Password"
                    variant="small"
                  />

                  <p className="fw-bolder text-secondary">
                    Being a Fintech we understand the importance of Multi Factor
                    authentication prescribed by Reserve Bank of India !
                  </p> */}
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="px-3 py-2 border-0 shadow card bg-grey rounded-15">
                    <Heading
                      className="mt-5 text-center"
                      text="Reset Password"
                      variant="large"
                    />
                    {error && (
                      <div
                        className="text-center alert alert-danger fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <div className="mt-5 mb-3 form-group">
                      <label htmlFor="newPassword">New Password</label>
                      <div className="inputWithButton">
                        <input
                          id="newPassword"
                          className="form-control"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          type={passwordType}
                        />
                        <button
                          className="password-eye1"
                          onClick={togglePassword}
                          type="button"
                        >
                          {passwordType === "password" ? (
                            <span>
                              {" "}
                              <Icon icon={eyeOff} />
                            </span>
                          ) : (
                            <span>
                              {" "}
                              <Icon icon={eye} />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3 form-group">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <div className="inputWithButton">
                        <input
                          id="confirmPassword"
                          className="form-control"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          type={passwordType1}
                        />
                        <button
                          className="password-eye1"
                          onClick={togglePassword1}
                          type="button"
                        >
                          {passwordType1 === "password" ? (
                            <span>
                              {" "}
                              <Icon icon={eyeOff} />
                            </span>
                          ) : (
                            <span>
                              {" "}
                              <Icon icon={eye} />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                      <Button
                        type="button"
                        className={
                          data.otpVal.length !== 6
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!data.otpVal}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <SpinnerComponent /> : <>Save Changes</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-3 border-0 shadow card bg-grey">
                <Heading
                  className="mt-5 text-center"
                  text="Reset Password"
                  variant="large"
                />
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="mt-5 mb-3 form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <div className="inputWithButton">
                    <input
                      id="newPassword"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type={passwordType}
                    />
                    <button
                      className="password-eye1"
                      onClick={togglePassword}
                      type="button"
                    >
                      {passwordType === "password" ? (
                        <span>
                          {" "}
                          <Icon icon={eyeOff} />
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Icon icon={eye} />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="mb-3 form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="inputWithButton">
                    <input
                      id="confirmPassword"
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type={passwordType1}
                    />
                    <button
                      className="password-eye1"
                      onClick={togglePassword1}
                      type="button"
                    >
                      {passwordType1 === "password" ? (
                        <span>
                          {" "}
                          <Icon icon={eyeOff} />
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Icon icon={eye} />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                  <Button
                    type="button"
                    className={
                      data.otpVal.length !== 6
                        ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                        : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                    }
                    disabled={!data.otpVal}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <SpinnerComponent /> : <>Save Changes</>}
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </>
  );
};

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [screen, setScreen] = useState<number>(0);
  const [data, setData] = useState<IData>({
    mobile: "",
    otpId: "",
    otpVal: "",
  });

  return (
    <>
      <Helmet>
        <title>Forgot Password - Ncaish</title>
      </Helmet>
      {screen === 0 ? (
        <Scrren1Page setScreen={setScreen} data={data} setData={setData} />
      ) : screen === 1 ? (
        <Scrren2Page setScreen={setScreen} data={data} setData={setData} />
      ) : (
        <Scrren3Page
          setScreen={setScreen}
          data={data}
          setData={setData}
          setShowSuccess={setShowSuccess}
        />
      )}
      <SuccessPopup
        message={`Password has updated successfully`}
        show={showSuccess}
        onClose={(flag) => navigate(RoutePaths.Login)}
      />
    </>
  );
};

export default ForgotPasswordPage;
