import React, { useEffect, useState } from "react";
import DashboardSubHeader from "../dashboard-sub-header/sub-header";
import SidebarMenu from "../sidebar-menu/sidebar-menu";
import "./dashboard-layout.scss";
import BrandLogo from "../brand-logo/brand-logo";
import { fromPublic } from "../../utils/common.utils";
import { Link } from "react-router-dom";
import { CorporateService } from "../../services/corporate.service";
import { isSuccess } from "@devexperts/remote-data-ts";
import { useDispatch } from "react-redux";
import { setProfile } from "../../state/slice/profile.slice";
import PageLoader from "../../page-loader";
import { AlphaDispatch, AlphaReduxStore } from "../../state/slice/alpha.slice";

import { useAppSelector } from "../../hooks/hooks";
import { selectPasswordChange } from "../app/app.slice";
import CommonError from "../common/errors/common-error";
import { some } from "fp-ts/lib/Option";
import { unWrapOption } from "../../utils/option.utils";
import { dashboardMenus } from "../../config/dashboard-menu";
import { alphaDashboardMenus } from "../../config/alpha-dashboard-menu";
import { TDashboardMenu } from "../../types/dashboard-menu.type";
import {
  ReportDownloadState,
  ReportsDispatch,
} from "../../state/slice/reports.slice";

const NcashLogo = fromPublic("/images/icons/Brand-logo-white.svg");
const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const isPasswordChange = useAppSelector(selectPasswordChange);
  const [sideBarMenu, setSideBarMenu] =
    useState<TDashboardMenu[]>(dashboardMenus);
  const [isLoading, setIsLoading] = useState(false);
  const activeSettings = useAppSelector(AlphaReduxStore.selectActiveSettings);
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const result = await CorporateService.myProfile();
      if (isSuccess(result)) {
        if (result.value.successful) {
          dispatch(setProfile(result.value.data || null));
        }
      } else {
        dispatch(setProfile(null));
      }
      let updatedSideBarMenu = [...sideBarMenu];

      const addMenu = (data: string, index: number) => {
        const menu = alphaDashboardMenus.map((obj) => {
          if (obj.label === data) {
            // updatedSideBarMenu.push(obj);

            // Insert the object at index 2 in the updatedSideBarMenu array
            updatedSideBarMenu.splice(index, 0, obj);

            setSideBarMenu(updatedSideBarMenu);
          }
        });
        return menu;
      };

      const alphaReleaseResult = await CorporateService.alphaRelease();
      if (isSuccess(alphaReleaseResult)) {
        if (alphaReleaseResult.value.successful) {
          ReportsDispatch.setDownload({
            "Statement of Allowances": ReportDownloadState.NOT_STARTED,
            "Statement of Perquisites": ReportDownloadState.NOT_STARTED,
            "De'tax Status Report": ReportDownloadState.NOT_STARTED,
            "FBA Utilisation Report": ReportDownloadState.NOT_STARTED,
          });

          if (alphaReleaseResult.value.data?.nps) {
            AlphaDispatch.setNpsStatus(alphaReleaseResult.value.data?.nps);
            // addMenu("NPS", 3);
          }
          if (alphaReleaseResult.value.data?.display) {
            addMenu("Memberships", 2);
          }
          if (
            alphaReleaseResult.value.data?.newClaims ||
            alphaReleaseResult.value.data?.giftCard
          ) {
            AlphaDispatch.setNewClaimsStatus(
              alphaReleaseResult.value.data?.newClaims
            );
            AlphaDispatch.setGiftCardStatus(
              alphaReleaseResult.value.data?.giftCard
            );
            // addMenu("Settings", 10);
            // setSideBarMenu(updatedSideBarMenu);
          }
          if (alphaReleaseResult.value.data?.newClaimsEmployeeConfiguration) {
            AlphaDispatch.setNewClaimsEmployee(
              alphaReleaseResult.value.data?.newClaimsEmployeeConfiguration
            );
          }
          if (alphaReleaseResult.value.data?.enableBatchExcel) {
            AlphaDispatch.setEnableBatchExcel(
              alphaReleaseResult.value.data?.enableBatchExcel
            );
          }
          if (alphaReleaseResult.value.data?.manualTxn) {
            AlphaDispatch.setManualTxn(
              alphaReleaseResult.value.data?.manualTxn
            );
          }
        } else {
          // AlphaDispatch.setDisplayStatus(false);
        }
      } else {
        // AlphaDispatch.setDisplayStatus(false);
      }
      setIsLoading(false);
    };
    run();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container-fluid dashboard-body-bg">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-lg-1 col-xl-1 col-xxl-1 dashboard-sidebar-container menu-max-width">
            <div className="primary-gradient">
              <div className="dashboard-sidebar">
                {/* <Link to="/"> */}
                <BrandLogo
                  src={NcashLogo}
                  width="55"
                  height="82"
                  classname="my-3"
                  name="Ncash Logo"
                />
                {/* </Link> */}
                <div className="mt-0">
                  <SidebarMenu menuItems={sideBarMenu} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-10 col-md-10 col-lg col-xl col-xxl dashboard-content-container">
            <div className="mx-auto">
              {activeSettings !== "Settings" && <DashboardSubHeader />}
              {children}
              {/* {isPasswordChange ? null : <ChangePasswordPopup />} */}
              <CommonError />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
