import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClaimCountInfo } from "../../../../api/detax/model/claimCountInfo";
import { ClaimsService } from "../../../../services/claims.service";
import { StatusCountService } from "../../../../services/status-count.service";
import { TransactionService } from "../../../../services/transaction.service";
import { selectDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";
import CardLoader from "../common/card-loader";
import { useAppSelector } from "../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";

//ClaimsService
interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const TransactionStatusCountCard: React.FC<{
  uid?: string;
  setCurTbl: (tbl: TStatusCountInfo) => void;
  curTbl: TStatusCountInfo | null;
  filterDate: IDateRange;
  detaxCard?: boolean;
}> = ({ uid = "", setCurTbl, curTbl, filterDate, detaxCard = false }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [counts, setCounts] = useState<Array<TStatusCountInfo> | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);
  const manualTxn = useAppSelector(AlphaReduxStore.selectManualTxn);
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const claimsStatusCount =
        await TransactionService.transactionApprovalStatusCounts(
          filterDate.startDate
            ? format(new Date(filterDate.startDate), "dd-MM-yyyy")
            : "",
          filterDate.endDate
            ? format(new Date(filterDate.endDate), "dd-MM-yyyy")
            : "",
          uid,
          "DEBIT"
        );
      setIsLoading(false);
      if (isSuccess(claimsStatusCount)) {
        if (claimsStatusCount.value.successful) {
          const allCards = StatusCountService.addAllTransactionCount(
            claimsStatusCount.value.data || []
          );
          if (allCards.length > 0) {
            setCounts(allCards);
            if (card == "") setCurTbl(allCards?.[0]);
            else {
              let cur = allCards.filter((i) => i.status === card)?.[0];
              if (cur) setCurTbl(cur);
            }
          }
        }
      } else {
        setErr(true);
      }
      //  else if (isFailure(claimsStatusCount)) {
      //   // alert(claimsStatusCount?.error?.outcome)
      //   console.log("claimsCounts: ", claimsStatusCount?.error?.outcome);
      // }
    };

    run();
  }, [filterDate]);

  const [graphData, setGraphData] = useState(alignedFormat);

  useEffect(() => {
    const updatedAlignedFormat = alignedFormat.map((obj) => {
      const correspondingMergedData = counts.find(
        (data) => data.status === obj.status
      );

      if (correspondingMergedData) {
        return { ...obj, count: correspondingMergedData?.count ?? 0 };
      } else {
        return obj;
      }
    });

    let totalCount = () => {
      let total = 0;
      if (updatedAlignedFormat.length > 0) {
        updatedAlignedFormat
          // .filter((data) => data.status !== "All")
          .map((item) => {
            total = total + (item?.count || 0);
          });
      }

      return total;
    };
    // setGraphData(
    //   updatedAlignedFormat.map((obj) =>
    //     obj.status === "All" ? { ...obj, count: totalCount() } : obj
    //   )
    // );
    setGraphData(updatedAlignedFormat);
  }, [counts]);

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        <>
          {isLoading && uid == "" ? (
            <CardLoader />
          ) : (
            <div
              className={classNames("card-counts", {
                "my-4": !detaxCard,
                "mb-4": detaxCard,
              })}
            >
              {graphData
                .filter((obj) =>
                  !manualTxn ? obj.status !== "MANUAL_TRANSACTIONS" : true
                )
                .map((item, key) => (
                  <div
                    key={key}
                    onClick={() => setCurTbl(item)}
                    className="card-count"
                  >
                    <WhiteCard
                      className={classNames("p-2 w-75 status-dot-card", {
                        // detaxCard: detaxCard,
                        active:
                          card && card !== ""
                            ? curTbl?.status === card && card === item.status
                            : +(curTbl?.status === item.status),
                        color6: item.status === "BILL_UPLOAD_PENDING",
                        color7: item.status === "APPROVED",
                        color8: item.status === "REJECTED",
                        color5: item.status === "MANUAL_TRANSACTIONS",
                        color0: item.status === "All",
                        color4: item.status === "REVIEW_PENDING",
                        color13: item.status === "FAILED",
                        color1: item.status === "APPROVAL_PENDING",
                      })}
                    >
                      <div className="status-dot"></div>
                      <h2 className="text-black text-center mb-0 fw-bold">
                        {item.count}
                      </h2>
                      <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                        {item.label}
                      </p>
                    </WhiteCard>
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TransactionStatusCountCard;

const alignedFormat = [
  {
    status: "All",
    label: "All",
    count: 0,
  },

  {
    status: "BILL_UPLOAD_PENDING",
    label: "Bills Upload Pending",
    count: 0,
  },
  {
    status: "APPROVAL_PENDING",
    label: "Bills Submitted",
    count: 0,
  },
  {
    status: "REVIEW_PENDING",
    label: "Under Review",
    count: 0,
  },
  {
    status: "MANUAL_TRANSACTIONS",
    label: "Manual Txns Review",
    count: 0,
  },
  {
    status: "APPROVED",
    label: "Approved",
    count: 0,
  },
  {
    status: "REJECTED",
    label: "Rejected",
    count: 0,
  },
  {
    status: "FAILED",
    label: "Failed",
    count: 0,
  },
];
