/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApiUserInfo } from "./apiUserInfo";
import { Timeline } from "./timeline";

export interface ClaimInfo {
  claimId?: string;
  claimCode?: string;
  category?: string;
  catImage?: string;
  amount?: number;
  appAmount?: number;
  remarks?: string;
  approverRemarks?: string;
  cancellationRemarks?: string;
  status?: ClaimInfo.StatusEnum;
  displayStatus?: string;
  images?: Array<string>;
  initiator?: ApiUserInfo;
  approver?: ApiUserInfo;
  initiatedAt?: string;
  updatedAt?: string;
  cancelledAt?: string;
  approvedAt?: string;
  reimbursedAt?: string;
  txnLinked?: boolean;
  txnRefId?: string;
  timeline?: Timeline;
  allowApproval?: boolean;
  systemMsg?: string;
}
export namespace ClaimInfo {
  export type StatusEnum =
    | "PENDING"
    | "APPROVER_PENDING"
    | "REVIEW_PENDING"
    | "APPROVED"
    | "REJECTED"
    | "IN_PROCESS"
    | "REIMBURSED"
    | "CANCELLED";
  export const StatusEnum = {
    PENDING: "PENDING" as StatusEnum,
    APPROVER_PENDING: "APPROVER_PENDING" as StatusEnum,
    REVIEWPENDING: "REVIEW_PENDING" as StatusEnum,
    APPROVED: "APPROVED" as StatusEnum,
    REJECTED: "REJECTED" as StatusEnum,
    INPROCESS: "IN_PROCESS" as StatusEnum,
    REIMBURSED: "REIMBURSED" as StatusEnum,
    CANCELLED: "CANCELLED" as StatusEnum,
  };
}
