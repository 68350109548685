import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TDetaxCardBatchMemberInfo } from "../../../../models/batches.models";
import { adminTableHeight } from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { unWrapOption } from "../../../../utils/option.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import { useGetBatchEmployeesByBatchId } from "./hooks/transaction.hooks.";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";
import { getTypeKind } from "./txn.utils";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";

const TransactionMemberTable: React.FC<{ batchId: string }> = ({ batchId }) => {
  const datatype = useSelector(DetaxReduxStore.selectDetaxType);

  const {
    data: tableData,
    isLoading,
    isSuccess: isTableSuccess,
    isFetching: isTableFetching,
    isError: isTableError,
  } = useGetBatchEmployeesByBatchId(batchId, datatype);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    if (tableData && tableData[getTypeKind(tableData.type)])
      setPaging((p) => ({
        ...p,
        total: tableData[getTypeKind(tableData.type)].length,
        page: 1,
      }));
  }, [tableData]);

  const rows = (data: TDetaxCardBatchMemberInfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td className="text-center">
              <Link
                className="text-secondary ncash_nav-link"
                to={
                  RoutePaths.EmployeesDetail +
                  "/" +
                  unWrapOption(item.uid, () => "")
                }
              >
                {unWrapOption(item.userCode, () => "")}
              </Link>
            </td>
            <td className="text-start">{unWrapOption(item.name, () => "")}</td>
            <td>{unWrapOption(item.mobile, () => "")}</td>
            <td className="text-center">
              {unWrapOption(item.email, () => "")}
            </td>
            <td className="text-center">
              {unWrapOption(item.cardNo, () => "")}
            </td>

            <td className="text-end">
              {currencyFormat(unWrapOption(item.amountToAdd, () => 0))}
            </td>
            <td className="text-center">
              {unWrapOption(item.executedAt, () => "-")}
            </td>
            <td className="text-capitalize text-center">
              <span
                className={
                  unWrapOption(item.status, () => "") === "EXECUTED"
                    ? "text-success"
                    : unWrapOption(item.status, () => "") === "PENDING"
                    ? "text-warning"
                    : ""
                }
              >
                {" "}
                {unWrapOption(item.status, () => "").toLowerCase()}{" "}
              </span>
            </td>
          </tr>
        ))
      : [
          <tr key={"ss"}>
            <td className="text-secondary" colSpan={8}>
              <img
                src={process.env.PUBLIC_URL + "/images/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
                height="150"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TDetaxCardBatchMemberInfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  if (isLoading) {
    return <TableLoader />;
  }
  let typeData;
  if (tableData) {
    typeData = tableData[
      getTypeKind(tableData.type)
    ] as TDetaxCardBatchMemberInfo[];
    rows(typeData);
  } else {
    // Handle the case where tableData is undefined
    // For example, you can show a loading indicator or an empty state
  }
  return (
    <>
      {tableData && tableData[getTypeKind(tableData.type)].length > 0 ? (
        <TablePagination
          data={paging}
          changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
          changeLimit={(data) =>
            setPaging((p) => ({ ...p, limit: data, page: 1 }))
          }
        >
          {" "}
          <table>
            <thead>
              <tr>
                <th scope="col">Employee Code</th>
                <th scope="col">Employee Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">E-mail</th>
                <th scope="col">Card No.</th>

                <th scope="col">Amount To be Added</th>
                <th scope="col">Executed At</th>
                <th scope="col">Recharge Status</th>
              </tr>
            </thead>
            <tbody>{rows(tblData(typeData ?? []))}</tbody>
          </table>
        </TablePagination>
      ) : (
        <NoTableData />
      )}
    </>
  );
};

export default TransactionMemberTable;
