import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "././reject-popup.scss";
import { CardTransactionInfo } from "../../../../api/detax/model/cardTransactionInfo";

const ApprovePopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  onApprove: () => void;
}> = ({ show, onHide, onApprove }) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-success d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <Modal.Title className="text-light">Confirmation</Modal.Title>
        <p className="modal-title text-white">Are you sure to Approve?</p>
      </Modal.Header>

      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Close
        </Button>
        <Button variant="success" onClick={() => onApprove()}>
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovePopup;

export type THead = {
  categoryIcon: string;
  categoryName: string;
  amount: number;
  baseAmt: number;
  transactionId: string;
  createdAt: string;
  rrn?: string;
  txnStatus?: CardTransactionInfo.TxnStatusEnum;
  scope?: CardTransactionInfo.ScopeEnum;
};

export const ApproveWithAmountPopup: React.FC<{
  head?: THead;
  actionState: boolean;
  show: boolean;
  onHide: (show: boolean) => void;
  onApprove: (amount: string, remark: string) => void;
}> = ({ show, onHide, onApprove, actionState, head }) => {
  const [amount, setAmount] = useState<string>("");
  const [text, setText] = useState("");
  console.log("tests", head?.amount);
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-success d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <Modal.Title className="text-light">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"text-light  text-center bg-success"}>
        <p className="modal-title text-white">Are you sure to Approve?</p>

        <div className="form-group">
          <input
            required
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value.toString());
            }}
            type="number"
            className="form-control mt-2"
            placeholder="Enter amount"
          />
        </div>
        <div className="form-group">
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={2}
            className="form-control mt-2"
            placeholder="Enter remark"
          />
        </div>
      </Modal.Body>

      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          disabled={actionState}
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Close
        </Button>
        <Button
          disabled={
            amount.length === 0 ||
            actionState ||
            parseFloat(amount) > (head?.baseAmt ?? 0)
          }
          variant="success"
          onClick={() => {
            onApprove(amount, text);
          }}
        >
          {actionState && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></span>
          )}
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
