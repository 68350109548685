import { Option, isNone, isSome, none, fold } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DetaxBatchInfo } from "../../../../../api/detax/model/detaxBatchInfo";
import { useAppSelector } from "../../../../../hooks/hooks";
import { DetaxReduxStore } from "../../../../../state/slice/detax.slice";
import PageTitle from "../../../../common/page-title";
import TableLoader from "../../common/table-loader";
import TransactionMemberTable from "../transaction-member-table";
import { fetchBatchDetail, getTabLabel } from "../txn.utils";
import TransactionDetailCard from "./transaction-detail-card";
import TransactionDetailHeading from "./transaction-detail-heading";
import { OnboardingService } from "../../../../../services/onboarding.service";
import { isSuccess } from "@devexperts/remote-data-ts";
import { BatchesService } from "../../../../../services/batches.service";
import { cons } from "fp-ts/lib/ReadonlyNonEmptyArray";
import { UserPermissionResponse } from "../../../../../api/detax/model/userPermissionResponse";
import { DetaxTypeKind } from "../../../../../models/detax-type-kind";
import NpsMemberTable from "../nps-member-table";

const TransactionDetail = () => {
  const { batchId } = useParams<{ batchId: string }>();
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const batchInfoOpt: Option<DetaxBatchInfo> = useAppSelector(
    DetaxReduxStore.selectBatchInfo
  );
  const [permissions, setPermissions] = useState<UserPermissionResponse>();
  const batchCode = isSome(batchInfoOpt)
    ? batchInfoOpt.value.batchCode || ""
    : "";
  const detailLabel = ` > ${getTabLabel(activeTab)} > ${batchCode}`;
  const title = `Batch Details | ${batchCode}`;

  useEffect(() => {
    if (batchId) {
      fetchBatchDetail(batchId);
    }
    const run = async () => {
      const result = await BatchesService.userPermissionCorporate();
      if (isSuccess(result)) {
        setPermissions(result.value);
        // console.log("Permission", result.value.initiateBatch);
      } else {
        // send to error page
      }
    };
    run();
  }, [batchId]);

  if (isNone(batchInfoOpt)) {
    return <TableLoader />;
  }

  return (
    <div>
      <PageTitle title={title} />
      <div>
        {batchId ? (
          <>
            <TransactionDetailHeading
              text={detailLabel}
              permissions={permissions?.initiateBatch ?? false}
            />
            <TransactionDetailCard
              batchId={batchId}
              batchInfoOpt={batchInfoOpt}
            />
            {activeTab === DetaxTypeKind.NPS ? (
              <NpsMemberTable batchId={batchId} />
            ) : (
              <TransactionMemberTable batchId={batchId} />
            )}
          </>
        ) : (
          <TableLoader />
        )}
      </div>
    </div>
  );
};

export default TransactionDetail;
