import React from "react";

import { useAppSelector } from "../../../../hooks/hooks";
import { selectSelectedPriceData } from "../../../../state/slice/fx.slice";
import { CurrencyName, CurrencyPrice, RealTimeNDate } from "./fx-card/card";

const SelectedRealTimePrice = () => {
  const price = useAppSelector(selectSelectedPriceData);
  const codes = price.code ? price.code.split("_") : [];
  //console.log("price:", price);
  const history = price.history;
  return (
    <>
      <div className="col-lg-6 h-100">
        {price ? (
          <div className="bg-white p-2 rounded-6_i shadow-sm h-100">
            <CurrencyName
              className="h5"
              name={price.code ? price.code.replace("_", "/") : ""}
            />
            <div className="d-flex align-items-center">
              <span className="pe-1 h4 m-0">🇮🇳</span>{" "}
              <p className="m-0"> Real time FX</p>
            </div>
            <CurrencyPrice
              currentPrice={price.price || 0}
              presentValue={price.changeValue || 0}
              percentageValue={price.changePer || 0}
              className="h5 mt-1"
            />
            {/* <RealTimeNDate time={price.time} currency={codes ? codes[1] : ""} /> */}
            <>
              <span className="f-14 text-muted">
                {" "}
                Last updated{" "}
                <img
                  src="../images/time.svg"
                  className="img-fluid me-1"
                  alt=""
                />
                {price.time}
              </span>
            </>
          </div>
        ) : null}
      </div>
      <div className="col-lg-6">
        <div className="bg-white p-2 rounded-6_i shadow-sm h-100">
          {history &&
            Object.keys(history)
              .map((h) => ({ key: h, value: history[h] }))
              .map((history) => {
                return (
                  <div key={history.key}>
                    <div className="row">
                      <div className="col">
                        <p className="fw-bold m-0">
                          <small>{history.key}</small>
                        </p>
                      </div>
                      <div className="col">
                        <p className="text-end text-black-50 m-0">
                          <small>{history.value}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default SelectedRealTimePrice;
