import { createSlice } from "@reduxjs/toolkit";
import { DetaxBatchCountInfo } from "../../api/detax/model/detaxBatchCountInfo";

import { NpsSubscriptionCountInfo } from "../../api/detax/model/npsSubscriptionCountInfo";

import { DetaxCardSubscriptionCountInfo } from "../../model/detaxCardSubscriptionCountInfo";

import { UserStatusCountInfo } from "../../model/userStatusCountInfo";
import { State } from "../store";
import { ClaimCountInfo } from "./../../model/claimCountInfo";

export interface IDashboard {
  dashboardLoading: boolean;
  dashboardRedirect: {
    page?: string;
    card?: string;
  };
  detaxInfo?: DetaxCardSubscriptionCountInfo[] | [];
  npsInfo?: NpsSubscriptionCountInfo[] | [];
  employeeInfo?: UserStatusCountInfo[] | [];
  claimInfo: ClaimCountInfo[] | [];
  detaxCardTransactions?: { [key: string]: number } | null;
  fba: DetaxBatchCountInfo[] | [];
  ta: DetaxBatchCountInfo[] | [];
  nps: DetaxBatchCountInfo[] | [];
  gc: DetaxBatchCountInfo[] | [];
  ea: DetaxBatchCountInfo[] | [];
  employeeMessage: string;
}

const initialState: IDashboard = {
  dashboardLoading: true,
  dashboardRedirect: { page: "", card: "" },
  detaxInfo: [],
  npsInfo: [],
  employeeInfo: [],
  claimInfo: [],
  detaxCardTransactions: null,
  fba: [],
  ta: [],
  nps: [],
  gc: [],
  ea: [],
  employeeMessage: "",
};

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setDashboardData: (state, { payload: dashboardData }) => {
      state.detaxInfo = dashboardData.detaxInfo;
      state.npsInfo = dashboardData.npsInfo;
      state.employeeInfo = dashboardData.employeeInfo;
      state.claimInfo = dashboardData.claimInfo;
      state.detaxCardTransactions = dashboardData.detaxCardTransactions;
      state.fba = dashboardData.fba;
      state.ta = dashboardData.ta;
      state.nps = dashboardData.nps;
      state.gc = dashboardData.gc;
      state.ea = dashboardData.ea;
    },
    endDashboardLoad: (state) => {
      state.dashboardLoading = false;
    },
    setDashboardRedirect: (state, { payload }) => {
      state.dashboardRedirect = payload;
    },
    resetDashboardRedirect: (state) => {
      state.dashboardRedirect = { page: "", card: "" };
    },
    setEmployeeMessage: (state, { payload }) => {
      state.employeeMessage = payload;
    },
  },
});

export const {
  initializeApp,
  setDashboardData,
  endDashboardLoad,
  setDashboardRedirect,
  resetDashboardRedirect,
  setEmployeeMessage,
} = slice.actions;

export const selectDashboardData = (state: State) => state.dashboard;
export const selectDashboardLoader = (state: State) =>
  state.dashboard.dashboardLoading;
export const selectDashboardRedirect = (state: State) =>
  state.dashboard.dashboardRedirect;
export const selectEmployeeMessage = (state: State) =>
  state.dashboard.employeeMessage;
export default slice.reducer;
