import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { DetaxService } from "../../../../services/detax.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import {
  adminTableHeight,
  getTitleCase,
  getTitleCaseToStatus,
} from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";

const DetaxTable: React.FC<{
  searchVal?: string;
  curTbl?: DetaxCardSubscriptionInfo | null;
  getSearch?: boolean;
}> = ({ searchVal = "", curTbl, getSearch }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);

  const [items, setItems] = useState<Option<DetaxCardSubscriptionInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<DetaxCardSubscriptionInfo[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 20,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    console.log("Changed");
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [getSearch, curTbl]);
  useEffect(() => {
    let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {
      setItems(none);
      setFilteredData(none);
      const tblResult = await DetaxService.getSubscription(
        cardStatus,
        paging.page,
        paging.limit
      );
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          // console.log("Test", tblResult.value.totalElements, paging.total);
          setPaging((p) => ({
            ...p,
            total: tblResult.value?.totalElements ?? 10,
            page: paging.page,
            limit: paging.limit,
          }));
          setItems(some(tblResult.value.data || []));
          // filterData(some(tblResult.value.data || []));
          setFilteredData(some(tblResult.value.data || []));

          // setReload(false);
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    if (searchVal.length === 0) {
      run();
    } else {
      getSearchDetax();
    }
    dispatch(resetDashboardRedirect());
  }, [paging.page, paging.limit, paging.total]);
  console.log("Test", filteredData, paging.total);
  // useEffect(() => {
  //   // getSearchDetax();
  // }, [searchVal.length && searchVal.length > 0]);
  let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
  const getSearchDetax = async () => {
    // if (reload) {
    setItems(none);
    const tblResult = await DetaxService.getSearchDetax(
      cardStatus,
      searchVal,
      paging.page,
      paging.limit
    );
    if (isSuccess(tblResult) && searchVal.length > 0) {
      if (tblResult.value.successful) {
        setPaging((p) => ({
          ...p,
          total: tblResult.value?.totalElements ?? 10,
          page: paging.page,
          limit: paging.limit,
        }));
        setItems(some(tblResult.value.data || []));
        // filterData(some(tblResult.value.data || []));
        // setReload(false);
        setFilteredData(some(tblResult.value.data || []));
      } else {
        setErr(true);
      }
    } else {
      setErr(true);
    }
    //   setReload(false);
    // }
  };
  const tblData = (tblData: DetaxCardSubscriptionInfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    // return result;
    return tblData;
  };
  console.log("Test 1", filteredData);
  // useEffect(() => filterData(items), [paging.limit, paging.page]);
  // const filterData = (allData: Option<DetaxCardSubscriptionInfo[]>) => {
  //   let data = pipe(
  //     allData,
  //     fold(
  //       () => [],
  //       (d) => (d.length > 0 ? d : [])
  //     )
  //   );
  //   if (searchVal) {
  //     let empCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as employee code
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //     let filteredData = data.filter(
  //       (item) =>
  //         nameRegEx.test(item?.name || "") ||
  //         mobRegEx.test(item?.mobile || "") ||
  //         empCodeRegEx.test(item.personId || "")
  //     );
  //     // setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(some(filteredData));
  //   } else {
  //     // setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(allData);
  //   }
  // };

  const getStatusDate = (item: DetaxCardSubscriptionInfo) => {
    let result: string = "-";
    let status = getTitleCaseToStatus(item.status || "");

    switch (status) {
      case "ACCEPTANCE_PENDING":
        result = item.createdAt || "-";
        break;
      case "KYC_PENDING":
        result = item.kycSubmittedAt || "-";
        break;
      case "KYC_UNDER_REVIEW":
        result = item.kycSubmittedAt || "-";
        break;
      case "KYC_APPROVED":
        result = item.kycApprovedAt || "-";
        break;
      case "KYC_REJECTED":
        result = item.kycApprovedAt || "-";
        break;
      case "ACTIVATION_PENDING":
        result = item.kycApprovedAt || "-";
        break;
      case "ACTIVATED":
        result = item.activatedAt || "-";
        break;
      case "BLOCKED":
        result = item.blockedAt || "-";
        break;
      case "DEACTIVATED":
        result = item.deactivatedAt || "-";
        break;
      case "EXPIRED":
        result = item.expiredAt || "-";
        break;

      default:
        break;
    }
    return result;
  };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        pipe(
          filteredData,
          fold(
            () => [<NoTableData />],
            (items) =>
              items.length > 0
                ? [
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">Employee Code</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Salary</th>
                            <th scope="col">Monthly FBA</th>
                            <th scope="col">FBA Balance</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Updated At</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tblData(items).map((item, key) => (
                            <tr key={key}>
                              <td className="text-start">
                                <Link
                                  className="text-secondary ncash_nav-link"
                                  to={
                                    RoutePaths.DashboardDetaxDetail +
                                    "/" +
                                    item.uid
                                  }
                                >
                                  {item.personId === "" ? "-" : item.personId}
                                </Link>
                              </td>
                              <td className="text-start">
                                {item.name === "" ? "-" : item.name}{" "}
                              </td>
                              <td>{item.mobile === "" ? "-" : item.mobile}</td>
                              <td className="text-start">
                                {item.email === "" ? "-" : item.email}
                              </td>
                              <td className="text-end text-nowrap">
                                {item?.salary != undefined && item?.salary >= 0
                                  ? currencyFormat(item?.salary || 0)
                                  : "-"}
                              </td>
                              <td className="text-end text-nowrap">
                                {item.monthlyFba != undefined &&
                                item.monthlyFba >= 0
                                  ? currencyFormat(item.monthlyFba)
                                  : "-"}
                              </td>
                              <td className="text-end text-nowrap">
                                {item.fbaBalance != undefined &&
                                item.fbaBalance >= 0
                                  ? currencyFormat(item.fbaBalance)
                                  : "-"}
                              </td>
                              {/* <td>{getStatusDate(item)}</td> */}
                              <td className="text-center text-nowrap">
                                {item.createdAt === "" ? "-" : item.createdAt}
                              </td>
                              <td className="text-center text-nowrap">
                                {item.updatedAt === "" ? "-" : item.updatedAt}
                              </td>
                              <td className="text-center text-nowrap">
                                <StatusText
                                  text={getTitleCase(item.status || "")}
                                  className={classNames({
                                    color2:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "ACCEPTANCE_PENDING",
                                    color4:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "KYC_PENDING",
                                    color14:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "KYC_INITIATED",
                                    color5:
                                      getTitleCaseToStatus(item.status || "") ==
                                        "KYC_UNDER_REVIEW" ||
                                      getTitleCaseToStatus(item.status || "") ==
                                        "KYC_SUBMITTED",

                                    color11:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "KYC_REJECTED",

                                    color3:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "ACTIVATION_PENDING",
                                    color7:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "ACTIVATED",
                                    color6:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "DEACTIVATED",

                                    color8:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "BLOCKED",
                                    color9:
                                      getTitleCaseToStatus(item.status || "") ==
                                      "EXPIRED",
                                  })}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TablePagination>,
                  ]
                : [<NoTableData />]
          )
        )
      )}
    </>
  );
};

export default DetaxTable;
