import { fromPublic } from "../../utils/common.utils";
import Stepper from "../stepper/stepper";
const NcashLogo = fromPublic("/images/icons/Brand-logo.svg");
const FigitalLogo = fromPublic("/images/icons/figital-logo.png");

const BrandLogoHeaderNew: React.FC = () => {
  return (
    <div
      className="mt-1 d-flex flex-row justify-content-between"
      style={{ gap: "32px" }}
    >
      <img
        src={FigitalLogo}
        className="ms-5 mt-3"
        width="100"
        height="45"
        title="Figital"
      />
      <Stepper />
      <img
        src={NcashLogo}
        width="75"
        height="75"
        title="Ncaish"
        className="mt-2"
      />
    </div>
  );
};

export default BrandLogoHeaderNew;

export const BrandHeader: React.FC = () => {
  return (
    <div
      className="mt-1 d-flex flex-row justify-content-between"
      style={{ gap: "32px" }}
    >
      <img
        src={FigitalLogo}
        className="ms-5 mt-3"
        width="100"
        height="45"
        title="Figital"
      />

      <img
        src={NcashLogo}
        width="75"
        height="75"
        title="Ncaish"
        className="mt-2"
      />
    </div>
  );
};
