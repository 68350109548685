import { isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { fold, fromNullable, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NcaishDetaxSubscriptonApiService } from "../../../../api/detax/api/ncaishDetaxSubscriptonApi.service";
import { ClaimApproverTblData } from "../../../../api/detax/model/claimApprovers";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { APIError } from "../../../../api/errors";
import { ClaimsService } from "../../../../services/claims.service";
import { Dispatch } from "../../../../state/dispatch";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import { unWrapOption } from "../../../../utils/option.utils";
import NoTableData from "../common/no-table-data";
import SuccessPopup from "../common/succes-popup";
import { ITableData, TablePagination } from "../common/table/table-pagination";
const ApproversRolesTable: React.FC<{
  searchVal?: string;
  show: () => void;
  approver?: Boolean;
  setEditApprover: (data: any) => any;
  editApprover: any;
  setApproverModal: (data: any) => any;
}> = ({
  searchVal = "",
  show,
  approver,
  editApprover,
  setEditApprover,
  setApproverModal,
}) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<APIError>();
  const [employees, setEmployees] =
    useState<Option<ClaimApproverTblData[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<ClaimApproverTblData[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [showSuccess, setShowSuccess] = useState({ show: false, message: "" });
  const [empList, setEmpList] = useState<DetaxCardSubscriptionInfo[]>();
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {

      const tblResult = await ClaimsService.getClaimApprovers();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setPaging((p) => ({
            ...p,
            total: (tblResult.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setEmployees(some(tblResult.value.data || []));
          setFilteredData(some(tblResult.value.data || []));
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();
    getData();
    dispatch(resetDashboardRedirect());
  }, [approver, showSuccess]);

  const deleteClaimApprover = async (refId: string) => {
    const tblResult = await ClaimsService.deleteClaimApprover(refId);
    if (isSuccess(tblResult)) {
      if (tblResult.value.successful) {
        setShowSuccess({ show: true, message: tblResult.value.message ?? "" });
      } else {
        Dispatch.App.setCommonError({
          showError: true,
          errorText: unWrapOption(
            fromNullable(tblResult.value.message),
            () => ""
          ),
        });
        setErr(true);
      }
    } else {
    }
    //   setReload(false);
  };

  const getData = async () => {
    const statusString = "ACTIVATED";
    const result = await NcaishDetaxSubscriptonApiService.getSubscription(
      statusString,
      1,
      10000000
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        setEmpList(result.value.data || []);
      }
    }
  };

  const tblData = (tblData: ClaimApproverTblData[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  const getNamesByUids = (uids: string[]): string[] => {
    if (empList === undefined) {
      return []; // Return an empty array or handle the undefined case appropriately.
    }

    const filteredUsers = empList.filter(
      (user) => user.uid && uids.includes(user.uid)
    );
    return filteredUsers.map((user) => user.name ?? "");
  };

  // Array of DetaxCardSubscriptionInfo objects

  useEffect(() => filterData(employees), [searchVal]);
  const filterData = (allData: Option<ClaimApproverTblData[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );
    if (searchVal) {
      let empCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as employee code
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let mobRegEx = new RegExp(searchVal + ".*", "gi");
      let filteredData = data.filter(
        (item) =>
          nameRegEx.test(item?.name || "") ||
          // mobRegEx.test(item?.mobile || "") ||
          empCodeRegEx.test(item.empCode || "")
      );
      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };
  // if (isLoading && !newlyAdded) {
  //   return <TableLoader />;
  // }

  if (error) {
    return <div>{error.outcome}</div>;
  }

  return (
    <>
      <SuccessPopup
        message={showSuccess.message}
        show={showSuccess.show}
        onClose={() => setShowSuccess({ show: false, message: "" })}
      />
      {pipe(
        filteredData,
        fold(
          () => [<NoTableData />],
          (employees) =>
            employees.length > 0
              ? [
                  <TablePagination
                    data={paging}
                    changePage={(data) =>
                      setPaging((p) => ({ ...p, page: data }))
                    }
                    changeLimit={(data) =>
                      setPaging((p) => ({ ...p, limit: data, page: 1 }))
                    }
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Approves For</th>
                          <th scope="col">Amount Limitations</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblData(employees).map((item, key) => (
                          <tr
                            key={key}
                            // onClick={() => {
                            //   show();
                            //   setPopupData({
                            //     name: item.name,
                            //     email: item.email,
                            //     // mobile: item.mobile,
                            //     // businessUnit: item.businessUnit,
                            //   });
                            // }}
                          >
                            <td className="align-table-data-center">
                              {!item.empCode ? "-" : item.empCode}
                            </td>
                            <td className="align-table-data-center">
                              {item.name || "-"}{" "}
                            </td>
                            {/* <td>{item.mobile || "-"}</td> */}
                            <td className="align-table-data-center">
                              {item.email || "-"}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {item.approvesFor.map((data, index) => (
                                <td
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    borderBottom:
                                      index ===
                                      (item.approvesFor ?? []).length - 1
                                        ? "none"
                                        : "1px solid #ececec",
                                  }}
                                >
                                  {data.claimApproverScope === "BUSINESS_UNIT"
                                    ? `Business Unit - ${data.businessUnit}`
                                    : data.claimApproverScope === "ENTIRE_ORG"
                                    ? "Entire organization"
                                    : ` ${getNamesByUids(
                                        data.employees || []
                                      )}`}
                                </td>
                              ))}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {item.approvesFor.map((obj, index) => (
                                <td
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    borderBottom:
                                      index ===
                                      (item.approvesFor ?? []).length - 1
                                        ? "none"
                                        : "1px solid #ececec",
                                  }}
                                >
                                  {obj.amount === 0
                                    ? "for all amounts"
                                    : `< Rs.${obj.amount}`}
                                </td>
                              )) || "-"}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {item.approvesFor.map((obj, index) => (
                                <td
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    borderBottom:
                                      index ===
                                      (item.approvesFor ?? []).length - 1
                                        ? "none"
                                        : "1px solid #ececec",
                                  }}
                                >
                                  <div className="d-flex justify-content-center align-items-center">
                                    <button
                                      className="btn btn-sm btn-danger me-2"
                                      onClick={() => {
                                        setApproverModal(true);
                                        setEditApprover({
                                          ...item,
                                          approvesFor: [
                                            item.approvesFor[index],
                                          ],
                                        });
                                        // setEditApprover(item);
                                      }}
                                    >
                                      <MdModeEdit />
                                    </button>
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        deleteClaimApprover(obj.approverDefId);
                                      }}
                                    >
                                      <MdDelete />
                                    </button>
                                  </div>{" "}
                                </td>
                              )) || "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TablePagination>,
                ]
              : [<NoTableData />]
        )
      )}
    </>
  );
};

export default ApproversRolesTable;
