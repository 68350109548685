import React, { useState } from "react";
import { DropdownButton } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const DateRangeDropdown: React.FC<{
  startDate?: any;
  endDate?: any;
  onChange: (data: any) => any;
}> = ({
  startDate, //new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate, //new Date(),
  onChange,
}) => {
  const [dtr, setDtr] = useState<IDateRange>({ startDate, endDate });
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const toggleDropdown = () => setDropdownIsOpen((p) => !p);

  const title = startDate
    ? `${format(new Date(startDate), "dd/MM/yyyy")} - ${format(
        new Date(endDate),
        "dd/MM/yyyy"
      )}`
    : "Select Date";

  const date = new Date();

  const minDate = new Date().getFullYear();
  return (
    <DropdownButton
      title={title}
      id="btn-gradient"
      // drop={"start"}
      onToggle={toggleDropdown}
      show={dropdownIsOpen}
    >
      <DateRangePicker
        minDate={new Date(`${2021}`)}
        maxDate={new Date()}
        ranges={[
          {
            startDate: dtr.startDate,
            endDate: dtr.endDate,
            key: "selection",
          },
        ]}
        onChange={(ranges: any) => {
          setDtr(ranges.selection);
        }}
      />
      <div className="d-flex justify-content-end ms-2">
        <button
          type="button"
          onClick={() => {
            setDropdownIsOpen(false);
          }}
          className="btn btn-danger btn-sm me-2"
        >
          Close
        </button>
        <button
          type="button"
          onClick={() => {
            onChange(dtr);
            setDropdownIsOpen(false);
          }}
          className="btn btn-primary btn-sm me-2"
        >
          Apply
        </button>
      </div>
    </DropdownButton>
  );
};

export default DateRangeDropdown;
