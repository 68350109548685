import { useQuery } from "react-query";
import { CorporateService } from "../services/corporate.service";
import { Option, isNone, isSome, none, fold, some } from "fp-ts/lib/Option";
import { isSuccess } from "@devexperts/remote-data-ts";

export const useGetBalanceAmount = () => {
  return useQuery<Option<number>, Error>(
    "getBalanceAmount",
    async () => {
      const result = await CorporateService.fetchMyBalance();
      if (isSuccess(result)) {
        if (result.value.successful) {
          // dispatch(setCompanyInfo(result.value.data));
          return some(result.value.balance || 0);
        } else {
          return none;
        }
      } else {
        return none;
      }
    },
    {
      refetchInterval: 10000, // refetch on 10 secs
    }
  );
};
