import { Button, Heading, MediumScreen, SmallScreen } from "..";

import React, { useEffect } from "react";

import { fromPublic } from "../../utils/common.utils";
import { RoutePaths } from "../../utils/route.utils";

import { useAppDispatch } from "../../hooks/hooks";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";

const Employee = fromPublic("/images/employee.svg");
const Financial = fromPublic("/images/financial.svg");
const Image9 = fromPublic("/images/bg-9.png");

const DigitalContractSuccessPageNew = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    mixpanel.track("Page load", { name: "Validate Company" });
  }, []);

  const handleSubmit = () => {
    navigate(RoutePaths.ApplicationSubmittedNew);
  };
  return (
    <div className="container p-0">
      <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <div className="row">
          <div className="col-lg-8 col-md-6 ">
            <MediumScreen>
              <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <img
                  className="img-fluid"
                  width="791"
                  height="741"
                  src={Image9}
                  alt="Mobile"
                />
              </div>
              <div className="mx-5 mt-5 textDetail">
                <Heading
                  className="mx-5 mb-3 mt-85"
                  text="Lorem ipsum"
                  variant="small"
                />

                <p className="mx-5 fw-bolder text-secondary">
                  Congratulations team for Signing up with Ncaish Platform. We
                  have taken utmost care to make your onboarding Journey Simple,
                  safe and secure.
                </p>
              </div>
            </MediumScreen>
          </div>
          <div className="mt-4 col-lg-4 col-md-6">
            <MediumScreen>
              <div className="container p-0 m-0">
                <div className="p-3 border-0 card bg-grey rounded-15">
                  <h2 className="fw-bold">Hi, Sampat</h2>
                  <p className="fw-bold">CG: GA898979Y</p>

                  <div className="text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                    <div className="container p-0 m-0">
                      <div className="p-3 bg-white border-0 shadow card rounded-15">
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <h6 className="mt-1 text-start fw-bold">
                              Congratulation!
                            </h6>
                            <p className="text-start text-secondary fw-bold ncash_fs-12">
                              Your Account Is Active Now To Make The Process
                              Smooth We Suggest You To Enable
                            </p>
                          </div>
                          <div className="p-0 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <img
                              className="img-fluid"
                              width="791"
                              height="741"
                              src={Image9}
                              alt="Mobile"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <Button
                              className="p-1 mx-auto my-auto mt-2 text-white btn-darkblue w-100 ncash_btn-greeting"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Enable Enach
                            </Button>
                          </div>
                          <div className="p-0 mt-2 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <span className="text-end ncash_font-14 text-lightblue">
                              I will do it later
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 mt-3 bg-white border-0 shadow card rounded-15">
                        <div className="row">
                          <div className="col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <h6 className=" text-start fw-bold">De'tax</h6>
                            <p className="text-start text-secondary fs-small">
                              Increase Employee Take Home Salary Without An
                              Increase In CTC. Yes! That's Possible. Start Now
                            </p>
                          </div>

                          <div className="p-0 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="p-2 rounded-15 ">
                              <img
                                className="img-fluid ncash_bg-lightpink icons w-50"
                                src={Financial}
                                alt="Employee"
                              />
                            </div>
                            <Button
                              className="p-1 mx-auto my-auto mt-2 text-white btn-darkblue w-75 ncash_btn-let-start"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Lets Start
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 mt-3 bg-white border-0 shadow card rounded-15">
                        <div className="row">
                          <div className="col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                            <h6 className="mt-1 text-start fw-bold">
                              Employee NPS
                            </h6>
                            <p className="text-start text-secondary fs-small">
                              Play A Better Role As An Employer By Managing
                              National Pension Scheme
                            </p>
                          </div>

                          <div className="p-0 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="p-2 rounded-15">
                              <img
                                className="img-fluid ncash_bg-lightblue icons w-50"
                                src={Employee}
                                alt="Employee"
                              />
                            </div>
                            <Button
                              className="p-1 mx-auto my-auto mt-2 text-white btn-darkblue w-75 ncash_btn-let-start"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Lets Start
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 ml-auto mr-auto text-center mb-25">
                        <Button type="button" onClick={handleSubmit}>
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MediumScreen>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <SmallScreen>
          <div className="container p-0 m-0">
            <div className="p-2 border-0 shadow card bg-grey">
              <h1 className="fw-bold">Hi, Sampat</h1>
              <p className="fw-bold">CG: GA898979Y</p>
              <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                <img
                  className="img-fluid"
                  width="791"
                  height="741"
                  src={Image9}
                  alt="BG"
                />
              </div>

              <div className="p-3 mt-4 mb-4 inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                <div className="p-3 bg-white border-0 card rounded-15">
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                      <h5 className="mt-1 fs-5 text-start fw-bold">
                        Congratulation!
                      </h5>
                      <p className="text-start fw-bold">
                        Your Account Is Active Now To Make The Process Smooth We
                        Suggest You To Enable
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-3 mt-3 bg-white border-0 card rounded-15">
                  <div className="row">
                    <div className="col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                      <h4 className="mt-1 fs-5 text-start fw-bold">De'tax</h4>
                      <p className="text-start">
                        {" "}
                        Increase Employee Take Home Salary Without An Increase
                        In CTC. Yes! That's Possible. Start Now
                      </p>
                    </div>

                    <div className="p-0 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <Button
                        className="p-2 mt-2 text-white btn-darkblue btn-lg w-25 ms-3 ncash_btn-let-start fw-bold fs-6"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Lets Start
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-3 mt-3 bg-white border-0 card rounded-15">
                  <div className="row">
                    <div className="col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                      <h4 className="mt-1 fs-5 text-start fw-bold">
                        Employee NPS
                      </h4>
                      <p className="text-start">
                        {" "}
                        Play A Better Role As An Employer By Managing National
                        Pension Scheme
                      </p>
                    </div>

                    <div className="p-0 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <Button
                        className="p-2 mt-2 text-white btn-darkblue btn-sm btn-start w-25 ms-3 ncash_btn-let-start fw-bold fs-6"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Lets Start
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5 ml-auto mr-auto text-center">
                <Button type="button" onClick={handleSubmit}>
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </SmallScreen>
      </div>
    </div>
  );
};

export default DigitalContractSuccessPageNew;
