import React from "react";
import { useAppSelector } from "../../hooks/hooks";
import { selectSignUp } from "../../state/slice/user.slice";
import FormikInputGroup from "../common/formik/formik-input-group/formik-input-group";

interface Props {
  setFieldValue?: any;
}
const Signup: React.FC<Props> = ({ setFieldValue }) => {

  const signUser = useAppSelector(selectSignUp);
  return (
    <>
      <div className="mb-2 ">
        <FormikInputGroup
          // value={signUser.name}
          icon="user"
          type="text"
          name="name"
          color="bg-lightpurple"
          className="form-control fs-small fw-bold input-field p-2 border-0"
          placeholder="Enter your Name"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="mb-2">
        <FormikInputGroup
          // value={signUser.email}
          icon="mail"
          type="text"
          name="email"
          color="bg-lightpink"
          className="form-control fs-small fw-bold input-field p-2 border-0"
          placeholder="Enter your E-mail"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="mb-3">
        <FormikInputGroup
          // value={signUser.mobile}
          icon="phone"
          type="text"
          name="mobile"
          maxLength={10}
          onChange={(values: any) => {

            if (values.target.value.length <= 10) {
              setFieldValue('mobile', values.target.value)
            }
          }}
          color="bg-lightblue"
          className="form-control fs-small fw-bold input-field p-2 border-0"
          placeholder="Enter your Mobile No"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
}

export default Signup;
