import { isSuccess, RemoteData } from "@devexperts/remote-data-ts";
import { Option, isNone, isSome, none, fold } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import { DetaxBatchInfo } from "../../../../../api/detax/model/detaxBatchInfo";
import { TDetaxBatchInfo } from "../../../../../models/batches.models";
import { BatchesService } from "../../../../../services/batches.service";
import { unWrapOption } from "../../../../../utils/option.utils";
import WhiteCard from "../../../../white-card/white-card";
import TableLoader from "../../common/table-loader";
import { useGetBatchDetailByBatchId } from "../hooks/transaction.hooks.";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../../state/slice/detax.slice";
import { useAppSelector } from "../../../../../hooks/hooks";
import { fetchBatchDetail } from "../txn.utils";
import { currencyFormat } from "../../../../../utils/format.utils";
import TransactionTimeline from "../../detax/transaction/transaction-timeline";
import TransactionTimelineHorizontal from "../../detax/transaction/transaction-timeline-horizontal";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const EmpCodeIcon = "/images/icons/emp_code.svg";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const EmpStatusIcon = "/images/icons/emp_status.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const AmountIcon = "/images/icons/icons/salary.svg";

const TransactionDetailCard: React.FC<{
  batchId: string;
  batchInfoOpt: Option<DetaxBatchInfo>;
}> = ({ batchId, batchInfoOpt }) => {
  if (isNone(batchInfoOpt)) {
    return <TableLoader />;
  }

  return (
    <div>
      {pipe(
        batchInfoOpt,
        fold(
          () => <div>No details found</div>,
          (data) => (
            <div className="row p-2" id="ExpenseDetails">
              <div className="col-12">
                <TransactionTimelineHorizontal stages={data.timeline?.stages} />
              </div>
              <div className="col-12 mt-3">
                <WhiteCard className="p-3">
                  <div className="row">
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={UserIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                          <p className="mb-0 f-13"> Created By </p>
                          <h5 className="font-14 mb-0">
                            {" "}
                            {data.createdBy ? data.createdBy.name || "" : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={PhoneIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Mobile No. </p>
                          <h5 className="font-14 mb-0">
                            {" "}
                            {data.createdBy ? data.createdBy.mobile || "" : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-purple rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={EmailIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> E-mail </p>
                          <h5 className="font-14 mb-0">
                            {" "}
                            {data.createdBy ? data.createdBy.email || "" : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={CalendarIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Created At </p>
                          <h5 className="font-14 mb-0">
                            {data.createdAt ? data.createdAt || "" : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={EmpCodeIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Batch Code </p>
                          <h5 className="font-14 mb-0">
                            {" "}
                            {data.batchCode ? data.batchCode || "" : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={EmpDesgIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Status </p>
                          <h5 className="font-14 mb-0 text-capitalize">
                            {" "}
                            {data.status
                              ? data.status
                                  ?.replace(/[^a-z,A-Z]/g, " ")
                                  .toLowerCase() || ""
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightred rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={EmpStatusIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Number of employee </p>
                          <h5 className="font-14 mb-0">
                            {" "}
                            {data.txnCount ? data.txnCount || 0 : 0}{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={AmountIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Amount </p>
                          <h5 className="font-14 mb-0">
                            {" "}
                            {currencyFormat(
                              data.amount ? data.amount || 0 : 0
                            )}{" "}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </WhiteCard>
                {data.paymentInfo && (
                  <WhiteCard className="p-3">
                    <div className="d-flex justify-content-between">
                      <h6 className="text-secondary">Payment Info</h6>
                    </div>
                    <div className="row">
                      <div className=" col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={AmountIcon}
                                className="img-fluid ml-auto mr-auto w-60"
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                            <p className="mb-0 f-13"> Mode </p>
                            <h5 className="font-14 mb-0">
                              {" "}
                              {data.paymentInfo
                                ? data.paymentInfo.mode || ""
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={AmountIcon}
                                className="img-fluid ml-auto mr-auto w-60"
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left">
                            <p className="mb-0 f-13">Amount </p>
                            <h5 className="font-14 mb-0">
                              {" "}
                              {data.paymentInfo
                                ? currencyFormat(data.paymentInfo.amount || 0)
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-icon rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={CalendarIcon}
                                className="img-fluid ml-auto mr-auto w-60"
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left">
                            <p className="mb-0 f-13"> Paid At </p>
                            <h5 className="font-14 mb-0">
                              {" "}
                              {data.paymentInfo
                                ? data.paymentInfo.paidAt || ""
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-3">
                        <div className="row">
                          <div className="col-2 col-md-2 text-right">
                            <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                              <img
                                src={EmpStatusIcon}
                                className="img-fluid ml-auto mr-auto w-60"
                                alt="icon"
                              />
                            </div>
                          </div>
                          <div className="col-9 col-md-9 ps-0 text-left">
                            <p className="mb-0 f-13"> Reference ID </p>
                            <h5 className="font-14 mb-0">
                              {data.paymentInfo
                                ? data.paymentInfo.referenceId || ""
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </WhiteCard>
                )}
              </div>
            </div>
          )
        )
      )}
    </div>
  );
};

export default TransactionDetailCard;
