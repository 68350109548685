import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import xlsx from "json-as-xlsx";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { EmployeePayload } from "../../../../api/employee/model/employeePayload";
import { NcaishAddressInfo } from "../../../../api/employee/model/ncaishAddressInfo";
import { SalaryConfig } from "../../../../api/employee/model/salaryConfig";
import { AdminCorporateCountInfo } from "../../../../model/adminCorporateCountInfo";
import { EmployeeService } from "../../../../services/employee.service";
import { setEmployeeMessage } from "../../../../state/slice/dashboard.slice";
import {
  getName,
  titleCase,
  validateEmail,
  validateMobile,
  validatePincode,
  validateMembershipType,
} from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import ErrorPopup from "../common/error-popup";
import NoTableData from "../common/no-table-data";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import "./employees.scss";
import BulkEmployeeModal from "../../../modal/employees/BulkEmployeeModal";
import ExcelJS, { DataValidation } from "exceljs";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";
import { useAppSelector } from "../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
const excelSchema: any = {
  "Employee Code": { prop: "code", type: String, required: true },
  "First Name": { prop: "fname", type: String, required: true },
  "Last Name": { prop: "lname", type: String, required: true },
  Email: { prop: "email", type: String, required: true },
  Mobile: { prop: "mobile", type: String, required: true },
  // "Membership (Detax/Detax+)": {
  //   prop: "membershipType",
  //   type: String,
  //   required: true,
  // },
  Gender: { prop: "gender", type: String, required: true },
  State: { prop: "state", type: String, required: true },
  City: { prop: "city", type: String, required: true },
  PinCode: { prop: "pinCode", type: String, required: true },
  Address: { prop: "address", type: String, required: true },
  "Date Of Joining (dd-MM-yyyy)": { prop: "doj", type: String, required: true },
  "Date Of Birth (dd-MM-yyyy)": { prop: "dob", type: String, required: true },
  "Basic Salary (Monthly)": {
    prop: "basicSalary",
    type: String,
    required: true,
  },
  "Flexible Allowance (Monthly)": {
    prop: "allowance",
    type: String,
    required: true,
  },
  "Total Salary (Monthly)": { prop: "salary", type: String, required: true },
  Department: { prop: "businessUnit", type: String, require: true },
  "Tax Regime": { prop: "taxRegime", type: String, require: true },
};
// same as import { EmployeePayload } from './employeePayload';
interface IPayload {
  code: string;
  doj: string;
  dob?: string;
  name: string;
  mobile: string;
  membershipType: string;
  email: string;
  salaryInfo?: SalaryConfig;
  address?: NcaishAddressInfo;
  gender?: EmployeePayload.GenderEnum | string;
  error?: string[];
  firstName: string;
  lastName: string;
  businessUnit?: string;
  taxRegime?: string;
}

interface IData {
  code: string;
  fname: string;
  lname: string;
  email: string;
  mobile: string;
  membershipType: string;
  gender: string;
  state: string;
  city: string;
  pinCode: string;
  address: string;
  doj: string;
  dob: string;
  basicSalary: string | number;
  allowance: string | number;
  salary: string | number;
  businessUnit: string;
  taxRegime: string;
  error: string[];
}

const DashboardEmployees: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [importData, setImportData] = useState<IPayload[] | []>([]);
  const [excelData, setExcelData] = useState<File[]>([]);
  const [curTbl, setCurTbl] = useState<AdminCorporateCountInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionState, setActionState] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseError, setResponseError] = useState({
    show: false,
    message: "",
  });
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [editData, setEditData] = useState<IData | null>(null);
  const [editID, setEditID] = useState<number | null>(null);
  const [department, setDepartment] = useState<BusinessUnit>();

  const newClaims = useAppSelector(AlphaReduxStore.selectNewClaimsStatus);
  console.log("Test Store", importData);
  useEffect(() => {
    dispatch(setEmployeeMessage(""));
    const run = async () => {
      const result = await EmployeeService.getBusinessUnit();
      if (isSuccess(result)) {
        if (result.value.successful) {
          console.log("test", result.value.data);
          setDepartment((prev) => (prev = result.value.data || prev));
          // result.value.data && loadEmployee(result.value.data);
        }
      }
    };
    setPaging((p) => ({ ...p, total: importData.length, page: 1, limit: 10 }));
    run();
  }, [importData.length]);

  const validateData = (data: IData[] | []) => {
    if (data.length > 0) {
      let tmp1: string[] = [""];
      let tmp2: string[] = [""];

      let newData: [] | IData[] = data.map((item) => {
        item.membershipType = "Detax";
        item = { ...item, error: [] };
        item.gender = item.gender && item.gender.toUpperCase();

        // console.log(
        //   "1-item.doj: ",
        //   item.doj,
        //   /[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/gi.test(item.doj)
        // );

        if (/[0-9]{1,2}[\/\-][0-9]{1,2}[\/\-][0-9]{4}$/gi.test(item.doj)) {
          //let arr = item.doj.split("/");
          let arr = item.doj.split(/[/-]+/);
          arr[0] = arr[0].length === 1 ? "0" + arr[0] : arr[0];
          arr[1] = arr[1].length === 1 ? "0" + arr[1] : arr[1];
          item.doj = arr.join("/");
          console.log("item.doj: ", item.doj);
        } else {
          console.log(
            "item.doj: ",
            item.doj,
            /[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/gi.test(item.doj)
          );
        }
        if (/[0-9]{1,2}[\/\-][0-9]{1,2}[\/\-][0-9]{4}$/gi.test(item.dob)) {
          //let arr = item.dob.split("/");
          let arr = item.dob.split(/[/-]+/);
          arr[0] = arr[0].length === 1 ? "0" + arr[0] : arr[0];
          arr[1] = arr[1].length === 1 ? "0" + arr[1] : arr[1];
          item.dob = arr.join("/");
          console.log("item.dob: ", item.dob);
        }

        if (!item.code) item.error = [...item.error, "code"];
        if (!item.fname || /.*\d.*/.test(item.fname))
          item.error = [...item.error, "fname"];
        if (!item.lname || /.*\d.*/.test(item.lname))
          item.error = [...item.error, "lname"];
        if (!item.email) item.error = [...item.error, "email"];
        if (!item.mobile) item.error = [...item.error, "mobile"];

        if (!item.membershipType) {
          item.error = [...item.error, "membershipType"];
          console.log(
            "In employees.tsx :: validation failed",
            item.membershipType
          );
        }
        if (!["MALE", "FEMALE", "TRANSGENDER"].includes(item.gender))
          item.error = [...item.error, "gender"];
        if (!item.state || /.*\d.*/.test(item.state))
          item.error = [...item.error, "state"];
        if (!item.city || /.*\d.*/.test(item.city))
          item.error = [...item.error, "city"];
        if (!item.pinCode) item.error = [...item.error, "pinCode"];
        if (!item.address) item.error = [...item.error, "address"];
        if (!item.doj) item.error = [...item.error, "doj"];
        if (!item.dob) item.error = [...item.error, "dob"];
        if (!item.basicSalary)
          item = {
            ...item,
            basicSalary: 0,
            error: [...item.error, "basicSalary"],
          };
        if (!item.allowance)
          item = { ...item, allowance: 0, error: [...item.error, "allowance"] };
        if (!item.salary)
          item = { ...item, salary: 0, error: [...item.error, "salary"] };

        if (!validateEmail(item.email) || tmp1.includes(item.email))
          item.error = [...item.error, "email"];
        if (!validateMobile(item.mobile) || tmp2.includes(item.mobile))
          item.error = [...item.error, "mobile"];
        if (!validateMembershipType(item.membershipType)) {
          item.error = [...item.error, "membershipType"];
          console.log(
            "In employees.tsx :: validation failed",
            item.membershipType
          );
        }
        if (!["MALE", "FEMALE", "TRANSGENDER"].includes(item.gender))
          item.error = [...item.error, "gender"];
        if (!validatePincode(item.pinCode))
          item.error = [...item.error, "pinCode"];

        if (!/[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/gi.test(item.doj))
          item.error = [...item.error, "doj"];
        if (!/[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/gi.test(item.dob))
          item.error = [...item.error, "dob"];
        if (
          !/[0-9]+/.test(item.basicSalary.toString()) ||
          parseFloat(item.basicSalary.toString()) <= 0
        )
          item.error = [...item.error, "basicSalary"];
        if (
          !/[0-9]+/.test(item.allowance.toString()) ||
          parseFloat(item.allowance.toString()) <= 0
        )
          item.error = [...item.error, "allowance"];
        if (
          !/[0-9]+/.test(item.salary.toString()) ||
          parseFloat(item.salary.toString()) <= 0
        )
          item.error = [...item.error, "salary"];
        if (!item.businessUnit) item.error = [...item.error, "businessUnit"];
        if (!["Old Regime", "New Regime"].includes(item.taxRegime))
          item.error = [...item.error, "taxRegime"];
        tmp1 = [...tmp1, item.email];
        tmp2 = [...tmp2, item.mobile];

        return item;
      });
      /* if (newData.length > 0)
        newData = newData.map(item => {
          if (item.error) {
            item.error = item.error.filter((v, i, a) => a.indexOf(v) === i)
          }
          return item
        }) */
      return newData;
    } else {
      return data;
    }
  };

  const importExcel = async () => {
    setShowError(false);
    if (excelData.length > 0) {
      let data: any[] = [];
      let rowData: any[] = [];
      let filesArr = [...excelData];

      for (let i = 0; i < filesArr.length; i++) {
        let file = filesArr[i];
        let rows = await readXlsxFile(file, {
          schema: excelSchema,
        });
        /* delete rows[0];
        rows = rows.filter((i) => i); */
        //  console.log("importExcel ::", rows);
        rowData = rows.rows;
        rowData = rowData.map((row: any) => ({ ...row, error: [] }));

        rows.errors.map((err, idx) => {
          if (
            err.error !== "required" &&
            (err.column === "Date Of Joining (dd-MM-yyyy)" ||
              err.column === "Date Of Birth (dd-MM-yyyy)")
          ) {
            let dtstr = format(new Date(err.value), "dd/MM/yyyy");
            //console.log("dtstr: ", dtstr);
            // console.log(err.row);
            if (err.column === "Date Of Joining (dd-MM-yyyy)") {
              rowData[err.row - 2] = {
                ...rowData[err.row - 2],
                doj: dtstr,
                error: [],
              };
            } else {
              rowData[err.row - 2] = {
                ...rowData[err.row - 2],
                dob: dtstr,
                error: [],
              };
            }
            //console.log(rowData[err.row - 2]);
          }
        });
      }
      console.log("rowData: ", rowData);
      rowData = validateData(rowData);

      let payload: IPayload[] | [] = [];
      rowData.map((item) => {
        payload = [
          ...payload,
          {
            code: item.code,
            name: titleCase(item.fname + " " + item.lname),
            email: item.email,
            mobile: item.mobile,
            membershipType: item.membershipType,
            gender: item.gender && item.gender.toUpperCase(),
            doj: item.doj,
            dob: item.dob,
            salaryInfo: {
              mode: "MONTHLY",
              salary: parseFloat(item.salary),
              basicSalary: parseFloat(item.basicSalary),
              allowance: parseFloat(item.allowance),
            },
            address: {
              address: item.address,
              country: "India",
              state: item.state,
              city: item.city,
              pinCode: item.pinCode,
            },
            error: item.error,
            firstName: item.fname,
            lastName: item.lname,
            businessUnit: item.businessUnit,
            taxRegime: item.taxRegime,
          },
        ];
      });

      if (payload.length == 0) setShowError(true);
      console.log("payload:  ", payload);
      setImportData(payload);
      setExcelData([]);
      setPaging((p) => ({ ...p, total: payload.length, page: 1, limit: 10 }));
    }
  };

  const tblData = (tblData: IPayload[] | []) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    // console.log("result ::", result);
    return result;
  };

  const uploadBulkEmployeesData = async () => {
    setShowError(false);
    setResponseError({ show: false, message: "" });
    setActionState(true);
    let payloadData = JSON.parse(JSON.stringify(importData));
    payloadData = payloadData.filter(
      (item: IPayload) => item.error && item.error.length === 0
    );
    payloadData.map((item: IPayload) => {
      delete item.error;
    });

    const employeesRequestData = payloadData.map((item: IPayload) => ({
      ...item,
      dob: item.dob?.replaceAll("/", "-"), // replace dd/MM/yyyy to dd-MM-yyyy
      doj: item.doj?.replaceAll("/", "-"), // replace dd/MM/yyyy to dd-MM-yyyy
      membershipType: "detax",
      taxRegime: item.taxRegime === "Old Regime" ? "OLD_REGIME" : "NEW_REGIME",
    }));

    const result = await EmployeeService.createBulk({
      data: employeesRequestData,
    });

    setActionState(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        dispatch(setEmployeeMessage(result.value.message));
        navigate(RoutePaths.EmployeesAdd);
      } else {
        setResponseError({ show: true, message: result.value.message || "" });
      }
      //
      // setImportData([]);
      // await loadData();
    }
  };

  const exportToExcel = () => {
    let cols: string[] = [
      "Employee Code",
      "First Name",
      "Last Name",
      "Email",
      "Mobile",
      // "Membership (Detax/Detax+)",
      "Gender",
      "State",
      "City",
      "PinCode",
      "Address",
      "Date Of Joining (dd-MM-yyyy)",
      "Date Of Birth (dd-MM-yyyy)",
      "Basic Salary (Monthly)",
      "Flexible Allowance (Monthly)",
      "Total Salary (Monthly)",
    ];

    let data = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content: [],
      },
    ];
    xlsx(data, { fileName: "Employees" });
  };
  const inputDate = (dt: string) => {
    // first check if date has "/" or "-" seperator
    const isSlashString = dt.includes("/");
    const isDashString = dt.includes("-");
    if (isSlashString) {
      const arr = dt.split("/");
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    }
    if (isDashString) {
      const arr = dt.split("-");
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    }
    return dt;
  };

  if (isLoading) {
    return <DashboardPageLoader />;
  }
  const taxRegime = ["Old Regime", "New Regime"];
  var taxRegimeJoins = taxRegime.join();
  const dept = department?.businessUnit || [];
  var deptJoins = dept.join();

  function generateExcelSheet() {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a new worksheet
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add data to the worksheet
    worksheet.columns = [
      { header: "Employee Code", width: 10 },
      { header: "First Name", width: 10 },
      { header: "Last Name", width: 10 },
      { header: "Email", width: 10 },
      { header: "Mobile", width: 10 },
      { header: "Gender", width: 10 },
      { header: "State", width: 10 },
      { header: "City", width: 10 },
      { header: "PinCode", width: 10 },
      { header: "Address", width: 10 },
      { header: "Date Of Joining (dd-MM-yyyy)", width: 10 },
      { header: "Date Of Birth (dd-MM-yyyy)", width: 10 },
      { header: "Basic Salary (Monthly)", width: 10 },
      { header: "Flexible Allowance (Monthly)", width: 10 },
      { header: "Total Salary (Monthly)", width: 10 },
      { header: "Department", width: 10 },
      { header: "Tax Regime", width: 10 },
    ];

    const numRows = 10000; // Change this to the number of rows you want

    // Add rows and set data validation for each row
    for (let i = 2; i <= numRows + 1; i++) {
      const dataValidation: DataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${deptJoins}"`],
      };

      worksheet.getCell(`P${i}`).dataValidation = dataValidation;
    }
    for (let i = 2; i <= numRows + 1; i++) {
      const dataValidation: DataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${taxRegime}"`],
      };

      worksheet.getCell(`Q${i}`).dataValidation = dataValidation;
    }

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Download the file
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Employees.xlsx";
      a.click();
    });
  }

  return (
    <>
      <h1 className="page-heading fw-bold"> Bulk Employee Upload</h1>
      {importData && importData && importData.length == 0 ? (
        <WhiteCard className="mt-4">
          <div className="d-flex justify-content-between">
            <div className="steps">
              <h3 className="page-heading mb-3">
                {" "}
                Step - 1
                <span>
                  {" "}
                  Download the template to fill the details of your employees in
                  the required format{" "}
                </span>
              </h3>
            </div>

            <ReuseIcon className="mb-3">
              <svg
                className="comman-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="47.54"
                height="43.884"
                viewBox="0 0 47.54 43.884"
                onClick={generateExcelSheet}
              >
                <path
                  id="download"
                  d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                  fill="#103458"
                />
              </svg>
            </ReuseIcon>
          </div>
        </WhiteCard>
      ) : null}

      <WhiteCard className="mt-4">
        <div className="steps">
          {/* <h3 className="mb-3">Uploaded Sheet</h3> */}
          {importData.length > 0 ? (
            <>
              {tblData(importData).length > 0 ? (
                <TablePagination
                  data={paging}
                  changePage={(data) =>
                    setPaging((p) => ({ ...p, page: data }))
                  }
                  changeLimit={(data) =>
                    setPaging((p) => ({ ...p, limit: data, page: 1 }))
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Employee Code</th>
                        <th scope="col">Employee Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mobile</th>
                        {/* <th scope="col">Membership</th> */}
                        <th scope="col">Gender</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">PinCode</th>
                        <th scope="col">Address</th>
                        <th scope="col">Date Of Joining</th>
                        <th scope="col">Date Of Birth</th>
                        <th scope="col">Basic Salary</th>
                        <th scope="col">Flexible Allowance</th>
                        <th scope="col">Total Salary</th>
                        <th scope="col">Department</th>
                        <th scope="col">Tax Regime</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tblData(importData).map((item, key) => (
                        <tr
                          className={classNames({
                            "error-row": item.error && item.error.length > 0,
                          })}
                          key={key}
                        >
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("code"),
                            })}
                          >
                            {item.code}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error &&
                                (item.error.includes("fname") ||
                                  item.error.includes("lname")),
                            })}
                          >
                            {item.name}{" "}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("email"),
                            })}
                          >
                            {item.email}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("mobile"),
                            })}
                          >
                            {item.mobile}
                          </td>
                          {/* <td
                            className={classNames({
                              "error-column":
                                item.error &&
                                item.error.includes("membershipType"),
                            })}
                          >
                            {item.membershipType}
                          </td> */}
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("gender"),
                            })}
                          >
                            {item.gender}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("state"),
                            })}
                          >
                            {item.address?.state || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("city"),
                            })}
                          >
                            {item.address?.city || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("pinCode"),
                            })}
                          >
                            {item.address?.pinCode || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("address"),
                            })}
                          >
                            {item.address?.address || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("doj"),
                            })}
                          >
                            {item.doj || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("dob"),
                            })}
                          >
                            {item.dob || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error &&
                                item.error.includes("basicSalary"),
                            })}
                          >
                            {item.salaryInfo?.basicSalary}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("allowance"),
                            })}
                          >
                            {item.salaryInfo?.allowance}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("salary"),
                            })}
                          >
                            {item.salaryInfo?.salary}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error &&
                                item.error.includes("businessUnit"),
                            })}
                          >
                            {item.businessUnit || ""}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("taxRegime"),
                            })}
                          >
                            {item.taxRegime || "OLD_REGIME"}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                onClick={() => {
                                  const startFrom =
                                    (paging.page - 1) * paging.limit;
                                  const keyIndex = startFrom + key;
                                  let newData = importData.filter(
                                    (i, k) => k === keyIndex
                                  )[0];
                                  let editModalData: IData = {
                                    code: newData.code || "",
                                    fname: getName(newData.name || "")[0] || "",
                                    lname: getName(newData.name || "")[1] || "",
                                    email: newData.email || "",
                                    mobile: newData.mobile || "",
                                    membershipType:
                                      newData.membershipType &&
                                      ["Detax", "Detax+"].includes(
                                        newData.membershipType
                                      )
                                        ? newData.membershipType
                                        : "Detax",
                                    gender:
                                      newData.gender &&
                                      [
                                        "MALE",
                                        "FEMALE",
                                        "TRANSGENDER",
                                      ].includes(newData.gender)
                                        ? newData.gender
                                        : "MALE",
                                    state: newData.address?.state || "",
                                    city: newData.address?.city || "",
                                    pinCode: newData.address?.pinCode || "",
                                    address: newData.address?.address || "",
                                    doj: newData.doj
                                      ? inputDate(newData.doj)
                                      : "",
                                    dob: newData.dob
                                      ? inputDate(newData.dob)
                                      : "",
                                    basicSalary:
                                      newData.salaryInfo?.basicSalary || 0,
                                    allowance:
                                      newData.salaryInfo?.allowance || 0,
                                    salary: newData.salaryInfo?.salary || 0,
                                    businessUnit: newData.businessUnit || "",
                                    taxRegime: newData.taxRegime || "",
                                    error: [],
                                  };

                                  // console.log('newKey: ', startFrom + key )
                                  // console.log('Key: ', key, paging);
                                  setEditData(editModalData);
                                  setEditID(keyIndex);
                                }}
                                className="btn btn-sm btn-danger me-2"
                              >
                                <MdModeEdit />
                              </button>
                              <button
                                onClick={() => {
                                  const startFrom =
                                    (paging.page - 1) * paging.limit;
                                  const keyIndex = startFrom + key;
                                  setImportData(
                                    importData.filter((i, k) => k !== keyIndex)
                                  );
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                <MdDelete />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TablePagination>
              ) : (
                <NoTableData />
              )}
              <div className="action"></div>
            </>
          ) : (
            <>
              <h3 className="page-heading mb-3">
                Step - 2
                <span>
                  Upload the filled in employee details sheet to enroll them in
                  the Ncaish platform
                </span>
              </h3>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  let arr: File[] = [];
                  acceptedFiles.forEach((file) => {
                    if (file.name.split(".").pop() == "xlsx")
                      arr = [...arr, file];
                  });
                  setExcelData(arr);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className="p-3 text-center my-4 mx-auto wrap-file-upload "
                    role="button"
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} accept=".xlsx" />
                      <svg
                        id="Group_2844"
                        width="75"
                        data-name="Group 2844"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 158.798 190.158"
                      >
                        <path
                          id="Path_6474"
                          data-name="Path 6474"
                          d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                          transform="translate(-157.192 -168.825)"
                          fill="#cecece"
                        />
                        <rect
                          id="Rectangle_365"
                          data-name="Rectangle 365"
                          width="158.798"
                          height="21.5"
                          rx="10.75"
                          transform="translate(0 168.659)"
                          fill="#cecece"
                        />
                      </svg>
                      <div className="w-25 px-4 mx-auto">
                        <p>Drag and drop here </p>
                        <p>or</p>
                        <p className="">Browse Files</p>
                      </div>
                      <ul className="files-list">
                        {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  </section>
                )}
              </Dropzone>

              <div className="w-25 mx-auto text-center">
                {excelData.length > 0 && (
                  <button
                    style={{ marginRight: "20px" }}
                    className="btn-ncaish-light text-white px-3 py-2"
                    onClick={() => setExcelData([])}
                  >
                    Clear
                  </button>
                )}
                {excelData.length > 0 ? (
                  <button
                    disabled={excelData.length == 0}
                    className="btn-gradiant-blue text-white w-50 px-3 py-2"
                    onClick={() => importExcel()}
                  >
                    Upload
                  </button>
                ) : null}
              </div>
            </>
          )}
        </div>
      </WhiteCard>
      {importData && importData && importData.length > 0 && (
        <div className="mb-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => setImportData([])}
          >
            Remove
          </button>
          <button
            disabled={actionState}
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => uploadBulkEmployeesData()}
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </div>
      )}
      {editID !== null && editData && (
        <BulkEmployeeModal
          data={editData}
          department={department?.businessUnit}
          setData={(data) => {
            data.doj = format(new Date(data.doj), "dd/MM/yyyy"); //  only date separator "/"" suported from excel not "-"
            data.dob = format(new Date(data.dob), "dd/MM/yyyy");
            let newData = validateData([data])[0];

            let newImportData = JSON.parse(JSON.stringify(importData));
            newImportData[editID] = {
              code: newData.code,
              doj: newData.doj,
              dob: newData.dob,
              name: newData.fname + " " + newData.lname,
              mobile: newData.mobile,
              membershipType: newData.membershipType,
              email: newData.email,
              salaryInfo: {
                mode: "MONTHLY",
                salary: parseFloat(newData.salary.toString()),
                basicSalary: parseFloat(newData.basicSalary.toString()),
                allowance: parseFloat(newData.allowance.toString()),
              },
              address: {
                address: newData.address,
                country: "India",
                state: newData.state,
                city: newData.city,
                pinCode: newData.pinCode,
              },
              gender: newData.gender,
              businessUnit: newData.businessUnit,
              taxRegime: newData.taxRegime,
              error: newData.error,
            };
            setImportData(newImportData);
            setEditData(null);
            setEditID(null);
          }}
          close={() => {
            setEditData(null);
            setEditID(null);
          }}
        />
      )}
      <ErrorPopup
        message={
          showError
            ? `Invalid data! Download the template to enrol your employees and send them invite to join`
            : responseError.message
        }
        show={showError || responseError.show}
        onClose={(flag) => {
          setShowError(flag);
          setResponseError({ show: false, message: "" });
        }}
      />
    </>
  );
};

export default DashboardEmployees;
