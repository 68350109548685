import React, { useEffect } from "react";
import PageLoader from "../../../../page-loader";
import { useStoreWithInitializer } from "../../../../state/storeHooks";
import { loadFxDashboardData } from "../../../../utils/fx.utils";
import "./fx-online.scss";
import FxMain from "./fx-main";
import FxDisclaimer from "./fx-disclaimer";
import { Spinner } from "react-bootstrap";
import { MyStorage } from "../../../../utils/local-storage.utils";
import { Helmet } from "react-helmet";

type ISection = "FX" | "DISCLAIMER" | "FX_RETAIL";
export const DashboardFxOnline: React.FC = () => {
  const { fxLoading, dashboard } = useStoreWithInitializer(
    ({ fx }) => fx,
    loadFxDashboardData
  ); // load localstorage data into redux store

  const [section, setSection] = React.useState<ISection>("FX");
  const [loading, setLoading] = React.useState(true);

  const handleSelectSection = (section: ISection) => {
    setSection(section);
  };

  const isAgreement = MyStorage.FxAgreement.LoadData() || false;

  useEffect(() => {
    const ids = document.getElementById("ssapp");
  }, []);

  if (fxLoading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Helmet>
        <title>Ncaish | FX Online</title>
      </Helmet>
      {section === "FX" && (
        <FxMain
          dashboard={dashboard}
          onStartNow={() =>
            handleSelectSection(isAgreement ? "FX_RETAIL" : "DISCLAIMER")
          }
        />
      )}

      {section === "DISCLAIMER" && (
        <FxDisclaimer
          onAgree={() => {
            MyStorage.FxAgreement.StoreData(true);
            handleSelectSection("FX_RETAIL");
          }}
        />
      )}
      {section === "FX_RETAIL" && (
        <>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              {" "}
              <PageLoader />
            </div>
          ) : null}
          <embed
            src={`${process.env.REACT_APP_FX_URL}`}
            width="100%"
            height="100%"
            style={{ height: "calc(100vh - 80px)" }}
            id="myId"
            onLoad={() => setLoading(false)}
          />
        </>
      )}
    </div>
  );
};
