import React from "react";
import { CardTransactionInfo } from "../../../../../api/detax/model/cardTransactionInfo";
import { currencyFormat } from "../../../../../utils/format.utils";
import WhiteCard from "../../../../white-card/white-card";
import { TExpense } from "./expense-card";

export type THead = {
  categoryIcon: string;
  categoryName: string;
  amount: number;
  baseAmt: number;
  transactionId: string;
  createdAt: string;
  rrn?: string;
  txnStatus?: CardTransactionInfo.TxnStatusEnum;
  scope?: CardTransactionInfo.ScopeEnum;
};

const TransactionHead: React.FC<{ head: THead; expense: TExpense }> = ({
  head,
  expense,
}) => {
  let statusCls = "text-primary";

  if (head.txnStatus === CardTransactionInfo.TxnStatusEnum.SUCCESS) {
    statusCls = "text-success";
  } else if (head.txnStatus === CardTransactionInfo.TxnStatusEnum.FAILED) {
    statusCls = "text-danger";
  }

  return (
    <WhiteCard className="p-3">
      <div className="row mx-4">
        <div className="col-2">
          <div className="border-secondary border-end">
            <h6 className="text-primary"> Category</h6>
            <h6 className="text-primary fw-bold">
              <img src={head.categoryIcon} width={40} alt="" className="px-2" />
              {head.categoryName}{" "}
            </h6>
          </div>
        </div>

        <div className="col-2">
          <div className="border-secondary border-end">
            <h6 className="text-primary"> Transaction Amount</h6>
            <h6 className="text-primary fw-bold">
              {currencyFormat(head.baseAmt)}
            </h6>
          </div>
        </div>

        <div className="col-2">
          <div className="border-secondary border-end">
            <h6 className="text-primary"> Transaction ID</h6>
            <h6 className="text-primary fw-bold">{head.rrn || ""}</h6>
          </div>
        </div>

        <div className="col-2">
          <div className="border-secondary border-end">
            <h6 className="text-primary">
              {" "}
              {expense.type == "CREDIT" ? "Credited On" : "Debited On"}
            </h6>
            <h6 className="text-primary fw-bold">{head.createdAt}</h6>
          </div>
        </div>
        <div className="col-2">
          <div className="border-secondary border-end">
            <h6 className="text-primary"> Status</h6>
            <h6 className={`${statusCls} fw-bold`}>{head.txnStatus}</h6>
          </div>
        </div>
        {expense.type === CardTransactionInfo.TypeEnum.CREDIT ? null : (
          <div className="col-2">
            <div className="border-secondary">
              <h6 className="text-primary"> Scope</h6>
              <h6 className={`text-primary fw-bold`}>{head.scope}</h6>
            </div>
          </div>
        )}
      </div>
    </WhiteCard>
  );
};

export default TransactionHead;
