import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { BatchesService } from "../../../../services/batches.service";
import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../state/slice/detax.slice";
import WhiteCard from "../../../white-card/white-card";
import CreateBatchTabs, { TabTypeKind } from "./create-batch-tab";
import EligibleUserTable from "./eligible-user-table";
import SearchBox from "./search-box";
import SelectedEligibleUsers from "./selected-eligible-users";
interface Option {
  label: string;
  option: string;
}
const CreateBatch: React.FC = () => {
  const filter = useAppSelector(DetaxReduxStore.selectFilter);
  const tabType = useAppSelector(DetaxReduxStore.selectTabType);
  const [selectUser, setSelectUser] = useState("");
  const [search, setSearch] = useState<string>("");
  const handleUserSelection = (event: any) => {
    setSelectUser(event.target.value);
  };
  const [selected, setSelected] = useState<TEligibleUserinfo[]>([]);
  // OPTION VALUE FOR THE DATE
  const [monthOptions, setMonthOptions] = useState<string[]>([]);
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Assuming financial year starts in April
    const startFinancialYear = 3; // April

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let monthYearOptions = [];

    if (
      new Date(currentYear, startFinancialYear).getTime() <=
      currentDate.getTime()
    ) {
      for (let m = startFinancialYear; m <= currentMonth; m++) {
        monthYearOptions.push(`${months[m]} ${currentYear}`);
      }
    } else {
      for (let m = startFinancialYear; m < 12; m++) {
        monthYearOptions.push(`${months[m]} ${currentYear - 1}`);
      }
      for (let m = 0; m <= currentMonth; m++) {
        monthYearOptions.push(`${months[m]} ${currentYear}`);
      }
    }
    const transformedArray = monthYearOptions.map((obj) => {
      return {
        label: obj,
        option: obj,
      };
    });
    // setMonthOptions(transformedArray);
    setMonthOptions(monthYearOptions);
    setSelectUser(monthYearOptions[monthYearOptions.length - 1]);
  }, []);

  let monthYear = selectUser.split(" ");

  useEffect(() => {
    const run = async () => {
      await BatchesService.loadEligibleUser(
        filter.type,
        "",
        monthYear[0],
        monthYear[1]
      );
    };
    if (monthYear[0].length > 0) {
      run();
    }
  }, [filter.type, monthYear[0]]);

  useEffect(() => {
    DetaxDispatch.resetIsAllUserSelected();
  }, []);

  const handleSearchUser = async (keyword: string) => {
    await BatchesService.loadEligibleUser(
      filter.type,
      keyword,
      monthYear[0],
      monthYear[1]
    );
  };
  return (
    <div>
      <WhiteCard className="mt-2">
        <CreateBatchTabs />

        {tabType === TabTypeKind.SelectUser && (
          <>
            <SearchBox
              placeHolder="Search by Employee Name"
              onChange={handleSearchUser}
              search={search}
              setSearch={(val: string) => setSearch(val)}
            />
            {activeTab === DetaxTypeKind.NPS && (
              <div
                className="flex flex-end"
                style={{
                  // border: "1px solid black",
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Select options={monthOptions} className="align-self-stretch" /> */}
                <select
                  className="w-15 py-1 px-1 mt-2 mb-2 justify-content-end"
                  defaultValue={""}
                  onChange={handleUserSelection}
                  value={selectUser}
                  style={{
                    appearance: "auto",
                    border: "auto",
                    background: "initial",
                    padding: "initial",
                    // Add any other properties you need to reset
                  }}
                  // // onChange={handleUserSelection}
                  // onChange={(e) => {
                  //   if (e.target.value.length === 2) {
                  //     if (e.target.value.charAt(0) === "0") {
                  //       e.target.value = e.target.value.substring(1);
                  //     }
                  //   }
                  //   handlecboChange(index, String(e.target.value), item);
                  // }}
                >
                  {/* <option value="This month">This month</option> */}
                  {monthOptions.map((monthYear) => (
                    <option key={monthYear} value={monthYear}>
                      {monthYear}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <EligibleUserTable
              search={search}
              selectUser={selectUser}
              selected={selected}
              setSelected={(val: TEligibleUserinfo[]) => setSelected(val)}
              month={monthYear[0]}
            />
          </>
        )}

        {tabType === TabTypeKind.ReviewUser && (
          <SelectedEligibleUsers
            selectUser={selectUser}
            selected={selected}
            setSelected={(val: TEligibleUserinfo[]) => setSelected(val)}
          />
        )}
      </WhiteCard>
    </div>
  );
};

export default CreateBatch;
