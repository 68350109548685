import React, { useState } from "react";
import { DetaxBatchCountInfo } from "../../../../api/detax/model/detaxBatchCountInfo";
import { TransactionInfo } from "../../../../model/dashboard/transactionInfo";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { loadDashboardData } from "../../../../utils/dashboard.utils";
import HomeTrxHorizontalTabs from "../../../horizontal-tabs/home-transaction-tabs";
import WhiteCard from "../../../white-card/white-card";
import TransactionChart from "./transaction-chart";
import TrxStatusCount from "./transaction-status-count";

const DashboardTransaction: React.FC<{
  data?: TransactionInfo;
  monthlyTransactions: { [key: string]: number } | null;
  fba?: DetaxBatchCountInfo[] | [];
  ta?: DetaxBatchCountInfo[] | [];
  nps?: DetaxBatchCountInfo[] | [];
  ea?: DetaxBatchCountInfo[] | [];
  gc?: DetaxBatchCountInfo[] | [];
}> = ({
  fba = [],
  ta = [],
  nps = [],
  gc = [],
  ea = [],
  monthlyTransactions,
}) => {
  const [activeTrxTab, setActiveTrxTab] = useState("fba");

  return (
    <WhiteCard>
      <h4 className="page-sub-heading"> Transactions</h4>
      <div className="col-md-12">
        <TransactionChart
          monthlyTransactions={monthlyTransactions}
          height={350}
        />
      </div>
      {/* <div onClick={async () => await loadDashboardData()}>
        <HomeTrxHorizontalTabs
          isNPS={false}
          tab={activeTrxTab}
          setTab={(tab) => setActiveTrxTab(tab || "")}
        />
      </div>

      {activeTrxTab === "fba" && (
        <TrxStatusCount tab={DetaxTypeKind.FBA} counts={fba} />
      )}
      {activeTrxTab === "ta" && (
        <TrxStatusCount tab={DetaxTypeKind.TA} counts={ta} />
      )}
      {activeTrxTab === "nps" && (
        <TrxStatusCount tab={DetaxTypeKind.NPS} counts={nps} />
      )} */}
      {/* <div className="count-boxes">
        <div className="count-box">
          <h4>{data.allTransactions}</h4>
          <div className="text-muted">
            <small>All Transaction</small>
          </div>
        </div>
        <div className="count-box">
          <h4>{data.batchCreated}</h4>
          <div className="text-muted">
            <small>Batch Created</small>
          </div>
        </div>
        <div className="count-box">
          <h4>{data.confPending}</h4>
          <div className="text-muted">
            <small>Conf. Pending</small>
          </div>
        </div>
        <div className="count-box">
          <h4>{data.paymentReceived}</h4>
          <div className="text-muted">
            <small>Payment Received</small>
          </div>
        </div>
        <div className="count-box">
          <h4>{data.subProcessed}</h4>
          <div className="text-muted">
            <small>Sub. Processed</small>
          </div>
        </div>
        <div className="count-box">
          <h4>{data.subOnHold}</h4>
          <div className="text-muted">
            <small>Sub. on Hold</small>
          </div>
        </div>
      </div> */}
    </WhiteCard>
  );
};

export default DashboardTransaction;
