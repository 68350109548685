import { isSuccess } from "@devexperts/remote-data-ts";
import { none } from "fp-ts/lib/Option";
import { InitiateDetaxBatchApiRequest } from "../../../../api/detax/model/initiateDetaxBatchApiRequest";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { BatchesService } from "../../../../services/batches.service";
import { Dispatch } from "../../../../state/dispatch";
import { DetaxDispatch } from "../../../../state/slice/detax.slice";
import { unWrapOption } from "../../../../utils/option.utils";

export const getTypeKind = (type: string): DetaxTypeKind => {
  switch (type) {
    case "FBA":
      return DetaxTypeKind.FBA;
    case "TA":
      return DetaxTypeKind.TA;
    case "EA":
      return DetaxTypeKind.EA;
    case "NPS":
      return DetaxTypeKind.NPS;
    case "GC":
      return DetaxTypeKind.GC;
    default:
      return DetaxTypeKind.FBA;
  }
};
let batchName = DetaxTypeKind.FBA;

export type TTab = { tabLabel: string; tabValue: DetaxTypeKind };

export const tabData: TTab[] = [
  { tabLabel: "De'tax FBA", tabValue: DetaxTypeKind.FBA },
  { tabLabel: "De'tax Claim", tabValue: DetaxTypeKind.TA },
  { tabLabel: "Expense Advance", tabValue: DetaxTypeKind.EA },
  { tabLabel: "NPS", tabValue: DetaxTypeKind.NPS },
  { tabLabel: "Employee Rewards", tabValue: DetaxTypeKind.GC },
];

export const getTabLabel = (tabValue: DetaxTypeKind): string => {
  const tab = tabData.find((tab) => tab.tabValue === tabValue);
  if (tab?.tabValue) {
    batchName = tab?.tabValue;
  }
  return tab ? tab.tabLabel : "";
};

export const fetchBatchDetail = async (batchId: string) => {
  //DetaxDispatch.setBatchInfo(none);
  Dispatch.App.setCommonError({
    showError: false,
    errorText: "",
  });

  const result =
    batchName === "NPS"
      ? await BatchesService.getCorporateBatchDetailByBatchIdNPS(batchId)
      : await BatchesService.getCorporateBatchDetailByBatchId(batchId);
  // const result = await BatchesService.getCorporateBatchDetailByBatchId(batchId);
  // console.log("api-request-BatchInfo", result)
  if (isSuccess(result)) {
    if (unWrapOption(result.value.successful, () => false) === true) {
      DetaxDispatch.setBatchInfo(result.value.data);
    } else {
      Dispatch.App.setCommonError({
        showError: true,
        errorText: unWrapOption(result.value.message, () => ""),
      });
    }
  } else {
    Dispatch.App.setCommonError({
      showError: true,
      errorText: "Something went wrong!",
    });
  }
};

export const selectedUsers = (users: TEligibleUserinfo[]) =>
  users.filter((user) => user.isChecked);

export const getModeOfPayment = (
  mode: string
): InitiateDetaxBatchApiRequest.ModeEnum => {
  if (mode === "IMPS") {
    return InitiateDetaxBatchApiRequest.ModeEnum.IMPS;
  } else if (mode === "RTGS") {
    return InitiateDetaxBatchApiRequest.ModeEnum.RTGS;
  }
  return InitiateDetaxBatchApiRequest.ModeEnum.NEFT;
};
