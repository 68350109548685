/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { NcaishDetaxFbaUtilisationReportApiResponse } from "../model/ncaishDetaxFbaUtilisationReportApiResponse";
import { BaseResponse } from "../model/baseResponse";
import { Download } from "../../../state/slice/reports.slice";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishDetaxReportApiService {
  /**
   *
   * @param pn -
   * @param rc -
   * @param uid -
   * @param from -
   * @param to -
   */
  export const getFbaUtilisationReport = async (
    pn: number,
    rc: number,
    uid?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<
    RemoteData<APIError, NcaishDetaxFbaUtilisationReportApiResponse>
  > => {
    const path = `/secure/detax/reports/fbaUtilisation`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   * @param from -
   * @param to -
   */
  export const getFbaUtilisationReportDownload = async (
    uid?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/detax/reports/fbaUtilisation/download`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getFbaUtilisationReportDownloadBlob = async (
    uid?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, Blob>> => {
    const path = `/secure/detax/reports/fbaUtilisation/download`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getFormReport = async (
    formType?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/detax/reports/fba-form-report/download`.replace(
      /^\//,

      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "formType",

        value: formType,
      },
      {
        key: "from",

        value: from,
      },
      {
        key: "to",

        value: to,
      },
    ];

    const queryParams = allQueryParams

      .map((param) => `${param.key}=${param.value}`)

      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,

      method: "get",

      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getFormReportBlob = async (
    formType?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, Blob>> => {
    const path = `/secure/detax/reports/fba-form-report/download`.replace(
      /^\//,

      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "formType",

        value: formType,
      },
      {
        key: "from",

        value: from,
      },
      {
        key: "to",

        value: to,
      },
    ];

    const queryParams = allQueryParams

      .map((param) => `${param.key}=${param.value}`)

      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);

    let headers = customHeaders;

    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,

      method: "get",

      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getFbaCategoryReportDownload = async (
    uid?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, Blob>> => {
    const path =
      `/secure/detax/reports/fbaUtilisationCategory-wise/download`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getReportStatus = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, Download>> => {
    const path = `/secure/detax/reports/report-thread/status`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getTransactionReportDownload = async (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: string,
    to: string,
    typestr: string,

    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ArrayBuffer>> => {
    const path = `/secure/detax/card/transactions/filter`.replace(/^\//, "");

    // var arr = [{ key: "type", value: "DEBIT" }];
    var arr = [];

    if (typestr.length > 0) {
      arr.push({
        key: "type",
        value: typestr,
      });
    }

    if (uidstr.length > 0) {
      arr.push({
        key: "uid",
        value: uidstr,
      });
    }

    if (walletstr.length > 0) {
      arr.push({
        key: "wallet",
        value: walletstr,
      });
    }
    if (typestr !== "" && !typestr.includes("CREDIT")) {
      if (categorystr.length > 0) {
        arr.push({
          key: "catId",
          value: categorystr,
        });
      }
      if (approvalstr.length > 0) {
        arr.push({
          key: "appStatus",
          value: approvalstr,
        });
      }
    }
    // else {
    //   arr.push({
    //     key: "appStatus",
    //     value:
    //       "BILL_UPLOAD_PENDING,APPROVAL_PENDING,REVIEW_PENDING,APPROVED,REJECTED,FAILED",
    //   });
    // }

    if (from.length > 0) {
      arr.push({
        key: "from",
        value: from,
      });
    }

    if (to.length > 0) {
      arr.push({
        key: "to",
        value: to,
      });
    }
    const allQueryParams: TQueryParam[] = arr;
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "uid",
    //     value: uidstr,
    //   }, {
    //     key: "catId",
    //     value: categorystr,
    //   }, {
    //     key: "wallet",
    //     value: walletstr,
    //   },
    //   {
    //     key: "appStatus",
    //     value: approvalstr,
    //   },
    //   {
    //     key: "from",
    //     value: from,
    //   },
    //   {
    //     key: "to",
    //     value: to,
    //   },

    //   {
    //     key: "type",
    //     value: "DEBIT",
    //   },
    // ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
      responseType: "arraybuffer",
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getTransactionReportDownloadCsv = async (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: string,
    to: string,
    typestr: string,

    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ArrayBuffer>> => {
    const path = `/secure/detax/reports/transaction-csv/download`.replace(
      /^\//,
      ""
    );

    // var arr = [{ key: "type", value: "DEBIT" }];
    var arr = [];

    if (typestr.length > 0) {
      arr.push({
        key: "type",
        value: typestr,
      });
    }

    if (uidstr.length > 0) {
      arr.push({
        key: "uid",
        value: uidstr,
      });
    }

    if (walletstr.length > 0) {
      arr.push({
        key: "wallet",
        value: walletstr,
      });
    }
    if (typestr !== "" && !typestr.includes("CREDIT")) {
      if (categorystr.length > 0) {
        arr.push({
          key: "catId",
          value: categorystr,
        });
      }
      if (approvalstr.length > 0) {
        arr.push({
          key: "appStatus",
          value: approvalstr,
        });
      }
    }
    // else {
    //   arr.push({
    //     key: "appStatus",
    //     value:
    //       "BILL_UPLOAD_PENDING,APPROVAL_PENDING,REVIEW_PENDING,APPROVED,REJECTED,FAILED",
    //   });
    // }

    if (from.length > 0) {
      arr.push({
        key: "from",
        value: from,
      });
    }

    if (to.length > 0) {
      arr.push({
        key: "to",
        value: to,
      });
    }
    const allQueryParams: TQueryParam[] = arr;
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "uid",
    //     value: uidstr,
    //   }, {
    //     key: "catId",
    //     value: categorystr,
    //   }, {
    //     key: "wallet",
    //     value: walletstr,
    //   },
    //   {
    //     key: "appStatus",
    //     value: approvalstr,
    //   },
    //   {
    //     key: "from",
    //     value: from,
    //   },
    //   {
    //     key: "to",
    //     value: to,
    //   },

    //   {
    //     key: "type",
    //     value: "DEBIT",
    //   },
    // ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
      responseType: "arraybuffer",
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
