import { useState } from "react";
import { pipe } from "fp-ts/lib/function";
import { fold, Option } from "fp-ts/lib/Option";
import { fromPublic } from "../../../../../utils/common.utils";
import ReactFlagsSelect from "react-flags-select";
import {
  CountryToCurrecyCode,
  CurrencyToCountryCode,
  customCurrencyList,
} from "../../../../../config/countries.data";

const ExchangeIcon = fromPublic("/images/icons/arrows-exchange.svg");

const CurrencySelectLabel = ({ label = "" }) => (
  <label className="font-14 fw-bold">{label}</label>
);

const CurrencySelectField: React.FC<{
  currencies: Array<{ code: string; name: string; flag: string }>;
  selectedCurrency: string;
  className?: string;
  onSelect?: (value: string) => void;
}> = ({
  currencies,
  selectedCurrency,
  className,
  onSelect = (value: string) => { },
}) => {
    return (
      <>
        {/* <select
        className={`${className}`}
        value={selectedCurrency}
        onChange={(e) => onSelect(e.target.value)}
      >
        {currencies.map((currency) => (
          <option key={currency.code} value={currency.code}>
            {`${currency.flag} ${currency.name}`}
          </option>
        ))}
      </select> */}
        <ReactFlagsSelect
          className={`${className} p-0`}
          selected={CurrencyToCountryCode[selectedCurrency]}
          onSelect={(code) => onSelect(CountryToCurrecyCode[code])}
          countries={currencies.map(
            (currency) => CurrencyToCountryCode[currency.code]
          )}
          customLabels={customCurrencyList(currencies)}
        />
      </>
    );
  };

const SwapCurrency: React.FC<{
  amount?: Option<number>;
  className?: string;
  onSwap?: () => void;
}> = ({ className, amount, onSwap = () => { } }) => {
  return (
    <>
      <button className={className} type="button" onClick={onSwap}>
        <img src={ExchangeIcon} className="img-fluid" alt="" width="" />
      </button>
    </>
  );
};

const CurrencySelectAmount: React.FC<{
  amount: Option<number>;
  onChange?: (value: string) => void;
  readOnly?: boolean;
}> = ({ amount, onChange = (value: string) => { }, readOnly }) => {
  const value = pipe(
    amount,
    fold(
      () => "",
      (v) => v.toString()
    )
  );
  return (
    <input
      type="number"
      value={value}
      className="form-control p-2 font-14"
      placeholder=""
      onChange={(e) => {
        onChange(e.target.value);
      }}
      readOnly={readOnly}
    />
  );
};

export {
  CurrencySelectField,
  CurrencySelectLabel,
  SwapCurrency,
  CurrencySelectAmount,
};
