/**
 * Auth Server API
 * Apis to perform login / sign up and authentication stuff across the system
 *
 * OpenAPI spec version: 1.6.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../errors";

//@ts-ignore
//import * as models from '../model/models';
import { AuthResponse } from "../../model/auth/authResponse";
import { LoginRequest } from "../../model/auth/loginRequest";
import { LoginResponse } from "../../model/auth/loginResponse";
import { VerifyLoginApiRequest } from "../../model/auth/verifyLoginApiRequest";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserLoginApiService {
  /**
   *
   * @param body -
   */
  export const login = async (
    body: LoginRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, LoginResponse>> => {
    const path = `/auth/login`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param body -
   */
  export const verifyLogin = async (
    body: VerifyLoginApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AuthResponse>> => {
    const path = `/auth/login/verify`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new UserLoginApiService()
