import { isSuccess, remoteData } from "@devexperts/remote-data-ts";
import { fromNullable, isSome, none, Option } from "fp-ts/lib/Option";

import { NcaishTransactionBatchApiService } from "../api/detax/api/ncaishTransactionBatchApi.service";
import { BaseResponse } from "../api/detax/model/baseResponse";
import { CreateTransactionBatchRequest } from "../api/detax/model/createTransactionBatchRequest";
import { DetaxAdminBatchDetailResponse } from "../api/detax/model/detaxAdminBatchDetailResponse";
import { DetaxBatchAddNewMemberRequest } from "../api/detax/model/detaxBatchAddNewMemberRequest";
import { DetaxBatchCountApiResponse } from "../api/detax/model/detaxBatchCountApiResponse";
import { DetaxBatchCountInfo } from "../api/detax/model/detaxBatchCountInfo";
import { DetaxBatchInfo } from "../api/detax/model/detaxBatchInfo";
import { DetaxBatchMember } from "../api/detax/model/detaxBatchMember";
import {
  DetaxCardBatchMemberInfo,
  NpsBatchMemberInfo,
} from "../api/detax/model/detaxCardBatchMemberInfo";
import { DetaxCardRechargeEligibleUsersResponse } from "../api/detax/model/detaxCardRechargeEligibleUsersResponse";
import { DetaxTransactionBatchDetailResponse } from "../api/detax/model/detaxTransactionBatchDetailResponse";
import { DetaxTransactionBatcheListResponse } from "../api/detax/model/detaxTransactionBatcheListResponse";
import { GetDetaxTransactionBatchMembersResponse } from "../api/detax/model/getDetaxTransactionBatchMembersResponse";
import { InitiateDetaxBatchApiRequest } from "../api/detax/model/initiateDetaxBatchApiRequest";
import { NpsRechargeEligibleUsersResponse } from "../api/detax/model/npsRechargeEligibleUsersResponse";
import {
  TDetaxBatchCountApiResponse,
  TDetaxBatchCountPage,
  TDetaxBatchInfo,
  TDetaxCardBatchMemberInfo,
  TDetaxCardRechargeEligibleUsersResponse,
  TDetaxTransactionBatchDetailResponse,
  TDetaxTransactionBatcheListResponse,
  TEligibleUserinfo,
  TGetDetaxTransactionBatchMembersResponse,
  TNpsBatchMemberInfo,
} from "../models/batches.models";
import { DetaxTypeKind } from "../models/detax-type-kind";
import { DetaxDispatch } from "../state/slice/detax.slice";
import { store } from "../state/store";
import { UserPermissionResponse } from "../api/detax/model/userPermissionResponse";

export namespace BatchesService {
  // this will call on load of the upload TXN page
  export const getDetaxBatchCounts = async (type: DetaxTypeKind) => {
    const result =
      type === "NPS"
        ? await NcaishTransactionBatchApiService.getCorporateDetaxBatchCountsNPS(
            type.toString()
          )
        : await NcaishTransactionBatchApiService.getCorporateDetaxBatchCounts(
            type.toString()
          );

    // const result =
    //   await NcaishTransactionBatchApiService.getCorporateDetaxBatchCounts(
    //     type.toString()
    //   );
    return remoteData.map(
      result,
      (response: DetaxBatchCountApiResponse): TDetaxBatchCountApiResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  // get upload txn table data by type and status
  export const getCorporateBatchByStatus = async ({
    type,
    batchStatus = "ALL",
    pn,
    rc,
  }: {
    type: DetaxTypeKind;
    batchStatus?: string;
    pn?: number;
    rc?: number;
  }) => {
    const status = batchStatus.toLowerCase() === "all" ? "" : batchStatus;
    const result =
      type === "NPS"
        ? await NcaishTransactionBatchApiService.getCorporateBatchByStatusNPS(
            status,
            pn,
            rc
          )
        : await NcaishTransactionBatchApiService.getCorporateBatchByStatus(
            type.toString(),
            status,
            pn,
            rc
          );

    // const result =
    //   await NcaishTransactionBatchApiService.getCorporateBatchByStatus(
    //     type.toString(),
    //     status
    //   );

    return remoteData.map(
      result,
      (
        response: DetaxTransactionBatcheListResponse
      ): TDetaxTransactionBatcheListResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
          pageCount: fromNullable(response.pageCount),
          recordCount: fromNullable(response.recordCount),
        };
      }
    );
  };

  //TEligibleUserinfo
  export const getFBAEligibleUsers = async () => {
    console.log("In getFBAEligibleUsers");
    const result = await NcaishTransactionBatchApiService.getFbaEligibleUsers(
      ""
    );

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                pranNo: none,
                cardNo: none,
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                cboCode: none,
                lastTransaction: fromNullable(d.lastTransaction),
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getTAEligibleUsers = async () => {
    const result =
      await NcaishTransactionBatchApiService.getTaReimbursementEligibleUsers(
        ""
      );

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                pranNo: none,
                cardNo: none,
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                cboCode: none,
                lastTransaction: none,
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getEAEligibleUsers = async () => {
    console.log("Inside getEAEligibleUsers");
    const result =
      await NcaishTransactionBatchApiService.getExpenseAdvanceEligibleUsers("");

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                pranNo: none,
                cardNo: none,
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                cboCode: none,
                lastTransaction: none,
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getNPSEligibleUsers = async () => {
    const result =
      await NcaishTransactionBatchApiService.npsRechargeEligibleUsers(
        "",
        "March",
        "2019"
      );

    return remoteData.map(
      result,
      (response: NpsRechargeEligibleUsersResponse): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                pranNo: fromNullable(d.pran),
                cardNo: none,
                limit: none,
                cboCode: fromNullable(d.cbo),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                lastTransaction: fromNullable(d.lastTransaction),
                amountToAdd: fromNullable(d.amountToAdd),
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  // FBA user search
  export const searchFBAEligibleUsers = async (keyword: string) => {
    const result = await NcaishTransactionBatchApiService.getFbaEligibleUsers(
      keyword
    );

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                pranNo: none,
                cardNo: fromNullable(d.cardNo),
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                cboCode: none,
                lastTransaction: none,
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getDownloadTemplate = async () => {
    const result = await NcaishTransactionBatchApiService.getDownloadTemplate();
    return remoteData.map(result, (response: ArrayBuffer) => response);
  };

  // TA user search
  export const searchTAEligibleUsers = async (keyword: string) => {
    const result =
      await NcaishTransactionBatchApiService.getTaReimbursementEligibleUsers(
        keyword
      );

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                pranNo: none,
                cardNo: fromNullable(d.cardNo),
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(d.amountToAdd),
                cboCode: none,
                lastTransaction: fromNullable(d.lastTransaction),
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  // NPS user search
  export const searchNPSEligibleUsers = async (
    keyword: string,
    month: string,
    year: string
  ) => {
    const result =
      await NcaishTransactionBatchApiService.npsRechargeEligibleUsers(
        keyword,
        month,
        year
      );

    return remoteData.map(
      result,
      (response: NpsRechargeEligibleUsersResponse): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                pranNo: fromNullable(d.pran),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                cardNo: none,
                limit: none,
                amountToAdd: fromNullable(d.amountToAdd),
                cboCode: fromNullable(d.contributionType),
                lastTransaction: fromNullable(d.lastTransaction),
                isChecked: false,
                arrearRemark: fromNullable(d.arrearRemarks),
                month: fromNullable(d.month),
                year: fromNullable(d.year),
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const searchEAEligibleUsers = async (keyword: string) => {
    //assuming fba eligible users will be eligible for expense advance
    const result = await NcaishTransactionBatchApiService.getFbaEligibleUsers(
      keyword
    );

    return remoteData.map(
      result,
      (
        response: DetaxCardRechargeEligibleUsersResponse
      ): TEligibleUserinfo[] => {
        const users: TEligibleUserinfo[] = response.successful
          ? response.data
            ? response.data.map((d) => ({
                uid: fromNullable(d.uid),
                userCode: fromNullable(d.userCode),
                name: fromNullable(d.name),
                email: fromNullable(d.email),
                mobile: fromNullable(d.mobile),
                npsAmount: fromNullable(d.npsAmount),
                totalCharges: fromNullable(d.totalCharges),
                pranNo: none,
                cardNo: fromNullable(d.cardNo),
                limit: fromNullable(d.limit),
                amountToAdd: fromNullable(0),
                cboCode: none,
                lastTransaction: none,
                isChecked: false,
                arrearRemark: none,
                month: none,
                year: none,
                rewardName: fromNullable(d.rewardName),
                rewardMessage: fromNullable(d.rewardMessage),
                rewardAmount: fromNullable(d.rewardAmount),
              }))
            : []
          : [];

        return users;
      }
    );
  };

  export const getEligibleUsers = async (type: DetaxTypeKind) => {
    console.log("BatchesService :: getEligibleUsers ::", type);
    if (type === DetaxTypeKind.NPS) {
      return getNPSEligibleUsers();
    }

    if (type === DetaxTypeKind.TA) {
      return getTAEligibleUsers();
    }

    if (type === DetaxTypeKind.EA) {
      console.log("EA");
      return getEAEligibleUsers();
    }

    return getFBAEligibleUsers();
  };

  export const searchEligibleUsers = async (
    type: DetaxTypeKind,
    keyword: string,
    month: string,
    year: string
  ) => {
    if (type === DetaxTypeKind.NPS) {
      return searchNPSEligibleUsers(keyword, month, year);
    }

    if (type === DetaxTypeKind.TA) {
      return searchTAEligibleUsers(keyword);
    }

    if (type === DetaxTypeKind.EA) {
      return searchEAEligibleUsers(keyword);
    }

    return searchFBAEligibleUsers(keyword);
  };

  export const createTransactionBatch = async (
    req: Required<CreateTransactionBatchRequest>
  ) => {
    const result =
      await NcaishTransactionBatchApiService.createTransactionBatch({
        type: req.type,
        data: req.data,
      });
    return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
  };

  export const createTransactionBatchNps = async (
    req: Required<CreateTransactionBatchRequest>
  ) => {
    const result =
      await NcaishTransactionBatchApiService.createTransactionBatchNps({
        type: req.type,
        data: req.data,
      });
    return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
  };

  export const getCorporateBatchDetailByBatchId = async (batchId: string) => {
    const result =
      await NcaishTransactionBatchApiService.getBatchByCorporateAndId(batchId);
    return remoteData.map(
      result,
      (
        response: DetaxTransactionBatchDetailResponse
      ): TDetaxTransactionBatchDetailResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  // IF THE BATCH DETAILS FROM NPS
  export const getCorporateBatchDetailByBatchIdNPS = async (
    batchId: string
  ) => {
    const result =
      await NcaishTransactionBatchApiService.getBatchByCorporateAndIdNPS(
        batchId
      );
    return remoteData.map(
      result,
      (
        response: DetaxTransactionBatchDetailResponse
      ): TDetaxTransactionBatchDetailResponse => {
        return {
          successful: fromNullable(response.successful),
          message: fromNullable(response.message),
          data: fromNullable(response.data),
        };
      }
    );
  };

  //getCorporateTransactionBatchMembers  Show Transaction details employees
  export const getCorporateBatchEmployeesByBatchId = async (
    batchId: string
  ) => {
    const result =
      await NcaishTransactionBatchApiService.getCorporateTransactionBatchMembers(
        batchId
      );
    return remoteData.map(result, parseDetaxBatchMemberResponse);
  };
  export const getCorporateBatchEmployeesByBatchIdNPS = async (
    batchId: string
  ) => {
    const result =
      await NcaishTransactionBatchApiService.getCorporateTransactionBatchMembersNPS(
        batchId
      );
    return remoteData.map(result, parseDetaxBatchMemberResponse);
  };

  export const userPermissionCorporate = async () => {
    const result =
      await NcaishTransactionBatchApiService.userPermissionCorporate(); //  .myClaimCounts(from, to);
    return remoteData.map(
      result,
      (response: UserPermissionResponse) => response
    );
  };

  // Initiate Batch Transaction
  export const initiateTransaction = async (req: {
    amount: number;
    mode: InitiateDetaxBatchApiRequest.ModeEnum;
    time: string;
    refId: string;
    batchId: string;
  }) =>
    //req: Required<InitiateDetaxBatchApiRequest>,
    // batchId: string
    {
      const result = await NcaishTransactionBatchApiService.initiatebatch(
        {
          amount: req.amount,
          time: req.time,
          refId: req.refId,
          mode: req.mode,
        },
        req.batchId
      );
      return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
    };

  export const initiateNpsTransaction = async (req: {
    amount: number;
    mode: InitiateDetaxBatchApiRequest.ModeEnum;
    time: string;
    refId: string;
    batchId: string;
  }) =>
    //req: Required<InitiateDetaxBatchApiRequest>,
    // batchId: string
    {
      const result = await NcaishTransactionBatchApiService.initiateNpsbatch(
        {
          amount: req.amount,
          time: req.time,
          refId: req.refId,
          mode: req.mode,
        },
        req.batchId
      );
      return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
    };

  export const cancelBatch = async (batchId: string, remarks: string = "") => {
    const result =
      await NcaishTransactionBatchApiService.cancelBatchByCorporate(
        { remarks },
        batchId
      );
    return remoteData.map(result, (response: BaseResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
    }));
  };

  export const cancelNpsBatch = async (
    batchId: string,
    remarks: string = ""
  ) => {
    const result =
      await NcaishTransactionBatchApiService.cancelNpsBatchByCorporate(
        { remarks },
        batchId
      );
    return remoteData.map(result, (response: BaseResponse) => ({
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
    }));
  };

  export const executeBatch = async (req: {
    // amount: number;
    // mode: InitiateDetaxBatchApiRequest.ModeEnum;
    // time: number;
    // refId: string;
    batchId: string;
  }) =>
    //req: Required<InitiateDetaxBatchApiRequest>,
    // batchId: string
    {
      const result = await NcaishTransactionBatchApiService.executeBatch(
        // {
        //   amount: req.amount,
        //   time: req.time,
        //   refId: req.refId,
        //   mode: req.mode,
        // },
        { remarks: "" },
        req.batchId
      );
      return remoteData.map(result, parseDetaxTransactionBatchDetailResponse);
    };

  export const rejectBatch = async (batchId: string, remarks: string = "") => {
    const result = await NcaishTransactionBatchApiService.rejectbatch(
      { remarks },
      batchId
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchDetailResponse) => ({
        successful: fromNullable(response.successful),
        message: fromNullable(response.message),
        data: fromNullable(response.data),
      })
    );
  };

  export const approveBatch = async (batchId: string) => {
    const result = await NcaishTransactionBatchApiService.approveBatch(
      { remarks: "" },
      batchId
    );
    return remoteData.map(
      result,
      (response: DetaxAdminBatchDetailResponse) => ({
        successful: fromNullable(response.successful),
        message: fromNullable(response.message),
        data: fromNullable(response.data),
      })
    );
  };

  export const uploadTransaction = async (req: {
    data: Array<DetaxBatchMember>;
    batchId: string;
  }) => {
    const result = await NcaishTransactionBatchApiService.updateBatchMember(
      { data: req.data },
      req.batchId
    );
    return remoteData.map(result, parseDetaxBatchMemberResponse);
  };

  export const loadEligibleUser = async (
    type: DetaxTypeKind,
    keyword: string = "",
    month: string,
    year: string
  ) => {
    try {
      DetaxDispatch.startLoad();
      const result = await searchEligibleUsers(type, keyword, month, year);
      if (isSuccess(result)) {
        DetaxDispatch.setEligibleUsers(result.value);
      } else {
      }
      DetaxDispatch.endLoad();
    } catch {
      DetaxDispatch.endLoad();
    }
  };

  export const parseDetaxBatchCountInfo = (
    data: Option<DetaxBatchCountInfo[]>
  ) => {};

  export const parseDetaxTransactionBatchDetailResponse = (
    response: DetaxTransactionBatchDetailResponse
  ): TDetaxTransactionBatchDetailResponse => {
    return {
      successful: fromNullable(response.successful),
      message: fromNullable(response.message),
      data: fromNullable(response.data),
    };
  };

  export const parseDetaxBatchInfo = (
    data: Option<DetaxBatchInfo[]>
  ): TDetaxBatchInfo[] => {
    const parseData: TDetaxBatchInfo[] = isSome(data)
      ? data.value.map((d) => ({
          batchId: fromNullable(d.batchId),
          batchCode: fromNullable(d.batchCode),
          type: fromNullable(d.type),
          txnCount: fromNullable(d.txnCount),
          amount: fromNullable(d.amount),
          status: fromNullable(d.status),
          paymentInfo: fromNullable(d.paymentInfo),

          createdAt: fromNullable(d.createdAt),
          updatedAt: fromNullable(d.updatedAt),
          initiatedAt: fromNullable(d.initiatedAt),
          cancelledAt: fromNullable(d.cancelledAt),
          approvedAt: fromNullable(d.approvedAt),
          executedAt: fromNullable(d.executedAt),
          createdBy: fromNullable(d.createdBy),
          initiatedBy: fromNullable(d.initiatedBy),
          cancelledBy: fromNullable(d.cancelledBy),
          approvedBy: fromNullable(d.approvedBy),
          executedBy: fromNullable(d.executedBy),
          timeline: fromNullable(d.timeline),
        }))
      : [];
    return parseData;
  };

  // export const parseDetaxBatchPageCount = (
  //   data: Option<DetaxTransactionBatcheListResponse>
  // ): TDetaxBatchCountPage => {
  //   // const parseData: TDetaxBatchCountPage = data.value;
  //   return data;
  // };

  export const parseDetaxBatchDetailsInfo = (
    data: Option<DetaxBatchInfo>
  ): TDetaxBatchInfo => {
    const parseData: TDetaxBatchInfo = isSome(data)
      ? {
          batchId: fromNullable(data.value.batchId),
          batchCode: fromNullable(data.value.batchCode),
          type: fromNullable(data.value.type),
          txnCount: fromNullable(data.value.txnCount),
          amount: fromNullable(data.value.amount),
          status: fromNullable(data.value.status),
          paymentInfo: fromNullable(data.value.paymentInfo),

          createdAt: fromNullable(data.value.createdAt),
          updatedAt: fromNullable(data.value.updatedAt),
          initiatedAt: fromNullable(data.value.initiatedAt),
          cancelledAt: fromNullable(data.value.cancelledAt),
          approvedAt: fromNullable(data.value.approvedAt),
          executedAt: fromNullable(data.value.executedAt),
          createdBy: fromNullable(data.value.createdBy),
          initiatedBy: fromNullable(data.value.initiatedBy),
          cancelledBy: fromNullable(data.value.cancelledBy),
          approvedBy: fromNullable(data.value.approvedBy),
          executedBy: fromNullable(data.value.executedBy),
          timeline: fromNullable(data.value.timeline),
        }
      : {
          batchId: none,
          batchCode: none,
          type: none,
          txnCount: none,
          amount: none,
          status: none,
          paymentInfo: none,
          createdAt: none,
          updatedAt: none,
          initiatedAt: none,
          cancelledAt: none,
          approvedAt: none,
          executedAt: none,
          createdBy: none,
          initiatedBy: none,
          cancelledBy: none,
          approvedBy: none,
          executedBy: none,
          timeline: none,
        };
    return parseData;
  };
  export const parseNpsBatchMemberInfo = (
    data: NpsBatchMemberInfo
  ): TNpsBatchMemberInfo => {
    return {
      uid: fromNullable(data.uid),
      pranNo: fromNullable(data.pranNo),
      name: fromNullable(data.name),
      subContribution: fromNullable(data.subContribution),
      // executedAt: fromNullable(data.executedAt),
      employerContribution: fromNullable(data.employerContribution),
      contriType: fromNullable(data.contriType),
      arrearRemarks: fromNullable(data.arrearRemarks),
      totalCharges: fromNullable(data.totalCharges),
      status: fromNullable(data.status),
      // isChecked: false,
    };
  };
  export const parseDetaxCardBatchMemberInfo = (
    data: DetaxCardBatchMemberInfo
  ): TDetaxCardBatchMemberInfo => {
    return {
      uid: fromNullable(data.uid),
      userCode: fromNullable(data.userCode),
      name: fromNullable(data.name),
      email: fromNullable(data.email),
      executedAt: fromNullable(data.executedAt),
      mobile: fromNullable(data.mobile),
      cardNo: fromNullable(data.cardNo),
      limit: fromNullable(data.limit),
      amountToAdd: fromNullable(data.amountToAdd),
      status: fromNullable(data.status),
      isChecked: false,
    };
  };
}

export const parseDetaxBatchMemberResponse = (
  response: GetDetaxTransactionBatchMembersResponse
): TGetDetaxTransactionBatchMembersResponse => {
  return {
    successful: fromNullable(response.successful),
    message: fromNullable(response.message),
    type: fromNullable(response.type),
    fbaTxns: fromNullable(response.fbaTxns),
    taTxns: fromNullable(response.taTxns),
    npsTxns: fromNullable(response.npsTxns),
    eaTxns: fromNullable(response.eaTxns),
    gcTxns: fromNullable(response.gcTxns),
  };
};
