import { FloatingLabel, Form } from "react-bootstrap";

const PopupForm = () => {
  return (
    <div
      className="modal fade modal-lg modal-dialog modal-dialog-centered position-relative d-flex "
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      data-bs-focus="true"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg position-fixed ">
        <div className="modal-content ">
          <div className="modal-header">
            <div>
              <h6 className="modal-title fw-bold" id="exampleModalLabel">
                Employee Details - DET001{" "}
              </h6>
              {/* <p className="fw-normal">
                Provide details of employee as following{" "}
              </p> */}
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Employee Name"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                /> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Employee Name"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="Username" />
                </FloatingLabel>
              </div>
              <div className="col-md-4">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                /> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mobile No"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="Username" />
                </FloatingLabel>

              </div>
              <div className="col-md-4">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Email ID"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                /> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="Username" />
                </FloatingLabel>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Monthly Salary"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                /> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Address"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="Username" />
                </FloatingLabel>
              </div>
              <div className="col-md-4">
                <FloatingLabel controlId="floatingSelect" label="Works with selects">
                  <Form.Select aria-label="Floating label select example">
                    <option>Employee Designation</option>
                    <option value="1">Product Manager</option>

                  </Form.Select>
                </FloatingLabel>
              </div>
              <div className="col-md-4 mt-3 d-flex  justify-content-center">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="active" id="active" value="active1" checked />
                  <label className="form-check-label px-1" htmlFor="active">
                    Active
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="inactive" id="inactive" value="inactive" />
                  <label className="form-check-label px-1" htmlFor="inactive">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary px-4 rounded-6_i text-light"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-6_i px-4 py-2 text-light"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    // <div
    //   className=""
    //   id="exampleModal"
    //   aria-labelledby="exampleModalLabel"
    //   aria-hidden="true"
    // >
    //   <div className="modal-dialog modal-lg">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <div>
    //           <h6 className="modal-title fw-bold" id="exampleModalLabel">
    //             Add new employee{" "}
    //           </h6>
    //           <p className="fw-normal">
    //             Provide details of employee as following{" "}
    //           </p>
    //         </div>
    //       </div>
    //       <div className="modal-body">
    //         <div className="row">
    //           <div className="col-md-4">
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder="Employee Name"
    //               aria-label="Username"
    //               aria-describedby="addon-wrapping"
    //             />
    //           </div>
    //           <div className="col-md-4">
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder="Mobile"
    //               aria-label="Username"
    //               aria-describedby="addon-wrapping"
    //             />
    //           </div>
    //           <div className="col-md-4">
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder="Email ID"
    //               aria-label="Username"
    //               aria-describedby="addon-wrapping"
    //             />
    //           </div>
    //         </div>
    //         <div className="row mt-4">
    //           <div className="col-md-4">
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder="Monthly Salary"
    //               aria-label="Username"
    //               aria-describedby="addon-wrapping"
    //             />
    //           </div>
    //           <div className="col-md-4">
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder="Flexible Allowances"
    //               aria-label="Username"
    //               aria-describedby="addon-wrapping"
    //             />
    //           </div>
    //           <div className="col-md-4">
    //             <input
    //               type="text"
    //               className="form-control"
    //               placeholder="Travel Allowances"
    //               aria-label="Username"
    //               aria-describedby="addon-wrapping"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className="modal-footer">
    //         <button
    //           type="button"
    //           className="btn btn-secondary px-4 rounded-6_i text-light"
    //           // data-bs-dismiss="modal"
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           type="button"
    //           className="btn btn-primary rounded-6_i px-4 py-2 text-light"
    //         >
    //           Submit
    //         </button>
    //       </div>

    // <h1>ADD YOUR EMPLOYES MODAL</h1>

    // <div className="row">
    //   <div className="col-md-4 my-auto text-center">
    //     <img src="image/img.jpg" className="img-fluid w-75" alt="dummy" />
    //   </div>
    //   <div className="col-md-8">
    //     <div className="modal-header">
    //       <h3 className="modal-title" id="exampleModalLabel">
    //         Add your employees{" "}
    //       </h3>
    //       <button
    //         type="button"
    //         className="btn-close"
    //         data-bs-dismiss="modal"
    //         aria-label="Close"
    //       ></button>
    //     </div>
    //     <div className="modal-body">
    //       <p>
    //         Lorem ipsum is a placeholder text commonly used to demonstrate
    //         the visual form of document or a typeface without relying on
    //         meaningful content. Lorem ipsum may be used as a placeholder
    //         before final copy is available.
    //       </p>
    //     </div>
    //     <div className="modal-footer">
    //       <button
    //         type="button"
    //         className="btn btn-disabled px-4 rounded-6_i text-light"
    //         data-bs-dismiss="modal"
    //       >
    //         Cancel
    //       </button>
    //       <button
    //         type="button"
    //         className="btn btn-gradiant-blue px-4 py-2 text-light"
    //       >
    //         Add Now
    //       </button>
    //     </div>
    //   </div>
    // </div>

    // <h1>Transaction more details modal</h1>
    // <div className="row m-3">
    //   <div className="modal-header">
    //     <div>
    //       <h6 className="modal-title fw-bold" id="exampleModalLabel">
    //         More Details Required{" "}
    //       </h6>
    //     </div>
    //   </div>
    //   <div className="modal-body p-0">
    //     <div className="row border rounded-10 p-2 m-1">
    //       <div className="col-md-6">
    //         <h6 className="text-secondary f-13">Total No. of Employee</h6>
    //         <h6 className="text-black f-13 fw-bold">450</h6>
    //       </div>
    //       <div className="col-md-6">
    //         <h6 className="text-secondary f-13">Total Amount (INR)</h6>
    //         <h6 className="text-black f-13 fw-bold">73,00,000</h6>
    //       </div>
    //     </div>
    //     <div className="col-12 mt-3 gap-3 d-flex justify-content-end">
    //       <button
    //         type="button"
    //         className="btn btn-disabled px-4 rounded-6_i text-light"
    //         data-bs-dismiss="modal"
    //       >
    //         Cancel
    //       </button>
    //       <button
    //         type="button"
    //         className="btn btn-popup px-4 py-2 text-light"
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};
export default PopupForm;
