import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute: React.FC<{
  isAuthenticated: boolean;
  children: React.ReactNode;
}> = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    return <>{children}</>;
  }
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
