import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetaxCardSubscriptionCountInfo } from "../../../../api/detax/model/detaxCardSubscriptionCountInfo";
import { DetaxService } from "../../../../services/detax.service";
import { MembershipService } from "../../../../services/membership.service";
import { StatusCountService } from "../../../../services/status-count.service";
import { selectDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";
import CardLoader from "../common/card-loader";

//DetaxService
const MembershipBatchStatusCount: React.FC<{
  setCurTbl: (tbl: TStatusCountInfo) => void;
  curTbl: TStatusCountInfo | null;
  reload?: boolean;
  setReload: (flag: boolean) => void;
}> = ({ setCurTbl, curTbl, reload = false, setReload = () => {} }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [counts, setCounts] = useState<Array<TStatusCountInfo> | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const run = async () => {
      // if (reload || !reload) {
      let status = curTbl?.status || "";
      // setIsLoading(true);
      const membershipStatusCount = await MembershipService.getMembershipCount(
        status
      );
      // setIsLoading(false);
      setErr(false);
      if (isSuccess(membershipStatusCount)) {
        if (membershipStatusCount.value.successful) {
          const allCardCount =
            StatusCountService.addAllMembershipBatchCardCount(
              membershipStatusCount.value.data || []
            );

          if (allCardCount.length > 0) {
            setCounts(allCardCount);
            if (card == "") setCurTbl(allCardCount?.[0]);
            else {
              let cur = allCardCount.filter((i) => i.status === card)?.[0];
              if (cur) setCurTbl(cur);
            }
            /* if (page !== '')
              dispatch(setDashboardRedirect({ page, card: (detaxStatusCount.value.data || [])?.[0]?.status || '' })) */
          }
        }
      } else {
        setErr(true);
      }
      // }
      //else if (isFailure(detaxStatusCount)) {
      //   // alert(detaxStatusCount?.error?.outcome)
      //   // console.log("detaxCounts: ", detaxStatusCount?.error?.outcome);
      // }
      setReload(false);
    };
    run();
  }, [reload]);

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        <>
          {isLoading ? (
            <CardLoader />
          ) : (
            <div className="card-counts my-4">
              {counts.map((item, key) => (
                <div
                  key={key}
                  onClick={() => setCurTbl(item)}
                  className="card-count"
                >
                  <WhiteCard
                    className={classNames("p-2 w-75 status-dot-card", {
                      color6: item.status == "All",
                      color3: item.status == "Requested",
                      color4: item.status == "Initiated",
                      color7: item.status == "Executed",
                      color12: item.status == "Approved",
                      color14: item.status == "Canceled",
                      // color3: item.status == "ACTIVATION_PENDING",
                      // color7: item.status == "ACTIVATED",
                      // color6: item.status == "DEACTIVATED",
                      // color8: item.status == "BLOCKED",
                      // color9: item.status == "EXPIRED",

                      active:
                        card && card != ""
                          ? curTbl?.status == card && card == item.status
                          : curTbl?.status == item.status,
                    })}
                  >
                    <div className="status-dot"></div>
                    <h2 className="text-black text-center mb-0 fw-bold">
                      {item.count}
                    </h2>
                    <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                      {item.status === "Canceled" ? "Cancelled" : item.status}
                    </p>
                  </WhiteCard>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MembershipBatchStatusCount;
