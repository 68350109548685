//claimCount
import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishDetaxSubscriptonApiService } from "../api/detax/api/ncaishDetaxSubscriptonApi.service";

import { NcaishNpsApiService } from "../api/detax/api/ncaishNpsApi.service";
import { EmployeeListResponse } from "../api/detax/model/employeeListResponse";
import { NpsAccountResponse } from "../api/detax/model/npsAccountResponse";
import { NpsContributionResponse } from "../api/detax/model/npsContributionResponse";
import { NpsSubscriptionApiRequest } from "../api/detax/model/npsSubscriptionApiRequest";
import { NpsSubscriptionCountApiResponse } from "../api/detax/model/npsSubscriptionCountApiResponse";
import { NpsSubscriptionResponse } from "../api/detax/model/npsSubscriptionResponse";
import { UpdateNpsAccountRequest } from "../api/detax/model/updateNpsAccountRequest";
import { EmployeeResponse } from "../model/employeeResponse";
import { NpsPranReMappingResponse } from "../api/detax/model/npsPranReMappingResponse";
import { AddBulkNPSApiRequest } from "../api/employee/model/addBulkEmployeeApiRequest";
import { NpsBulkApiResponse } from "../api/detax/model/employeeListApiResponse";

export namespace NpsService {
  export const getCardSubscriptionCount = async (status?: string) => {
    const result =
      await NcaishDetaxSubscriptonApiService.getNpsSubscriptionCount(status);
    return remoteData.map(
      result,
      (response: NpsSubscriptionCountApiResponse) => response
    );
  };

  export const getNpsSubscriptions = async (
    status: string = "",
    pn: number,
    rc: number
  ) => {
    const result = await NcaishDetaxSubscriptonApiService.getNpsSubscriptions(
      status,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: NpsSubscriptionResponse) => response
    );
  };

  export const getNpsPranReMapping = async (pn: number, rc: number) => {
    const result = await NcaishDetaxSubscriptonApiService.getNpsPranReMapping(
      pn,
      rc
    );
    return remoteData.map(result, (response: Blob) => response);
  };

  export const updatePranReMapping = async (body: string[]) => {
    const result = await NcaishDetaxSubscriptonApiService.updatePranReMapping(
      body
    );
    return remoteData.map(
      result,
      (response: NpsPranReMappingResponse) => response
    );
  };

  export const getNpsSubscriptionsSearch = async (
    keyword: string,
    status: string = "",
    pn: number,
    rc: number
  ) => {
    const result =
      await NcaishDetaxSubscriptonApiService.getNpsSubscriptionsSearch(
        keyword,
        status,
        pn,
        rc
      );
    return remoteData.map(
      result,
      (response: NpsSubscriptionResponse) => response
    );
  };
  export const sendNpsSubscriptionRequest = async (
    body: NpsSubscriptionApiRequest
  ) => {
    const result =
      await NcaishDetaxSubscriptonApiService.sendNpsSubscriptionRequest(body);
    return remoteData.map(
      result,
      (response: NpsSubscriptionResponse) => response
    );
  };
  export const searchNpsEligibleEmployee = async (keyword?: string) => {
    const result =
      await NcaishDetaxSubscriptonApiService.searchNpsEligibleEmployee(keyword);
    return remoteData.map(result, (response: EmployeeListResponse) => response);
  };
  export const getUserAccount = async (uid: string) => {
    const result = await NcaishNpsApiService.getUserAccount(uid);
    return remoteData.map(result, (response: NpsAccountResponse) => response);
  };
  export const getContribution = async (uid: string) => {
    const result = await NcaishNpsApiService.getContribution(uid);
    return remoteData.map(
      result,
      (response: NpsContributionResponse) => response
    );
  };
  export const updateAccount = async (
    uid: string,
    request: UpdateNpsAccountRequest
  ) => {
    const result = await NcaishNpsApiService.updateAccount(uid, request);
    return remoteData.map(result, (response: NpsAccountResponse) => response);
  };

  export const createNpsBulk = async (
    body: AddBulkNPSApiRequest,
    cid: string
  ) => {
    const result = await NcaishNpsApiService.createNpsBulk(body, cid);
    return remoteData.map(result, (response: NpsBulkApiResponse) => response);
  };
}
