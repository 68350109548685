import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "./heading.scss";

const Heading: React.FC<{
  title?: string;
  detail?: string;
  status?: string;
  buttons?: JSX.Element;
  links?: string[];
  breadcrum?: { label: string; url: string };
}> = ({
  title,
  detail,
  status,
  buttons = "",
  links = [],
  breadcrum = undefined,
}) => {
  return (
    <div className="page-heading-container d-flex justify-content-between">
      <h1 className="page-heading">
        {breadcrum ? (
          <Link to={breadcrum.url}>
            <span className="title">{`${breadcrum.label} > `}</span>
          </Link>
        ) : null}
        {title ? (
          links?.[0] ? (
            <Link to={links?.[0]}>
              <span className="title">{title}</span>
            </Link>
          ) : (
            <span className="title">{title}</span>
          )
        ) : null}
        {detail ? ` >  ${detail}` : ""}
        {status ? (
          <span
            className={classNames({
              color6: status === "PENDING" || "INACTIVE" || "REJECTED",
              color7: status === "LIVE" || "ACTIVE",
              color8: status === "EXPIRED" || "BLOCKED",
              color1: status === "CIN_VERIFIED",
              color2: status === "SIGNUP_COMPLETED",
              color3: status === "SIGNATORY_UPDATED",
              color4: status === "SERVICE_SELECTION_COMPLETED",
              color5: status === "BR_SENT",
              color9: status === "REVIEW_PENDING",
            })}
          >{` | ${status}`}</span>
        ) : null}
      </h1>
      <div>{buttons}</div>
    </div>
  );
};

export default Heading;
