/**
 * Ncaish Auth API
 * Apis to manage Login,Logout,Change password,Forgot password,Remote access token verification,Application clients,Roles ,Permissions,etc
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SwitchAccountApiRequest {
  uid?: string;
  cid?: string;
  switchTo?: SwitchAccountApiRequest.SwitchToEnum;
}
export namespace SwitchAccountApiRequest {
  export type SwitchToEnum = "CORPORATE" | "SUPER_ADMIN";
  export const SwitchToEnum = {
    CORPORATE: "CORPORATE" as SwitchToEnum,
    SUPERADMIN: "SUPER_ADMIN" as SwitchToEnum,
  };
}
