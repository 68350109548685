/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DetaxBatchPaymentInfo } from "./detaxBatchPaymentInfo";
import { DetaxUserBasicInfo } from "./detaxUserBasicInfo";
import { Timeline } from "./timeline";

export interface DetaxBatchInfo {
  batchId?: string;
  batchCode?: string;
  type?: DetaxBatchInfo.TypeEnum;
  txnCount?: number;
  amount?: number;
  status?: DetaxBatchInfo.StatusEnum;
  paymentInfo?: DetaxBatchPaymentInfo;
  createdAt?: string;
  updatedAt?: string;
  initiatedAt?: string;
  cancelledAt?: string;
  approvedAt?: string;
  executedAt?: string;
  createdBy?: DetaxUserBasicInfo;
  initiatedBy?: DetaxUserBasicInfo;
  cancelledBy?: DetaxUserBasicInfo;
  approvedBy?: DetaxUserBasicInfo;
  executedBy?: DetaxUserBasicInfo;
  timeline?: Timeline;
}
export namespace DetaxBatchInfo {
  export type TypeEnum = "FBA" | "TA" | "NPS" | "EA" | "GC";
  export const TypeEnum = {
    FBA: "FBA" as TypeEnum,
    TA: "TA" as TypeEnum,
    NPS: "NPS" as TypeEnum,
    EA: "EA" as TypeEnum,
    GC: "GC" as TypeEnum,
  };
  export type StatusEnum =
    | "CREATED"
    | "SUBMITTED"
    | "PAYMENT_CONFIRMED"
    | "EXECUTING"
    | "FAILED"
    | "PARTIALLY_EXECUTED"
    | "EXECUTED"
    | "ON_HOLD"
    | "CANCELLED"
    | "REJECTED";
  export const StatusEnum = {
    CREATED: "CREATED" as StatusEnum,
    SUBMITTED: "SUBMITTED" as StatusEnum,
    PAYMENTCONFIRMED: "PAYMENT_CONFIRMED" as StatusEnum,
    EXECUTING: "EXECUTING" as StatusEnum,
    FAILED: "FAILED" as StatusEnum,
    PARTIALLYEXECUTED: "PARTIALLY_EXECUTED" as StatusEnum,
    EXECUTED: "EXECUTED" as StatusEnum,
    ONHOLD: "ON_HOLD" as StatusEnum,
    CANCELLED: "CANCELLED" as StatusEnum,
    REJECTED: "REJECTED" as StatusEnum,
  };
}
