import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClaimCountInfo } from "../../../../api/detax/model/claimCountInfo";
import { ClaimsService } from "../../../../services/claims.service";
import { StatusCountService } from "../../../../services/status-count.service";
import { selectDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";
import CardLoader from "../common/card-loader";

//ClaimsService
interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const ClaimsStatusCount: React.FC<{
  uid?: string;
  setCurTbl: (tbl: TStatusCountInfo) => void;
  curTbl: TStatusCountInfo | null;
  filterDate: IDateRange;
  detaxCard?: boolean;
}> = ({ uid = "", setCurTbl, curTbl, filterDate, detaxCard = false }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [counts, setCounts] = useState<Array<TStatusCountInfo> | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const claimsStatusCount = await ClaimsService.claimCount(
        filterDate.startDate
          ? format(new Date(filterDate.startDate), "dd-MM-yyyy")
          : "",
        filterDate.endDate
          ? format(new Date(filterDate.endDate), "dd-MM-yyyy")
          : "",
        uid
      );
      setIsLoading(false);
      if (isSuccess(claimsStatusCount)) {
        if (claimsStatusCount.value.successful) {
          const allCards = StatusCountService.addAllClaimCount(
            claimsStatusCount.value.data || []
          );
          if (allCards.length > 0) {
            setCounts(allCards);
            if (card == "") setCurTbl(allCards?.[0]);
            else {
              let cur = allCards.filter((i) => i.status === card)?.[0];
              if (cur) setCurTbl(cur);
            }
          }
        }
      } else {
        setErr(true);
      }
      //  else if (isFailure(claimsStatusCount)) {
      //   // alert(claimsStatusCount?.error?.outcome)
      //   console.log("claimsCounts: ", claimsStatusCount?.error?.outcome);
      // }
    };

    run();
  }, [filterDate]);

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        <>
          {isLoading && uid == "" ? (
            <CardLoader />
          ) : (
            <div
              className={classNames("card-counts", {
                "my-4": !detaxCard,
                "mb-4": detaxCard,
              })}
            >
              {counts.map((item, key) => (
                <div
                  key={key}
                  onClick={() => setCurTbl(item)}
                  className="card-count"
                >
                  <WhiteCard
                    className={classNames("p-2 w-75 status-dot-card", {
                      // detaxCard: detaxCard,
                      active:
                        card && card != ""
                          ? curTbl?.status == card && card == item.status
                          : +(curTbl?.status == item.status),
                      color2: item.status === "PENDING",
                      color7: item.status === "APPROVED",
                      color8: item.status === "REJECTED",
                      color9: item.status === "REIMBURSED",
                      color3: item.status === "IN_PROCESS",
                      color0: item.status === "All",
                      color4: item.status === "REVIEW_PENDING",
                      color1: item.status == "APPROVER_PENDING",
                      color6: item.status == "CANCELLED",
                    })}
                  >
                    <div className="status-dot"></div>
                    <h2 className="text-black text-center mb-0 fw-bold">
                      {item.count}
                    </h2>
                    <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                      {item.label}
                    </p>
                  </WhiteCard>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ClaimsStatusCount;
