import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, MediumScreen, SmallScreen } from "../..";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";

import PageLoader from "../../../page-loader";
import { OnboardingService } from "../../../services/onboarding.service";
import { selectAppToken } from "../../../state/slice/token.slice";
import { fromPublic } from "../../../utils/common.utils";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";

import CompanyDetailsCard from "../../company/company-details-card";
import { setCompanyInfo } from "../../company/company.slice";
import DirectorSignatoryDetailsCard from "../../company/director-signatory-details-card";
import Stepper from "../../stepper/stepper";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import BrandLogoHeader from "./brandlogo-header";

const Building = fromPublic("/images/icons/building.svg");
const Image1 = fromPublic("/images/cd.svg");
const User = fromPublic("/images/icons/user.svg");
const Group = fromPublic("/images/icons/group-1.svg");

const CompanyReviewPage = () => {
  const appToken = useAppSelector(selectAppToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Company review" });

    const run = async () => {
      const result = await OnboardingService.getReview();
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          dispatch(setCompanyInfo(result.value.data));
          setLoading(false);
        } else {
          //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
        }
      } else {
        // send to retry page/ error page
      }
    };
    run();
  }, []);

  const handleSubmit = () => {
    mixpanel.track("Button click", { name: "Verify company" });
    navigate(RoutePaths.DigitalContract);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <div className="container p-0">
        <BrandLogoHeader />
        {/* <Stepper /> */}
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mt-5 mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="Mobile"
                  />
                </div>
                <div className="mt-5 textDetail">
                  <Heading
                    className="mx-5 mb-3 mt-n5"
                    text="Welcome Onboard"
                    variant="small"
                  />

                  <p className="mx-5 fw-bolder text-secondary">
                    You are almost there! A few more clicks and your employees
                    will be on the path to financial wellness. Let’s go for it!
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="p-0 m-0 container-fluid">
                  <div className="p-4 border-0 shadow card bg-grey rounded-15">
                    <BackButton
                      text="Your company details are"
                      canGoBack={false}
                    />
                    <div
                      className="custom-scroll"
                      style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        height: "50vh",
                      }}
                    >
                      <CompanyDetailsCard />
                      <DirectorSignatoryDetailsCard />
                    </div>
                    <div className="mt-3 mb-2 ml-auto mr-auto text-center">
                      <Button type="button" onClick={handleSubmit}>
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-3 border-0 card bg-grey">
                <BackButton text="Details" />
                <div className="mt-2 mb-2 row ">
                  {/* <div className="col-2 ">
                    <img
                      className="img-fluid ncash_bg-lightgray w-100"
                      src={Building}
                      alt=""
                    />
                  </div> */}
                  <div className="mt-1 col-10 ps-4">
                    <h4 className="fw-bold ncash_companyHeading">
                      Your company details are
                    </h4>
                  </div>
                </div>
                <div className="d-none d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="BG"
                  />
                </div>
                <CompanyDetailsCard />
                <DirectorSignatoryDetailsCard />
                <div className="mt-3 mb-5 ml-auto mr-auto text-center">
                  <Button type="button" onClick={handleSubmit}>
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </div>
  );
};

export default CompanyReviewPage;
