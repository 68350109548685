import { ClaimCountInfo } from "../api/detax/model/claimCountInfo";
import { DetaxBatchCountInfo } from "../api/detax/model/detaxBatchCountInfo";
import { DetaxCardSubscriptionCountInfo } from "../api/detax/model/detaxCardSubscriptionCountInfo";
import { MembershipDetaxCountInfo } from "../api/detax/model/membershipDetaxCountInfo";
import { NpsSubscriptionCountInfo } from "../api/detax/model/npsSubscriptionCountInfo";
import { TransactionApprovalCountInfo } from "../api/detax/model/transactionApprovalCountInfo";
import { UserStatusCountInfo } from "../api/employee/model/userStatusCountInfo";
import { TStatusCountInfo } from "../types/status-count-info.type";

export namespace StatusCountService {
  export const addAllEmployeeCount = (data: Array<UserStatusCountInfo>) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };

  export const addAllDetaxCardCount = (
    data: DetaxCardSubscriptionCountInfo[]
  ) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };

  export const addAllDetaxTransactionCount = (data: DetaxBatchCountInfo[]) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };

  export const addAllClaimCount = (data: ClaimCountInfo[]) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };

  export const addAllNpsCount = (data: NpsSubscriptionCountInfo[]) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };

  export const addAllTransactionCount = (
    data: TransactionApprovalCountInfo[]
  ) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };
  // COUNT THE BATCH FOR MEMBERSHIP
  export const addAllMembershipBatchCardCount = (
    data: MembershipDetaxCountInfo[]
  ) => {
    const allCount: TStatusCountInfo = {
      status: "All",
      label: "All",
      count: data.reduce((a, c) => a + (c.count || 0), 0),
    };
    const counts: TStatusCountInfo[] = [
      allCount,
      ...data.map((d) => ({
        status: d.status || "",
        label: d.label || "",
        count: d.count || 0,
      })),
    ];
    return counts;
  };
}
