/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { RemoteData } from "@devexperts/remote-data-ts";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { BaseResponse } from "../model/baseResponse";
import { CancelClaimApiRequest } from "../model/cancelClaimApiRequest";
import { ClaimApprovalApiRequest } from "../model/claimApprovalApiRequest";
import { ClaimDetailApiResponse } from "../model/claimDetailApiResponse";
import {
  ClaimApproverResponse,
  ClaimResponseApprover,
  ClaimResponseEmployee,
} from "../model/claimResponse";
import { ClaimStatusWiseCountApiResponse } from "../model/claimStatusWiseCountApiResponse";
import { FetchBulkClaimResponse } from "../model/fetchBulkClaimResponse";
import { FetchBulkDataApiRequest } from "../model/fetchBulkDataApiRequest";
import { GetClaimsApiResponse } from "../model/getClaimsApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishClaimApiService {
  /**
   * Api to fetch all claims
   * @param status -
   * @param from -
   * @param to -
   * @param uid -
   * @param pn -
   * @param rc -
   */
  export const allClaim = async (
    status?: string,
    from?: string,
    to?: string,
    uid?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetClaimsApiResponse>> => {
    const path = `/secure/detax/card/claims`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "uid",
        value: uid,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param claimId -
   */
  export const approval = async (
    body: ClaimApprovalApiRequest,
    claimId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimDetailApiResponse>> => {
    const path = `/secure/detax/card/claims/approval/${encodeURIComponent(
      String(claimId)
    )}`
      .replace(/^\//, "")
      .replace("{" + "claimId" + "}", String(claimId));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param claimId -
   * @param cid -
   */
  export const approvalSuperAdmin = async (
    body: ClaimApprovalApiRequest,
    claimId: string,
    cid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimDetailApiResponse>> => {
    const path = `/secure/detax/card/claims/approval/${encodeURIComponent(
      String(claimId)
    )}/corporates/${encodeURIComponent(String(cid))}`
      .replace(/^\//, "")
      .replace("{" + "claimId" + "}", String(claimId))
      .replace("{" + "cid" + "}", String(cid));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param claimId -
   */
  export const cancelClaim = async (
    body: CancelClaimApiRequest,
    claimId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimDetailApiResponse>> => {
    const path = `/secure/detax/card/claims/cancel/${encodeURIComponent(
      String(claimId)
    )}`
      .replace(/^\//, "")
      .replace("{" + "claimId" + "}", String(claimId));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param from -
   * @param to -
   * @param uid -
   */
  export const claimCount = async (
    from?: string,
    to?: string,
    uid?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimStatusWiseCountApiResponse>> => {
    const path = `/secure/detax/card/claims/status/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "uid",
        value: uid,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param request -
   */
  export const fetchBulkClaims = async (
    request: FetchBulkDataApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FetchBulkClaimResponse>> => {
    const path = `/secure/detax/card/claims/bulk/fetch`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    // const queryParams = allQueryParams
    //   .map((param) => `${param.key}=${param.value}`)
    //   .join("&");
    const queryParams = queryString.stringify(request);
    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to fetch claim details by claimid
   * @param id -
   */
  export const getClaimById = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimDetailApiResponse>> => {
    const path = `/secure/detax/card/claims/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param claimId -
   */
  export const reimburse = async (
    claimId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimDetailApiResponse>> => {
    const path = `/secure/detax/card/claims/reimburse/${encodeURIComponent(
      String(claimId)
    )}`
      .replace(/^\//, "")
      .replace("{" + "claimId" + "}", String(claimId));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getClaimEmployees = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimResponseEmployee>> => {
    const path =
      `/secure/detax/card/claims/employee/claimApprover/list`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getClaimApprovers = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimResponseApprover>> => {
    const path = `/secure/detax/card/claims/claimApprover/list`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const createClaimApprover = async (
    body: any,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ClaimApproverResponse>> => {
    const path = `/secure/detax/card/claims/claim-approver/create`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const deleteClaimApprover = async (
    approverDefId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path =
      `/secure/detax/card/claims/approver/delete/${approverDefId}`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateClaimApprover = async (
    body: any,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/detax/card/claims/approver/update`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
