import { Option, none } from "fp-ts/lib/Option";
import { UserPermission } from "../model/auth/oauthLogin.model";

export type TOAuthLoginResponse = {
  access_token: Option<string>;
  token_type: Option<string>;
  refresh_token: Option<string>;
  expires_in: Option<number>;
  scope: Option<string>;
  id: Option<string>;
  cid: Option<string>;
  email: Option<string>;
  full_name: Option<string>;
  roles: Option<string[]>;
  jti: Option<string>;
  initPassChanged: Option<boolean>;
  appToken: Option<string>;
  permissions: Array<UserPermission.PermissionType>;
  switchAccount: Option<boolean>;
  successful: Option<boolean>;
  message: Option<string>;
};

export const defaultTOAuthLoginResponse: TOAuthLoginResponse = {
  access_token: none,
  token_type: none,
  refresh_token: none,
  expires_in: none,
  scope: none,
  id: none,
  cid: none,
  email: none,
  full_name: none,
  roles: none,
  jti: none,
  initPassChanged: none,
  appToken: none,
  permissions: [],
  switchAccount: none,
  successful: none,
  message: none,
};
