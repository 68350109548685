/**
 * Corporate API
 * Apis to manage corporate onboarding and operations
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "./api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "./errors";

//@ts-ignore
//import * as models from '../model/models';
import { AddBulkEmployeeRequest } from "../model/addBulkEmployeeRequest";
import { BaseResponse } from "../model/baseResponse";
import { EmployeeListResponse } from "../model/employeeListResponse";
import { EmployeeRequest } from "../model/employeeRequest";
import { EmployeeResponse } from "../model/employeeResponse";
import { Pagination } from "../model/pagination";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EmployeeApiService {
  /**
   *
   * @param body -
   */
  export const create = async (
    body: EmployeeRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeResponse>> => {
    const path = `/secure/corporates/employees`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param body -
   */
  export const createBulk = async (
    body: AddBulkEmployeeRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeListResponse>> => {
    const path = `/secure/corporates/employees/bulk`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param pagination -
   */
  export const getAll = async (
    pagination: Pagination,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeListResponse>> => {
    const path = `/secure/corporates/employees`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //         {
    //             key: 'pagination',
    //             value: pagination
    //         },
    // ];
    // const queryParams = allQueryParams
    //         .map(param => `${param.key}=${param.value}`)
    //         .join('&');

    const apiUrl = path.concat(
      `?page=${pagination.page}&limit=${pagination.limit}`
    );
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param id -
   */
  export const getById = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeResponse>> => {
    const path = `/secure/corporates/employees/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param id -
   */
  export const remove = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/corporates/employees/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "delete",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param body -
   * @param id -
   */
  export const update = async (
    body: EmployeeRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeResponse>> => {
    const path = `/secure/corporates/employees/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new EmployeeApiService()
