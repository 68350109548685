import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ClaimCountInfo } from "../../../../api/detax/model/claimCountInfo";
import { ClaimInfo } from "../../../../api/detax/model/claimInfo";
import { ClaimsService } from "../../../../services/claims.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import {
  adminTableHeight,
  getTitleCase,
  getTitleCaseToStatus,
  titleCase,
} from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import NumberFormat from "react-number-format";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import { TransactionService } from "../../../../services/transaction.service";
import { CardTransactionInfo } from "../../../../api/detax/model/cardTransactionInfo";
import { useAppSelector } from "../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
export interface IDateRange {
  startDate?: any;
  endDate?: any;
}

const TransactionsList: React.FC<{
  searchVal?: string;
  uid?: string;
  curTbl: TStatusCountInfo;
  filterDate: IDateRange;
  setData: (data: CardTransactionInfo[] | []) => void;
}> = ({ searchVal = "", uid = "", curTbl, filterDate, setData }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const manualTxn = useAppSelector(AlphaReduxStore.selectManualTxn);
  const [isLoading, setIsLoading] = useState(false);
  // const [items, setItems] = useState<Option<CardTransactionInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<CardTransactionInfo[]>>(none);
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [paging, setPaging] = useState<ITableData>({
    total: 10,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    console.log("Changed");
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [curTbl]);
  useEffect(() => {
    // let cardStatus =
    //   page === RoutePaths.DashboardClaims ? card : curTbl?.status;
    //console.log("TABLE", page, card, cardStatus);
    const cardStatus = curTbl.status;
    const run = async () => {
      // setItems(none);
      if (cardStatus) {
        const status = cardStatus === "All" ? "" : cardStatus;
        const tblResult = await TransactionService.getUserTransactions(
          "",
          "",
          "",
          filterDate.startDate
            ? format(new Date(filterDate.startDate), "dd-MM-yyyy")
            : "",
          filterDate.endDate
            ? format(new Date(filterDate.endDate), "dd-MM-yyyy")
            : "",
          "DEBIT",
          "",
          [status],
          paging.page,
          paging.limit
        );

        if (isSuccess(tblResult)) {
          if (tblResult.value.successful) {
            console.log(
              tblResult.value.data?.filter((item) => item.type === "CREDIT")
            );
            setPaging((p) => ({
              ...p,
              total: tblResult.value.recordCount ?? 10,
              page: paging.page,
              limit: paging.limit,
            }));
            // setItems(some(tblResult.value.data || []));
            // filterData(some(tblResult.value.data || []));
            setFilteredData(some(tblResult.value.data || []));
            setData(tblResult.value.data || []);
          } else {
            setErr(true);
            setErrMessage(tblResult.value.message || "");
            // setItems(some([]));
            // filterData(some([]));
            setData([]);
          }
        }
      }
    };
    run();
    //dispatch(resetDashboardRedirect());
  }, [paging.page, paging.limit, paging.total]);

  const tblData = (tblData: CardTransactionInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    // return result;
    return tblData;
  };

  // useEffect(() => filterData(items), [searchVal]);
  // const filterData = (allData: Option<CardTransactionInfo[]>) => {
  //   let data = pipe(
  //     allData,
  //     fold(
  //       () => [],
  //       (d) => (d.length > 0 ? d : [])
  //     )
  //   );
  //   if (searchVal) {
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let txnIdRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as transaction id
  //     let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //     let filteredData = data.filter(
  //       (item) =>
  //         txnIdRegEx.test(item.rrn || "") ||
  //         nameRegEx.test(item.createdBy?.name || "") ||
  //         mobRegEx.test(item.createdBy?.mobile || "")
  //     );
  //     setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(some(filteredData));
  //   } else {
  //     setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(allData);
  //   }
  // };

  if (isNone(filteredData)) {
    return <TableLoader />;
  }

  return (
    <>
      {err ? (
        <div className="text-center">{errMessage}</div>
      ) : (
        pipe(
          filteredData,
          fold(
            () => [<NoTableData />],
            (items) =>
              items.length > 0
                ? [
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Type</th>
                            <th scope="col">Category</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Wallet</th>
                            <th scope="col">Scope</th>
                            <th scope="col">Initiated At</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Approved Amount</th>
                            <th scope="col">Approval Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tblData(items)
                            // .filter((obj) =>
                            //   curTbl.status === "REVIEW_PENDING"
                            //     ? obj.manualTransaction === true
                            //     : true
                            // )
                            .map((item, key) => (
                              <tr key={key}>
                                <td className="text-start">
                                  {item.categoryInfo?.icon && (
                                    <img
                                      src={item.categoryInfo?.icon}
                                      width={40}
                                      alt=""
                                      className="px-2"
                                    />
                                  )}
                                </td>
                                <td>
                                  <Link
                                    className="text-secondary ncash_nav-link"
                                    to={
                                      RoutePaths.DashboardDetaxTransactionDetail +
                                      "/" +
                                      item.txnId +
                                      "/" +
                                      item.createdBy?.uid +
                                      "/" +
                                      "transaction"
                                    }
                                  >
                                    {item.rrn || "-"}
                                  </Link>{" "}
                                </td>
                                <td className="text-start">{item.type} </td>
                                <td className="text-start">
                                  {item.categoryInfo?.name === ""
                                    ? "-"
                                    : item.categoryInfo?.name}
                                </td>
                                <td>{item.createdBy?.name || "-"}</td>
                                <td>{item.createdBy?.mobile || "-"}</td>
                                <td>{item.txnWallet || "-"}</td>
                                <td>{item.scope || "-"}</td>
                                <td className="text-center text-nowrap">
                                  {item.createdAt === "" ? "-" : item.createdAt}
                                </td>
                                <td className="text-center">
                                  {currencyFormat(item.baseAmt ?? 0)}
                                </td>
                                <td className="text-center">
                                  {item.approvalStatus === "APPROVED"
                                    ? currencyFormat(item.appAmt || 0)
                                    : "-"}
                                </td>
                                <td className="text-center text-nowrap">
                                  <StatusText
                                    text={
                                      manualTxn &&
                                      item.approvalStatus ===
                                        "REVIEW_PENDING" &&
                                      item.manualTransaction
                                        ? "Manual Txn Review"
                                        : item.approvalDisplayStatus || "-"
                                    }
                                    className={classNames({
                                      color6:
                                        item.approvalStatus ===
                                        "BILL_UPLOAD_PENDING",
                                      color7:
                                        item.approvalStatus === "APPROVED",
                                      color8:
                                        item.approvalStatus === "REJECTED",
                                      color5:
                                        item.manualTransaction &&
                                        item.approvalStatus ===
                                          "REVIEW_PENDING",
                                      color4:
                                        item.approvalStatus ===
                                        "REVIEW_PENDING",
                                      color13: item.approvalStatus === "FAILED",
                                      color1:
                                        item.approvalStatus ===
                                        "APPROVAL_PENDING",
                                    })}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </TablePagination>,
                  ]
                : [<NoTableData />]
          )
        )
      )}
    </>
  );
};

export default TransactionsList;
