import React from "react";
import FormikInputGroup from "../common/formik/formik-input-group/formik-input-group";

export function ForgotPassWithUsername() {
  return (
    <>
      <div className="mb-0">
        <FormikInputGroup
          icon="user"
          type="text"
          name="mobile"
          maxLength={50}
          className="form-control input-field p-2 border-0"
          placeholder="Enter your email"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
}
