
import React from 'react'

const ToastError: React.FC<{errArray?: string[]}> = ({errArray = []}) => {
  return (
    <div>
        <ol>
        {errArray.map((e, index) => (<li key={index} style={{ fontSize: 12, fontWeight: 700 }}>{e}</li>))}
  
        </ol>
    </div>
  );
}

export default ToastError;



// export const ErrorToast = () => {
//   return (
//     <Card>
//       <Card.Body>
//         <Card.Title>Invalid Input</Card.Title>
//         <ListGroup as="ol" numbered>
//             {errArray.map((e, index) => (<ListGroup.Item key={index} as="li">{e}</ListGroup.Item>))}
            
//         </ListGroup>
//     </Card.Body>
//     </Card>
//   )
// }
