/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { CardTransactionDetailResponse } from "../model/cardTransactionDetailResponse";
import { CardTransactionsMonthlyCountResponse } from "../model/cardTransactionsMonthlyCountResponse";
import { DetaxCardTransactionApprovalRequest } from "../model/detaxCardTransactionApprovalRequest";
import { FetchBulkDataApiRequest } from "../model/fetchBulkDataApiRequest";
import { FetchBulkTransactionResponse } from "../model/fetchBulkTransactionResponse";
import { TransactionApprovalStatusWiseCountApiResponse } from "../model/transactionApprovalStatusWiseCountApiResponse";
import { TransactionHistoryResponse } from "../model/transactionHistoryResponse";
import { CreateRewardApiRequest } from "../model/rewardsApiRequest";
import {
  RewardsApiResponse,
  RewardsConfigApiResponse,
  RewardsConfigRes,
} from "../model/rewardsApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishRewardsApiService {
  /**
   *
   * @param from -
   * @param to -
   * @param uid -
   */
  export const getRewards = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RewardsApiResponse>> => {
    const path = `/secure/detax/giftCard/get`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getRewardLogo = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RewardsConfigApiResponse>> => {
    const path = `/secure/detax/giftCard/config/get`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const createRewards = async (
    body: CreateRewardApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RewardsApiResponse>> => {
    const path = `/secure/detax/giftCard/create`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const deleteRewards = async (
    reward?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RewardsApiResponse>> => {
    const path = `/secure/detax/giftCard/delete`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "rewardName",
        value: reward,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "delete",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updateRewards = async (
    body: CreateRewardApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RewardsApiResponse>> => {
    const path = `/secure/detax/giftCard/update`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [{ key: "id", value: body.id }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
