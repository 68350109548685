import { numberOfVisitedPages } from "../components/app/app.slice";
import { NextActionEnum } from "../enum/next-screen.enum";
import { AuthResponse } from "../model/auth/authResponse";
import { LoginResponse } from "../model/auth/loginResponse";
import { SignupResponse } from "../model/auth/signupResponse";
import { CorpReviewApiResponse } from "../model/corpReviewApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { SendBrResponse } from "../model/sendBrResponse";
import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";
import { ValidateCorpApiResponse } from "../model/validateCorpApiResponse";
import { store } from "../state/store";

export enum RoutePaths {
  // Company Onboarding Routes
  Index = "/",
  ValidateCompany = "/validate-cin", //CurrentScreen.VALIDATE,
  SignUp = "/signup", //CurrentScreen.SIGNUP,
  SignupVerification = "/signup-verify", //CurrentScreen.SIGNUP_VERIFICATION,
  CompanyReview = "/review", //CurrentScreen.COMPANY_REVIEW,
  DigitalContract = "/receipient", //CurrentScreen.CONTRACT_RECEIPIENT,
  SelectServices = "/services", //CurrentScreen.SELECT_SERVICES,
  ReviewApplication = "/app-review", //CurrentScreen.APPLICATION_REVIEW,
  ApplicationSubmitted = "/app-submitted", //CurrentScreen.APPLICATION_SUBMITTED,

  ///////////////////////////////////////////////////////////////////////////
  ValidateCompanyNew = "/manual-onboarding", //CurrentScreen.VALIDATE,
  SignUpNew = "/signup-new", //CurrentScreen.SIGNUP,
  SignupVerificationNew = "/signup-verify-new", //CurrentScreen.SIGNUP_VERIFICATION,
  CompanyReviewNew = "/review-new", //CurrentScreen.COMPANY_REVIEW,
  DigitalContractNew = "/receipient-new", //CurrentScreen.CONTRACT_RECEIPIENT,
  SelectServicesNew = "/services-new", //CurrentScreen.SELECT_SERVICES,
  ReviewApplicationNew = "/app-review-new", //CurrentScreen.APPLICATION_REVIEW,
  ApplicationSubmittedNew = "/app-submitted-new", //CurrentScreen.APPLICATION_SUBMITTED,
  ///////////////////////////////////////////////////////////////////////////

  // Misc Routes
  Login = "/login",
  LoginVerification = "/login-verify",
  UserDetails = "/user-details",
  DigitalContractSuccess = "/digital-contract-success",
  WelcomeBack = "/welcome",
  ForgotPassword = "/forgot-password",
  MustChangePassword = "/must-change-password",

  SwitchAccount = "/switch/:switchToken",

  // Dashboard Routes
  DashboardMyProfile = "/dashboard/myProfile",
  DashboardChangePassword = "/dashboard/changePassword",
  EmployeesSearch = "/dashboard/employeesSearch",
  EmployeesAdd = "/dashboard/add-employees",
  EmployeesDetail = "/dashboard/employeesDetail",
  DashboardHome = "/dashboard/home",
  DashboardEmployees = "/dashboard/employees",
  DashboardDetax = "/dashboard/detax",
  DashboardDetaxDetail = "/dashboard/detaxDetail",
  DashboardMembershipsBatchDetail = "/dashboard/MembershipsBatchDetail",
  DashboardDetaxTransactionDetail = "/dashboard/tranDetail",
  DashboardFxOnline = "/dashboard/fx",
  DashboardNps = "/dashboard/nps",
  DashboardNpsDetail = "/dashboard/npsDetail",
  DashboardAnalysis = "/dashboard/analysis",
  DashboardBatch = "/dashboard/batches",
  DashboardClaims = "/dashboard/claims",
  DashboardTransactions = "/dashboard/transactions",
  DashboardClaimsDetail = "/dashboard/claimsDetail",
  DashboardAA = "/dashboard/aa",
  DashboardAAaccountSection = "/dashboard/aaAccountSection",
  DashboardSubscribe = "/dashboard/subscribe",
  DashboardSettings = "/dashboard/settings",
  PageLoader = "/loader",
  DashboardMemberships = "/dashboard/memberships",
  DashboardMembershipsDetails = "/dashboard/membershipsDetail",
  DashboardSettingsRole = "/dashboard/settings/roles",
  DashboardClaimApprover = "/dashboard/settings/claimApprover",
  DashboardNpsBulkUpload = "/dashboard/nps/bulk-upload",
  DashboardBatchCreate = "/dashboard/batches/create",
}

export const ScreenSteps: Array<{ page: string; step: number }> = [
  { page: RoutePaths.ValidateCompany, step: 1 },
  { page: RoutePaths.SignUp, step: 2 },
  { page: RoutePaths.SignupVerification, step: 2 },
  { page: RoutePaths.CompanyReview, step: 3 },
  { page: RoutePaths.DigitalContract, step: 4 },
  { page: RoutePaths.SelectServices, step: 5 },
  { page: RoutePaths.ReviewApplication, step: 6 },
  { page: RoutePaths.ApplicationSubmitted, step: 7 },
];

export const ScreenStepsNew: Array<{ page: string; step: number }> = [
  { page: RoutePaths.ValidateCompanyNew, step: 1 },
  { page: RoutePaths.SignUpNew, step: 2 },
  { page: RoutePaths.SignupVerificationNew, step: 2 },
  { page: RoutePaths.CompanyReviewNew, step: 3 },
  { page: RoutePaths.DigitalContractNew, step: 4 },
  { page: RoutePaths.SelectServicesNew, step: 5 },
  { page: RoutePaths.ReviewApplicationNew, step: 6 },
  { page: RoutePaths.ApplicationSubmittedNew, step: 7 },
];

export const DashboardScreens: string[] = [
  RoutePaths.DashboardMyProfile,
  RoutePaths.DashboardChangePassword,
  RoutePaths.DashboardHome,
  RoutePaths.DashboardEmployees,
  RoutePaths.DashboardDetax,
  RoutePaths.DashboardFxOnline,
  RoutePaths.DashboardNps,
  RoutePaths.DashboardAnalysis,
  RoutePaths.DashboardBatch,
  RoutePaths.DashboardClaims,
  RoutePaths.DashboardSettings,
  RoutePaths.EmployeesSearch,
  RoutePaths.EmployeesAdd,
  RoutePaths.EmployeesDetail,
  RoutePaths.DashboardClaimsDetail,
  RoutePaths.DashboardDetaxDetail,
  RoutePaths.DashboardNpsDetail,
  RoutePaths.DashboardAA,
  RoutePaths.DashboardAAaccountSection,
  RoutePaths.DashboardMemberships,
  RoutePaths.DashboardMembershipsDetails,
  RoutePaths.DashboardMembershipsBatchDetail,
  RoutePaths.DashboardSettingsRole,
  RoutePaths.DashboardClaimApprover,
  RoutePaths.DashboardNpsBulkUpload,
  RoutePaths.DashboardBatchCreate,
];

export const GetCompletedScreen = (currentScreen: string) => {
  // to do: add logic when signup and contract_review comes.
  // make it blue when we have number from 0 to return number
  const visitedPages: number[] = [];

  // const currentStep =
  //   ScreenSteps.find((s) => s.page === currentScreen)?.step || 0;  // cin - flow

  const currentStep =
    ScreenStepsNew.find((s) => s.page === currentScreen)?.step || 0;

  console.log(currentStep);
  if (currentStep) {
    for (let i = 1; i <= currentStep; i++) {
      visitedPages.push(i);
    }
    if (currentScreen === RoutePaths.ApplicationSubmittedNew) {
      // cin - flow
      store.dispatch(numberOfVisitedPages([1, 2, 3, 4, 5, 6, 7]));
    } else {
      store.dispatch(numberOfVisitedPages(visitedPages));
    }
  }
};

export function ConvertToRoutePath(
  nextAction?:
    | string
    | NextActionEnum
    | SignupResponse.NextActionEnum
    | AuthResponse.NextActionEnum
    | UpdateSignatoriesApiResponse.NextActionEnum
    | SendBrResponse.NextActionEnum
    | CorpReviewApiResponse.NextActionEnum
    | ValidateCorpApiResponse.NextActionEnum
    | LoginResponse.RedirectionEnum
    | CorpUpdateServiceApiResponse.NextActionEnum
): string | undefined {
  if (!nextAction) {
    return undefined;
  } else {
    switch (nextAction) {
      case NextActionEnum.SIGNUP:
        return RoutePaths.SignUp;
      case NextActionEnum.SIGNUPVERIFICATION:
        return RoutePaths.SignupVerification;
      case NextActionEnum.COMPANYREVIEW:
        return RoutePaths.CompanyReview;
      case NextActionEnum.CONTRACTRECEIPIENT:
        return RoutePaths.DigitalContract;
      case NextActionEnum.SELECTSERVICES:
        return RoutePaths.SelectServices;
      case NextActionEnum.APPLICATIONREVIEW:
        return RoutePaths.ReviewApplication;
      case NextActionEnum.APPLICATIONSUBMITTED:
        return RoutePaths.ApplicationSubmitted;
      case NextActionEnum.DASHBOARD:
        return RoutePaths.DashboardHome;
      case NextActionEnum.LOGIN:
        return RoutePaths.Login;
      case NextActionEnum.LOGINVERIFICATION:
        return RoutePaths.LoginVerification;
      case NextActionEnum.FXRETAIL:
        return RoutePaths.DashboardFxOnline;
      //case NextActionEnum.SENDREDIRECT:
      //return RoutePaths.WelcomeBack;
      default:
        return nextAction;
    }
  }
}
