import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClaimsService } from "../../../../services/claims.service";
import { fromPublic } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import Heading from "../common/heading";
import { SetApprover } from "./SetApprover";

const ClaimApprover: React.FC = () => {
  const welcomeClaim = fromPublic("/images/welcome_claim.svg");
  const welcomeClaimLeft = fromPublic("/images/welcome_claim_left.svg");
  const welcomeClaimRight = fromPublic("/images/welcome_claim_right.svg");

  const navigate = useNavigate();
  // const defaultApprover = "Sathis";
  const [slide, setSlide] = useState(0);
  const [defaultApproverName, setDefaultApproverName] = useState("");
  const [defaultApproverEmail, setDefaultApproverEmail] = useState("");
  const [err, setErr] = useState(false);
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      const tblResult = await ClaimsService.getClaimApprovers();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setDefaultApproverName(tblResult.value.data?.[0]?.name ?? "");
          setDefaultApproverEmail(tblResult.value.data?.[0]?.email ?? "");
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
    };
    run();
  }, []);

  return (
    <>
      <PageTitle title="Settings" />
      <Heading
        title="Settings"
        detail={"Claim Approvers"}
        links={[RoutePaths.DashboardSettings]}
      />
      <div
        className="mt-3 h-100 d-flex align-items-center justify-content-center w-100"
        // style={{ height: "100vh" }}
      >
        {slide === 0 ? (
          <div className="d-flex row h-100">
            <div>
              <h4>
                <b>Welcome to the Claim Approver Set Up flow </b>
              </h4>
            </div>
            <div className="">
              <ul className="lh-lg">
                <li>
                  All claims currently raised by your employees will be sent to{" "}
                  <b>{defaultApproverName}</b> for approval.
                </li>
                <li>
                  There are two simple steps in this process
                  <ul>
                    <li>Step 1 – Choose the list of claim approvers</li>
                    <li>
                      Step 2 – Individually define the scope of each claim
                      approver{" "}
                      <ul>
                        <li>
                          With this flow, you will be able to set the claim
                          approver for employees at{" "}
                          <ol>
                            <li>An individual level </li>
                            <li>For the entire business unit </li>
                            <li>For the entire organization</li>
                          </ol>
                        </li>
                        <li>
                          Claim approvers can also be set for a specific amount.
                          For example – if employee A raises a claim for Rs.
                          2000, then the approval will be sent to one approver
                          and any amount greater than Rs. 2000 will be sent to
                          another approver.{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <button
                className="btn-gradiant-blue text-white px-3 py-2 mt-3"
                onClick={() => {
                  setSlide(1);
                }}
              >
                Choose set of approvers in your organisation
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-end align-items-baseline h-100">
              <img
                // className="img-fluid"
                width="25%"
                height="25%"
                src={welcomeClaim}
                alt="Welcome"
              />
            </div>
          </div>
        ) : null}

        {/* {show === 1 ? (
          <div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="pe-3">
                <h6>
                  <b>Employees</b>
                </h6>
                <div className="box p-0">
                  <div className="search-bg p-1 sticky-top">
                    <div className=" widthinput p-1">
                      <input
                        // required
                        // value={referenceId}
                        onChange={handleSearch}
                        type="text"
                        className="form-control"
                        placeholder="Search by Employees"
                      />
                    </div>
                  </div>
                  <div className="overflow-scroll box1">
                    {filteredAvailableUsers.map((user: any) => (
                      <div
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        className="p-2 ps-2 roles tr2"
                      >
                        {user.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ps-3">
                <h6>
                  <b>Approvers</b>
                </h6>
                <div className="box overflow-scroll ">
                  {selectedUsers.map((user) => (
                    <div
                      key={user.uid}
                      onClick={() => handleUserRemove(user)}
                      className="border b-color d-flex flex-row justify-content-between mt-2 p-2 ps-2"
                    >
                      <div> {user.name}</div>
                      <div className="pe-1 roles">
                        <img src="/images/icons/Close.svg" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-4 mb-3 w-75">
              <button
                className="btn-gradiant-blue text-white px-3 py-2"
                onClick={() => {
                  setShow(2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        ) : null} */}
        {slide === 2 || slide === 1 ? (
          <div
            className="d-flex col  w-100"
            style={{ height: "85vh", overflowX: "hidden", overflowY: "auto" }}
          >
            {slide === 1 ? (
              <div
                className="d-flex align-items-center justify-content-center pe-2"
                style={{ width: "20vw" }}
              >
                <img
                  // className="img-fluid"
                  width="80%"
                  height="80%"
                  src={welcomeClaimLeft}
                  alt="Welcome"
                />
              </div>
            ) : null}
            <div
              style={{
                width: slide === 1 ? "60vw" : "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SetApprover
                defaultApproverName={defaultApproverName}
                defaultApproverEmail={defaultApproverEmail}
                slide={slide}
                setSlide={(data: any) => setSlide(data)}
                btnView={true}
                editApprover={null}
                setEditApprover={() => ""}
                closeModal={() => ""}
              />
            </div>
            {slide === 1 ? (
              <div
                className="d-flex align-items-center justify-content-center ps-2"
                style={{ width: "20vw" }}
              >
                <img
                  // className="img-fluid"
                  width="100%"
                  height="100%"
                  src={welcomeClaimRight}
                  alt="Welcome"
                />
              </div>
            ) : null}
          </div>
        ) : // <div>
        //   <div>
        //     <div className="d-flex flex-row ">
        //       {selectedUsers?.map((item, index) => (
        //         <div
        //           className={`me-5 pb-1 roles ${
        //             index === data ? "bottombar" : ""
        //           } `}
        //           key={index}
        //           // onClick={() => setData(index)}
        //         >
        //           {item.name}
        //         </div>
        //       ))}
        //     </div>
        //     <div className="mt-3">
        //       <div className="maincon">
        //         {/* {hasExistingEmployees() ? null : "Hi"} */}
        //         <div className="namebar">
        //           <div className="pe-5">
        //             <h5>
        //               {selectedUsers?.length === 0
        //                 ? ""
        //                 : selectedUsers[data]?.name}
        //             </h5>
        //           </div>
        //           <div className="pe-5 ps-5">
        //             {selectedUsers?.length === 0
        //               ? ""
        //               : selectedUsers[data].email}
        //           </div>
        //           <div className="pe-5 ps-5">
        //             {selectedUsers?.length === 0
        //               ? ""
        //               : selectedUsers[data].mobile}
        //           </div>
        //           {/* <div className="pe-5 ps-5">
        //             Department :{" "}
        //             {selectedUsers?.length === 0
        //               ? ""
        //               : selectedUsers[data].status}
        //           </div> */}
        //         </div>
        //         <div className="midcon">
        //           <div className="subcon2">
        //             <div>
        //               <div>
        //                 <b>Approves</b>
        //               </div>
        //               <Select
        //                 value={approves}
        //                 options={approvesoption}
        //                 onChange={handleApproves}
        //               />
        //             </div>
        //             <div className="ps-5">
        //               {approves?.value === "SPECIFIC_EMPLOYEES" ? (
        //                 <div>
        //                   <div>
        //                     <b>Select Employees</b>
        //                   </div>
        //                   <Select
        //                     value={selectemp}
        //                     options={employeeoption}
        //                     onChange={handleMultiSelectChange}
        //                     isMulti
        //                   />
        //                 </div>
        //               ) : approves?.value === "BUSINESS_UNIT" ? (
        //                 <div>
        //                   <div>
        //                     <b>Business Unit</b>
        //                   </div>
        //                   <Select
        //                     value={business}
        //                     options={deptopt}
        //                     onChange={handleBusiness}
        //                   />
        //                 </div>
        //               ) : (
        //                 <div>
        //                   {/* <div>Business Unit</div>
        //                 <Select
        //                   value={business}
        //                   options={businessoption}
        //                   onChange={handleBusiness}
        //                 /> */}
        //                 </div>
        //               )}
        //             </div>
        //             <div className="ps-5">
        //               <div>
        //                 <b>Configure Amount</b>
        //               </div>
        //               <div className="mt-2 d-flex  justify-content-center">
        //                 <div className="form-check form-check-inline">
        //                   <input
        //                     className="form-check-input"
        //                     type="radio"
        //                     name="inlineRadioOptions"
        //                     id="inlineRadio1"
        //                     value="yes"
        //                     checked={configamount === "yes"}
        //                     onChange={(event) =>
        //                       setConfigamount(event.target.value)
        //                     }
        //                   />
        //                   <label
        //                     className="form-check-label"
        //                     htmlFor="inlineRadio1"
        //                   >
        //                     Yes
        //                   </label>
        //                 </div>
        //                 <div className="form-check form-check-inline">
        //                   <input
        //                     className="form-check-input"
        //                     type="radio"
        //                     name="inlineRadioOptions"
        //                     id="inlineRadio2"
        //                     value="no"
        //                     onChange={(event) =>
        //                       setConfigamount(event.target.value)
        //                     }
        //                     checked={configamount === "no"}
        //                   />
        //                   <label
        //                     className="form-check-label"
        //                     htmlFor="inlineRadio2"
        //                   >
        //                     No
        //                   </label>
        //                 </div>
        //               </div>
        //             </div>
        //             <div className="ps-5">
        //               {configamount === "yes" ? (
        //                 <div>
        //                   <div>
        //                     <b>For Amount</b>
        //                   </div>
        //                   <div className="d-flex flex-row">
        //                     <input
        //                       placeholder="<="
        //                       disabled={true}
        //                       type="number"
        //                       className="form-control textfield1"
        //                     />
        //                     <div className="ps-2 pe-1 pt-1">Rs.</div>
        //                     <input
        //                       required
        //                       type="number"
        //                       className="form-control textfield"
        //                       placeholder="Enter amount"
        //                       value={amount}
        //                       onChange={(e) => setAmount(e.target.value)}
        //                     />
        //                   </div>
        //                 </div>
        //               ) : null}
        //             </div>
        //           </div>
        //           <div className="subcon3">
        //             {/* <div>
        //                 <div>
        //                   <b>Submits to</b>
        //                 </div>
        //                 <div className="d-flex flex-row align-items-center">
        //                   <Select
        //                     value={submits}
        //                     options={submitsoption}
        //                     onChange={handleSubmits}
        //                     className="pe-3"
        //                   />
        //                   <div>for all amounts</div>
        //                 </div>
        //               </div> */}
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        //   <div className="d-flex justify-content-end align-items-center mt-4 mb-3 w-75">
        //     {/* <button
        //       className="btn-ncaish-light text-white px-3 py-2"
        //       onClick={() => {
        //         // closeModal();
        //         // defaultData();
        //         setView(true);
        //       }}
        //     >
        //       Cancel
        //     </button> */}
        //     <button
        //       disabled={
        //         selectedUsers?.length < 1 || view === true
        //           ? submitDisable()
        //           : false
        //       }
        //       className="btn-gradiant-blue text-white px-3 py-2"
        //       onClick={() => {
        //         onSubmit();
        //         // if (data !== selectedUsers?.length - 1) {
        //         //   onSubmit();
        //         //   console.log("Submit, not");
        //         // } else if (data === selectedUsers?.length - 1) {
        //         //   console.log("Submit, equal");
        //         // }
        //       }}
        //       // onClick={() => onSubmit()}
        //     >
        //       {/* {view === false ? "Next" : "Submit"} */}
        //       {data === selectedUsers?.length - 1 ? "Submit" : "Next"}
        //     </button>
        //   </div>
        // </div>
        null}
      </div>
    </>
  );
};
export default ClaimApprover;
