import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "../../state/store";
import { TBrReceipient } from "../../types/br-receipient.type";
import { TCorporateUser } from "../../types/corporate-user.type";

export interface CompanyState {
  receipients: TBrReceipient[];
  directors: TCorporateUser[];
}

const initialState: CompanyState = {
  receipients: [],
  directors: [],
};

const slice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    initializeContract: () => initialState,
    setReceipients: (
      state,
      { payload: receipients }: PayloadAction<TBrReceipient[]>
    ) => {
      state.receipients = receipients;
    },
    setDirectors: (
      state,
      { payload: directors }: PayloadAction<TCorporateUser[]>
    ) => {
      state.directors = directors;
    },
  },
});

export const { initializeContract, setReceipients, setDirectors } =
  slice.actions;

export const selectReceipients = (state: State) => state.contract.receipients;
export const selectDirectors = (state: State) => state.contract.directors;

export default slice.reducer;
