import { useMutation } from "react-query";
import { AuthService } from "../services/auth.service";

export const useLoginWithPassword = () => {
  return useMutation(AuthService.loginUsingPassword);
};

export const useLoginWithToken = () => {
  return useMutation(AuthService.loginWithToken);
};
