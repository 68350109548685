import { isSuccess } from "@devexperts/remote-data-ts";
import { fromNullable } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { NcaishDetaxSubscriptonApiService } from "../../../../api/detax/api/ncaishDetaxSubscriptonApi.service";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";
import { useAppSelector } from "../../../../hooks/hooks";
import { ClaimsService } from "../../../../services/claims.service";
import { EmployeeService } from "../../../../services/employee.service";
import { Dispatch } from "../../../../state/dispatch";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import { fromPublic } from "../../../../utils/common.utils";
import { unWrapOption } from "../../../../utils/option.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import WhiteCard from "../../../white-card/white-card";
import SuccessPopup from "../common/succes-popup";
import "./settings.scss";
interface Option2 {
  uid: string;
  name: string;
  value: string;
  label: string;
}
interface CustomOptionType {
  uid: string;
  name: string;
  value: string;
  label: string;
  mobile: string;
  email: string;
  department: string;
}
interface Option {
  value: string;
  label: string;
}

const welcomeClaimFinal = "/images/claim_illustration.svg";
const EmailIcon = "/images/line_icons/Email.svg";
const DepartmentIcon = "/images/line_icons/Department.svg";
const PhoneIcon = "/images/line_icons/Mobile_No.svg";
export const SetApprover: React.FC<{
  slide: number;
  defaultApproverName: string;
  setSlide: (data: any) => any;
  btnView: boolean;
  defaultApproverEmail: string;
  editApprover: any;
  setEditApprover: (data: any) => any;
  closeModal: (data: any) => any;
}> = ({
  slide,
  defaultApproverName,
  setSlide,
  btnView,
  defaultApproverEmail,
  editApprover,
  setEditApprover,
  closeModal,
}) => {
  const swapIcon = fromPublic("/images/swap_icon.svg");
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  // const [slide, setSlide] = useState(0);
  const [view, setView] = useState(false);
  const [showSuccess, setShowSuccess] = useState({ show: false, message: "" });
  const [showError, setShowError] = useState({ show: false, message: "" });
  // const [actionState1, setActionState1] = useState(false);
  const [data, setData] = useState(0);
  const [defaultApprover, setDefaultApprover] = useState("");
  const [err, setErr] = useState(false);
  const [department, setDepartment] = useState<BusinessUnit>();
  const [employees, setEmployees] = useState<DetaxCardSubscriptionInfo[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<
    DetaxCardSubscriptionInfo[]
  >([]);
  console.log("Slide", slide);
  const [availableUsers, setAvailableUsers] = useState<
    DetaxCardSubscriptionInfo[]
  >([]);
  const [errMsg, setErrMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const filterCondition = useAppSelector(
    AlphaReduxStore.selectNewClaimsEmployee
  );
  console.log("Edit Approver Test", editApprover);
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      const tblResult = await ClaimsService.getClaimApprovers();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setDefaultApprover(tblResult.value.data?.[0]?.name ?? "");
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();
  }, []);

  useEffect(() => {
    const run = async () => {
      getData();
    };
    run();
    const run1 = async () => {
      const result = await EmployeeService.getBusinessUnit();
      if (isSuccess(result)) {
        if (result.value.successful) {
          console.log("test", result.value.data);
          setDepartment((prev) => (prev = result.value.data || prev));
          // result.value.data && loadEmployee(result.value.data);
        }
      }
    };
    run1();
  }, []);

  const getData = async () => {
    const statusString = "ACTIVATED";
    const result = await NcaishDetaxSubscriptonApiService.getSubscription(
      statusString,
      1,
      10000000
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        setEmployees(result.value.data || []);
        setAvailableUsers(result.value.data || []);
      }
    }
  };
  const employeeoption: CustomOptionType[] | undefined = employees?.map(
    (employee) => ({
      uid: employee.uid || "",
      name: employee.name || "",
      value: employee.uid || "",
      label: employee.name || "",
      mobile: employee.mobile || "",
      email: employee.email || "",
      department: employee.businessUnit || "",
    })
  );
  const handleUserSelect = (user: DetaxCardSubscriptionInfo) => {
    // Check if the user is already selected or the maximum limit is reached
    if (
      selectedUsers?.some((selectedUser) => selectedUser.uid === user.uid) ||
      (selectedUsers?.length ?? 0) >= 5
    ) {
      return;
    }

    // Remove the selected user from the available users list
    setAvailableUsers((prevUsers: DetaxCardSubscriptionInfo[] | undefined) =>
      prevUsers
        ? prevUsers.filter((availableUser) => availableUser.uid !== user.uid)
        : []
    );

    // Add the selected user to the selected users list
    setSelectedUsers((prevUsers: DetaxCardSubscriptionInfo[] | undefined) =>
      prevUsers ? [...prevUsers, user] : [user]
    );
  };

  const handleUserRemove = (user: DetaxCardSubscriptionInfo) => {
    // Remove the user from the selected users list
    setSelectedUsers((prevUsers: DetaxCardSubscriptionInfo[] | undefined) =>
      prevUsers
        ? prevUsers.filter((selectedUser) => selectedUser.uid !== user.uid)
        : []
    );

    // Add the removed user back to the available users list
    setAvailableUsers((prevUsers: DetaxCardSubscriptionInfo[] | undefined) =>
      prevUsers ? [...prevUsers, user] : [user]
    );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredAvailableUsers = availableUsers
    .filter((user: any) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((obj) => obj.email !== defaultApproverEmail);

  const approvesoption = [
    { value: "SPECIFIC_EMPLOYEES", label: "for specific employees" },
    { value: "BUSINESS_UNIT", label: "for the business unit" },
    { value: "ENTIRE_ORG", label: "for the entire organization" },
  ];

  const approvesScopeName = (data: any) => {
    if (data === "SPECIFIC_EMPLOYEES") {
      return "For specific employees";
    } else if (data === "BUSINESS_UNIT") {
      return "For the business unit";
    } else if (data === "ENTIRE_ORG") {
      return "For the entire organization";
    } else {
      return null;
    }
  };

  const [requestData, setRequestData] = useState(
    selectedUsers.map((obj) => ({
      ...obj,
      approves: { value: "", label: "" },
      business: { value: "", label: "" },
      selectemp: [],
      configamount: "no",
      amount: "0",
    }))
  );
  useEffect(() => {
    let combineDetails = selectedUsers.map((obj) => ({
      ...obj,
      approves: { value: "", label: "" },
      business: { value: "", label: "" },
      selectemp: [],
      configamount: "no",
      amount: "0",
    }));
    const approverLabel =
      editApprover !== null && editApprover.approvesFor
        ? approvesoption.filter(
            (obj) =>
              obj.value === editApprover.approvesFor[0]?.claimApproverScope
          )
        : [{ value: "", label: "" }];

    const selectempLabel =
      editApprover !== null && editApprover.approvesFor
        ? employeeoption.filter((obj) => obj.email !== editApprover.email)
        : [];
    const editEmployee =
      editApprover !== null &&
      editApprover.approvesFor &&
      editApprover.approvesFor[0]?.employees
        ? selectempLabel.filter((obj) =>
            editApprover.approvesFor[0].employees.includes(obj.uid)
          )
        : [];
    console.log("test run", approverLabel[0].value);
    console.log("Selected Emp", editEmployee, selectedUsers);
    setRequestData(
      editApprover !== null && editApprover.approvesFor
        ? [
            {
              ...editApprover,
              approves: {
                value: approverLabel[0].value || "",
                label: approverLabel[0].label || "",
              },
              business: {
                value: editApprover?.approvesFor?.[0]?.businessUnit || "",
                label: editApprover?.approvesFor?.[0]?.businessUnit || "",
              },
              selectemp: editEmployee,
              configamount:
                parseFloat(editApprover?.approvesFor?.[0]?.amount || 0) > 0
                  ? "yes"
                  : "no",
              amount: parseFloat(editApprover?.approvesFor?.[0]?.amount || 0),
            },
          ]
        : combineDetails
    );
  }, [slide, selectedUsers, employees]);

  console.log("RequestData", requestData);
  const handleInputChange = (index: number, name: string, value: any) => {
    const updatedRequestData = [...requestData];
    updatedRequestData[index] = { ...updatedRequestData[index], [name]: value };
    setRequestData(updatedRequestData);
  };

  const defaultData = () => {
    setSelectedUsers([]);
    setView(false);
    // setAvailableUsers([]);
  };
  // let uid = "";
  const uid = requestData[data]?.selectemp?.map((obj: any) => obj.uid) || [];

  let requestBody: any;

  if (requestData[data] && requestData[data].approves?.value === "ENTIRE_ORG") {
    requestBody = {
      email: requestData[data].email,
      name: requestData[data].name,
      approvalScope: "ENTIRE_ORG",
      amount:
        requestData[data].configamount === "yes"
          ? parseFloat(requestData[data].amount) === null
            ? 0
            : parseFloat(requestData[data].amount)
          : 0,
      businessUnit: requestData[data].businessUnit,
    };
  } else if (
    requestData[data] &&
    requestData[data].approves?.value === "BUSINESS_UNIT"
  ) {
    requestBody = {
      email: requestData[data].email,
      name: requestData[data].name,
      approvalScope: "BUSINESS_UNIT",
      businessUnit: requestData[data].businessUnit,
      amount:
        requestData[data].configamount === "yes"
          ? parseFloat(requestData[data].amount) === null
            ? 0
            : parseFloat(requestData[data].amount)
          : 0,
    };
  } else if (
    requestData[data] &&
    requestData[data].approves?.value === "SPECIFIC_EMPLOYEES"
  ) {
    requestBody = {
      email: requestData[data].email,
      name: requestData[data].name,
      approvalScope: "SPECIFIC_EMPLOYEES",
      employees: uid,
      amount:
        requestData[data].configamount === "yes"
          ? parseFloat(requestData[data].amount) === null
            ? 0
            : parseFloat(requestData[data].amount)
          : 0,
      businessUnit: requestData[data].businessUnit,
    };
  }
  let approverDefId = "";
  let approverUid = "";

  if (editApprover !== null && editApprover?.approvesFor) {
    approverDefId = editApprover.approvesFor[0]?.approverDefId || "";
    approverUid = editApprover.approvesFor[0]?.approverUid || "";
  }

  const updateData = {
    ...requestBody,
    approverDefId: approverDefId,
    approverUid: approverUid,
    claimApproverScope: requestBody?.approvalScope ?? "",
  };

  const onSubmit = async () => {
    setErrMsg("");
    const result = await ClaimsService.createClaimApprover(requestBody);
    if (isSuccess(result)) {
      if (result.value.successful) {
        console.log("Success");
        setShowSuccess({
          ...showSuccess,
          show: true,
          message: "Submitted Successfully",
        });
        reset();
        // closeModal();
      } else {
        setErrMsg(result.value.message ?? "");
        setShowError({ show: true, message: result.value.message ?? "" });
        Dispatch.App.setCommonError({
          showError: true,
          errorText: unWrapOption(fromNullable(result.value.message), () => ""),
        });
      }
    }
  };
  const updateClaimApprover = async () => {
    setErrMsg("");
    const result = await ClaimsService.updateClaimApprover(updateData);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setShowSuccess({
          ...showSuccess,
          show: true,
          message: "Updated Successfully",
        });
        closeModal(false);
      } else {
        setErrMsg(result.value.message ?? "");
        setShowError({ show: true, message: "Update Failed" });
        Dispatch.App.setCommonError({
          showError: true,
          errorText: unWrapOption(fromNullable(result.value.message), () => ""),
        });
      }
    } else {
    }
    //   setReload(false);
  };
  // useEffect(() => {
  //   setRequestData([]);
  //   setData(0);
  //   setSelectedUsers([]);
  // }, [closeModal(false)]);

  const reset = () => {
    if (data < selectedUsers.length - 1) {
      setData(data + 1);
      console.log("Test Done");
    } else if (data === selectedUsers?.length - 1) {
      console.log("Test navigate");
      closeModal(false);
      navigate(RoutePaths.DashboardSettingsRole);
      setRequestData([]);
      setSlide(1);
    }

    // setSlide(1);
  };

  const submitDisable = () => {
    if (
      (requestData[data] && requestData[data].approves.value) ??
      "".length > 0
    ) {
      if (requestData[data].approves.value === "ENTIRE_ORG") {
        return false;
      } else if (
        requestData[data] &&
        requestData[data].approves.value === "BUSINESS_UNIT"
      ) {
        if ((requestData[data].approves.value ?? "").length > 0) {
          return false;
        } else {
          return true;
        }
      } else if (
        requestData[data] &&
        requestData[data].approves.value === "SPECIFIC_EMPLOYEES"
      ) {
        if (uid.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  return (
    <div className="w-100 h-100">
      <SuccessPopup
        message={showSuccess.message}
        show={showSuccess.show}
        onClose={() => {
          setShowSuccess({ ...showSuccess, show: false, message: "" });
        }}
      />

      {/* <ErrorPopup
        message={showError.message}
        show={showError.show}
        onClose={(flag) => {
          setShowError({ show: false, message: "" });
        }}
      /> */}
      {slide === 1 ? (
        <div>
          <div className="d-flex flex-row justify-content-center align-items-center h-100">
            <div className="pe-3 h-100">
              <h6>
                <b>Employees</b>
              </h6>
              <div className="box p-0">
                <div className="search-bg p-1 sticky-top">
                  <div className=" widthinput p-1">
                    <input
                      // required
                      // value={referenceId}
                      onChange={handleSearch}
                      type="text"
                      className="form-control"
                      placeholder="Search by Employees"
                    />
                  </div>
                </div>
                <div className="overflow-scroll box1">
                  {filteredAvailableUsers.map((user: any) => (
                    <div
                      key={user.id}
                      onClick={() => handleUserSelect(user)}
                      className="p-2 ps-2 roles tr2"
                    >
                      {user.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <img
                // className="img-fluid"
                width="25"
                height="25"
                src={swapIcon}
                alt="Welcome"
              />
            </div>
            <div className="ps-3">
              <h6>
                <b>Approvers</b>
              </h6>
              <div className="box overflow-scroll ">
                {defaultApproverName === "" ? null : (
                  <div className="border b-color d-flex flex-row justify-content-between mt-2 p-2 ps-2">
                    <div>
                      <b>{`${defaultApproverName} - Default Approver`}</b>
                    </div>
                  </div>
                )}

                {selectedUsers.map((user: any) => (
                  <div
                    key={user.uid}
                    onClick={() => handleUserRemove(user)}
                    className="border b-color d-flex flex-row justify-content-between mt-2 p-2 ps-2"
                  >
                    <div> {user.name}</div>
                    <div className="pe-1 roles">
                      <img src="/images/icons/Close.svg" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-end align-items-center mt-4 mb-3"
            style={{ width: !btnView ? "100%" : "100%" }}
          >
            <button
              disabled={selectedUsers.length === 0}
              className="btn-gradiant-blue text-white px-3 py-2"
              onClick={() => {
                setSlide(2);
              }}
            >
              Next
            </button>
          </div>
        </div>
      ) : null}
      {slide === 2 ? (
        <div className="d-flex col">
          <div className="w-100">
            <div className="d-flex flex-row border-bottom">
              {selectedUsers?.map((item: any, index: number) => (
                <div
                  className={`me-5 pb-1 roles fs-5 ${
                    index === data ? "bottombar" : ""
                  } `}
                  key={index}
                  // onClick={() => setData(index)}
                >
                  {item.name}
                </div>
              ))}
              {selectedUsers?.length === 0 ? (
                <div className={`me-5 pb-1 roles fs-5 bottombar`}>
                  {requestData[0]?.name}
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <div
                className="maincon"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {/* {hasExistingEmployees() ? null : "Hi"} */}
                <WhiteCard className="p-1">
                  <div className="namebar row-cols-auto justify-content-around">
                    {/* <div className="pe-5">
                    <h5>
                      {selectedUsers?.length === 0
                        ? ""
                        : selectedUsers[data]?.name}
                    </h5>
                     </div> */}
                    <div className="d-flex col  align-items-center">
                      <div className="col-2  text-right pe-5">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmailIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="mb-0 f-13">Email</p>
                        {selectedUsers?.length === 0
                          ? requestData[0]?.email
                          : selectedUsers[data].email}
                      </div>
                    </div>
                    <div className="d-flex col  align-items-center">
                      <div className="col-2 text-right pe-5">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={PhoneIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="mb-0 f-13">Mobile No.</p>
                        {selectedUsers?.length === 0
                          ? requestData[0]?.mobile
                          : selectedUsers[data].mobile}
                      </div>
                    </div>
                    <div className="d-flex col  align-items-center">
                      <div className="col-2  text-right pe-5">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={DepartmentIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="mb-0 f-13">Department </p>
                        {selectedUsers?.length === 0
                          ? requestData[0]?.businessUnit
                          : selectedUsers[data].businessUnit ?? "Err"}
                      </div>
                    </div>
                  </div>
                </WhiteCard>
                <div className="midcon">
                  {/* {btnView && (
                    <div className="d-flex align-items-end w-100 position-absolute justify-content-end">
                      <img
                        src={welcomeClaimFinal}
                        width={"30%"}
                        height={"30%"}
                      />
                    </div>
                  )} */}
                  {/* <WhiteCard className="p-3"> */}
                  {requestData.map((obj, index) => (
                    <div
                      className="subcon2"
                      hidden={index === data ? false : true}
                      key={index}
                    >
                      <div className="ps-2 pt-2">
                        <div>
                          <b>Approves</b>
                        </div>
                        <Select
                          name="approves"
                          value={obj.approves}
                          options={approvesoption.filter((obj) => {
                            if (filterCondition) {
                              return obj.value !== "";
                            } else {
                              return obj.value !== "SPECIFIC_EMPLOYEES";
                            }
                          })}
                          onChange={(selectedOption) =>
                            handleInputChange(index, "approves", selectedOption)
                          }
                        />
                      </div>
                      <div className="ps-2 pt-3">
                        {obj.approves?.value === "SPECIFIC_EMPLOYEES" ? (
                          <div>
                            <div>
                              <b>Select Employees</b>
                            </div>
                            <Select
                              name="selectemp"
                              value={obj.selectemp}
                              options={employeeoption.filter(
                                (obj: any) =>
                                  obj.email !== requestData[data].email ?? ""
                              )}
                              closeMenuOnSelect={false}
                              // onChange={handleMultiSelectChange}
                              onChange={(selectedOption) =>
                                handleInputChange(
                                  index,
                                  "selectemp",
                                  selectedOption
                                )
                              }
                              components={animatedComponents}
                              isMulti
                            />
                          </div>
                        ) : obj.approves?.value === "BUSINESS_UNIT" ? (
                          <div>
                            {/* <div>
                                <b>Business Unit</b>
                              </div>
                              <Select
                                name="business"
                                value={obj.business}
                                options={deptopt}
                                // onChange={handleBusiness}
                                onChange={(selectedOption) =>
                                  handleInputChange(
                                    index,
                                    "business",
                                    selectedOption
                                  )
                                }
                              /> */}
                          </div>
                        ) : (
                          <div>
                            {/* <div>Business Unit</div>
                              <Select
                                value={business}
                                options={businessoption}
                                onChange={handleBusiness}
                              /> */}
                          </div>
                        )}
                      </div>
                      <div className="ps-2 pt-3">
                        <div>
                          <b>Configure Amount</b>
                        </div>
                        <div className="mt-2 ">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`configamount-${index}`}
                              id="inlineRadio1"
                              value="yes"
                              checked={obj.configamount === "yes"}
                              // onChange={(event) =>
                              //   setConfigamount(event.target.value)
                              // }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "configamount",
                                  e.target.value
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`configamount-${index}`}
                              id="inlineRadio2"
                              value="no"
                              // onChange={(event) =>
                              //   setConfigamount(event.target.value)
                              // }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "configamount",
                                  e.target.value
                                )
                              }
                              checked={obj.configamount === "no"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="ps-2 pt-3">
                        {obj.configamount === "yes" ? (
                          <div>
                            <div>
                              <b>For Amount</b>
                            </div>
                            <div className="d-flex flex-row">
                              <input
                                placeholder="<="
                                disabled={true}
                                type="number"
                                className="form-control textfield1"
                              />
                              <div className="ps-2 pe-1 pt-1">Rs.</div>
                              <input
                                required
                                name="amount"
                                type="number"
                                className="form-control textfield"
                                placeholder="Enter amount"
                                value={obj.amount}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "amount",
                                    e.target.value
                                  )
                                }
                                // onChange={(e) => setAmount(e.target.value)}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  {/* </WhiteCard> */}
                  <div className="subcon3">
                    {/* <div>
                    <div>
                      <b>Submits to</b>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <Select
                        value={submits}
                        options={submitsoption}
                        onChange={handleSubmits}
                        className="pe-3"
                      />
                      <div>for all amounts</div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-end align-items-center mt-4 mb-3"
              style={{ width: !btnView ? "100%" : "100%" }}
            >
              {/* <div className="text-danger" style={{ marginRight: "3%" }}>
                {errMsg !== "" ? errMsg : null}
              </div> */}
              <button
                disabled={
                  editApprover === null
                    ? selectedUsers?.length < 1 || slide === 2
                      ? submitDisable() ?? false
                      : false
                    : false
                }
                className="btn-gradiant-blue text-white px-3 py-2"
                onClick={() => {
                  editApprover === null ? onSubmit() : updateClaimApprover();
                  // if (data !== selectedUsers?.length - 1) {
                  //   onSubmit();
                  //   console.log("Submit, not");
                  // } else if (data === selectedUsers?.length - 1) {
                  //   console.log("Submit, equal");
                  // }
                }}
                // onClick={() => onSubmit()}
              >
                {/* {view === false ? "Next" : "Submit"} */}
                {data === selectedUsers?.length - 1 || editApprover !== null
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          </div>

          {data !== 0 ? (
            <div
              style={{
                width: "60%",
              }}
              className="ps-3"
            >
              <div className="d-flex w-100 row">
                {/* <div>
                <b>Selected Approver Scope</b>
              </div> */}
                <div>
                  <ol>
                    {requestData.slice(0, data).map((obj, index) => (
                      <WhiteCard>
                        <div>
                          <b>{obj.name}</b>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#F3F3F3",
                          }}
                          className="rounded-1 p-2"
                        >
                          <div className="d-flex col justify-content-between">
                            <div
                              style={{
                                backgroundColor: "#1B3356",
                                color: "#F3F3F3",
                                fontSize: 13,
                              }}
                              className="rounded-pill ps-2 pe-2 pt-1 pb-1"
                            >
                              {approvesScopeName(obj.approves?.value) ??
                                "For the employees"}
                            </div>
                            <div
                              style={{
                                backgroundColor: "#1B3356",
                                color: "#F3F3F3",
                                fontSize: 13,
                              }}
                              className="rounded-pill ps-2 pe-2 pt-1 pb-1"
                            >
                              {obj.configamount === "yes"
                                ? parseFloat(obj.amount) > 0
                                  ? `Less than ${obj.amount}`
                                  : "For all amounts" ?? "0"
                                : "For all amounts"}
                            </div>
                          </div>
                          <div className="d-flex col justify-content-between p-1">
                            <div style={{ color: "#9A9A9A", fontSize: 12 }}>
                              Approves
                            </div>
                            <div style={{ color: "#9A9A9A", fontSize: 12 }}>
                              Amount
                            </div>
                          </div>
                        </div>
                        <div className="row row-cols-auto p-1">
                          {obj.selectemp.map((object: any) => (
                            <div
                              style={{
                                backgroundColor: "#F3F3F3",
                                fontSize: 12,
                              }}
                              className="rounded-pill ps-2 pe-2 pt-1 pb-1 m-1"
                            >
                              {object.name}
                            </div>
                          ))}
                          {obj.approves?.value === "BUSINESS_UNIT" ? (
                            <div
                              style={{
                                backgroundColor: "#F3F3F3",
                                fontSize: 12,
                              }}
                              className="rounded-pill ps-2 pe-2 pt-1 pb-1 m-1"
                            >
                              {obj.businessUnit ?? "Err"}
                            </div>
                          ) : null}
                        </div>
                      </WhiteCard>
                    ))}
                  </ol>
                </div>
              </div>
            </div>
          ) : btnView ? (
            <div
              className="d-flex align-items-center  justify-content-center "
              style={{ padding: "5%", width: "60%" }}
            >
              <img src={welcomeClaimFinal} width={"100%"} height={"100%"} />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
