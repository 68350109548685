import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NpsSubscriptionInfo } from "../../../../api/detax/model/npsSubscriptionInfo";
import { NpsService } from "../../../../services/nps.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import { getTitleCase } from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import SuccessPopup from "../common/succes-popup";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";

const NpsTable: React.FC<{
  search?: boolean;
  searchVal?: string;
  curTbl?: TStatusCountInfo | null /* reload?: boolean; setReload: (flag: boolean) => void; */;
  reload?: boolean;
  setReload: (flag: boolean) => void;
  setCurTbl: (tbl: TStatusCountInfo) => void;
  setRemapState: (val: boolean) => void;
  remapState: boolean;
}> = ({
  searchVal = "",
  search,
  curTbl /* reload = false, setReload = () => { } */,
  reload,
  setReload,
  setCurTbl,
  setRemapState,
  remapState,
}) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [showSuccess, setShowSuccess] = useState({ show: false, message: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<Option<NpsSubscriptionInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<NpsSubscriptionInfo[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [curTbl?.status, search]);
  useEffect(() => {
    let cardStatus =
      page === RoutePaths.DashboardNps
        ? card
        : curTbl?.status === "All"
        ? ""
        : curTbl?.status;
    const run = async () => {
      // if (reload) {
      setItems(none);
      const tblResult = await NpsService.getNpsSubscriptions(
        cardStatus,
        paging.page,
        paging.limit
      );
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setPaging((p) => ({
            ...p,
            total: tblResult.value.recordCount ?? 10,
            page: paging.page,
            limit: paging.limit,
          }));
          setItems(some(tblResult.value.data || []));
          setFilteredData(some(tblResult.value.data || []));
          // setReload(true)
        } else {
          setErr(true);
        }
      }
      // setReload(false);
      // }
    };

    const runSearch = async () => {
      // if (reload) {
      setItems(none);
      const tblResult = await NpsService.getNpsSubscriptionsSearch(
        searchVal,
        cardStatus,
        paging.page,
        paging.limit
      );
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setPaging((p) => ({
            ...p,
            total: tblResult.value.recordCount ?? 10,
            page: paging.page,
            limit: paging.limit,
          }));
          setItems(some(tblResult.value.data || []));
          setFilteredData(some(tblResult.value.data || []));
          // setReload(true)
        } else {
          setErr(true);
        }
      }
      // setReload(false);
      // }
    };

    if (searchVal.length === 0) {
      run();
    } else {
      runSearch();
    }
    dispatch(resetDashboardRedirect());
  }, [paging.page, paging.limit, paging.total, reload]);

  const updatePranReMapping = async (uid: string) => {
    const result = await NpsService.updatePranReMapping([uid]);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setShowSuccess({ show: true, message: result.value.message ?? "" });
      }
    }
  };

  const tblData = (tblData: NpsSubscriptionInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  // useEffect(() => filterData(items), [searchVal]);
  // const filterData = (allData: Option<NpsSubscriptionInfo[]>) => {
  //   let data = pipe(
  //     allData,
  //     fold(
  //       () => [],
  //       (d) => (d.length > 0 ? d : [])
  //     )
  //   );
  //   if (searchVal) {
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //     let filteredData = data.filter(
  //       (item) =>
  //         nameRegEx.test(item?.name || "") || mobRegEx.test(item?.mobile || "")
  //     );
  //     setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(some(filteredData));
  //   } else {
  //     setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(allData);
  //   }
  // };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <>
      <SuccessPopup
        message={showSuccess.message}
        show={showSuccess.show}
        onClose={() => {
          setShowSuccess({ ...showSuccess, show: false, message: "" });
          setReload(true);
          setRemapState(true);
        }}
      />
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        pipe(
          filteredData,
          fold(
            () => [<NoTableData />],
            (items) =>
              items.length > 0
                ? [
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">Emp Code</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Salary</th>
                            <th scope="col">Monthly Contribution</th>
                            {curTbl?.status !== "PRAN_REMAPPING" && (
                              <th scope="col">Status</th>
                            )}{" "}
                            {curTbl?.status === "PRAN_REMAPPING" && (
                              <th scope="col">Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {tblData(items).map((item, key) => (
                            <tr key={key}>
                              <td>
                                <Link
                                  className="text-secondary ncash_nav-link"
                                  to={
                                    RoutePaths.DashboardNpsDetail +
                                    "/" +
                                    item.uid
                                  }
                                >
                                  {item.personId === "" ? "-" : item.personId}
                                </Link>
                              </td>
                              <td>{item.name === "" ? "-" : item.name} </td>
                              <td>{item.mobile === "" ? "-" : item.mobile}</td>
                              <td>{item.email === "" ? "-" : item.email}</td>
                              <td>{currencyFormat(item.salary || 0)}</td>
                              <td>
                                {currencyFormat(item.monthlyContribution || 0)}
                              </td>
                              <td className="text-center text-nowrap">
                                {curTbl?.status !== "PRAN_REMAPPING" && (
                                  <StatusText
                                    text={getTitleCase(item.npsStatus || "")}
                                    className={classNames({
                                      color2:
                                        item.npsStatus == "Acceptance Pending",
                                      color7: item.npsStatus === "Activated",
                                      color6: item.npsStatus === "Deactivated",
                                      color8: item.npsStatus === "Cancelled",
                                      color1: item.npsStatus === "Kyc Verified",
                                      color3:
                                        item.npsStatus === "PRAN Remapping",
                                      color10:
                                        item.npsStatus ===
                                        "Registration in Progress",
                                      color4:
                                        item.npsStatus ===
                                        "Bank Validation Completed",
                                      color5:
                                        item.npsStatus ===
                                        "Pan Validation Completed",
                                      color9: item.npsStatus === "Accepted",
                                      // active: curTbl?.status == item.status,
                                    })}
                                  />
                                )}

                                {curTbl?.status === "PRAN_REMAPPING" && (
                                  <Button
                                    className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                                    onClick={() => {
                                      updatePranReMapping(item.uid ?? "");
                                    }}
                                  >
                                    Activate
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TablePagination>,
                  ]
                : [<NoTableData />]
          )
        )
      )}
    </>
  );
};

export default NpsTable;
