import "./fx.css";

const CurrencyName = ({ name = "", className = "fw-normal" }) => (
  <h5 className={className}> {name}</h5>
);
const CurrencyPrice = ({
  currentPrice = 0,
  presentValue = 0,
  percentageValue = 0,
  className = "card-title fs-6",
}) => (
  <h5 className={className}>
    {" "}
    {currentPrice}
    {presentValue < 0 ? (
      <span className="text-danger_i">
        {" "}
        {presentValue} <span className="text-info_i"> ( </span>{" "}
        {percentageValue}% <span className="text-info_i"> ) </span>{" "}
      </span>
    ) : (
      <span className="text-success">
        {" "}
        {presentValue} <span className="text-info_i"> ( </span>{" "}
        {percentageValue}% <span className="text-info_i"> ) </span>{" "}
      </span>
    )}
  </h5>
);
const RealTimeNDate: React.FC<{ time?: string; currency?: string }> = ({
  time = "00:00:00",
  currency = "",
}) => {
  return (
    <>
      <img src="../images/time.svg" className="img-fluid me-1" alt="" />
      <span className="f-14 text-muted">
        {" "}
        {time} - Real-time Date {currency}{" "}
      </span>
    </>
  );
};
const DailyRange = ({ labelName = "Daily Range" }) => (
  <h6 className="fw-normal"> {labelName} </h6>
);
const RangeBar: React.FC<{ scale?: number; low?: number; high?: number }> = ({
  scale = 0,
  low = 0,
  high = 100,
}) => {
  const startRange = low;
  const endRange = high;
  return (
    <>
      <div className="d-flex align-items-baseline h-100">
        <p className="m-0">{startRange}</p>
        <div className="progress w-100 mx-1" style={{ height: "0.4rem" }}>
          <div
            className="progress-bar bg-info_i"
            style={{ width: scale * 10 + "%" }}
          ></div>
        </div>
        <p className="m-0">{endRange}</p>
      </div>
    </>
  );
};
const BidNAsk = ({ bidValue = 0, askValue = 0 }) => {
  return (
    <>
      <div className="row bg-light py-1 mx-0 rounded-6_i h-100">
        <div className="col-6 border-end text-center">
          <p className="text-muted f-14 mb-0"> Bid </p>
          <p className="f-18 mb-0"> {bidValue}</p>
        </div>
        <div className="col-6 text-center">
          <p className="text-muted f-14 mb-0"> Ask </p>
          <p className="f-18 mb-0"> {askValue} </p>
        </div>
      </div>
    </>
  );
};

export {
  CurrencyName,
  CurrencyPrice,
  RealTimeNDate,
  DailyRange,
  RangeBar,
  BidNAsk,
};
