import { isSuccess } from "@devexperts/remote-data-ts";
import React from "react";
import { useParams } from "react-router-dom";
import { CardTransactionInfo } from "../../../../../api/detax/model/cardTransactionInfo";
import { DetaxCardTransactionApprovalRequest } from "../../../../../api/detax/model/detaxCardTransactionApprovalRequest";
import { Stage } from "../../../../../api/detax/model/stage";
import { TransactionService } from "../../../../../services/transaction.service";
import { RoutePaths } from "../../../../../utils/route.utils";
import PageTitle from "../../../../common/page-title";
import ApprovePopup, {
  ApproveWithAmountPopup,
} from "../../common/approve-popup";

import DashboardPageLoader from "../../common/dashboard-page-loader";
import EmployeeDetails from "../../common/employee-details";
import Heading from "../../common/heading";
import RejectPopup from "../../common/reject-popup";
import SuccessPopup from "../../common/succes-popup";
import TransactionApprovedCard from "../../transaction/transaction-approval-card";
import BillCard from "./bill-card";
import ExpenseCard, { TExpense } from "./expense-card";
import TransactionHead, { THead } from "./transaction-head";
import TransactionHeading from "./transaction-heading";
import TransactionTimeline from "./transaction-timeline";

const DetaxTransactionDetails: React.FC = () => {
  const params = useParams<{
    transactionId: string;
    empId: string;
    callFrom: string;
  }>();
  const [head, setHead] = React.useState<THead>({
    categoryIcon: "",
    categoryName: "",
    amount: 0,
    baseAmt: 0,
    transactionId: "",
    createdAt: "",
  });

  const [expense, setExpense] = React.useState<TExpense>({
    type: undefined,
    approvalStatus: undefined,
    scope: undefined,
    remarks: "",
    manualTransaction: false,
    surCharge: false,
  });

  const [bills, setBills] = React.useState<string[]>([]);
  const [stages, setStages] = React.useState<Array<Stage> | undefined>([]);

  const [cardTransationInfo, setCardTransactionInfo] = React.useState<
    CardTransactionInfo | undefined
  >();

  const [showReject, setShowReject] = React.useState(false);
  const [showApprove, setShowApprove] = React.useState(false);

  const [modalType, setModalType] = React.useState<boolean>(true);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [err, setErr] = React.useState({ show: false, message: "" });
  const [approveActionState, setApproveActionState] = React.useState(false);
  const [rejectActionState, setRejectActionState] = React.useState(false);

  const getTransactionDetailsAsync = async (transactionId: string) => {
    const result = await TransactionService.getTransactionDetails(
      transactionId
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        const { data } = result.value;
        // prepare head
        const head: THead = {
          categoryIcon: data?.categoryInfo?.icon || "",
          categoryName: data?.categoryInfo?.name || "",
          amount: data?.amt || 0,
          baseAmt: data?.baseAmt || 0,
          transactionId: data?.txnId || "",
          createdAt: data?.createdAt || "",
          rrn: data?.rrn,
          txnStatus: data?.txnStatus,
          scope: data?.scope,
        };

        const expense: TExpense = {
          type: data?.type,
          approvalStatus: data?.approvalStatus,
          scope: data?.scope,
          remarks: data?.remarks,
          txnWallet: data?.txnWallet,
          merchant: data?.merchant,
          approvalDisplayStatus: data?.approvalDisplayStatus,
          manualTransaction: data?.manualTransaction,
          surCharge: data?.surCharge,
          partnerTxnType: data?.partnerTxnType,
        };

        setHead(head);
        setExpense(expense);
        setBills(data?.bills || []);
        setStages(data?.timeline?.stages);
        setCardTransactionInfo(data);
      }
    }
  };

  React.useEffect(() => {
    const { transactionId } = params;
    if (transactionId) {
      getTransactionDetailsAsync(transactionId);
    }
  }, [params.transactionId]);

  const handleRejectPopup = (show: boolean) => {
    setShowReject(show);
  };

  const handleApprovePopup = (show: boolean) => {
    setShowApprove(show);
  };

  const handleReject = async (remark: string) => {
    const body: DetaxCardTransactionApprovalRequest = {
      approved: false,
      remarks: remark,
    };
    if (params.transactionId && params.empId) {
      setRejectActionState(true);
      const result = await TransactionService.transactionApproval(
        body,
        params.transactionId,
        params.empId
      );
      if (isSuccess(result)) {
        setRejectActionState(false);
        if (result.value.successful) {
          await getTransactionDetailsAsync(params.transactionId);
          setShowReject(false);
          setModalType(false);
          setShowSuccess(true);
        }
      } else {
        setRejectActionState(false);
      }
    }
  };
  console.log("Head", head);
  const handleApprove = async (amount: string, remark: string) => {
    const body: DetaxCardTransactionApprovalRequest = {
      approved: true,
      amount: parseFloat(amount),
      remarks: remark,
    };
    if (params.transactionId && params.empId) {
      setApproveActionState(true);
      const result = await TransactionService.transactionApproval(
        body,
        params.transactionId,
        params.empId
      );
      if (isSuccess(result)) {
        setApproveActionState(false);
        if (result.value.successful) {
          await getTransactionDetailsAsync(params.transactionId);

          setShowApprove(false);
          setModalType(true);
          setShowSuccess(true);
        } else {
          //setApproveActionState(false);
        }
      } else {
        setApproveActionState(false);
      }
    }
  };

  const breadcrum =
    params.callFrom === "employee"
      ? {
          label: "Employee",
          url: RoutePaths.EmployeesDetail + "/" + params.empId + "",
        }
      : params.callFrom === "detax"
      ? {
          label: "Detax",
          url: RoutePaths.DashboardDetaxDetail + "/" + params.empId + "",
        }
      : params.callFrom === "transaction"
      ? {
          label: "Transactions",
          url: RoutePaths.DashboardTransactions,
        }
      : undefined;

  //console.log("breadcrum: ", breadcrum);
  if (!params.transactionId) {
    // when transaction id undefined show loader
    return <DashboardPageLoader />;
  }

  return (
    <div>
      <PageTitle title={`Transaction Details | ${params.transactionId}`} />
      <TransactionHeading
        breadcrum={breadcrum}
        status={expense.approvalStatus}
        setShowRejectPopup={handleRejectPopup}
        setShowApprovePopup={handleApprovePopup}
        expense={expense}
      />

      {params.empId && (
        <EmployeeDetails
          id={params.empId}
          loadEmployee={(employee) => {}}
          showSalary={false}
          showAddress={false}
        />
      )}

      <TransactionHead head={head} expense={expense} />

      <div className="row p-2" id="ExpenseDetails">
        <div className="col-9">
          <div className="row">
            <div className="col-5">
              <ExpenseCard expense={expense} />
            </div>
            <div className="col-7">
              {expense.type === "DEBIT" ? <BillCard bills={bills} /> : null}
            </div>
          </div>
          <div className="row pt-4">
            {/* display here approved and pending cards */}
            <div className="col">
              {expense.type === "DEBIT" ? (
                <TransactionApprovedCard cardInfo={cardTransationInfo} />
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-3">
          <TransactionTimeline stages={stages} />
        </div>
      </div>

      <RejectPopup
        actionState={rejectActionState}
        show={showReject}
        onHide={() => setShowReject(false)}
        onReject={handleReject}
      />

      <ApproveWithAmountPopup
        actionState={approveActionState}
        show={showApprove}
        onHide={() => setShowApprove(false)}
        onApprove={handleApprove}
        head={head}
      />

      <SuccessPopup
        message={`${modalType ? "Approved" : "Rejected"} successfully!`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
    </div>
  );
};

export default DetaxTransactionDetails;
