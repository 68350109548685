import React from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../../hooks/hooks";
import { Dispatch } from "../../../state/dispatch";
import { selectCommonError } from "../../app/app.slice";

const CommonError: React.FC = () => {
  const error = useAppSelector(selectCommonError);

  return (
    <Modal show={error.showError} centered>
      <Modal.Header className="bg-danger d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <div className="text-light text-center">{error.errorText}</div>
      </Modal.Header>

      <Modal.Footer
        className="ncaish-bg-grey d-flex justify-content-center pointer"
        onClick={() =>
          Dispatch.App.setCommonError({ ...error, showError: false })
        }
      >
        <p className="light fw-bold">OK</p>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonError;
