import { isSuccess } from "@devexperts/remote-data-ts";
import axios from "axios";
import { format, parseISO } from "date-fns";
import React, { PureComponent, useEffect, useState } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useAppSelector } from "../../../../hooks/hooks";
import { CurrencyGraphData } from "../../../../model/fx/currencyGraphData";
import { FetchCurrencyGraphRequest } from "../../../../model/fx/fetchCurrencyGraphRequest";
import { FxService } from "../../../../services/fx.service";
import { selectSelectedPriceData } from "../../../../state/slice/fx.slice";

const dataFix = [
  {
    date: "2022-01-27",
    open: "162.45000",
    high: "163.84000",
    low: "158.28000",
    close: "159.22000",
    volume: "115066405",
  },
  {
    datetime: "2022-01-26",
    open: "163.50000",
    high: "164.39000",
    low: "157.82001",
    close: "159.69000",
    volume: "108275300",
  },
  {
    datetime: "2022-01-25",
    open: "158.98000",
    high: "162.75999",
    low: "157.02000",
    close: "159.78000",
    volume: "115798400",
  },
  {
    datetime: "2022-01-24",
    open: "160.02000",
    high: "162.30000",
    low: "154.70000",
    close: "161.62000",
    volume: "162706700",
  },
  {
    datetime: "2022-01-21",
    open: "164.42000",
    high: "166.33000",
    low: "162.30000",
    close: "162.41000",
    volume: "122501300",
  },
  {
    datetime: "2022-01-20",
    open: "166.98000",
    high: "169.67999",
    low: "164.17999",
    close: "164.50999",
    volume: "91420500",
  },
  {
    datetime: "2022-01-19",
    open: "170.00000",
    high: "171.08000",
    low: "165.94000",
    close: "166.23000",
    volume: "94815000",
  },
  {
    datetime: "2022-01-18",
    open: "171.50999",
    high: "172.53999",
    low: "169.41000",
    close: "169.80000",
    volume: "90956700",
  },
  {
    datetime: "2022-01-14",
    open: "171.34000",
    high: "173.78000",
    low: "171.09000",
    close: "173.07001",
    volume: "80355000",
  },
  {
    datetime: "2022-01-13",
    open: "175.78000",
    high: "176.62000",
    low: "171.78999",
    close: "172.19000",
    volume: "84505800",
  },
  {
    datetime: "2022-01-12",
    open: "176.12000",
    high: "177.17999",
    low: "174.82001",
    close: "175.53000",
    volume: "74805200",
  },
  {
    datetime: "2022-01-11",
    open: "172.32001",
    high: "175.17999",
    low: "170.82001",
    close: "175.08000",
    volume: "76138300",
  },
  {
    datetime: "2022-01-10",
    open: "169.08000",
    high: "172.50000",
    low: "168.17000",
    close: "172.19000",
    volume: "106765600",
  },
  {
    datetime: "2022-01-07",
    open: "172.89000",
    high: "174.14000",
    low: "171.03000",
    close: "172.17000",
    volume: "86580100",
  },
  {
    datetime: "2022-01-06",
    open: "172.70000",
    high: "175.30000",
    low: "171.64000",
    close: "172.00000",
    volume: "96904000",
  },
  {
    datetime: "2022-01-05",
    open: "179.61000",
    high: "180.17000",
    low: "174.64000",
    close: "174.92000",
    volume: "94537600",
  },
  {
    datetime: "2022-01-04",
    open: "182.63000",
    high: "182.94000",
    low: "179.12000",
    close: "179.70000",
    volume: "99310400",
  },
  {
    datetime: "2022-01-03",
    open: "177.83000",
    high: "182.88000",
    low: "177.71001",
    close: "182.00999",
    volume: "104487900",
  },
  {
    datetime: "2021-12-31",
    open: "178.09000",
    high: "179.23000",
    low: "177.25999",
    close: "177.57001",
    volume: "64025500",
  },
  {
    datetime: "2021-12-30",
    open: "179.47000",
    high: "180.57001",
    low: "178.09000",
    close: "178.20000",
    volume: "59773000",
  },
  {
    datetime: "2021-12-29",
    open: "179.33000",
    high: "180.63000",
    low: "178.14000",
    close: "179.38000",
    volume: "62348900",
  },
  {
    datetime: "2021-12-28",
    open: "180.16000",
    high: "181.33000",
    low: "178.53000",
    close: "179.28999",
    volume: "79144300",
  },
  {
    datetime: "2021-12-27",
    open: "177.09000",
    high: "180.42000",
    low: "177.07001",
    close: "180.33000",
    volume: "74919600",
  },
  {
    datetime: "2021-12-23",
    open: "175.85001",
    high: "176.85001",
    low: "175.27000",
    close: "176.28000",
    volume: "68227500",
  },
  {
    datetime: "2021-12-22",
    open: "173.03999",
    high: "175.86000",
    low: "172.14999",
    close: "175.64000",
    volume: "92004100",
  },
  {
    datetime: "2021-12-21",
    open: "171.56000",
    high: "173.20000",
    low: "169.12000",
    close: "172.99001",
    volume: "85845000",
  },
  {
    datetime: "2021-12-20",
    open: "168.28000",
    high: "170.58000",
    low: "167.47000",
    close: "169.75000",
    volume: "107499064",
  },
  {
    datetime: "2021-12-17",
    open: "169.92999",
    high: "173.47000",
    low: "169.69000",
    close: "170.86000",
    volume: "136929933",
  },
  {
    datetime: "2021-12-16",
    open: "179.28000",
    high: "181.14000",
    low: "170.75000",
    close: "172.25999",
    volume: "150185543",
  },
  {
    datetime: "2021-12-15",
    open: "175.11000",
    high: "179.50000",
    low: "172.31000",
    close: "179.30000",
    volume: "130687600",
  },
];

const chartData = {
  successful: true,
  message: "Success",
  data: [
    {
      date: "01 Sep 2019",
      price: "70.64000",
    },
    {
      date: "01 Oct 2019",
      price: "70.97800",
    },
    {
      date: "01 Nov 2019",
      price: "71.74600",
    },
    {
      date: "01 Dec 2019",
      price: "71.35000",
    },
    {
      date: "01 Jan 2020",
      price: "71.54000",
    },
    {
      date: "01 Feb 2020",
      price: "72.53400",
    },
    {
      date: "01 Mar 2020",
      price: "75.33300",
    },
    {
      date: "01 Apr 2020",
      price: "75.07700",
    },
    {
      date: "01 May 2020",
      price: "75.59000",
    },
    {
      date: "01 Jun 2020",
      price: "75.54000",
    },
    {
      date: "01 Jul 2020",
      price: "74.91600",
    },
    {
      date: "01 Aug 2020",
      price: "73.25400",
    },
    {
      date: "01 Sep 2020",
      price: "73.56000",
    },
    {
      date: "01 Oct 2020",
      price: "74.55400",
    },
    {
      date: "01 Nov 2020",
      price: "73.99000",
    },
    {
      date: "01 Dec 2020",
      price: "73.03620",
    },
    {
      date: "01 Jan 2021",
      price: "72.87700",
    },
    {
      date: "01 Feb 2021",
      price: "73.92000",
    },
    {
      date: "01 Mar 2021",
      price: "73.13700",
    },
    {
      date: "01 Apr 2021",
      price: "74.05000",
    },
    {
      date: "01 May 2021",
      price: "72.51100",
    },
    {
      date: "01 Jun 2021",
      price: "74.36000",
    },
    {
      date: "01 Jul 2021",
      price: "74.33700",
    },
    {
      date: "01 Aug 2021",
      price: "72.94700",
    },
    {
      date: "01 Sep 2021",
      price: "74.16400",
    },
    {
      date: "01 Oct 2021",
      price: "74.93500",
    },
    {
      date: "01 Nov 2021",
      price: "75.10000",
    },
    {
      date: "01 Dec 2021",
      price: "74.41500",
    },
    {
      date: "01 Jan 2022",
      price: "74.58835",
    },
    {
      date: "01 Feb 2022",
      price: "74.81650",
    },
  ],
  min: 70,
  max: 76,
};
const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        style={{ fontSize: 12, fontWeight: 700 }}
      >
        {payload.value}
      </text>
    </g>
  );
};

const FxChart = () => {
  const price = useAppSelector(selectSelectedPriceData);
  const codes = price.code ? price.code.replace("_", "/") : "";

  //const [data, setData] = useState(chartData.data);
  const [data, setData] = useState<{
    data: CurrencyGraphData[];
    min: number;
    max: number;
  }>({ data: [], min: 0, max: 0 });
  useEffect(() => {
    if (codes) {
      const run = async () => {
        // const { data } = await axios.get(
        //   `https://api.twelvedata.com/time_series?symbol=${codes}&interval=1month&apikey=49cf7632566b497aa5be9cdf2eeb7243`
        // );
        //setData(data.values);
        const result = await FxService.fetchGraphData(
          "ONE_MONTH",
          codes.replace("/", "_") as FetchCurrencyGraphRequest.CodeEnum
        );
        if (isSuccess(result)) {
          setData({
            data: result.value.data,
            min: result.value.min,
            max: result.value.max,
          });
        }
      };
      run();
    }
  }, [codes]);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart
        data={data.data}
        width={500}
        height={400}
        margin={{
          top: 15,
          right: 15,
          left: -20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} interval={0} />
        <YAxis
          // label={"Range"}
          dataKey={"price"}
          // allowDataOverflow
          domain={[data.min, data.max]}

        // type="number"
        // interval={"preserveEnd"}
        />
        <Tooltip />

        <Area
          type="monotone"
          dataKey="price"
          dot={{ stroke: "#105099", strokeWidth: 2 }}
        />
        {/* <Line type="monotone" dataKey="low" stroke="#82ca9d" strokeWidth={2} /> */}
        {/* <Line type="monotone" dataKey="open" stroke="red" strokeWidth={2} /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default FxChart;
