import { getOrElse } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import React from "react";
import { TCorporateService } from "../../types/corporate-service.type";

const SelectServiceCard: React.FC<{ services: TCorporateService[] }> = ({
  services,
}) => {
  return (
    <div className="card border-0  bg-white rounded-15 mt-3 p-3">
      <div className="row">
        <div className="col-12">
          <div className="row px-3">
            <div className="col-12 col-md-12 text-start border-bottom mb-3 ">
              <h6 className=" ps-3 mt-1 neg-margin">Solutions selected </h6>
            </div>
          </div>
          <div className="row">
            {services.map((service, index) => {
              const selected = pipe(
                service.selected,
                getOrElse(() => false)
              );
              return (
                <div className="col-md-4" key={index}>
                  <div className="row">
                    <div className="col-4 col-md-12 ps-0 text-center ps-0 ">
                      <h6 className="mb-0">
                        {selected ? (
                          <>
                            <span className="text-success me-2">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 20 20"
                                height="1.4em"
                                width="1.4em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                            {pipe(
                              service.name,
                              getOrElse(() => "")
                            )}{" "}
                          </>
                        ) : (
                          <></>
                        )}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectServiceCard;
