import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import xlsx from "json-as-xlsx";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { EmployeePayload } from "../../../../api/employee/model/employeePayload";
import { NcaishAddressInfo } from "../../../../api/employee/model/ncaishAddressInfo";
import { SalaryConfig } from "../../../../api/employee/model/salaryConfig";
import { AdminCorporateCountInfo } from "../../../../model/adminCorporateCountInfo";
import { EmployeeService } from "../../../../services/employee.service";
import { setEmployeeMessage } from "../../../../state/slice/dashboard.slice";
import {
  getName,
  titleCase,
  validateEmail,
  validateMobile,
  validatePincode,
  validateMembershipType,
  validatePRAN,
  validateAmount,
} from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import ErrorPopup from "../common/error-popup";
import NoTableData from "../common/no-table-data";
import { ITableData, TablePagination } from "../common/table/table-pagination";
// import "./employees.scss";
import BulkEmployeeModal from "../../../modal/employees/BulkEmployeeModal";
import ExcelJS, { DataValidation } from "exceljs";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";
import { useAppSelector } from "../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import { NpsService } from "../../../../services/nps.service";
import { selectLoginUserCid } from "../../../app/app.slice";
import { Button } from "react-bootstrap";
import { BatchesService } from "../../../../services/batches.service";
import BulkCreateBatchModal from "./batch-bulk-editModal";
import { currencyFormat } from "../../../../utils/format.utils";
import ConfirmPopup from "../common/popups/confirm-popup";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { useCreateTransactionBatchMutation } from "./hooks/transaction.hooks.";
const excelSchema: any = {
  SNO: { prop: "sno", type: String, required: true },
  "Employee UID": { prop: "uid", type: String, required: true },
  "Employee Code": { prop: "code", type: String, required: true },
  "Employee Name": { prop: "name", type: String, required: true },
  Email: { prop: "email", type: String, required: true },
  "Mobile No": { prop: "mobile", type: String, required: true },
  "FBA Amount": { prop: "amount", type: String, required: true },
};
// same as import { EmployeePayload } from './employeePayload';
interface IPayload {
  sno: string;
  uid: string;
  code: string;
  name: string;
  email: string;
  mobile: string;
  amount: string;
  error?: string[];
}

interface IData {
  sno: string;
  uid: string;
  code: string;
  name: string;
  email: string;
  mobile: string;
  amount: string;
  error: string[];
}

const BatchBulkUpload: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [importData, setImportData] = useState<IPayload[] | []>([]);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [excelData, setExcelData] = useState<File[]>([]);
  const cid = useAppSelector(selectLoginUserCid);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionState, setActionState] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseError, setResponseError] = useState({
    show: false,
    message: "",
  });
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [editData, setEditData] = useState<IData | null>(null);
  const [editID, setEditID] = useState<number | null>(null);

  const {
    mutateAsync: createTransactionBatch,
    isLoading: isUploadTransactionLoading,
  } = useCreateTransactionBatchMutation();

  const validateData = (data: IData[] | []) => {
    if (data.length > 0) {
      let tmp1: string[] = [""];
      let tmp2: string[] = [""];
      let tmp3: string[] = [""];

      let newData: [] | IData[] = data.map((item) => {
        item = { ...item, error: [] };

        // console.log(
        //   "1-item.doj: ",
        //   item.doj,
        //   /[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/gi.test(item.doj)
        // );

        if (!item.name || /.*\d.*/.test(item.name))
          item.error = [...item.error, "name"];
        if (!item.sno) item.error = [...item.error, "sno"];
        if (!item.email) item.error = [...item.error, "email"];
        if (!item.amount) item.error = [...item.error, "amount"];

        if (!validateEmail(item.email) || tmp1.includes(item.email))
          item.error = [...item.error, "email"];
        if (!validateMobile(item.mobile) || tmp2.includes(item.mobile))
          item.error = [...item.error, "mobile"];

        if (!validateAmount(item.amount) || tmp3.includes(item.amount))
          item.error = [...item.error, "amount"];

        return item;
      });
      /* if (newData.length > 0)
        newData = newData.map(item => {
          if (item.error) {
            item.error = item.error.filter((v, i, a) => a.indexOf(v) === i)
          }
          return item
        }) */
      return newData;
    } else {
      return data;
    }
  };

  const importExcel = async () => {
    setShowError(false);
    if (excelData.length > 0) {
      let data: any[] = [];
      let rowData: any[] = [];
      let filesArr = [...excelData];

      for (let i = 0; i < filesArr.length; i++) {
        let file = filesArr[i];
        let rows = await readXlsxFile(file, {
          schema: excelSchema,
        });
        /* delete rows[0];
        rows = rows.filter((i) => i); */
        //  console.log("importExcel ::", rows);
        rowData = rows.rows;
        rowData = rowData.map((row: any) => ({ ...row, error: [] }));

        rows.errors.map((err, idx) => {
          if (
            err.error !== "required" &&
            (err.column === "Date Of Joining (dd-MM-yyyy)" ||
              err.column === "Date Of Birth (dd-MM-yyyy)")
          ) {
            let dtstr = format(new Date(err.value), "dd/MM/yyyy");
            //console.log("dtstr: ", dtstr);
            // console.log(err.row);
            if (err.column === "Date Of Joining (dd-MM-yyyy)") {
              rowData[err.row - 2] = {
                ...rowData[err.row - 2],
                doj: dtstr,
                error: [],
              };
            } else {
              rowData[err.row - 2] = {
                ...rowData[err.row - 2],
                dob: dtstr,
                error: [],
              };
            }
            //console.log(rowData[err.row - 2]);
          }
        });
      }
      console.log("rowData: ", rowData);
      rowData = validateData(rowData);

      let payload: IPayload[] | [] = [];
      rowData.map((item) => {
        payload = [
          ...payload,
          {
            name: item.name,
            sno: item.sno,
            code: item.code,
            uid: item.uid,
            email: item.email,
            amount: item.amount,
            mobile: item.mobile,
            error: item.error,
          },
        ];
      });

      if (payload.filter((obj) => parseFloat(obj.amount) > 0).length === 0) {
        setShowError(true);
      }
      console.log("payload:  ", payload);
      setImportData(payload.filter((obj) => parseFloat(obj.amount) > 0));
      setExcelData([]);
      setPaging((p) => ({
        ...p,
        total: payload.filter((obj) => parseFloat(obj.amount) > 0).length,
        page: 1,
        limit: 10,
      }));
    }
  };

  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: importData.filter((obj) => parseFloat(obj.amount) > 0).length,
      page:
        importData.filter((obj) => parseFloat(obj.amount) > 0).length % 10 === 0
          ? paging.page > 1
            ? paging.page - 1
            : paging.page
          : paging.page,
      limit: paging.limit,
    }));
  }, [importData.length]);

  const tblData = (tblData: IPayload[] | []) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    // console.log("result ::", result);
    return result;
  };

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  const downloadTemplate = async () => {
    const result = await BatchesService.getDownloadTemplate();

    if (isSuccess(result)) {
      const url = window.URL.createObjectURL(new Blob([result.value]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Create Batch.xlsx`; // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setActionState(false);
    } else {
      setActionState(false);
    }
  };

  const handleCreateBatch = async () => {
    setActionState(true);

    const requestPayload = {
      data: importData
        .filter((obj) => parseFloat(obj.amount) > 0)
        .map((obj) => ({
          uid: obj.uid,
          amount: parseFloat(obj.amount),
        })),
      type: DetaxTypeKind.FBA,
    };
    const result = await createTransactionBatch(requestPayload);

    if (isSuccess(result)) {
      setActionState(false);
      if (result.value.successful) {
        setShowConfirmPopup(false);
        navigate(RoutePaths.DashboardBatch);
      } else {
      }
    } else {
      // handle error state
      setActionState(false);
    }
  };

  return (
    <>
      <h1 className="page-heading fw-bold">
        {" "}
        {importData.filter((obj) => parseFloat(obj.amount) > 0).length > 0
          ? "Review Batch"
          : "Upload Template"}
      </h1>
      {importData &&
      importData &&
      importData.filter((obj) => parseFloat(obj.amount) > 0).length === 0 ? (
        <WhiteCard className="mt-4">
          <div className="d-flex justify-content-between">
            {/* <div className="steps">
              <h3 className="page-heading mb-3">
                {" "}
                Step - 1
                <p style={{ fontSize: 15, marginTop: 5 }}>
                  Download the template to modify FBA amounts in bulk
                </p>
              </h3>
              <div
                style={{
                  // border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                  onClick={() => {
                    downloadTemplate();
                  }}
                >
                  Download Template
                </Button>
              </div>
            </div> */}
            <div className="steps">
              <h3 className="page-heading mb-3">
                {" "}
                Step - 1
                <span>
                  Download the template to modify FBA amounts in bulk.
                </span>
              </h3>
            </div>

            <ReuseIcon className="mb-3">
              <svg
                className="comman-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="47.54"
                height="43.884"
                viewBox="0 0 47.54 43.884"
                onClick={() => {
                  downloadTemplate();
                }}
              >
                <path
                  id="download"
                  d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                  fill="#103458"
                />
              </svg>
            </ReuseIcon>
          </div>
        </WhiteCard>
      ) : null}

      <WhiteCard className="mt-4">
        <div className="steps">
          {importData.filter((obj) => parseFloat(obj.amount) > 0).length > 0 ? (
            <>
              {tblData(importData).filter((obj) => parseFloat(obj.amount) > 0)
                .length > 0 ? (
                <TablePagination
                  data={paging}
                  changePage={(data) =>
                    setPaging((p) => ({ ...p, page: data }))
                  }
                  changeLimit={(data) =>
                    setPaging((p) => ({ ...p, limit: data, page: 1 }))
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        {/* <th scope="col">S No.</th> */}
                        {/* <th scope="col">User id</th> */}
                        <th scope="col">Employee Code</th>
                        <th scope="col">Employee Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">FBA Amount</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tblData(importData)
                        .filter((obj) => parseFloat(obj.amount) > 0)
                        .map((item, key) => (
                          <tr
                            className={classNames({
                              "error-row": item.error && item.error.length > 0,
                            })}
                            key={key}
                          >
                            {/* <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("sno"),
                            })}
                          >
                            {item.sno}
                          </td> */}
                            {/* <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("uid"),
                            })}
                          >
                            {item.uid}
                          </td> */}
                            <td
                              className={classNames({
                                "error-column":
                                  item.error && item.error.includes("code"),
                              })}
                              style={{ textAlign: "left" }}
                            >
                              {item.code}
                            </td>
                            <td
                              style={{ textAlign: "left" }}
                              className={classNames({
                                "error-column":
                                  item.error && item.error.includes("name"),
                              })}
                            >
                              {item.name}
                            </td>
                            <td
                              style={{ textAlign: "left" }}
                              className={classNames({
                                "error-column":
                                  item.error && item.error.includes("email"),
                              })}
                            >
                              {item.email}
                            </td>
                            <td
                              style={{ textAlign: "left" }}
                              className={classNames({
                                "error-column":
                                  item.error && item.error.includes("mobile"),
                              })}
                            >
                              {item.mobile}
                            </td>

                            <td
                              style={{ textAlign: "right" }}
                              className={classNames({
                                "error-column":
                                  item.error && item.error.includes("amount"),
                              })}
                            >
                              {currencyFormat(parseFloat(item.amount))}
                            </td>

                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <button
                                  onClick={() => {
                                    const startFrom =
                                      (paging.page - 1) * paging.limit;
                                    const keyIndex = startFrom + key;
                                    let newData = importData
                                      .filter(
                                        (obj) => parseFloat(obj.amount) > 0
                                      )
                                      .filter((i, k) => k === keyIndex)[0];
                                    let editModalData: IData = {
                                      sno: newData.sno || "",
                                      uid: newData.uid || "",
                                      code: newData.code || "",
                                      name: newData.name || "",
                                      email: newData.email || "",
                                      mobile: newData.mobile || "",
                                      amount: newData.amount || "",
                                      error: [],
                                    };

                                    // console.log('newKey: ', startFrom + key )
                                    // console.log('Key: ', key, paging);
                                    setEditData(editModalData);
                                    setEditID(keyIndex);
                                  }}
                                  className="btn btn-sm btn-danger me-2"
                                >
                                  <MdModeEdit />
                                </button>
                                <button
                                  onClick={() => {
                                    const startFrom =
                                      (paging.page - 1) * paging.limit;
                                    const keyIndex = startFrom + key;
                                    setImportData(
                                      importData
                                        .filter(
                                          (obj) => parseFloat(obj.amount) > 0
                                        )
                                        .filter((i, k) => k !== keyIndex)
                                    );
                                  }}
                                  className="btn btn-sm btn-primary"
                                >
                                  <MdDelete />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TablePagination>
              ) : (
                <NoTableData />
              )}
              <div className="action"></div>
            </>
          ) : (
            <>
              {/* <h3 className="page-heading mb-3">
                Step - 2
                <p style={{ fontSize: 15, marginTop: 5 }}>
                  Upload the template to create the batch
                </p>
              </h3> */}
              <h3 className="page-heading mb-3">
                Step - 2<span>Upload the template to create the batch.</span>
              </h3>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  let arr: File[] = [];
                  acceptedFiles.forEach((file) => {
                    if (file.name.split(".").pop() == "xlsx")
                      arr = [...arr, file];
                  });
                  setExcelData(arr);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className="p-3 text-center my-4 mx-auto wrap-file-upload "
                    role="button"
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} accept=".xlsx" />
                      <svg
                        id="Group_2844"
                        width="75"
                        data-name="Group 2844"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 158.798 190.158"
                      >
                        <path
                          id="Path_6474"
                          data-name="Path 6474"
                          d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                          transform="translate(-157.192 -168.825)"
                          fill="#cecece"
                        />
                        <rect
                          id="Rectangle_365"
                          data-name="Rectangle 365"
                          width="158.798"
                          height="21.5"
                          rx="10.75"
                          transform="translate(0 168.659)"
                          fill="#cecece"
                        />
                      </svg>
                      <div className="w-25 px-4 mx-auto">
                        <p>Drag and drop here </p>
                        <p>or</p>
                        <p className="">Browse Files</p>
                      </div>
                      <ul className="files-list">
                        {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  </section>
                )}
              </Dropzone>

              <div className="w-25 mx-auto text-center">
                {excelData.length > 0 && (
                  <button
                    style={{ marginRight: "20px" }}
                    className="btn-ncaish-light text-white px-3 py-2"
                    onClick={() => setExcelData([])}
                  >
                    Clear
                  </button>
                )}
                {excelData.length > 0 ? (
                  <button
                    disabled={excelData.length == 0}
                    className="btn-gradiant-blue text-white w-50 px-3 py-2"
                    onClick={() => importExcel()}
                  >
                    Upload
                  </button>
                ) : null}
              </div>
            </>
          )}
        </div>
      </WhiteCard>
      {/* {importData && importData && importData.length > 0 && (
        <div className="mb-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => setImportData([])}
          >
            Remove
          </button>
          <button
            // disabled={actionState}
            disabled={
              importData
                .map((obj) => obj.error)
                .map((obj) => (obj?.length === 0) === true)
                .filter((obj) => obj === false).length !== 0
            }
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => uploadBulkNpsData()}
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </div>
      )} */}
      {importData &&
        importData &&
        importData.filter((obj) => parseFloat(obj.amount) > 0).length > 0 && (
          <>
            <WhiteCard className="mt-4">
              <div className="row ">
                <div className="col-md-6">
                  <>
                    <h6 className="text-secondary">Total No. of Employees</h6>
                    <h4 className="text-black mb-0">
                      {
                        importData.filter((obj) => parseFloat(obj.amount) > 0)
                          .length
                      }
                    </h4>
                  </>
                </div>
                <div className="col-md-6 text-end">
                  <h6 className="text-secondary">Total Amount (INR)</h6>
                  <h4 className="text-black mb-0">
                    {currencyFormat(
                      importData &&
                        importData
                          .filter((obj) => parseFloat(obj.amount) > 0)
                          .map((item: any) => parseFloat(item.amount))
                          .reduce(
                            (total: number, amount: number) => total + amount,
                            0
                          )
                    )}
                  </h4>
                </div>
              </div>
            </WhiteCard>
            <div className="mt-4 mb-4" style={{ textAlign: "right" }}>
              <button
                className="btn-ncaish-light text-white px-3 py-2"
                onClick={() => setImportData([])}
              >
                Back
              </button>
              <button
                style={{ marginLeft: "10px" }}
                className="btn-gradiant-blue text-white px-3 py-2"
                onClick={() => setShowConfirmPopup(true)}
                disabled={
                  importData.filter((obj) => parseFloat(obj.amount) > 0)
                    .length === 0 || actionState
                }
              >
                {actionState && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Create Batch
              </button>
            </div>
          </>
        )}
      {editID !== null && editData && (
        <BulkCreateBatchModal
          data={editData}
          setData={(data) => {
            let newData = validateData([data])[0];

            let newImportData = JSON.parse(JSON.stringify(importData));
            newImportData[editID] = {
              name: newData.name,
              sno: newData.sno,
              code: newData.code,
              uid: newData.uid,
              email: newData.email,
              mobile: newData.mobile,
              amount: parseFloat(newData.amount.toString()),
              error: newData.error,
            };
            setImportData(newImportData);
            setEditData(null);
            setEditID(null);
          }}
          close={() => {
            setEditData(null);
            setEditID(null);
          }}
        />
      )}
      <ConfirmPopup
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
        onConfirm={() => {
          {
            handleCreateBatch();
          }
        }}
      />
      <ErrorPopup
        message={showError ? `Invalid template format!` : responseError.message}
        show={showError || responseError.show}
        onClose={(flag) => {
          setShowError(flag);
          setResponseError({ show: false, message: "" });
        }}
      />
    </>
  );
};

export default BatchBulkUpload;
