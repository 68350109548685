import classnames from "classnames";
import React from "react";
import { images } from "../../../utils/staticData";
import s from "./input.module.scss";

interface Props {
  icon?: keyof typeof images;
  color?: "bg-lightpink" | "bg-lightblue" | "bg-lightpurple";
}

const InputGroup: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
> = ({
  icon = "building",
  color = "bg-lightblue",
  children,
  ...otherProps
}) => {
  const className = classnames(s["p-2"], {
    [s["ncash_bg-lightpink"]]: color === "bg-lightpink",
    [s["ncash_bg-lightblue"]]: color === "bg-lightblue",
    [s["ncash_bg-lightpurple"]]: color === "bg-lightpurple",
  });
  return (
    <div className="input-group border-1 rounded-15 ml-auto mr-auto border-grey">
      <span
        className="input-group-text ncash_w-group-wrapper input-field"
        id="basic-addon1"
      >
        <div className={className}>
          <img
            className="img-fluid icons w-50"
            src={images[icon]}
            alt="Building"
          />
        </div>
      </span>
      <input {...otherProps} />
    </div>
  );
};

export default InputGroup;
