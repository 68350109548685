import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import xlsx from "json-as-xlsx";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { EmployeePayload } from "../../../../api/employee/model/employeePayload";
import { NcaishAddressInfo } from "../../../../api/employee/model/ncaishAddressInfo";
import { SalaryConfig } from "../../../../api/employee/model/salaryConfig";
import { AdminCorporateCountInfo } from "../../../../model/adminCorporateCountInfo";
import { EmployeeService } from "../../../../services/employee.service";
import { setEmployeeMessage } from "../../../../state/slice/dashboard.slice";
import {
  getName,
  titleCase,
  validateEmail,
  validateMobile,
  validatePincode,
  validateMembershipType,
  validatePRAN,
  validateAmount,
} from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import ErrorPopup from "../common/error-popup";
import NoTableData from "../common/no-table-data";
import { ITableData, TablePagination } from "../common/table/table-pagination";
// import "./employees.scss";
import BulkEmployeeModal from "../../../modal/employees/BulkEmployeeModal";
import ExcelJS, { DataValidation } from "exceljs";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";
import { useAppSelector } from "../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import BulkNPSModal from "./nps-bulk-editModal";
import { NpsService } from "../../../../services/nps.service";
import { selectLoginUserCid } from "../../../app/app.slice";
import { Button } from "react-bootstrap";
const excelSchema: any = {
  "Employee Name": { prop: "name", type: String, required: true },
  PRAN: { prop: "pran", type: String, required: true },
  Email: { prop: "email", type: String, required: true },
  "Monthly Contribution": { prop: "amount", type: String, required: true },
};
// same as import { EmployeePayload } from './employeePayload';
interface IPayload {
  name: string;
  pran: string;
  email: string;
  amount: string;
  error?: string[];
}

interface IData {
  name: string;
  pran: string;
  email: string;
  amount: string;
  error: string[];
}

const NpsBulkUpload: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [importData, setImportData] = useState<IPayload[] | []>([]);
  const [excelData, setExcelData] = useState<File[]>([]);
  const cid = useAppSelector(selectLoginUserCid);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionState, setActionState] = useState(false);
  const [showError, setShowError] = useState(false);
  const [responseError, setResponseError] = useState({
    show: false,
    message: "",
  });
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [editData, setEditData] = useState<IData | null>(null);
  const [editID, setEditID] = useState<number | null>(null);

  console.log("Test Store", importData);

  const validateData = (data: IData[] | []) => {
    if (data.length > 0) {
      let tmp1: string[] = [""];
      let tmp2: string[] = [""];
      let tmp3: string[] = [""];

      let newData: [] | IData[] = data.map((item) => {
        item = { ...item, error: [] };

        // console.log(
        //   "1-item.doj: ",
        //   item.doj,
        //   /[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$/gi.test(item.doj)
        // );

        if (!item.name || /.*\d.*/.test(item.name))
          item.error = [...item.error, "name"];
        if (!item.pran) item.error = [...item.error, "pran"];
        if (!item.email) item.error = [...item.error, "email"];
        if (!item.amount) item.error = [...item.error, "amount"];

        if (!validateEmail(item.email) || tmp1.includes(item.email))
          item.error = [...item.error, "email"];
        if (!validatePRAN(item.pran) || tmp2.includes(item.pran))
          item.error = [...item.error, "pran"];

        if (!validateAmount(item.amount) || tmp3.includes(item.pran))
          item.error = [...item.error, "amount"];

        return item;
      });
      /* if (newData.length > 0)
        newData = newData.map(item => {
          if (item.error) {
            item.error = item.error.filter((v, i, a) => a.indexOf(v) === i)
          }
          return item
        }) */
      return newData;
    } else {
      return data;
    }
  };

  const importExcel = async () => {
    setShowError(false);
    if (excelData.length > 0) {
      let data: any[] = [];
      let rowData: any[] = [];
      let filesArr = [...excelData];

      for (let i = 0; i < filesArr.length; i++) {
        let file = filesArr[i];
        let rows = await readXlsxFile(file, {
          schema: excelSchema,
        });
        /* delete rows[0];
        rows = rows.filter((i) => i); */
        //  console.log("importExcel ::", rows);
        rowData = rows.rows;
        rowData = rowData.map((row: any) => ({ ...row, error: [] }));

        rows.errors.map((err, idx) => {
          if (
            err.error !== "required" &&
            (err.column === "Date Of Joining (dd-MM-yyyy)" ||
              err.column === "Date Of Birth (dd-MM-yyyy)")
          ) {
            let dtstr = format(new Date(err.value), "dd/MM/yyyy");
            //console.log("dtstr: ", dtstr);
            // console.log(err.row);
            if (err.column === "Date Of Joining (dd-MM-yyyy)") {
              rowData[err.row - 2] = {
                ...rowData[err.row - 2],
                doj: dtstr,
                error: [],
              };
            } else {
              rowData[err.row - 2] = {
                ...rowData[err.row - 2],
                dob: dtstr,
                error: [],
              };
            }
            //console.log(rowData[err.row - 2]);
          }
        });
      }
      console.log("rowData: ", rowData);
      rowData = validateData(rowData);

      let payload: IPayload[] | [] = [];
      rowData.map((item) => {
        payload = [
          ...payload,
          {
            name: item.name,
            pran: item.pran,
            email: item.email,
            amount: item.amount,
            error: item.error,
          },
        ];
      });

      if (payload.length == 0) setShowError(true);
      console.log("payload:  ", payload);
      setImportData(payload);
      setExcelData([]);
      setPaging((p) => ({ ...p, total: payload.length, page: 1, limit: 10 }));
    }
  };

  const tblData = (tblData: IPayload[] | []) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    // console.log("result ::", result);
    return result;
  };

  const uploadBulkNpsData = async () => {
    setShowError(false);
    setResponseError({ show: false, message: "" });
    setActionState(true);
    let payloadData = JSON.parse(JSON.stringify(importData));
    payloadData = payloadData.filter(
      (item: IPayload) => item.error && item.error.length === 0
    );
    payloadData.map((item: IPayload) => {
      delete item.error;
    });

    const employeesRequestData = payloadData.map((item: IPayload) => ({
      pranNumber: item.pran,
      email: item.email,
      nameOnPran: item.name,
      monthlyContribution: item.amount,
    }));

    const result = await NpsService.createNpsBulk(
      {
        data: employeesRequestData,
      },
      cid
    );

    setActionState(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        dispatch(setEmployeeMessage(result.value.message));
        navigate(RoutePaths.DashboardNps);
      } else {
        setResponseError({ show: true, message: result.value.message || "" });
      }
      //
      // setImportData([]);
      // await loadData();
    }
  };

  const inputDate = (dt: string) => {
    // first check if date has "/" or "-" seperator
    const isSlashString = dt.includes("/");
    const isDashString = dt.includes("-");
    if (isSlashString) {
      const arr = dt.split("/");
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    }
    if (isDashString) {
      const arr = dt.split("-");
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    }
    return dt;
  };

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  function generateExcelSheet() {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a new worksheet
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add data to the worksheet
    worksheet.columns = [
      { header: "Employee Name", width: 20 },
      { header: "PRAN", width: 20 },
      { header: "Email", width: 20 },
      { header: "Monthly Contribution", width: 20 },
    ];

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Download the file
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "NPS.xlsx";
      a.click();
    });
  }

  return (
    <>
      <h1 className="page-heading fw-bold"> PRAN Migration</h1>
      {importData && importData && importData.length == 0 ? (
        <WhiteCard className="mt-4">
          <div className="d-flex justify-content-between">
            <div className="steps">
              <h3 className="page-heading mb-3">
                {" "}
                Step - 1
                <p style={{ fontSize: 15, marginTop: 5 }}>
                  If any of your employees have existing PRANs from their
                  earlier organisations or through a different CRA other than
                  KFintech, please contact us to get the employees migrated to
                  KFintech. Once the employees are migrated to KFintech,
                  download the template to activate them in the Ncash platform.
                </p>
              </h3>
              <div
                style={{
                  // border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                  onClick={generateExcelSheet}
                >
                  Download Template
                </Button>
              </div>
            </div>

            {/* <ReuseIcon className="mb-3">
              <svg
                className="comman-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="47.54"
                height="43.884"
                viewBox="0 0 47.54 43.884"
                onClick={generateExcelSheet}
              >
                <path
                  id="download"
                  d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                  fill="#103458"
                />
              </svg>
            </ReuseIcon> */}
          </div>
        </WhiteCard>
      ) : null}

      <WhiteCard className="mt-4">
        <div className="steps">
          {/* <h3 className="mb-3">Uploaded Sheet</h3> */}
          {importData.length > 0 ? (
            <>
              {tblData(importData).length > 0 ? (
                <TablePagination
                  data={paging}
                  changePage={(data) =>
                    setPaging((p) => ({ ...p, page: data }))
                  }
                  changeLimit={(data) =>
                    setPaging((p) => ({ ...p, limit: data, page: 1 }))
                  }
                >
                  <table>
                    <thead>
                      <tr>
                        <th scope="col">Employee Name</th>
                        <th scope="col">PRAN</th>
                        <th scope="col">Email</th>
                        <th scope="col">Monthly Contribution</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tblData(importData).map((item, key) => (
                        <tr
                          className={classNames({
                            "error-row": item.error && item.error.length > 0,
                          })}
                          key={key}
                        >
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("name"),
                            })}
                          >
                            {item.name}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("pran"),
                            })}
                          >
                            {item.pran}
                          </td>
                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("email"),
                            })}
                          >
                            {item.email}
                          </td>

                          <td
                            className={classNames({
                              "error-column":
                                item.error && item.error.includes("amount"),
                            })}
                          >
                            {item.amount}
                          </td>

                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                onClick={() => {
                                  const startFrom =
                                    (paging.page - 1) * paging.limit;
                                  const keyIndex = startFrom + key;
                                  let newData = importData.filter(
                                    (i, k) => k === keyIndex
                                  )[0];
                                  let editModalData: IData = {
                                    name: newData.name || "",
                                    pran: newData.pran || "",
                                    email: newData.email || "",
                                    amount: newData.amount || "",
                                    error: [],
                                  };

                                  // console.log('newKey: ', startFrom + key )
                                  // console.log('Key: ', key, paging);
                                  setEditData(editModalData);
                                  setEditID(keyIndex);
                                }}
                                className="btn btn-sm btn-danger me-2"
                              >
                                <MdModeEdit />
                              </button>
                              <button
                                onClick={() => {
                                  const startFrom =
                                    (paging.page - 1) * paging.limit;
                                  const keyIndex = startFrom + key;
                                  setImportData(
                                    importData.filter((i, k) => k !== keyIndex)
                                  );
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                <MdDelete />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TablePagination>
              ) : (
                <NoTableData />
              )}
              <div className="action"></div>
            </>
          ) : (
            <>
              <h3 className="page-heading mb-3">
                Step - 2
                <p style={{ fontSize: 15, marginTop: 5 }}>
                  Once the employees are migrated to KFintech, upload the filled
                  template to activate NPS for the employees in the Ncash
                  platform.
                </p>
              </h3>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  let arr: File[] = [];
                  acceptedFiles.forEach((file) => {
                    if (file.name.split(".").pop() == "xlsx")
                      arr = [...arr, file];
                  });
                  setExcelData(arr);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className="p-3 text-center my-4 mx-auto wrap-file-upload "
                    role="button"
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} accept=".xlsx" />
                      <svg
                        id="Group_2844"
                        width="75"
                        data-name="Group 2844"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 158.798 190.158"
                      >
                        <path
                          id="Path_6474"
                          data-name="Path 6474"
                          d="M258.215,314.354H215.523A12.651,12.651,0,0,1,202.872,301.7V246.6h-43.22l77.866-77.772,77.23,77.275h-43.88v55.6A12.651,12.651,0,0,1,258.215,314.354Z"
                          transform="translate(-157.192 -168.825)"
                          fill="#cecece"
                        />
                        <rect
                          id="Rectangle_365"
                          data-name="Rectangle 365"
                          width="158.798"
                          height="21.5"
                          rx="10.75"
                          transform="translate(0 168.659)"
                          fill="#cecece"
                        />
                      </svg>
                      <div className="w-25 px-4 mx-auto">
                        <p>Drag and drop here </p>
                        <p>or</p>
                        <p className="">Browse Files</p>
                      </div>
                      <ul className="files-list">
                        {excelData.map((file, fk) => (
                          <li key={fk}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  </section>
                )}
              </Dropzone>

              <div className="w-25 mx-auto text-center">
                {excelData.length > 0 && (
                  <button
                    style={{ marginRight: "20px" }}
                    className="btn-ncaish-light text-white px-3 py-2"
                    onClick={() => setExcelData([])}
                  >
                    Clear
                  </button>
                )}
                {excelData.length > 0 ? (
                  <button
                    disabled={excelData.length == 0}
                    className="btn-gradiant-blue text-white w-50 px-3 py-2"
                    onClick={() => importExcel()}
                  >
                    Upload
                  </button>
                ) : null}
              </div>
            </>
          )}
        </div>
      </WhiteCard>
      {importData && importData && importData.length > 0 && (
        <div className="mb-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => setImportData([])}
          >
            Remove
          </button>
          <button
            // disabled={actionState}
            disabled={
              importData
                .map((obj) => obj.error)
                .map((obj) => (obj?.length === 0) === true)
                .filter((obj) => obj === false).length !== 0
            }
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => uploadBulkNpsData()}
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </div>
      )}
      {editID !== null && editData && (
        <BulkNPSModal
          data={editData}
          setData={(data) => {
            let newData = validateData([data])[0];

            let newImportData = JSON.parse(JSON.stringify(importData));
            newImportData[editID] = {
              name: newData.name,
              pran: newData.pran,
              email: newData.email,
              amount: parseFloat(newData.amount.toString()),

              error: newData.error,
            };
            setImportData(newImportData);
            setEditData(null);
            setEditID(null);
          }}
          close={() => {
            setEditData(null);
            setEditID(null);
          }}
        />
      )}
      <ErrorPopup
        message={
          showError
            ? `Invalid data! Download the template to enrol your employees and send them invite to join`
            : responseError.message
        }
        show={showError || responseError.show}
        onClose={(flag) => {
          setShowError(flag);
          setResponseError({ show: false, message: "" });
        }}
      />
    </>
  );
};

export default NpsBulkUpload;
