/**
 * Ncaish FX API
 * Api to manage real time FX price , currency conversion and calculate saving
 *
 * OpenAPI spec version: 1.6.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../errors";

//@ts-ignore
//import * as models from '../model/models';
import { FxConversionRequest } from "../../model/fx/fxConversionRequest";
import { FxConversionResponse } from "../../model/fx/fxConversionResponse";
import { FxDashboardResponse } from "../../model/fx/fxDashboardResponse";
import { FxSavingRequest } from "../../model/fx/fxSavingRequest";
import { FxSavingResponse } from "../../model/fx/fxSavingResponse";
import { RealTimePriceResponse } from "../../model/fx/realTimePriceResponse";
import { FetchCurrencyGraphRequest } from "../../model/fx/fetchCurrencyGraphRequest";
import { CurrencyGraphResponse } from "../../model/fx/currencyGraphResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishFxApiService {
  /**
   * Api to calcuate saving by date and currencies
   * @param request -
   */
  export const calculateSaving = async (
    request: FxSavingRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FxSavingResponse>> => {
    const path = `/secure/fx/currency/calculator/saving`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    // const queryParams = allQueryParams
    //   .map((param) => `${param.key}=${param.value}`)
    //   .join("&");

    const apiUrl = path.concat(
      `?rateType=${request.rateType}&fromCurrency=${request.fromCurrency}&toCurrency=${request.toCurrency}&date=${request.date}&amount=${request.amount}&bankCredited=${request.bankCredited}`
    );
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to conver price by currency
   * @param request -
   */
  export const convert = async (
    request: FxConversionRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FxConversionResponse>> => {
    const path = `/secure/fx/currency/calculator`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    // const queryParams = allQueryParams
    //   .map((param) => `${param.key}=${param.value}`)
    //   .join("&");

    const apiUrl = path.concat(
      `?from=${request.from}&to=${request.to}&amount=${request.amount}`
    );
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch fx dashboard data-- call this on load...
   */
  export const dashboard = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FxDashboardResponse>> => {
    const path = `/secure/fx/dashboard`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to fetch currency graph data
   * @param request -
   */
  export const fetchGraph = async (
    request: FetchCurrencyGraphRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CurrencyGraphResponse>> => {
    const path = `/secure/fx/graph`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //         {
    //             key: 'request',
    //             value: request
    //         },
    // ];
    // const queryParams = allQueryParams
    //         .map(param => `${param.key}=${param.value}`)
    //         .join('&');

    // const apiUrl = queryParams === '' ? path : path.concat(`?${queryParams}`);
    const apiUrl = path.concat(
      `?interval=${request.interval}&code=${request.code}`
    );
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch real time price change
   * @param request -
   */
  export const realTimePrice = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, RealTimePriceResponse>> => {
    const path = `/secure/fx/currency/latest/price`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new NcaishFxApiService()
