import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./popup.scss";

const ConfirmPopup: React.FC<{
  title?: string;
  show: boolean;
  onHide: (show: boolean) => void;
  onConfirm: () => void;
}> = ({
  show,
  onHide,
  onConfirm,
  title = "Are you sure you want to create batch?",
}) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-success d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <Modal.Title className="text-light">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer className=" d-flex justify-content-center">
        <Button variant="secondary" onClick={() => onHide(false)}>
          No
        </Button>
        <Button variant="success" onClick={() => onConfirm()}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopup;
