/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { DetaxCardSubscriptionApiRequest } from "../model/detaxCardSubscriptionApiRequest";
import { DetaxCardSubscriptionCountApiResponse } from "../model/detaxCardSubscriptionCountApiResponse";
import { DetaxCardSubscriptionResponse } from "../model/detaxCardSubscriptionResponse";
import { EmployeeListApiResponse } from "../model/employeeListApiResponse";
import { NpsSubscriptionApiRequest } from "../model/npsSubscriptionApiRequest";
import { NpsSubscriptionCountApiResponse } from "../model/npsSubscriptionCountApiResponse";
import { NpsSubscriptionResponse } from "../model/npsSubscriptionResponse";
import { MembershipDetaxCountApiResponse } from "../model/membershipDetaxCountApiResponse";
import { MembershipBatchTableResponse } from "../model/membershipBatchTableResponse";
import { MembershipBatchCountApiRequest } from "../model/membershipBatchCountApiRequest";
import { EmployeeResponse } from "../../../model/employeeResponse";
import { NpsPranReMappingResponse } from "../model/npsPranReMappingResponse";
import { BaseResponse } from "../model/baseResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishDetaxSubscriptonApiService {
  /**
   * Api to get status wise subscription count
   * @param status -
   */
  export const getCardSubscriptionCount = async (
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardSubscriptionCountApiResponse>> => {
    const path = `/secure/detax/subscriptions/cards/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get All detax card subscription requests
   * @param status -
   */
  export const getNpsSubscriptionCount = async (
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsSubscriptionCountApiResponse>> => {
    const path = `secure/nps/users/count`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get All detax card subscription requests
   * @param status -
   */
  export const getNpsSubscriptions = async (
    status: string,
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsSubscriptionResponse>> => {
    const path = `/secure/nps/users`
      .replace(/^\//, "")
      .replace("{" + "status" + "}", String(status));
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getNpsPranReMapping = async (
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, Blob>> => {
    const path = `/secure/nps/users/pran-remapping/download`.replace(/^\//, "");
    // .replace("{" + "status" + "}", String(status));
    const allQueryParams: TQueryParam[] = [
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const updatePranReMapping = async (
    body: string[],
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsPranReMappingResponse>> => {
    const path = `/secure/nps/users/updatePranReMapping`.replace(/^\//, "");
    // .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getNpsSubscriptionsSearch = async (
    keyword: string,
    status: string,
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsSubscriptionResponse>> => {
    const path = `/secure/nps/users/search`
      .replace(/^\//, "")
      .replace("{" + "status" + "}", String(status));
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get All detax card subscription requests
   * @param status -
   */
  export const getSubscription = async (
    status?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardSubscriptionResponse>> => {
    const path = `/secure/detax/subscriptions/cards`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getSubscriptionReport = async (
    status?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/detax/reports/detax/status/download`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getSearchDetax = async (
    status?: string,
    keyword?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardSubscriptionResponse>> => {
    const path = `/secure/detax/subscriptions/cards/search`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "keyword",
        value: keyword,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to get my detax subscription requests
   */
  export const mySubscriptionsReuqets = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardSubscriptionResponse>> => {
    const path = `/secure/detax/subscriptions/me`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to search card eligible employee
   * @param keyword -
   */
  export const searchCardEligibleEmployee = async (
    keyword?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeListApiResponse>> => {
    const path =
      `/secure/detax/subscriptions/cards/eligible/employees/search`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to search nps eligible employee
   * @param keyword -
   */
  export const searchNpsEligibleEmployee = async (
    keyword?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, EmployeeListApiResponse>> => {
    const path =
      `/secure/detax/subscriptions/nps/eligible/employees/search`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to send nps subscription request
   * @param body -
   */
  export const sendNpsSubscriptionRequest = async (
    body: NpsSubscriptionApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsSubscriptionResponse>> => {
    const path = `/secure/nps/users/subscription`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to send detax card subscription request
   * @param body -
   */
  export const sendSubscriptionRequest = async (
    body: DetaxCardSubscriptionApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardSubscriptionResponse>> => {
    const path = `/secure/detax/subscriptions/cards`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  // FOR MEMBERSHIP DETAX+ COUNT API HIT
  export const getMembershipDetaxplusCount = async (
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipDetaxCountApiResponse>> => {
    const path = `/secure/corporates/membership/getAll/membershipEntry`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  // FOR MEMBERSHIP DETAX COUNT API HIT
  export const getMembershipDetaxCount = async (
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipDetaxCountApiResponse>> => {
    const path = `secure/corporates/employees/membershiptype/DETAX`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  // FOR MEMBERSHIP SEND INVITTION
  export const sendInvitationRequest = async (
    body: MembershipBatchCountApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardSubscriptionResponse>> => {
    const path = `/secure/corporates/membership/batch/create`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  // FOR MEMBERSHIP BATCH STATUS COUNT
  /**
   * Api to get status wise subscription count
   * @param status -
   */
  export const getMembershipBatchCount = async (
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipDetaxCountApiResponse>> => {
    const path = `/secure/corporates/membership/batch/status/counts`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to get All detax card subscription requests
   * @param status -
   */
  export const getMembershipBatchTable = async (
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, MembershipBatchTableResponse>> => {
    const path = `/secure/corporates/membership/batch/batchWithStatus`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
    ];
    const queryParams = allQueryParams
      // .map((param) => `${param.key}=${param.value}`)
      .map((param) => `${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`/${queryParams}`);
    // const apiUrl = queryParams === "" ? path : path;

    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
