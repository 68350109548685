import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";
import { FiFileText } from "react-icons/fi";
import { GoGift } from "react-icons/go";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { ClaimApproverTblData } from "../../../../api/detax/model/claimApprovers";
import { useAppSelector } from "../../../../hooks/hooks";
import { ClaimsService } from "../../../../services/claims.service";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import {
  ReportsDispatch,
  ReportsReduxStore,
} from "../../../../state/slice/reports.slice";
import { fromPublic } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import Heading from "../common/heading";
import EmployeeRewards from "./employeeRewards";
import ReportsList from "./reportsList";
import ReportsTab from "./reportsTab";
import "./settings.scss";

const closeIcon = fromPublic("/images/icons/back.svg");

const Settings: React.FC = () => {
  const [searchVal, setSearchVal] = useState("");
  const [hide, setHide] = useState(false);
  const [active, setActive] = useState<number | null>(0);
  const navigate = useNavigate();
  const giftCardStatus = useAppSelector(AlphaReduxStore.selectGiftCardStatus);
  const newClaimStatus = useAppSelector(AlphaReduxStore.selectNewClaimsStatus);
  const accessPath = (
    userPermissions: string[], // permissions user opted while onboarding
    accessPermissions: string[], // system define can access permission
    path: string
  ) => {
    const canAccess = accessPermissions.some((p) =>
      userPermissions.includes(p)
    );
    return canAccess ? path : RoutePaths.DashboardSubscribe;
  };
  const [employees, setEmployees] = useState<ClaimApproverTblData[]>([]);
  console.log("Employee", employees?.length);
  const [err, setErr] = useState(false);
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {

      const tblResult = await ClaimsService.getClaimApprovers();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setEmployees(tblResult.value.data || []);
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();
  }, []);
  const [collapsed, setCollapsed] = useState(true);
  const showNew = useAppSelector(ReportsReduxStore.selectShowNew);

  const handleMouseEnter = () => {
    setCollapsed(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
  };

  return (
    <>
      <PageTitle title="Settings" />
      <div
        style={{
          // border: "1px solid black",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Sidebar
          style={{ height: "100vh" }}
          // image={sidebarImage}
          // collapsed={collapsed}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          // title="Settings"
          backgroundColor="#FFFFFF"
        >
          <Menu>
            <MenuItem
              // icon={<FcSettings size={25} />}
              disabled={true}
            >
              <Heading title="Settings" />
            </MenuItem>
            <MenuItem
              icon={
                <FiFileText
                  color={active === 0 ? "#3183FE" : "#777777"}
                  size={15}
                />
              }
              style={{
                backgroundColor: active === 0 ? "#D2EAFF" : "transparent",
                color: active === 0 ? "#3183FE" : "#777777",
              }}
              onClick={() => {
                setActive(0);
              }}
            >
              Reports
            </MenuItem>
            {giftCardStatus && (
              <MenuItem
                style={{
                  backgroundColor: active === 1 ? "#D2EAFF" : "transparent",
                  color: active === 1 ? "#3183FE" : "#777777",
                }}
                icon={
                  <GoGift
                    size={15}
                    color={active === 1 ? "#3183FE" : "#777777"}
                  />
                }
                onClick={() => setActive(1)}
              >
                Employee Rewards
              </MenuItem>
            )}
            {newClaimStatus && (
              <MenuItem
                icon={
                  <BsCheck2Circle
                    color={active === 2 ? "#3183FE" : "#777777"}
                    size={15}
                  />
                }
                style={{
                  backgroundColor: active === 2 ? "#D2EAFF" : "transparent",
                  color: active === 2 ? "#3183FE" : "#777777",
                }}
                onClick={() => {
                  setActive(2);
                  navigate(
                    employees?.length <= 1
                      ? RoutePaths.DashboardClaimApprover
                      : RoutePaths.DashboardSettingsRole
                  );
                }}
              >
                Claim Approvers
              </MenuItem>
            )}

            {/* <SubMenu label="Charts">
              <MenuItem> Pie charts </MenuItem>
              <MenuItem> Line charts </MenuItem>
            </SubMenu> */}
          </Menu>
        </Sidebar>
        <div style={{ width: "100%", height: "100%" }} className="m-1">
          {active === 1 && (
            <div>
              <Heading title="Employee Rewards" />
              <EmployeeRewards />
            </div>
          )}
          {active === 0 && (
            <div style={{ padding: "1%" }}>
              <div
                style={{
                  display: !hide ? undefined : "none",
                }}
              >
                <p style={{ fontWeight: "bold", margin: 0, fontSize: 18 }}>
                  Reports
                </p>
                <p
                  style={{
                    color: "#777777",
                    fontSize: 14,
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="my-2 mb-3 border-bottom"
                >
                  <span>Export your reports</span>

                  <Button
                    className="btn-gradiant-blue btn-lg text-white fs-small"
                    style={{
                      // fontSize: 16,
                      opacity: !hide ? 1 : 0,
                      marginTop: -10,
                      marginBottom: 5,
                    }}
                    onClick={() => {
                      setHide(true);
                      ReportsDispatch.setShowNew(false);
                    }}
                    disabled={hide}
                  >
                    {/* {load && (
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                      ></span>
                    )} */}
                    Downloads
                    {showNew && (
                      <span
                        style={{
                          position: "absolute",
                          zIndex: 99,
                          marginTop: -15,
                          backgroundColor: "red",
                          fontSize: 10,
                          borderRadius: 25,
                        }}
                        className="px-1 "
                      >
                        New
                      </span>
                    )}
                  </Button>
                </p>
                <div
                  style={{
                    // border: "1px solid black",
                    height: "83vh",
                    scrollBehavior: "auto",
                    overflow: "scroll",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ReportsTab />
                </div>
              </div>

              <div
                style={{
                  display: hide ? undefined : "none",
                  // borderLeft: "1px solid #6d6d6d",
                  // paddingLeft: 10,
                  alignSelf: "stretch",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                    fontSize: 18,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={closeIcon}
                    style={{
                      height: 15,
                      width: 15,
                      cursor: "pointer",
                      display: "flex",
                    }}
                    onClick={() => {
                      setHide(false);
                    }}
                  />
                  <span style={{ marginLeft: 10 }}>Downloads</span>
                </p>
                <p
                  style={{
                    color: "#777777",
                    fontSize: 14,
                    fontWeight: "600",
                    display: "flex",
                    justifyContent: "space-between",
                    // marginLeft: 15,
                  }}
                  className="my-2 mb-3 border-bottom"
                >
                  <span>View list of downloaded reports</span>
                  {/* <span style={{ fontSize: 16, opacity: 0 }} onClick={() => {}}>
                    Downloads
                  </span> */}
                </p>
                <div
                  style={{
                    // border: "1px solid black",
                    height: "83vh",
                    scrollBehavior: "auto",
                    overflow: "scroll",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ReportsList show={hide} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Settings;
