import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { BackButton, Button, SmallScreen } from "../..";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import PageLoader from "../../../page-loader";
import { OnboardingService } from "../../../services/onboarding.service";
import { TCorporateUser } from "../../../types/corporate-user.type";
import { TReceipientDto } from "../../../types/update-receipient-request.type";
import {
  fromPublic,
  validateEmail,
  validateMobile,
  validateName,
} from "../../../utils/common.utils";
import {
  receipientToDirector,
  receipientToSignatory,
} from "../../../utils/contract.utils";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";
import { selectDraftId } from "../../app/app.slice";
import AuthorizeSignatory from "../../contract/authorize-signatory";
import { setDirectors, setReceipients } from "../../contract/contract.slice";
import DirectorDropdown from "../../contract/director/director-dropdown";
import Stepper from "../../stepper/stepper";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import BrandLogoHeader from "./brandlogo-header";

const User = fromPublic("/images/user.svg");
const Group = fromPublic("/images/icons/group-1.svg");
const Image1 = fromPublic("/images/director.svg");

export const recipient = {
  userId: "",
  name: "",
  email: "",
  mobile: "",
};

export interface ISegnatory {
  userId: string;
  name: string;
  email: string;
  mobile: string;
}

export interface IErrVal {
  index: number;
  message: string;
}
export interface IDirectorErr {
  email: IErrVal[];
  mobile: IErrVal[];
}
export interface ISegnatoryErr {
  name: IErrVal[];
  email: IErrVal[];
  mobile: IErrVal[];
}

const DigitalContractPage: React.FC = () => {
  const draftId = useAppSelector(selectDraftId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedDirectors, setSelectedDirector] = useState<TCorporateUser[]>(
    []
  );
  const [selectedSignatories, setAddSignatories] = useState([recipient]);
  const [maxRecipientErr, setMaxRecipientErr] = useState<boolean>(false);

  const [directorErr, setDirectorErr] = useState<IDirectorErr>({
    email: [],
    mobile: [],
  });
  const [segnatoryErr, setSegnatoryErr] = useState<ISegnatoryErr>({
    name: [],
    email: [],
    mobile: [],
  });
  const [noRecipientErr, setNoRecipientErr] = useState(false);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Digital Contract" });
    const run = async () => {
      const result = await OnboardingService.getContractReceipient(draftId);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          dispatch(setDirectors(result.value.directors));
          dispatch(setReceipients(result.value.receipients));
          if (result.value.receipients.length > 0) {
            setSelectedDirector(receipientToDirector(result.value.receipients));
            setAddSignatories(receipientToSignatory(result.value.receipients));
          }
          setLoading(false);
        } else {
          setLoading(false);
          //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
        }
      } else {
        setLoading(false);
        // send to error page
      }
    };
    run();
  }, []);

  const handleFormSubmit = async (values: any) => {
    if (isLoading) return;
    mixpanel.track("Button click", { name: "Create contract" });
    setNoRecipientErr(false);
    setError("");
    setIsLoading(true);
    const data: TReceipientDto[] = [];

    let dirErr: IDirectorErr = {
      email: [],
      mobile: [],
    };
    let sigErr: ISegnatoryErr = {
      name: [],
      email: [],
      mobile: [],
    };

    let sEmails = selectedSignatories
      .filter((i) => i.email != "")
      .map((i) => i.email);
    let dEmails = selectedDirectors
      .filter(
        (i) =>
          pipe(
            i.email,
            getOrElse(() => "")
          ) != ""
      )
      .map((i) =>
        pipe(
          i.email,
          getOrElse(() => "")
        )
      );
    let emails = [...dEmails, ...sEmails];

    let sMobiles = selectedSignatories
      .filter((i) => i.mobile != "")
      .map((i) => i.mobile);
    let dMobiles = selectedDirectors
      .filter(
        (i) =>
          pipe(
            i.mobile,
            getOrElse(() => "")
          ) != ""
      )
      .map((i) =>
        pipe(
          i.mobile,
          getOrElse(() => "")
        )
      );

    let mobiles = [...dMobiles, ...sMobiles];

    selectedDirectors.forEach((d, dk) => {
      let email = pipe(
        d.email,
        getOrElse(() => "")
      );
      let mobile = pipe(
        d.mobile,
        getOrElse(() => "")
      );

      if (email == "")
        dirErr.email.push({ index: dk, message: "Email ID is required!" });
      else if (!validateEmail(email))
        dirErr.email.push({ index: dk, message: "Invalid email ID" });
      else if (emails.filter((i) => i == email).length > 1) {
        if (!sEmails.includes(email)) {
          if (dk === dEmails.lastIndexOf(email))
            if (dirErr.email.filter((a, b) => a.index == dk).length == 0)
              dirErr.email.push({
                index: dk,
                message: "Email ID has already entered",
              });
            else
              dirErr.email = dirErr.email.map((a, b) => {
                if (a.index == dk) a.message = "Email ID has already entered";
                return a;
              });
        }
        /* dirErr.email.push({
          index: dk,
          message: "Email ID has already entered",
        }); */
      }

      if (mobile == "")
        dirErr.mobile.push({ index: dk, message: "Mobile no. is required!" });
      else if (!validateMobile(mobile))
        dirErr.mobile.push({ index: dk, message: "Invalid mobile no." });
      else if (mobiles.filter((i) => i == mobile).length > 1) {
        if (!sMobiles.includes(mobile)) {
          if (dk === dMobiles.lastIndexOf(mobile))
            if (dirErr.mobile.filter((a, b) => a.index == dk).length == 0)
              dirErr.mobile.push({
                index: dk,
                message: "Mobile no. has already entered",
              });
            else
              dirErr.mobile = dirErr.mobile.map((a, b) => {
                if (a.index == dk) a.message = "Mobile no. has already entered";
                return a;
              });
        }
        /* dirErr.mobile.push({
          index: dk,
          message: "Mobile no. has already entered",
        }); */
      }

      data.push({
        userId: pipe(
          d.userId,
          getOrElse(() => "")
        ),
        name: pipe(
          d.name,
          getOrElse(() => "")
        ),
        email,
        mobile,
        type: "DIRECTOR",
      });
    });
    setDirectorErr(dirErr);

    selectedSignatories.forEach((d, dk) => {
      if (d.name == "")
        sigErr.name.push({ index: dk, message: "Name is required!" });
      else if (!validateName(d.name))
        sigErr.name.push({ index: dk, message: "Invalid Name" });

      if (d.email == "")
        sigErr.email.push({ index: dk, message: "Email ID is required!" });
      else if (!validateEmail(d.email))
        sigErr.email.push({ index: dk, message: "Invalid email ID" });
      else if (emails.filter((i) => i == d.email).length > 1) {
        if (dk === sEmails.lastIndexOf(d.email))
          if (sigErr.email.filter((a, b) => a.index == dk).length == 0)
            sigErr.email.push({
              index: dk,
              message: "Email ID has already entered",
            });
          else
            sigErr.email = sigErr.email.map((a, b) => {
              if (a.index == dk) a.message = "Email ID has already entered";
              return a;
            });
        /* sigErr.email.push({
          index: dk,
          message: "Email ID has already entered",
        }); */
      }

      if (d.mobile == "")
        sigErr.mobile.push({ index: dk, message: "Mobile no. is required!" });
      else if (!validateMobile(d.mobile))
        sigErr.mobile.push({ index: dk, message: "Invalid mobile no." });
      else if (mobiles.filter((i) => i == d.mobile).length > 1) {
        if (dk === sMobiles.lastIndexOf(d.mobile))
          if (sigErr.mobile.filter((a, b) => a.index == dk).length == 0)
            sigErr.mobile.push({
              index: dk,
              message: "Mobile no. has already entered",
            });
          else
            sigErr.mobile = sigErr.mobile.map((a, b) => {
              if (a.index == dk) a.message = "Mobile no. has already entered";
              return a;
            });
        /* sigErr.mobile.push({
          index: dk,
          message: "Mobile no. has already entered",
        }); */
      }

      if (d.name) {
        data.push({
          userId: d.userId,
          name: d.name,
          email: d.email,
          mobile: d.mobile,
          type: "SIGNATORY",
        });
      }
    });
    setSegnatoryErr(sigErr);

    // setIsLoading(false);

    if (selectedSignatories.length + selectedDirectors.length <= 1) {
      setNoRecipientErr(true);
    } else if (
      dirErr.email.length == 0 &&
      dirErr.mobile.length == 0 &&
      sigErr.name.length == 0 &&
      sigErr.email.length == 0 &&
      sigErr.mobile.length == 0
    ) {
      // console.log("OKK");
      const result = await OnboardingService.updateSignatories(data, draftId);
      if (isSuccess(result)) {
        setIsLoading(false);
        if (
          pipe(
            result.value.successful,
            getOrElse(() => false)
          )
        ) {
          const path = pipe(
            result.value.nextAction,
            getOrElse(() => "/")
          );
          navigate(path);
        } else {
          setError("Signup failed");
        }
      } else if (isFailure(result)) {
        setIsLoading(false);
        setError("Signup failed");
      }
    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container p-0">
        <BrandLogoHeader />
        {/* <Stepper /> */}
        <div className="row">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block col-md-6 col-lg-7 col-xl-8 col-xxl-8">
            <div
              className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6"
              style={{ marginTop: "20px" }}
            >
              <img
                className="img-fluid"
                width="948"
                height="707"
                src={Image1}
                alt="Mobile"
              />
            </div>
            <div className="mx-5 mt-4 textDetail">
              <h5
                className="mx-5 mb-3 text-black fw-bold"
                style={{ marginTop: "80px" }}
              >
                Welcome Onboard
              </h5>
              <p className="mx-5 fw-bolder text-secondary">
                These Directors are captured as per ROC. Please select the right
                authorised signatories who are authorised as per your Board
                Resolution submitted earlier with Figital Technologies. Then
                please add Official User Names who will be accessing the
                Platform.
              </p>
            </div>
          </div>
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block col-md-6 col-lg-5 col-xl-4 col-xxl-4">
            <div className="container p-0 m-0">
              <div className="px-4 py-3 mb-5 border-0 shadow card bg-grey rounded-15 digital-scroll">
                <BackButton
                  text="Assign users"
                  path={RoutePaths.CompanyReview}
                  canGoBack={true}
                />
                <div
                  className="custom-scroll"
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "65vh",
                  }}
                >
                  <DirectorDropdown
                    devicetype="desktop"
                    selectedDirectors={selectedDirectors}
                    setSelectedDirector={setSelectedDirector}
                    errors={directorErr}
                    setNoRecipientErr={setNoRecipientErr}
                    selectedSignatories={selectedSignatories}
                    maxRecipientErr={maxRecipientErr}
                    setMaxRecipientErr={(flag) => setMaxRecipientErr(flag)}
                  />
                  <AuthorizeSignatory
                    devicetype="desktop"
                    signatories={selectedSignatories}
                    setAddSignatories={setAddSignatories}
                    errors={segnatoryErr}
                    setNoRecipientErr={setNoRecipientErr}
                    selectedDirectors={selectedDirectors}
                    maxRecipientErr={maxRecipientErr}
                    setMaxRecipientErr={(flag) => setMaxRecipientErr(flag)}
                  />
                  {noRecipientErr && (
                    <div className="mt-2 text-center fs-small ncash_validate-error">
                      At least 2 recipient is required
                    </div>
                  )}
                </div>

                <div className="ml-auto mr-auto text-center">
                  {maxRecipientErr && (
                    <div className="my-2 text-center fs-small ncash_validate-error">
                      Maximum receipients has reached
                    </div>
                  )}
                  <Button type="button" onClick={handleFormSubmit}>
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="pt-3 border-0 card bg-grey">
                <BackButton text="Assign users" />
                <DirectorDropdown
                  devicetype="mobile"
                  selectedDirectors={selectedDirectors}
                  setSelectedDirector={setSelectedDirector}
                  errors={directorErr}
                  setNoRecipientErr={setNoRecipientErr}
                  selectedSignatories={selectedSignatories}
                  maxRecipientErr={maxRecipientErr}
                  setMaxRecipientErr={(flag) => setMaxRecipientErr(flag)}
                />
                <AuthorizeSignatory
                  devicetype="mobile"
                  signatories={selectedSignatories}
                  setAddSignatories={setAddSignatories}
                  errors={segnatoryErr}
                  setNoRecipientErr={setNoRecipientErr}
                  selectedDirectors={selectedDirectors}
                  maxRecipientErr={maxRecipientErr}
                  setMaxRecipientErr={(flag) => setMaxRecipientErr(flag)}
                />
                {noRecipientErr && (
                  <div className="mt-2 text-center fs-small ncash_validate-error">
                    At least 2 recipient is required
                  </div>
                )}
                {maxRecipientErr && (
                  <div className="mt-2 text-center fs-small ncash_validate-error">
                    Maximum receipients has reached
                  </div>
                )}
                <div className="mt-5 ml-auto mr-auto text-center">
                  <Button type="button" onClick={handleFormSubmit}>
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </>
  );
};

export default DigitalContractPage;
