import React from "react";
import { FiFileText } from "react-icons/fi";
const ReportHeader: React.FC<{ title: string; desc: string }> = ({
  title,
  desc = "",
}) => {
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #EEEEEE",
            borderRadius: 50,
            height: 35,
            width: 35,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiFileText size={17} strokeWidth={1} />
        </div>
        <div className="m-2">
          <p
            className="m-0"
            style={{ color: "black", fontWeight: "600", fontSize: 16 }}
          >
            {title}
          </p>
          <p
            className="m-0"
            style={{ color: "#777777", fontWeight: "500", fontSize: 13 }}
          >
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
