import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { setDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { getTitleCase } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import CusomtomGraphs from "../../../graph/graph";
import WhiteCard from "../../../white-card/white-card";
import { ClaimCountInfo } from "./../../../../model/claimCountInfo";

const DashboardClaim: React.FC<{
  data: ClaimCountInfo[] | [];
}> = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let totalCount = () => {
    let total = 0;
    if (data.length > 0) {
      data.map((item) => {
        total = total + (item?.count || 0);
      });
    }

    return total;
  };

  const color = (status: ClaimCountInfo.StatusEnum | null) => {
    let color = "#ddd";
    if (status == "PENDING") color = "#ffbd36";
    else if (status == "APPROVED") color = "#23c212";
    else if (status == "REJECTED") color = "#ff255f";
    else if (status == "REIMBURSED") color = "#23c212";
    else if (status == "CANCELLED") color = "#ffbd36";
    return color;
  };
  return (
    <WhiteCard>
      <h4 className="page-sub-heading"> Claims</h4>
      {totalCount() > 0 ? (
        <div className="col">
          <div className="h-100 text-center pb-3 d-block">
            <CusomtomGraphs
              color="#DCFFE8"
              width="100%"
              height="60"
              value={totalCount()}
            />
            <h4 className="pt-1 mt-1 mb-0">Total Claims</h4>
            <div className="row">
              {data.map((item, key) => (
                <div
                  onClick={() => {
                    dispatch(
                      setDashboardRedirect({
                        page: RoutePaths.DashboardClaims,
                        card: item?.status,
                      })
                    );
                    navigate(RoutePaths.DashboardClaims);
                  }}
                  key={key}
                  className="col-md-4 mt-4"
                >
                  <div
                    style={{
                      width: "100%",
                      height: 70,
                      cursor: "pointer",
                    }}
                  >
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={[
                            {
                              name: item.label,
                              value: item.count,
                              color: color(item?.status || null),
                            },
                            {
                              name: "Other",
                              value: totalCount() - (item.count || 0),
                              color: "#e3e3e3",
                            },
                          ]}
                          nameKey="name"
                          dataKey="value"
                          innerRadius="60%"
                          outerRadius="80%"
                          startAngle={90}
                          endAngle={-270}
                          fill="#8884d8"
                        >
                          {[
                            {
                              name: item.label,
                              value: item.count || 0,
                              color: color(item?.status || null),
                            },
                            {
                              name: "Other",
                              value: totalCount() - (item.count || 0),
                              color: "#e3e3e3",
                            },
                          ].map((entry) => (
                            <Cell fill={entry.color} />
                          ))}
                          <Label width={30} position="center">
                            {item.count || 0}
                          </Label>
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <div className="text-muted text-center">
                      <small>{getTitleCase(item.status || "")}</small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="col">
          <div className="h-100 text-center pb-3 d-block">
            <CusomtomGraphs
              color="#DCFFE8"
              width="100%"
              height="60"
              value={0}
            />
            <h4 className="pt-1 mt-1 mb-0">Total Claims</h4>
          </div>
        </div>
      )}
    </WhiteCard>
  );
};

export default DashboardClaim;
