import { remoteData } from "@devexperts/remote-data-ts";
import { fromNullable } from "fp-ts/lib/Option";
import { NcaishCorporateEmployeeApiService } from "../api/employee/api/ncaishCorporateEmployeeApi.service";
import { AddBulkEmployeeApiRequest } from "../api/employee/model/addBulkEmployeeApiRequest";
import { AddEmployeeApiRequest } from "../api/employee/model/addEmployeeApiRequest";
import { EmployeeDetailApiResponse } from "../api/employee/model/employeeDetailApiResponse";
import { EmployeeListApiResponse } from "../api/employee/model/employeeListApiResponse";
import { UpdateEmployeeSalaryRequest } from "../api/employee/model/updateEmployeeSalaryRequest";
import { UserStatusCountResponse } from "../api/employee/model/userStatusCountResponse";
import { EmployeeApiService } from "../api/employeeApi.service";
import { AddBulkEmployeeRequest } from "../model/addBulkEmployeeRequest";
import { EmployeeListResponse } from "../model/employeeListResponse";
import { EmployeePayload } from "../model/employeePayload";
import { EmployeeRequest } from "../model/employeeRequest";
import { EmployeeResponse } from "../model/employeeResponse";
import { Pagination } from "../model/pagination";
import { TPagination } from "../types/pagination.type";

export namespace EmployeeService {
  export const getAllEmployeeData = async (
    status?: string,
    pn?: number,
    rc?: number
  ) => {
    const statusString = status === "All" ? "" : status;
    const result = await NcaishCorporateEmployeeApiService.getAll(
      statusString,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: EmployeeListApiResponse) => response
    );
  };

  export const getSearchEmployeeData = async (
    status?: string,
    keyword?: string,
    pn?: number,
    rc?: number
  ) => {
    const statusString = status === "All" ? "" : status;
    const result = await NcaishCorporateEmployeeApiService.getSearch(
      statusString,
      keyword,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: EmployeeListApiResponse) => response
    );
  };

  const parseEmployeesData = (res: EmployeeListResponse) => ({
    successful: res.successful ? res.successful : false,
    message: res.message ? res.message : "",
    data: res.data ? res.data : [],
    hm: res.hm ? res.hm : false,
  });

  export const addEmployee = async (request: AddEmployeeApiRequest) => {
    const body: AddEmployeeApiRequest = {
      code: request.code,
      doj: request.doj,
      dob: request.dob,
      name: request.name,
      mobile: request.mobile,
      email: request.email,
      salaryInfo: request.salaryInfo,
      address: request.address,
      gender: request.gender,
      firstName: request.firstName,
      lastName: request.lastName,
      membershipType: request.membershipType,
      businessUnit: request.businessUnit,
      taxRegime: request.taxRegime,
    };
    const result = await NcaishCorporateEmployeeApiService.create(body);
    // console.log("add employee", result);
    return remoteData.map(result, (res: EmployeeResponse) => ({
      successful: res.successful,
      message: fromNullable(res.message),
      data: res.data || {},
    }));
  };

  export const getEmployeeData = async (request: string) => {
    const result = await EmployeeApiService.getById(request);
    return remoteData.map(result, (res: EmployeeResponse) => ({
      successful: res.successful,
      message: fromNullable(res.message),
      data: res.data || {
        empId: "",
        code: "",
        doj: "",
        name: "",
        mobile: "",
        email: "",
        salary: 0,
        membershipType: "",
        gender: "MALE",
        address: {
          address: "",
          state: "",
          country: "India",
          pinCode: "",
          // location: {
          //   lat: 0,
          //   lng: 0,
          // },
        },
      },
    }));
  };

  export const update = async (body: AddEmployeeApiRequest, id: string) => {
    const result = await NcaishCorporateEmployeeApiService.update(body, id);
    console.log("service error: ", result);
    return remoteData.map(result, (response: EmployeeResponse) => response);
  };

  export const updateEmployeeData = async (
    request: EmployeeRequest,
    id: string
  ) => {
    const result = await EmployeeApiService.update(request, id);
    return remoteData.map(result, (res: EmployeeResponse) => ({
      successful: res.successful,
      message: fromNullable(res.message),
      data: res.data || {
        empId: "",
        code: "",
        doj: "",
        name: "",
        mobile: "",
        email: "",
        salary: 0,
        membershipType: "",
        gender: "MALE",
        address: {
          address: "",
          state: "",
          country: "India",
          pinCode: "",
          // location: {
          //   lat: 0,
          //   lng: 0,
          // },
        },
      },
    }));
  };

  export const addBulkEmployeeData = async (
    request: AddBulkEmployeeRequest
  ) => {
    const result = await EmployeeApiService.createBulk(request);
    return remoteData.map(result, parseEmployeesData);
  };

  export const getById = async (id: string) => {
    const result = await NcaishCorporateEmployeeApiService.getById(id);
    return remoteData.map(
      result,
      (response: EmployeeDetailApiResponse): EmployeeResponse => {
        return {
          successful: response.successful,
          message: response.message,
          data: response.data,
        };
      }
    );
  };

  export const employeeCounts = async () => {
    const result = await NcaishCorporateEmployeeApiService.employeeCounts();
    return remoteData.map(
      result,
      (response: UserStatusCountResponse) => response
    );
  };

  export const createBulk = async (body: AddBulkEmployeeApiRequest) => {
    const result = await NcaishCorporateEmployeeApiService.createBulk(body);
    return remoteData.map(
      result,
      (response: EmployeeListApiResponse) => response
    );
  };

  export const updateSalary = async (
    body: UpdateEmployeeSalaryRequest,
    id: string
  ) => {
    const result = await NcaishCorporateEmployeeApiService.updateSalary(
      body,
      id
    );
    return remoteData.map(
      result,
      (response: EmployeeDetailApiResponse) => response
    );
  };

  export const getBusinessUnit = async () => {
    const result = await NcaishCorporateEmployeeApiService.getBusinessUnit();
    return remoteData.map(
      result,
      (response: EmployeeDetailApiResponse): EmployeeResponse => {
        return {
          successful: response.successful,
          message: response.message,
          data: response.data,
        };
      }
    );
  };
}
