import { isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { fold, isSome, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EmployeeInfo } from "../../../../api/detax/model/employeeInfo";
import { NpsService } from "../../../../services/nps.service";
import { adminTableHeight } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import TableLoader from "../common/table-loader";

const NpsInvitationTable: React.FC<{
  keyword?: string;
  uids: string[];
  setUids: (data: string[]) => void;
  searchVal: string;
}> = ({ keyword = "", uids, setUids, searchVal = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<Option<EmployeeInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<EmployeeInfo[]>>(none);
  useEffect(() => {
    const run = async () => {
      setItems(none);
      const tblResult = await NpsService.searchNpsEligibleEmployee(keyword);
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setItems(some(tblResult.value.data || []));
          filterData(some(tblResult.value.data || []));
        }
      }
    };
    run();
  }, []);

  useEffect(() => filterData(items), [searchVal]);

  const filterData = (allData: Option<EmployeeInfo[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );
    if (searchVal) {
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let mobRegEx = new RegExp(searchVal + ".*", "gi");
      let filteredData = data.filter(
        (item) =>
          nameRegEx.test(item?.name || "") || mobRegEx.test(item.mobile || "")
      );
      // setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      // setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };

  const isCheckAll = () => {
    let dataLen = 0;
    if (isSome(items)) {
      dataLen = items.value.length;
    }
    return dataLen === uids.length;
  };
  const mngCheckAll = (e: any) => {
    let newUids: any = [];
    if (e.target.checked) {
      if (isSome(items)) {
        newUids = items.value.map((i) => i.empId);
      }
    }
    setUids(newUids);
  };

  return (
    <div style={{ maxHeight: "350px", minHeight: "350px" }}>
      {isNone(filteredData) ? (
        <TableLoader />
      ) : (
        <div
          className="custom-table"
          style={{
            maxHeight: "350px",
            minHeight: "350px",
          }}
        >
          {pipe(
            filteredData,
            fold(
              () => [<NoTableData />],
              (items) =>
                items.length > 0
                  ? [
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">
                              <input
                                checked={isCheckAll()}
                                onChange={(e) => mngCheckAll(e)}
                                className="form-check-input cardCheckbox"
                                type="checkbox"
                              />
                            </th>
                            <th scope="col">Emp-ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Salary</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item, key) => (
                            <tr key={key}>
                              <td>
                                {item.empId && (
                                  <input
                                    type="checkbox"
                                    className="form-check-input cardCheckbox"
                                    checked={uids.includes(item.empId)}
                                    onChange={(e) => {
                                      if (item.empId)
                                        if (uids.includes(item.empId)) {
                                          setUids(
                                            uids.filter(
                                              (i: string) => i != item.empId
                                            )
                                          );
                                        } else {
                                          setUids([...uids, item.empId]);
                                        }
                                    }}
                                  />
                                )}
                              </td>
                              <td>
                                <Link
                                  className="text-secondary ncash_nav-link"
                                  to={
                                    RoutePaths.DashboardNpsDetail +
                                    "/" +
                                    item.empId
                                  }
                                >
                                  {item.code === "" ? "-" : item.code}
                                </Link>
                              </td>
                              <td>{item.name === "" ? "-" : item.name} </td>
                              <td>{item.mobile === "" ? "-" : item.mobile}</td>
                              <td>{item.email === "" ? "-" : item.email}</td>
                              <td>{item.salaryInfo?.salary || 0}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>,
                    ]
                  : [<NoTableData />]
            )
          )}
        </div>
      )}
    </div>
  );
};

export default NpsInvitationTable;
