import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EmployeeService } from "../../../../services/employee.service";
import HorizontalTabs from "../../../horizontal-tabs/horizontal-tabs";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import {
  CardDetailsTabs,
  Claims,
  FlexiAllowance,
  SalaryStructure,
  Transactions,
} from "../../../common/vertical-tablist/tabs/employee/tabs";
import VerticalTabs from "../../../common/vertical-tablist/tabs/employee/vertical-tablist";
import EmployeeDetails from "../common/employee-details";
import { EmployeeInfo } from "../../../../api/detax/model/employeeInfo";
import NPSverticalTabs from "../../../common/vertical-tablist/tabs/nps/vertical-tablist";
import {
  Contribution,
  PranAccount,
} from "../../../common/vertical-tablist/tabs/nps/nps-tabs";
import PageTitle from "../../../common/page-title";
import Heading from "../common/heading";
import { RoutePaths } from "../../../../utils/route.utils";
import { useDispatch } from "react-redux";
import { NpsService } from "../../../../services/nps.service";
import { NpsAccountResponse } from "../../../../api/detax/model/npsAccountResponse";

const EmployeesDetail: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [tabData, setTabData] = useState<NpsAccountResponse>();
  const [activeTab, setActiveTab] = useState("card");
  const [activeNPStab, setActiveNPStab] = useState("PranAccount");
  const [activeHtab, setActiveHtab] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<EmployeeInfo>({
    empId: params.id,
    code: "",
    doj: "",
    dob: "",
    name: "",
    mobile: "",
    email: "",
    salaryInfo: { salary: 0 },
    address: {
      address: "",
      state: "",
      country: "India",
      pinCode: "",
      // location: {
      //   lat: 0,
      //   lng: 0,
      // },
    },
    detaxCard: true,
    nps: true,
    membershipType: "",
  });

  useEffect(() => {
    if (params?.id) {
      loadData(params.id || "");
      navigate(RoutePaths.EmployeesDetail + "/" + params.id);
    }
  }, [params.id]);

  const loadData = async (id: string) => {
    const result = await EmployeeService.getById(id);
    if (isSuccess(result)) {
      setEmployee((p) => ({ ...p, ...result.value.data }));
      if (result.value.data?.detaxCard) setActiveHtab("detaxCard");
      else if (result.value.data?.nps) setActiveHtab("nps");
      else setActiveHtab("");
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <PageTitle title={`Employee | ${employee.name}`} />
      <Heading
        title="Employee details"
        detail={employee.name || ""}
        status={/* details?.status || */ ""}
        links={[RoutePaths.EmployeesAdd]}
      />

      {params.id && <EmployeeDetails id={params.id} edit />}

      <HorizontalTabs
        isDetaxCard={employee.detaxCard}
        isNPS={employee.nps}
        tab={activeHtab}
        setTab={(tab) => setActiveHtab(tab || "")}
      />

      {employee.detaxCard && activeHtab == "detaxCard" && (
        <div className="tab-content mt-3">
          <div className="tab-pane active">
            <div className="row">
              <div className="col-2">
                <WhiteCard className="mt-0 rounded shadow-none">
                  <VerticalTabs
                    activeTab={activeTab}
                    onClick={(tab) => setActiveTab(tab)}
                  />
                </WhiteCard>
              </div>
              <div className="col-10">
                {activeTab === "card" && <CardDetailsTabs uid={params?.id} />}
                {activeTab === "claims" && <Claims id={params?.id} detaxCard />}
                {activeTab === "allowance" && (
                  <FlexiAllowance uid={params?.id} />
                )}
                {activeTab === "salary" && <SalaryStructure />}
                {activeTab === "transactions" && (
                  <Transactions id={params?.id} callFrom="employee" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {employee.nps && activeHtab == "nps" && (
        <div className="tab-content mt-3">
          <div className="tab-pane active">
            <div className="row">
              <div className="col-2">
                <WhiteCard className="mt-0 rounded shadow-none">
                  <NPSverticalTabs
                    activeTab={activeNPStab}
                    onClick={(tab) => setActiveNPStab(tab)}
                  />
                </WhiteCard>
              </div>
              <div className="col-10">
                {activeNPStab === "PranAccount" && (
                  <PranAccount uid={params?.id} />
                )}
                {activeNPStab === "Contribution" && (
                  <Contribution uid={params?.id} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EmployeesDetail;
