import { isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { fold, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClaimEmployeeTblData } from "../../../../api/detax/model/claimEmployees";
import { APIError } from "../../../../api/errors";
import { ClaimsService } from "../../../../services/claims.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import NoTableData from "../common/no-table-data";
import { ITableData, TablePagination } from "../common/table/table-pagination";

const EmployeesRolesTable: React.FC<{
  searchVal?: string;
  show: () => void;
  setPopupData: (data: any) => any;
}> = ({ searchVal = "", show, setPopupData }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<APIError>();
  const [employees, setEmployees] =
    useState<Option<ClaimEmployeeTblData[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<ClaimEmployeeTblData[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {

      const tblResult = await ClaimsService.getClaimEmployees();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setPaging((p) => ({
            ...p,
            total: (tblResult.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setEmployees(some(tblResult.value.data || []));
          setFilteredData(some(tblResult.value.data || []));
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();
    dispatch(resetDashboardRedirect());
  }, []);

  const tblData = (tblData: ClaimEmployeeTblData[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  useEffect(() => filterData(employees), [searchVal]);
  const filterData = (allData: Option<ClaimEmployeeTblData[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );
    if (searchVal) {
      let empCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as employee code
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let mobRegEx = new RegExp(searchVal + ".*", "gi");
      let filteredData = data.filter(
        (item) =>
          nameRegEx.test(item?.name || "") ||
          mobRegEx.test(item?.mobile || "") ||
          empCodeRegEx.test(item.empCode || "")
      );
      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };
  // if (isLoading && !newlyAdded) {
  //   return <TableLoader />;
  // }

  if (error) {
    return <div>{error.outcome}</div>;
  }

  return (
    <>
      {pipe(
        filteredData,
        fold(
          () => [<NoTableData />],
          (employees) =>
            employees.length > 0
              ? [
                  <TablePagination
                    data={paging}
                    changePage={(data) =>
                      setPaging((p) => ({ ...p, page: data }))
                    }
                    changeLimit={(data) =>
                      setPaging((p) => ({ ...p, limit: data, page: 1 }))
                    }
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">E-mail</th>
                          <th scope="col">Department</th>
                          <th scope="col">Approver</th>
                          <th scope="col">Amount Limitations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblData(employees).map((item, key) => (
                          <tr
                            key={key}
                            // onClick={() => {
                            //   show();
                            //   setPopupData({
                            //     name: item.name,
                            //     email: item.email,
                            //     mobile: item.mobile,
                            //     businessUnit: item.businessUnit,
                            //   });
                            // }}
                          >
                            <td className="align-table-data-center">
                              {!item.empCode ? "-" : item.empCode}
                            </td>
                            <td className="align-table-data-center">
                              {item.name || "-"}{" "}
                            </td>
                            <td>{item.mobile || "-"}</td>
                            <td className="align-table-data-center">
                              {item.email || "-"}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.businessUnit || "-"}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {item.approvers?.map((obj, outerindex) =>
                                obj.approvesFor.map((data, index) => (
                                  <td
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      borderBottom:
                                        outerindex + index ===
                                        (item.approvers ?? []).length - 1
                                          ? "none"
                                          : "1px solid #ececec",
                                    }}
                                  >
                                    {obj.name}
                                    {/* {data.claimApproverScope === "BUSINESS_UNIT"
                                      ? `for the department of ${data.businessUnit}`
                                      : data.claimApproverScope === "ENTIRE_ORG"
                                      ? "for the entire organization"
                                      : "for specific employees"} */}
                                    {/* {index +
                                      " " +
                                      obj.approvesFor.length +
                                      " " +
                                      outerindex} */}
                                  </td>
                                ))
                              ) || "-"}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {item.approvers?.map((obj, outerindex) =>
                                obj.approvesFor.map((data, index) => (
                                  <td
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      borderBottom:
                                        outerindex + index ===
                                        (item.approvers ?? []).length - 1
                                          ? "none"
                                          : "1px solid #ececec",
                                    }}
                                  >
                                    {data.amount === 0
                                      ? "for all amounts"
                                      : `for amounts < Rs.${data.amount}`}
                                  </td>
                                ))
                              ) || "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TablePagination>,
                ]
              : [<NoTableData />]
        )
      )}
    </>
  );
};

export default EmployeesRolesTable;
