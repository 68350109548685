import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAAdata } from "../../../../state/slice/aa.slice";
import { selectProfile } from "../../../../state/slice/profile.slice";
import { loadAAdata } from "../../../../utils/account-aggregator.utils";
import DashboardPageLoader from "../common/dashboard-page-loader";
import AccountSelection from "./account-selection";
import "./acc-agg.scss";

const DashboardAAaccountSection: React.FC = () => {
  const profile = useSelector(selectProfile);
  const { aaLoading, aaOverview, aaAccount, aaTransactions } =
    useSelector(selectAAdata);

  useEffect(() => {
    const run = async () => {
      if (profile?.uid) {
        await loadAAdata("finduittest04@gmail.com");
      }
    };
    run();
  }, []);

  if (aaLoading) {
    return <DashboardPageLoader />;
  }
  return (
    <>
      <AccountSelection
        transactions={aaTransactions}
        aaOverview={aaOverview}
        aaAccount={aaAccount}
      />
    </>
  );
};

export default DashboardAAaccountSection;
