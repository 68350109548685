import React from "react";
import { Button } from "react-bootstrap";
import { TFxDashboardResponse } from "../../../../types/fx/fx-dashboard-response.type";
import FxOnlineBanner from "./fx-banner/fxbanner";
import FxChart from "./fx-chart";
import RealTimePrice from "./real-time-price/real-time-price";
import SelectedRealTimePrice from "./selected-real-time-price";
import SavingCalculator from "./saving-calculator/saving-calculator";
import CurrencyConvertor from "./currency-convertor/currency-convertor";

interface IFxMainProps {
  dashboard: TFxDashboardResponse;
  onStartNow: () => void;
}
const FxMain: React.FC<IFxMainProps> = ({ dashboard, onStartNow }) => {
  return (
    <>
      <div className="row gx-2 mb-2">
        <div className="col-12 col-md-5">
          <SavingCalculator dashboard={dashboard} />
        </div>
        <div className="col-12 col-md-7">
          <div className="row gx-2">
            <RealTimePrice />
          </div>
        </div>
      </div>
      <div className="row gx-2">
        <div className="col-12 col-md-5">
          <CurrencyConvertor currencies={dashboard.currencies} />
        </div>
        <div className="col-12 col-md-7">
          <div className="row gx-2">
            <div className="col-12 col-md-12">
              <div className="card shadow-sm mb-2 rounded-6_i border-0">
                <FxChart />
              </div>
              <div className="row gx-2">
                <SelectedRealTimePrice />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 my-3 text-end">
          <Button
            className="btn-gradiant-blue px-4 py-2 text-white"
            type="button"
            onClick={onStartNow}
          >
            Start Now
          </Button>
        </div>
      </div>
    </>
  );
};

export default FxMain;
