import { MakeOption } from "./make-option";

export enum DetaxTypeKind {
  FBA = "FBA",
  TA = "TA",
  NPS = "NPS",
  EA = "EA",
  GC = "GC",
}

interface test {
  name?: string;
  email?: string;
}

type rr = Required<test>;

type rTest = MakeOption<rr>;
