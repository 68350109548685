import { Option } from "fp-ts/lib/Option";
import { store } from "../../state/store";
import { TOAuthLoginResponse } from "../../types/oauth-login-response.type";
import {
  setDraftId,
  setLoginId,
  setCurrentScreen,
  loadUser,
  logout,
  endLoad,
  initializeApp,
  updatePageAfterLogin,
  numberOfVisitedPages,
  setIsAuthenticated,
  setLoginUserCid,
  setFullName,
  setPasswordChanged,
  setCommonError,
  setBadRequestError,
  TCommonError,
  setAuthInfo,
  TBadRequestError,
} from "./app.slice";

export const AppDispatch = {
  setDraftId: (payload: Option<string>) => store.dispatch(setDraftId(payload)),
  setFullName: (payload: Option<string>) =>
    store.dispatch(setFullName(payload)),
  setLoginId: (payload: Option<string>) => store.dispatch(setLoginId(payload)),
  setLoginUserCid: (payload: Option<string>) =>
    store.dispatch(setLoginUserCid(payload)),

  setPasswordChanged: (payload: Option<boolean>) =>
    store.dispatch(setPasswordChanged(payload)),

  setCommonError: (payload: TCommonError) =>
    store.dispatch(setCommonError(payload)),

    setBadRequestError: (payload: TBadRequestError) =>
    store.dispatch(setBadRequestError(payload)),

  setAuthInfo: (payload: TOAuthLoginResponse) =>
    store.dispatch(setAuthInfo(payload)),
};
