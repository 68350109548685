import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishCardApiService } from "../api/detax/api/ncaishCardApi.service";
import { NcaishDetaxSubscriptonApiService } from "../api/detax/api/ncaishDetaxSubscriptonApi.service";
import { NcaishTransactionApiService } from "../api/detax/api/ncaishTransactionApi.service";
import { CardTransactionsMonthlyCountResponse } from "../api/detax/model/cardTransactionsMonthlyCountResponse";
import { DetaxCardSubscriptionApiRequest } from "../api/detax/model/detaxCardSubscriptionApiRequest";
import { DetaxCardSubscriptionCountApiResponse } from "../api/detax/model/detaxCardSubscriptionCountApiResponse";
import { DetaxCardSubscriptionResponse } from "../api/detax/model/detaxCardSubscriptionResponse";
import { EmployeeListResponse } from "../api/detax/model/employeeListResponse";
import { FbaAllowanceResponse } from "../api/detax/model/fbaAllowanceResponse";
import { GetCardsApiResponse } from "../api/detax/model/getCardsApiResponse";
import { UpdateCardStatusApiRequest } from "../api/detax/model/updateCardStatusApiRequest";
import { ReplaceCardApiResponse } from "../api/detax/model/replaceCardApiResponse";
import { Search } from "react-router";
import { BaseResponse } from "../model/baseResponse";

export namespace DetaxService {
  export const getCardSubscriptionCount = async (status?: string) => {
    const statusString = status === "All" ? "" : status;
    const result =
      await NcaishDetaxSubscriptonApiService.getCardSubscriptionCount(
        statusString
      );
    return remoteData.map(
      result,
      (response: DetaxCardSubscriptionCountApiResponse) => response
    );
  };
  export const getSubscription = async (
    status?: string,
    pn?: number,
    rc?: number
  ) => {
    const statusString = status === "All" ? "" : status;
    const result = await NcaishDetaxSubscriptonApiService.getSubscription(
      statusString,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: DetaxCardSubscriptionResponse) => response
    );
  };

  export const getSubscriptionReport = async (
    status?: string,
    pn?: number,
    rc?: number
  ) => {
    const statusString = status === "All" ? "" : status;
    const result = await NcaishDetaxSubscriptonApiService.getSubscriptionReport(
      statusString,
      pn,
      rc
    );
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getSearchDetax = async (
    status?: string,
    keyword?: string,
    pn?: number,
    rc?: number
  ) => {
    const statusString = status === "All" ? "" : status;
    const result = await NcaishDetaxSubscriptonApiService.getSearchDetax(
      statusString,
      keyword,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: DetaxCardSubscriptionResponse) => response
    );
  };

  export const sendSubscriptionRequest = async (
    body: DetaxCardSubscriptionApiRequest
  ) => {
    const result =
      await NcaishDetaxSubscriptonApiService.sendSubscriptionRequest(body);
    return remoteData.map(
      result,
      (response: DetaxCardSubscriptionResponse) => response
    );
  };
  export const searchCardEligibleEmployee = async (keyword?: string) => {
    const result =
      await NcaishDetaxSubscriptonApiService.searchCardEligibleEmployee(
        keyword
      );
    return remoteData.map(result, (response: EmployeeListResponse) => response);
  };
  export const getCards = async (uid: string) => {
    const result = await NcaishCardApiService.getCards(uid);
    return remoteData.map(result, (response: GetCardsApiResponse) => response);
  };

  export const updateCardStatus = async (
    id: string,
    body: UpdateCardStatusApiRequest
  ) => {
    const result = await NcaishCardApiService.updateCardStatus(body, id);
    return remoteData.map(result, (response: GetCardsApiResponse) => response);
  };
  export const updateCardUnlockStatus = async (
    id: string,
    body: UpdateCardStatusApiRequest
  ) => {
    const result = await NcaishCardApiService.updateCardUnlockStatus(body, id);
    return remoteData.map(result, (response: GetCardsApiResponse) => response);
  };
  export const getCorporateEmployFbaAllowance = async (uid: string) => {
    const result = await NcaishCardApiService.getCorporateEmployFbaAllowance(
      uid
    );
    return remoteData.map(result, (response: FbaAllowanceResponse) => response);
  };
  export const getMonthlyCounts = async () => {
    const result = await NcaishTransactionApiService.getMonthlyCounts();
    return remoteData.map(
      result,
      (response: CardTransactionsMonthlyCountResponse) => response
    );
  };
  export const replaceCard = async (uid: string) => {
    const result = await NcaishCardApiService.replaceCard(uid);
    return remoteData.map(
      result,
      (response: ReplaceCardApiResponse) => response
    );
  };
}
