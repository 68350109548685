import { useEffect, useState } from "react";
import logoLoader from '../../../../output_Idbo5p.gif';

const DashboardPageLoader = () => {
  const [sidebarWidth, setSidebarWidth] = useState(
    localStorage.getItem("dashSideBarWidth") || 102
  );
  useEffect(() => {
    if (document) {
      let el = document.querySelector(
        ".dashboard-sidebar-container .primary-gradient"
      );
      if (el) {
        setSidebarWidth(el.clientWidth);
        localStorage.setItem("dashSideBarWidth", el.clientWidth.toString());
      }
    }
  });

  return (
    <div
      className="vh-100  d-flex justify-content-center align-items-center"
      style={{
        position: "fixed",
        zIndex: "99999999999",
        background: "#fff",
        top: "0",
        bottom: "0",
        right: "0",
        width: "calc(100% - " + sidebarWidth + "px)",
      }}
    >
      <div
        // className="spinner-border"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <img src={logoLoader} alt="Loading.." width="100" height="100" />
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default DashboardPageLoader;
