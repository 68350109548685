import { isFailure, isSuccess } from "@devexperts/remote-data-ts";

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { BackButton, Button, Heading, MediumScreen, SmallScreen } from "../..";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useSignUp } from "../../../hooks/use-signup";

import {
  selectSignUp,
  setSignupId,
  setSignupUser,
  setVerificationId,
} from "../../../state/slice/user.slice";
import { fromPublic } from "../../../utils/common.utils";
import { setErrorMessages } from "../../../utils/error.utils";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";
import { selectDraftId } from "../../app/app.slice";
import FormikComponent from "../../common/formik/formik-component/formik-component";
import Loader from "../../common/loader/loader";
import Signup from "../../form/signup";
import Stepper from "../../stepper/stepper";
import mixpanel from "mixpanel-browser";
import BrandLogoHeader from "./brandlogo-header";

const Image2 = fromPublic("/images/signup.svg");

interface Props {
  setFieldValue?: any;
}
interface IValues {
  name: string;
  email: string;
  mobile: string;
}
const formInitialSchema: IValues = {
  name: "",
  email: "",
  mobile: "",
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const mailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter your valid email address")
    .matches(mailRegExp, "Email is not valid"),
  mobile: yup
    .string()
    .required("Mobile No. is required")
    .min(10)
    .max(10)
    .matches(phoneRegExp, "Phone number is not valid"),
});

const SignUpDetailsPage: React.FC<Props> = ({ setFieldValue }) => {
  const navigate = useNavigate();
  const signUser = useAppSelector(selectSignUp);
  const draftId = useAppSelector(selectDraftId);

  const dispatch = useAppDispatch();
  const [error, setError] = useState<String>("");

  const { mutateAsync: signup, isLoading } = useSignUp();

  useEffect(() => {
    mixpanel.track("Page load", { name: "Signup" });
  }, []);

  const handleFormSubmit = async (values: IValues) => {
    const result = await signup({
      applicationId: draftId,
      name: values.name,
      email: values.email,
      mobile: values.mobile,
    });

    mixpanel.register(result);
    mixpanel.track("Button click", { name: "Signup", ...result });

    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        dispatch(setSignupId(unWrapOption(result.value.signupId, () => "")));
        dispatch(
          setVerificationId(unWrapOption(result.value.verificationId, () => ""))
        );
        const path = unWrapOption(result.value.nextAction, () => "/");
        navigate(path);
        dispatch(
          setSignupUser({
            name: values.name,
            email: values.email,
            mobile: values.mobile,
          })
        );
      } else {
        setError(unWrapOption(result.value.message, () => "Error in signup"));
      }
    } else if (isFailure(result)) {
      setError(setErrorMessages(result.error));
    }
  };

  return (
    <FormikComponent
      validation={validationSchema}
      initialValue={formInitialSchema}
      handleSubmit={handleFormSubmit}
    >
      <div className="container p-0">
        <BrandLogoHeader />
        {/* <Stepper /> */}
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image2}
                    alt="Mobile"
                  />
                </div>
                <div className="textDetail">
                  <Heading
                    className="mx-5 mb-3 mt-n55"
                    text="Onboarding Second Step"
                    variant="small"
                  />

                  <p className="mx-5 fw-bolder text-secondary">
                    We need your official credentials in order to proceed
                    forward.
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="p-3 border-0 shadow card bg-grey rounded-15">
                    {/* <BackButton text="User Details" path="" /> */}
                    {error && (
                      <div
                        className="text-center alert alert-danger fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <Heading
                      className="mt-5 text-center"
                      text="Sign Up"
                      variant="large"
                    />
                    <p className="mt-4 mb-0 mb-4 text-center text-black d-none d-sm-none d-md-block fw-bold">
                      Enter the following details to sign up.
                    </p>
                    <div className="text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                      <Signup setFieldValue={setFieldValue} />

                      <div className="mt-5 ml-auto mr-auto text-center ">
                        <Button type="submit">
                          {isLoading ? <Loader color="white" /> : <>Continue</>}
                        </Button>
                      </div>
                    </div>
                    <p className="mt-4 mb-4 text-center fw-bolder ">
                      Already have an account?
                      <Link className="ncash_nav-link" to={RoutePaths.Login}>
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-3 border-0 shadow card bg-grey">
                {/* <BackButton text="User Details" /> */}
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image2}
                    alt="BG"
                  />
                </div>

                <p className="mt-1 mb-0 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small">
                  Enter the following details to sign up.
                </p>

                <div className="p-2 mt-3 mb-4 card rounded-15 inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                  <Signup setFieldValue={setFieldValue} />
                </div>
                <div className="mb-4 ml-auto mr-auto text-center">
                  <Button type="submit">
                    {isLoading ? <Loader color="white" /> : <>Continue</>}
                  </Button>
                </div>

                <p className="mb-5 text-center fw-bolder ">
                  Already have an account?
                  <Link className="ncash_nav-link" to={RoutePaths.Login}>
                    Login
                  </Link>{" "}
                </p>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </FormikComponent>
  );
};

export default SignUpDetailsPage;
