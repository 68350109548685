import { Button } from "react-bootstrap";
import { FxService } from "../../../../../services/fx.service";
import { TFXCurrency } from "../../../../../types/fx/fx-calculator-data.type";
import { TCurrencyType } from "../../../../../types/fx/currency.type";

import {
  CurrencySelectField,
  CurrencySelectLabel,
  SwapCurrency,
  CurrencySelectAmount,
} from "../fx-exporter/fx-exporter";
import { isSuccess } from "@devexperts/remote-data-ts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import {
  selectConversionRequest,
  setConversionRequestData,
} from "../../../../../state/slice/fx.slice";
import { useState } from "react";
import { Option, none, fromNullable } from "fp-ts/lib/Option";
import { ConvertKind } from "../../../../../models/enum-convert-kind";
import { unWrapOption } from "../../../../../utils/option.utils";
import {
  AreConversionRequisitesReady,
  GetCountriesCode,
} from "../../../../../utils/fx.utils";

const CurrencyCoverter: React.FC<{ currencies: Array<TFXCurrency> }> = ({
  currencies,
}) => {
  const { from, to, amount, conversionAmount } = useAppSelector(
    selectConversionRequest
  );
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const updateDataToStore = async (
    kind: ConvertKind,
    value: number | string
  ) => {
    if (kind === ConvertKind.FROM) {
      dispatch(
        setConversionRequestData({
          from: value.toString(),
          to,
          amount,
          conversionAmount,
        })
      );
    } else if (kind === ConvertKind.TO) {
      dispatch(
        setConversionRequestData({
          from,
          to: value.toString(),
          amount,
          conversionAmount,
        })
      );
    } else if (kind === ConvertKind.AMOUNT) {
      dispatch(
        setConversionRequestData({
          from,
          to,
          amount: fromNullable(Number(value)),
          conversionAmount,
        })
      );
    } else if (kind === ConvertKind.CONVERTED_AMOUNT) {
      dispatch(
        setConversionRequestData({
          from,
          to,
          amount,
          conversionAmount: fromNullable(Number(value)),
        })
      );
    }
  };

  const handleSwapCurrency = () => {
    dispatch(
      setConversionRequestData({
        from: to,
        to: from,
        amount,
        conversionAmount,
      })
    );
  };

  const handleConvertCurrency = async () => {
    if (AreConversionRequisitesReady()) {
      setLoader(true);
      const request = {
        from: from as TCurrencyType,
        to: to as TCurrencyType,
        amount: unWrapOption(amount, () => 0),
      };

      const result = await FxService.convertCurrency(request);
      if (isSuccess(result)) {
        setLoader(false);
        updateDataToStore(
          ConvertKind.CONVERTED_AMOUNT,
          unWrapOption(result.value.amount, () => 0)
        );
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <div className="card pt-2 pb-2 ps-3 pe-3 rounded-10 bg-white border-0 shadow-sm h-100">
        <div className="card-title h4">Currency Convertor</div>
        <div className="row gx-3 align-items-end">
          <div className="col-md-5 col-lg">
            <CurrencySelectLabel label="From" />
            <CurrencySelectField
              currencies={GetCountriesCode(currencies)}
              selectedCurrency={from}
              onSelect={(value) => updateDataToStore(ConvertKind.FROM, value)}
              className="border-0 form-select form-select"
            />
          </div>
          <div className="w-auto">
            <SwapCurrency
              onSwap={handleSwapCurrency}
              className="mx-auto flag-wrap card p-2 d-flex align-items-center rounded-circle border-1 cursor-pointer"
            />
          </div>
          <div className="col-md-5 col-lg">
            <CurrencySelectLabel label="To" />
            <CurrencySelectField
              currencies={GetCountriesCode(
                currencies.filter((c) => c.code !== from)
              )}
              selectedCurrency={to}
              onSelect={(value) => updateDataToStore(ConvertKind.TO, value)}
              className="border-0 form-select form-select"
            />
          </div>
        </div>

        <div className="row my-2">
          <div className="col col-md">
            <CurrencySelectLabel label="Amount" />
            <CurrencySelectAmount
              amount={amount}
              onChange={(value) => updateDataToStore(ConvertKind.AMOUNT, value)}
            />
          </div>
          <div className="w-auto mx-3"></div>
          <div className="col col-md">
            <CurrencySelectLabel label="Amount" />
            <CurrencySelectAmount amount={conversionAmount} readOnly />
          </div>
        </div>
        <div className="row">
          <div className="col text-end my-1">
            <Button
              className="btn col-3 border-0 text-white primary-bg"
              onClick={handleConvertCurrency}
            >
              {loader ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              ) : (
                "Convert"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrencyCoverter;
