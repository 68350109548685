import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ClaimInfo } from "../../../../api/detax/model/claimInfo";
import { ClaimApprovalApiRequest } from "../../../../model/claimApprovalApiRequest";
import { ClaimsService } from "../../../../services/claims.service";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import DocumentModal from "../common/document-modal";
import Heading from "../common/heading";
import SuccessPopup from "../common/succes-popup";
import BillCard from "../detax/transaction/bill-card";
import TransactionTimeline from "../detax/transaction/transaction-timeline";
import ApprovedCard from "./approved-card";
import PendingCard from "./pending-card";
import DetailCard from "./detail-card";
import ApproverPendingCard from "./approver-pending";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const BuildIcon = "/images/icons/building.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const EmpCodeIcon = "/images/icons/emp_code.svg";

const LocationIcon = "/images/icons/location-sharp.svg";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const EmpStatusIcon = "/images/icons/emp_status.svg";

const DashboardClaimsDetails: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);

  const [text, setText] = useState("");
  const [amount, setAmount] = useState<string>("");
  const [modalType, setModalType] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [err, setErr] = useState({ show: false, message: "" });

  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [actionState, setActionState] = useState(false);
  const [docModal, setDocModal] = useState({ show: false, url: "" });
  const [details, setDetails] = useState<ClaimInfo>({
    claimId: "",
    claimCode: "",
    category: "",
    catImage: "",
    amount: 0,
    appAmount: 0,
    remarks: "",
    status: "PENDING",
    images: [],
    initiator: {
      name: "",
      email: "",
      mobile: "",
      address: "",
      kycStatus: true,
      role: "",
      status: "",
      activeFrom: "",
    },
  });

  useEffect(() => {
    const run = async () => {
      if (params?.id) {
        setIsLoading(true);
        const result = await ClaimsService.getClaimById(params?.id);
        if (isSuccess(result)) {
          if (result.value.successful) {
            setDetails((prev) => (prev = result.value.data || prev));
          }
        }
        setIsLoading(false);
      }
    };
    run();
  }, [params.id]);

  if (isLoading) {
    return <DashboardPageLoader />;
  }
  const updateClaim = async (status: boolean) => {
    if (params?.id) {
      setErr({ show: false, message: "" });
      let payload: ClaimApprovalApiRequest = {
        approved: status,
        amount: parseFloat(amount),
        remarks: text,
      };
      if (!modalType) delete payload.amount;
      let proceed = false;
      if (modalType && amount != "") proceed = true;
      else if (!modalType) proceed = true;
      if (proceed) {
        setActionState(true);
        const result = await ClaimsService.updateClaim(payload, params?.id);
        setActionState(false);
        if (isSuccess(result)) {
          if (result.value.successful) {
            setDetails((p) => result.value.data || p);
            handleClose();
            setText("");
            setAmount("");
            setShowSuccess(true);
          } else {
            // setErr({
            //   show: true,
            //   message: result.value.message || "Something went wrong!",
            // });
          }
        } else {
          //setErr({ show: true, message: "Something went wrong!" });
        }
      }
    }
  };
  const handleClose = () => {
    setActionState(false);
    setShow(false);
    setText("");
    setAmount("");
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <PageTitle title={`Claim Details | ${details.claimCode}`} />
      <Heading
        title="Claims"
        detail={details.claimCode}
        status={details.displayStatus}
        buttons={
          <>
            {details.allowApproval === false && details.status === "PENDING" ? (
              <div>
                <h5 className="text-primary">{details.systemMsg || ""}</h5>
                TxnId:
                <Link
                  className="text-secondary ncash_nav-link"
                  to={
                    RoutePaths.DashboardDetaxTransactionDetail +
                    "/" +
                    details.txnRefId +
                    "/" +
                    details.claimId
                  }
                >
                  {details.txnRefId}
                </Link>{" "}
              </div>
            ) : null}
            {/* {details.allowApproval === true ? ( */}
            {details.status === "REVIEW_PENDING" ? (
              <>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    handleShow();
                    setModalType(true);
                  }}
                >
                  Accept
                </button>
                <button
                  className="btn btn-secondary ms-2 btn-danger"
                  onClick={() => {
                    handleShow();
                    setModalType(false);
                  }}
                >
                  Reject
                </button>
              </>
            ) : null}
          </>
        }
        links={[RoutePaths.DashboardClaims]}
      />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          className={
            modalType
              ? "bg-success d-flex flex-column justify-content-center"
              : "bg-danger d-flex flex-column justify-content-center"
          }
        >
          <div className="text-center  text-light fs-10"> &#9888;</div>
          <Modal.Title className="text-light">Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={classNames("text-light  text-center", {
            "ncaish-bg-danger": !modalType,
            "bg-success": modalType,
          })}
        >
          <p className="modal-title text-white">
            Are you sure to {modalType ? "Approve" : "Reject"}?
          </p>
          {modalType && (
            <div className="form-group">
              <input
                required
                value={amount}
                onChange={(e) => setAmount(e.target.value.toString())}
                type="number"
                className="form-control mt-2"
                placeholder="Enter amount"
              />
            </div>
          )}
          <div className="form-group">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={2}
              className="form-control mt-2"
              placeholder="Enter remark"
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center flex-column bg-white">
          {err.show && (
            <div
              className="text-center text-danger"
              style={{ fontSize: "14px" }}
            >
              {err.message}
            </div>
          )}
          <div className="d-flex justify-content-center">
            <button
              disabled={actionState}
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              disabled={
                (modalType ? actionState : text.length === 0) ||
                actionState ||
                (modalType
                  ? parseFloat(amount) > (details?.amount ?? 0) ||
                    amount.length === 0
                  : false)
              }
              className={`btn btn-${modalType ? "success" : "danger"}`}
              onClick={() => updateClaim(modalType)}
            >
              {actionState && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
              )}
              {modalType ? "Approve" : "Reject"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <WhiteCard className="p-3 mt-3">
        <div className="row">
          <div className="col-11">
            <div className="row">
              {details.initiator?.name && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={UserIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        Employee Name
                      </p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.name}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
              {details.initiator?.mobile && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={PhoneIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted"> Mobile No</p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.mobile}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
              {details.initiator?.email && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={EmailIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">Email</p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.email}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/*
              {details.initiator?.address && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={LocationIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">Address</p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.address}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            
            <div className="row mt-3">
              <div className="col-md-3">
                <div className="row">
                  <div className="col-2 col-md-2 text-right">
                    <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                      <img
                        src={EmpCodeIcon}
                        className="img-fluid ml-auto mr-auto w-60"
                        alt="icon"
                      />
                    </div>
                  </div>
                  <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                    <p className="mb-0 ncash_fs-12 text-muted">Aadhar Status</p>
                    <h5 className="fs-small  mb-0">
                      {details.initiator?.kycStatus
                        ? "Verified"
                        : "Not Verified"}
                    </h5>
                  </div>
                </div>
              </div>
              {details.initiator?.role && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={EmpDesgIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        Employee Designation
                      </p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.role}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
              {details.initiator?.status && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={EmpStatusIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">
                        Employee Status
                      </p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.status}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
              {details.initiator?.activeFrom && (
                <div className=" col-md-3">
                  <div className="row">
                    <div className="col-2 col-md-2 text-right">
                      <div className="bg-blue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                        <img
                          src={CalendarIcon}
                          className="img-fluid ml-auto mr-auto w-60"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                      <p className="mb-0 ncash_fs-12 text-muted">Active From</p>
                      <h5 className="fs-small  mb-0">
                        {details.initiator?.activeFrom}{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
              */}
          </div>
          <div className="col-1 d-flex justify-content-end"></div>
        </div>
      </WhiteCard>
      <WhiteCard id="ExpenseDetails">
        <div className="d-flex justify-content-end">
          <div className="text-secondary">
            {details.initiatedAt} <span className="mx-2"> | </span>{" "}
            <span className="text-danger">{details.displayStatus} </span>
          </div>
        </div>
        <div className="card border-light p-2">
          <div className="row p-2">
            <div className="col-9">
              <div className="row">
                <div className="col-5">
                  <DetailCard claim={details} />
                </div>
                <div className="col-7">
                  <BillCard bills={details.images || []} />
                </div>
              </div>
              <div className="row">
                {/* display here approved and pending cards */}
                <div className="col">
                  {details.status === ClaimInfo.StatusEnum.PENDING ? (
                    <PendingCard />
                  ) : details.status ===
                    ClaimInfo.StatusEnum.APPROVER_PENDING ? (
                    <ApproverPendingCard />
                  ) : (
                    <ApprovedCard claim={details} />
                  )}
                </div>
              </div>
            </div>
            <div className="col-3">
              {" "}
              <TransactionTimeline stages={details.timeline?.stages} />
            </div>
          </div>
        </div>
      </WhiteCard>
      <SuccessPopup
        message={`${modalType ? "Approved" : "Rejected"} successfully!`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
    </>
  );
};

export default DashboardClaimsDetails;
