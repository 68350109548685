import { MembershipTabKind } from "../../../../models/membership-tab-kind";

export type MTab = { tabLabel: string; tabValue: MembershipTabKind };

export const tabData: MTab[] = [
  {
    tabLabel: "Invite Employees",
    tabValue: MembershipTabKind.INVITEEMPLOYEES,
  },
  { tabLabel: "Membership Batch", tabValue: MembershipTabKind.MEMBERSHIPBATCH },
];
