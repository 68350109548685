import { pipe } from "fp-ts/lib/function";
import { getOrElse, map, toNullable } from "fp-ts/lib/Option";
import React from "react";
import { Accordion } from "react-bootstrap";
import { useAppSelector } from "../../hooks/hooks";
import { fromPublic } from "../../utils/common.utils";
import { selectCompanyInfo } from "./company.slice";

const Building = fromPublic("/images/icons/building.svg");
const Image1 = fromPublic("/images/bg-1.png");
const User = fromPublic("/images/icons/user.svg");
const Group = fromPublic("/images/icons/group-1.svg");

const DirectorSignatoryDetailsCard = () => {
  const companyInfoOpt = useAppSelector(selectCompanyInfo);

  return pipe(
    companyInfoOpt,
    map(({ directors }) => (
      <div className="row  mt-2 align-items-center ">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="d-inline-block me-3">
                <img
                  className="img-fluid ncash_bg-lightblue "
                  src={Group}
                  alt="group"
                  style={{
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              </span>

              <span className="fw-bold fs-6">Director/Signatory Details</span>
            </Accordion.Header>

            <Accordion.Body className="bg-white rounded-15 shadow-sm mt-2">
              <div>
                {directors.map((director, index) => (
                  <div className="row" key={index}>
                    <div className="col-2">
                      <div className=" rounded-15  mt-1">
                        <img
                          className="img-fluid ncash_bg-lightpink w-100 "
                          src={User}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="col-10">
                      <h6 className="text-start fw-bold mt-2">
                        {pipe(
                          director.name,
                          getOrElse(() => "")
                        )}
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    )),
    toNullable
  );
};

export default DirectorSignatoryDetailsCard;
