/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InitiateDetaxBatchApiRequest {
  amount?: number;
  mode?: InitiateDetaxBatchApiRequest.ModeEnum;
  time?: string;
  refId?: string;
}
export namespace InitiateDetaxBatchApiRequest {
  export type ModeEnum = "NEFT" | "IMPS" | "RTGS";
  export const ModeEnum = {
    NEFT: "NEFT" as ModeEnum,
    IMPS: "IMPS" as ModeEnum,
    RTGS: "RTGS" as ModeEnum,
  };
}
