import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, store } from "../../state/store";

export interface AlphaState {
  display: boolean;
  newClaims: boolean;
  newClaimsEmployee: boolean;
  nps: boolean;
  giftCard: boolean;
  activeSettings: string;
  enableBatchExcel: boolean;
  manualTxn: boolean;
}

export const initialAlphaState: AlphaState = {
  display: false,
  newClaims: false,
  newClaimsEmployee: false,
  nps: false,
  giftCard: false,
  activeSettings: "",
  enableBatchExcel: false,
  manualTxn: false,
};

const alphaSlice = createSlice({
  name: "alpha",
  initialState: initialAlphaState,
  reducers: {
    initializeAlpha: () => initialAlphaState,

    setDisplayStatus: (state, { payload: status }: PayloadAction<boolean>) => {
      state.display = status;
    },
    setNewClaimsStatus: (
      state,
      { payload: status }: PayloadAction<boolean>
    ) => {
      state.newClaims = status;
    },
    setNewClaimsEmployee: (
      state,
      { payload: status }: PayloadAction<boolean>
    ) => {
      state.newClaimsEmployee = status;
    },
    setNpsStatus: (state, { payload: status }: PayloadAction<boolean>) => {
      state.nps = status;
    },
    setGiftCardStatus: (state, { payload: status }: PayloadAction<boolean>) => {
      state.giftCard = status;
    },
    setActiveSettings: (state, { payload: status }: PayloadAction<string>) => {
      state.activeSettings = status;
    },
    setEnableBatchExcel: (
      state,
      { payload: status }: PayloadAction<boolean>
    ) => {
      state.enableBatchExcel = status;
    },
    setManualTxn: (state, { payload: status }: PayloadAction<boolean>) => {
      state.manualTxn = status;
    },
  },
});

export namespace AlphaDispatch {
  export const setDisplayStatus = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setDisplayStatus(payload));
  export const setNewClaimsStatus = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setNewClaimsStatus(payload));
  export const setNewClaimsEmployee = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setNewClaimsEmployee(payload));
  export const setNpsStatus = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setNpsStatus(payload));
  export const setGiftCardStatus = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setGiftCardStatus(payload));
  export const setActiveSettings = (payload: string) =>
    store.dispatch(alphaSlice.actions.setActiveSettings(payload));
  export const setEnableBatchExcel = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setEnableBatchExcel(payload));
  export const setManualTxn = (payload: boolean) =>
    store.dispatch(alphaSlice.actions.setManualTxn(payload));
}

export namespace AlphaReduxStore {
  export const selectDisplayStatus = (state: State) => state.alpha.display;
  export const selectNewClaimsStatus = (state: State) => state.alpha.newClaims;
  export const selectNewClaimsEmployee = (state: State) =>
    state.alpha.newClaimsEmployee;
  export const selectNpsStatus = (state: State) => state.alpha.nps;
  export const selectGiftCardStatus = (state: State) => state.alpha.giftCard;
  export const selectActiveSettings = (state: State) =>
    state.alpha.activeSettings;
  export const selectEnableBatchExcel = (state: State) =>
    state.alpha.enableBatchExcel;
  export const selectManualTxn = (state: State) => state.alpha.manualTxn;
}

export default alphaSlice.reducer;
