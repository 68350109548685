import { Form, Formik } from "formik";
import React from "react";

interface Props {
  validation: any;
  initialValue: any;
  handleSubmit: any;
  children: any;
}

const FormikComponent: React.FC<Props> = ({
  children,
  validation,
  initialValue,
  handleSubmit,
  ...otherProps
}) => {
  // const formInitialSchema = {
  //   name: "",
  //   email: "",
  //   mobile: "",
  //   companyname: "",
  // };

  // const formValidationSchema = yup.object().shape({
  //   name: yup.string().required("Name is required"),
  //   email: yup
  //     .string()
  //     .required("Email is required")
  //     .email("Enter valid email"),
  //   mobile: yup.string().required("Please Enter your number").min(10).max(10),
  //   companyname: yup.string().required("Enter valid company Name / CIN"),
  // });

  return (
    <div>
      <Formik
        initialValues={initialValue}
        validationSchema={validation}
        onSubmit={(values) => handleSubmit(values)}
        {...otherProps}
      >
        <Form {...otherProps}>
          {children}
          {/* {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            setFieldValue
          });
        })
          } */}
        </Form>
      </Formik>
    </div>
  );
};

export default FormikComponent;
