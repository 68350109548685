import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch } from "../../hooks/hooks";
import { useLogin } from "../../hooks/use-login";

import { Dispatch } from "../../state/dispatch";
import { setLoginUser } from "../../state/slice/user.slice";
import { fromPublic } from "../../utils/common.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";

import FormikComponent from "../common/formik/formik-component/formik-component";

import SpinnerComponent from "../common/loader/spinner";
import LoginWithMobile from "../form/login";
import mixpanel from "mixpanel-browser";

const Image3 = fromPublic("/images/bg1-new2.svg");

interface IValues {
  mobile: string;
}

const formInitialSchema: IValues = {
  mobile: "",
};
const validationSchema = yup.object().shape({
  mobile: yup.string().required("Please Enter your number").min(10).max(10),
});

// while testing use this Number: 8860618924

const LoginDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");

  useEffect(() => {
    mixpanel.track("Page load", { name: "Login using password" });
  }, []);

  const { mutateAsync: login, isLoading } = useLogin();
  const handleFormSubmit = async (values: IValues) => {
    setError("");

    const result = await login(values.mobile);

    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        try {
          Dispatch.App.setLoginId(result.value.loginId);
        } catch (error) {
          console.log("login error: ", error);
        }
        dispatch(setLoginUser({ mobile: values.mobile }));
        const path = pipe(
          result.value.nextScreen,
          getOrElse(() => "/")
        );
        navigate(path);
      } else {
        setError(unWrapOption(result.value.message, () => "Login Failed"));
      }
    } else if (isFailure(result)) {
      setError("Login Failed");
    }
  };

  return (
    <>
      <Helmet>
        <title>Ncaish</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="row align-items-center vh-100">
              <div className="col-lg-8 col-md-6 ">
                <MediumScreen>
                  <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="Mobile"
                    />
                  </div>
                  <div className="mt-5 textDetail">
                    <Heading
                      className="mb-3 mt-85"
                      text="Login"
                      variant="small"
                    />

                    <p className="fw-bolder text-secondary">
                      Being a Fintech we understand the importance of Multi
                      Factor authentication prescribed by Reserve Bank of India
                      !
                    </p>
                  </div>
                </MediumScreen>
              </div>
              <div className="mt-4 col-lg-4 col-md-6">
                <MediumScreen>
                  <div className="container p-0 m-0">
                    <div className="p-3 border-0 shadow card bg-grey rounded-15">
                      {error && (
                        <div
                          className="text-center alert alert-danger fw-bold"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      <Heading
                        className="text-center mt-120"
                        text="Log In"
                        variant="large"
                      />
                      <p className="mt-4 mb-0 mb-4 text-center text-black d-none d-sm-none d-md-block fw-bold">
                        Please enter your mobile number
                      </p>
                      <div className="text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                        <LoginWithMobile />
                        <div className="mt-5 mb-5 ml-auto mr-auto text-center">
                          <Button type="submit">
                            {isLoading ? <SpinnerComponent /> : <>Continue</>}
                          </Button>
                        </div>
                      </div>

                      <p className="mt-1 mb-5 text-center fw-bolder text-secondary">
                        Not having an account!
                        <Link
                          className="ncash_nav-link"
                          to={RoutePaths.ValidateCompany}
                        >
                          Sign Up
                        </Link>{" "}
                        now
                      </p>
                    </div>
                  </div>
                </MediumScreen>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="p-3 border-0 shadow card bg-grey">
                  {error && (
                    <div
                      className="text-center alert alert-danger fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="BG"
                    />
                  </div>

                  <h1 className="mt-3 mb-4 text-center fw-bold heading fs-5">
                    Log In
                  </h1>
                  <p className="mb-0 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small">
                    Please enter your mobile number
                  </p>

                  <div className="p-3 mt-4 mb-4 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                    <LoginWithMobile />
                  </div>
                  <div className="mb-5 ml-auto mr-auto text-center">
                    <Button type="submit">
                      {isLoading ? <SpinnerComponent /> : <>Continue</>}
                    </Button>
                  </div>

                  <p className="mt-1 text-center fw-bolder text-secondary ">
                    Not having an account!
                    <Link className="ncash_nav-link" to={RoutePaths.SignUpNew}>
                      Sign Up
                    </Link>{" "}
                    now
                  </p>
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

export default LoginDetailsPage;
