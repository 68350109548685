import { isSuccess } from "@devexperts/remote-data-ts";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddEmployeeApiRequest } from "../../../../api/employee/model/addEmployeeApiRequest";
import { UserStatusCountInfo } from "../../../../api/employee/model/userStatusCountInfo";
import { EmployeeInfo } from "../../../../model/employeeInfo";
import { Pagination } from "../../../../model/pagination";
import { EmployeeService } from "../../../../services/employee.service";
import {
  selectEmployeeMessage,
  setEmployeeMessage,
} from "../../../../state/slice/dashboard.slice";
import { fixNumberInput, fromPublic } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import SaveEmployeeForm from "../../../modal/employees/EmployeeModal";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import ErrorPopup from "../common/error-popup";
import Heading from "../common/heading";
import SuccessPopup from "./../common/succes-popup";
import { IEmployeeModal } from "./employee-modal-types";
import EmployeesExport from "./employees-export";
import EmployeesStatusCount from "./employees-status-count";
import EmployeesTable from "./employees-table";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";

const AddSingleIcon = fromPublic("/images/icons/usericon.svg");
const MultiUserIcon = fromPublic("/images/icons/multiuser.svg");

const inputDate = (dt: string) => {
  let arr = dt.split("-");
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
};

const EmployeesAdd: React.FC = () => {
  const dispatch = useDispatch();
  const employeeMessage = useSelector(selectEmployeeMessage);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<IEmployeeModal["data"]>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      code: "",
      doj: format(new Date(), "yyyy-MM-dd"),
      dob: format(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "yyyy-MM-dd"
      ),
      fname: "",
      lname: "",
      mobile: "",
      membershipType: "",
      email: "",
      status: "ACTIVE",
      salaryInfo: {
        mode: "MONTHLY",
        salary: 0,
        basicSalary: 0,
        allowance: 0,
      },
      address: {
        address: "",
        country: "India",
        state: "",
        city: "",
        pinCode: "",
      },
      gender: "MALE",
      businessUnit: "",
      taxRegime: "",
    },
  });
  const [successRes, setSuccessRes] = useState<{
    show: boolean;
    message: string;
  }>({ show: false, message: "" });
  const [err, setErr] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  });
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  const [employees, setEmployees] = useState<EmployeeInfo[] | []>([]);
  const [data, setData] = useState<EmployeeInfo[] | []>([]);
  const [newlyAdded, setNewlyAdded] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [getSearch, setGetSearch] = useState(false);
  const [department, setDepartment] = useState<BusinessUnit>(); //Select business unit Api res
  const [busiUnit, setBusiUnit] = useState("");
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    limit: 100,
  });
  const [reload, setReload] = useState(true);
  const [actionState, setActionState] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);

  useEffect(() => {
    setReload(true);
    setNewlyAdded(true);
    const run = async () => {
      const result = await EmployeeService.getBusinessUnit();
      if (isSuccess(result)) {
        if (result.value.successful) {
          console.log("test", result.value.data);
          setDepartment((prev) => (prev = result.value.data || prev));
          // result.value.data && loadEmployee(result.value.data);
        }
      }
    };
    run();
  }, [employees]);

  const closeModal = () => {
    clearErrors();
    reset();
    setValue("doj", format(new Date(), "yyyy-MM-dd"));
    setValue(
      "dob",
      format(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "yyyy-MM-dd"
      )
    );
    setDetailsModal(false);
    setActionState(false);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    console.log("Testttt", data);
    data = {
      ...data,
      membershipType: "detax",
      doj: format(new Date(data.doj), "dd-MM-yyyy"),
      dob: format(new Date(data.dob), "dd-MM-yyyy"),
      name: data.fname + " " + data.lname,
      firstName: data.fname,
      lastName: data.lname,
      businessUnit: busiUnit,
      taxRegime: data.taxRegime,
    };
    delete data.fname;
    delete data.lname;
    setActionState(true);
    setErr({ show: false, message: "" });
    const result = await EmployeeService.addEmployee(data);
    console.log("employee result: ", result);
    setActionState(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        dispatch(
          setEmployeeMessage(
            pipe(
              result.value.message,
              getOrElse(() => "")
            )
          )
        );
        setEmployees((prev) => [result.value.data, ...prev]);
        closeModal();
      } else {
        setErr({
          show: true,
          message: pipe(
            result.value.message,
            getOrElse(() => "")
          ),
        });
      }
    }
  };

  const openAddModal = () => {
    setDetailsModal(true);
    setValue("code", "");
    setValue("doj", "");
    setValue("dob", "");
    setValue("fname", "");
    setValue("lname", "");
    setValue("mobile", "");
    setValue("email", "");
    setValue("status", "ACTIVE");
    setValue("salaryInfo.mode", "MONTHLY");
    setValue("salaryInfo.salary", 0);
    setValue("salaryInfo.basicSalary", 0);
    setValue("salaryInfo.allowance", 0);
    setValue("address.address", "");
    setValue("address.state", "");
    setValue("address.city", "");
    setValue("address.country", "India");
    setValue("address.pinCode", "");
    setValue("gender", "MALE");
    setValue("membershipType", "");
    setValue("businessUnit", "");
    setValue("taxRegime", "OLD_REGIME");
  };

  useEffect(() => fixNumberInput(), [watch()]);

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <PageTitle title="Employees" />
      <Heading title="Employees" />
      <WhiteCard className="my-4 p-1 rounded">
        <div className="d-flex justify-content-between">
          <CustomSearchBar
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="70%"
            text="Search by Employee Name / Email / Mobile"
            search={getSearch}
            setSearch={(val: boolean) => setGetSearch(val)}
          />
          <div className="d-flex align-items-center ">
            {curTbl && (
              <Button
                className="btn-gradiant-blue btn-lg text-white fs-small me-2"
                onClick={() => setGetSearch(!getSearch)}
              >
                Search
              </Button>
            )}
            {/* {curTbl && <EmployeesExport curTbl={curTbl} />} */}
            <DropdownButton title="Add Employee" id="btn-gradient">
              <div
                onClick={() => openAddModal()}
                className="fw-bold fs-xsmall dropdown-item"
              >
                <img
                  src={AddSingleIcon}
                  className="img-fluid px-2"
                  alt="icon"
                />
                Add Single
              </div>

              <Dropdown.Item>
                <Link
                  className=" ncaish-link fw-bold text-black fs-xsmall"
                  to={RoutePaths.DashboardEmployees}
                >
                  <img
                    src={MultiUserIcon}
                    className="img-fluid px-2"
                    alt="icon"
                  />
                  Add Multiple
                </Link>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </WhiteCard>

      <PageData
        setTbl={setCurTbl}
        searchVal={searchVal}
        newlyAdded={newlyAdded}
        setNewlyAdded={() => setNewlyAdded(false)}
        reload={reload}
        setReload={() => setReload(false)}
        getSearch={getSearch}
        setGetSearch={(val: boolean) => setGetSearch(val)}
      />

      {detailsModal && (
        <SaveEmployeeForm
          title="Add New Employee"
          department={department?.businessUnit}
          busiUnit={busiUnit}
          setBusiUnit={(val: string) => setBusiUnit(val)}
          // modal={modal}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          actionState={actionState}
          isEditable={false}
        />
      )}
      {employeeMessage != "" && (
        <SuccessPopup
          message={employeeMessage}
          show={true}
          onClose={(flag) => dispatch(setEmployeeMessage(""))}
        />
      )}
      {/*
          MERGE_CONFLICT
          message="Employee added successfully"
             */}
      <ErrorPopup
        message={err.message}
        show={err.show}
        onClose={(flag) => {
          setErr({ show: false, message: "" });
          setShowError(flag);
        }}
      />
    </>
  );
};

export default EmployeesAdd;

const PageData: React.FC<{
  searchVal?: string;
  reload?: boolean;
  newlyAdded?: boolean;
  setNewlyAdded: (flag: boolean) => void;
  setReload: (flag: boolean) => void;
  setTbl: (tbl: TStatusCountInfo) => void;
  getSearch?: boolean;
  setGetSearch: (data: boolean) => any;
}> = ({
  searchVal = "",
  reload = false,
  newlyAdded = false,
  setNewlyAdded = () => {},
  setReload = () => {},
  setTbl = () => {},
  getSearch,
  setGetSearch,
}) => {
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  const [data, setData] = useState<EmployeeInfo[] | []>([]);

  return (
    <>
      <EmployeesStatusCount
        reload={reload}
        setReload={() => {
          setReload(false);
          setNewlyAdded(false);
        }}
        setCurTbl={(d) => {
          setCurTbl(d);
          setTbl(d);
        }}
        curTbl={curTbl}
        newlyAdded={newlyAdded}
      />
      {curTbl && (
        <EmployeesTable
          searchVal={searchVal}
          curTbl={curTbl}
          reload={reload}
          setReload={() => {
            setReload(false);
            setNewlyAdded(false);
          }}
          setData={(d: any) => setData(d)}
          newlyAdded={newlyAdded}
          getSearch={getSearch}
          setGetSearch={(val: boolean) => setGetSearch(val)}
        />
      )}
    </>
  );
};
