import {
  failure,
  RemoteData,
  success,
} from "@devexperts/remote-data-ts/lib/remote-data";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshOptions,
} from "axios-auth-refresh";
import { Dispatch } from "../state/dispatch";
import {
  GetAuthorizationHeader,
  GetTokenFromLocalStorage,
  refreshOAuthTokenLogic,
} from "../utils/auth.utils";
import {
  APIError,
  ApiStatus,
  ApplicationErrorResult,
  HttpStatus,
  NetworkFailResult,
  ServiceCallOutcome,
  WebServerErrorResult,
} from "./errors";

export enum RequestMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

export type TApiClient = {
  readonly RequestMethod: typeof RequestMethod;
  readonly error: APIError;
  readonly request: <R = never>(
    request: AxiosRequestConfig
  ) => Promise<RemoteData<APIError, R>>;
};

export const createApiClient = (
  baseHref: string,
  extraHeaders?: object
): TApiClient => {
  const request = async <Response = never>(
    requestConfig: AxiosRequestConfig
  ): Promise<RemoteData<APIError, Response>> => {
    //console.log(`${baseHref} -- ${requestConfig.url}`);
    const headers = requestConfig.url;
    const ignoreUrl = requestConfig.url || "";

    let config: AxiosRequestConfig = {
      baseURL: baseHref,
      ...requestConfig,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        ...extraHeaders,
        ...requestConfig.headers,
      },
    };

    // if token is available, add it to the headers except "oauth/login" url
    if (
      ignoreUrl.indexOf("/login") === -1 &&
      ignoreUrl.indexOf("/validate") === -1
    ) {
      const token = GetTokenFromLocalStorage();
      if (token.accessToken) {
        config = await GetAuthorizationHeader(config);
      }
    }

    return axios
      .request(config)
      .then((res) => success(res.data))
      .catch((error) => {
        //console.log(`axios error:`, error);
        return failure(FailedRequestError(error));
      });
  };

  return {
    RequestMethod,
    error: {
      isSuccess: false,
      outcome: ServiceCallOutcome.NoResponseFromServer,
      isTimeout: false,
    },
    request,
  };
};

export function FailedRequestError(ex: unknown): APIError {
  const error = ex as AxiosError;

  // no response: network error
  if (!error.response) {
    const networkFail: NetworkFailResult = {
      isSuccess: false,
      outcome: ServiceCallOutcome.NoResponseFromServer,
      isTimeout: error.code === "ECONNABORTED", // from axios documentation
    };
    return networkFail;
  }

  // otherwise, we will at least get an http status
  const http: HttpStatus = {
    Code: error.response.status,
    Text: error.response.statusText,
    Data: error.response.data,
  };

  // if the status is not success, we will have an api response
  const errorData = error.response.data;
  const tryAsApiError = errorData as { message: string; successful: boolean };

  if (tryAsApiError.successful === false) {
    const api: ApiStatus = {
      Message: tryAsApiError.message,
    };

    const applicationError: ApplicationErrorResult = {
      isSuccess: false,
      outcome: ServiceCallOutcome.ApplicationError,
      httpStatus: http,
      apiStatus: api,
    };

    if (http.Code === 400) {
      //console.log("bad request: ", api)

      const errs: string[] = http.Data ? http.Data.errors : [];
      Dispatch.App.setBadRequestError({ showError: true, errorText: errs });
      //toast.error(errs.join("   "));
      //const toastId = toast("Validation Failed");
      //() => toast(<ToastError s />, options);
      // toast.update(toastId, {
      //   type: toast.TYPE.INFO,
      //   render: <ToastError>
      // });
    }

    return applicationError;
  }

  // generic http error from the web server
  const webServerError: WebServerErrorResult = {
    isSuccess: false,
    outcome: ServiceCallOutcome.WebServerError,
    httpStatus: http,
  };

  return webServerError;
}

// refresh token interceptor ..
const options: AxiosAuthRefreshOptions = {
  pauseInstanceWhileRefreshing: true,
};

createAuthRefreshInterceptor(axios, refreshOAuthTokenLogic, options);

const configHeaderObject = {
  "client-id": `${process.env.REACT_APP_CLIENT_ID}`,
  "client-secret": `${process.env.REACT_APP_CLIENT_SECRET}`,
  Authorization: `Basic ${process.env.REACT_APP_CLIENT}`,
};
// const baseClient = createApiClient("http://ncaishapi.rocketflyer.in");

const baseClient = createApiClient(
  `${process.env.REACT_APP_API_PATH}`,
  configHeaderObject
);

export const ApiClient = baseClient;

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PATH}`,
  headers: configHeaderObject,
});
