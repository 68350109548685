import { isSuccess } from "@devexperts/remote-data-ts";
import { boolean } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { fold, some } from "fp-ts/lib/Option";
import { COUNTRIES } from "../config/countries.data";
import { ICurrencyData } from "../models/currency-data";

import { FxService } from "../services/fx.service";
import { endFxDataLoad, setFxDashboardData } from "../state/slice/fx.slice";
import { store } from "../state/store";
import {
  TFxCalculatorData,
  TFXCurrency,
} from "../types/fx/fx-calculator-data.type";
import { unWrapOption } from "./option.utils";

export async function loadFxDashboardData() {
  const result = await FxService.getDashboardData();
  if (isSuccess(result)) {
    store.dispatch(setFxDashboardData(result.value));
    store.dispatch(endFxDataLoad());
  } else {
    store.dispatch(endFxDataLoad());
  }
}

export function AreConversionRequisitesReady(): boolean {
  const {
    conversionRequest: { amount, from, to, conversionAmount },
  } = store.getState().fx;

  const isValidAmount = pipe(
    amount,
    fold(
      () => false,
      (v) => !!v
    )
  );

  //if (isValidAmount) return false;

  if (from === to) return false;
  if (from === "") return false;
  if (to === "") return false;

  if (unWrapOption(amount, () => 0) === 0) return false;

  if (unWrapOption(amount, () => 0) < 0) return false;
  return true;
}

export function AreSavingRequisitesReady(): boolean {
  const {
    savingRequest: { amount, fromCurrency, toCurrency },
  } = store.getState().fx;

  if (fromCurrency === toCurrency) return false;
  if (fromCurrency === "") return false;
  if (toCurrency === "") return false;

  if (unWrapOption(amount, () => 0) === 0) return false;

  if (unWrapOption(amount, () => 0) < 0) return false;

  return true;
}

export function GetFromAndToCurrencyByRateType(
  savingCalData: TFxCalculatorData,
  rateType: string
): { from: ICurrencyData[]; to: ICurrencyData[] } | undefined {
  if (savingCalData && savingCalData.rateCurrency) {
    const rateCurrency = savingCalData.rateCurrency[rateType.toString()];

    // const fromKeys = rateCurrency.from
    //   ? rateCurrency.from
    //   : { code: "", title: "" };

    // const fromCountry = COUNTRIES.find((c) => c.id === fromKeys.code) || {
    //   id: "",
    //   name: "",
    //   flag: "",
    //   alpha2: "",
    //   alpha3: "",
    //   ioc: "",
    // };

    const fromKeys = rateCurrency.from ? rateCurrency.from : [];
    const fromCountries = COUNTRIES.filter((c) =>
      fromKeys.map((s) => s.code?.toString() || "").includes(c.id)
    ).map((c) => ({ code: c.id, name: c.name, flag: c.flag }));

    const toKeys = rateCurrency.to ? rateCurrency.to : [];

    const toCountries = COUNTRIES.filter((c) =>
      toKeys.map((s) => s.code?.toString() || "").includes(c.id)
    ).map((c) => ({ code: c.id, name: c.name, flag: c.flag }));

    return {
      from: fromKeys.map((t) => ({
        code: t.code?.toString() || "",
        name: t.title || "",
        flag: fromCountries.find((c) => c.code === t.code)?.flag || "",
      })),
      to: toKeys.map((t) => ({
        code: t.code?.toString() || "",
        name: t.title || "",
        flag: toCountries.find((c) => c.code === t.code)?.flag || "",
      })),
    };
  }
}

export function GetCountriesCode(
  currencies: Array<TFXCurrency>
): ICurrencyData[] {
  const countries = COUNTRIES.filter((c) =>
    currencies.map((s) => s.code).includes(c.id)
  ).map((c) => ({ code: c.id, name: c.name, flag: c.flag }));

  return currencies.map((t) => ({
    code: t.code,
    name: t.title,
    flag: countries.find((c) => c.code === t.code)?.flag || "",
  }));
}
