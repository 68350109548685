import React, { useEffect, useState } from "react";
import "./transaction-chart-tooltip.scss";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import NumberFormat from "react-number-format";

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        style={{ fontSize: 12, fontWeight: 700 }}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip: React.FC<any> = ({ payload, label, active }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <div className="tooltip-label1">
          <label>{`${label}`}</label>
        </div>
        <div className="tooltip-label2">
          <label>
            {`Amount : `}
            <NumberFormat
              value={payload[0].payload.amount}
              displayType={"text"}
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix="₹ "
            />
          </label>
        </div>
      </div>
    );
  }
  return null;
};

const TransactionChart: React.FC<{
  monthlyTransactions: { [key: string]: number } | null;
  height: number;
}> = ({ monthlyTransactions, height }) => {
  const [chartData, setChartData] = useState<
    { date: string; amount: string }[] | []
  >([]);
  useEffect(() => {
    if (monthlyTransactions) {
      let newData: { date: string; amount: string }[] | [] = [];
      Object.keys(monthlyTransactions).map((key: string) => {
        newData = [
          ...newData,
          {
            date: key,
            amount: monthlyTransactions[key].toString(),
          },
        ];
      });
      setChartData(newData);
    }
  }, [monthlyTransactions]);

  const minMax = () => {
    let result = [0, 0];
    if (monthlyTransactions) {
      let d = Object.keys(monthlyTransactions).map(
        (key: string) => monthlyTransactions[key]
      );
      result = [Math.min(...d), Math.max(...d)];
    }
    return result;
  };

  return (
    <ResponsiveContainer width="100%" height={height || 250}>
      <AreaChart
        data={chartData}
        width={500}
        height={400}
        margin={{
          top: 15,
          right: 15,
          left: 0,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} interval={0} />
        <YAxis
          // label={"Range"}
          dataKey={"amount"}
          // allowDataOverflow
          domain={minMax()}

          // type="number"
          // interval={"preserveEnd"}
        />
        <Tooltip content={<CustomTooltip />} />

        <Area
          type="monotone"
          dataKey="amount"
          stroke="#ff4242"
          // dot={{ stroke: "#105099", strokeWidth: 2 }}
        />
        {/* <Line type="monotone" dataKey="low" stroke="#82ca9d" strokeWidth={2} /> */}
        {/* <Line type="monotone" dataKey="open" stroke="red" strokeWidth={2} /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TransactionChart;
