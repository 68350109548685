import { isSuccess } from "@devexperts/remote-data-ts";
import { ClaimCountInfo } from "../api/detax/model/claimCountInfo";
import { DetaxBatchCountInfo } from "../api/detax/model/detaxBatchCountInfo";
import { DetaxCardSubscriptionCountInfo } from "../api/detax/model/detaxCardSubscriptionCountInfo";
import { NpsSubscriptionCountInfo } from "../api/detax/model/npsSubscriptionCountInfo";
import { UserStatusCountInfo } from "../api/employee/model/userStatusCountInfo";
import { CorporateService } from "../services/corporate.service";
import { DetaxService } from "../services/detax.service";
import { NpsService } from "../services/nps.service";
import { TransactionService } from "../services/transaction.service";
import {
  endDashboardLoad,
  resetDashboardRedirect,
  setDashboardData,
} from "../state/slice/dashboard.slice";
import { store } from "../state/store";

export async function loadDashboardData() {
  let npsInfo: Array<NpsSubscriptionCountInfo> | [] = [];
  let detaxCardTransactions: { [key: string]: number } | null = null;
  let resultData: {
    detaxInfo: DetaxCardSubscriptionCountInfo[] | [];
    employeeInfo: UserStatusCountInfo[] | [];
    claimInfo: ClaimCountInfo[] | [];
  } = {
    detaxInfo: [],
    employeeInfo: [],
    claimInfo: [],
  };
  let fba: DetaxBatchCountInfo[] | [] = [];
  let ta: DetaxBatchCountInfo[] | [] = [];
  let nps: DetaxBatchCountInfo[] | [] = [];
  let ea: DetaxBatchCountInfo[] | [] = [];
  let gc: DetaxBatchCountInfo[] | [] = [];

  let [
    detaxCardTransactionsRes,
    npsStatusCount,
    result,
    fbaResult,
    taResult,
    npsResult,
    eaResult,
    gcResult,
  ] = await Promise.all([
    await DetaxService.getMonthlyCounts(),
    await NpsService.getCardSubscriptionCount(""),
    await CorporateService.getDashboardData(),
    await TransactionService.getDetaxBatchCounts("FBA"),
    await TransactionService.getDetaxBatchCounts("TA"),
    await TransactionService.getDetaxBatchCounts("NPS"),
    await TransactionService.getDetaxBatchCounts("EA"),
    await TransactionService.getDetaxBatchCounts("GC"),
  ]);

  if (isSuccess(detaxCardTransactionsRes)) {
    if (detaxCardTransactionsRes.value.successful) {
      detaxCardTransactions = detaxCardTransactionsRes.value.data || null;
    }
  }

  if (isSuccess(npsStatusCount)) {
    if (npsStatusCount.value.successful) {
      npsInfo = npsStatusCount.value.data || [];
    }
  }

  if (isSuccess(result)) {
    if (result.value.successful) {
      resultData.detaxInfo = result.value.detaxInfo?.data
        ? result.value.detaxInfo?.data
        : [];
      resultData.employeeInfo = result.value.employeeInfo?.data
        ? result.value.employeeInfo?.data
        : [];
      resultData.claimInfo = result.value.claimInfo?.data
        ? result.value.claimInfo?.data
        : [];
    }
  }

  if (isSuccess(fbaResult)) {
    if (fbaResult.value.successful) fba = fbaResult.value.data || [];
  }
  if (isSuccess(taResult)) {
    if (taResult.value.successful) ta = taResult.value.data || [];
  }
  if (isSuccess(eaResult)) {
    if (eaResult.value.successful) ea = eaResult.value.data || [];
  }
  if (isSuccess(npsResult)) {
    if (npsResult.value.successful) nps = npsResult.value.data || [];
  }
  if (isSuccess(gcResult)) {
    if (gcResult.value.successful) gc = gcResult.value.data || [];
  }

  store.dispatch(
    setDashboardData({
      npsInfo,
      detaxInfo: resultData.detaxInfo,
      employeeInfo: resultData.employeeInfo,
      claimInfo: resultData.claimInfo,
      detaxCardTransactions,
      fba,
      ta,
      nps,
      ea,
      gc,
    })
  );
  store.dispatch(endDashboardLoad());
  store.dispatch(resetDashboardRedirect());
}
