const building = "/images/building.svg";
const edit = "/images/edit.svg";
const mail = "/images/mail.svg";
const phone = "/images/phone.svg";
const user = "/images/user.svg";
const lock = "/images/lock.svg";
const address = "/images/address-cin.svg";
const cin = "/images/cin.svg";
const company = "/images/company-name-cin.svg";
const date = "/images/date-cin.svg";
const reg = "/images/reg-num-cin.svg";
const roc = "/images/roc-code-cin.svg";

export const images = {
  building,
  user,
  mail,
  phone,
  edit,
  lock,
  address,
  cin,
  company,
  date,
  reg,
  roc,
};
