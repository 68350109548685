import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "././reject-popup.scss";

const LogoutPopup: React.FC<{
  show: boolean;
  onHide: (show: boolean) => void;
  onLogout: (remark: string) => void;
}> = ({ show, onHide, onLogout }) => {
  const [remark, setRemark] = useState("");

  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-danger d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <Modal.Title className="text-light">
          Are you sure you want to logout?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
        <Button variant="danger" onClick={() => onLogout(remark)}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutPopup;
