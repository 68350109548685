import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { useLoginWithToken } from "../../hooks/use-login-with-password";
import { Dispatch } from "../../state/dispatch";
import { setToken } from "../../state/slice/token.slice";
import { setLoginUser } from "../../state/slice/user.slice";
import { isPermissionToDetaxCard } from "../../utils/auth.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import { setIsAuthenticated } from "../app/app.slice";
import { toast } from "react-toastify";

const LoginUsingToken: React.FC = () => {
  const { switchToken } = useParams<{ switchToken: string }>();
  const dispatch = useAppDispatch();
  const { mutateAsync: loginWithToken, isLoading } = useLoginWithToken();
  const navigate = useNavigate();

  const [error, setError] = useState<String>("");

  useEffect(() => {
    const run = async () => {
      const result = await loginWithToken({
        token: switchToken,
      });
      console.log(result);
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false)) {
          const roles = unWrapOption(result.value.roles, () => [""]);
          Dispatch.App.setAuthInfo(result.value); // set response in redux store
          Dispatch.App.setLoginId(result.value.id);
          Dispatch.App.setLoginUserCid(result.value.cid);
          Dispatch.App.setFullName(result.value.full_name);
          Dispatch.App.setPasswordChanged(result.value.initPassChanged);
          dispatch(
            setLoginUser({ mobile: unWrapOption(result.value.email, () => "") })
          );
          dispatch(
            setToken({
              accessToken: unWrapOption(result.value.access_token, () => ""),
              refreshToken: unWrapOption(result.value.refresh_token, () => ""),
              tokenExpiry: unWrapOption(result.value.expires_in, () => 0),
              scope: roles[0], // store roles into scope to check on app load
            })
          );
          dispatch(setIsAuthenticated(true));
          Dispatch.Token.setAppToken(result.value.appToken);

          if (roles[0] === "ROLE_CORPORATE_APPLICANT") {
            // send to ApplicationSubmitted when applicant
            //await getNextScreenFromReview();
            navigate(RoutePaths.ApplicationSubmitted);
          } else if (
            roles[0] === "ROLE_CORPORATE_ADMIN" ||
            roles[0] === "ROLE_CORPORATE_ADMIN_MAKER"
          ) {
            // check if password must be changed
            const isPasswordChangeNotRequired = unWrapOption(
              result.value.initPassChanged,
              () => true
            );

            // when return false then should go to password change
            if (isPasswordChangeNotRequired) {
              // go to dashbaord if DETAX_CARD permission added else send to FXOnline

              navigate(
                isPermissionToDetaxCard(result.value.permissions)
                  ? RoutePaths.DashboardHome
                  : RoutePaths.DashboardFxOnline
              );
            } else {
              // go to password change screen

              navigate(RoutePaths.MustChangePassword);
            }
          } else {
            console.log("Incorrect role : ", roles[0]);
          }
        } else {
          toast.error("Login Failed");
        }
      } else if (isFailure(result)) {
        toast.error("Login Failed");
        setError("Login Failed");
      }
    };
    run();
  }, []);

  if (error) {
    return <>{error}</>;
  }

  return <>Switching account....</>;
};

export default LoginUsingToken;
