import React from "react";
import FormikInputGroup from "../common/formik/formik-input-group/formik-input-group";
import FormikInputGroupEye from "../common/formik/formik-input-group/formik-input-group-eye";


function LoginWithMobile() {

  return (
    <>
      <div className="mb-0">
        <FormikInputGroup
          icon="phone"
          type="text"
          name="mobile"
          // maxLength={10}
          className="form-control input-field p-2 border-0"
          placeholder="Enter your mobile number"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
}

export function LoginWithUsernameAndPassword() {
  return (
    <>
      <div className="mb-3">
        <FormikInputGroup
          icon="user"
          type="text"
          name="username"
          // maxLength={10}
          className="form-control input-field p-2 border-0"
          placeholder="Enter your mobile number or email"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
      <div className="mb-0">
        <FormikInputGroupEye
          icon="lock"
          type="password"
          name="password"
          maxLength={50}
          className="form-control input-field p-2 border-0"
          placeholder="Enter your password"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </>
  );
}

export default LoginWithMobile;
