import React, { useState } from "react";

import { useAppSelector } from "../../../../hooks/hooks";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";
import PageTitle from "../../../common/page-title";

import TransactionTabs from "../../../horizontal-tabs/transaction-tabs";

import Heading from "../common/heading";

import CreateBatchButton from "./create-batch-button";

import StatusSection from "./status-section";
import TransactionTable from "./transaction-table";

import { getTabLabel } from "./txn.utils";
import CreateBatch from "./create-batch";
import CustomSearchBar from "../../../custom-search/custom-search";

const UploadTXN: React.FC = () => {
  const [searchVal, setSearchVal] = useState("");
  const page = useAppSelector(DetaxReduxStore.selectPage);
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const createTransactionLabel =
    page === "ELIGIBLE_USERS"
      ? `${getTabLabel(activeTab)} > Create Batch`
      : "Batches";

  return (
    <div>
      <PageTitle title="Batches" />
      <Heading title={createTransactionLabel} />
      {page !== "ELIGIBLE_USERS" && <TransactionTabs />}
      {page === "DEFAULT" && (
        <>
          {/* <SearchTXN /> */}
          {/* <CustomSearchBar
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="70%"
            text="Search by Batch Code/ Created By "
          /> */}
          <StatusSection isLoading />
          <TransactionTable searchVal={searchVal} />
        </>
      )}
      {page === "ELIGIBLE_USERS" && <CreateBatch />}
    </div>
  );
};

export default UploadTXN;
