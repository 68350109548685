import { createSlice } from "@reduxjs/toolkit";
import { FiuConsentInfo } from "../../api/account-aggregator/model/fiuConsentInfo";
import { FiuProfileSummaryApiResponse } from "../../api/account-aggregator/model/fiuProfileSummaryApiResponse";
import { FiuTransactionApiData } from "../../api/account-aggregator/model/fiuTransactionApiData";
import { FiuTransactionGraphData } from "../../api/account-aggregator/model/fiuTransactionGraphData";
import { State } from "../store";

export interface Iaa {
    aaLoading: boolean;
    aaOverview: Array<FiuConsentInfo> | [];
    aaAccount: FiuProfileSummaryApiResponse | null;
    aaTransactions: FiuTransactionApiData | null;
    aaTransactionsGraph: FiuTransactionGraphData[] | null;
}

const initialState: Iaa = {
    aaLoading: true,
    aaOverview: [],
    aaAccount: null,
    aaTransactions: null,
    aaTransactionsGraph: null
}

const slice = createSlice({
    name: 'aa',
    initialState,
    reducers: {
        initializeApp: () => initialState,
        setAAdata: (state, { payload: aaData }) => {
            state.aaOverview = aaData.aaOverview
            state.aaAccount = aaData.aaAccount
            state.aaTransactions = aaData.aaTransactions
            state.aaTransactionsGraph = aaData.aaTransactionsGraph
        },
        endAAload: state => {
            state.aaLoading = false
        },
    }
})

export const { initializeApp, setAAdata, endAAload } = slice.actions

export const selectAAdata = (state: State) => state.aa
export const selectAAloader = (state: State) => state.aa.aaLoading

export default slice.reducer;