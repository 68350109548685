import { BackButton, Button, Heading } from "../..";
import { pipe } from "fp-ts/lib/function";
import { fold, getOrElse, map, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { fromPublic, getTitleCase } from "../../../utils/common.utils";
import { OnboardingService } from "../../../services/onboarding.service";
import { isSuccess, isFailure } from "@devexperts/remote-data-ts";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";

import { TCorporateInfo } from "../../../types/corporate-info.type";
import { getCorpInfo, getSubmittedInfo } from "../../../utils/company.utils";

import Stepper from "../../stepper/stepper";
import PageLoader from "../../../page-loader";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";
import { AuthService } from "../../../services/auth.service";
import {
  selectAppToken,
  selectRefreshToken,
} from "../../../state/slice/token.slice";
import {
  GetTokenFromLocalStorage,
  SetTokenInStore,
} from "../../../utils/auth.utils";
import { MyStorage } from "../../../utils/local-storage.utils";
import { resetProfile } from "../../../state/slice/profile.slice";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { OAuthLoginApiService } from "../../../api/auth/oauthLoginApi.service";
import BrandLogoHeader from "./brandlogo-header";

const Image11 = fromPublic("/images/br.svg");
const Bell = fromPublic("/images/icons/bell-icon.svg");

const ApplicationSubmittedView: React.FC = () => {
  const refreshToken = useAppSelector(selectRefreshToken);
  const appToken = useAppSelector(selectAppToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [corpInfo, setCorpInfo] = useState<Option<TCorporateInfo>>(none);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Application submitted" });
  }, []);

  useEffect(() => {
    const run = async () => {
      const result = await OnboardingService.getReview();
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          //check the next screen if this is dashboard then send it to dashboard with token refresh.
          const nextAction = unWrapOption(result.value.nextAction, () => "");
          if (nextAction === RoutePaths.DashboardHome) {
            // Commneted to use new token
            //const tokenResult = await AuthService.refreshToken(refreshToken);
            // if (isSuccess(tokenResult)) {
            //   SetTokenInStore(tokenResult.value, refreshToken);
            // }
            navigate(RoutePaths.DashboardFxOnline);
          }
          setCorpInfo(result.value.data);
        } else {
          //setError(unWrapOption(result.value.message, () => "Invalid CIN"));
        }
        setLoading(false);
      } else if (isFailure(result)) {
        //setError("Review not found");
      }
    };
    run();
  }, []);

  const handleLogout = async () => {
    // check authorisation token and call logout
    const token = GetTokenFromLocalStorage();
    if (token.accessToken) {
      // if token exist then call logout
      const result = await OAuthLoginApiService.oAuthLogout();
    }
    localStorage.clear();
    navigate("/login");
  };

  const corpProp = getSubmittedInfo(corpInfo);

  const brDoc = corpProp.brInfo.document
    ? pipe(
        corpProp.brInfo.document,
        getOrElse(() => "")
      )
    : "";

  const state = pipe(
    corpProp.state,
    getOrElse(() => "")
  );

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <div className="container p-0">
        <BrandLogoHeader />
        {/* <Stepper /> */}
        <div className="row">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block col-md-6 col-lg-7 col-xl-8 col-xxl-8">
            <div className="mt-5 mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <img
                className="img-fluid"
                width="948"
                height="707"
                src={process.env.PUBLIC_URL + Image11}
                alt="Mobile"
              />
            </div>
            <div className="textDetail" style={{ marginTop: "-40px" }}>
              <Heading
                className="mx-5 mb-3"
                text="Congratulations!"
                variant="small"
              />
              <p className="mx-5 fw-bolder text-secondary">
                Congratulations on signing up. Your company has taken the right
                step for becoming the employer of choice. We wish your company
                and your employees all the very best.
              </p>
            </div>
          </div>
          <div className="mt-4 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block col-md-6 col-lg-5 col-xl-4 col-xxl-4">
            <div className="container p-0 m-0">
              <div
                className="p-3 mb-5 border-0 shadow card custom-scroll bg-grey rounded-15"
                style={{ maxHeight: 650, overflowY: "auto" }}
              >
                <BackButton text="Application submitted" canGoBack={false} />

                <p className="pt-3 mb-1 fs-6 fw-bold text-secondary pe-3">
                  User authorisation agreement sent!
                </p>
                <p className="mb-3 ncash_sub-text text-secondary col-12 col-lg-12">
                  The agreement has been sent to the authorised users of the
                  company. Review and sign it digitally.
                </p>

                <div className="p-3 bg-white border-0 card rounded-15">
                  <div className="row">
                    <div className="col-12 pe-0">
                      <div className="row">
                        <div className="col-5 pe-0">
                          <div className="mt-1 text-left fs-6 fw-bold">
                            Agreement sent on{" "}
                          </div>
                        </div>
                        <div className="p-0 mt-1 col-1">:</div>
                        <div className="col-6">
                          <div className="mt-1 text-left fs-6 fw-bolder">
                            {pipe(
                              corpProp.brInfoSentOn,
                              getOrElse(() => "N/A")
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <div className="fs-6 fw-bold">Status</div>
                        </div>
                        <div className="p-0 col-1">:</div>
                        <div className="col-6 pe-0">
                          <div className="fs-6 text-secondary text-warning fw-bolder">
                            {getTitleCase(
                              state === "BR_SENT" ? "Agreement sent" : state
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {brDoc ? (
                        <Button
                          onClick={() => {
                            brDoc && window.open(brDoc);
                          }}
                          className={` ${
                            brDoc ? "" : " btn-disable "
                          }  btn-darkblue p-1 mx-auto my-auto w-100 font-12 text-white mt-2`}
                        >
                          View
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-12">
                      {pipe(
                        corpProp.state,
                        getOrElse(() => "")
                      ).toLowerCase() === "live" ? (
                        <Button
                          onClick={handleLogout}
                          className={`btn-darkblue p-1 mx-auto my-auto w-100 font-12 text-white mt-2`}
                        >
                          Go To Login
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <p className="pb-1 m-0 mt-3 fs-5 fw-bold">Agreement Sent To</p>
                {corpProp.receipients.map((rec, index) => (
                  <div className="mb-4 row" key={index}>
                    <div className="col-8">
                      <div className="font-16">
                        {pipe(
                          rec.name,
                          getOrElse(() => "")
                        )}
                      </div>
                      <div className="font-14">
                        {pipe(
                          rec.email,
                          getOrElse(() => "")
                        )}
                      </div>
                      <div className="font-14">
                        +91-
                        {pipe(
                          rec.mobile,
                          getOrElse(() => "")
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="text-lightblue font-12 fw-bold d-flex justify-content-end">
                        {pipe(
                          rec.type,
                          getOrElse(() => "")
                        ) === "DIRECTOR"
                          ? "Director"
                          : "Signatory"}
                      </div>
                      {pipe(
                        rec.status,
                        getOrElse(() => "")
                      ) === "SIGNED" ? (
                        <small className="d-flex justify-content-end align-items-center text-end text-success">
                          <p className="m-0 me-1"> Signed</p>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1.4em"
                            width="1.4em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M22 5.18L10.59 16.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L22 5.18zm-2.21 5.04c.13.57.21 1.17.21 1.78 0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8c1.58 0 3.04.46 4.28 1.25l1.44-1.44A9.9 9.9 0 0012 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-1.19-.22-2.33-.6-3.39l-1.61 1.61z"></path>
                          </svg>
                        </small>
                      ) : (
                        <small className="d-flex justify-content-end text-end text-warning">
                          <p className="m-0 me-1">Pending</p>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="2em"
                            width="2em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                          </svg>
                        </small>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 d-md-none d-lg-none d-xl-none d-xxl-none">
        <div className="container p-0 m-0">
          <div className="border-0 card rounded-15 bg-grey">
            <div className="pt-2 m-2 row">
              <div className="col-10">
                <BackButton text="Application submitted" canGoBack={false} />
              </div>
              <div className="col-2 ">
                <div className="p-2 bg-white border-2 shadow card rounded-15 align-items-center">
                  <img className="img-fluid w-100" src={Bell} alt="Mobile" />
                </div>
              </div>
            </div>
            <p className="pt-3 fs-6 fw-bold text-secondary ps-3 pe-3">
              User authorisation agreement sent!
            </p>
            <div className="row ms-3 me-3">
              <div className="p-0 col-7 col-sm-7">
                <p className="fs-small text-secondary">
                  The agreement has been sent to the authorised users of the
                  company. Review and sign it digitally.
                </p>
              </div>

              <div className="col-5 col-sm-5 ">
                <img
                  className="img-fluid"
                  width="948"
                  height="707"
                  src={process.env.PUBLIC_URL + Image11}
                  alt="Mobile"
                />
              </div>
            </div>
            <div className="p-2 bg-white border-0 shadow card rounded-15 ms-3 me-3 Top-25 Absolute-width z-2">
              <div className="row">
                <div className="col-9 pe-0">
                  <div className="row">
                    <div className="col-5 pe-0">
                      <div className="mt-1 text-left fs-6 fw-bold">
                        Agreement sent on{" "}
                      </div>
                    </div>
                    <div className="p-0 mt-1 col-1">:</div>
                    <div className="col-6">
                      <div className="mt-1 text-left fs-6 fw-bolder">
                        {pipe(
                          corpProp.brInfoSentOn,
                          getOrElse(() => "N/A")
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div className="fs-6 fw-bold">Status</div>
                    </div>
                    <div className="p-0 col-1">:</div>
                    <div className="col-6 pe-0">
                      <div className="fs-6 text-secondary text-warning fw-bolder">
                        {getTitleCase(
                          state === "BR_SENT" ? "Agreement sent" : state
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  {brDoc ? (
                    <Button
                      onClick={() => {
                        brDoc && window.open(brDoc);
                      }}
                      className={` ${
                        brDoc ? "" : " btn-disable "
                      }  btn-darkblue p-1 mx-auto my-auto w-100 font-12 text-white mt-2`}
                    >
                      View
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="p-3 mt-1 bg-white border-0 card rounded-30 positin-relative">
              <p className="mt-3 mt-4 fs-5 fw-bold">Agreement Sent To</p>
              {corpProp.receipients.map((rec, index) => (
                <div className="row" key={index}>
                  <div className="col-8">
                    <div className="font-14 fw-bold">
                      {pipe(
                        rec.name,
                        getOrElse(() => "")
                      )}
                    </div>
                    <div className="font-12">
                      {pipe(
                        rec.email,
                        getOrElse(() => "")
                      )}
                    </div>
                    <div className="font-12">
                      +91-
                      {pipe(
                        rec.mobile,
                        getOrElse(() => "")
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="text-lightblue font-12 fw-bold d-flex justify-content-end">
                      {pipe(
                        rec.type,
                        getOrElse(() => "")
                      ) === "DIRECTOR"
                        ? "Director"
                        : "Signatory"}
                    </div>
                  </div>
                  <hr className="mt-1 me-1" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSubmittedView;
