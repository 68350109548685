import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "../..";
import { useAppDispatch } from "../../../hooks/hooks";
import { useValidateCin } from "../../../hooks/use-validate-cin";
import { Dispatch } from "../../../state/dispatch";
import { fromPublic } from "../../../utils/common.utils";
import { setErrorMessages } from "../../../utils/error.utils";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";

import FormikComponent from "../../common/formik/formik-component/formik-component";
import FormikInputGroup from "../../common/formik/formik-input-group/formik-input-group";
import Loader from "../../common/loader/loader";
import Stepper from "./stepper";
import mixpanel from "mixpanel-browser";
import BrandLogoHeader from "./brandlogo-header";

//const Image1 = fromPublic("/images/bg1-new.svg");
const Image1 = fromPublic("/images/bg1-new2.svg");
// while testing use this CIN: U74999MP2017PTC044578 // L65922MH1989PLC052257

interface IValues {
  companyname: string;
}

const formInitialSchema: IValues = {
  companyname: "",
};
const validationSchema = yup.object().shape({
  companyname: yup.string().required("Enter Valid Company CIN"),
});

const ValidateCompanyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const { mutateAsync: validateCin, isLoading } = useValidateCin();

  useEffect(() => {
    mixpanel.track("Page load", { name: "Validate Company" });
  }, []);

  const handleFormSubmit = async (values: IValues) => {
    mixpanel.track("Button click", {
      name: "Verify CIN",
      CIN: values.companyname,
    });
    const result = await validateCin(values.companyname);
    //console.log(`result: `, result);
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        Dispatch.App.setDraftId(result.value.applicationId);
        const path = pipe(
          result.value.nextAction,
          getOrElse(() => "/")
        );
        navigate(path);
      } else {
        setError(unWrapOption(result.value.message, () => "Invalid CIN"));
      }
    } else if (isFailure(result)) {
      setError(setErrorMessages(result.error));
    }
  };

  return (
    <>
      <Helmet>
        <title>Ncaish</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <BrandLogoHeader />
          {/* <Stepper /> */}
          <div className="mt-4 row d-none d-md-block">
            <div className="col-12">
              <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                <div className="row">
                  <div className="col-lg-8 col-md-6 ">
                    <MediumScreen>
                      <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <img
                          className="img-fluid"
                          width="791"
                          height="741"
                          src={Image1}
                          alt="Mobile"
                        />
                      </div>

                      <div className="mt-10 textDetail">
                        <Heading
                          className="mx-5 mb-3 mt-n85"
                          text="Onboarding First Step"
                          variant="small"
                        />

                        <p className="mx-5 fw-bolder text-secondary">
                          The journey to your employee’s financial wellness
                          begins now. Let’s get started with 7 simple steps.
                        </p>
                      </div>
                    </MediumScreen>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <MediumScreen>
                      <div className="container p-0 m-0">
                        <div className="p-3 border-0 shadow card bg-grey rounded-15">
                          {error && (
                            <div
                              className="text-center alert alert-danger fw-bold"
                              role="alert"
                            >
                              {error}
                            </div>
                          )}
                          <Heading
                            className="text-center mt-85"
                            text="Ncaish welcomes you!"
                            variant="large"
                          />
                          <p className="mt-4 mb-0 mb-1 text-center text-black d-none d-sm-none d-md-block fw-bold"></p>

                          <div className="mt-4 text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                            <div className="mb-3">
                              <FormikInputGroup
                                icon="building"
                                type="text"
                                name="companyname"
                                className="p-2 border-0 form-control fs-small fw-bolder input-field"
                                placeholder="Enter your Company CIN"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                              />
                            </div>
                            <span className="text-center fw-bold text-lightblue">
                              We need this to make your onboarding hassle free.
                            </span>
                            <div className="mt-3 ml-auto mr-auto text-center ">
                              <Button type="submit">
                                {isLoading ? (
                                  <Loader color="white" />
                                ) : (
                                  <>Continue</>
                                )}
                              </Button>

                              <p className="mt-4 mb-5 text-center fw-bolder">
                                Already have an account?
                                <Link
                                  className="ncash_nav-link"
                                  to={RoutePaths.Login}
                                >
                                  Login
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MediumScreen>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="border-0 shadow card bg-grey">
                  {error && (
                    <div
                      className="text-center alert alert-danger fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="p-3 img-fluid"
                      width="791"
                      height="741"
                      src={Image1}
                      alt="BG"
                    />
                  </div>
                  <h1 className="text-center fw-bold ">Ncaish welcomes you!</h1>
                  <p className="mb-4 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small"></p>

                  <div className="p-2 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                    <FormikInputGroup
                      icon="building"
                      type="text"
                      name="companyname"
                      className="p-2 border-0 form-control fs-small fw-bolder input-field"
                      placeholder="Enter your Company CIN"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <span className="mt-2 mb-2 text-center fw-bold text-secondary fs-small">
                      We need this to make your onboarding hassle free.
                    </span>
                  </div>
                  <div className="mt-3 ml-auto mr-auto text-center">
                    <Button type="submit">
                      {isLoading ? <Loader color="white" /> : <>Continue</>}
                    </Button>
                    <p className="mt-3 mb-5 text-center fw-bolder ">
                      Already have an account?
                      <Link className="ncash_nav-link" to={RoutePaths.Login}>
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

export default ValidateCompanyPage;
