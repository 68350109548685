import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import { EmployeeInfo } from "../../../../api/detax/model/employeeInfo";
import { MembershipBatchTableInfo } from "../../../../api/detax/model/membershipbatchtableInfo";
import { MembershipService } from "../../../../services/membership.service";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import { currencyFormat } from "../../../../utils/format.utils";
import {
  CardDetailsTabs,
  Claims,
  FlexiAllowance,
  Transactions,
} from "../../../common/vertical-tablist/tabs/employee/tabs";
import VerticalTabs from "../../../common/vertical-tablist/tabs/employee/vertical-tablist";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import EmployeeDetails from "../common/employee-details";
import Heading from "../common/heading";
import { pipe } from "fp-ts/lib/function";
import { useDispatch } from "react-redux";
import TransactionTimelineHorizontal from "../detax/transaction/transaction-timeline-horizontal";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import NoTableData from "../common/no-table-data";
import CancelPopup from "../common/popups/cancel-popup";
import SuccessPopup from "../common/succes-popup";

const UserIcon = "/images/line_icons/Employee_name.svg";
const PhoneIcon = "/images/line_icons/Mobile_No.svg";
const EmailIcon = "/images/line_icons/Email.svg";
const GenderIcon = "/images/line_icons/Gender.svg";
const EmpCodeIcon = "/images/line_icons/Employee_Code.svg";
const EmpStatusIcon = "/images/line_icons/Status.svg";
const AddressIcon = "/images/line_icons/Address.svg";
const CityIcon = "/images/line_icons/City.svg";
const CountryIcon = "/images/line_icons/Country.svg";
const StateIcon = "/images/line_icons/State.svg";
const PinCodeIcon = "/images/line_icons/PinCode.svg";

const DOBIcon = "/images/line_icons/Date_of_Birth.svg";
const ActiveFromIcon = "/images/line_icons/Active_From.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";

export const DashboardMembershipsBatchDetails: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState("card");
  const [activeNPStab, setActiveNPStab] = useState("PranAccount");
  const [activeHtab, setActiveHtab] = useState<string>("");
  const [err, setErr] = useState(false);
  const [showCancelBatch, setShowCancelBatch] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<MembershipBatchTableInfo>({
    batchCode: params.id,
    cid: "",
    batchStatus: "",
    users: [
      {
        userId: "",
        name: "",
        mobile: "",
        transactionId: "",
        transactionDate: "",
      },
    ],
    corpName: "",
    registeredAt: "",
    status: "",

    transactionDate: "",
    batchAmount: 0,
    amountRecieved: 0,
    id: "",
    objectId: "",
    createdBy: "",
    deletedAt: "",
    updatedAt: "",
    createdAt: "",
    archive: true,
    cinNumber: "",
    name: "",
    mobile: "",
    email: "",
    transactionId: "",
  });

  const handleCancelBatch = async () => {
    const result = await MembershipService.cancelBatchRequest(params.id || "");
    if (isSuccess(result)) {
      setEmployee((p) => ({ ...p, ...result.value.data }));

      setPaging((p) => ({
        ...p,
        total: (employee.users || []).length,
        page: 1,
        limit: 10,
      }));
      setShowSuccess(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (params?.id) {
      loadData(params.id || "");
      navigate(RoutePaths.DashboardMembershipsBatchDetail + "/" + params.id);
    }
  }, [params.id]);

  const loadData = async (id: string) => {
    const result = await MembershipService.getById(id);
    if (isSuccess(result)) {
      setEmployee((p) => ({ ...p, ...result.value.data }));
      // if (result.value.data?.detaxCard) setActiveHtab("detaxCard");
      // else if (result.value.data?.nps) setActiveHtab("nps");
      // else setActiveHtab("");
      setPaging((p) => ({
        ...p,
        total: (employee.users || []).length,
        page: 1,
        limit: 10,
      }));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: (employee.users || []).length,
      page: 1,
      limit: 10,
    }));
  }, [employee]);

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  var status;

  if (employee.batchStatus == "Requested") {
    status = [
      {
        name: "REQUESTED",
        displayName: "Requested",
        executed: true,
        color: "GREEN",
      },
      {
        name: "INITIATED",
        displayName: "Initiated",
        executed: false,
        color: "GRAY",
      },
      {
        name: "EXECUTED",
        displayName: "Executed",
        executed: false,
        color: "GRAY",
      },
      {
        name: "APPROVED",
        displayName: "Approved",
        executed: false,
        color: "GRAY",
      },
    ];
  } else if (employee.batchStatus == "Initiated") {
    status = [
      {
        name: "REQUESTED",
        displayName: "Requested",
        executed: true,
        color: "GREEN",
      },
      {
        name: "INITIATED",
        displayName: "Initiated",
        executed: true,
        color: "GREEN",
      },
      {
        name: "EXECUTED",
        displayName: "Executed",
        executed: false,
        color: "GRAY",
      },
      {
        name: "APPROVED",
        displayName: "Approved",
        executed: false,
        color: "GRAY",
      },
    ];
  } else if (employee.batchStatus == "Executed") {
    status = [
      {
        name: "REQUESTED",
        displayName: "Requested",
        executed: true,
        color: "GREEN",
      },
      {
        name: "INITIATED",
        displayName: "Initiated",
        executed: true,
        color: "GREEN",
      },
      {
        name: "EXECUTED",
        displayName: "Executed",
        executed: true,
        color: "GREEN",
      },
      {
        name: "APPROVED",
        displayName: "Approved",
        executed: false,
        color: "GRAY",
      },
    ];
  } else if (employee.batchStatus == "Approved") {
    status = [
      {
        name: "REQUESTED",
        displayName: "Requested",
        executed: true,
        color: "GREEN",
      },
      {
        name: "INITIATED",
        displayName: "Initiated",
        executed: true,
        color: "GREEN",
      },
      {
        name: "EXECUTED",
        displayName: "Executed",
        executed: true,
        color: "GREEN",
      },
      {
        name: "APPROVED",
        displayName: "Approved",
        executed: true,
        color: "GREEN",
      },
    ];
  }

  return (
    <>
      <PageTitle title={`Membership Batch details | ${employee.batchCode}`} />

      <div className="row">
        <div className="col d-flex justify-content-between">
          <Heading
            title="Membership Batch details"
            detail={employee.batchCode}
            status={""}
            links={[RoutePaths.DashboardMemberships]}
          />
          {employee.batchStatus === "Requested" && (
            <button
              className="btn btn-secondary ms-2 btn-danger"
              onClick={() => {
                setShowCancelBatch(true);
              }}
            >
              Cancel Batch
            </button>
          )}
        </div>
      </div>
      <div className="row p-2" id="ExpenseDetails">
        <div className="col-12">
          <TransactionTimelineHorizontal stages={status} />
        </div>
      </div>

      <WhiteCard className="p-3">
        <div className="">
          <h4 className="f-14 text-secondary">Batch Details</h4>
          <div className="row">
            <div className="col-11">
              <div className="row">
                {employee.batchCode && employee.batchCode != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpCodeIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Batch Code </p>
                        <h5 className="font-14 mb-0">
                          {employee.batchCode || null}
                          {/* {currencyFormat(details.salaryInfo?.salary || 0)} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.batchStatus && employee.batchStatus != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpStatusIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Status </p>
                        <h5 className="font-14 mb-0">
                          {employee.batchStatus === "Canceled"
                            ? "Cancelled"
                            : employee.batchStatus || null}
                          {/* {currencyFormat(
                              details.salaryInfo?.basicSalary || 0
                            )} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.batchAmount && employee.batchAmount != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={SalaryIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Batch Amount </p>
                        <h5 className="font-14 mb-0">
                          {/* {employee.batchAmount || null} */}
                          {currencyFormat(employee.batchAmount || 0)}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.transactionId && employee.transactionId != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpCodeIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Transaction ID </p>
                        <h5 className="font-14 mb-0">
                          {employee.transactionId || null}
                          {/* {currencyFormat(
                              details.salaryInfo?.basicSalary || 0
                            )} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.transactionDate &&
                employee.transactionDate != null &&
                employee > 0 ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={ActiveFromIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Transaction Date </p>
                        <h5 className="font-14 mb-0">
                          {employee.transactionDate || null}
                          {/* {currencyFormat(
                              details.salaryInfo?.basicSalary || 0
                            )} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.createdAt && employee.createdAt != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={ActiveFromIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Created At </p>
                        <h5 className="font-14 mb-0">
                          {employee.createdAt || null}
                          {/* {currencyFormat(
                              details.salaryInfo?.basicSalary || 0
                            )} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </WhiteCard>

      <WhiteCard className="p-3">
        <div className="">
          <h4 className="f-14 text-secondary">Corporate Details</h4>
          <div className="row">
            <div className="col-11">
              <div className="row">
                {employee.corpName && employee.corpName != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Corporate Name </p>
                        <h5 className="font-14 mb-0">
                          {employee.corpName || null}
                          {/* {currencyFormat(details.salaryInfo?.salary || 0)} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.cinNumber && employee.cinNumber != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpCodeIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> CIN Number </p>
                        <h5 className="font-14 mb-0">
                          {employee.cinNumber || null}
                          {/* {currencyFormat(
                              details.salaryInfo?.basicSalary || 0
                            )} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.registeredAt && employee.registeredAt != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={ActiveFromIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Registered At </p>
                        <h5 className="font-14 mb-0">
                          {employee.registeredAt || null}
                          {/* {currencyFormat(employee.registeredAt || 0)} */}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
                {employee.amountRecieved && employee.amountRecieved != null ? (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={SalaryIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Amount Received </p>
                        <h5 className="font-14 mb-0">
                          {/* {employee.transactionId || null} */}
                          {currencyFormat(employee.amountRecieved || 0)}
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </WhiteCard>

      <div>
        {employee.users?.length || 0 > 0
          ? [
              <div className="row">
                <div>
                  <h1 className="page-heading">Employees</h1>
                </div>
                <div className="row">
                  <div className="col-7 p-3 ">
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col"> Name</th>
                            <th scope="col">Mobile </th>
                            {/* <th scope="col">Memberships subscribed</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {employee.users?.map((item, key) => (
                            <tr key={key}>
                              {/* <td className="text-start">
                          <Link
                            className="text-secondary ncash_nav-link"
                            to={RoutePaths.EmployeesDetail + "/" + item.userId}
                          >
                            {item.userId === "" ? "-" : item.userId}
                          </Link>
                        </td> */}
                              <td className="text-start text-nowrap">
                                {item.name === "" ? "-" : item.name}{" "}
                              </td>

                              <td className="text-center text-nowrap">
                                {item.mobile === "" ? "-" : item.mobile}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TablePagination>
                  </div>
                </div>
              </div>,
            ]
          : [<NoTableData />]}
      </div>
      <CancelPopup
        show={showCancelBatch}
        onHide={(val: boolean) => setShowCancelBatch(val)}
        onCancel={() => {
          handleCancelBatch();
          setShowCancelBatch(false);
        }}
      />
      <SuccessPopup
        message="Membership Batch cancelled successfully"
        show={showSuccess}
        onClose={(val: boolean) => setShowSuccess(val)}
      />
    </>
  );
};
