import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  BusinessUnit,
  EmployeeInfo,
} from "../../../../api/detax/model/employeeInfo";
import { AddEmployeeApiRequest } from "../../../../api/employee/model/addEmployeeApiRequest";
import { EmployeeService } from "../../../../services/employee.service";
import CardButton from "../../../common/button/card-button";
import SaveEmployeeForm from "../../../modal/employees/EmployeeModal";
import WhiteCard from "../../../white-card/white-card";
import { IEmployeeModal } from "../employees/employee-modal-types";
import DashboardPageLoader from "./dashboard-page-loader";
import SuccessPopup from "./succes-popup";
import "./employee-details.scss";
import { fixNumberInput, getName } from "../../../../utils/common.utils";
import EmployeeSalaryForm from "../../../modal/employees/EmployeeSalaryForm";
import { EmployeeDetailApiResponse } from "../../../../api/detax/model/employeeDetailApiResponse";
import { useLocation } from "react-router-dom";
import { LocalStorageItemKeys } from "../../../../utils/local-storage.utils";
import { currencyFormat } from "../../../../utils/format.utils";

import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import ErrorPopup from "./error-popup";
import { ServiceCallOutcome } from "../../../../api/errors";
import { Dispatch } from "../../../../state/dispatch";
import { Console } from "console";
import { basename } from "path";
import { url } from "inspector";
import { EmployeeResponse } from "../../../../model/employeeResponse";

/*
const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const EmpCodeIcon = "/images/icons/employee_code.png";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const EmpStatusIcon = "/images/icons/emp_status.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";
*/

const UserIcon = "/images/line_icons/Employee_name.svg";
const PhoneIcon = "/images/line_icons/Mobile_No.svg";
const EmailIcon = "/images/line_icons/Email.svg";
const GenderIcon = "/images/line_icons/Gender.svg";
const EmpCodeIcon = "/images/line_icons/Employee_Code.svg";
const EmpStatusIcon = "/images/line_icons/Status.svg";
const AddressIcon = "/images/line_icons/Address.svg";
const CityIcon = "/images/line_icons/City.svg";
const CountryIcon = "/images/line_icons/Country.svg";
const StateIcon = "/images/line_icons/State.svg";
const PinCodeIcon = "/images/line_icons/PinCode.svg";

const DOBIcon = "/images/line_icons/Date_of_Birth.svg";
const ActiveFromIcon = "/images/line_icons/Active_From.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";
const DepartmentIcon = "/images/line_icons/Department.svg";
const TaxRegimeIcon = "/images/icons/tax-regime.svg";
const EmployeeDetails: React.FC<{
  id: string;
  edit?: boolean;
  loadEmployee?: (employee: EmployeeInfo) => void;
  showSalary?: boolean;
  showAddress?: boolean;
}> = ({
  id,
  edit = false,
  loadEmployee = (employee) => {},
  showSalary = true,
  showAddress = true,
}) => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm<IEmployeeModal["data"]>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      code: "",
      doj: "",
      dob: "",
      fname: "",
      lname: "",
      mobile: "",
      email: "",
      membershipType: "",
      status: "ACTIVE",
      salaryInfo: {
        mode: "MONTHLY",
        salary: 0,
        basicSalary: 0,
        allowance: 0,
      },
      address: {
        address: "",
        country: "India",
        state: "",
        city: "",
        pinCode: "",
      },
      gender: "MALE",
      businessUnit: "",
      taxRegime: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);

  const [salaryModal, setSalaryModal] = useState(false);
  const [department, setDepartment] = useState<BusinessUnit>(); //Select business unit Api res

  const [details, setDetails] = useState<EmployeeInfo>({
    empId: id,
    code: "",
    doj: "",
    dob: "",
    name: "",
    mobile: "",
    email: "",
    membershipType: "",
    salaryInfo: {
      mode: "MONTHLY",
      salary: 0,
      basicSalary: 0,
      allowance: 0,
    },
    status: "ACTIVE",
    displayStatus: "",
    address: {
      address: "",
      country: "India",
      state: "",
      city: "",
      pinCode: "",
    },
    detaxCard: true,
    nps: true,
    businessUnit: "",
    taxRegime: "",
  });
  const [actionState, setActionState] = useState(false);
  const [busiUnit, setBusiUnit] = useState("");
  const [err, setErr] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  });

  useEffect(() => {
    const run = async () => {
      if (id) {
        setIsLoading(true);
        const result = await EmployeeService.getById(id);
        if (isSuccess(result)) {
          if (result.value.successful) {
            setDetails((prev) => (prev = result.value.data || prev));
            result.value.data && loadEmployee(result.value.data);
          }
        }
        setIsLoading(false);
      }
    };
    run();

    const run1 = async () => {
      if (id) {
        setIsLoading(true);
        const result = await EmployeeService.getBusinessUnit();
        if (isSuccess(result)) {
          if (result.value.successful) {
            console.log("test", result.value.data);
            setDepartment((prev) => (prev = result.value.data || prev));
            // result.value.data && loadEmployee(result.value.data);
          }
        }
        setIsLoading(false);
      }
    };
    run1();
    //localStorage.setItem(
    //   LocalStorageItemKeys.currentScreenRoute,
    //   location.pathname
    // );
  }, [id]);

  const closeModal = () => {
    setActionState(false);
    clearErrors();
    reset();
    setDetailsModal(false);
    setSalaryModal(false);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    data = {
      ...data,
      doj: format(new Date(data.doj), "dd-MM-yyyy"),
      dob: format(new Date(data.dob), "dd-MM-yyyy"),
      name: data.fname + " " + data.lname,
      businessUnit: busiUnit.length > 0 ? busiUnit : undefined,
      taxRegime: data.taxRegime,
    };
    delete data.fname;
    delete data.lname;
    setActionState(true);
    setErr({ show: false, message: "" });
    const result = await EmployeeService.update(data, id);
    console.log("update employee", result);
    setActionState(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setDetails((p) => ({ ...p, ...result.value.data }));
        closeModal();
        setShowSuccess(true);
      } else {
        setErr({
          show: true,
          message: result.value.message || "Something went wrong",
        });
      }
    } else if (isFailure(result)) {
      if (result.error.outcome === ServiceCallOutcome.ApplicationError) {
        if (result.error.httpStatus.Code === 400) {
          //console.log("update request: ", result.error.apiStatus);
          //Dispatch.App.setBadRequestError({showError: true, errorText: result.error.httpStatus.Data ? result.error.httpStatus.Data.message : ""});
        }
      }
      // check bad request and open popup
    }
  };

  const inputDate = (dt: string) => {
    let arr = dt.split("-");
    return `${arr[2]}-${arr[1]}-${arr[0]}`;
  };

  const fillData = () => {
    setValue("code", details.code || "");
    setValue("doj", details.doj ? inputDate(details.doj) : "");
    setValue("dob", details.dob ? inputDate(details.dob) : "");
    setValue("fname", getName(details?.name || "")[0] || "");
    setValue("lname", getName(details?.name || "")[1] || "");
    setValue("mobile", details.mobile || "");
    setValue("email", details.email || "");
    setValue("membershipType", details.membershipType || "");
    setValue("status", details.status || "ACTIVE");
    setValue("salaryInfo.mode", "MONTHLY");
    setValue("salaryInfo.salary", details.salaryInfo?.salary);
    setValue("salaryInfo.basicSalary", details.salaryInfo?.basicSalary);
    setValue("salaryInfo.allowance", details.salaryInfo?.allowance);
    setValue("address.address", details.address?.address || "");
    setValue("address.state", details.address?.state || "");
    setValue("address.city", details.address?.city || "");
    setValue("address.country", details.address?.country || "India");
    setValue("address.pinCode", details.address?.pinCode || "");
    setValue("gender", details.gender || "MALE");
    setValue("businessUnit", details.businessUnit || "");
    setValue("taxRegime", details.taxRegime || "");
  };

  const openDetailsModal = () => {
    setDetailsModal(true);
    fillData();
  };

  const fillSalaryData = () => {
    setSalaryModal(true);
    fillData();
  };

  const saveSalary: SubmitHandler<any> = async (data) => {
    setActionState(true);
    const result = await EmployeeService.updateSalary(
      {
        salary: parseFloat(data.salaryInfo.salary) || 0,
        basicSalary: parseFloat(data.salaryInfo.basicSalary) || 0,
        allowance: parseFloat(data.salaryInfo.allowance) || 0,
      },
      id
    );
    setActionState(false);
    if (isSuccess(result)) {
      if (result.value.successful) {
        setDetails((p) => ({ ...p, ...result.value.data }));
        closeModal();
        setShowSuccess(true);
      }
    }
  };

  useEffect(() => fixNumberInput(), [watch()]);

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <WhiteCard className="p-3">
        <div className="">
          <div className="row">
            <div className="col-11">
              <div className="row">
                <h4 className="f-14 text-secondary">Personal details</h4>
                {details.name && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Name </p>
                        <h5 className="font-14 mb-0">{details.name}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.mobile && (
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={PhoneIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Mobile No. </p>
                        <h5 className="font-14 mb-0">{details.mobile}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.email && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmailIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> E-mail </p>
                        <h5 className="font-14 mb-0">{details.email}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.gender && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={GenderIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Gender </p>
                        <h5 className="font-14 mb-0">{details.gender}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.membershipType && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={GenderIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Membership </p>
                        <h5 className="font-14 mb-0">
                          {details.membershipType == "detax"
                            ? "De'tax"
                            : "De'tax+"}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {/* )
              </div>
              <div className="row mt-3">
                {details.code && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpCodeIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Code </p>
                        <h5 className="font-14 mb-0">{details.code}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="col-12 col-md-3">
                    <div className="row">
                        <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                            src={EmpDesgIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                            />
                        </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Designation </p>
                        <h5 className="font-14 mb-0">
                            {" "}
                            Product Manager{" "}
                        </h5>
                        </div>
                    </div>
                </div> */}
                {details.displayStatus && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpStatusIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Status </p>
                        <h5 className="font-14 mb-0">
                          {details.displayStatus}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.dob && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={DOBIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Date Of Birth </p>
                        <h5 className="font-14 mb-0">{details.dob}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.doj && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={ActiveFromIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Date of Joining </p>
                        <h5 className="font-14 mb-0">{details.doj}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.taxRegime && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={TaxRegimeIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Tax Regime </p>
                        <h5 className="font-14 mb-0">
                          {details.taxRegime === "OLD_REGIME"
                            ? "Old Regime"
                            : "New Regime"}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.businessUnit && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={DepartmentIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Department </p>
                        <h5 className="font-14 mb-0">{details.businessUnit}</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {showAddress && (
                <div className="row mt-3">
                  <h4 className="f-14 text-secondary">Address details</h4>
                  {details.address?.country && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={CountryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Country </p>
                          <h5 className="font-14 mb-0">
                            {details.address?.country}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {details.address?.state && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={StateIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> State </p>
                          <h5 className="font-14 mb-0">
                            {details.address?.state}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {details.address?.city && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={CityIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> City </p>
                          <h5 className="font-14 mb-0">
                            {details.address?.city}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {details.address?.pinCode && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={PinCodeIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Pincode </p>
                          <h5 className="font-14 mb-0">
                            {details.address?.pinCode}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {details.address?.address && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={AddressIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Address </p>
                          <h5 className="font-14 mb-0">
                            {details.address?.address}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-1 d-flex justify-content-end">
              {edit && (
                <CardButton onClick={() => openDetailsModal()} icon={"edit"} />
              )}
            </div>
          </div>
        </div>
      </WhiteCard>

      {showSalary && (
        <WhiteCard className="p-3">
          <div className="">
            <h4 className="f-14 text-secondary">Salary details</h4>
            <div className="row">
              <div className="col-11">
                <div className="row">
                  {details.salaryInfo?.salary &&
                  details.salaryInfo?.salary > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={SalaryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Monthly Salary </p>
                          <h5 className="font-14 mb-0">
                            {currencyFormat(details.salaryInfo?.salary || 0)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {details.salaryInfo?.basicSalary &&
                  details.salaryInfo?.basicSalary > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={SalaryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Monthly Basic Salary </p>
                          <h5 className="font-14 mb-0">
                            {currencyFormat(
                              details.salaryInfo?.basicSalary || 0
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {details.salaryInfo?.allowance &&
                  details.salaryInfo?.allowance > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={SalaryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Monthly allowance </p>
                          <h5 className="font-14 mb-0">
                            {currencyFormat(details.salaryInfo?.allowance || 0)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-1 d-flex justify-content-end">
                {edit && (
                  <CardButton onClick={() => fillSalaryData()} icon={"edit"} />
                )}
              </div>
            </div>
          </div>
        </WhiteCard>
      )}

      {salaryModal && edit && (
        <EmployeeSalaryForm
          title="Update Salary"
          // modal={salaryModal}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          onSubmit={saveSalary}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          actionState={actionState}
        />
      )}

      {detailsModal && edit && (
        <SaveEmployeeForm
          title="Edit Employee"
          department={department?.businessUnit}
          busiUnit={busiUnit}
          setBusiUnit={(val: string) => setBusiUnit(val)}
          // modal={modal}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          actionState={actionState}
          isEditable
        />
      )}
      <SuccessPopup
        message={`Updated successfully!`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />

      <ErrorPopup
        message={err.message}
        show={err.show}
        onClose={(flag) => {
          setErr({ show: false, message: "" });
          //setShowError(flag);
        }}
      />
    </>
  );
};

export default EmployeeDetails;
