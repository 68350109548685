import "./custom-search.scss";

const SearchIcon = "/images/icons/search.svg";
type TCustomSearchBar = {
  width?: string;
  height?: string;
  display?: string;
  boxShadow?: string;
  background?: string;
  text?: string;
  value?: string;
  onChange?: any;
  search?: boolean;
  setSearch?: (val: boolean) => void;
};

const CustomSearchBar: React.FC<TCustomSearchBar> = ({
  width,
  height,
  display,
  boxShadow,
  background,
  text = "Search...",
  value = "",
  onChange = () => {},
  search,
  setSearch = () => {},
}) => {
  return (
    <div
      id="custom-search"
      style={{
        boxShadow: boxShadow,
        background: background,
        display: display,
        height: height,
        width: width,
      }}
    >
      <button type="submit">
        <img
          src={process.env.PUBLIC_URL + SearchIcon}
          alt="search icon"
          width="18"
          height="18"
        />
      </button>{" "}
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="search"
        placeholder={text}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearch(!search);
          }
        }}
      />
    </div>
  );
};
export default CustomSearchBar;
