export const currencyFormat = (value: number = 0) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);

export const availableLimit = (maxLimit: number = 0, used: number = 0) => {
  const usedAmount = maxLimit - used;
  return usedAmount;
  // new Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "INR",
  // }).format(usedAmount);
};
