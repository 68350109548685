import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import { recipient } from "../components/pages/digital-contract.page";
import { TBrReceipient } from "../types/br-receipient.type";
import { TCorporateUser } from "../types/corporate-user.type";

export const receipientToSignatory = (rec: TBrReceipient[]) => {
  const filteredSignatories = rec
    .filter(
      (r) =>
        pipe(
          r.type,
          getOrElse(() => "")
        ) === "SIGNATORY"
    )
    .map((r) => {
      return {
        userId: pipe(
          r.userId,
          getOrElse(() => "")
        ),
        name: pipe(
          r.name,
          getOrElse(() => "")
        ),
        email: pipe(
          r.email,
          getOrElse(() => "")
        ),
        mobile: pipe(
          r.mobile,
          getOrElse(() => "")
        ),
      };
    });

  return filteredSignatories.length ? filteredSignatories : [recipient];
};

export const receipientToDirector = (
  rec: TBrReceipient[]
): TCorporateUser[] => {
  return rec
    .filter(
      (r) =>
        pipe(
          r.type,
          getOrElse(() => "")
        ) === "DIRECTOR"
    )
    .map((r) => {
      return {
        userId: r.userId,
        name: r.name,
        email: r.email,
        mobile: r.mobile,
      };
    });
};
