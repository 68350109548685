import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NpsSubscriptionCountInfo } from "../../../../api/detax/model/npsSubscriptionCountInfo";

import { setDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { RoutePaths } from "../../../../utils/route.utils";
import CusomtomGraphs from "../../../graph/graph";
import WhiteCard from "../../../white-card/white-card";

const DashboardNps: React.FC<{
  data?: NpsSubscriptionCountInfo[] | [];
}> = ({ data = [] }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let totalCount = () => {
    let total = 0;
    if (data.length > 0) {
      data.map((item) => {
        total = total + (item?.count || 0);
      });
    }
    return total;
  };

  const colorCodes = (status: NpsSubscriptionCountInfo.StatusEnum | null) => {
    if (status != null) {
      if (status === "ACCEPTANCE_PENDING") return "#dcf6ff";
      else if (status === "ACTIVATED") return "#dcffe8";
      else if (status === "DEACTIVATED") return "#ebcfee";
      else if (status === "CANCELLED") return "#f98464";
    } else {
      return "#fff";
    }
  };
  return (
    <WhiteCard>
      <h4 className="page-sub-heading"> NPS</h4>
      <div className="row col-row-auto g-1">
        {data.length > 0 ? (
          <>
            <div className="col">
              <div className="card border-light h-100 text-center p-1 d-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                >
                  <defs>
                    <filter
                      id="Rectangle_651"
                      x="0"
                      y="0"
                      width="80"
                      height="80"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="5" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.161" result="color" />
                      <feComposite
                        operator="out"
                        in="SourceGraphic"
                        in2="blur"
                      />
                      <feComposite operator="in" in="color" />
                      <feComposite operator="in" in2="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="Group_2835"
                    data-name="Group 2835"
                    transform="translate(-242 -287)"
                  >
                    <g data-type="innerShadowGroup">
                      <rect
                        id="Rectangle_651-2"
                        data-name="Rectangle 651"
                        width="80"
                        height="80"
                        rx="40"
                        transform="translate(242 287)"
                        fill="#dcf6ff"
                      />
                      <g
                        transform="matrix(1, 0, 0, 1, 242, 287)"
                        filter="url(#Rectangle_651)"
                      >
                        <rect
                          id="Rectangle_651-3"
                          data-name="Rectangle 651"
                          width="80"
                          height="80"
                          rx="40"
                          fill="#fff"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_2816"
                      data-name="Group 2816"
                      transform="translate(-1376.798 -3078.693)"
                    >
                      <path
                        id="Path_6884"
                        data-name="Path 6884"
                        d="M1687.174,3382.962a9.1,9.1,0,0,1,4.812,3.448.95.95,0,0,0,.386.26c2.374,1.142,3.324,3.16,3.473,5.66a7.38,7.38,0,0,1-.047,1.344,5.278,5.278,0,0,0,.373,3.328,4.36,4.36,0,0,1-1.909,5.5,2.634,2.634,0,0,0-.818,1.123,12.47,12.47,0,0,1-4.311,5.181,7.64,7.64,0,0,1-10.391-1.432,41.015,41.015,0,0,1-2.755-4.02,2.3,2.3,0,0,0-.642-.772,4.289,4.289,0,0,1-2.4-4.656,3.057,3.057,0,0,1,1.175-2.225,1.285,1.285,0,0,0,.385-.9c.274-6.236,3.684-9.924,9.459-11.71.05-.015.082-.088.122-.134Zm2.226,7.112c-3.507,3.788-7.978,4.789-12.837,4.826-.33,4.589.891,8.528,4.241,11.634a5.621,5.621,0,0,0,7.837.1,11.153,11.153,0,0,0,3.516-5.279,11.04,11.04,0,0,0,.862-4.555A12.112,12.112,0,0,0,1689.4,3390.073Z"
                        transform="translate(-25.928)"
                        fill="#40a6c9"
                      />
                      <path
                        id="Path_6885"
                        data-name="Path 6885"
                        d="M1642.365,3538.863a3.032,3.032,0,0,1-1.578-3.105c.145-2.36-.1-4.766.248-7.087.655-4.322,3.466-6.957,7.52-8.235,3.483-1.1,3.51-1.017,6.323,1.318.737.611,1.466,1.232,2.159,1.815-.221.848-.436,1.642-.634,2.44-.321,1.293-.179,1.591,1.089,2.231l-1.834,10.624Z"
                        transform="translate(0 -110.439)"
                        fill="#40a6c9"
                      />
                      <path
                        id="Path_6886"
                        data-name="Path 6886"
                        d="M1742.885,3538.283q-.675-3.628-1.349-7.256c-.207-1.127-.4-2.257-.595-3.367,1.483-.672,1.644-1.056.419-4.643,1.484-1.234,2.979-2.487,4.49-3.72a.979.979,0,0,1,.675-.257c2.9.589,5.77,1.271,7.952,3.514a10.139,10.139,0,0,1,3.062,7.333c0,1.862-.05,3.726.017,5.586a2.694,2.694,0,0,1-1.571,2.81Z"
                        transform="translate(-80.869 -109.86)"
                        fill="#40a6c9"
                      />
                    </g>
                  </g>
                </svg>
                <h2 className="pt-1 h4 mb-0"> {totalCount()}</h2>
                <p className="mb-0">Eligible User</p>
              </div>
            </div>

            {data.map((item, key) => (
              <div key={key} className="col">
                <div
                  onClick={() => {
                    dispatch(
                      setDashboardRedirect({
                        page: RoutePaths.DashboardNps,
                        card: item?.status,
                      })
                    );
                    navigate(RoutePaths.DashboardNps);
                  }}
                  style={{ cursor: "pointer" }}
                  className="card border-light h-100 text-center py-2 d-block"
                >
                  <CusomtomGraphs
                    color={colorCodes(item?.status || null)}
                    totalpercentage={Number(
                      ((Number(item.count) / totalCount()) * 100).toFixed(2)
                    )}
                  />
                  <h2 className="pt-1 h3">{item.count}</h2>
                  <p className="mb-0">{item.label}</p>
                </div>
              </div>
            ))}
            {/* <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div> */}
          </>
        ) : null}
      </div>
    </WhiteCard>
  );
};

export default DashboardNps;
