import { useState } from "react";
import { Button } from "react-bootstrap";
import { useAppSelector } from "../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import Heading from "../common/heading";
import NpsExport from "./nps-export";
import NpsInvitation from "./nps-invitation";
import NpsStatusCount from "./nps-status-count";
import { Npssubscribed } from "./nps-subscribed";
import NpsTable from "./nps-table";
import "./nps.scss";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../../utils/route.utils";

const DashboardNps = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState("");
  const [reload, setReload] = useState(true);
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  const npsStatus = useAppSelector(AlphaReduxStore.selectNpsStatus);
  const [remapState, setRemapState] = useState(false);
  /* useEffect(() => {
    setReload(true)
  }, [curTbl]); */
  console.log("Testsss", curTbl);
  return (
    <>
      {npsStatus && (
        <>
          <PageTitle title="NPS" />
          <Heading title="NPS" />
          <WhiteCard className="my-4 p-1 rounded">
            <div className="d-flex justify-content-between">
              <CustomSearchBar
                value={searchVal}
                onChange={(val: string) => setSearchVal(val)}
                boxShadow="none"
                height="auto"
                width="70%"
                text="Search by Employee Name / Email / Mobile"
                search={search}
                setSearch={(val: boolean) => setSearch(val)}
              />
              <div className="d-flex align-items-center">
                <Button
                  className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                  onClick={() => setSearch(!search)}
                >
                  Search
                </Button>

                <Button
                  className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                  onClick={() => setShow(true)}
                >
                  Send Invitation
                </Button>
                <Button
                  className="btn-gradiant-blue btn-lg text-white fs-small m-1"
                  onClick={() => {
                    navigate(RoutePaths.DashboardNpsBulkUpload);
                  }}
                >
                  PRAN Migration
                </Button>
                <NpsInvitation
                  show={show}
                  closeModal={() => setShow(false)}
                  setInviteFlag={(flag) => setReload(flag)}
                />
              </div>
            </div>
          </WhiteCard>

          <PageData
            setTbl={(val) => setCurTbl(val)}
            searchVal={searchVal}
            reload={reload}
            setReload={(val: boolean) => setReload(val)}
            search={search}
            setRemapState={(val: boolean) => setRemapState(val)}
            remapState={remapState}
          />
        </>
      )}
      {!npsStatus && <Npssubscribed />}
    </>
  );
};
export default DashboardNps;

const PageData: React.FC<{
  search: boolean;
  searchVal?: string;
  reload?: boolean;
  setReload: (flag: boolean) => void;
  setTbl: (tbl: TStatusCountInfo) => void;
  setRemapState: (val: boolean) => void;
  remapState: boolean;
}> = ({
  search,
  searchVal = "",
  reload = false,
  setReload,
  setTbl = () => {},
  setRemapState,
  remapState,
}) => {
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  return (
    <>
      <NpsStatusCount
        setCurTbl={(data) => {
          setCurTbl(data);
          setTbl(data);
        }}
        curTbl={curTbl}
        reload={reload}
        setReload={(flag) => setReload(flag)}
        setRemapState={(val: boolean) => setRemapState(val)}
        remapState={remapState}
      />
      {/* {curTbl && ( */}
      {curTbl?.status === "PRAN_REMAPPING" && (curTbl?.count || 0) > 0 && (
        <NpsExport />
      )}
      <NpsTable
        searchVal={searchVal}
        curTbl={curTbl}
        search={search}
        reload={reload}
        setReload={(flag) => setReload(flag)}
        setCurTbl={(data) => {
          setCurTbl(data);
          setTbl(data);
        }}
        setRemapState={(val: boolean) => setRemapState(val)}
        remapState={remapState}
      />
      {/* )} */}
    </>
  );
};
