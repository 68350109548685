import { remoteData } from "@devexperts/remote-data-ts";
import { NcashFIUProfileSummaryTransactionAPIsService } from "../api/account-aggregator/api/ncashFIUProfileSummaryTransactionAPIs.service";
import { FiuConsentResponse } from "../api/account-aggregator/model/fiuConsentResponse";
import { FiuInitiateResponse } from "../api/account-aggregator/model/fiuInitiateResponse";
import { FiuProfileSummaryApiResponse } from "../api/account-aggregator/model/fiuProfileSummaryApiResponse";
import { FiuTransactionApiResponse } from "../api/account-aggregator/model/fiuTransactionApiResponse";
import { FiuTransactionGraphApiResponse } from "../api/account-aggregator/model/fiuTransactionGraphApiResponse";

export namespace AAService {

  export const initiateFiuRequest = async () => {
    const result =
      await NcashFIUProfileSummaryTransactionAPIsService.initiateFiuRequest();
    return remoteData.map(
      result,
      (response: FiuInitiateResponse) => response
    );
  };

  export const getFiuAccountTransactions = async (
    startDate?: string,
    endDate?: string,
    transactionType?: string,
  ) => {
    const result =
      await NcashFIUProfileSummaryTransactionAPIsService.getFiuAccountTransactions(startDate = "", endDate = "", transactionType = "");
    return remoteData.map(
      result,
      (response: FiuTransactionApiResponse) => response
    );
  };

  export const getFIUConsents = async (userId?: string,) => {
    const result =
      await NcashFIUProfileSummaryTransactionAPIsService.getFIUConsents(userId);
    return remoteData.map(
      result,
      (response: FiuConsentResponse) => response
    );
  };
  export const getFIUProfileSummary = async () => {
    const result =
      await NcashFIUProfileSummaryTransactionAPIsService.getFIUProfileSummary();
    return remoteData.map(
      result,
      (response: FiuProfileSummaryApiResponse) => response
    );
  };
  export const getFiuAccountTransactionsGraphData = async (startDate?: string,
    endDate?: string,) => {
    const result =
      await NcashFIUProfileSummaryTransactionAPIsService.getFiuAccountTransactionsGraphData(startDate = '', endDate = '');
    return remoteData.map(
      result,
      (response: FiuTransactionGraphApiResponse) => response
    );
  };
}
