import React from "react";
import { Stage } from "../../../../../api/detax/model/stage";

const TransactionTimeline: React.FC<{ stages?: Array<Stage> }> = ({
  stages = [],
}) => {
  return (
    <>
      {
        stages.length > 0 ? (
          <div className="card p-2">
            <div className="card-body">
              <h5 className="card-title">Timeline</h5>
              
              {stages.map((stage, index) => (
                <div
                  key={index}
                  className={
                    stage.color === "GREEN"
                      ? "step completed"
                      : stage.color === "RED"
                      ? "step rejected"
                      : "step"
                  }
                >
                  <div className="d-flex gap-2 w-100" aria-current="true">
                    <div className="d-flex gap-2 w-100 justify-content-between">
                      <div>
                        <h6 className="mb-0 text-capitalize">
                          {stage.displayName?.replace(/^\//, "").toLowerCase()}
                        </h6>
                        <p className="mb-0 opacity-75">{stage.executedAt}</p>
                      </div>
                    </div>
                  </div>
                  <div className="v-stepper">
                    <div className="circle"></div>
                    <div className="line"></div>
                  </div>
                </div>
              ))}







              {/* Other comments */}

              {/* <div className="step active">
            <div className="d-flex gap-2 w-100" aria-current="true">
              <img
                src="https://media.istockphoto.com/photos/middle-aged-man-with-pleasant-face-expression-picture-id1005817658?b=1&k=20&m=1005817658&s=170667a&w=0&h=tpil3FJm2k6CHWiVczRjC-dBR8kP1ZckPMVETYX-89g="
                alt="twbs"
                width="32"
                height="32"
                className="rounded-circle flex-shrink-0"
              />
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Active</h6>
                  <p className="mb-0 opacity-75">16, mar,2022 02:30pm</p>
                </div>
              </div>
            </div>
            <div className="v-stepper">
              <div className="circle"></div>
              <div className="line"></div>
            </div>
          </div>

          <div className="step">
            <div className="d-flex gap-2 w-100" aria-current="true">
              <img
                src="https://media.istockphoto.com/photos/middle-aged-man-with-pleasant-face-expression-picture-id1005817658?b=1&k=20&m=1005817658&s=170667a&w=0&h=tpil3FJm2k6CHWiVczRjC-dBR8kP1ZckPMVETYX-89g="
                alt="twbs"
                width="32"
                height="32"
                className="rounded-circle flex-shrink-0"
              />
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">To be </h6>
                  <p className="mb-0 opacity-75">16, mar,2022 02:30pm</p>
                </div>
              </div>
            </div>
            <div className="v-stepper">
              <div className="circle"></div>
              <div className="line"></div>
            </div>
          </div>

          <div className="step">
            <div className="d-flex gap-2 w-100" aria-current="true">
              <img
                src="https://media.istockphoto.com/photos/middle-aged-man-with-pleasant-face-expression-picture-id1005817658?b=1&k=20&m=1005817658&s=170667a&w=0&h=tpil3FJm2k6CHWiVczRjC-dBR8kP1ZckPMVETYX-89g="
                alt="twbs"
                width="32"
                height="32"
                className="rounded-circle flex-shrink-0"
              />
              <div className="d-flex gap-2 w-100 justify-content-between">
                <div>
                  <h6 className="mb-0">Intiated</h6>
                  <p className="mb-0 opacity-75">16, mar,2022 02:30pm</p>
                </div>
              </div>
            </div>
            <div className="v-stepper">
              <div className="circle"></div>
              <div className="line"></div>
            </div>
          </div> */}
            </div>
          </div>
        ) : (
          " "
        )
        //(
        // <img
        //  // src={process.env.PUBLIC_URL + "/images/No-data-found.png"}
        //  // className="img-fluid"
        //   //alt="no data found"
        //   //height="150"
        // />
        // <></>
        // )
      }
    </>
  );
};

export default TransactionTimeline;
