//claimCount

import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishDetaxReportApiService } from "../api/detax/api/ncaishDetaxReportApi.service";

import { NcaishTransactionApiService } from "../api/detax/api/ncaishTransactionApi.service";
import { NcaishTransactionBatchApiService } from "../api/detax/api/ncaishTransactionBatchApi.service";
import { CardTransactionDetailResponse } from "../api/detax/model/cardTransactionDetailResponse";

import { DetaxBatchCountApiResponse } from "../api/detax/model/detaxBatchCountApiResponse";
import { DetaxCardTransactionApprovalRequest } from "../api/detax/model/detaxCardTransactionApprovalRequest";
import { ReportListApiResponse } from "../api/detax/model/reportListApiResponse";
import { TransactionApprovalStatusWiseCountApiResponse } from "../api/detax/model/transactionApprovalStatusWiseCountApiResponse";
import { TransactionHistoryResponse } from "../api/detax/model/transactionHistoryResponse";
import { BaseResponse } from "../model/baseResponse";
import { CorpWalletTxnResponse } from "../api/detax/model/corpWalletTxnResponse";
import { Download } from "../state/slice/reports.slice";

export namespace TransactionService {
  export const transactionApprovalStatusCounts = async (
    from?: string,
    to?: string,
    uid?: string,
    type?: string
  ) => {
    const result = await NcaishTransactionApiService.approvalStatusCounts(
      from,
      to,
      uid,
      type
    );
    return remoteData.map(
      result,
      (response: TransactionApprovalStatusWiseCountApiResponse) => response
    );
  };

  export const getUserTransactions = async (
    uid: string,
    catId?: string,
    cardId?: string,
    from?: string,
    to?: string,
    type?: string,
    wallet?: string,
    appStatus?: Array<string>,
    pn?: number,
    rc?: number
  ) => {
    const result = await NcaishTransactionApiService.getTransactions(
      uid,
      catId,
      cardId,
      from,
      to,
      type,
      wallet,
      appStatus,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: TransactionHistoryResponse) => response
    );
  };

  export const moveTxnFbaToTa = async (uid: string, rrn: string) => {
    const result = await NcaishTransactionApiService.moveTxnFbaToTa(uid, rrn);
    return remoteData.map(
      result,
      (response: TransactionHistoryResponse) => response
    );
  };

  export const getDetaxBatchCounts = async (type: string) => {
    const result =
      type === "NPS"
        ? await NcaishTransactionBatchApiService.getCorporateDetaxBatchCountsNPS(
            type.toString()
          )
        : await NcaishTransactionBatchApiService.getCorporateDetaxBatchCounts(
            type.toString()
          );
    return remoteData.map(
      result,
      (response: DetaxBatchCountApiResponse) => response
    );
  };

  export const getTransactionDetails = async (txnId: string) => {
    const result = await NcaishTransactionApiService.getTransactionDetails(
      txnId
    );
    return remoteData.map(
      result,
      (response: CardTransactionDetailResponse) => response
    );
  };

  export const transactionApproval = async (
    body: DetaxCardTransactionApprovalRequest,
    txnId: string,
    cid: string
  ) => {
    const result =
      await NcaishTransactionApiService.corporateAdminRransactionApproval(
        body,
        txnId
      );
    return remoteData.map(
      result,
      (response: CardTransactionDetailResponse) => response
    );
  };

  export const getDownloadFbaUtilisationReport = async (
    from: string,
    to: string
  ) => {
    const result =
      await NcaishDetaxReportApiService.getFbaUtilisationReportDownload(
        "",
        from,
        to,
        { responseType: "blob" }
      );
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getDownloadFbaUtilisationReportBlob = async (
    from: string,
    to: string
  ) => {
    const result =
      await NcaishDetaxReportApiService.getFbaUtilisationReportDownloadBlob(
        "",
        from,
        to,
        { responseType: "blob" }
      );
    return remoteData.map(result, (response: Blob) => response);
  };

  export const getDownloadFbaCategoryReport = async (
    from: string,
    to: string
  ) => {
    const result =
      await NcaishDetaxReportApiService.getFbaCategoryReportDownload(
        "",
        from,
        to,
        { responseType: "blob" }
      );
    return remoteData.map(result, (response: Blob) => response);
  };

  export const getReportStatus = async () => {
    const result = await NcaishDetaxReportApiService.getReportStatus();
    return remoteData.map(result, (response: Download) => response);
  };

  export const getFormReport = async (
    formType: string,
    from: string,
    to: string
  ) => {
    const result = await NcaishDetaxReportApiService.getFormReport(
      formType,
      from,
      to
    );
    return remoteData.map(result, (response: BaseResponse) => response);
  };

  export const getFormReportBlob = async (
    formType: string,
    from: string,
    to: string
  ) => {
    const result = await NcaishDetaxReportApiService.getFormReportBlob(
      formType,
      from,
      to
    );
    return remoteData.map(result, (response: Blob) => response);
  };

  export const getDownloadTransactionReport = async (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: string,
    to: string,
    typestr: string
  ) => {
    const result =
      await NcaishDetaxReportApiService.getTransactionReportDownload(
        uidstr,
        categorystr,
        walletstr,
        approvalstr,
        from,
        to,
        typestr

        // { responseType: 'arraybuffer' }
      );
    return remoteData.map(result, (response: ArrayBuffer) => response);
  };

  export const getDownloadTransactionReportCsv = async (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: string,
    to: string,
    typestr: string
  ) => {
    const result =
      await NcaishDetaxReportApiService.getTransactionReportDownloadCsv(
        uidstr,
        categorystr,
        walletstr,
        approvalstr,
        from,
        to,
        typestr

        // { responseType: 'arraybuffer' }
      );
    return remoteData.map(result, (response: ArrayBuffer) => response);
  };

  export const getRecentReports = async (year?: string) => {
    const result = await NcaishTransactionApiService.getRecentReports(year);
    return remoteData.map(
      result,
      (response: ReportListApiResponse) => response
    );
  };
  export const downloadReportWithPath = async (url: string) => {
    const result = await NcaishTransactionApiService.downloadReportWithPath(
      url
    );
    return remoteData.map(result, (response: string) => response);
  };

  export const getCorpTransactions = async (
    cardId: string,

    type: string,
    wallet: string,
    transferType: string,
    txnStatus: Array<string>,
    from: string,
    to: string,
    pn: number,
    rc: number
  ) => {
    const result = await NcaishTransactionApiService.getCorpTransactions(
      cardId,

      type,
      wallet,
      transferType,
      txnStatus,
      from,
      to,
      pn,
      rc
    );
    return remoteData.map(
      result,
      (response: CorpWalletTxnResponse) => response
    );
  };
}
