import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "././reject-popup.scss";

const RejectPopup: React.FC<{
  actionState: boolean;
  show: boolean;
  onHide: (show: boolean) => void;
  onReject: (remark: string) => void;
}> = ({ actionState,  show, onHide, onReject }) => {
  const [remark, setRemark] = useState("");
  
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header className="bg-danger d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <Modal.Title className="text-light">Confirmation</Modal.Title>
        <p className="modal-title text-white">Are you sure to Reject?</p>
      </Modal.Header>
      <Modal.Body className="text-light fw-bold text-center">
        <textarea
          className="form-control"
          rows={3}
          placeholder="Remarks"
          onChange={(e) => setRemark(e.target.value)}
          value={remark}
        ></textarea>
      </Modal.Body>
      <Modal.Footer className=" d-flex justify-content-center">
        <Button
          disabled={actionState}
          className="btn-gradiant-gray"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Close
        </Button>
        <Button
          disabled={remark.length === 0 || actionState}
          variant="danger"
          onClick={() => {
  
            onReject(remark);
          }}
        >
          {actionState && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></span>
          )}
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectPopup;
