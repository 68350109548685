import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  validateEmail,
  validateMobile,
  validateName,
  validatePRAN,
} from "../../../../utils/common.utils";
import Select from "react-select";
interface IData {
  sno: string;
  uid: string;
  code: string;
  name: string;
  email: string;
  mobile: string;
  amount: string;
  error: string[];
}

const BulkCreateBatchModal: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  close: () => void;
}> = ({ data, setData, close }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<IData>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: data.name,
      sno: data.sno,
      code: data.code,
      uid: data.uid,
      email: data.email,
      mobile: data.mobile,
      amount: data.amount,
      error: [],
    },
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => setIsLoaded(true), []);

  const closeModal = () => {
    close();
    setValue("name", "");
    setValue("sno", "");
    setValue("code", "");
    setValue("mobile", "");
    setValue("uid", "");
    setValue("email", "");
    setValue("amount", "");
  };
  const onSubmit: SubmitHandler<IData> = (data) => {
    setData({ ...data });
  };

  return (
    <>
      {isLoaded ? (
        <Modal show={true} onHide={() => closeModal()} centered>
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title className=" fs-5 fw-bold">
                  Edit Batch Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <p className="fw-bold">
                  Provide details of the employee as following
                </p> */}
                <div className="row d-flex justify-content-center">
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="" className="text-muted">
                      Employee Code
                    </label>
                    <input
                      {...register("code", {
                        required: "Employee Code is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.code?.message,
                      })}
                      disabled
                    />
                    <div className="invalid-feedback">
                      {errors?.code?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="fname" className="text-muted">
                      Employee Name
                    </label>
                    <input
                      {...register("name", {
                        required: "Name is required!",
                        validate: (val: string) =>
                          validateName(val) || "Invalid name!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.name?.message,
                      })}
                      disabled
                    />
                    <div className="invalid-feedback">
                      {errors?.name?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="" className="text-muted">
                      Email ID
                    </label>
                    <input
                      {...register("email", {
                        required: "Email ID is required!",
                        validate: (val: string) =>
                          validateEmail(val) || "Invalid email ID!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.email?.message,
                      })}
                      disabled
                    />
                    <div className="invalid-feedback">
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="" className="text-muted">
                      Mobile No.
                    </label>
                    <input
                      {...register("mobile", {
                        required: "Mobile No. is required!",
                        validate: (val: string) =>
                          validateMobile(val) || "Invalid Mobile No.!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.mobile?.message,
                      })}
                      disabled
                    />
                    <div className="invalid-feedback">
                      {errors?.mobile?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="salary" className="text-muted">
                      FBA Amount
                    </label>
                    <input
                      // defaultValue={modal.data?.salary}
                      {...register("amount", {
                        required: "Fba Amount is required!",
                        validate: (val: string) =>
                          parseFloat(val.toString()) >= 0 ||
                          "Fba Amount is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.amount?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.amount?.message}
                    </div>
                  </div>
                  <div
                    className="form-group col-md-6 mb-3"
                    style={{ visibility: "hidden" }}
                  >
                    <label htmlFor="fname" className="text-muted">
                      S No.
                    </label>
                    <input
                      {...register("sno", {
                        required: "Name is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.sno?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.sno?.message}
                    </div>
                  </div>
                </div>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className=" px-3 py-2 btn bg-dark bg-opacity-10 border-0"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="primary-bg btn text-white border-0 px-3 py-2"
                >
                  Submit
                </button>
              </Modal.Footer>
            </form>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default BulkCreateBatchModal;
