import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { none, Option } from "fp-ts/lib/Option";
import { State } from "../../state/store";
import { TCorporateInfo } from "../../types/corporate-info.type";

export interface CompanyState {
  companyInfo: Option<TCorporateInfo>;
}

const initialState: CompanyState = {
  companyInfo: none,
};

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    setCompanyInfo: (
      state,
      { payload: company }: PayloadAction<Option<TCorporateInfo>>
    ) => {
      state.companyInfo = company;
    },
  },
});

export const { setCompanyInfo } = slice.actions;

export const selectCompanyInfo = (state: State) => state.company.companyInfo;

export default slice.reducer;
