import { pipe } from "fp-ts/lib/function";
import { fromNullable, getOrElse } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";
import { TCorporateUser } from "../../../types/corporate-user.type";
import {
  fromPublic,
  validateEmail,
  validateMobile,
} from "../../../utils/common.utils";
import {
  IDirectorErr,
  IErrVal,
  ISegnatory,
} from "../../pages/digital-contract.page";
import { selectDirectors } from "../contract.slice";
import DirectorForm from "./director-form";
import DirectorHeader from "./director-header";
import DirectorRow from "./director-row";

const User = fromPublic("/images/user.svg");
const Group = fromPublic("/images/icons/group-1.svg");
const ArrowUp = fromPublic("images/icons/arrow-up.png");
const ArrowDown = fromPublic("images/icons/arrow-down-square.svg");

interface IProps {
  devicetype: string;
  selectedDirectors: TCorporateUser[];
  setSelectedDirector: (director: TCorporateUser[]) => void;
  errors: IDirectorErr;
  setNoRecipientErr: (val: boolean) => void;
  selectedSignatories: ISegnatory[];
  maxRecipientErr: boolean;
  setMaxRecipientErr: (flag: boolean) => void;
}

const DirectorDropdown: React.FC<IProps> = ({
  devicetype = "",
  selectedDirectors,
  setSelectedDirector,
  errors,
  setNoRecipientErr,
  selectedSignatories,
  maxRecipientErr,
  setMaxRecipientErr
}) => {
  const directors = useAppSelector(selectDirectors);
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState<IDirectorErr>(errors);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  useEffect(() => {
    checkAllErrs();
  }, [selectedDirectors, selectedSignatories]);

  const checkAllErrs = () => {
    chkDuplicateEmail();
    chkDuplicateMobile();
    chkMaxRecipients();
    setNoRecipientErr(false);
  };

  const chkDuplicateEmail = () => {
    let sEmails = selectedSignatories
      .filter((i) => i.email != "")
      .map((i) => i.email);
    let dEmails = selectedDirectors.map((i) =>
      pipe(
        i.email,
        getOrElse(() => "")
      )
    );

    let newEmails: string[] = [...sEmails, ...dEmails].filter((i) => i);

    let emailErrors: IErrVal[] = formErrors.email.filter(
      (a) => a.message != "Email ID has already entered"
    );
    dEmails.map((i, k) => {
      if (newEmails.filter((i1) => i1 == i).length > 1) {
        if (!sEmails.includes(i)) {
          if (k === dEmails.lastIndexOf(i))
            if (emailErrors.filter((a, b) => a.index == k).length == 0)
              emailErrors.push({
                index: k,
                message: "Email ID has already entered",
              });
            else
              emailErrors = emailErrors.map((a, b) => {
                if (a.index == k) a.message = "Email ID has already entered";
                return a;
              });
        }
      }
      /* else {
        if (
          emailErrors.filter(
            (a, b) =>
              a.index == k && a.message == "Email ID has already entered"
          ).length > 0
        ) {
          emailErrors = emailErrors.filter((a, b) => a.index != k);
        }
      } */
    });
    setFormErrors((prev) => ({ ...prev, email: emailErrors }));
  };

  const chkDuplicateMobile = () => {
    let sMobiles = selectedSignatories
      .filter((i) => i.mobile != "")
      .map((i) => i.mobile);
    let dMobiles = selectedDirectors.map((i) =>
      pipe(
        i.mobile,
        getOrElse(() => "")
      )
    );

    let newMobiles: string[] = [...sMobiles, ...dMobiles].filter((i) => i);

    let mobileErrors: IErrVal[] = formErrors.mobile.filter(
      (a) => a.message != "Mobile no. has already entered"
    );
    dMobiles.map((i, k) => {
      if (newMobiles.filter((i1) => i1 == i).length > 1) {
        if (!sMobiles.includes(i)) {
          if (k === dMobiles.lastIndexOf(i))
            if (mobileErrors.filter((a, b) => a.index == k).length == 0)
              mobileErrors.push({
                index: k,
                message: "Mobile no. has already entered",
              });
            else
              mobileErrors = mobileErrors.map((a, b) => {
                if (a.index == k) a.message = "Mobile no. has already entered";
                return a;
              });
        }
      }
    });
    setFormErrors((prev) => ({ ...prev, mobile: mobileErrors }));
  };

  const chkMaxRecipients = () => {
    let flag = false;
    if (selectedDirectors.length + selectedSignatories.length >= 3) {
      flag = true;
    }
    setMaxRecipientErr(flag);
    return flag;
  };

  const handleDirectorSelect = (director: TCorporateUser) => {
    if (!chkMaxRecipients())
      setSelectedDirector([...selectedDirectors, director]);
  };

  const handleDirectorUnSelect = (userId: string) => {
    let index = -1;
    selectedDirectors.forEach((i, k) => {
      if (
        userId ==
        pipe(
          i.userId,
          getOrElse(() => "")
        )
      )
        index = k;
    });
    if (index > -1)
      setFormErrors(() => ({
        email: formErrors.email.filter((i) => i.index != index),
        mobile: formErrors.mobile.filter((i) => i.index != index),
      }));

    const newSelectedDirectors = [...selectedDirectors];
    setSelectedDirector(
      newSelectedDirectors.filter(
        (d) =>
          pipe(
            d.userId,
            getOrElse(() => "")
          ) !== userId
      )
    );
  };

  const onBlur = (name: string, val: string, director: TCorporateUser) => {
    const index = selectedDirectors.findIndex(
      (d) =>
        pipe(
          d.userId,
          getOrElse(() => "")
        ) ===
        pipe(
          director.userId,
          getOrElse(() => "")
        )
    );
    if (name === 'email') {
      let emailErrors = formErrors.email.filter((i, k) => i.index != index);
      if (val == "")
        emailErrors.push({ index, message: "Email ID is required!" });
      else if (!validateEmail(val))
        emailErrors.push({ index, message: "Invalid email ID" });
      setFormErrors((prev) => ({ ...prev, email: emailErrors }));
    } else if (name === 'mobile') {
      let mobileErrors = formErrors.mobile.filter((i, k) => i.index != index);
      if (val == "")
        mobileErrors.push({ index, message: "Mobile no. is required!" });
      else if (!validateMobile(val))
        mobileErrors.push({ index, message: "Invalid mobile no." });
      setFormErrors((prev) => ({ ...prev, mobile: mobileErrors }));
    }
  }

  const handleEmailChange = (email: string, director: TCorporateUser) => {
    const index = selectedDirectors.findIndex(
      (d) =>
        pipe(
          d.userId,
          getOrElse(() => "")
        ) ===
        pipe(
          director.userId,
          getOrElse(() => "")
        )
    );
    if (index !== -1) {
      const dir = { ...selectedDirectors[index], email: fromNullable(email) };
      selectedDirectors[index] = dir;
      setSelectedDirector([...selectedDirectors]);
    }

    /* let emailErrors = formErrors.email.filter((i, k) => i.index != index);
    if (email == "")
      emailErrors.push({ index, message: "Email ID is required!" });
    else if (!validateEmail(email))
      emailErrors.push({ index, message: "Invalid email ID" });
    setFormErrors((prev) => ({ ...prev, email: emailErrors })); */
  };

  const handleMobileChange = (mobile: string, director: TCorporateUser) => {
    const index = selectedDirectors.findIndex(
      (d) =>
        pipe(
          d.userId,
          getOrElse(() => "")
        ) ===
        pipe(
          director.userId,
          getOrElse(() => "")
        )
    );
    if (index !== -1) {
      const dir = { ...selectedDirectors[index], mobile: fromNullable(mobile) };
      selectedDirectors[index] = dir;
      setSelectedDirector([...selectedDirectors]);
    }
    /* let mobileErrors = formErrors.mobile.filter((i, k) => i.index != index);
    if (mobile == "")
      mobileErrors.push({ index, message: "Mobile no. is required!" });
    else if (!validateMobile(mobile))
      mobileErrors.push({ index, message: "Invalid mobile no." });
    setFormErrors((prev) => ({ ...prev, mobile: mobileErrors })); */
  };

  const isDirectorSelected = (director: TCorporateUser) => {
    return selectedDirectors.find(
      (d) =>
        pipe(
          d.userId,
          getOrElse(() => "")
        ) ===
        pipe(
          director.userId,
          getOrElse(() => "")
        )
    )
      ? true
      : false;
  };

  return (
    <div
      className={
        devicetype === "desktop"
          ? "card border-0  bg-white mt-1 rounded-15 px-2  "
          : "card border-0 rounded-15 mt-3 p-2 ms-3 me-3"
      }
    >
      <div className="row">
        <DirectorHeader toggle={open} onToggle={setOpen} />
        {/* {maxRecipientErr && open && (
          <div className="fs-small ncash_validate-error text-center mt-2">
            Maximum receipients has selected
          </div>
        )} */}
        {open ? (
          <div className="col-12">
            <div className="bordered-15 card p-2 rounded-6_i border-light">
              {directors.map((director, index) => (
                <DirectorRow
                  key={index}
                  title={pipe(
                    director.name,
                    getOrElse(() => "")
                  )}
                  img={User}
                  size="sm"
                  index={index}
                  director={director}
                  onSelect={handleDirectorSelect}
                  onUnSelect={handleDirectorUnSelect}
                  isChecked={isDirectorSelected(director)}
                />
              ))}
            </div>
          </div>
        ) : (
          <>
            {selectedDirectors.map((director, index) => (
              <DirectorForm
                key={index}
                director={director}
                onRemove={handleDirectorUnSelect}
                onBlur={onBlur}
                onEmailChange={handleEmailChange}
                onMobileChange={handleMobileChange}
                error={{
                  email:
                    formErrors.email.filter((i) => i.index === index)?.[0]
                      ?.message || "",
                  mobile:
                    formErrors.mobile.filter((i) => i.index === index)?.[0]
                      ?.message || "",
                }}
              // emailValid={emailValid}
              // mobileValid={mobileValid}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default DirectorDropdown;