import "./acc-agg.scss";
import { AccountAggregator } from "./account-aggregator";

const DashboardAccountAggregate: React.FC = () => {
  return (
    <>
      <AccountAggregator />
    </>
  );
};

export default DashboardAccountAggregate;
