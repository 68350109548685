import React from "react";
import { Button } from "react-bootstrap";
import "./fx-online.css";

const FxDisclaimer: React.FC<{ onAgree: () => void }> = ({ onAgree }) => {
  return (
    <div>
      <div className="row text-center mt-3">
        <h1>{`Terms of Use for Digital Ncaish FX Online Services`}</h1>
      </div>
      <div className="row p-4 disclaimer-scroll">
        <ul>
          <li className="text-justify">
            These terms and conditions (“T&Cs for Ncaish FX Online Services”)
            mandate the terms on which You (User) access and register on the
            Platform for availing the services in relation to Ncaish FX Online
            and allied products (including purchase and redemption thereof)
            (“Ncaish FX Online Products”) offered and provided by partnered
            third party Ncaish FX Online suppliers and vendors (“Ncaish FX
            Online Suppliers”) through the Platform (“Ncaish FX Online
            Services”).
          </li>
          <li className="text-justify">
            The T&Cs for Ncaish FX Online Services are in addition to the Terms
            and Conditions and Privacy Policy of the Platform that apply to You
            when You access and register on the Platform for availing the
            Platform Services. The Terms and Conditions and T&Cs for Ncaish FX
            Online Services shall be read harmoniously and in case of any
            conflict, the T&Cs for Ncaish FX Online Services shall prevail
            solely in relation to the Ncaish FX Online Services to the extent of
            conflict. All capitalised terms used herein however not defined,
            shall have the meaning ascribed to them under the Terms and
            Conditions and/or the Privacy Policy. All information collected by
            the Platform to provide the Ncaish FX Online Services will also be
            covered under the Privacy Policy.
          </li>
          <li className="text-justify">
            Please read the T&Cs for Ncaish FX Online Services carefully before
            registering on the Platform for such services or accessing any
            material information in relation to the Ncaish FX Online Services
            through the Platform. By accessing and using our Ncaish FX Online
            Services You accept these T&C and agree to be legally bound by the
            same.
          </li>
          <li className="text-justify">
            The Company hereby grants You, a limited, non-exclusive,
            non-transferable, revocable license to use the Platform for the
            purposes of availing the Ncaish FX Online Services from Figital. The
            Ncaish FX Online Services would also include communication with the
            User for queries, enquiries, and other customer support.
          </li>
          <li className="text-justify">
            Figital is acting as a facilitator for the Ncaish FX Online
            accumulation plan of various Ncaish FX Online Suppliers wherein the
            role of is limited to the extent of providing the Platform to Users
            and the Ncaish FX Online Products are being offered and provided by
            the respective Ncaish FX Online Suppliers to You. The Company
            Figital, or any of its affiliates, do not sell, purchase, or accept
            the Ncaish FX Online Products on their own accord and do not own
            Ncaish FX Online Products of their own. Any purchase or sale of
            Ncaish FX Online Products is a bilateral transaction between the
            Ncaish FX Online Supplier and the User.
          </li>
          <li className="text-justify">
            The information in relation to Ncaish FX Online Products including
            the live rate of Ncaish FX Online Products is received from the
            respective Ncaish FX Online Supplier and is displayed on an “as is”
            basis on the Platform. The Company is not in any manner responsible
            for the accuracy, completeness of such information and any
            difference in the rate of Ncaish FX Online Products provided on the
            Platform and that is made available by the Ncaish FX Online Supplier
            on the Ncaish FX Online Products at the time of purchase or
            redemption. The provision of the Ncaish FX Online Services through
            the Platform and display of such information received from the
            Ncaish FX Online Suppliers shall not in any manner constitute any
            recommendation, advice or opinion being given by , the Company or
            any of its affiliates in relation to the Ncaish FX Online Suppliers,
            and/ or the Ncaish FX Online Products.
          </li>
          <li className="text-justify">
            We shall share the information and documents received from You for
            the Ncaish FX Online Products with the respective Ncaish FX Online
            Supplier selected by You through secure channels. While undertakes
            measures to ensure that such transmission is error-free, however, it
            does not guarantee that the transmissions of Your details will
            always be secure or that unauthorized third parties will never be
            able to defeat the security measures taken by Figital. Figital
            assumes no liability or responsibility for disclosure of Your
            information due to errors in transmission, unauthorized third-party
            access, or other causes beyond its reasonable control.
          </li>
          <li className="text-justify">
            You hereby agree and acknowledge: (i) to promptly provide all
            requested know-your-customer (KYC) details (including identity
            proof, permanent account number (PAN) and address proof) and any
            other information as may be requested by and/or the Ncaish FX Online
            Supplier for providing the Ncaish FX Online Services to You and
            undertaking the verification of the KYC. reserves a right to request
            for additional information or documents from the User while
            providing the Ncaish FX Online Services. You understand, agree and
            give your consent to share all such information and documents
            collected by with the Ncaish FX Online Supplier of Your choice and
            You agree to provide complete, genuine, and accurate information and
            documents to for availing the Ncaish FX Online Services and shall
            ensure that such information is not false, misleading, or incorrect.
            Please note that all verification of documents provided by You for
            the Ncaish FX Online Products is undertaken by the Ncaish FX Online
            Supplier.
          </li>
          <li className="text-justify">
            Upon successful completion of account opening with the Ncaish FX
            Online Supplier of Your choice, You will be entitled to purchase,
            redeem and take delivery of Ncaish FX Online Products based on the
            terms and conditions of the respective Ncaish FX Online Supplier
            that is either communicated on the Platform to You or separately
            made available to You by the Ncaish FX Online Supplier.
          </li>
          <li className="text-justify">
            You understand that Ncaish FX Online Products are not exchange
            traded products and that is merely acting as a facilitator for such
            products through the Platform. All disputes with respect to this
            facilitation activity would not have access to any stock exchange in
            India as redressal forum or Arbitration mechanism.
          </li>
          <li className="text-justify">
            You hereby represent and warrant that these T&Cs for Ncaish FX
            Online Services constitute a legal, valid, and binding obligation on
            You and that all orders to be placed and transactions to be
            conducted under these T&Cs are lawful.
          </li>
          <li className="text-justify">
            Please note that neither Figital nor any of the its associates,
            group and subsidiary entities shall be held responsible and liable
            for delivery, quality, or merchantability of the Ncaish FX Online
            Products, including redemption thereof provided and sold to You by
            the respective Ncaish FX Online Supplier and this is the
            responsibility of the Ncaish FX Online Supplier. is not responsible
            and liable for delivery of Ncaish FX Online Products purchased by
            You and it is the responsibility of the respective Ncaish FX Online
            Supplier.
          </li>
          <li className="text-justify">
            You understand that Ncaish FX Online Suppliers along with an
            independent trustee will be the custodian and responsible for the
            Ncaish FX Online Product’s custody.
          </li>
          <li className="text-justify">
            You acknowledge and understand that investment in Ncaish FX Online
            Products is speculative, and may involve a degree of risk and loss
            and that You have considered Your financial objectives, obtained
            appropriate independent advice, if required prior to entering into
            these T&Cs for Ncaish FX Online Services and have formed the opinion
            that dealing in the Ncaish FX Online Products is suitable for Your
            needs and purposes.
          </li>
          <li className="text-justify">
            You acknowledge that You are solely responsible for all taxes,
            tariffs and duties that may be applicable as a result of purchase or
            redemption of Ncaish FX Online Products.
          </li>
          <li className="text-justify">
            The decision to accept or reject the purchase or redemption request
            for the Ncaish FX Online Product received from a User is that of the
            respective Ncaish FX Online Supplier. The Ncaish FX Online Supplier
            may decide, in its sole discretion, to reject a purchase or
            redemption request from a User, including in the event of (i)
            failure to satisfy KYC verification, as determined by the Ncaish FX
            Online Supplier; and/or (ii) payment made from an account which the
            User is not authorised to operate. The Company and/or will not be
            responsible or liable to the User or any third party, for any acts
            or omissions of the Ncaish FX Online Supplier including delay,
            rejection or cancellation of the purchase request for Ncaish FX
            Online Products, any loss of money or damage caused to You on
            account thereof, or failure of the Ncaish FX Online Supplier to
            refund the money collected from the User or any part thereof towards
            a rejected purchase request, breach of confidentiality or any
            applicable law by the Ncaish FX Online Supplier, negligence of
            Ncaish FX Online Supplier or failure of Ncaish FX Online Supplier to
            perform any obligations with respect to the Ncaish FX Online
            Services.
          </li>
          <li className="text-justify">
            You understand that the Ncaish FX Online Supplier will be solely
            responsible and liable to resolve any queries/complaints/grievances
            and address any issues/complaints/grievances relating to the quality
            of the Ncaish FX Online Product, it’s safe-keeping and custody, or
            any damage, wrong shipment, or theft at the time of delivery or such
            other grievances which pertain to the Ncaish FX Online Products.
          </li>
          <li className="text-justify">
            You acknowledge that neither nor any of its employees, directors,
            associates, group entities are inducing the Users for trading/invest
            in Ncaish FX Online Products. Trading/investment decision is the
            sole responsibility of the User.
          </li>
          <li className="text-justify">
            You further understand and agree that neither nor any of its
            associates, group and subsidiary -entities guarantee or give any
            assurance as to returns or profits or capital protection or
            appreciation on the Ncaish FX Online Products transacted by You
            through the Platform.
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-12 text-center mb-3 mt-4">
          <Button
            className="btn-gradiant-blue px-4 py-2 text-white"
            type="button"
            onClick={onAgree}
          >
            I Agree
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FxDisclaimer;
