import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { TransactionService } from "../../../../services/transaction.service";
import {
  ReportsDispatch,
  ReportsReduxStore,
} from "../../../../state/slice/reports.slice";
import { fromPublic } from "../../../../utils/common.utils";
import NoTableData from "../common/no-table-data";
import { ITableData } from "../common/table/table-pagination";
const downloadIcon = fromPublic("/images/icons/download.svg");

interface props {
  show: boolean;
}

const ReportsList: React.FC<props> = ({ show }) => {
  const [paging, setPaging] = useState<ITableData>({
    total: 10,
    page: 1,
    limit: 10,
  });
  const downloadedReport = useAppSelector(ReportsReduxStore.selectDownload);

  const [report, setReport] = useState<Array<string>>();
  const checkExist = useAppSelector(ReportsReduxStore.selectCheckExist);

  const getReportDetails = (path: string) => {
    // const d =
    //   "933e3db4-aec0-43bb-b8d6-924863b50db7/719442f7-3b38-4722-8c30-8c0efb883727/Statement of Perquisites -09-07-2024 18:01.csv";

    const dotSplit = path.split("/")[2].split(" ");

    const date1 = dotSplit
      .reverse()
      .filter((obj, index) => index < 2)
      .reverse();

    const date2 = date1[0]
      .split("-")
      .filter((obj, index) => index >= 1)
      .join("-");

    const date3 = date1[1].split(".")[0];

    const list = dotSplit
      .filter((obj, index) => index >= 2)
      .reverse()
      .join(" ");

    // const name = capitalizeHyphenatedString(list);
    const name = list;
    const date = `${date2} ${date3}`;
    const year = date2.split("-")[2];

    return { date, name, year };
  };

  useEffect(() => {
    fetchData();
  }, [show, downloadedReport]);

  const fetchData = async () => {
    const result = await TransactionService.getRecentReports();

    if (isSuccess(result)) {
      if (result.value.success) {
        setReport(result.value.data);
        if (
          result.value.data[0] === checkExist ||
          checkExist.length === 0 ||
          result.value.data.length === 0
        ) {
          ReportsDispatch.setShowNew(false);
        } else {
          ReportsDispatch.setShowNew(true);
        }
        ReportsDispatch.setCheckExist(result.value.data[0]);
      } else {
        alert(result.value.message);
      }
    } else {
      alert("Internal Error");
    }
  };

  const downloadReport = async (url: string) => {
    const result = await TransactionService.downloadReportWithPath(url);

    if (isSuccess(result)) {
      const url = result.value;
      const link = document.createElement("a");
      link.href = url as string;
      // const fileName = `Test.csv`; // whatever your file name .
      // link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      alert("Internal Error");
    }
  };

  const downloadFile = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => alert("Failed to download file"));
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "stretch",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {(report?.length ?? 0) > 0 ? (
        // <TablePagination
        //   data={paging}
        //   changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
        //   changeLimit={(data) =>
        //     setPaging((p) => ({ ...p, limit: data, page: 1 }))
        //   }
        // >
        <table>
          <thead>
            <tr>
              <th scope="col" style={{ fontSize: 14 }}>
                S.No.
              </th>
              <th scope="col" style={{ fontSize: 14 }}>
                Report Date
              </th>
              <th scope="col" style={{ fontSize: 14 }}>
                Report Name
              </th>
              <th scope="col" style={{ fontSize: 14 }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {report &&
              report.map((item, key) => (
                <tr key={key}>
                  <td
                    className="align-table-data-center"
                    style={{ fontSize: 14 }}
                  >
                    {key + 1}
                  </td>
                  <td
                    className="align-table-data-center"
                    style={{ fontSize: 14 }}
                  >
                    {getReportDetails(item).date}{" "}
                  </td>
                  <td
                    className="align-table-data-left"
                    style={{ fontSize: 14 }}
                  >
                    {getReportDetails(item).name}
                  </td>
                  {/* <td className="align-table-data-left">
                      {item.email || "-"}
                    </td> */}
                  <td className="text-center text-nowrap">
                    <img
                      src={downloadIcon}
                      style={{ height: 20, width: 20, cursor: "pointer" }}
                      onClick={() => {
                        downloadReport(item);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        // </TablePagination>
        <NoTableData />
      )}
    </div>
  );
};

export default ReportsList;
