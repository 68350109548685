import { format } from "date-fns";
import React from "react";
import DocumentModal from "../../common/document-modal";

const BillCard: React.FC<{ bills?: Array<string> }> = ({ bills = [] }) => {
  const [docModal, setDocModal] = React.useState({ show: false, url: "" });

  return (
    <div className="card text-dark mb-3">
      <div className="d-flex card-header  justify-content-between">
        <h5 className="card-title">Uploaded Bills</h5>
        <small> {format(new Date(), "dd/MM/yyyy")}</small>
      </div>
      <div className="card-body">
        <div className="row">
          {bills.length > 0 ? (
            bills.map((bill, index) => (
              <div key={index} className="col-2">
                <div className="mb-3">
                  <img
                    src={bill}
                    onClick={() => setDocModal({ show: true, url: bill })}
                    alt=""
                    className="img-fluid img-thumbnail"
                    width="100"
                    height="100"
                    style={{
                      objectFit: "cover",
                      width: 100,
                      height: 82,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <div>No bills uploaded</div>
          )}
        </div>
        <DocumentModal
          show={docModal.show}
          url={docModal.url}
          closeModal={() => setDocModal({ show: false, url: "" })}
        />
      </div>
    </div>
  );
};

export default BillCard;
