import React from "react";
import { ClaimInfo } from "../../../../api/detax/model/claimInfo";
import { currencyFormat } from "../../../../utils/format.utils";

const DetailCard: React.FC<{ claim: ClaimInfo }> = ({ claim }) => {
  return (
    <div className="card text-dark mb-3">
      <div className="d-flex card-header  justify-content-between">
        <h5 className="card-title">Claim Details</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Claim ID</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.claimCode}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Amount Raised</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p>
              {claim.amount || claim.amount === 0
                ? currencyFormat(claim.amount)
                : "N/A"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Date & Time</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.initiatedAt}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Category</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.category || "N/A"}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Remarks </p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.remarks || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailCard;
