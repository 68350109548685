import React from "react";
import "./claims.scss";

const PendingCard: React.FC = () => {
  return (
    <div className="text-dark pending-claim-box">
      <div className="card-body text-color">
        <p className="fw-bold m-0"> Status : Pending</p>
        <p className="m-0">Claim Review is Pending</p>
      </div>
    </div>
  );
};

export default PendingCard;
