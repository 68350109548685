import classNames from "classnames";
import { format } from "date-fns";
import { Modal } from "react-bootstrap";
import { AddAdminUserApiRequest } from "../../../../api/employee/model/addAdminUserApiRequest";
import { validateEmail, validateMobile, validateName } from "../../../../utils/common.utils";


interface Iprops /* extends React.InputHTMLAttributes<HTMLInputElement> */ {
  title?: string;
  modal: {
    show: boolean;
    data: AddAdminUserApiRequest;
  };
  closeModal: () => void;
  handleSubmit: (data: any) => React.FormEventHandler<HTMLFormElement>;
  onSubmit: (data: any) => void;
  register: any;
  errors: any;
  actionState?: boolean;
}

const inputDate = (dt: any) => {
  try {
    dt = dt.replace(/-/g, '/')
    return format(new Date(dt), "yyyy-MM-dd")
  } catch (error) { }
  return ''
}

const SaveProfileForm: React.FC<Iprops> = ({
  title,
  modal,
  closeModal,
  handleSubmit,
  onSubmit,
  register,
  errors,
  actionState = false
}) => {
  return (
    <Modal show={modal.show} onHide={() => closeModal()} size="lg" centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className=" fs-5 fw-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fw-bold">Provide details of the employee as following</p>
          <div className="row">
            <div className="form-group col-md-4">
              <label htmlFor="code">Employee Code</label>
              <input
                defaultValue={modal.data.code}
                {...register("code", {
                  required: "Employee Code is required!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.code?.message,
                })}
              />
              <div className="invalid-feedback">{errors?.code?.message}</div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="name">Employee Name</label>
              <input
                defaultValue={modal.data.name}
                {...register("name", {
                  required: "Employee name is required!",
                  validate: (val: string) =>
                    validateName(val) || "Invalid name!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.name?.message,
                })}
              />
              <div className="invalid-feedback">{errors?.name?.message}</div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="">Mobile</label>
              <input
                defaultValue={modal.data.mobile}
                {...register("mobile", {
                  required: "Mobile no. is required!",
                  validate: (val: string) =>
                    validateMobile(val) || "Mobil no. must be 10 digits",
                })}
                className={classNames("form-control number-input", {
                  "is-invalid": errors?.mobile?.message,
                })}
                maxLength={10}
              />
              <div className="invalid-feedback">{errors?.mobile?.message}</div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="">Email ID</label>
              <input
                defaultValue={modal.data.email}
                {...register("email", {
                  required: "Email ID is required!",
                  validate: (val: string) =>
                    validateEmail(val) || "Invalid email ID!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.email?.message,
                })}
              />
              <div className="invalid-feedback">{errors?.email?.message}</div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="gender">Gender</label>
              <select
                defaultValue={modal.data.gender}
                {...register("gender")}
                className="form-control"
              >
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="TRANSGENDER">Transgender</option>
              </select>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="">Date Of Join</label>
              <input
                defaultValue={inputDate(modal.data.doj)}
                {...register("doj", {
                  required: "Date Of Join is required!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.doj?.message,
                })}
                type={"date"}
                max={new Date()}
              />
              <div className="invalid-feedback">{errors?.doj?.message}</div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="address.address">Address</label>
              <input
                defaultValue={modal?.data?.address?.address}
                {...register("address.address", {
                  required: "Address is required!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.address?.address?.message,
                })}
              />
              <div className="invalid-feedback">
                {errors?.address?.address?.message}
              </div>
            </div>
            {/* <div className="form-group col-md-4">
              <label htmlFor="address.state">State</label>
              <input
                defaultValue={modal.data.address.state}
                {...register("address.state", {
                  required: "State is required!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.address?.state?.message,
                })}
              />
              <div className="invalid-feedback">
                {errors?.address?.state?.message}
              </div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="address.country">Country</label>
              <select
                defaultValue={modal.data.address.country}
                {...register("address.country", {
                  required: "Country is required!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.address?.country?.message,
                })}
              >
                <option value="">SELECT COUNTRY</option>
                {COUNTRIES.map((country, key) => (
                  <option key={key} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                {errors?.address?.country?.message}
              </div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="address.pinCode">PinCode</label>
              <input
                defaultValue={modal.data.address.pinCode}
                {...register("address.pinCode", {
                  required: "Pincode is required!",
                })}
                className={classNames("form-control", {
                  "is-invalid": errors?.address?.pinCode?.message,
                })}
                type={"number"}
                maxLength={6}
              />
              <div className="invalid-feedback">
                {errors?.address?.pinCode?.message}
              </div>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="address.location.lat">Latitude</label>
              <input
                defaultValue={modal.data.address.location.lat}
                {...register("address.location.lat")}
                className="form-control"
                type={"number"}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="address.location.lng">Longitude</label>
              <input
                defaultValue={modal.data.address.location.lng}
                {...register("address.location.lng")}
                className="form-control"
                type={"number"}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={actionState}
            onClick={() => closeModal()}
            type="button"
            className="btn-ncaish-light px-3 py-2"
          >
            Cancel
          </button>
          <button disabled={actionState} type="submit" className="btn-gradiant-blue px-3 py-2">
            {actionState && <span className="spinner-border spinner-border-sm me-2" role="status"></span>}
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SaveProfileForm;
