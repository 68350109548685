import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ClaimCountInfo } from "../../../../api/detax/model/claimCountInfo";
import { ClaimInfo } from "../../../../api/detax/model/claimInfo";
import { ClaimsService } from "../../../../services/claims.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import {
  adminTableHeight,
  getTitleCase,
  getTitleCaseToStatus,
  titleCase,
} from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import NumberFormat from "react-number-format";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
interface IDateRange {
  startDate?: any;
  endDate?: any;
}

const ClaimsTable: React.FC<{
  searchVal?: string;
  uid?: string;
  curTbl: TStatusCountInfo;
  filterDate: IDateRange;
  setData: (data: ClaimInfo[] | []) => void;
}> = ({ searchVal = "", uid = "", curTbl, filterDate, setData }) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<Option<ClaimInfo[]>>(none);
  const [filteredData, setFilteredData] = useState<Option<ClaimInfo[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    console.log("Changed");
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [curTbl.status]);
  useEffect(() => {
    let cardStatus =
      page === RoutePaths.DashboardClaims ? card : curTbl?.status;
    // console.log("TABLE", page, card, cardStatus);
    const run = async () => {
      setItems(none);
      if (cardStatus) {
        const tblResult = await ClaimsService.allClaim(
          cardStatus,
          filterDate.startDate
            ? format(new Date(filterDate.startDate), "dd-MM-yyyy")
            : "",
          filterDate.endDate
            ? format(new Date(filterDate.endDate), "dd-MM-yyyy")
            : "",
          uid,
          paging.page,
          paging.limit
        );
        if (isSuccess(tblResult)) {
          if (tblResult.value.successful) {
            setPaging((p) => ({
              ...p,
              total: tblResult.value.recordCount,
              page: paging.page,
              limit: paging.limit,
            }));
            setItems(some(tblResult.value.data || []));
            setFilteredData(some(tblResult.value.data || []));
            setData(tblResult.value.data || []);
          } else {
            setErr(true);
          }
        }
      }
    };
    run();
    dispatch(resetDashboardRedirect());
  }, [paging.page, paging.limit, paging.total]);

  const tblData = (tblData: ClaimInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  // useEffect(() => filterData(items), [searchVal]);
  // const filterData = (allData: Option<ClaimInfo[]>) => {
  //   let data = pipe(
  //     allData,
  //     fold(
  //       () => [],
  //       (d) => (d.length > 0 ? d : [])
  //     )
  //   );
  //   if (searchVal) {
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let claimCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as claim id
  //     let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //     let filteredData = data.filter(
  //       (item) =>
  //         claimCodeRegEx.test(item.claimCode || "") ||
  //         nameRegEx.test(item.initiator?.name || "") ||
  //         mobRegEx.test(item.initiator?.mobile || "")
  //     );
  //     setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(some(filteredData));
  //   } else {
  //     setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(allData);
  //   }
  // };

  if (isNone(items)) {
    return <TableLoader />;
  }

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        pipe(
          filteredData,
          fold(
            () => [<NoTableData />],
            (items) =>
              items.length > 0
                ? [
                    <TablePagination
                      data={paging}
                      changePage={(data) =>
                        setPaging((p) => ({ ...p, page: data }))
                      }
                      changeLimit={(data) =>
                        setPaging((p) => ({ ...p, limit: data, page: 1 }))
                      }
                    >
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">Claim Code</th>
                            <th scope="col">Category</th>
                            {/* <th scope="col">Image</th> */}
                            {uid == "" && (
                              <>
                                <th scope="col">Employee Name</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">E-mail</th>
                              </>
                            )}
                            <th scope="col">Created At</th>
                            <th scope="col">Updated At</th>
                            {/* {!(
                              curTbl.status ===
                                ClaimCountInfo.StatusEnum.APPROVED ||
                              curTbl.status ===
                                ClaimCountInfo.StatusEnum.REJECTED ||
                              curTbl.status ===
                                ClaimCountInfo.StatusEnum.REIMBURSED ||
                              curTbl.status ===
                                ClaimCountInfo.StatusEnum.PENDING
                            ) && <th scope="col">Updated At</th>} */}

                            {/* {curTbl.status ===
                              ClaimCountInfo.StatusEnum.APPROVED && (
                              <th scope="col">Approved At</th>
                            )}
                            {curTbl.status ===
                              ClaimCountInfo.StatusEnum.REJECTED && (
                              <th scope="col">Rejected At</th>
                            )}
                            {curTbl.status ===
                              ClaimCountInfo.StatusEnum.REIMBURSED && (
                              <th scope="col">Reimbursed At</th>
                            )} */}
                            <th scope="col">Amount</th>
                            {curTbl.status !==
                              ClaimCountInfo.StatusEnum.PENDING && (
                              <th scope="col">Approval Amount</th>
                            )}
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tblData(items).map((item, key) => (
                            <tr key={key}>
                              <td className="text-start">
                                <Link
                                  className="text-secondary ncash_nav-link "
                                  to={
                                    RoutePaths.DashboardClaimsDetail +
                                    "/" +
                                    item.claimId
                                  }
                                >
                                  {!item.claimCode ? "-" : item.claimCode}
                                </Link>
                              </td>
                              <td className="text-start">
                                {!item.catImage ? (
                                  ""
                                ) : (
                                  <img
                                    src={item.catImage}
                                    width={26}
                                    height={26}
                                    alt=""
                                  />
                                )}
                                <span className="mx-2">
                                  {!item.category ? "-" : item.category}
                                </span>
                              </td>
                              {/* <td>
                              {item.images ? (
                                ""
                              ) : (
                                <img
                                  src={item.images}
                                  alt="-"
                                  width={50}
                                  height={50}
                                />
                              )}
                            </td> */}
                              {uid == "" && (
                                <>
                                  <td className="text-start">
                                    <Link
                                      className="text-secondary ncash_nav-link"
                                      to={
                                        RoutePaths.EmployeesDetail +
                                        "/" +
                                        item.initiator?.uid
                                      }
                                    >
                                      {!item.initiator?.name
                                        ? "-"
                                        : item.initiator?.name}
                                    </Link>
                                  </td>
                                  <td>
                                    {item.initiator?.mobile === ""
                                      ? "-"
                                      : item.initiator?.mobile}
                                  </td>
                                  <td className="text-start">
                                    {item.initiator?.email === ""
                                      ? "-"
                                      : item.initiator?.email}
                                  </td>
                                </>
                              )}
                              <td className="text-center text-nowrap">
                                {" "}
                                {item.initiatedAt === ""
                                  ? "-"
                                  : item.initiatedAt}
                              </td>
                              {/* {!(
                                curTbl.status ===
                                  ClaimCountInfo.StatusEnum.APPROVED ||
                                curTbl.status ===
                                  ClaimCountInfo.StatusEnum.REJECTED ||
                                curTbl.status ===
                                  ClaimCountInfo.StatusEnum.REIMBURSED ||
                                curTbl.status ===
                                  ClaimCountInfo.StatusEnum.PENDING
                              ) && (
                                <td className="text-center text-nowrap">
                                  {" "}
                                  {item.updatedAt === "" ? "-" : item.updatedAt}
                                </td>
                              )} */}
                              <td className="text-center text-nowrap">
                                {" "}
                                {item.updatedAt === "" ? "-" : item.updatedAt}
                              </td>
                              {/* {curTbl.status ===
                                ClaimCountInfo.StatusEnum.APPROVED && (
                                <td className="text-center text-nowrap">
                                  {" "}
                                  {item.approvedAt === ""
                                    ? "-"
                                    : item.approvedAt}
                                </td>
                              )}
                              {curTbl.status ===
                                ClaimCountInfo.StatusEnum.REJECTED && (
                                <td className="text-center text-nowrap">
                                  {" "}
                                  {item.cancelledAt === ""
                                    ? "-"
                                    : item.cancelledAt}
                                </td>
                              )}
                              {curTbl.status ===
                                ClaimCountInfo.StatusEnum.REIMBURSED && (
                                <td className="text-center text-nowrap">
                                  {" "}
                                  {item.reimbursedAt === ""
                                    ? "-"
                                    : item.reimbursedAt}
                                </td>
                              )} */}
                              <td className="text-end">
                                {currencyFormat(item.amount || 0)}
                              </td>
                              {curTbl.status !==
                                ClaimCountInfo.StatusEnum.PENDING && (
                                <td className="text-end">
                                  {currencyFormat(item.appAmount || 0)}
                                </td>
                              )}
                              <td className="text-center">
                                <StatusText
                                  text={getTitleCase(item.displayStatus || "")}
                                  className={classNames({
                                    color2: item.status == "PENDING",
                                    color7: item.status == "APPROVED",
                                    color8: item.status == "REJECTED",
                                    color9: item.status == "REIMBURSED",
                                    color3: item.status == "IN_PROCESS",
                                    color4: item.status == "REVIEW_PENDING",
                                    color1: item.status == "APPROVER_PENDING",
                                    color6: item.status == "CANCELLED",
                                  })}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TablePagination>,
                  ]
                : [<NoTableData />]
          )
        )
      )}
    </>
  );
};

export default ClaimsTable;
