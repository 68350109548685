import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

// this popup is for select date range
const DateRangeSelectionPopup: React.FC<{
  actionStateCat: boolean;
  actionStateUtil: boolean;
  show: boolean;
  closeModal: () => void;
  onDownloadUtil: (from: Date, to: Date) => void;
  onDownloadCat: (from: Date, to: Date) => void;
}> = ({
  actionStateCat,
  actionStateUtil,
  show,
  closeModal,
  onDownloadUtil,
  onDownloadCat,
}) => {
  const [modeOfPayment, setModeOfPayment] = useState<string>("NEFT");
  const [from, setFromDate] = useState<Date>(new Date());
  const [to, setToDate] = useState<Date>(new Date());

  const [fromNew, setFromDateNew] = useState<Date>(new Date());
  const [toNew, setToDateNew] = useState<Date>(new Date());

  const [year, setYear] = useState("");

  const crr = new Date();
  //console.log(format(new Date(), "dd-MM-yyyy"));
  // console.log(
  //   //     amount + ",  " + modeOfPayment + ",  " + date &&
  //   format(parseISO(date || "10-05-2022"), "dd-MM-yyyy") + ",  " + referenceId
  // );
  // useEffect(() => {
  //   if (show === false) {
  //     defaultValue();
  //   }
  // }, [show]);
  const onSetDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    //  setDate(new Date(event.target.value));
  };

  const convertDate = (date?: string): string => {
    if (date) {
      const dArr = date.split("-");

      return `${dArr[2]}-${dArr[1]}-${dArr[0]}`;
    } else {
      return `${format(new Date(), "dd-MM-yyyy")}`;
    }
  };

  const [selectedValue, setSelectedValue] = useState("option");
  const [btnradio, setbtnradio] = useState(true);
  const [btnradiofba, setbtnradiofba] = useState(false);
  const [btnradioreport, setbtnradioreport] = useState(false);
  const [btnenable, setbtnenable] = useState(false);

  // const handleChange = (event: any) => {
  //   setSelectedValue(event.target.value);
  // };

  let financialYearStart: any;
  let financialYearEnd: any;

  const [radioBtn, setRadioBtn] = useState(true);

  useEffect(() => {
    if (radioBtn) {
      if (currentMonth >= 3) {
        financialYearStart = new Date(`04-01-${currentYear}`);
        financialYearEnd = new Date(`03-31-${currentYear + 1}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      } else {
        financialYearStart = new Date(`04-01-${currentYear - 1}`);
        financialYearEnd = new Date(`03-31-${currentYear}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      }
    } else {
      if (currentMonth >= 3) {
        financialYearStart = new Date(`04-01-${currentYear - 1}`);
        financialYearEnd = new Date(`03-31-${currentYear + 1 - 1}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      } else {
        financialYearStart = new Date(`04-01-${currentYear - 1 - 1}`);
        financialYearEnd = new Date(`03-31-${currentYear - 1}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      }
    }
  }, [radioBtn]);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();
  let checkFinancialYear = currentDate.getFullYear();

  if (currentMonth >= 3) {
    checkFinancialYear += 1;
  }
  const financialYear = `FY ${
    checkFinancialYear - 1
  } - FY ${checkFinancialYear}`;

  const financialYearLast = `FY ${checkFinancialYear - 1 - 1} - FY ${
    checkFinancialYear - 1
  }`;

  // const [btndisable, setBtndisable] = useState(true);

  // useEffect(() => {
  //   if (btnradio == true) {
  //     setBtndisable(true);
  //   } else {
  //     setBtndisable(false);
  //   }
  // });

  // const callbtnradio = () => {
  //   setbtnradio(!btnradio);
  // };
  // const callbtnradiofba = () => {
  //   setbtnradiofba(true);
  //   setbtnradioreport(false);
  //   setbtnenable(false);
  // };
  // const callbtnradioreport = () => {
  //   setbtnradioreport(true);
  //   setbtnradiofba(false);
  //   setbtnenable(false);
  // };
  // const defaultValue = () => {
  //   setFromDateNew(new Date());
  //   setToDateNew(new Date());
  //   setbtnradio(false);
  // };
  console.log(`From: ${fromNew} --  To: ${toNew}`);
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal();
          // defaultValue();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Download FBA Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="row align-items-center justify-content-center">
              <div className="container">
                <div
                  className=""
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="">
                    <label className="">Select the Financial Year</label>
                  </div>
                  <div className="" style={{ marginLeft: 95 }}>
                    <div className="" style={{ flexDirection: "row" }}>
                      <input
                        className="form-check-input"
                        type="radio"
                        value="option1"
                        // checked={selectedValue === "option1"}
                        checked={radioBtn}
                        // onChange={handleChange}
                        onClick={() => {
                          setRadioBtn(true);
                        }}
                      />
                      <label style={{ marginLeft: 5 }}>{financialYear}</label>
                    </div>
                    <div
                      className=""
                      style={{ flexDirection: "row", marginTop: 7 }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        value="option1"
                        // checked={selectedValue === "option1"}
                        checked={!radioBtn}
                        // onChange={handleChange}
                        onClick={() => {
                          setRadioBtn(false);
                        }}
                      />
                      <label style={{ marginLeft: 5 }}>
                        {financialYearLast}
                      </label>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="row mt-4"></div>
                <div className="row">
                  <div className="col">
                    <p className="text-center">(or)</p>
                  </div>
                </div> */}

                {/* <div className="row">
                  <div className="col">
                    <label>Choose the custom date below:</label>
                  </div>
                </div>
                <div className="row mt-3"></div>
                <div className="row">
                  <div className="col-6">
                    <label>From</label>
                    <input
                      type="date"
                      className="form-control border-0"
                      // value={from.toLocaleDateString("en-CA")}
                      disabled={btndisable}
                      onChange={(event) => {
                        setFromDate(new Date(event.target.value));
                        setFromDateNew(new Date(event.target.value));
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label>To</label>
                    <input
                      type="date"
                      className="form-control border-0"
                      // value={to.toLocaleDateString("en-CA")}
                      disabled={btndisable}
                      onChange={(event) => {
                        setToDate(new Date(event.target.value));
                        setToDateNew(new Date(event.target.value));
                      }}
                    />
                  </div>
                </div> */}

                {/* FOR ADD A DOWNLOAD BUTTON OPTIONS  */}
                {/* <div className="border-bottom mt-5"></div> */}
                {/* <div className="row mt-3">
                  <div className="col-1"></div>
                  <div className="col-3 d-flex justify-content-end">
                    <label className="d-flex justify-content-end">
                      Download{" "}
                    </label>
                  </div>
                  <div className="col-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="option1"
                      // checked={selectedValue === "option1"}
                      checked={btnradiofba}
                      // onChange={handleChange}
                      onClick={callbtnradiofba}
                    />
                  </div>
                  <div className="col-5 px-0">
                    <label>FBA Utilization Report </label>
                  </div>
                  <div className="col-2"></div>
                  <div className="col-1"></div>
                  <div className="col-3"></div>
                  <div className="col-1">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="option1"
                      // checked={selectedValue === "option1"}
                      checked={btnradioreport}
                      // onChange={handleChange}
                      onClick={callbtnradioreport}
                    />
                  </div>
                  <div className="col-5 px-0">
                    <label>FBA Category Report</label>
                  </div>
                  <div className="col-2"></div>
                </div> */}
                {/* END HERE  */}

                {/* <div className="row mt-4"></div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button
              className="btn-gradiant-blue px-3 py-2 font-14 mr-auto text-white m-2"
              size="sm"
              type="button"
              onClick={() => {
                onDownloadUtil(fromNew, toNew);
              }}
              disabled={btnenable}
            >
              {actionStateUtil && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
              )}
              Download FBA Utilization
            </Button>

            {/* <Button
              className="btn-gradiant-blue px-3 py-2 font-14 mr-auto text-white m-2"
              size="sm"
              type="button"
              onClick={() => {
                onDownloadCat(fromNew, toNew);
              }}
              disabled={btnenable}
            >
              {actionStateCat && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
              )}
              Download FBA Category
            </Button> */}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DateRangeSelectionPopup;
