/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { CardTransactionDetailResponse } from "../model/cardTransactionDetailResponse";
import { CardTransactionsMonthlyCountResponse } from "../model/cardTransactionsMonthlyCountResponse";
import { DetaxCardTransactionApprovalRequest } from "../model/detaxCardTransactionApprovalRequest";
import { FetchBulkDataApiRequest } from "../model/fetchBulkDataApiRequest";
import { FetchBulkTransactionResponse } from "../model/fetchBulkTransactionResponse";
import { TransactionApprovalStatusWiseCountApiResponse } from "../model/transactionApprovalStatusWiseCountApiResponse";
import { TransactionHistoryResponse } from "../model/transactionHistoryResponse";
import { ReportListApiResponse } from "../model/reportListApiResponse";
import { CorpWalletTxnResponse } from "../model/corpWalletTxnResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishTransactionApiService {
  /**
   *
   * @param from -
   * @param to -
   * @param uid -
   */
  export const approvalStatusCounts = async (
    from?: string,
    to?: string,
    uid?: string,
    type?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<
    RemoteData<APIError, TransactionApprovalStatusWiseCountApiResponse>
  > => {
    const path =
      `/secure/detax/card/transactions/approval/status/count`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "uid",
        value: uid,
      },
      {
        key: "type",
        value: type,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const corporateAdminRransactionApproval = async (
    body: DetaxCardTransactionApprovalRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CardTransactionDetailResponse>> => {
    const path = `/secure/detax/card/transactions/${encodeURIComponent(
      String(id)
    )}/approval`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param request -
   */
  export const fetchTransactions = async (
    request: FetchBulkDataApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FetchBulkTransactionResponse>> => {
    const path = `/secure/detax/card/transactions/bulk/fetch`.replace(
      /^\//,
      ""
    );
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    // const queryParams = allQueryParams
    //   .map((param) => `${param.key}=${param.value}`)
    //   .join("&");
    const queryParams = queryString.stringify(request);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const getMonthlyCounts = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CardTransactionsMonthlyCountResponse>> => {
    const path =
      `/secure/detax/card/transactions/corporate/monthly/transactions/counts`.replace(
        /^\//,
        ""
      );
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param txnId -
   */
  export const getTransactionDetails = async (
    txnId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CardTransactionDetailResponse>> => {
    const path = `/secure/detax/card/transactions/${encodeURIComponent(
      String(txnId)
    )}`
      .replace(/^\//, "")
      .replace("{" + "txnId" + "}", String(txnId));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   * @param catId -
   * @param cardId -
   * @param from -
   * @param to -
   * @param type -
   * @param wallet -
   * @param appStatus -
   * @param pn -
   * @param rc -
   */
  export const getTransactions = async (
    uid: string,
    catId?: string,
    cardId?: string,
    from?: string,
    to?: string,
    type?: string,
    wallet?: string,
    appStatus?: Array<string>,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, TransactionHistoryResponse>> => {
    const path = `/secure/detax/card/transactions`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
      {
        key: "catId",
        value: catId,
      },
      {
        key: "cardId",
        value: cardId,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "type",
        value: type,
      },
      {
        key: "wallet",
        value: wallet,
      },
      {
        key: "appStatus",
        value: appStatus,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const moveTxnFbaToTa = async (
    uid: string,
    rrn: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, TransactionHistoryResponse>> => {
    const path = `/secure/corporates/transaction/transfer-Fba-to-Ta`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "uid",
        value: uid,
      },
      { key: "rrn", value: rrn },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   * @param catId -
   * @param cardId -
   * @param from -
   * @param to -
   * @param type -
   * @param wallet -
   * @param appStatus -
   * @param pn -
   * @param rc -
   */
  export const getUserTransactions = async (
    uid: string,
    catId?: string,
    cardId?: string,
    from?: string,
    to?: string,
    type?: string,
    wallet?: string,
    appStatus?: Array<string>,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, TransactionHistoryResponse>> => {
    const path = `/secure/detax/card/transactions/user/${encodeURIComponent(
      String(uid)
    )}`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));
    const allQueryParams: TQueryParam[] = [
      {
        key: "catId",
        value: catId,
      },
      {
        key: "cardId",
        value: cardId,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "type",
        value: type,
      },
      {
        key: "wallet",
        value: wallet,
      },
      {
        key: "appStatus",
        value: appStatus,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param catId -
   * @param cardId -
   * @param from -
   * @param to -
   * @param type -
   * @param wallet -
   * @param appStatus -
   * @param pn -
   * @param rc -
   */
  export const myTransactions = async (
    catId?: string,
    cardId?: string,
    from?: string,
    to?: string,
    type?: string,
    wallet?: string,
    appStatus?: Array<string>,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, TransactionHistoryResponse>> => {
    const path = `/secure/detax/card/transactions/me`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "catId",
        value: catId,
      },
      {
        key: "cardId",
        value: cardId,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
      {
        key: "type",
        value: type,
      },
      {
        key: "wallet",
        value: wallet,
      },
      {
        key: "appStatus",
        value: appStatus,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param txnId -
   * @param cid -
   */
  export const transactionApproval = async (
    body: DetaxCardTransactionApprovalRequest,
    txnId: string,
    cid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CardTransactionDetailResponse>> => {
    const path = `/secure/detax/card/transactions/${encodeURIComponent(
      String(txnId)
    )}/approval/corporates/${encodeURIComponent(String(cid))}`
      .replace(/^\//, "")
      .replace("{" + "txnId" + "}", String(txnId))
      .replace("{" + "cid" + "}", String(cid));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getRecentReports = async (
    year?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ReportListApiResponse>> => {
    const path = `/secure/detax/reports/list/corp-reports`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [{ key: "year", value: year }];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const downloadReportWithPath = async (
    url: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, string>> => {
    const path = `/secure/detax/reports/download-link`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "path",
        value: url,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getCorpTransactions = async (
    cardId: string,

    type: string,
    wallet: string,
    transferType: string,
    txnStatus: Array<string>,
    from: string,
    to: string,
    pn: number,
    rc: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpWalletTxnResponse>> => {
    const path = `/secure/corporates/transactions`.replace(/^\//, "");

    var arr = [];

    if (cardId.length > 0) {
      arr.push({
        key: "cardId",
        value: cardId,
      });
    }

    if (from.length > 0) {
      arr.push({
        key: "from",
        value: from,
      });
    }

    if (to.length > 0) {
      arr.push({
        key: "to",
        value: to,
      });
    }

    if (type.length > 0) {
      arr.push({
        key: "type",
        value: type,
      });
    }

    if (wallet.length > 0) {
      arr.push({
        key: "wallet",
        value: wallet,
      });
    }

    if (transferType.length > 0) {
      arr.push({
        key: "transferType",
        value: transferType,
      });
    }

    if (txnStatus.length > 0) {
      arr.push({
        key: "txnStatus",
        value: txnStatus.join(","),
      });
    }

    if (pn > 0) {
      arr.push({
        key: "pn",
        value: pn,
      });
    }

    if (rc > 0) {
      arr.push({
        key: "rc",
        value: rc,
      });
    }

    const allQueryParams: TQueryParam[] = arr;
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
