import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React from "react";
import { TCorporateService } from "../../types/corporate-service.type";
import { fromPublic } from "../../utils/common.utils";
import { CorporateServiceInfo } from "../../model/corporateServiceInfo";
const cardIcon = fromPublic("/images/icons/card.svg");

const ServiceCard: React.FC<{
  service: TCorporateService;
  isChecked: boolean;
  onSelect: (
    type: CorporateServiceInfo.ServiceTypeEnum,
    isSelected: boolean
  ) => void;
}> = ({ service, isChecked, onSelect }) => {
  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    onSelect(
      e.target.value as CorporateServiceInfo.ServiceTypeEnum,
      e.target.checked
    );
  };

  return (
    <div className="card border-0 mt-3 bg-white rounded-15 p-2">
      <div className="row">
        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
          <div className=" rounded-15  mt-3">
            <img
              className="img-fluid icon w-100 ncash_bg-lightpink"
              src={cardIcon}
              alt="Card"
            />
          </div>
        </div>

        <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9 p-0 col-9 col-sm-9 p-0">
          <h4 className="fs-5 text-start fw-bold mt-1">
            {pipe(
              service.name,
              getOrElse(() => "")
            )}
          </h4>
          <div className="text-secondary text-start fs-small font-15">
            {pipe(
              service.description,
              getOrElse(() => "")
            )}
          </div>
        </div>
        <div className="col-md-1 col-lg-1 p-0 pe-3 col-1 col-sm-1 p-0 mt-3">
          <input
            className="form-check-input cardCheckbox"
            id={`service-${pipe(
              service.type,
              getOrElse(() => "")
            )}`}
            name={`service-${pipe(
              service.type,
              getOrElse(() => "")
            )}`}
            type="checkbox"
            value={pipe(
              service.type,
              getOrElse(() => "")
            )}
            checked={isChecked}
            onChange={handleSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
