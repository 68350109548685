import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EmployeeInfo } from "../../../../api/detax/model/employeeInfo";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import {
  Contribution,
  PranAccount,
} from "../../../common/vertical-tablist/tabs/nps/nps-tabs";
import NPSverticalTabs from "../../../common/vertical-tablist/tabs/nps/vertical-tablist";
import WhiteCard from "../../../white-card/white-card";
import DashboardPageLoader from "../common/dashboard-page-loader";
import EmployeeDetails from "../common/employee-details";
import Heading from "../common/heading";
import { NpsService } from "../../../../services/nps.service";
import { isSuccess } from "@devexperts/remote-data-ts";
import { NpsAccountResponse } from "../../../../api/detax/model/npsAccountResponse";

export const DashboardNpsDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState("PranAccount");
  const [employee, setEmployee] = useState<EmployeeInfo>();
  const params = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<NpsAccountResponse>();
  const empName = employee ? employee.name || "" : "";
  useEffect(() => {
    const run = async () => {
      if (params.id) {
        setIsLoading(true);
        const result = await NpsService.getUserAccount(params.id);
        setIsLoading(false);
        if (isSuccess(result)) {
          if (result.value.successful) {
            console.log("Test", result.value);
            setData(result.value);
          }
        }
      }
    };
    run();
  }, [params.id]);
  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <PageTitle title={`NPS details | ${empName}`} />
      <Heading
        title="NPS"
        detail={empName}
        status={/* details?.status || */ ""}
        links={[RoutePaths.DashboardNps]}
      />

      {params.id && (
        <EmployeeDetails
          id={params.id}
          loadEmployee={(employee) => setEmployee(employee)}
          showAddress={false}
        />
      )}
      {data?.data.status === "ACTIVATED" && (
        <div className="tab-content mt-3">
          <div className="tab-pane active">
            <div className="row">
              <div className="col-2">
                <WhiteCard className="mt-0 rounded shadow-none">
                  <NPSverticalTabs
                    activeTab={activeTab}
                    onClick={(tab) => setActiveTab(tab)}
                  />
                </WhiteCard>
              </div>
              <div className="col-10">
                {activeTab === "PranAccount" && (
                  <PranAccount uid={params?.id} />
                )}
                {activeTab === "Contribution" && (
                  <Contribution uid={params?.id} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
