import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishCorporateAdminApiService } from "../api/employee/api/ncaishCorporateAdminApi.service";
import { AddAdminUserApiRequest } from "../api/employee/model/addAdminUserApiRequest";
import { AdminAlphaReleaseApiResponse } from "../api/employee/model/adminAlphaReleaseApiResponse";
import { AdminUserDetailApiResponse } from "../api/employee/model/adminUserDetailApiResponse";
import { NcaishCorporateApiService } from "../api/ncaishCorporateApi.service";
import { AdminCorpCountApiResponse } from "../model/adminCorpCountApiResponse";
import { AdminCorporateListApiResponse } from "../model/adminCorporateListApiResponse";
import { CorporateDetailApiResponse } from "../model/corporateDetailApiResponse";
import { DashboardResponse } from "../model/dashboardResponse";
import { GetM2pWalletResponse } from "../model/getM2pWalletResponse";

export namespace CorporateService {
  export const applicationCounts = async () => {
    const result = await NcaishCorporateApiService.applicationCounts();
    return remoteData.map(
      result,
      (response: AdminCorpCountApiResponse) => response
    );
  };

  export const fetchByState = async (status?: string) => {
    const result = await NcaishCorporateApiService.fetchByState(status);

    return remoteData.map(
      result,
      (response: AdminCorporateListApiResponse) => response
    );
  };

  export const detail = async (id: string) => {
    const result = await NcaishCorporateApiService.detail(id);
    return remoteData.map(
      result,
      (response: CorporateDetailApiResponse) => response
    );
  };

  export const getDashboardData = async () => {
    const result = await NcaishCorporateApiService.getDashboardData();
    return remoteData.map(result, (response: DashboardResponse) => response);
  };

  export const myProfile = async () => {
    const result = await NcaishCorporateAdminApiService.myProfile();
    return remoteData.map(
      result,
      (response: AdminUserDetailApiResponse) => response
    );
  };

  export const alphaRelease = async () => {
    const result = await NcaishCorporateAdminApiService.alphaRelease();
    return remoteData.map(
      result,
      (response: AdminAlphaReleaseApiResponse) => response
    );
  };

  export const update1 = async (body: AddAdminUserApiRequest, id: string) => {
    const result = await NcaishCorporateAdminApiService.update1(body, id);
    return remoteData.map(
      result,
      (response: AdminUserDetailApiResponse) => response
    );
  };

  export const fetchBalance = async (cid: string) => {
    const result = await NcaishCorporateApiService.fetchBalance(cid);
    return remoteData.map(result, (response: GetM2pWalletResponse) => response);
  };

  export const fetchMyBalance = async () => {
    const result = await NcaishCorporateApiService.fetchMyBalance();
    return remoteData.map(result, (response: GetM2pWalletResponse) => response);
  };
}
