import React from "react";
import { TBrReceipient } from "../../types/br-receipient.type";

import UserCard from "./user-card";

const SignatoryCard: React.FC<{ receipients: TBrReceipient[] }> = ({
  receipients,
}) => {
  return (
    <div className="card border-0  bg-white rounded-15 mt-3 p-3 shadow-sm">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-12 ps-0 text-left  mb-2 ps-0 ">
              <h6 className="ps-3">Authorised users </h6>
            </div>
          </div>
          {receipients.map(({ name, mobile, email }, index) => (
            <UserCard name={name} mobile={mobile} email={email} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SignatoryCard;
