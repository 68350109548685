import classNames from "classnames";
import React from "react";
import "../horizontal-tabs/horizontal-tabs.scss";

type TProps = {
  isOverview?: boolean;
  isAccount?: boolean;
  setTab?: (tab?: string) => void;
  tab?: string;
};

const AccountHorizontalTabs: React.FC<TProps> = ({
  isOverview = true,
  isAccount = true,
  setTab = () => { },
  tab = ""
}) => {
  return (
    <div id="exTab2" className="p-0 border-bottom">
      <ul className="nav">
        {isOverview && (
          <li className="nav-item" onClick={() => setTab('overview')}>
            <div className={classNames("nav-link text-secondary fw-bold fs-6", { active: tab === 'overview' })}>
              Overview
            </div>
          </li>
        )}
        {isAccount && (
          <li className="nav-item px-5" onClick={() => setTab('account')}>
            <div className={classNames("nav-link text-secondary fw-bold fs-6", { active: tab === 'account' })} >
              Account
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};
export default AccountHorizontalTabs;
