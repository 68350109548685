import { pipe } from "fp-ts/lib/function";
import { getOrElse, Option } from "fp-ts/lib/Option";
import React from "react";

interface Props {
  userId: Option<string>;
  devicetype: string;
  isChecked: boolean;
  onCheck: (checked: boolean) => void;
}

const DirectorCheckbox: React.FC<Props> = ({
  userId,
  devicetype,
  isChecked,
  onCheck,
}) => {
  //    const [isChecked, setIsChecked] = useState(false)
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(e.target.checked);
  };

  return (
    <div
      className={
        devicetype === "desktop" ? "col-md-1 col-lg-1 p-0 col-1" : "col-1 p-0"
      }
    >
      <input
        className="form-check-input  cardCheckbox"
        type="checkbox"
        value="One"
        aria-label="..."
        name={`director-${pipe(
          userId,
          getOrElse(() => "")
        )}`}
        checked={isChecked}
        onChange={handleCheck}
      />
    </div>
  );
};

export default DirectorCheckbox;
