import { isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { fold, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { APIError } from "../../../../api/errors";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import { currencyFormat } from "../../../../utils/format.utils";
import NoTableData from "../common/no-table-data";
import { ITableData, TablePagination } from "../common/table/table-pagination";

import { Button } from "react-bootstrap";
import {
  CreateRewardApiResponse,
  UpdateRewardApiResponse,
} from "../../../../api/detax/model/rewardsApiResponse";
import { RewardsService } from "../../../../services/rewards.service";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import SuccessPopup from "../common/succes-popup";
import NewRewardPopup from "./newRewardPopup";
const EmployeeRewards: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [show, setShow] = useState(false);
  const [error, setError] = useState<APIError>();
  const [employees, setEmployees] =
    useState<Option<CreateRewardApiResponse[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<CreateRewardApiResponse[]>>(none);
  const [err, setErr] = useState(false);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [searchVal, setSearchVal] = useState("");
  const [showSuccess, setShowSuccess] = useState({ show: false, message: "" });
  const [rewardsList, setRewardsList] =
    useState<Option<CreateRewardApiResponse[]>>(none);
  const [title, setTitle] = useState("");
  const [iseditAble, setIsEditAble] = useState<boolean>(false);
  const [data, setData] = useState<UpdateRewardApiResponse>({
    rewardName: "",
    rewardLogo: "",
    rewardAnime: "",
    rewardDesc: "",
    rewardAmt: 0,
    rewardConfigName: "",
    id: "",
  });
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {

      const tblResult = await RewardsService.getRewards();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setPaging((p) => ({
            ...p,
            total: (tblResult.value.data || []).length,
            page: 1,
            limit: 10,
          }));
          setEmployees(some(tblResult.value.data || []));
          // setFilteredData(some(tblResult.value.data || []));
          setRewardsList(some(tblResult.value.data || []));
          filterData(some(tblResult.value.data || []));
        } else {
          setErr(true);
        }
      } else {
        setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();

    dispatch(resetDashboardRedirect());
  }, [show, showSuccess.show]);

  let body: CreateRewardApiResponse;
  const createRewards = async (val: CreateRewardApiResponse) => {
    const result = await RewardsService.createRewards(val);

    if (isSuccess(result)) {
      if (result.value.successful) {
        setShow(false);
        setData({
          rewardName: "",
          rewardLogo: "",
          rewardAnime: "",
          rewardDesc: "",
          rewardAmt: 0,
          rewardConfigName: "",
          id: "",
        });
        setShowSuccess({ show: true, message: result.value.message ?? "" });
      }
    }
  };

  const deleteRewards = async (val: string) => {
    const result = await RewardsService.deleteRewards(val);

    if (isSuccess(result)) {
      if (result.value.successful) {
        setRewardsList(some(result.value.data || []));
        setShowSuccess({ show: true, message: result.value.message ?? "" });
      }
    }
  };

  const updateRewards = async (val: UpdateRewardApiResponse) => {
    console.log("Update");
    const result = await RewardsService.updateRewards(val);

    if (isSuccess(result)) {
      if (result.value.successful) {
        setShow(false);
        setData({
          rewardName: "",
          rewardLogo: "",
          rewardAnime: "",
          rewardDesc: "",
          rewardAmt: 0,
          rewardConfigName: "",
          id: "",
        });
        setShowSuccess({ show: true, message: result.value.message ?? "" });
      }
    }
  };

  const tblData = (tblData: CreateRewardApiResponse[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  // Array of DetaxCardSubscriptionInfo objects

  useEffect(() => filterData(rewardsList), [searchVal]);
  const filterData = (allData: Option<CreateRewardApiResponse[]>) => {
    let data = pipe(
      allData,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    );
    if (searchVal.length !== 0) {
      let empCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as employee code
      let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
      let mobRegEx = new RegExp(searchVal + ".*", "gi");
      let filteredData = data.filter((item) =>
        nameRegEx.test(item?.rewardName || "")
      );
      setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
      setFilteredData(some(filteredData));
    } else {
      setPaging((p) => ({ ...p, total: data.length, page: 1 }));
      setFilteredData(allData);
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <WhiteCard className="my-4 p-1 rounded">
          <div className="d-flex justify-content-between">
            <CustomSearchBar
              value={searchVal}
              onChange={(val: string) => setSearchVal(val)}
              boxShadow="none"
              height="auto"
              width="70%"
              text="Search by reward"
            />

            <Button
              className="btn-gradiant-blue btn-lg text-white fs-small m-1"
              onClick={() => {
                setShow(true);
                setTitle("Create employee reward");
                setIsEditAble(false);
              }}
            >
              New Reward
            </Button>
          </div>
        </WhiteCard>
      </div>
      <SuccessPopup
        message={showSuccess.message}
        show={showSuccess.show}
        onClose={() => setShowSuccess({ show: false, message: "" })}
      />
      {pipe(
        filteredData,
        fold(
          () => [<NoTableData />],
          (employees) =>
            employees.length > 0
              ? [
                  <TablePagination
                    data={paging}
                    changePage={(data) =>
                      setPaging((p) => ({ ...p, page: data }))
                    }
                    changeLimit={(data) =>
                      setPaging((p) => ({ ...p, limit: data, page: 1 }))
                    }
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Reward Name</th>
                          <th scope="col">Reward Badge</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Description</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblData(employees).map((item, key) => (
                          <tr
                            key={key}
                            // onClick={() => {
                            //   show();
                            //   setPopupData({
                            //     name: item.name,
                            //     email: item.email,
                            //     // mobile: item.mobile,
                            //     // businessUnit: item.businessUnit,
                            //   });
                            // }}
                          >
                            <td className="align-table-data-center">
                              {key + 1}
                            </td>
                            <td className="align-table-data-center">
                              {item.rewardName || "-"}{" "}
                            </td>
                            {/* <td>{item.mobile || "-"}</td> */}
                            <td className="align-table-data-center">
                              <img
                                src={item.rewardLogo}
                                style={{ width: 50, height: 50 }}
                              />
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {currencyFormat(item.rewardAmt)}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              {item.rewardDesc}
                            </td>
                            <td
                              className="text-center text-nowrap"
                              style={{ padding: "0.5rem 0rem" }}
                            >
                              <td
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  // borderBottom:
                                  //   index ===
                                  //   (item.approvesFor ?? []).length - 1
                                  //     ? "none"
                                  //     : "1px solid #ececec",
                                }}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <button
                                    className="btn btn-sm btn-danger me-2"
                                    onClick={() => {
                                      setShow(true);
                                      setTitle("Edit employee reward");
                                      setIsEditAble(true);
                                      setData({
                                        ...data,
                                        rewardAmt: item.rewardAmt,
                                        rewardDesc: item.rewardDesc,
                                        rewardLogo: item.rewardLogo,
                                        rewardName: item.rewardName,
                                        id: item.id,
                                      });
                                    }}
                                  >
                                    <MdModeEdit />
                                  </button>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {
                                      deleteRewards(item.rewardName);
                                    }}
                                  >
                                    <MdDelete />
                                  </button>
                                </div>{" "}
                              </td>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TablePagination>,
                ]
              : [<NoTableData />]
        )
      )}
      <NewRewardPopup
        show={show}
        title={title}
        data={data}
        setData={(val: CreateRewardApiResponse) => setData(val)}
        actionState1={false}
        closeModal={(val) => {
          setShow(val);
          setTitle("");
        }}
        updateRewards={(val: CreateRewardApiResponse) => updateRewards(val)}
        createRewards={(val: CreateRewardApiResponse) => createRewards(val)}
        iseditable={iseditAble}
      />
    </>
  );
};

export default EmployeeRewards;
