import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { COUNTRIES } from "../../../config/countries.data";
import {
  validateEmail,
  validateMobile,
  validateName,
  validatePincode,
  validateMembershipType,
} from "../../../utils/common.utils";
import Select from "react-select";
interface IData {
  code: string;
  fname: string;
  lname: string;
  email: string;
  mobile: string;
  membershipType: string;
  gender: string;
  state: string;
  city: string;
  pinCode: string;
  address: string;
  doj: string;
  dob: string;
  basicSalary: string | number;
  allowance: string | number;
  salary: string | number;
  businessUnit: string;
  taxRegime: string;
  error: string[];
}
interface Option {
  value: string;
  label: string;
}
const BulkEmployeeModal: React.FC<{
  data: IData;
  department: any;
  setData: (data: IData) => void;
  close: () => void;
}> = ({ data, setData, close, department }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<IData>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      code: data.code,
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      mobile: data.mobile,
      membershipType: data.membershipType,
      gender: data.gender,
      state: data.state,
      city: data.city,
      pinCode: data.pinCode,
      address: data.address,
      doj: data.doj ? data.doj : format(new Date(), "yyyy-MM-dd"),
      dob: data.dob
        ? data.dob
        : format(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            "yyyy-MM-dd"
          ),
      basicSalary: data.basicSalary,
      allowance: data.allowance,
      salary: data.salary,
      businessUnit: data.businessUnit,
      taxRegime: data.taxRegime,
      error: [],
    },
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => setIsLoaded(true), []);
  const [business, setBusiness] = useState<Option | undefined>(() => {
    if (data.businessUnit) {
      return { value: data.businessUnit, label: data.businessUnit };
    }

    return undefined;
  });
  const closeModal = () => {
    close();
    setValue("code", "");
    setValue("fname", "");
    setValue("lname", "");
    setValue("mobile", "");
    setValue("membershipType", "");
    setValue("email", "");
    setValue("salary", 0);
    setValue("basicSalary", 0);
    setValue("allowance", 0);
    setValue("address", "");
    setValue("state", "");
    setValue("city", "");
    setValue("pinCode", "");
    setValue("doj", format(new Date(), "yyyy-MM-dd"));
    setValue(
      "dob",
      format(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        "yyyy-MM-dd"
      )
    );
    setValue("gender", "MALE");
    setValue("taxRegime", "");
  };
  let dept: Array<string> | null | undefined = department;
  const deptopt: Option[] = dept
    ? dept.map((obj) => ({
        value: obj || "",
        label: obj || "",
      }))
    : [];
  const onSubmit: SubmitHandler<IData> = (data) => {
    setData({ ...data, businessUnit: business?.value ?? "" });
  };
  const handleBusiness = (selectedOption: any) => {
    setBusiness(selectedOption);
  };

  return (
    <>
      {isLoaded ? (
        <Modal show={true} onHide={() => closeModal()} size="lg" centered>
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title className=" fs-5 fw-bold">
                  Edit Employee
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="fw-bold">
                  Provide details of the employee as following
                </p>
                <div className="row">
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="code" className="text-muted">
                      Employee Code
                    </label>
                    <input
                      {...register("code", {
                        required: "Employee Code is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.code?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.code?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="fname" className="text-muted">
                      First Name
                    </label>
                    <input
                      {...register("fname", {
                        required: "First name is required!",
                        validate: (val: string) =>
                          validateName(val) || "Invalid name!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.fname?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.fname?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="lname" className="text-muted">
                      Last Name
                    </label>
                    <input
                      {...register("lname", {
                        required: "Last name is required!",
                        validate: (val: string) =>
                          validateName(val) || "Invalid name!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.lname?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.lname?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Mobile
                    </label>
                    <input
                      {...register("mobile", {
                        required: "Mobile no. is required!",
                        validate: (val: string) =>
                          validateMobile(val) || "Mobile no. must be 10 digits",
                        valueAsNumber: true,
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.mobile?.message,
                      })}
                      maxLength={10}
                    />
                    <div className="invalid-feedback">
                      {errors?.mobile?.message}
                    </div>
                  </div>
                  {/* <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Membership
                    </label>
                    <input
                      {...register("membershipType", {
                        required: "Membership data is required!",
                        validate: (val: string) =>
                          validateMembershipType(val) || "Invalid membership!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.membershipType?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.membershipType?.message}
                    </div>
                  </div> */}
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Email ID
                    </label>
                    <input
                      {...register("email", {
                        required: "Email ID is required!",
                        validate: (val: string) =>
                          validateEmail(val) || "Invalid email ID!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.email?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="gender" className="text-muted">
                      Gender
                    </label>
                    <select {...register("gender")} className="form-control">
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="TRANSGENDER">Transgender</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Date Of Birth
                    </label>
                    <input
                      {...register("dob", {
                        required: "Date Of Birth is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.dob?.message,
                      })}
                      type={"date"}
                      max={format(
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 18)
                        ),
                        "yyyy-MM-dd"
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors?.dob?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Date Of Joining
                    </label>
                    <input
                      // defaultValue={inputDate(modal.data.doj != '' ? modal.data.doj : format(new Date(), "yyyy-MM-dd"))}
                      {...register("doj", {
                        required: "Date Of Join is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.doj?.message,
                      })}
                      type={"date"}
                      // max={format(new Date(), "yyyy-MM-dd")}
                    />
                    <div className="invalid-feedback">
                      {errors?.doj?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Department
                    </label>
                    <Select
                      value={business}
                      options={deptopt}
                      onChange={handleBusiness}
                      className="w-100"
                    />
                    {/* <div className="invalid-feedback">
                      {errors?.doj?.message}
                    </div> */}
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="taxRegime" className="text-muted">
                      Tax Regime
                    </label>
                    <select {...register("taxRegime")} className="form-control">
                      <option value="Old Regime">Old Regime</option>
                      <option value="New Regime">New Regime</option>
                    </select>
                  </div>
                </div>
                <hr />
                <div className="row">
                  {/* <div
                    className="form-group col-md-4 mb-3"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="country">Country</label>
                    <select
                      // defaultValue={modal?.data?.country}
                      {...register("country", {
                        required: "Country is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.country?.message,
                      })}
                    >
                      <option value={"India"}>{"India"}</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors?.country?.message}
                    </div>
                  </div> */}
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="state">State</label>
                    <input
                      // defaultValue={modal?.data?.state}
                      {...register("state", {
                        required: "State is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.state?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.state?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="city">City</label>
                    <input
                      // defaultValue={modal?.data?.city}
                      {...register("city", {
                        required: "City is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.city?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.city?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="pinCode">PinCode</label>
                    <input
                      // defaultValue={modal?.data?.pinCode}
                      {...register("pinCode", {
                        required: "Pincode is required!",
                        validate: (val: string) =>
                          validatePincode(val) || "Pincode must be 6 digits",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.pinCode?.message,
                      })}
                      maxLength={6}
                    />
                    <div className="invalid-feedback">
                      {errors?.pinCode?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="address" className="text-muted">
                      Address
                    </label>
                    <input
                      // defaultValue={modal?.data?.address}
                      {...register("address", {
                        required: "Address is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.address?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.address?.message}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12">
                    <p>Salary Information</p>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="salary" className="text-muted">
                      Salary
                    </label>
                    <input
                      // defaultValue={modal.data?.salary}
                      {...register("salary", {
                        required: "Salary is required!",
                        validate: (val: string | number) =>
                          parseFloat(val.toString()) > 0 ||
                          "Salary is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.salary?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.salary?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="basicSalary" className="text-muted">
                      Basic Salary
                    </label>
                    <input
                      // defaultValue={modal.data?.basicSalary}
                      {...register("basicSalary", {
                        required: "Basic salary is required!",
                        validate: (val: string | number) =>
                          parseFloat(val.toString()) > 0 ||
                          "Basic salary is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.basicSalary?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.basicSalary?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="allowance" className="text-muted">
                      Flexible Allowance
                    </label>
                    <input
                      // defaultValue={modal.data?.allowance}
                      {...register("allowance", {
                        required: "Flexible Allowance is required!",
                        validate: (val: string | number) =>
                          parseFloat(val.toString()) > 0 ||
                          "Flexible allowance is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.allowance?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.allowance?.message}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className=" px-3 py-2 btn bg-dark bg-opacity-10 border-0"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="primary-bg btn text-white border-0 px-3 py-2"
                >
                  Submit
                </button>
              </Modal.Footer>
            </form>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default BulkEmployeeModal;
