import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminUserInfo } from "../../api/employee/model/adminUserInfo";
import { State } from "../../state/store";

export interface UserState {
    data: AdminUserInfo | null;
}

const initialState: UserState = {
    data: null
};

const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        initializeApp: () => initialState,
        setProfile: (state, { payload }: PayloadAction<AdminUserInfo | null>) => {
            state.data = payload
        },
        resetProfile: (state) => {
            state.data = null
        },
    },
});

export const { setProfile, resetProfile } = slice.actions;
export const selectProfile = (state: State) => state.profile.data

export default slice.reducer;
