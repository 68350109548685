import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { InlineButton, InputGroup } from "..";
import { useAppSelector } from "../../hooks/hooks";
import { TCorporateUser } from "../../types/corporate-user.type";
import {
  validateEmail,
  validateMobile,
  validateName,
} from "../../utils/common.utils";
import {
  IErrVal,
  ISegnatory,
  ISegnatoryErr,
} from "../pages/digital-contract.page";
import { selectDirectors } from "./contract.slice";
interface IProps {
  devicetype: string;
  signatories: ISegnatory[];
  setAddSignatories: (signatories: ISegnatory[]) => void;
  errors: ISegnatoryErr;
  setNoRecipientErr: (val: boolean) => void;
  selectedDirectors: TCorporateUser[];
  maxRecipientErr: boolean;
  setMaxRecipientErr: (flag: boolean) => void;
  disabled?: boolean;
}

const AuthorizeSignatory: React.FC<IProps> = ({
  devicetype = "",
  signatories,
  setAddSignatories,
  errors,
  setNoRecipientErr,
  selectedDirectors,
  maxRecipientErr,
  setMaxRecipientErr,
  disabled = false,
}) => {
  const [formErrors, setFormErrors] = useState<ISegnatoryErr>(errors);
  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  useEffect(() => {
    checkAllErrs();
  }, [selectedDirectors, signatories]);

  const checkAllErrs = () => {
    chkDuplicateMobile();
    chkDuplicateEmail();
    chkMaxRecipients();
    setNoRecipientErr(false);
  };

  const chkDuplicateEmail = () => {
    let dEmails = selectedDirectors
      .filter(
        (i) =>
          pipe(
            i.email,
            getOrElse(() => "")
          ) != ""
      )
      .map((i) =>
        pipe(
          i.email,
          getOrElse(() => "")
        )
      );
    let sEmails = signatories.map((i) => i.email);
    let newEmails: string[] = [...dEmails, ...sEmails].filter((i) => i);

    let emailErrors: IErrVal[] = formErrors.email.filter(
      (a) => a.message != "Email ID has already entered"
    );
    sEmails.map((i, k) => {
      if (newEmails.filter((i1) => i1 == i).length > 1) {
        if (k === sEmails.lastIndexOf(i))
          if (emailErrors.filter((a, b) => a.index == k).length == 0)
            emailErrors.push({
              index: k,
              message: "Email ID has already entered",
            });
          else
            emailErrors = emailErrors.map((a, b) => {
              if (a.index == k) a.message = "Email ID has already entered";
              return a;
            });
      }
    });
    setFormErrors((prev) => ({ ...prev, email: emailErrors }));
  };

  const chkDuplicateMobile = () => {
    let dMobiles = selectedDirectors
      .filter(
        (i) =>
          pipe(
            i.mobile,
            getOrElse(() => "")
          ) != ""
      )
      .map((i) =>
        pipe(
          i.mobile,
          getOrElse(() => "")
        )
      );
    let sMobiles = signatories.map((i) => i.mobile);
    let newMobiles: string[] = [...dMobiles, ...sMobiles].filter((i) => i);

    let mobileErrors: IErrVal[] = formErrors.mobile.filter(
      (a) => a.message != "Mobile no. has already entered"
    );
    sMobiles.map((i, k) => {
      if (newMobiles.filter((i1) => i1 == i).length > 1) {
        if (k === sMobiles.lastIndexOf(i))
          if (mobileErrors.filter((a, b) => a.index == k).length == 0)
            mobileErrors.push({
              index: k,
              message: "Mobile no. has already entered",
            });
          else
            mobileErrors = mobileErrors.map((a, b) => {
              if (a.index == k) a.message = "Mobile no. has already entered";
              return a;
            });
      }
      /* else {
        if (
          mobileErrors.filter(
            (a, b) =>
              a.index == k && a.message == "Mobile no. has already entered"
          ).length > 0
        ) {
          mobileErrors = mobileErrors.filter((a, b) => a.index != k);
        }
      } */
    });
    setFormErrors((prev) => ({ ...prev, mobile: mobileErrors }));
  };

  const chkMaxRecipients = () => {
    let flag = false;
    if (signatories.length + selectedDirectors.length >= 3) {
      flag = true;
    }
    setMaxRecipientErr(flag);
    return flag;
  };

  const recipient = {
    userId: "",
    name: "",
    email: "",
    mobile: "",
  };
  const addMore = () => {
    if (!chkMaxRecipients()) setAddSignatories([...signatories, recipient]);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedRecipient = signatories.map((signatory, i) =>
      index == i ? Object.assign(signatory, { [name]: value }) : signatory
    );

    /* if (name == "name") {
      let nameErrors = formErrors.name.filter((i, k) => i.index != index);
      if (value == "") nameErrors.push({ index, message: "Name is required!" });
      else if (!validateName(value))
        nameErrors.push({ index, message: "Invalid name" });
      setFormErrors((prev) => ({ ...prev, name: nameErrors }));
    } */

    /* if (name == "email") {
      let emailErrors = formErrors.email.filter((i, k) => i.index != index);
      if (value == "")
        emailErrors.push({ index, message: "Email ID is required!" });
      else if (!validateEmail(value))
        emailErrors.push({ index, message: "Invalid email ID" });
      setFormErrors((prev) => ({ ...prev, email: emailErrors }));
    } */
    /* if (name == "mobile") {
      let mobileErrors = formErrors.mobile.filter((i, k) => i.index != index);
      if (value == "")
        mobileErrors.push({ index, message: "Mobile no. is required!" });
      else if (!validateMobile(value))
        mobileErrors.push({ index, message: "Invalid mobile no." });
      setFormErrors((prev) => ({ ...prev, mobile: mobileErrors }));
    } */

    setAddSignatories(updatedRecipient);
  };

  const removeSignatory = (index: any) => {
    setFormErrors(() => ({
      email: formErrors.email.filter((i) => i.index != index),
      mobile: formErrors.mobile.filter((i) => i.index != index),
      name: formErrors.name.filter((i) => i.index != index),
    }));

    const filteredSignatory = [...signatories];
    filteredSignatory.splice(index, 1);
    setAddSignatories(filteredSignatory);
  };

  const directors = useAppSelector(selectDirectors);

  return (
    <div
      className={
        devicetype === "desktop"
          ? "card border-0  bg-white rounded-15 mt-3 p-3"
          : "mt-1 p-3 mx-3"
      }
    >
      <div className="row">
        <div className="col-12 pe-3">
          <div className="row">
            <div
              className={
                devicetype === "desktop"
                  ? "d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block text-center"
                  : "card border-0  bg-white rounded-15 d-md-block d-lg-block d-xl-block d-xxl-block text-center pt-3"
              }
            >
              <h6 className="fw-bold text-start ">Authorised user details</h6>
              {signatories.map((signatory, index) => (
                <div className="card border-0 bg-white rounded-15" key={index}>
                  <div className="row">
                    <div className="col-10">
                      <p className="fw-bold text-start">User - {index + 1}</p>
                    </div>
                    <div className="col-2">
                      {!disabled && (
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                          onClick={() => removeSignatory(index)}
                        >
                          &#10006;
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mb-2 ">
                    <InputGroup
                      icon="user"
                      type="text"
                      name="name"
                      onChange={(e) => onChange(e, index)}
                      value={signatory.name}
                      color="bg-lightpink"
                      className="form-control fs-small fw-bold input-field p-2 border-0"
                      placeholder="Enter name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onBlur={(e) => {
                        let nameErrors = formErrors.name.filter(
                          (i, k) => i.index != index
                        );
                        if (e.target.value == "")
                          nameErrors.push({
                            index,
                            message: "Name is required!",
                          });
                        else if (!validateName(e.target.value))
                          nameErrors.push({ index, message: "Invalid name" });
                        setFormErrors((prev) => ({
                          ...prev,
                          name: nameErrors,
                        }));
                      }}
                      disabled={disabled}
                    />
                    {formErrors.name.filter((i) => i.index === index)?.[0]
                      ?.message && (
                      <div className="fs-small ncash_validate-error mt-2">
                        {
                          formErrors.name.filter((i) => i.index === index)?.[0]
                            ?.message
                        }
                      </div>
                    )}
                  </div>
                  <div className="mb-2">
                    <InputGroup
                      icon="mail"
                      type="text"
                      name="email"
                      onChange={(e) => onChange(e, index)}
                      value={signatory.email}
                      color="bg-lightpurple"
                      className="form-control fs-small fw-bold input-field p-2 border-0"
                      placeholder="Enter email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onBlur={(e) => {
                        let emailErrors = formErrors.email.filter(
                          (i, k) => i.index != index
                        );
                        if (e.target.value == "")
                          emailErrors.push({
                            index,
                            message: "Email ID is required!",
                          });
                        else if (!validateEmail(e.target.value))
                          emailErrors.push({
                            index,
                            message: "Invalid email ID",
                          });
                        setFormErrors((prev) => ({
                          ...prev,
                          email: emailErrors,
                        }));
                      }}
                      disabled={disabled}
                    />
                    {formErrors.email.filter((i) => i.index === index)?.[0]
                      ?.message && (
                      <div className="fs-small ncash_validate-error mt-2">
                        {
                          formErrors.email.filter((i) => i.index === index)?.[0]
                            ?.message
                        }
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <InputGroup
                      icon="phone"
                      type="text"
                      name="mobile"
                      maxLength={10}
                      onChange={(e) => onChange(e, index)}
                      value={signatory.mobile}
                      color="bg-lightblue"
                      className="form-control fs-small fw-bold input-field p-2 border-0"
                      placeholder="Enter mobile number"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onBlur={(e) => {
                        let mobileErrors = formErrors.mobile.filter(
                          (i, k) => i.index != index
                        );
                        if (e.target.value == "")
                          mobileErrors.push({
                            index,
                            message: "Mobile no. is required!",
                          });
                        else if (!validateMobile(e.target.value))
                          mobileErrors.push({
                            index,
                            message: "Invalid mobile no.",
                          });
                        setFormErrors((prev) => ({
                          ...prev,
                          mobile: mobileErrors,
                        }));
                      }}
                      disabled={disabled}
                    />
                    {formErrors.mobile.filter((i) => i.index === index)?.[0]
                      ?.message && (
                      <div className="fs-small ncash_validate-error mt-2">
                        {
                          formErrors.mobile.filter(
                            (i) => i.index === index
                          )?.[0]?.message
                        }
                      </div>
                    )}
                  </div>
                </div>
              ))}

              {!disabled && !maxRecipientErr && (
                <div className=" d-flex w-100 justify-content-end">
                  <InlineButton
                    type="button"
                    color="secondary"
                    text=" + Add More "
                    onClick={addMore}
                  />
                </div>
              )}

              {/* {maxRecipientErr ? (
                <div className="fs-small ncash_validate-error mt-2">
                  Maximum receipients has reached
                </div>
              ) : (
                <div className=" d-flex w-100 justify-content-end">
                  <InlineButton
                    type="button"
                    color="secondary"
                    text=" + Add More "
                    onClick={addMore}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizeSignatory;
