import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { RealTimeCurrencyPrice } from "../../../../../model/fx/realTimeCurrencyPrice";
import { updatePriceInStore } from "../../../../../state/slice/fx.slice";
import {
  CurrencyName,
  CurrencyPrice,
  DailyRange,
  RangeBar,
} from "../fx-card/card";

import { motion, AnimatePresence } from "framer-motion";

const itemVariants = {
  hidden: {},
  visible: {},
  transition: { duration: 1, ease: "easeInOut" },
};

const PriceCard: React.FC<{
  price: RealTimeCurrencyPrice;
  dataKey: string;
  selectedCurrency: string;
}> = ({ price, dataKey, selectedCurrency }) => {
  const dispatch = useAppDispatch();
  const [animate, setAnimate] = React.useState(false);

  useEffect(() => {
    setAnimate(true);
    const interval = setInterval(() => setAnimate(false), 2000);

    return () => clearInterval(interval);
  }, [price]);

  return (
    <motion.div
      //   initial={{ opacity: 0, y: -200 }}
      //   animate={{ opacity: 1, y: 0 }}
      //   exit={{ opacity: 1 }}
      animate={animate ? { scale: [1, 0.5, 1] } : { scale: [1, 1, 1] }}
      className="col-12 col-xl-4 col-lg-6 col-sm-6 col-md-6 g-2 cursor-pointer h-100"
      onClick={() => {
        dispatch(updatePriceInStore({ price, currency: dataKey }));
      }}
    >
      <div
        className={`card border shadow-sm rounded-10 ${dataKey === selectedCurrency ? "border-primary" : ""
          } `}
      >
        <div className="p-2">
          <CurrencyName name={price.code ? price.code.replace("_", "/") : ""} />
          <CurrencyPrice
            currentPrice={price.price || 0}
            presentValue={price.changeValue || 0}
            percentageValue={price.changePer || 0}
          />
          {/* <RealTimeNDate
      time={price.time}
      currency={price.code ? price.code.split("_")[1] : ""}
    /> */}
          <DailyRange />
          <RangeBar
            scale={price.scale || 0}
            low={price.low || 0}
            high={price.high || 0}
          />
          {/* <BidNAsk /> */}
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(PriceCard);
