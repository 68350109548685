import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AdminUserInfo } from "../../../../api/employee/model/adminUserInfo";
import { CorporateService } from "../../../../services/corporate.service";
import DashboardPageLoader from "../common/dashboard-page-loader";
import WhiteCard from "../../../white-card/white-card";
import PageTitle from "../../../common/page-title";
import Heading from "../common/heading";
import CardButton from "../../../common/button/card-button";
import SuccessPopup from "../common/succes-popup";
import { AddAdminUserApiRequest } from "../../../../api/employee/model/addAdminUserApiRequest";
import SaveProfileForm from "./SaveProfileForm";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../../state/slice/profile.slice";
import { fixNumberInput } from "../../../../utils/common.utils";

const UserIcon = "/images/icons/user-plus.svg";
const PhoneIcon = "/images/icons/phone.svg";
const EmailIcon = "/images/icons/email-solid.svg";
const LocationIcon = "/images/icons/location-sharp.svg";
const EmpCodeIcon = "/images/icons/emp_code.svg";
const EmpDesgIcon = "/images/icons/emp_designation.svg";
const EmpStatusIcon = "/images/icons/emp_status.svg";
const CalendarIcon = "/images/icons/calendar-solid.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";

interface Imodal {
  show: boolean;
  data: AddAdminUserApiRequest;
}

const DashboardMyProfile: React.FC = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<AddAdminUserApiRequest>({
    mode: "onTouched",
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [actionState, setActionState] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [modal, setModal] = useState<Imodal>({
    show: false,
    data: {
      code: "",
      doj: "",
      name: "",
      mobile: "",
      email: "",
      role: "ROLE_NCAISH_ADMIN",
      address: {
        address: "",
        state: "",
        country: "India",
        pinCode: "",
        //location: { lat: 0, lng: 0 },
      },
      gender: "MALE",
    },
  });
  const [details, setDetails] = useState<AdminUserInfo>({
    uid: "",
    code: "",
    doj: "",
    name: "",
    mobile: "",
    email: "",
    role: "ROLE_NCAISH_ADMIN",
    address: {
      address: "",
      state: "",
      country: "India",
      pinCode: "",
      // location: {
      //   lat: 0,
      //   lng: 0,
      // },
    },
    detaxCard: true,
    nps: true,
  });
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const result = await CorporateService.myProfile();
      setIsLoading(false);
      if (isSuccess(result)) {
        if (result.value.successful) {
          // console.log(result.value.data);
          setDetails((prev) => (prev = result.value.data || prev));
        }
      }
    };
    run();
  }, []);

  const getLocation = (
    address: string | undefined,
    state: string | undefined,
    country: string | undefined,
    pinCode: string | undefined
  ) => {
    let arr: string[] = [];
    if (address) arr.push(address);
    if (state) arr.push(state);
    if (country) arr.push(country);
    let output: string = arr.join(", ").trim().replace(/,$/, "");
    if (pinCode) output = output + " " + pinCode;
    return output;
  };

  const fillData = () => {
    setModal({
      show: true,
      data: {
        code: details?.code || "",
        doj: details?.doj || "",
        name: details?.name || "",
        mobile: details?.mobile || "",
        email: details?.email || "",
        role: details?.role || "ROLE_NCAISH_ADMIN",
        address: {
          address: details.address?.address || "",
          state: details.address?.state || "",
          country: details.address?.country || "",
          pinCode: details.address?.pinCode || "",
          //location: { lat: 0, lng: 0 },
        },
        gender: "MALE",
      },
    });
  };

  const closeModal = () => {
    clearErrors();
    setActionState(false);
    setModal({
      show: false,
      data: {
        code: "",
        doj: "",
        name: "",
        mobile: "",
        email: "",
        role: "ROLE_NCAISH_ADMIN",
        address: {
          address: "",
          state: "",
          country: "India",
          pinCode: "",
          //location: { lat: 0, lng: 0 },
        },
        gender: "MALE",
      },
    });
  };

  const onSubmit: SubmitHandler<AddAdminUserApiRequest> = async (data) => {
    data.address = {
      ...data.address,
      state: "",
      country: "India",
      pinCode: "",
      //location: { lat: 0, lng: 0 },
    };
    data = {
      ...data,
      role: details?.role || "ROLE_NCAISH_ADMIN",
      doj: format(new Date(data.doj), "dd-MM-yyyy"),
    };
    if (details.uid) {
      setActionState(true);
      const result = await CorporateService.update1(data, details.uid);
      setActionState(false);
      if (isSuccess(result)) {
        if (result.value.successful) {
          setDetails((p) => ({ ...p, ...result.value.data }));
          dispatch(setProfile(result.value.data || null));
          closeModal();
          setShowSuccess(true);
        }
      }
    }
  };

  useEffect(() => fixNumberInput(), [watch()])

  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <PageTitle title={`My Profile`} />
      <Heading title="My Profile" />
      <WhiteCard className="p-3">
        <div className="">
          <div className="row">
            <div className="col-11">
              <div className="row">
                {details.name && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-limegreen rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Name </p>
                        <h5 className="font-14 fw-bold mb-0">{details.name}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.mobile && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={PhoneIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 f-13"> Mobile No. </p>
                        <h5 className="font-14 fw-bold mb-0">
                          {details.mobile}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.email && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-purple rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmailIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 f-13"> E-mail </p>
                        <h5 className="font-14 fw-bold mb-0">
                          {details.email}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {getLocation(
                  details.address?.address,
                  details.address?.state,
                  details.address?.country,
                  details.address?.pinCode
                ) != "" && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-2 text-right">
                          <div className="bg-lightorange rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                            <img
                              src={LocationIcon}
                              className="img-fluid ml-auto mr-auto w-60"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                          <p className="mb-0 f-13"> Address </p>
                          <h5 className="font-14 fw-bold mb-0">
                            {getLocation(
                              details.address?.address,
                              details.address?.state,
                              details.address?.country,
                              details.address?.pinCode
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {/* <div className="row mt-3">
                {details.code && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={EmpCodeIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Code </p>
                        <h5 className="font-14 fw-bold mb-0">{details.code}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {details.doj && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-2 text-right">
                        <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1">
                          <img
                            src={CalendarIcon}
                            className="img-fluid ml-auto mr-auto w-60"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 f-13"> Active From </p>
                        <h5 className="font-14 fw-bold mb-0">{details.doj}</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>*/}

            </div>
              {/* <div className="col-1 d-flex justify-content-end">
              {<CardButton onClick={() => fillData()} icon={"edit"} />}
                </div> */}
          </div>
        </div>
      </WhiteCard>
      {modal.show && (
        <SaveProfileForm
          title="Edit Profile"
          modal={modal}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          register={register}
          errors={errors}
          actionState={actionState}
        />
      )}
      <SuccessPopup
        message={`Updated successfully!`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
    </>
  );
};
export default DashboardMyProfile;
