// import Acc from "./../../../public/images/acc.svg";
// const Image1 = fromPublic("./images/br.svg");
// import Acc from "../images/fx-header.svg";
import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AAService } from "../../../../services/account-aggregator.service";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import "../account-aggregator-pages/aa.css";
import Heading from "../common/heading";

export const AccountAggregator = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState({
    isAnyConsentPresent: false,
    redirectionUrl: "",
  });
  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const result = await AAService.initiateFiuRequest();
      if (isSuccess(result)) {
        if (result.value.successful) {
          setRedirect({
            isAnyConsentPresent: result.value.isAnyConsentPresent || false,
            redirectionUrl: result.value.redirectionUrl || "",
          });
        }
      }
      setIsLoading(false);
    };
    run();
  }, []);

  useEffect(() => {
    if (redirect.isAnyConsentPresent) navigate(redirect.redirectionUrl);
  }, [redirect]);

  // if (isLoading) {
  //   return <DashboardPageLoader />;
  // }

  return (
    <>
      <PageTitle title="Account Aggregator" />
      <Heading title="Account Aggregator" />
      <div className="shadow-sm text-center bg-white round-less margin-first pb-4 p-5">
        <img
          src={process.env.PUBLIC_URL + "/images/aa-start.svg"}
          className="w-25 mb-3 img-fluid"
          alt=""
        />
        <h4>
          {" "}
          Welcome to <span className="fw-bold"> Account Aggregator </span>{" "}
        </h4>
        <p className="fs-6">
          {" "}
          Lorem ipsum is the dummy placeholder text commonly used to demonstrate
          the visual form of a document or a typeface without relying on
          meaningful content.{" "}
        </p>
        <Link
          to={RoutePaths.DashboardAAaccountSection}
          className="btn btn-darkblue mt-2 text-white"
        >
          Start Now
        </Link>
      </div>
    </>
  );
};
