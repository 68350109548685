/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { BaseResponse } from "../model/baseResponse";
import {
  CreateTransactionBatchRequest,
  CreateTransactionBatchRequestNps,
} from "../model/createTransactionBatchRequest";
import { DetaxAdminBatchDetailResponse } from "../model/detaxAdminBatchDetailResponse";
import { DetaxAdminBatchListResponse } from "../model/detaxAdminBatchListResponse";
import { DetaxBatchAddNewMemberRequest } from "../model/detaxBatchAddNewMemberRequest";
import { DetaxBatchCountApiResponse } from "../model/detaxBatchCountApiResponse";
import { DetaxBatchRemoveMemberRequest } from "../model/detaxBatchRemoveMemberRequest";
import { DetaxCardRechargeEligibleUsersResponse } from "../model/detaxCardRechargeEligibleUsersResponse";
import { DetaxTransactionBatchDetailResponse } from "../model/detaxTransactionBatchDetailResponse";
import { DetaxTransactionBatcheListResponse } from "../model/detaxTransactionBatcheListResponse";
import { GetDetaxTransactionBatchMembersResponse } from "../model/getDetaxTransactionBatchMembersResponse";
import { InitiateDetaxBatchApiRequest } from "../model/initiateDetaxBatchApiRequest";
import { NpsRechargeEligibleUsersResponse } from "../model/npsRechargeEligibleUsersResponse";
import { UpdateBatchApiRequest } from "../model/updateBatchApiRequest";
import { UserPermissionResponse } from "../model/userPermissionResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishTransactionBatchApiService {
  /**
   *
   * @param body -
   * @param id -
   */
  export const addNewBatchMembers = async (
    body: DetaxBatchAddNewMemberRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetDetaxTransactionBatchMembersResponse>> => {
    const path = `/secure/detax/batches/${encodeURIComponent(
      String(id)
    )}/members`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getDownloadTemplate = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ArrayBuffer>> => {
    const path =
      `/secure/detax/batches/fba/eligible/users/downloadExcel`.replace(
        /^\//,
        ""
      );

    const allQueryParams: TQueryParam[] = [];

    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
      responseType: "arraybuffer",
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const approveBatch = async (
    body: UpdateBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxAdminBatchDetailResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(
      String(id)
    )}/approve`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const cancelBatchByCorporate = async (
    body: UpdateBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/detax/corporate/batches/${encodeURIComponent(
      String(id)
    )}/cancel`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const cancelNpsBatchByCorporate = async (
    body: UpdateBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `secure/nps/corporate/batches/${encodeURIComponent(
      String(id)
    )}/cancel`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to create FBA,TA and NPS Recharge batche
   * @param body -
   */
  export const createTransactionBatch = async (
    body: CreateTransactionBatchRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/detax/batches`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  // Create Batch For NPS
  /**
   * Api to create FBA,TA and NPS Recharge batche
   * @param body -
   */
  export const createTransactionBatchNps = async (
    body: CreateTransactionBatchRequestNps,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/nps/batches`.replace(/^\//, "");

    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const deleteBatchMembers = async (
    body: DetaxBatchRemoveMemberRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/detax/batches/${encodeURIComponent(
      String(id)
    )}/members`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "delete",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const executeBatch = async (
    body: UpdateBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxAdminBatchDetailResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(
      String(id)
    )}/execute`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param id -
   */
  export const getBatchByCorporateAndId = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/detax/corporate/batches/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  // NPS BATCH DETAILS
  /**
   *
   * @param id -
   */
  export const getBatchByCorporateAndIdNPS = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/nps/corporate/batches/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param type -
   * @param status -
   * @param cid -
   * @param from -
   * @param to -
   */
  export const getBatchesByStatus = async (
    type: string,
    status?: string,
    cid?: string,
    from?: string,
    to?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxAdminBatchListResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(
      String(type)
    )}/get`
      .replace(/^\//, "")
      .replace("{" + "type" + "}", String(type));
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "cid",
        value: cid,
      },
      {
        key: "from",
        value: from,
      },
      {
        key: "to",
        value: to,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param type -
   * @param status -
   */
  export const getCorporateBatchByStatus = async (
    type: string,
    status?: string,
    pn?: number,
    rc?: number,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatcheListResponse>> => {
    const path = `/secure/detax/corporate/batches/${encodeURIComponent(
      String(type)
    )}/get`
      .replace(/^\//, "")
      .replace("{" + "type" + "}", String(type));
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  // NPS LIST THE TABLE BASED ON THE BATCH STATUS
  /**
   *
   * @param type -
   * @param status -
   */
  export const getCorporateBatchByStatusNPS = async (
    status?: string,
    pn?: number,
    rc?: number,

    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatcheListResponse>> => {
    const path = `/secure/nps/corporate/batches/get`.replace(/^\//, "");
    // .replace("{" + "type" + "}", String(type));
    const allQueryParams: TQueryParam[] = [
      {
        key: "status",
        value: status,
      },
      {
        key: "pn",
        value: pn,
      },
      {
        key: "rc",
        value: rc,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param type -
   */
  export const getCorporateDetaxBatchCounts = async (
    type: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxBatchCountApiResponse>> => {
    const path = `/secure/detax/batches/${encodeURIComponent(
      String(type)
    )}/status/counts`
      .replace(/^\//, "")
      .replace("{" + "type" + "}", String(type));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  // NPS API
  /**
   *
   * @param type -
   */
  export const getCorporateDetaxBatchCountsNPS = async (
    type: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxBatchCountApiResponse>> => {
    const path = `/secure/nps/batches/status/counts`
      .replace(/^\//, "")
      .replace("{" + "type" + "}", String(type));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param id -
   */
  export const getCorporateTransactionBatchMembers = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetDetaxTransactionBatchMembersResponse>> => {
    const path = `/secure/detax/corporate/batches/${encodeURIComponent(
      String(id)
    )}/members`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  // MEMBER TABLE FOR NPS BATCH
  /**
   *
   * @param id -
   */
  export const getCorporateTransactionBatchMembersNPS = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetDetaxTransactionBatchMembersResponse>> => {
    const path = `/secure/nps/corporate/batches/${encodeURIComponent(
      String(id)
    )}/members`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [
      {
        key: "pn",
        value: 0,
      },
      {
        key: "rc",
        value: 0,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param type -
   */
  export const getDetaxBatchCounts = async (
    type: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxBatchCountApiResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(
      String(type)
    )}/status/counts`
      .replace(/^\//, "")
      .replace("{" + "type" + "}", String(type));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param keyword -
   */
  export const getFbaEligibleUsers = async (
    keyword?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardRechargeEligibleUsersResponse>> => {
    const path = `/secure/detax/batches/fba/eligible/users/search`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    console.log("In getFbaEligibleUsers");
    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param keyword -
   */
  export const getTaReimbursementEligibleUsers = async (
    keyword?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardRechargeEligibleUsersResponse>> => {
    const path = `/secure/detax/batches/ta/eligible/users/search`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const getExpenseAdvanceEligibleUsers = async (
    keyword?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxCardRechargeEligibleUsersResponse>> => {
    const path = `/secure/detax/batches/ta/eligible/users/search`.replace(
      /^\//,
      ""
    );
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    console.log("Inside getExpenseAdvanceEligibleUsers");
    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param id -
   */
  export const getTransactionBatchById = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxAdminBatchDetailResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param batchId -
   */
  export const getTransactionBatchMembers = async (
    batchId: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetDetaxTransactionBatchMembersResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(
      String(batchId)
    )}/members`
      .replace(/^\//, "")
      .replace("{" + "batchId" + "}", String(batchId));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const initiatebatch = async (
    body: InitiateDetaxBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/detax/corporate/batches/${encodeURIComponent(
      String(id)
    )}/initiate`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const initiateNpsbatch = async (
    body: InitiateDetaxBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxTransactionBatchDetailResponse>> => {
    const path = `/secure/nps/corporate/batches/${encodeURIComponent(
      String(id)
    )}/initiate`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param keyword -
   */
  export const npsRechargeEligibleUsers = async (
    keyword?: string,
    month?: string,
    year?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, NpsRechargeEligibleUsersResponse>> => {
    // /secure/detax/batches/nps/eligible/users/search
    const path = `/secure/nps/batches/eligible/users/search`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "keyword",
        value: keyword,
      },
      {
        key: "month",
        value: month,
      },
      {
        key: "year",
        value: year,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const rejectbatch = async (
    body: UpdateBatchApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, DetaxAdminBatchDetailResponse>> => {
    const path = `/secure/detax/admin/batches/${encodeURIComponent(
      String(id)
    )}/reject`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const updateBatchMember = async (
    body: DetaxBatchAddNewMemberRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetDetaxTransactionBatchMembersResponse>> => {
    const path = `/secure/detax/batches/${encodeURIComponent(
      String(id)
    )}/members`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const userPermissionCorporate = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UserPermissionResponse>> => {
    const path = `/secure/corporates/role/permissions`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
