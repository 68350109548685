export enum ConvertKind {
  FROM = "FROM",
  TO = "TO",
  AMOUNT = "AMOUNT",
  CONVERTED_AMOUNT = "CONVERTED_AMOUNT",
}

export enum SavingKind {
  FROM = "FROM",
  TO = "TO",
  AMOUNT = "AMOUNT",
  DATE = "DATE",
  RATE_TYPE = "RATE_TYPE",
  SAVING_AMOUNT = "SAVING_AMOUNT",
  DAY_RANGE = "DAY_RANGE",
  BANK_CREDITED = "BANK_CREDITED",
}
