/**
 * User apis
 * Apis to manage employees, corporate admin user and ncaish admin users
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { AddAdminUserApiRequest } from "../model/addAdminUserApiRequest";
import { AdminUserDetailApiResponse } from "../model/adminUserDetailApiResponse";
import { AdminUserListApiResponse } from "../model/adminUserListApiResponse";
import { BaseResponse } from "../model/baseResponse";
import { Pagination } from "../model/pagination";
import { AdminAlphaReleaseApiResponse } from "../model/adminAlphaReleaseApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishCorporateAdminApiService {
  /**
   *
   * @param body -
   */
  export const create1 = async (
    body: AddAdminUserApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminUserDetailApiResponse>> => {
    const path = `/secure/corporates/admin`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param id -
   */
  export const getAdminUserById = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminUserDetailApiResponse>> => {
    const path = `/secure/corporates/admin/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const alphaRelease = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminAlphaReleaseApiResponse>> => {
    const path = `/secure/corporates/display`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param pagination -
   * @param status -
   */
  export const getAll1 = async (
    pagination: Pagination,
    status?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminUserListApiResponse>> => {
    const path = `/secure/corporates/admin`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //         {
    //             key: 'status',
    //             value: status
    //         },
    //         {
    //             key: 'pagination',
    //             value: pagination
    //         },
    // ];
    // const queryParams = allQueryParams
    //         .map(param => `${param.key}=${param.value}`)
    //         .join('&');

    const apiUrl = path.concat(
      `?page=${pagination.page}&limit=${pagination.limit}&status=${status}`
    );
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const myProfile = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminUserDetailApiResponse>> => {
    const path = `/secure/corporates/admin/myProfile`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param id -
   */
  export const removeAdminUser = async (
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, BaseResponse>> => {
    const path = `/secure/corporates/admin/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "delete",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   * @param id -
   */
  export const update1 = async (
    body: AddAdminUserApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AdminUserDetailApiResponse>> => {
    const path = `/secure/corporates/admin/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
