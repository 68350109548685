import { isSuccess } from "@devexperts/remote-data-ts";
import { option } from "fp-ts";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AuthService } from "../../services/auth.service";
import { Dispatch } from "../../state/dispatch";
import { fromPublic } from "../../utils/common.utils";
import SpinnerComponent from "../common/loader/spinner";
import Heading from "../heading/heading";
import MediumScreen from "../responsive-container/medium-screen";
import SmallScreen from "../responsive-container/small-screen";
import { useNavigate } from "react-router-dom";
import { OAuthLoginApiService } from "../../api/auth/oauthLoginApi.service";
import { MyStorage } from "../../utils/local-storage.utils";

import { BrandHeader } from "../brandlogo-header/brandlogo-header";

import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const Image3 = fromPublic("/images/reset_password.svg");

const MustChangePassword: React.FC = () => {
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [actionState, setActionState] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    let payload = {
      password: currentPassword,
      newPassword: newPassword,
    };
    // console.log(payload)
    setError("");
    if (newPassword !== "") {
      if (newPassword === confirmPassword) {
        if (newPassword.length >= 6 && newPassword.length <= 30) {
          setIsLoading(true);
          setActionState(true);
          const result = await AuthService.changePassword(payload);
          setIsLoading(false);
          setActionState(true);
          if (isSuccess(result)) {
            if (result.value.successful) {
              localStorage.clear();
              navigate("/login");
            } else {
              setError(result.value.message || "");
            }
          } else {
            //setError("Something went wrong");
            setActionState(false);
          }
        } else {
          setError("Password must be between 6 and 30 characters");
          setActionState(false);
        }
      } else {
        setError("Password and Confirm Password does not match");
        setActionState(false);
      }
    } else {
      setError("Password is required");
      setActionState(false);
    }
  };

  const handleLogout = async () => {
    //const result = await AuthService.revoke();
    //if (isSuccess(result)) {
    localStorage.clear();
    navigate("/login");
    //} else {
    // console.log("logout api call failed");
    //}
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordInput2, setPasswordInput2] = useState("");

  const [passwordType3, setPasswordType3] = useState("password");
  const [passwordInput3, setPasswordInput3] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePasswordCon = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };
  const togglePasswordConp = () => {
    if (passwordType3 === "password") {
      setPasswordType3("text");
      return;
    }
    setPasswordType3("password");
  };


  return (
    <>
      <Helmet>
        <title>Change Password - Ncaish</title>
      </Helmet>
      <div className="container p-0">
      <BrandHeader />
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image3}
                    alt="Mobile"
                  />
                </div>
                <div className="mt-5 textDetail">
                  <Heading
                    className="mb-3 mt-85"
                    text="Change Password"
                    variant="small"
                  />

                  <p className="fw-bolder text-secondary">
                    Secure your account by providing a really strong password.
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  {showSuccess === false ? (
                    <div className="px-3 py-2 border-0 shadow card bg-grey rounded-15">
                      <Heading
                        className="mt-5 text-center"
                        text="Change Password"
                        variant="large"
                      />
                      {error && (
                        <div
                          className="text-center alert alert-danger fw-bold"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      <div className="mt-5 form-group">
                        <label htmlFor="currentPassword">
                          Current Password
                        </label>
                        <div className="inputWithButton">
                        <input
                          id="currentPassword"
                          className="form-control"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          maxLength={30}
                          type={passwordType}
                        />
                        <button
                  className="eye-button"
                  onClick={togglePassword}
                  type="button"

                >
                  {passwordType === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>

                        </div>
                      </div>
                      <div className="mt-3 mb-3 form-group">
                        <label htmlFor="newPassword">New Password</label>
                        <div className="inputWithButton">
                        <input
                          id="newPassword"
                          className="form-control"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          maxLength={30}
                          type={passwordType2}
                        />
                        <button
                  className="eye-button"
                  onClick={togglePasswordCon}
                  type="button"
                >
                  {passwordType2 === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
                        </div>
                      </div>
                      <div className="mb-3 form-group">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <div className="inputWithButton">
                        <input
                          id="confirmPassword"
                          className="form-control"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          maxLength={30}
                          type={passwordType3}
                        />
                                 <button
                  className="eye-button"
                  onClick={togglePasswordConp}
                  type="button"
                >
                  {passwordType3 === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
                      </div>
                      </div>
                      <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                        <Button
                          type="button"
                          className={
                            "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                          }
                          disabled={actionState}
                          onClick={handleSubmit}
                        >
                          {isLoading ? (
                            <SpinnerComponent />
                          ) : (
                            <>Change Password</>
                          )}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="px-3 py-2 border-0 shadow card bg-grey rounded-15">
                      <Heading
                        className="mt-5 text-center"
                        text="Change Password"
                        variant="large"
                      />

                      <div
                        className="text-center alert alert-success fw-bold"
                        role="alert"
                      >
                        {"Password has changed successfully."}
                      </div>

                      <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                        <Button
                          type="button"
                          className={
                            "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                          }
                          onClick={() => {
                            Dispatch.App.setPasswordChanged(option.some(true));
                            handleLogout();
                          }}
                        >
                          Go To Login
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              {showSuccess === false ? (
                <div className="p-3 border-0 shadow card bg-grey">
                  <Heading
                    className="mt-5 text-center"
                    text="Change Password"
                    variant="large"
                  />
                  {error && (
                    <div
                      className="text-center alert alert-danger fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="mt-5 mb-3 form-group">
                    <label htmlFor="currentPassword">Current Password</label>
                    <div className="inputWithButton">
                    <input
                      id="currentPassword"
                      className="form-control"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      type={passwordType}
                    />
                                    <button
                  className="eye-button"
                  onClick={togglePassword}
                  type="button"

                >
                  {passwordType === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
                    </div>
                  </div>
                  <div className="mt-3 mb-3 form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <div className="inputWithButton">
                    <input
                      id="newPassword"
                      className="form-control"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type={passwordType2}
                    />
                                    <button
                  className="eye-button"
                  onClick={togglePasswordCon}
                  type="button"
                >
                  {passwordType2 === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
                    </div>
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="inputWithButton">
                    <input
                      id="confirmPassword"
                      className="form-control"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type={passwordType3}
                    />
                                 <button
                  className="eye-button"
                  onClick={togglePasswordConp}
                  type="button"
                >
                  {passwordType3 === "password" ? (
                    <span>
                      {" "}
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>
                  </div>
                  </div>
                  <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                    <Button
                      type="button"
                      className={
                        "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                      }
                      disabled={actionState}
                      onClick={handleSubmit}
                    >
                      {isLoading ? <SpinnerComponent /> : <>Change Password</>}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="px-3 py-2 border-0 shadow card bg-grey rounded-15">
                  <Heading
                    className="mt-5 text-center"
                    text="Change Password"
                    variant="large"
                  />

                  <div
                    className="text-center alert alert-success fw-bold"
                    role="alert"
                  >
                    {"Password has changed successfully."}
                  </div>

                  <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                    <Button
                      type="button"
                      className={
                        "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                      }
                      onClick={() => {
                        Dispatch.App.setPasswordChanged(option.some(true));
                        handleLogout();
                      }}
                    >
                      Go To Login
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </SmallScreen>
        </div>
      </div>
    </>
  );
};

export default MustChangePassword;
