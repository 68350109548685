import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import React, { useState } from "react";
import { NpsSubscriptionInfo } from "../../../../api/detax/model/npsSubscriptionInfo";
import { APIError } from "../../../../api/errors";
import { NpsService } from "../../../../services/nps.service";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import { Button } from "react-bootstrap";

const NpsExport: React.FC<{
  curTbl?: NpsSubscriptionInfo;
  uid?: string;
}> = ({ curTbl, uid = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<APIError>();

  const getData = async () => {
    const result = await NpsService.getNpsPranReMapping(1, 100000);

    if (isSuccess(result)) {
      const url = window.URL.createObjectURL(new Blob([result.value]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `NpsReMapping ${+new Date()}.csv`; // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else if (isFailure(result)) {
    }
  };

  // const getData = async () => {
  //   let data: NpsSubscriptionInfo[] | [] = [];
  //   setError(undefined);
  //   setIsLoading(true);
  //   const tblResult = await NpsService.getNpsSubscriptions(
  //     curTbl?.status || "",
  //     0,
  //     0
  //   );
  //   setIsLoading(false);
  //   if (isSuccess(tblResult)) {
  //     if (tblResult.value.successful) {
  //       data = tblResult.value.data || [];
  //     }
  //   } else if (isFailure(tblResult)) {
  //     setError(tblResult.error);
  //   }
  //   return data;
  // };

  // const exportToExcel = async () => {
  //   let data = await getData();

  //   let cols: string[] = [
  //     "Invite Code",
  //     "Employee Name",
  //     "Mobile",
  //     "E-mail",
  //     "Salary",
  //     "Monthly Contribution",
  //     "Status",
  //   ];

  //   let content: any[] = [];
  //   data.map((item, index) => {
  //     content = [
  //       {
  //         ["Invite Code"]: item.personId === "" ? "-" : item.personId,
  //         ["Employee Name"]: item.name === "" ? "-" : item.name,
  //         ["Mobile"]: item.mobile === "" ? "-" : item.mobile,
  //         ["E-mail"]: item.email === "" ? "-" : item.email,
  //         ["Salary"]: item.salary,
  //         ["Monthly Contribution"]: item.monthlyContribution,
  //         ["Status"]: getTitleCase(item.status || ""),
  //       },
  //       ...content,
  //     ];
  //   });

  //   let output = [
  //     {
  //       sheet: "Sheet1",
  //       columns: cols.map((item) => ({ label: item, value: item })),
  //       content,
  //     },
  //   ];
  //   xlsx(output, { fileName: "NPS" });
  // };

  if (error) {
    return <div>{error.outcome}</div>;
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {/* <ReuseIcon className="mx-1">
        <svg
          className="comman-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="47.54"
          height="43.884"
          viewBox="0 0 47.54 43.884"
          onClick={() => getData()}
        >
          <path
            id="download"
            d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
            fill="#103458"
          />
        </svg>
      </ReuseIcon> */}
      <div style={{ width: "85%" }}>
        <p style={{ margin: 0, marginBottom: 10 }}>
          <b>Step 1 :</b> The following list of employees have an existing PRAN
          with other CRA's.{" "}
          <a
            style={{
              color: "#007ee0",
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            onClick={() => getData()}
          >
            Download the report
          </a>{" "}
          to submit to the CRA.
          {"  "}
          <svg
            className="comman-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 47.54 43.884"
            onClick={() => getData()}
            style={{ cursor: "pointer" }}
          >
            <path
              id="download"
              d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
              fill="#103458"
            />
          </svg>
        </p>
        <p style={{ margin: 0, marginBottom: 10 }}>
          <b>Step 2 :</b> Once the employees are migrated to KFintech CRA, click
          on "Activate" beside the corresponding user to activate them in the
          Ncash platform.
        </p>
      </div>
      {/* <div
        style={{
          width: "15%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          className="btn-gradiant-blue btn-lg text-white fs-small m-1"
          onClick={() => getData()}
        >
          Download Report
        </Button>
      </div> */}
    </div>
  );
};

export default NpsExport;
