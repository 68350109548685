import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, fromNullable, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { EmployeeInfo } from "../../../../api/employee/model/employeeInfo";
import { UserStatusCountInfo } from "../../../../api/employee/model/userStatusCountInfo";
import { APIError } from "../../../../api/errors";
import { EmployeeService } from "../../../../services/employee.service";
import {
  resetDashboardRedirect,
  selectDashboardRedirect,
} from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import { getTitleCase } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";

const EmployeesTable: React.FC<{
  searchVal?: string;
  curTbl?: TStatusCountInfo | null;
  reload?: boolean;
  setReload?: () => void;
  setData?: (data: EmployeeInfo[] | []) => void;
  newlyAdded?: boolean;
  getSearch?: boolean;
  setGetSearch: (data: boolean) => any;
}> = ({
  searchVal = "",
  curTbl,
  newlyAdded = false,
  reload = false,
  setReload = () => {},
  setData = () => {},
  getSearch,
  setGetSearch,
}) => {
  const dispatch = useDispatch();
  const { page, card } = useSelector(selectDashboardRedirect);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<APIError>();
  const [employees, setEmployees] = useState<Option<EmployeeInfo[]>>(none);
  const [filteredData, setFilteredData] =
    useState<Option<EmployeeInfo[]>>(none);
  const [lastPage, setLastPage] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [paging, setPaging] = useState<ITableData>({
    total: 10,
    page: 1,
    limit: 10,
  });
  const [prevCurTbl, setPrevCurTbl] = useState(curTbl);
  useEffect(() => {
    console.log("Changed");
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [getSearch, curTbl]);
  useEffect(() => {
    const run = async () => {
      // if (reload) {
      setFirstLoad(false);
      // setReload();
      // }
      getData();
      dispatch(resetDashboardRedirect());
    };
    if (searchVal.length === 0) {
      run();
    } else {
      getSearchData();
    }
  }, [paging.page, paging.limit, paging.total]);

  // useEffect(() => {
  //   // getSearchData();
  // }, [searchVal.length && searchVal.length > 0]);

  const getData = async () => {
    setIsLoading(true);
    setError(undefined);
    setFilteredData(none);
    let cardStatus = page === RoutePaths.EmployeesAdd ? card : curTbl?.status;
    const tblResult = await EmployeeService.getAllEmployeeData(
      cardStatus ? cardStatus : "",
      paging.page,
      paging.limit
    );
    setIsLoading(false);
    if (isSuccess(tblResult)) {
      if (tblResult.value.successful) {
        setPaging((p) => ({
          ...p,
          total: tblResult.value.recordCount || 10,
          page: paging.page,
          limit: paging.limit,
        }));
        setEmployees(some(tblResult.value.data || []));
        // filterData(some(tblResult.value.data || []));
        setFilteredData(some(tblResult.value.data || []));
        setData(tblResult.value.data || []);
        setLastPage(tblResult.value.hm || false);
      }
    } else if (isFailure(tblResult)) {
      setError(tblResult.error);
    }
  };

  const getSearchData = async () => {
    setGetSearch(false);
    setIsLoading(true);
    setError(undefined);
    let cardStatus = page === RoutePaths.EmployeesAdd ? card : curTbl?.status;
    const tblResult = await EmployeeService.getSearchEmployeeData(
      cardStatus ? cardStatus : "",
      searchVal,
      paging.page,
      paging.limit
    );
    setIsLoading(false);
    if (isSuccess(tblResult) && searchVal.length > 0) {
      if (tblResult.value.successful) {
        setPaging((p) => ({
          ...p,
          total: tblResult.value.recordCount || 10,
          page: paging.page,
          limit: paging.limit,
        }));
        setEmployees(some(tblResult.value.data || []));
        setFilteredData(some(tblResult.value.data || []));
        setData(tblResult.value.data || []);
        setLastPage(tblResult.value.hm || false);
      }
    } else if (isFailure(tblResult)) {
      setError(tblResult.error);
    }
  };

  const tblData = (tblData: EmployeeInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    // return result;
    return tblData;
  };

  // useEffect(() => filterData(employees), [searchVal]);
  // const filterData = (allData: Option<EmployeeInfo[]>) => {
  //   let data = pipe(
  //     allData,
  //     fold(
  //       () => [],
  //       (d) => (d.length > 0 ? d : [])
  //     )
  //   );
  //   if (searchVal) {
  //     let empCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as employee code
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //     let filteredData = data.filter(
  //       (item) =>
  //         nameRegEx.test(item?.name || "") ||
  //         mobRegEx.test(item?.mobile || "") ||
  //         empCodeRegEx.test(item.code || "")
  //     );
  //     setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(some(filteredData));
  //   } else {
  //     setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(allData);
  //   }
  // };

  if (isLoading && !newlyAdded) {
    return <TableLoader />;
  }

  if (error) {
    return <div>{error.outcome}</div>;
  }
  return (
    <>
      {pipe(
        filteredData,
        fold(
          () => [<NoTableData />],
          (employees) =>
            employees.length > 0
              ? [
                  <TablePagination
                    data={paging}
                    changePage={(data) =>
                      setPaging((p) => ({ ...p, page: data }))
                    }
                    changeLimit={(data) =>
                      setPaging((p) => ({ ...p, limit: data, page: 1 }))
                    }
                  >
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">E-mail</th>
                          <th scope="col">Created At</th>
                          <th scope="col">Updated At</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tblData(employees).map((item, key) => (
                          <tr key={key}>
                            <td className="align-table-data-left">
                              <Link
                                className="text-secondary ncash_nav-link"
                                to={
                                  RoutePaths.EmployeesDetail +
                                    "/" +
                                    item.empId || ""
                                }
                              >
                                {!item.code ? "-" : item.code}
                              </Link>
                            </td>
                            <td className="align-table-data-left">
                              {item.name || "-"}{" "}
                            </td>
                            <td>{item.mobile || "-"}</td>
                            <td className="align-table-data-left">
                              {item.email || "-"}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.onboardedAt || "-"}
                            </td>
                            <td className="text-center text-nowrap">
                              {item.updatedAt || "-"}
                            </td>
                            <td className="align-table-data-left">
                              {item.status && (
                                <StatusText
                                  text={getTitleCase(item.status || "")}
                                  className={classNames({
                                    color6: item.status === "INACTIVE",
                                    color7: item.status === "ACTIVE",
                                    color8: item.status === "BLOCKED",
                                  })}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TablePagination>,
                ]
              : [<NoTableData />]
        )
      )}
    </>
  );
};

export default EmployeesTable;
