import { isSuccess, RemoteData } from "@devexperts/remote-data-ts";
import { useQuery } from "react-query";
import { APIError } from "../api/errors";
import { RealTimeCurrencyPrice } from "../model/fx/realTimeCurrencyPrice";
import { FxService } from "../services/fx.service";
import { updateSelectedPriceInStore } from "../state/slice/fx.slice";
import { store } from "../state/store";
import { TRealTimePriceResponse } from "../types/fx/fx-realtime-price-response.type";

export const useGetRealTimePriceQuery = () => {
  const { selectedPriceCurrency } = store.getState().fx;
  return useQuery<{ [key: string]: RealTimeCurrencyPrice }, Error>(
    "getPrice",
    async () => {
      const result = await FxService.realTimePrice();
      if (isSuccess(result)) {
        if (result.value.successful) {
          store.dispatch(
            updateSelectedPriceInStore(
              result.value.prices[selectedPriceCurrency]
            )
          );
          return result.value.prices;
        } else {
          return {};
        }
      } else {
        return {};
      }
    },
    {
      refetchInterval: 20000, // refetch on 20 secs
    }
  );
};
