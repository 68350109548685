import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { EmployeeInfo } from "../../../../api/detax/model/employeeInfo";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import { isFailure, isSuccess } from "@devexperts/remote-data-ts";

import DashboardPageLoader from "../common/dashboard-page-loader";
import EmployeeDetails from "../common/employee-details";
import Heading from "../common/heading";
import { useDispatch } from "react-redux";
import { MembershipBatchTableInfo } from "../../../../api/detax/model/membershipbatchtableInfo";
import { EmployeeService } from "../../../../services/employee.service";
import WhiteCard from "../../../white-card/white-card";
import EmployeeSalaryForm from "../../../modal/employees/EmployeeSalaryForm";
import { currencyFormat } from "../../../../utils/format.utils";

const UserIcon = "/images/line_icons/Employee_name.svg";
const PhoneIcon = "/images/line_icons/Mobile_No.svg";
const EmailIcon = "/images/line_icons/Email.svg";
const GenderIcon = "/images/line_icons/Gender.svg";
const EmpCodeIcon = "/images/line_icons/Employee_Code.svg";
const EmpStatusIcon = "/images/line_icons/Status.svg";
const AddressIcon = "/images/line_icons/Address.svg";
const CityIcon = "/images/line_icons/City.svg";
const CountryIcon = "/images/line_icons/Country.svg";
const StateIcon = "/images/line_icons/State.svg";
const PinCodeIcon = "/images/line_icons/PinCode.svg";

const DOBIcon = "/images/line_icons/Date_of_Birth.svg";
const ActiveFromIcon = "/images/line_icons/Active_From.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";

export const DashboardMembershipsDetails: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState("card");
  const [activeNPStab, setActiveNPStab] = useState("PranAccount");
  const [activeHtab, setActiveHtab] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<EmployeeInfo>({
    empId: params.id,
    code: "",
    doj: "",
    dob: "",
    name: "",
    mobile: "",
    email: "",
    membershipType: "",
    salaryInfo: {
      mode: "MONTHLY",
      salary: 0,
      basicSalary: 0,
      allowance: 0,
    },
    status: "ACTIVE",
    displayStatus: "",
    address: {
      address: "",
      country: "India",
      state: "",
      city: "",
      pinCode: "",
    },
    detaxCard: true,
    nps: true,
  });

  useEffect(() => {
    if (params?.id) {
      loadData(params.id || "");
      navigate(RoutePaths.DashboardMembershipsDetails + "/" + params.id);
    }
  }, [params.id]);

  const loadData = async (id: string) => {
    const result = await EmployeeService.getById(id);
    if (isSuccess(result)) {
      setEmployee((p) => ({ ...p, ...result.value.data }));
      if (result.value.data?.detaxCard) setActiveHtab("detaxCard");
      else if (result.value.data?.nps) setActiveHtab("nps");
      else setActiveHtab("");
    }
    setIsLoading(false);
  };
  if (isLoading) {
    return <DashboardPageLoader />;
  }

  return (
    <>
      <PageTitle title={`Employee details | ${employee.name}`} />
      <Heading
        title="Employee details"
        detail={employee.name}
        status={""}
        links={[RoutePaths.DashboardMemberships]}
      />

      {/*   {params.id && (
        <EmployeeDetails
          id={params.id}
          loadEmployee={(employee) => setEmployee(employee)}
          showAddress={false}
        />
      )} */}
      <WhiteCard className="p-3">
        <div className="">
          <div className="row">
            <div className="col-11">
              <div className="row">
                <h4 className="f-14 text-secondary">Personal details</h4>
                {employee.name && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={UserIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Name </p>
                        <h5 className="font-14 mb-0">{employee.name}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {employee.mobile && (
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={PhoneIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Mobile No. </p>
                        <h5 className="font-14 mb-0">{employee.mobile}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {employee.email && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmailIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> E-mail </p>
                        <h5 className="font-14 mb-0">{employee.email}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {employee.gender && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={GenderIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Gender </p>
                        <h5 className="font-14 mb-0">{employee.gender}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {employee.membershipType && (
                  <div className=" col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={GenderIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Membership </p>
                        <h5 className="font-14 mb-0">
                          {employee.membershipType}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {/* )
              </div>
              <div className="row mt-3">
                {details.code && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpCodeIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Code </p>
                        <h5 className="font-14 mb-0">{details.code}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="col-12 col-md-3">
                    <div className="row">
                        <div className="col-2 col-md-2 text-right">
                        <div className="bg-green rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                            src={EmpDesgIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                            />
                        </div>
                        </div>
                        <div className="col-9 col-md-9 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Designation </p>
                        <h5 className="font-14 mb-0">
                            {" "}
                            Product Manager{" "}
                        </h5>
                        </div>
                    </div>
                </div> */}
                {employee.displayStatus && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={EmpStatusIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Employee Status </p>
                        <h5 className="font-14 mb-0">
                          {employee.displayStatus}
                        </h5>
                      </div>
                    </div>
                  </div>
                )}
                {employee.dob && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={DOBIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Date Of Birth </p>
                        <h5 className="font-14 mb-0">{employee.dob}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {employee.doj && (
                  <div className="col-12 col-md-3">
                    <div className="row">
                      <div className="col-2 col-md-3 text-right">
                        <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                          <img
                            src={ActiveFromIcon}
                            className="img-fluid ml-auto mr-auto w-70"
                            alt="icon"
                          />
                        </div>
                      </div>
                      <div className="col-9 col-md-8 ps-0 text-left">
                        <p className="mb-0 f-13"> Active From </p>
                        <h5 className="font-14 mb-0">{employee.doj}</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {true && (
                <div className="row mt-3">
                  <h4 className="f-14 text-secondary">Address details</h4>
                  {employee.address?.country && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={CountryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Country </p>
                          <h5 className="font-14 mb-0">
                            {employee.address?.country}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {employee.address?.state && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={StateIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> State </p>
                          <h5 className="font-14 mb-0">
                            {employee.address?.state}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {employee.address?.city && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={CityIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> City </p>
                          <h5 className="font-14 mb-0">
                            {employee.address?.city}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {employee.address?.pinCode && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={PinCodeIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Pincode </p>
                          <h5 className="font-14 mb-0">
                            {employee.address?.pinCode}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {employee.address?.address && (
                    <div className=" col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightpink rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={AddressIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Address </p>
                          <h5 className="font-14 mb-0">
                            {employee.address?.address}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* <div className="col-1 d-flex justify-content-end">
              {edit && (
                <CardButton onClick={() => openDetailsModal()} icon={"edit"} />
              )}
            </div> */}
          </div>
        </div>
      </WhiteCard>
      {true && (
        <WhiteCard className="p-3">
          <div className="">
            <h4 className="f-14 text-secondary">Salary details</h4>
            <div className="row">
              <div className="col-11">
                <div className="row">
                  {employee.salaryInfo?.salary &&
                  employee.salaryInfo?.salary > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={SalaryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Monthly Salary </p>
                          <h5 className="font-14 mb-0">
                            {currencyFormat(employee.salaryInfo?.salary || 0)}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {employee.salaryInfo?.basicSalary &&
                  employee.salaryInfo?.basicSalary > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={SalaryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Monthly Basic Salary </p>
                          <h5 className="font-14 mb-0">
                            {currencyFormat(
                              employee.salaryInfo?.basicSalary || 0
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {employee.salaryInfo?.allowance &&
                  employee.salaryInfo?.allowance > 0 ? (
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-2 col-md-3 text-right">
                          <div className="bg-lightblue rounded-6_i text-center mx-auto my-auto p-1 mt-1 icon-width">
                            <img
                              src={SalaryIcon}
                              className="img-fluid ml-auto mr-auto w-70"
                              alt="icon"
                            />
                          </div>
                        </div>
                        <div className="col-9 col-md-8 ps-0 text-left">
                          <p className="mb-0 f-13"> Monthly allowance </p>
                          <h5 className="font-14 mb-0">
                            {currencyFormat(
                              employee.salaryInfo?.allowance || 0
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="col-1 d-flex justify-content-end">
                {edit && (
                  <CardButton onClick={() => fillSalaryData()} icon={"edit"} />
                )}
              </div> */}
            </div>
          </div>
        </WhiteCard>
      )}
    </>
  );
};
