import React, { useState } from "react";
import { RoutePaths } from "../../../../utils/route.utils";
import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import Heading from "../common/heading";
import SettingsRolesTable from "./settings-roles-employees-table";
import "./settings.scss";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import RolesPopup from "./RolesPopup";
import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ClaimApproverTabKind } from "./claimApprover.utils";
import ClaimApproverTab from "./claimapprover-tab";
import EmployeesRolesTable from "./settings-roles-employees-table";
import NewApproverPopup from "./newApproverPopup";
import ApproversRolesTable from "./settings-roles-approvers-table";
import { NcaishClaimApiService } from "../../../../api/detax/api/ncaishClaimApi.service";
import { isSuccess } from "@devexperts/remote-data-ts";
import { unWrapOption } from "../../../../utils/option.utils";
import { Dispatch } from "../../../../state/dispatch";
const SettingsRoles: React.FC = () => {
  const [searchVal, setSearchVal] = useState("");
  const [filter, setFilter] = useState(false);
  const [approver, setApprover] = useState(false);
  const [editApprover, setEditApprover] = useState(null);
  const [activeTab, setActiveTab] = useState<ClaimApproverTabKind>(
    ClaimApproverTabKind.APPROVERS
  );

  const [popupData, setPopupData] = useState();

  return (
    <>
      <PageTitle title="Settings" />
      <Heading
        title="Roles"
        // detail={"Employee"}
        // links={[RoutePaths.DashboardSettings]}
        breadcrum={{ label: "Settings", url: RoutePaths.DashboardSettings }}
      />
      <ClaimApproverTab
        activeTab={activeTab}
        setActiveTab={(tabType: ClaimApproverTabKind) => setActiveTab(tabType)}
      />
      {/* {activeTab === ClaimApproverTabKind.EMPLOYEES && ( */}
      <WhiteCard className="my-4 p-1 rounded">
        <div className="d-flex justify-content-between">
          <CustomSearchBar
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="70%"
            text="Search by Employee Name"
          />
          {activeTab === ClaimApproverTabKind.APPROVERS && (
            <div className="d-flex align-items-center">
              {/* {curTbl && <DetaxExport curTbl={curTbl} />} */}
              <Button
                className="btn-gradiant-blue btn-lg text-white fs-small"
                onClick={() => setApprover(true)}
              >
                Add New Approver
              </Button>
            </div>
          )}
        </div>
      </WhiteCard>
      {/* )} */}
      {activeTab === ClaimApproverTabKind.EMPLOYEES && (
        <EmployeesRolesTable
          searchVal={searchVal}
          show={() => setFilter(true)}
          setPopupData={(data: any) => setPopupData(data)}
        />
      )}
      {activeTab === ClaimApproverTabKind.APPROVERS && (
        <ApproversRolesTable
          approver={approver}
          searchVal={searchVal}
          show={() => setFilter(true)}
          editApprover={editApprover}
          setEditApprover={(data: any) => setEditApprover(data)}
          setApproverModal={(data: any) => setApprover(data)}
        />
      )}
      {/* <RolesPopup
        popupData={popupData}
        actionState1={false}
        show={filter}
        closeModal={() => setFilter(false)}
      /> */}
      <NewApproverPopup
        actionState1={false}
        show={approver}
        closeModal={(data: any) => setApprover(data)}
        editApprover={editApprover}
        setEditApprover={(data: any) => setEditApprover(data)}
      />
    </>
  );
};
export default SettingsRoles;
