/**
 * Ncaish Account Aggregator APIs
 * The __Account Aggregator__ (AA) serves as an intermediary between __Financial Information Providers__ (such as banks, securities, insurance, pensions and other account/finance management service providers) and __Financial Information Users__. APIs to manage FIU and FIP life cycle provided by AA.
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { FiuConsentResponse } from "../model/fiuConsentResponse";
import { FiuInitiateResponse } from "../model/fiuInitiateResponse";
import { FiuProfileApiResponse } from "../model/fiuProfileApiResponse";
import { FiuSummaryApiResponse } from "../model/fiuSummaryApiResponse";
import { FiuTransactionApiResponse } from "../model/fiuTransactionApiResponse";
import { FiuTransactionGraphApiResponse } from "../model/fiuTransactionGraphApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcashFIUProfileSummaryTransactionAPIsService {
  /**
   *
   * @summary FIU Consents
   * @param userId -
   */
  export const getFIUConsents = async (
    userId?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FiuConsentResponse>> => {
    const path = `/secure/aa/fiu/consents`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "userId",
        value: userId,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @summary FIU Account Profile
   */
  export const getFIUProfile = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FiuProfileApiResponse>> => {
    const path = `/secure/aa/fiu/account/profile`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @summary FIU Account Summary
   */
  export const getFIUProfileSummary = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FiuSummaryApiResponse>> => {
    const path = `/secure/aa/fiu/account/summary`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @summary FIU Account Transactions
   * @param startDate -
   * @param endDate -
   * @param transactionType -
   */
  export const getFiuAccountTransactions = async (
    startDate?: string,
    endDate?: string,
    transactionType?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FiuTransactionApiResponse>> => {
    const path = `/secure/aa/fiu/account/transactions`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "startDate",
        value: startDate,
      },
      {
        key: "endDate",
        value: endDate,
      },
      {
        key: "transactionType",
        value: transactionType,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @summary FIU Account Transactions
   * @param startDate -
   * @param endDate -
   */
  export const getFiuAccountTransactionsGraphData = async (
    startDate?: string,
    endDate?: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FiuTransactionGraphApiResponse>> => {
    const path = `/secure/aa/fiu/account/transactions/graph`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [
      {
        key: "startDate",
        value: startDate,
      },
      {
        key: "endDate",
        value: endDate,
      },
    ];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @summary Initiate FIU Request
   */
  export const initiateFiuRequest = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FiuInitiateResponse>> => {
    const path = `/secure/aa/fiu/init`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
