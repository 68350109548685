import React from "react";
import { CardTransactionInfo } from "../../../../../api/detax/model/cardTransactionInfo";
import { getTitleCase } from "../../../../../utils/common.utils";
import { useAppSelector } from "../../../../../hooks/hooks";
import { AlphaReduxStore } from "../../../../../state/slice/alpha.slice";

export type TExpense = {
  type?: CardTransactionInfo.TypeEnum;
  approvalStatus?: CardTransactionInfo.ApprovalStatusEnum;
  scope?: CardTransactionInfo.ScopeEnum;
  remarks?: string;
  txnWallet?: CardTransactionInfo.TxnWalletEnum;
  merchant?: string;
  approvalDisplayStatus?: string;
  manualTransaction?: boolean;
  surCharge?: boolean;
  partnerTxnType?: string;
};

const ExpenseCard: React.FC<{ expense: TExpense }> = ({ expense }) => {
  const manualTxn = useAppSelector(AlphaReduxStore.selectManualTxn);
  return (
    <div className="card text-dark mb-3">
      <div className="card-header">
        <h5 className="card-title"> Details</h5>
      </div>
      <div className="card-body">
        {expense.type === CardTransactionInfo.TypeEnum.DEBIT && (
          <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <p> Merchant</p>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <p className="text-capitalize"> {expense.merchant || ""}</p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Transaction Types</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p className="text-capitalize"> {expense.type?.toLowerCase()}</p>
          </div>
        </div>
        {manualTxn && (
          <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <p>Payment Type</p>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <p className="text-capitalize">
                {expense.manualTransaction
                  ? "Manual Transaction"
                  : "De'tax Card"}
              </p>
            </div>
          </div>
        )}

        {expense.approvalStatus && (
          <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <p>Approve Status</p>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <p className="text-capitalize">
                {getTitleCase(expense.approvalStatus)}
              </p>
            </div>
          </div>
        )}
        {expense.scope && (
          <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <p> Scope</p>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <p className="text-capitalize">
                {expense.scope?.toLowerCase() || "N/A"}
              </p>
            </div>
          </div>
        )}
        {expense.txnWallet && (
          <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <p> Wallet</p>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <p className="text-capitalize">{expense.txnWallet || ""}</p>
            </div>
          </div>
        )}
        {expense.remarks && (
          <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <p> Remarks</p>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <p> {expense.remarks || "N/A"}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpenseCard;
