import { useState } from "react";
import { Button } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CardTransactionInfo } from "../../../../api/detax/model/cardTransactionInfo";
import { ClaimCountInfo } from "../../../../api/detax/model/claimCountInfo";
import { ClaimInfo } from "../../../../model/claimInfo";
import { TransactionService } from "../../../../services/transaction.service";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";

import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import DateRangeDropdown from "../common/date-range-dropdown";
import Heading from "../common/heading";
import ClaimsExport from "./claims-export";
import ClaimsStatusCount from "./claims-status-count";
import ClaimsTable from "./claims-table";
import TransactionStatusCountCard from "./transaction-status-count-card";
import TransactionsList from "./transactions-list";
import { format } from "date-fns";
import { isSuccess } from "@devexperts/remote-data-ts";
import DateRangeSelectionPopup from "./DateRangeSelectionPopup";
import TransactionsExport from "./transaction-export";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import FilterPopup from "./FilterPopup";

interface IDateRange {
  startDate?: any;
  endDate?: any;
}
const DashboardTransactions = () => {
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  const [data, setData] = useState<CardTransactionInfo[] | []>([]);
  const [searchVal, setSearchVal] = useState("");
  const [filterDate, setFilterDate] = useState<IDateRange>({});

  const [user, setUser] = useState<CardTransactionInfo[] | []>([]);

  return (
    <>
      <PageTitle title="Transactions" />
      <Heading title="Transactions" />

      {/* <WhiteCard className="mb-4 mt-2 p-1 rounded">
        <div className="d-flex justify-content-between">
          <CustomSearchBar
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="60%"
            text="Search by Transaction ID/Employee Name/Mobile "
          />
          <div className="d-flex align-items-center justify-content-center ">
            {curTbl && (
              <TransactionsExport
                curTbl={curTbl}
                filterDate={filterDate}
                searchVal={searchVal}
              />
            )}
            <Button
              className="btn-gradiant-blue px-3 py-2 font-14  text-white"
              size="sm"
              type="button"
              onClick={() => handleDownloadFbaReport()}
              disabled={actionState}
            >
              {actionState && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
              )}
              Fba Utilization Report
            </Button>
            <div className="ms-2"></div> */}
      {/* <DateRangeDropdown
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(data: IDateRange) => setFilterDate(data)}
            /> */}
      {/* <Button
              className="btn-gradiant-blue px-3 py-2 font-14  text-white"
              size="sm"
              type="button"
              onClick={() => setFilter(true)}
            >
              {actionState1 && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
              )}
              Transactions Report
            </Button> */}
      {/* <div
              className={"wrap-icon"}
              id="reuseIcon"
              role="button"
              onClick={() => setFilter(true)}
            >
              <img src="/images/filter.svg" />
            </div> */}
      {/* </div>
        </div>
      </WhiteCard> */}

      <TransactionStatusCountCard
        setCurTbl={setCurTbl}
        curTbl={curTbl}
        filterDate={filterDate}
      />
      {/* <div className="d-flex align-items-center justify-content-end m-1">
        {curTbl && (
          <TransactionsExport
            curTbl={curTbl}
            filterDate={filterDate}
            searchVal={searchVal}
          />
        )}
        <Button
          className="btn-gradiant-blue px-3 py-2 font-14  text-white"
          size="sm"
          type="button"
          onClick={() => handleDownloadFbaReport()}
          disabled={actionState}
        >
          {actionState && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></span>
          )}
          Fba Utilization Report
        </Button>
        <div className="ms-2"></div>
        <DateRangeDropdown
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(data: IDateRange) => setFilterDate(data)}
            />
        <Button
          className="btn-gradiant-blue px-3 py-2 font-14  text-white"
          size="sm"
          type="button"
          onClick={() => setFilter(true)}
        >
          {actionState1 && (
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
            ></span>
          )}
          Transactions Report
        </Button>
      </div> */}
      {curTbl && (
        <TransactionsList
          searchVal={searchVal}
          setData={(d: any) => setData(d)}
          curTbl={curTbl}
          filterDate={filterDate}
        />
      )}
    </>
  );
};
export default DashboardTransactions;
