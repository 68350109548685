import { useState } from "react";
import { FiuConsentInfo } from "../../../../api/account-aggregator/model/fiuConsentInfo";
import { FiuProfileSummaryApiResponse } from "../../../../api/account-aggregator/model/fiuProfileSummaryApiResponse";
import { FiuTransactionApiData } from "../../../../api/account-aggregator/model/fiuTransactionApiData";
import AccountHorizontalTabs from "../../../horizontal-tabs/account-horizontal-tabs";
import "../account-aggregator-pages/aa.css";
import AAtrxTable from "./aa-transaction-table";

const AccountSelection: React.FC<{
  aaOverview: Array<FiuConsentInfo> | [];
  aaAccount: FiuProfileSummaryApiResponse | null;
  transactions?: FiuTransactionApiData | null;
  // transactions: { [key: string]: number } | null;
}> = ({ aaOverview, aaAccount, transactions }) => {
  const [activeTab, setActiveTab] = useState("overview");
  return (
    <div>
      <h6 className="headings my-3">
        <span className="text-header-gray"> Account Aggregator &gt; </span>{" "}
        Account Selection
      </h6>

      <div onClick={async () => { }}>
        <AccountHorizontalTabs
          isAccount
          isOverview
          tab={activeTab}
          setTab={(tab) => setActiveTab(tab || "")}
        />
      </div>

      {activeTab === "overview" && (
        <>
          <div className="card mt-3 pt-2 pb-2 ps-3 pe-3 rounded-10 bg-white border-0 shadow-sm h-100">
            <div className="card-title h5">Transaction</div>
            <div className="col-md-12 col-lg-12 dashed-border p-3 mt-1">
              {/* <TransactionChart monthlyTransactions={transactions} /> */}
            </div>
            <div onClick={() => console.log(transactions)} className="col-md-12 col-lg-12 dashed-border p-3 mt-3">
              <AAtrxTable data={transactions?.transactions?.transaction || []} />
            </div>
          </div>
        </>
      )}

      {/* <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link text-header-gray"
            id="ex1-tab-1"
            data-mdb-toggle="tab"
            href="#ex1-tabs-1"
            role="tab"
            aria-controls="ex1-tabs-1"
            aria-selected="true"
          >
            Account
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link text-header-gray"
            id="ex1-tab-2"
            data-mdb-toggle="tab"
            href="#ex1-tabs-2"
            role="tab"
            aria-controls="ex1-tabs-2"
            aria-selected="false"
          >
            Consent
          </a>
        </li>
        <li className="nav-item active" role="presentation">
          <a
            className="nav-link active"
            id="ex1-tab-3"
            data-mdb-toggle="tab"
            href="#ex1-tabs-3"
            role="tab"
            aria-controls="ex1-tabs-3"
            aria-selected="false"
          >
            Overview
          </a>
        </li>
      </ul>

      <div className="tab-content" id="ex1-content">
        <div
          className="tab-pane fade"
          id="ex1-tabs-1"
          role="tabpanel"
          aria-labelledby="ex1-tab-1"
        >
          Tab 1 content
        </div>
        <div
          className="tab-pane fade"
          id="ex1-tabs-2"
          role="tabpanel"
          aria-labelledby="ex1-tab-2"
        >
          Tab 2 content
        </div>
        <div
          className="tab-pane fade show active"
          id="ex1-tabs-3"
          role="tabpanel"
          aria-labelledby="ex1-tab-3"
        >
          <div className="round-less shadow-sm px-4 row bg-white pb-4 pt-3">
            <div className="d-flex justify-content-between">
              <h5 className="px-0 mt-3"> Transaction </h5>
              <div>
                <div className="dropdown float-start">
                  <button
                    className="btn border-0 p-1 fs-xsmall text-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Bank Name
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="dropdown float-start">
                  <button
                    className="btn border-0 p-1 fs-xsmall text-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    This Year
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 dashed-border p-3 mt-1">
              <TransactionChart monthlyTransactions={monthlyTransactions} />
            </div>
            <div className="col-md-12 col-lg-12 dashed-border p-3 mt-3">
              <CustomTable />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AccountSelection;
