import { APIError, ServiceCallOutcome } from "../api/errors";

export const setErrorMessages = (error: APIError): string => {
  switch (error.outcome) {
    case ServiceCallOutcome.NoResponseFromServer:
      return "Network error";
    case ServiceCallOutcome.WebServerError:
      return `Technical error. Please try again.`;
    case ServiceCallOutcome.ApplicationError:
      return `${error.apiStatus.Message}`;
    //case ServiceCallOutcome.Success:
    //return "Success";
    default:
      return "";
  }
};

export const setErrorMessagesLogin = (error: APIError): string => {
  switch (error.outcome) {
    case ServiceCallOutcome.NoResponseFromServer:
      return "Network error";
    case ServiceCallOutcome.WebServerError:
      return `${error.httpStatus.Data.message}`;
    case ServiceCallOutcome.ApplicationError:
      return `${error.apiStatus.Message}`;
    //case ServiceCallOutcome.Success:
    //return "Success";
    // default:
    //   return "Something went wrong!";
  }
};
