import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import OtpField from "react-otp-field";
import { Link, useNavigate } from "react-router-dom";
import { Button, CardButton, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { AuthService } from "../../services/auth.service";
import { selectLogin } from "../../state/slice/user.slice";
import { fromPublic, timerSeconds } from "../../utils/common.utils";
import { setErrorMessages } from "../../utils/error.utils";
import { RoutePaths } from "../../utils/route.utils";
import { selectLoginId } from "../app/app.slice";
import SpinnerComponent from "../common/loader/spinner";
import mixpanel from "mixpanel-browser";

const Image1 = fromPublic("/images/otp.svg");

// RAJESH: This component is not in use
export const LoginVerificationPage: React.FC = () => {
  const loginId = useAppSelector(selectLoginId);
  const loginUser = useAppSelector(selectLogin);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [numberOtp, setNumberOtp] = useState("");
  const [otpCounter, setOtpCounter] = useState(25);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Login Verification" });
  }, []);

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpCounter]);

  const handleNumberOtpChange = (numberOtp: any) => {
    setNumberOtp(numberOtp);
  };

  const handleResendOtp = () => {
    setOtpCounter(25);

    setNumberOtp("");
  };

  const handleSubmit = async () => {
    if (isLoading) return;

    setError("");
    setIsLoading(true);

    const result = await AuthService.verifyLogin(loginId, numberOtp);

    if (isSuccess(result)) {
      setIsLoading(false);
      // commented below lie to work with new verifyLogin response.
      // if (unWrapOption(result.value.successful, () => false)) {
      //   dispatch(
      //     setToken({
      //       accessToken: unWrapOption(result.value.accessToken, () => ""),
      //       refreshToken: unWrapOption(result.value.refreshToken, () => ""),
      //       tokenExpiry: unWrapOption(result.value.expiry, () => 0),
      //       scope: unWrapOption(result.value.scope, () => ""),
      //     })
      //   );

      //   MyStorage.AuthUser.StoreData(result.value.data);

      //   const scope = unWrapOption(result.value.scope, () => "");

      //   dispatch(setIsAuthenticated(true));

      //   if (scope === Scope.CORPORATE_APPLICANT) {
      //     await getNextScreenFromReview();
      //     // dispatch(setCurrentScreen(RoutePaths.DashboardFxOnline));
      //   } else if (scope === Scope.CORPORATE_ADMIN) {
      //     dispatch(setCurrentScreen(RoutePaths.DashboardFxOnline));
      //   }
      // } else {
      //   setError(unWrapOption(result.value.message, () => "Invalid OTP"));
      // }
    } else if (isFailure(result)) {
      setIsLoading(false);
      setError(setErrorMessages(result.error));
    }
  };
  const goBack = () => {
    navigate(RoutePaths.Login);
  };

  return (
    <div>
      <div className="container p-0">
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center vh-100">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="Mobile"
                  />
                </div>
                <div className="mt-5 textDetail">
                  <Heading
                    className="mx-5 mb-3 mt-85"
                    text="OTP Capture"
                    variant="small"
                  />

                  <p className="mx-5 fw-bolder text-secondary">
                    Being a Fintech we understand the importance of Multi Factor
                    authentication prescribed by Reserve Bank of India ! Please
                    do not share this OTP with anyone else !
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="px-3 py-2 border-0 shadow card bg-grey rounded-15">
                    {/* <h2 className="">User Verification</h2> */}
                    <Heading
                      className="mt-5 text-center"
                      text="OTP Verification"
                      variant="large"
                    />
                    {error && (
                      <div
                        className="text-center alert alert-danger fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}
                    <span className="mt-3 mb-3 text-center text-black fw-bold text-capitalize">
                      We have sent the verification code to your <br />
                      mobile number
                    </span>
                    <span className="mt-3 mb-1 text-center text-black fw-bold fs-5">
                      +91-{loginUser.mobile} &nbsp;
                      <CardButton icon={"edit"} onClick={goBack} />
                    </span>
                    <div
                      id="otp"
                      className="flex-row mt-3 mb-3 inputs d-flex justify-content-center"
                    >
                      <OtpField
                        classNames="d-flex "
                        value={numberOtp}
                        onChange={handleNumberOtpChange}
                        numInputs={6}
                        onChangeRegex={/^([0-9]{0,})$/}
                        autoFocus
                        inputProps={{
                          className:
                            "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                          disabled: false,
                        }}
                      />
                    </div>

                    <p className="mt-1 mb-0 text-center fw-bolder text-secondary">
                      Didn't receive Verification Code?
                    </p>
                    <Link
                      to=""
                      className={
                        "fw-bolder text-darkblue text-center nav-link " +
                        (otpCounter <= 0 ? " " : "disabled")
                      }
                      onClick={handleResendOtp}
                    >
                      Resend Code{" "}
                      <span className="mt-2 text-danger">
                        {timerSeconds(otpCounter)}
                      </span>
                    </Link>

                    {/* <ResendButton /> */}

                    <div className="mt-5 mb-20 ml-auto mr-auto text-center">
                      <Button
                        type="button"
                        className={
                          numberOtp.length !== 6
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!numberOtp}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <SpinnerComponent /> : <>Continue</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-2 border-0 shadow card bg-grey">
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="p-2 img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="BG"
                  />
                </div>

                <div className="p-3 mt-2 mb-3 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                  <Heading
                    className="mt-0 text-center"
                    text="OTP Verification"
                    variant="small"
                  />
                  <span className="mt-0 text-center fs-small fw-bold text-secondary">
                    We have sent the verification code to your mobile number
                  </span>
                  <span className="mt-2 text-center text-black fw-bold fs-5">
                    +91-{loginUser.mobile} &nbsp;
                    <CardButton icon={"edit"} onClick={goBack} />
                  </span>

                  <div
                    id="otp"
                    className="flex-row mt-2 mb-4 inputs d-flex justify-content-center"
                  >
                    <OtpField
                      classNames="d-flex "
                      value={numberOtp}
                      onChange={handleNumberOtpChange}
                      numInputs={6}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      inputProps={{
                        className:
                          "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                        disabled: false,
                      }}
                    />
                  </div>

                  <p className="mb-0 text-center fw-bolder text-secondary ">
                    Didn't receive Verification Code?
                  </p>
                  {/* <ResendButton /> */}
                  <Link
                    to=""
                    className={
                      "fw-bolder text-darkblue text-center nav-link " +
                      (otpCounter <= 0 ? " " : "disabled")
                    }
                    onClick={handleResendOtp}
                  >
                    Resend Code{" "}
                    <span className="mt-2 text-danger">
                      {timerSeconds(otpCounter)}
                    </span>
                  </Link>
                </div>
                <div className="mb-3 ml-auto mr-auto text-center">
                  <Button
                    type="button"
                    className={
                      numberOtp.length !== 6
                        ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                        : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                    }
                    disabled={!numberOtp}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <SpinnerComponent /> : <>Continue</>}
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </div>
  );
};
