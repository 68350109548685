import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { format } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import {
  fold,
  fromNullable,
  isSome,
  none,
  Option,
  some,
} from "fp-ts/lib/Option";
import xlsx from "json-as-xlsx";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CardTransactionInfo } from "../../../../../api/detax/model/cardTransactionInfo";
import { ClaimCountInfo } from "../../../../../api/detax/model/claimCountInfo";
import { ClaimInfo } from "../../../../../api/detax/model/claimInfo";
import { DetaxCardInfo } from "../../../../../api/detax/model/detaxCardInfo";
import { FbaAllowanceResponse } from "../../../../../api/detax/model/fbaAllowanceResponse";
import { ReplaceCardApiResponse } from "../../../../../api/detax/model/replaceCardApiResponse";
import { UpdateCardStatusApiRequest } from "../../../../../api/detax/model/updateCardStatusApiRequest";
import { useAppSelector } from "../../../../../hooks/hooks";
import { DetaxService } from "../../../../../services/detax.service";
import { TransactionService } from "../../../../../services/transaction.service";
import { Dispatch } from "../../../../../state/dispatch";
import { AlphaReduxStore } from "../../../../../state/slice/alpha.slice";
import { TStatusCountInfo } from "../../../../../types/status-count-info.type";
import { getTitleCase } from "../../../../../utils/common.utils";
import {
  availableLimit,
  currencyFormat,
} from "../../../../../utils/format.utils";
import { unWrapOption } from "../../../../../utils/option.utils";
import { RoutePaths } from "../../../../../utils/route.utils";
import { selectSwitchAccountPermission } from "../../../../app/app.slice";
import TransferTxn from "../../../../modal/employees/TransferTxn";
import ClaimsStatusCount from "../../../../pages/dashboard/claims/claims-status-count";
import ClaimsTable from "../../../../pages/dashboard/claims/claims-table";
import DateRangeDropdown from "../../../../pages/dashboard/common/date-range-dropdown";
import NoTableData from "../../../../pages/dashboard/common/no-table-data";
import StatusText from "../../../../pages/dashboard/common/status-text";
import SuccessPopup from "../../../../pages/dashboard/common/succes-popup";
import {
  ITableData,
  TablePagination,
} from "../../../../pages/dashboard/common/table/table-pagination";
import WhiteCard from "../../../../white-card/white-card";
import CardButton from "../../../button/card-button";
import ErrorPopup from "../../../../pages/dashboard/common/error-popup";

const CardsIcon = "/images/icons/icons/cards.svg";
const FileIcon = "/images/icons/icons/file.svg";
const TransactionIcon = "/images/icons/icons/transaction.svg";
const SalaryIcon = "/images/icons/icons/salary.svg";
const FuelIcon = "/images/icons/icons/fuel.svg";
const TransportIcon = "/images/icons/icons/car.svg";
const FoodIcon = "/images/icons/icons/food.svg";
const HealthIcon = "/images/icons/icons/health.svg";

interface IDateRange {
  startDate?: any;
  endDate?: any;
}

export const CardDetailsTabs: React.FC<{ uid?: string }> = ({ uid }) => {
  const [show, setShow] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSuccesslock, setShowSuccesslock] = useState(false);
  const giftCardStatus = useAppSelector(AlphaReduxStore.selectGiftCardStatus);
  const [modalType, setModalType] =
    useState<UpdateCardStatusApiRequest.StatusEnum>("BLOCKED");
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DetaxCardInfo[]>([]);
  const [replaceCardData, setReplaceCardData] =
    useState<ReplaceCardApiResponse>();
  const [selectedCardId, setSelectedCardId] = useState<Option<string>>(none);
  const [showModal, setShowModal] = useState(false);
  const isSwitchAccount = unWrapOption(
    useAppSelector(selectSwitchAccountPermission),
    () => false
  );
  useEffect(() => {
    const run = async () => {
      if (uid) {
        setIsLoading(true);
        const result = await DetaxService.getCards(uid);
        if (isSuccess(result)) {
          if (result.value.successful) {
            setData(result.value.data || []);
            setSelectedCardId(none);
          }
        }
        setIsLoading(false);
      }
    };
    run();
  }, [uid]);
  const replaceCard = async () => {
    if (uid) {
      setIsLoading(true);
      const result = await DetaxService.replaceCard(uid);
      if (isSuccess(result)) {
        if (result.value.successful) {
          setReplaceCardData(result.value.data);
          console.log("test", result.value.data);
          handleClose();
          setText("");
          setShowSuccess(true);
        } else {
          Dispatch.App.setBadRequestError({
            showError: true,
            errorText: [result.value.message ?? ""],
          });
        }
      }
      setIsLoading(false);
    }
  };
  const updateCardStatus = async (
    status: UpdateCardStatusApiRequest.StatusEnum
  ) => {
    if (isSome(selectedCardId)) {
      setIsLoading(true);
      const result = await DetaxService.updateCardStatus(selectedCardId.value, {
        remarks: text,
        status: status,
      });
      if (isSuccess(result)) {
        if (result.value.successful) {
          setData(result.value.data || []);
          handleClose();
          setText("");
          setShowSuccess(true);
        }
      }
      setIsLoading(false);
    }
  };

  const updateCardlockStatus = async () => {
    if (uid) {
      setIsLoading(true);
      const result = await DetaxService.updateCardUnlockStatus(uid, {
        remarks: text,
      });
      if (isSuccess(result)) {
        console.log("Result: ", result);
        if (result.value.successful) {
          console.log("Result: ", result);
          setData(result.value.data || []);
          handleClose();
          setText("");
          setShowSuccesslock(true);
        }
      }
      setIsLoading(false);
      console.log("card id: ", selectedCardId);
      handleClose();
      setShowSuccesslock(true);
    }
  };

  const handleClose = () => {
    setShow(false);
    setShowModal(false);
    setText("");
    setSelectedCardId(none);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <div id="1">
        <WhiteCard className="mt-0 ">
          <div className="card-title fw-bold d-flex">
            <div className="p-1 rounded-6_i bg-lightblue text-center">
              <img
                src={CardsIcon}
                className="img-fluid w-85 ms-auto me-auto p-1"
                alt="CardsIcon "
              />
            </div>
            &nbsp;Card Details
          </div>
          {data.length > 0
            ? data.map((item: DetaxCardInfo, key: number) => (
                <div
                  key={key}
                  className="card rounded-10 border border-light p-3"
                >
                  <div className="row">
                    <div className="col-5 fw-bold fs-6">
                      {getTitleCase(item.displayStatus || "")}
                    </div>
                    <div className="col-7 d-flex justify-content-end gap-3">
                      {isSwitchAccount && (
                        <button
                          onClick={() => {
                            // handleShow();
                            setModalType("REPLACE_CARD");
                            // setSelectedCardId(fromNullable(item.cardId));
                            replaceCard();
                          }}
                          type="button"
                          className="btn btn-outline-warning naich-block text-warning px-3 fw-bold fs-xxsmall"
                        >
                          Replace Card
                        </button>
                      )}
                      {item.status == "ACTIVATED" && (
                        <>
                          <button
                            onClick={() => {
                              handleShow();
                              setShowModal(true);
                              setSelectedCardId(fromNullable(item.cardId));
                            }}
                            type="button"
                            className="btn btn-outline-success naich-block text-success px-3 fw-bold fs-xxsmall"
                          >
                            Unlock
                          </button>
                          <button
                            onClick={() => {
                              handleShow();
                              setModalType("BLOCKED");
                              setSelectedCardId(fromNullable(item.cardId));
                            }}
                            type="button"
                            className="btn btn-outline-danger naich-block text-danger px-3 m-0 fw-bold fs-xxsmall"
                          >
                            Block
                          </button>
                          <button
                            onClick={() => {
                              handleShow();
                              setModalType("DEACTIVATED");
                              setSelectedCardId(fromNullable(item.cardId));
                            }}
                            type="button"
                            className="btn btn-outline-secondary naich-block text-dark px-3 fw-bold fs-xxsmall"
                          >
                            Deactivate
                          </button>
                        </>
                      )}
                      {item.status == "BLOCKED" && (
                        <button
                          onClick={() => {
                            handleShow();
                            setModalType("ACTIVATED");
                            setSelectedCardId(fromNullable(item.cardId));
                          }}
                          type="button"
                          className="btn btn-outline-success naich-block text-white px-3 fw-bold fs-xxsmall"
                          style={{ backgroundColor: "#198754", color: "white" }}
                        >
                          Unblock
                        </button>
                      )}

                      <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header className="bg-danger d-flex flex-column justify-content-center">
                          <div className="text-center  text-light fs-10">
                            {" "}
                            &#9888;
                          </div>
                        </Modal.Header>
                        <Modal.Body className="ncaish-bg-danger text-light fw-bold text-center">
                          Are you sure to{" "}
                          {modalType === "REPLACE_CARD"
                            ? "Replace"
                            : modalType === "BLOCKED"
                            ? "Block"
                            : modalType === "ACTIVATED"
                            ? "Unblock"
                            : "Deactivate"}{" "}
                          this card?
                          <div className="form-group">
                            <textarea
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                              rows={2}
                              className="form-control mt-2"
                              placeholder="Enter remark"
                            />
                          </div>
                        </Modal.Body>
                        <Modal.Footer className=" d-flex justify-content-center">
                          <Button
                            className="btn-gradiant-gray"
                            variant="secondary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                          <Button
                            className={
                              modalType == "BLOCKED"
                                ? "bg-danger border-danger"
                                : modalType == "DEACTIVATED"
                                ? "bg-danger border-danger"
                                : "btn-gradiant-blue"
                            }
                            variant="primary"
                            onClick={() => updateCardStatus(modalType)}
                          >
                            {modalType == "BLOCKED"
                              ? "Block"
                              : modalType == "DEACTIVATED"
                              ? "Deactivate"
                              : "Unblock"}
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal show={showModal} onHide={handleClose} centered>
                        <Modal.Header className="bg-success d-flex flex-column justify-content-center">
                          <div className="text-center  text-light fs-10">
                            {" "}
                            &#9888;
                          </div>
                        </Modal.Header>
                        <Modal.Body className="bg-success text-light fw-bold text-center">
                          Are you sure you want to unlock the user?
                          <div className="form-group">
                            <textarea
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                              rows={2}
                              className="form-control mt-2"
                              placeholder="Enter remark"
                            />
                          </div>
                        </Modal.Body>
                        <Modal.Footer className=" d-flex justify-content-center">
                          <Button
                            className="btn-gradiant-gray"
                            variant="secondary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                          <Button
                            className="bg-success"
                            variant="primary"
                            onClick={updateCardlockStatus}
                          >
                            Unlock
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-4">
                      <span className="text-secondary font-12">Kit Number</span>
                      <div className="text-black fs-6">
                        {item.cardNumber || ""}
                      </div>
                    </div>
                    <div className="col-4">
                      <span className="text-secondary font-12">
                        Total Balance
                      </span>
                      <div className="text-black fs-6">
                        {currencyFormat(item.totalBalance || 0)}
                      </div>
                    </div>
                    <div className="col-4">
                      <span className="text-secondary font-12">
                        FBA Balance
                      </span>
                      <div className="text-black fs-6">
                        {currencyFormat(item.fbaBal || 0)}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <span className="text-secondary font-12">TA Balance</span>
                      <div className="text-black fs-6">
                        {currencyFormat(item.taBal || 0)}
                      </div>
                    </div>
                    {giftCardStatus && (
                      <div className="col-4">
                        <span className="text-secondary font-12">
                          Rewards Balance
                        </span>
                        <div className="text-black fs-6">
                          {currencyFormat(item.gcBal || 0)}
                        </div>
                      </div>
                    )}
                    <div className="col-4">
                      <span className="text-secondary font-12">
                        Active From
                      </span>
                      <div className="text-black fs-6">{item.activatedAt}</div>
                    </div>
                    <div className="col-4">
                      <span className="text-secondary font-12">
                        Last FBA Recharge
                      </span>
                      <div className="text-black fs-6">
                        {item.lastFbaRecharge}
                      </div>
                    </div>
                    <div className="col-4">
                      <span className="text-secondary font-12">
                        Last TA Recharge
                      </span>
                      <div className="text-black fs-6">
                        {item.lastTaRecharge}
                      </div>
                    </div>
                    <div className="col-4">
                      <span className="text-secondary font-12">Remark</span>
                      <div className="text-black fs-6">
                        {item.remarks || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </WhiteCard>

        {/* <EmployeeTransaction /> */}
      </div>
      <SuccessPopup
        message={
          (modalType === "REPLACE_CARD"
            ? "Replaced"
            : modalType == "BLOCKED"
            ? "Blocked"
            : modalType == "DEACTIVATED"
            ? "Deactivated"
            : "Unblocked") + " successfully"
        }
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
      <SuccessPopup
        message={"Account has been unlocked successfully "}
        show={showSuccesslock}
        onClose={(flag) => setShowSuccesslock(flag)}
      />
    </>
  );
};

export const Claims: React.FC<{ id?: string; detaxCard?: boolean }> = ({
  id,
  detaxCard = false,
}) => {
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  const [data, setData] = useState<ClaimInfo[] | []>([]);

  const [filterDate, setFilterDate] = useState<IDateRange>({});

  // {
  //   startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  //   endDate: new Date(),
  // }

  const exportToExcel = () => {
    let cols: string[] = ["Claim Code", "Category"];

    if (id) {
      cols.push("Employee Name");
      cols.push("Mobile");
      cols.push("E-mail");
    }
    cols.push("Created At");

    const isAddUpdate =
      curTbl &&
      !(
        curTbl.status === ClaimCountInfo.StatusEnum.APPROVED ||
        curTbl.status === ClaimCountInfo.StatusEnum.REJECTED ||
        curTbl.status === ClaimCountInfo.StatusEnum.REIMBURSED ||
        curTbl.status === ClaimCountInfo.StatusEnum.PENDING
      );

    //if (isAddUpdate) {
    cols.push("Updated At");
    //}

    // curTbl &&
    //   curTbl.status === ClaimCountInfo.StatusEnum.APPROVED &&
    //   cols.push("Approved At");

    // curTbl &&
    //   curTbl.status === ClaimCountInfo.StatusEnum.REJECTED &&
    //   cols.push("Rejected At");

    // curTbl &&
    //   curTbl.status === ClaimCountInfo.StatusEnum.REIMBURSED &&
    //   cols.push("Reimbursed At");

    cols.push("Amount");

    curTbl &&
      curTbl.status !== ClaimCountInfo.StatusEnum.PENDING &&
      cols.push("Approval Amount");

    cols.push("Status");

    let content: any[] = [];
    data.forEach((item: ClaimInfo, index) => {
      const ctx: { [key: string]: any } = {
        ["Claim Code"]: item.claimCode || "-",
        ["Category"]: item.category || "-",
        // ["Employee Name"]: item.initiator?.name || "-",
        // ["Mobile"]: item.initiator?.mobile || "-",
        // ["Date"]: item.initiator?.activateFrom || "-",
        // ["E-mail"]: item.initiator?.email || "-",
        // ["Amount"]: item?.amount && item?.amount >= 0 ? item.amount : "-",
        // ["Approval Amount"]: item.appAmount || "-",
        // ["Status"]: item.status || "-",
      };

      if (id) {
        ctx["Employee Name"] = item.initiator?.name || "-";
        ctx["Mobile"] = item.initiator?.mobile || "-";
        ctx["E-mail"] = item.initiator?.email || "-";
      }

      ctx["Created At"] = item.initiatedAt || "-";

      //if (isAddUpdate) {
      ctx["Updated At"] = item.updatedAt || "-";
      //}

      // if (curTbl && curTbl.status === ClaimCountInfo.StatusEnum.APPROVED)
      //   ctx["Approved At"] = item.approvedAt || "-";

      // if (curTbl && curTbl.status === ClaimCountInfo.StatusEnum.REJECTED)
      //   ctx["Rejected At"] = item.cancelledAt || "-";

      // if (curTbl && curTbl.status === ClaimCountInfo.StatusEnum.REIMBURSED)
      //   ctx["Reimbursed At"] = item.reimbursedAt || "-";

      ctx["Amount"] = item.amount || "-";

      if (curTbl && curTbl.status !== ClaimCountInfo.StatusEnum.PENDING)
        ctx["Approval Amount"] = item.appAmount || "-";

      ctx["Status"] = item.displayStatus || "";

      content.push(ctx);
    });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];
    xlsx(output, { fileName: "Claims" });
  };

  return (
    <>
      <div id="2">
        <WhiteCard className="mt-0 rounded-10 ">
          <div className="d-flex justify-content-between">
            <div className="col-5 card-title fw-bold d-flex align-items-center">
              <div className="p-1 rounded-6_i bg-green text-center p-1">
                <img
                  src={FileIcon}
                  className="img-fluid w-85 ms-auto me-auto p-1"
                  alt="FileIcon "
                />
              </div>
              &nbsp;Claims
            </div>
            <div className="">
              <div className="row">
                <div
                  className="col-2 text-center"
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    className="comman-icon cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 47.54 43.884"
                    onClick={exportToExcel}
                  >
                    <path
                      id="download"
                      d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                      fill="#103458"
                    />
                  </svg>
                </div>
                {/* <div className="col-5">
                  <DropdownButton title="All Status" id="ncaish-status">
                    All Status
                  </DropdownButton>
                </div> */}
                &nbsp;
                <div className="col-5">
                  <DateRangeDropdown
                    startDate={filterDate.startDate}
                    endDate={filterDate.endDate}
                    onChange={(data: IDateRange) => setFilterDate(data)}
                  />
                </div>
              </div>
            </div>
          </div>

          <ClaimsStatusCount
            uid={id}
            setCurTbl={setCurTbl}
            curTbl={curTbl}
            filterDate={filterDate}
            detaxCard
          />
          {curTbl && (
            <ClaimsTable
              setData={(d: any) => setData(d)}
              uid={id}
              curTbl={curTbl}
              filterDate={filterDate}
            />
          )}
        </WhiteCard>
      </div>
    </>
  );
};

export const FlexiAllowance: React.FC<{ uid?: string }> = ({ uid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FbaAllowanceResponse | null>(null);

  useEffect(() => {
    const run = async () => {
      if (uid) {
        setIsLoading(true);
        const result = await DetaxService.getCorporateEmployFbaAllowance(uid);
        if (isSuccess(result)) {
          if (result.value.successful) {
            setData(result.value);
            console.log("uma", result.value);
          }
        }
        setIsLoading(false);
      }
    };
    run();
  }, [uid]);

  return (
    <>
      {data ? (
        <div id="3">
          <WhiteCard className="mt-0 rounded-10 ">
            <div className="row">
              <div className="col-12 card-title fw-bold">
                <img
                  src={FileIcon}
                  className="img-fluid p-1 rounded-6_i bg-green"
                  alt="FileIcon "
                />
                &nbsp;Flexi-Allowance
              </div>
            </div>
            <div className="row"></div>
            <div className="row mt-3">
              <div className="col-md-8 ms-2">
                <span className="text-secondary">Monthly FBA</span> -{" "}
                {data.monthlyFba ? currencyFormat(data.monthlyFba) : null}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-secondary">FBA balance</span> -{" "}
                {data.fbaBalance ? currencyFormat(data.fbaBalance) : null}
                {/* &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-secondary">AvailableLimit</span> -{" "}
                {data.availableLimit
                  ? currencyFormat(data.availableLimit)
                  : null}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <span className="text-secondary">Current Limit</span> -{" "}
                {data.currentLimit ? currencyFormat(data.currentLimit) : null} */}
              </div>
            </div>
            <div className="row p-2">
              {data?.subwallets &&
                data?.subwallets.map((item, key) => (
                  <div key={key} className="col-12 col-md-6  p-3">
                    <div
                      key={key}
                      className=" row"
                      // style={{ background: "gray" }}
                    >
                      <div className="col flexi-shadow_i rounded-6_i">
                        <div className="row">
                          {item.icon && (
                            <div className="col-1 col-md-1 p-0 text-end">
                              <img
                                src={item.icon}
                                className="img-fluid p-2 rounded-6_i bg-lightpink_i"
                                alt="icon"
                                width="34"
                              />
                            </div>
                          )}
                          <div className="col-11 col-md-11 d-flex align-items-center">
                            <h5 className="f-15 mt-1">{item.categoryName}</h5>
                            {/* <div className="progress my-2">
                          <HorizontalLineBar
                            enableMaxLimit={item.enableMaxLimit}
                            currentLimit={item.currentLimit}
                            used={item.used}
                            maxLimit={item.maxLimit}
                          />
                        </div> */}
                          </div>
                          {/* <div className="col-3 col-md-3">
                        {item.enableMaxLimit ? (
                          <p className="f-8 mb-2">
                            {" "}
                            Max Limit{" "}
                            <span className="f-15">
                              {" "}
                              {item.maxLimit
                                ? currencyFormat(item.maxLimit)
                                : null}{" "}
                            </span>{" "}
                          </p>
                        ) : null}
                      </div> */}
                        </div>

                        <div className="row">
                          <div className="col-1 col-md-1"></div>
                          <div className="col-3 col-md-3">
                            <div className="row">
                              <div className=" col-12 f-8">Spent Amount </div>
                              <div className="f-8 col-12 mb-1">
                                {currencyFormat(item.used)}
                              </div>
                            </div>
                          </div>
                          <div className="col-1 col-md-1">
                            {item.enableMaxLimit ? "|" : ""}{" "}
                          </div>
                          <div className="col-3 col-md-3">
                            {item.enableMaxLimit && (
                              <div className="row">
                                <div className=" col-12 f-8">
                                  Available Limit
                                </div>
                                <div className="f-8 col-12 mb-1">
                                  {currencyFormat(
                                    availableLimit(item.maxLimit, item.used)
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          {/* <div className="col-1 col-md-1">
                            {item.enableMaxLimit ? "|" : ""}{" "}
                          </div>
                          <div className="col-3 col-md-3">
                            {item.enableMaxLimit && (
                              <div className="row">
                                <div className=" col-12 f-8">Max Limit</div>
                                <div className="f-8 col-12">
                                  {currencyFormat(item.maxLimit)}
                                </div>
                                <div
                                  className=" col-12 mb-1"
                                  style={{ fontSize: "10px", color: "gray" }}
                                >
                                  Per month
                                </div>
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </WhiteCard>
        </div>
      ) : null}
    </>
  );
};

export const SalaryStructure: React.FC = () => {
  return (
    <>
      <div id="4">
        <WhiteCard className="mt-0 rounded-10">
          <div className="row">
            <div className="col-10 card-title fw-bold">
              <img
                src={SalaryIcon}
                className="img-fluid p-1 rounded-6_i bg-green"
                alt="icon"
              />
              &nbsp;Salary Structure
            </div>
            <div className="col-1">
              <CardButton icon={"edit"} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Monthly Salary{" "}
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {" "}
                      INR 65,000{" "}
                    </h5>
                  </div>
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Yearly Salary{" "}
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {" "}
                      INR 8,65,000{" "}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className="col-1"
                style={{ borderRight: "1px solid #efefef" }}
              ></div>
              <div className="col-5">
                <div className="row">
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Monthly Allowance
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {" "}
                      INR 15,000{" "}
                    </h5>
                  </div>
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Yearly Allowance
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {" "}
                      INR 2,05,000{" "}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WhiteCard>
      </div>
    </>
  );
};

export const Transactions: React.FC<{
  id?: string;
  name?: string;
  callFrom?: string;
}> = ({ id, callFrom = "", name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Option<CardTransactionInfo[]>>(none);
  const [filterDate, setFilterDate] = useState<IDateRange>({});
  const isSwitchAccount = unWrapOption(
    useAppSelector(selectSwitchAccountPermission),
    () => false
  );
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [moveTxn, setMoveTxn] = useState({
    show: false,
    name: "",
    uid: "",
    txnId: "",
  });
  const [successPopup, setSuccessPopup] = useState({
    show: false,
    message: "",
  });
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  useEffect(() => {
    if (id) {
      run();
    }
  }, [filterDate]);
  const run = async () => {
    setIsLoading(true);
    const result = await TransactionService.getUserTransactions(
      id ?? "",
      "",
      "",
      filterDate.startDate
        ? format(new Date(filterDate.startDate), "dd-MM-yyyy")
        : "",
      filterDate.endDate
        ? format(new Date(filterDate.endDate), "dd-MM-yyyy")
        : "",
      "",
      "",
      [""],
      1,
      100000
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        if (some(result.value.data)) {
          setData(some(result.value.data || []));
          setPaging((p) => ({
            ...p,
            total: (result.value.data || []).length,
            page: 1,
            limit: 10,
          }));
        }
      }
    }
    setIsLoading(false);
  };

  const exportToExcel = () => {
    let cols: string[] = [
      "Transaction ID",
      "Type",
      "Category",
      "Wallet",
      "Time",

      "Amount",
      "Approved Amount",
      "Approval Status",
      "Status",
    ];

    let content: any[] = [];
    pipe(
      data,
      fold(
        () => [],
        (d) => (d.length > 0 ? d : [])
      )
    ).map((item: CardTransactionInfo) => {
      content = [
        {
          ["Transaction ID"]: item.rrn === "" || "-",
          ["Type"]: item.type || "-",
          ["Category"]:
            item.categoryInfo?.name === "" ? "-" : item.categoryInfo?.name,
          ["Wallet"]: item.txnWallet || "-",
          ["Time"]: item.createdAt === "" ? "-" : item.createdAt,
          ["Amount"]: item.amt || 0,
          ["Approved Amount"]:
            item.approvalStatus === "APPROVED" ? item.appAmt || 0 : 0,
          ["Approval Status"]:
            item.approvalDisplayStatus === ""
              ? "-"
              : item.approvalDisplayStatus,
          ["Status"]: item.txnStatus,
        },
        ...content,
      ];
    });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];
    xlsx(output, { fileName: "Transactions" });
  };

  const tblData = (tblData: CardTransactionInfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    return result;
  };

  const MoveTxn = async () => {
    const result = await TransactionService.moveTxnFbaToTa(
      moveTxn.uid,
      moveTxn.txnId
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        setMoveTxn({
          name: "",
          uid: "",
          txnId: "",
          show: false,
        });
        setSuccessPopup({ show: true, message: result.value.message ?? "" });
      } else {
        setError({ show: true, message: result.value.message ?? "" });
      }
    } else {
      setError({ show: true, message: "Transfer Failed" });
    }
  };

  return (
    <>
      <div id="5">
        <WhiteCard className="mt-0 rounded-10 ">
          <div className="d-flex justify-content-between">
            <div className="col-5 card-title fw-bold">
              <img
                src={TransactionIcon}
                className="img-fluid p-1 rounded-6_i alert-success"
                alt="icon"
              />
              &nbsp;Transactions
            </div>
            <div className="">
              <div className="row">
                <div className="col-2 text-center">
                  <svg
                    className="comman-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 47.54 43.884"
                    onClick={exportToExcel}
                  >
                    <path
                      id="download"
                      d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                      fill="#103458"
                    />
                  </svg>
                </div>
                {/* <div className="col-5">
                  <DropdownButton title="All Status" id="ncaish-status">
                    All Status
                  </DropdownButton>
                </div> */}
                &nbsp;
                <div className="col-5">
                  <DateRangeDropdown
                    startDate={filterDate.startDate}
                    endDate={filterDate.endDate}
                    onChange={(data: IDateRange) => setFilterDate(data)}
                  />
                </div>
              </div>
            </div>
          </div>

          {pipe(
            data,
            fold(
              () => [<NoTableData />],
              (items) =>
                items.length > 0
                  ? [
                      <TablePagination
                        data={paging}
                        changePage={(data) =>
                          setPaging((p) => ({ ...p, page: data }))
                        }
                        changeLimit={(data) =>
                          setPaging((p) => ({ ...p, limit: data, page: 1 }))
                        }
                      >
                        <table>
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Transaction ID</th>
                              <th scope="col">Type</th>
                              <th scope="col">Category</th>
                              <th scope="col">Wallet</th>
                              <th scope="col">Time</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Approved Amount</th>
                              <th scope="col">Approval Status</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tblData(items).map((item, key) => (
                              <tr key={key}>
                                <td className="text-start">
                                  {item.categoryInfo?.icon && (
                                    <img
                                      src={item.categoryInfo?.icon}
                                      width={40}
                                      alt=""
                                      className="px-2"
                                    />
                                  )}
                                </td>
                                <td>
                                  <Link
                                    className="text-secondary ncash_nav-link"
                                    to={
                                      RoutePaths.DashboardDetaxTransactionDetail +
                                      "/" +
                                      item.txnId +
                                      "/" +
                                      id +
                                      "/" +
                                      callFrom
                                    }
                                  >
                                    {item.rrn || "-"}
                                  </Link>{" "}
                                </td>
                                <td className="text-start">{item.type} </td>
                                <td className="text-start">
                                  {item.categoryInfo?.name === ""
                                    ? "-"
                                    : item.categoryInfo?.name}
                                </td>
                                <td>{item.txnWallet || "-"}</td>
                                <td>
                                  {item.createdAt === "" ? "-" : item.createdAt}
                                </td>
                                <td className="text-center">
                                  {item.amt && currencyFormat(item.amt)}
                                </td>
                                <td className="text-center">
                                  {item.approvalStatus === "APPROVED"
                                    ? currencyFormat(item.appAmt || 0)
                                    : "-"}
                                </td>
                                <td>{item.approvalDisplayStatus || "-"}</td>
                                <td className="text-center">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <StatusText
                                      text={item.txnStatus}
                                      className={classNames({
                                        //color1: item.txnStatus == "INITIATED",
                                        color2: item.txnStatus == "FAILED",
                                        color3: item.txnStatus == "SUCCESS",
                                        //color4: item.txnStatus == "RETRY",
                                      })}
                                    />
                                    {callFrom === "detax" &&
                                      isSwitchAccount &&
                                      item.approvalStatus ===
                                        CardTransactionInfo.ApprovalStatusEnum
                                          .BILLUPLOADPENDING &&
                                      item.txnWallet ===
                                        CardTransactionInfo.TxnWalletEnum.FBA &&
                                      item.partnerTxnType !==
                                        "FUNDPOST_DEBIT" &&
                                      item.partnerTxnType !==
                                        "FUNDPOST_CREDIT" && (
                                        <div
                                          className="dropdown"
                                          style={{
                                            marginLeft: 0,
                                            border: 0,
                                          }}
                                        >
                                          <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{
                                              backgroundColor: "transparent",
                                              backgroundImage: "none",

                                              appearance: "none",
                                              MozAppearance: "none",
                                              WebkitAppearance: "none",
                                              width: 1,
                                              border: 0,
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="black"
                                              className="bi bi-three-dots-vertical"
                                              viewBox="0 0 16 16"
                                              style={{ marginLeft: -8 }}
                                            >
                                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                            </svg>
                                          </button>
                                          <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() =>
                                                  setMoveTxn({
                                                    show: true,
                                                    name: name ?? "",
                                                    uid: id ?? "",
                                                    txnId: item.rrn ?? "",
                                                  })
                                                }
                                                style={{ fontSize: 14 }}
                                              >
                                                Transfer FBA to TA
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </TablePagination>,
                    ]
                  : [<NoTableData />]
            )
          )}
        </WhiteCard>
        <TransferTxn
          show={moveTxn.show}
          data={{ name: moveTxn.name, txdId: moveTxn.txnId, uid: moveTxn.uid }}
          onApprove={() => {
            MoveTxn();
          }}
          onHide={() => {
            setMoveTxn({
              name: "",
              uid: "",
              txnId: "",
              show: false,
            });
          }}
        />
        <SuccessPopup
          message={successPopup.message}
          show={successPopup.show}
          onClose={(flag) => {
            setSuccessPopup({ show: false, message: "" });
            run();
          }}
        />
        <ErrorPopup
          message={error.message}
          show={error.show}
          onClose={() => setError({ show: false, message: "" })}
        />
      </div>
    </>
  );
};
