import { Modal } from "react-bootstrap";
import "./table/success-popup.scss";
// import "././reject-popup.scss";
const SuccessPopup: React.FC<{
  message?: string;
  show?: boolean;
  onClose: (show: boolean) => void;
  txtSmall?: boolean;
}> = ({ message = "Successful!", show = false, onClose, txtSmall = false }) => {
  return (
    <>
      <Modal show={show} onClose={() => onClose(false)} centered>
        <Modal.Header className="d-flex flex-column justify-content-center mt-3">
          <div className="text-center rounded-circle bg-success  text-light  fs-2 ncaish-w-50">
            &#10004;
          </div>
          <Modal.Title
            className="text-success mt-3 mb-3"
            style={
              txtSmall
                ? { fontSize: 20, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            {message}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer className="ncaish-bg-grey1 d-flex justify-content-center pointer">
          {/* <p className="light fw-bold">OK</p> */}
          <button
            className="primary-bg btn success-popup-button"
            onClick={() => onClose(false)}
            style={
              txtSmall
                ? { fontSize: 14, textAlign: "center" }
                : { textAlign: "center" }
            }
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuccessPopup;
