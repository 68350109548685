import React from "react";
import classNames from "classnames";

import "../horizontal-tabs/horizontal-tabs.scss";
import { DetaxDispatch, DetaxReduxStore } from "../../state/slice/detax.slice";

import { useAppSelector } from "../../hooks/hooks";
import { tabData, TTab } from "../pages/dashboard/upload-txn/txn.utils";
import CreateBatchButton from "../pages/dashboard/upload-txn/create-batch-button";
import xlsx from "json-as-xlsx";
import { TDetaxBatchInfo } from "../../models/batches.models";
import { unWrapOption } from "../../utils/option.utils";
import { isSome } from "fp-ts/lib/Option";
import { object } from "yup";
import { AlphaReduxStore } from "../../state/slice/alpha.slice";
import { DetaxTypeKind } from "../../models/detax-type-kind";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../utils/route.utils";
import { Link } from "react-router-dom";
import { fromPublic } from "../../utils/common.utils";
import { TabTypeKind } from "../pages/dashboard/upload-txn/create-batch-tab";
const AddSingleIcon = fromPublic("/images/icons/usericon.svg");
const MultiUserIcon = fromPublic("/images/icons/multiuser.svg");
type TabProps = {
  tabs?: TTab[];
};

const TransactionTabs: React.FC<TabProps> = ({ tabs = tabData }) => {
  const navigate = useNavigate();
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const enableBatchExcel = useAppSelector(
    AlphaReduxStore.selectEnableBatchExcel
  );
  const batches: TDetaxBatchInfo[] = useAppSelector(
    DetaxReduxStore.selectBatches
  );
  const giftCardStatus = useAppSelector(AlphaReduxStore.selectGiftCardStatus);
  const npsStatus = useAppSelector(AlphaReduxStore.selectNpsStatus);
  const exportToExcel = () => {
    let cols: string[] = [];
    cols.push("Batch Code");
    cols.push("Created At");
    cols.push("Updated At");
    cols.push("Created By");
    cols.push("No Of Employees");
    cols.push("Total Amount");
    cols.push("Status");

    let content: any[] = [];

    batches.forEach((item: TDetaxBatchInfo, index) => {
      const ctx: { [key: string]: any } = {
        ["Batch Code"]: unWrapOption(item.batchCode, () => ""),
      };
      ctx["Created At"] = isSome(item.createdAt) ? item.createdAt.value : "";
      ctx["Updated At"] = isSome(item.updatedAt) ? item.updatedAt.value : "";

      ctx["Created By"] = isSome(item.createdBy)
        ? item.createdBy.value.name
        : "";
      ctx["No Of Employees"] = isSome(item.txnCount) ? item.txnCount.value : 0;

      ctx["Total Amount"] = isSome(item.amount) ? item.amount.value : 0;

      ctx["Status"] = isSome(item.status) ? item.status.value : "";

      content.push(ctx);
    });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];

    xlsx(output, { fileName: "Batches" });
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div id="exTab2" className="p-0 border-bottom">
        <ul className="nav">
          {tabs
            .filter(
              (obj) => obj.tabValue !== (npsStatus ? "" : DetaxTypeKind.NPS)
            )
            .filter(
              (obj) => obj.tabValue !== (giftCardStatus ? "" : DetaxTypeKind.GC)
            )
            .map((tab, index) => {
              return (
                <li className="nav-item cursor-pointer" key={index}>
                  <a
                    className={classNames(
                      "nav-link text-secondary fw-bold fs-6 ",
                      {
                        active: tab.tabValue === activeTab,
                      }
                    )}
                    data-toggle="tab"
                    onClick={() => {
                      DetaxDispatch.setDetaxType(tab.tabValue);
                    }}
                  >
                    {tab.tabLabel}
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        {/* {activeTab !== "NPS" && (
          <div className="text-center mx-2" style={{ cursor: "pointer" }}>
            <svg
              className="comman-icon cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 47.54 43.884"
              onClick={exportToExcel}
            >
              <path
                id="download"
                d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                fill="#103458"
              />
            </svg>
          </div>
        )} */}
        {enableBatchExcel && activeTab === DetaxTypeKind.FBA ? (
          <DropdownButton
            title="Create Batch"
            id="btn-gradient"
            style={{ cursor: "pointer" }}
          >
            <div
              onClick={() => {
                DetaxDispatch.clearSelectedEligibleUsers();
                DetaxDispatch.setTabType(TabTypeKind.SelectUser);
                DetaxDispatch.showPage("ELIGIBLE_USERS");
              }}
              className="fw-bold fs-xsmall dropdown-item"
            >
              {/* <img src={AddSingleIcon} className="img-fluid px-2" alt="icon" /> */}
              Select Users
            </div>

            <Dropdown.Item>
              <Link
                className=" ncaish-link fw-bold text-black fs-xsmall"
                to={RoutePaths.DashboardBatchCreate}
              >
                {/* <img
                  src={MultiUserIcon}
                  className="img-fluid px-2"
                  alt="icon"
                /> */}
                Upload Template
              </Link>
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          <div>
            <CreateBatchButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TransactionTabs);
