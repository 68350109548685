import { isSuccess } from "@devexperts/remote-data-ts";
import { fromNullable, isSome } from "fp-ts/lib/Option";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { OAuthLoginApiService } from "../../api/auth/oauthLoginApi.service";
import { SwitchAccountApiRequest } from "../../api/oauth/model/switchAccountApiRequest";
import { useAppSelector } from "../../hooks/hooks";
import { AuthService } from "../../services/auth.service";
import { resetProfile, selectProfile } from "../../state/slice/profile.slice";
import { isPermissionToDetaxCard } from "../../utils/auth.utils";
import { fromPublic, titleCase } from "../../utils/common.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import {
  selectFullName,
  selectSwitchAccountPermission,
  selectUserPermissions,
} from "../app/app.slice";
import ReuseIcon from "../common/reuse-icons/reuse-icons";
import CustomSearchBar from "../custom-search/custom-search";
import AccountErrorPopup from "../pages/dashboard/common/account-error-popup";
import LogoutPopup from "../pages/dashboard/common/logout-popup";
import SwitchAccountPopup from "../pages/dashboard/common/switch-account-popup";
import BalanceAmount from "./balance-amount";
import "./sub-header.scss";
import { TransactionService } from "../../services/transaction.service";
import { CorpApplicationApiResponse } from "../../model/corpApplicationApiResponse";
import { CorpWalletTxnData } from "../../api/detax/model/corpWalletTxnResponse";
import { currencyFormat } from "../../utils/format.utils";
import {
  ITableData,
  TablePagination,
} from "../pages/dashboard/common/table/table-pagination";
import { Modal } from "react-bootstrap";
import DateRangeDropdown from "../pages/dashboard/common/date-range-dropdown";
import { IDateRange } from "../pages/dashboard/claims/transactions-list";
import { format } from "date-fns";
import NoTableData from "../pages/dashboard/common/no-table-data";
import { AlphaReduxStore } from "../../state/slice/alpha.slice";
import Select from "react-select";
import { DetaxTypeKind } from "../../models/detax-type-kind";
import { CardTransactionInfo } from "../../api/detax/model/cardTransactionInfo";
interface Option {
  value: string;
  label: string;
}

const closeIcon = fromPublic("/images/icons/Close.svg");

const customStyles = {
  // container: (provided) => ({
  //   ...provided,
  //   width: 300,
  //   margin: '0 auto',
  // }),
  // control: (provided, state) => ({
  //   ...provided,
  //   backgroundColor: state.isFocused ? 'lightblue' : 'white',
  //   borderColor: state.isFocused ? 'blue' : 'gray',
  //   boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
  //   '&:hover': {
  //     borderColor: state.isFocused ? 'blue' : 'gray',
  //   },
  // }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    // border: "1px solid blue",
    zIndex: 999,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 15,
    // backgroundColor: state.isSelected ? 'blue' : state.isFocused ? 'lightblue' : 'white',
    // color: state.isSelected ? 'white' : 'black',
    // '&:hover': {
    //   backgroundColor: state.isSelected ? 'blue' : 'lightgray',
    //   color: state.isSelected ? 'white' : 'black',
    // },
  }),
  // singleValue: (provided) => ({
  //   ...provided,
  //   color: 'black',
  // }),
};

const walletType = [
  {
    value: CardTransactionInfo.TxnWalletEnum.FBA,
    label: "Flexible Allowance",
  },
  { value: CardTransactionInfo.TxnWalletEnum.TA, label: "Travel Allowance" },
  { value: CardTransactionInfo.TxnWalletEnum.GC, label: "Rewards" },
];
const txnType = [
  { value: "CREDIT", label: "Credit" },
  { value: "DEBIT", label: "Debit" },
];

const DashboardSubHeader = () => {
  const data = useSelector(selectProfile);
  const userPermissions = useAppSelector(selectUserPermissions);
  const isSwitchAccount = unWrapOption(
    useAppSelector(selectSwitchAccountPermission),
    () => false
  );

  const corpName = useSelector(selectFullName);

  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [show, setShow] = useState(false);
  const [showSwitchAccountPopup, setShowSwitchAccountPopup] = useState(false);
  const [selectedCorporateId, setSelectedCorporateId] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleLogout = async () => {
    const result = await OAuthLoginApiService.oAuthLogout();
    if (isSuccess(result)) {
      // MyStorage.AuthToken.ClearData();
      // MyStorage.AuthUser.ClearData();
      // MyStorage.LoginId.ClearData();
      // dispatch(resetProfile());
      localStorage.clear();
      navigate("/login");
    } else {
      console.log("logout api call failed");
    }
  };

  const handleSwitchAccountToSuperAdmin = async () => {
    if (selectedCorporateId === "") {
      const requestPaylaod: SwitchAccountApiRequest = {
        uid: "",
        cid: selectedCorporateId,
        switchTo: SwitchAccountApiRequest.SwitchToEnum.SUPERADMIN,
      };

      const result = await AuthService.switchAccountTokenUrl(requestPaylaod);

      if (isSuccess(result)) {
        if (result.value.successful) {
          console.log(result.value.url);
          const url = fromNullable(result.value.url);
          if (isSome(url)) {
            // const urlArray = url.value.split("/");

            // const urlToSwitch =
            //   `${process.env.REACT_APP_SUPER_ADMIN_DOMAIN_URL}` +
            //   urlArray.pop();

            localStorage.clear();
            window.location.href = url.value;
          } else {
            setError("Url not found to switch.");
          }
          setShowSwitchAccountPopup(false);
        } else {
          setError(
            result.value.message || "You are not permitted to switch accounts."
          );
          setShowSwitchAccountPopup(false);
        }
      } else {
        //setError("Something went wrong.");
        setShowSwitchAccountPopup(false);
      }
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center my-2 mb-3 border-bottom"
        id="sub-header"
      >
        {/* <CustomSearchBar /> */}
        <div>Welcome {corpName}</div>
        <div className="d-flex justify-content-center align-items-center mb-2">
          {isPermissionToDetaxCard(userPermissions) && (
            <ReuseIcon
              className="dropdown mx-2 p-2 border-0 shadow-sm pe-auto"
              onClick={() => {
                setShow(true);
              }}
            >
              <Link
                className="border-1 rounded-circle wrap-profile"
                to=""
                // role="button"
                // id="dropdownMenuLink"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
                style={{
                  textDecorationLine: "none",
                  cursor: "pointer",
                }}
              >
                <div
                  className="d-flex gap-2 align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/icon-wallet.jpg"}
                    alt="wallet balance"
                    width="28"
                  />

                  <div
                    className="pt-1 fw-bold wallet-balance"
                    style={{
                      textDecorationLine: "none",
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <label style={{ cursor: "pointer" }}>
                      Wallet&nbsp;balance&nbsp;:&nbsp;
                    </label>

                    <BalanceAmount />
                  </div>
                </div>
              </Link>
            </ReuseIcon>
          )}
          {isSwitchAccount ? (
            <ReuseIcon className="mx-3">
              <button
                onClick={() => {
                  setShowSwitchAccountPopup(true);
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 18 18"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                  ></path>
                </svg>
              </button>
            </ReuseIcon>
          ) : null}
          <ReuseIcon className="dropdown">
            <Link
              className="border-0 rounded-circle wrap-profile"
              to=""
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width={24}
                height={24}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 140.667 140.667"
              >
                <path
                  id="user"
                  d="M76.333,76.333A35.167,35.167,0,1,1,111.5,41.167,35.167,35.167,0,0,1,76.333,76.333Zm0,26.375c28.01,0,53.143,5.017,70.333,26.932v17.027H6V129.64C23.191,107.72,48.323,102.708,76.333,102.708Z"
                  transform="translate(-6 -6)"
                  fill="#5f6b76"
                />
              </svg>
            </Link>

            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuLink"
              style={{ width: 300 }}
            >
              <li>
                <div className="row">
                  {/*
                  <div className="col-md-3 col-lg-3">
                    <div className="pt-2 px-3 mr-1">
                      <img
                        src="https://media.istockphoto.com/photos/middle-aged-man-with-pleasant-face-expression-picture-id1005817658?b=1&k=20&m=1005817658&s=170667a&w=0&h=tpil3FJm2k6CHWiVczRjC-dBR8kP1ZckPMVETYX-89g="
                        width="70"
                        height="60"
                        alt="user profile"
                        className="rounded-3"
                      />
                    </div>
                  </div>
        */}
                  <div className="col-md-9 col-lg-9">
                    <Link
                      to={RoutePaths.DashboardMyProfile}
                      className="dropdown-item"
                    >
                      <div>{data ? data.name : "Username"}</div>
                      <div className="badge bg-primary opacity-25 ">
                        {data?.role &&
                          titleCase(
                            data?.role
                              .replace(/role_/i, "")
                              .split("_")
                              .join(" ")
                          )}
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  to={RoutePaths.DashboardMyProfile}
                  className="dropdown-item"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                  </svg>{" "}
                  My Account
                </Link>
              </li>
              {!isSwitchAccount && (
                <li>
                  <Link
                    to={RoutePaths.DashboardChangePassword}
                    className="dropdown-item"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                    </svg>{" "}
                    Change Password
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to=""
                  className="dropdown-item"
                  onClick={() => handleLogout()}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M21 3.01H3c-1.1 0-2 .9-2 2V9h2V4.99h18v14.03H3V15H1v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14a2 2 0 00-2-2zM11 16l4-4-4-4v3H1v2h10v3z"></path>
                  </svg>{" "}
                  Sign Out
                </Link>
              </li>
            </ul>
          </ReuseIcon>
        </div>
      </div>
      <LogoutPopup
        show={showPopup}
        onHide={() => setShowPopup(false)}
        onLogout={() => handleLogout()}
      />
      <SwitchAccountPopup
        show={showSwitchAccountPopup}
        onHide={() => setShowSwitchAccountPopup(false)}
        onApprove={() => handleSwitchAccountToSuperAdmin()}
      />
      {error && (
        <AccountErrorPopup
          errorText={error}
          show={!!error}
          onHide={(show) => {
            setError("");
          }}
        />
      )}
      <TxnModal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </>
  );
};
export default DashboardSubHeader;

interface props {
  show: boolean;
  onClose: () => void;
}

const TxnModal: React.FC<props> = ({ show, onClose }) => {
  const [paging, setPaging] = useState<ITableData>({
    total: 10,
    page: 1,
    limit: 10,
  });
  const [filterDate, setFilterDate] = useState<IDateRange>({});
  const [txnData, setTxnData] = useState<Array<CorpWalletTxnData>>();
  const [selectWalletType, setSelectWalletType] = useState<Option>();
  const [selectTxnType, setSelectTxnType] = useState<Option>();
  const giftCardStatus = useAppSelector(AlphaReduxStore.selectGiftCardStatus);
  const npsStatus = useAppSelector(AlphaReduxStore.selectNpsStatus);
  useEffect(() => {
    setSelectTxnType(undefined);
    setSelectWalletType(undefined);
    setFilterDate({});
  }, [show]);
  useEffect(() => {
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [selectTxnType, selectWalletType]);
  useEffect(() => {
    getTransaction();
  }, [paging.page, paging.limit, paging.total, filterDate]);

  const getTransaction = async () => {
    const result = await TransactionService.getCorpTransactions(
      "",
      selectTxnType?.value ? selectTxnType.value : "",
      selectWalletType?.value ? selectWalletType.value : "",
      "",
      [],
      filterDate.startDate
        ? format(new Date(filterDate.startDate), "dd-MM-yyyy")
        : "",
      filterDate.endDate
        ? format(new Date(filterDate.endDate), "dd-MM-yyyy")
        : "",
      paging.page,
      paging.limit
    );
    if (isSuccess(result)) {
      if (result.value.successful) {
        setTxnData(result.value.data);
        setPaging((p) => ({
          ...p,
          total: result.value.recordCount || 10,
          page: paging.page,
          limit: paging.limit,
        }));
      } else {
        setPaging((p) => ({
          ...p,
          total: 1000,
          page: paging.page,
          limit: paging.limit,
        }));
        console.log("Test", result.value.message);
      }
    } else {
      console.log("Error");
      setPaging((p) => ({
        ...p,
        total: 1000,
        page: paging.page,
        limit: paging.limit,
      }));
    }
  };
  function formatDateString(dateString: string) {
    if (dateString.length !== 8) {
      throw new Error("Invalid date string length");
    }

    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);

    return `${day}-${month}-${year}`;
  }

  const handleWalletTypeSelection = (event: any) => {
    setSelectWalletType(event);
  };

  const handleTxnTypeSelection = (event: any) => {
    setSelectTxnType(event);
  };
  return (
    <Modal
      centered
      show={show}
      // size="xl"
      onHide={() => {
        onClose();
      }}
    >
      <style>{`.modal-dialog { max-width: ${900}px;}`}</style>
      <div style={{ padding: "1%" }}>
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <p
            style={{
              fontWeight: "bold",
              margin: 0,
              fontSize: 18,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Corporate Wallet Transactions
            <img
              src={closeIcon}
              style={{ height: 15, width: 15, marginTop: 5, cursor: "pointer" }}
              onClick={() => {
                onClose();
                setFilterDate({});
              }}
            />
          </p>

          <p
            style={{
              color: "#777777",
              fontSize: 14,
              fontWeight: "600",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="my-1 mb-1 border-bottom"
          >
            {txnData && txnData.length > 0 && (
              <span>List of transactions from the corporate wallet</span>
            )}
          </p>

          {((txnData && txnData.length > 0) ||
            (filterDate.startDate && filterDate.endDate) ||
            selectTxnType ||
            selectWalletType) && (
            <div
              className="mb-1"
              style={{
                display: "flex",
                // alignItems: "flex-end",
                justifyContent: "flex-end",

                // width: 300,
              }}
            >
              <Select
                value={selectTxnType}
                options={txnType}
                onChange={handleTxnTypeSelection}
                className="w-25 me-2"
                placeholder="Select Txn Type"
                styles={customStyles}
                isClearable
              />
              <Select
                value={selectWalletType}
                isClearable
                options={walletType.filter(
                  (obj) =>
                    obj.value !== (giftCardStatus ? "" : DetaxTypeKind.GC)
                )}
                onChange={handleWalletTypeSelection}
                className="w-25 me-2"
                placeholder="Select Wallet Type"
                styles={customStyles}
              />
              <DateRangeDropdown
                startDate={filterDate.startDate}
                endDate={filterDate.endDate}
                onChange={(data: IDateRange) => setFilterDate(data)}
              />
            </div>
          )}

          {(txnData?.length ?? 0) > 0 ? (
            <TablePagination
              data={paging}
              changePage={(data) =>
                setPaging((p: ITableData) => ({ ...p, page: data }))
              }
              changeLimit={(data) =>
                setPaging((p: ITableData) => ({ ...p, limit: data, page: 1 }))
              }
              pagingView={
                paging.page === 1
                  ? (txnData?.length ?? 0) > 10
                    ? false
                    : true
                  : false
              }
            >
              <table>
                <thead>
                  <tr>
                    <th scope="col" style={{ fontSize: 14 }}>
                      Date
                    </th>
                    <th scope="col" style={{ fontSize: 14 }}>
                      Description
                    </th>
                    <th scope="col" style={{ fontSize: 14 }}>
                      Transaction Type
                    </th>
                    <th scope="col" style={{ fontSize: 14 }}>
                      Amount
                    </th>
                    <th scope="col" style={{ fontSize: 14 }}>
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 400 }}>
                  {txnData &&
                    txnData.map((obj, key) => (
                      <tr key={key}>
                        <td
                          className="align-table-data-center"
                          style={{ fontSize: 14 }}
                        >
                          {formatDateString(obj.day)}
                        </td>
                        <td
                          className="align-table-data-left"
                          style={{ fontSize: 14 }}
                        >
                          {obj.remarks}
                        </td>
                        <td
                          className="align-table-data-center"
                          style={{ fontSize: 14 }}
                        >
                          {obj.type}
                        </td>
                        <td
                          className="align-table-data-right"
                          style={{ fontSize: 14 }}
                        >
                          {currencyFormat(obj.amt)}
                        </td>
                        <td
                          className="align-table-data-right"
                          style={{ fontSize: 14 }}
                        >
                          {currencyFormat(obj.balance)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TablePagination>
          ) : (
            <NoTableData />
          )}
        </div>
      </div>
    </Modal>
  );
};
