import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DetaxService } from "../../../../services/detax.service";
import { MembershipService } from "../../../../services/membership.service";
import { debounce } from "../../../../utils/common.utils";
import CustomSearchBar from "../../../custom-search/custom-search";
import SuccessPopup from "../common/succes-popup";
import MembershipInvitationTable from "./membership-invitation-table";

const MembershipInvitation: React.FC<{
  show: boolean;
  closeModal: () => void;
  setInviteFlag: (flag: boolean) => void;
}> = ({ show, closeModal, setInviteFlag = () => {} }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [tmp1, setTmp1] = useState("");
  const [tmp2, setTmp2] = useState("");
  const [uid, setUid] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setUid([]);
    setKeyword("");
    setTmp1("");
    setTmp2("");
  }, []);

  const sendInvitation = async () => {
    if (uid.length > 0) {
      setIsLoading(true);
      const result = await MembershipService.sendSubscriptionRequest({ uid });
      setIsLoading(false);
      if (isSuccess(result)) {
        setKeyword("");
        setTmp1("");
        setTmp2("");
        setInviteFlag(true);
        closeModal();
        setUid([]);
        setShowSuccess(true);
      }
    }
  };

  // useEffect(() => {
  //   if (tmp1 === tmp2) setKeyword(tmp2);
  // }, [tmp1]);

  // const updateKeyword = debounce((text: string) => setTmp1(text), 1000);

  return (
    <>
      <Modal show={show} onHide={() => closeModal()} centered size="xl">
        <Modal.Header className="pb-0">
          <Modal.Title>Send Invite</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <CustomSearchBar
            boxShadow="none"
            height="auto"
            width="70%"
            text="Search by Name/ Mobile "
            value={tmp2}
            onChange={(data: any) => {
              setTmp2(data);
              // updateKeyword(data);
            }}
          />

          <MembershipInvitationTable
            keyword={keyword}
            uid={uid}
            searchVal={tmp2}
            setUid={(data) => setUid(data)}
            height="420px"
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => closeModal()}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => sendInvitation()}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Send Now
          </button>
        </Modal.Footer>
      </Modal>
      <SuccessPopup
        message={`Membership request created successfully`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
    </>
  );
};

export default MembershipInvitation;
