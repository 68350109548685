import { useState } from "react";
import { Button } from "react-bootstrap";
import { DetaxCardSubscriptionCountInfo } from "../../../../api/detax/model/detaxCardSubscriptionCountInfo";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import Heading from "../common/heading";
import DetaxExport from "./detax-export";
import DetaxInvitation from "./detax-invitation";
import DetaxStatusCount from "./detax-status-count";
import DetaxTable from "./detax-table";
import "./detax.scss";
const DashboardDetax: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState("");
  const [reload, setReload] = useState(true);
  const [curTbl, setCurTbl] = useState<DetaxCardSubscriptionInfo | null>(null);
  const [getSearch, setGetSearch] = useState(false);
  // useEffect(() => {
  //   setReload(true)
  // }, [curTbl]);

  return (
    <>
      <PageTitle title="De'tax Subscriptions" />
      <Heading title="De'tax Subscriptions" />

      <WhiteCard className="my-4 p-1 rounded">
        <div className="d-flex justify-content-between">
          <CustomSearchBar
            value={searchVal}
            onChange={(val: string) => setSearchVal(val)}
            boxShadow="none"
            height="auto"
            width="70%"
            text="Search by Employee Name / Email / Mobile"
            search={getSearch}
            setSearch={(val: boolean) => setGetSearch(val)}
          />
          <div className="d-flex align-items-center">
            {curTbl && (
              <Button
                className="btn-gradiant-blue btn-lg text-white fs-small me-2"
                onClick={() => setGetSearch(!getSearch)}
              >
                Search
              </Button>
            )}
            {/* {curTbl && <DetaxExport curTbl={curTbl} />} */}
            <Button
              className="btn-gradiant-blue btn-lg text-white fs-small"
              onClick={() => setShow(true)}
            >
              Send Invitation
            </Button>
            <DetaxInvitation
              show={show}
              closeModal={() => setShow(false)}
              setInviteFlag={(flag) => setReload(flag)}
            />
          </div>
        </div>
      </WhiteCard>

      <PageData
        setTbl={setCurTbl}
        searchVal={searchVal}
        reload={reload}
        setReload={() => setReload(false)}
        getSearch={getSearch}
      />
    </>
  );
};
export default DashboardDetax;

const PageData: React.FC<{
  searchVal?: string;
  reload?: boolean;
  setReload: (flag: boolean) => void;
  setTbl: (tbl: TStatusCountInfo) => void;
  getSearch?: boolean;
}> = ({
  searchVal = "",
  reload = false,
  setReload = () => {},
  setTbl = () => {},
  getSearch,
}) => {
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  return (
    <>
      <DetaxStatusCount
        setCurTbl={(data) => {
          setCurTbl(data);
          setTbl(data);
        }}
        curTbl={curTbl}
        reload={reload}
        setReload={(flag) => setReload(flag)}
      />
      {curTbl && (
        <DetaxTable
          searchVal={searchVal}
          curTbl={curTbl}
          getSearch={getSearch}
        />
      )}
    </>
  );
};
