import React from "react";
import { Modal } from "react-bootstrap";
import { downloadImage } from "../../../../utils/common.utils";
import ReuseIcon from "../../../common/reuse-icons/reuse-icons";
import "./heading.scss";

const DocumentModal: React.FC<{
  show: boolean;
  url?: string;
  closeModal: () => void;
}> = ({ show, url, closeModal }) => {
  // console.log(status);
  return (
    <Modal show={show} onHide={() => closeModal()} size='lg' centered>
      <Modal.Header>
        <Modal.Title className="w-100 fs-5 fw-bold">
          <div className="d-flex justify-content-between w-100">
            <span>Documents</span>
            <span onClick={() => downloadImage(url)}>
              <ReuseIcon className="mx-3">
                <svg
                  className="comman-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="47.54"
                  height="43.884"
                  viewBox="0 0 47.54 43.884"
                >
                  <path
                    id="download"
                    d="M36.57,38.4a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,36.57,38.4Zm7.313,0a1.854,1.854,0,1,0-.543,1.286A1.758,1.758,0,0,0,43.883,38.4ZM47.54,32v9.142A2.732,2.732,0,0,1,44.8,43.884H2.743a2.645,2.645,0,0,1-1.943-.8A2.645,2.645,0,0,1,0,41.141V32a2.645,2.645,0,0,1,.8-1.943,2.645,2.645,0,0,1,1.943-.8H16.028l3.857,3.885a5.516,5.516,0,0,0,7.77,0l3.885-3.885H44.8A2.732,2.732,0,0,1,47.54,32ZM38.255,15.743a1.648,1.648,0,0,1-.4,2l-12.8,12.8a1.8,1.8,0,0,1-2.572,0l-12.8-12.8a1.648,1.648,0,0,1-.4-2,1.708,1.708,0,0,1,1.685-1.115h7.313V1.829A1.758,1.758,0,0,1,18.826.543,1.758,1.758,0,0,1,20.112,0h7.313a1.758,1.758,0,0,1,1.286.543,1.758,1.758,0,0,1,.543,1.286v12.8h7.313a1.706,1.706,0,0,1,1.685,1.115Z"
                    fill="#103458"
                  />
                </svg>
              </ReuseIcon>
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img style={{ width: "100%" }} src={url} alt="url" />
      </Modal.Body>
    </Modal>
  );
};

export default DocumentModal;
