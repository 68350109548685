import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
// import "././reject-popup.scss";

const TransferTxn: React.FC<{
  show: boolean;
  data: {
    name: string;
    uid: string;
    txdId: string;
  };
  onHide: (show: boolean) => void;
  onApprove: (val: string) => void;
}> = ({ show, onHide, onApprove, data }) => {
  return (
    <Modal show={show} onHide={() => onHide(false)} centered>
      <Modal.Header>
        <p className="m-0" style={{ fontWeight: "bold", fontSize: 16 }}>
          Transfer Txn from FBA to TA
        </p>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: 14 }} className="m-0">
          Transaction with RRN -{" "}
          <span style={{ fontWeight: "bold" }}>{data.txdId}</span> by{" "}
          <span style={{ fontWeight: "bold" }}>{data.name}</span> will be moved
          from the FBA wallet to TA wallet.
        </p>
      </Modal.Body>

      <Modal.Footer className=" d-flex justify-content-right">
        <Button
          className="btn-gradiant-gray btn-lg text-white fs-small m-1 me-2"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
        <Button
          className="btn-gradiant-blue btn-lg text-white fs-small m-1 me-2"
          onClick={() => onApprove(data.uid)}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferTxn;
