import logoLoader from "../../../../output_Idbo5p.gif";
import { adminTableHeight } from "../../../../utils/common.utils";
const TableLoader = () => {
  return (
    <div
      className="h-100 d-flex justify-content-center align-items-center"
      style={{
        background: "#fff",
        maxHeight: adminTableHeight(),
      }}
    >
      <div
        // className="spinner-border"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <img src={logoLoader} alt="Loading.." width="100" height="100" />
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
export default TableLoader;
