export interface OAuthLoginRequest {
  username: string;
  password: string;
}

export interface OAuthLoginResponse {
  access_token?: string;
  token_type?: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  id?: string;
  cid?: string;
  email?: string;
  full_name?: string;
  roles?: string[];
  jti?: string;
  initPassChanged?: boolean;
  appToken?: string;
  permissions?: Array<UserPermission.PermissionType>;
  switchAccount?: boolean;

  successful?: boolean;
  message?: string;
}

//FX, DETAX_CARD, NPS, AA

export namespace UserPermission {
  // export type RoleEnum =
  //   | "ROLE_NCAISH_ADMIN"
  //   | "ROLE_CORPORATE_APPLICANT"
  //   | "ROLE_CORPORATE_ADMIN"
  //   | "ROLE_RETAIL_INVESTOR"
  //   | "ROLE_CORPORATE_EMPLOYEE"
  //   | "ROLE_FX_TRIAL_APPLICANT"
  //   | "ROLE_FX_TRIAL";
  // export const RoleEnum = {
  //   NCAISHADMIN: "ROLE_NCAISH_ADMIN" as RoleEnum,
  //   CORPORATEAPPLICANT: "ROLE_CORPORATE_APPLICANT" as RoleEnum,
  //   CORPORATEADMIN: "ROLE_CORPORATE_ADMIN" as RoleEnum,
  //   RETAILINVESTOR: "ROLE_RETAIL_INVESTOR" as RoleEnum,
  //   CORPORATEEMPLOYEE: "ROLE_CORPORATE_EMPLOYEE" as RoleEnum,
  //   FXTRIALAPPLICANT: "ROLE_FX_TRIAL_APPLICANT" as RoleEnum,
  //   FXTRIAL: "ROLE_FX_TRIAL" as RoleEnum,
  // };
  export type PermissionType =
    | "FX"
    | "DETAX_CARD"
    | "NPS"
    | "AA"
    | "MEMBERSHIP";
  export const PermissionType = {
    FX: "FX" as PermissionType,
    DETAX_CARD: "DETAX_CARD" as PermissionType,
    NPS: "NPS" as PermissionType,
    AA: "AA" as PermissionType,
    MEMBERSHIP: "MEMBERSHIP" as PermissionType,
  };
}
