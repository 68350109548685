import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./settings.scss";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { NcaishDetaxSubscriptonApiService } from "../../../../api/detax/api/ncaishDetaxSubscriptonApi.service";
import {
  isSuccess,
  RemoteData,
  RemoteInitial,
} from "@devexperts/remote-data-ts";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { useStoreWithInitializer } from "../../../../state/storeHooks";
import { loadDashboardData } from "../../../../utils/dashboard.utils";
import { EmployeeService } from "../../../../services/employee.service";
import { BusinessUnit } from "../../../../api/detax/model/employeeInfo";
import { NcaishClaimApiService } from "../../../../api/detax/api/ncaishClaimApi.service";
import { CreateClaimApproverApiRequest } from "../../../../api/detax/model/createClaimApproverApiRequest";
import { SetApprover } from "./SetApprover";
import { fromPublic } from "../../../../utils/common.utils";
import { Dispatch } from "../../../../state/dispatch";
import { unWrapOption } from "../../../../utils/option.utils";
import {
  CreateRewardApiResponse,
  RewardsConfigRes,
} from "../../../../api/detax/model/rewardsApiResponse";
import { RewardsService } from "../../../../services/rewards.service";
import Form from "react-bootstrap/Form";
import { IoMdArrowDropdown } from "react-icons/io";
import Lottie from "lottie-react";
import * as empofmonth from "../../../../animations/empofmonth.json";
import * as greatjob from "../../../../animations/great-job.json";
import * as onthespotaward from "../../../../animations/onTheSpotAward.json";
import * as starperformer from "../../../../animations/star-performer.json";
import * as starofthequarter from "../../../../animations/starOfTheQuarter.json";
import * as yourock from "../../../../animations/you-rock.json";
import { boolean } from "yup";
interface Option2 {
  uid: string;
  name: string;
  value: string;
  label: string;
}
interface CustomOptionType {
  uid: string;
  name: string;
  value: string;
  label: string;
  mobile: string;
  email: string;
  department: string;
}
interface Option {
  value: string;
  label: string;
}
const NewRewardPopup: React.FC<{
  data: CreateRewardApiResponse;
  setData: (val: CreateRewardApiResponse) => void;
  actionState1: boolean;
  show: boolean;
  closeModal: (data: any) => any;
  updateRewards: (data: CreateRewardApiResponse) => void;
  createRewards: (data: CreateRewardApiResponse) => void;
  title: string;
  iseditable: boolean;
}> = ({
  show,
  closeModal,
  actionState1,
  data,
  setData,
  updateRewards,
  createRewards,
  title,
  iseditable,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const [dropdown, setDropdown] = useState<RewardsConfigRes[] | undefined>([]);
  const [open, setOpen] = useState(false);
  const defaultData = () => {
    setData({
      rewardName: "",
      rewardLogo: "",
      rewardAnime: "",
      rewardDesc: "",
      rewardAmt: 0,
      rewardConfigName: "",
      id: "",
    });
    setOpen(false);
  };
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {

      const tblResult = await RewardsService.getRewardLogo();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setDropdown(tblResult.value.data);
        } else {
          // setErr(true);
        }
      } else {
        // setErr(true);
      }
      //   setReload(false);
      // }
    };
    run();

    // dispatch(resetDashboardRedirect());
  }, [show]);

  const [collapsed, setCollapsed] = useState(false);
  const [name, setName] = useState("");
  const handleMouseEnter = (data: string) => {
    setCollapsed(true);
  };

  const handleMouseLeave = (data: string) => {
    setCollapsed(false);
  };
  const animation = () => {
    switch (name) {
      case "Spot Award":
        return onthespotaward;
        break;
      case "Great Job!":
        return greatjob;
        break;
      case "You Rock!":
        return yourock;
        break;
      case "Employee of the Month":
        return empofmonth;
        break;
      case "Star Performer":
        return starperformer;
        break;
      case "Star Of the Quarter":
        return starofthequarter;
        break;

      default:
        return greatjob;
        break;
    }
  };
  let selectedName =
    dropdown?.filter((obj) => obj.rewardLogo === data.rewardLogo) ?? [];

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal(false);
          defaultData();
        }}
        centered
        // className="modal-xl"
        // size="xl"
      >
        <style>{`.modal-dialog { max-width: ${600}px; }`}</style>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
            className="m-2"
          >
            <div style={{ width: 230 }}>
              <label className="text-muted" style={{ fontWeight: "500" }}>
                Reward Name
              </label>
              <input
                className={"form-control"}
                value={data.rewardName}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 40) {
                    setData({ ...data, rewardName: inputValue });
                  } else {
                    alert("* The maximum allowed number of characters is 40");
                  }
                }}
              />
            </div>
            <div style={{ width: 230 }}>
              <label className="text-muted" style={{ fontWeight: "500" }}>
                Amount
              </label>
              <input
                className={"form-control"}
                value={data.rewardAmt}
                type="text"
                onChange={(e) => {
                  setData({
                    ...data,
                    rewardAmt: isNaN(parseFloat(e.target.value))
                      ? 0
                      : parseFloat(e.target.value),
                  });
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
            className="m-2"
          >
            <div style={{ width: 230 }}>
              <label className="text-muted" style={{ fontWeight: "500" }}>
                Reward Badge
              </label>
              <div
                className={"form-control"}
                style={{
                  height:
                    data.rewardLogo === "" && !open ? 40 : open ? 200 : 65,
                  overflow: "auto",
                  // display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {!open && (
                  <div
                    onClick={() => setOpen(true)}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // justifyContent: "center",
                      width: 195,
                      // border: "1px solid black",
                    }}
                  >
                    {data.rewardLogo !== "" && (
                      <>
                        <img
                          src={data.rewardLogo}
                          style={{
                            width: 50,
                            height: 50,
                            // border: "1px solid black",
                          }}
                        />
                        {"  "}
                        <p style={{ margin: 0, padding: 5 }}>
                          {" "}
                          {selectedName[0].rewardConfigName}
                        </p>
                      </>
                    )}
                    {data.rewardLogo === "" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        Select Badge <IoMdArrowDropdown />
                      </div>
                    )}
                  </div>
                )}
                {open &&
                  dropdown &&
                  dropdown.map((obj, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setData({
                          ...data,
                          rewardLogo: obj.rewardLogo,
                          rewardConfigName: obj.rewardConfigName,
                        });
                        setOpen(false);
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 5,
                        cursor: "pointer", // Change cursor to pointer on hover
                        transition: "background-color 0.3s ease", // Add a smooth transition for background-color
                        backgroundColor: "transparent", // Set the initial background color
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#f0f0f0"; // Change to your desired background color on hover
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent"; // Reset background color on mouse leave
                      }}
                    >
                      <img
                        src={obj.rewardLogo}
                        style={{
                          width: 50,
                          height: 50,
                          // border: "1px solid black",
                          cursor: "default",
                        }}
                        alt={`Option ${index}`}
                        onMouseEnter={() => {
                          handleMouseEnter(obj.rewardName);
                          setName(obj.rewardConfigName);
                        }}
                        // onMouseLeave={() => {
                        //   handleMouseLeave("");
                        //   setName("");
                        // }}
                        onMouseMove={() => {
                          handleMouseLeave("");
                          setName("");
                        }}
                      />
                      {"  "}
                      <p style={{ margin: 0, padding: 5 }}>
                        {" "}
                        {obj.rewardConfigName}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div style={{ width: 230 }}>
              <label className="text-muted" style={{ fontWeight: "500" }}>
                Description
              </label>
              <textarea
                className="form-control"
                style={{}}
                value={data.rewardDesc}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 120) {
                    setData({ ...data, rewardDesc: inputValue });
                  } else {
                    alert("* The maximum allowed number of characters is 120");
                  }
                }}
                rows={3} // Specify the number of visible rows
              />
            </div>
          </div>
          {/* {collapsed && <Lottie animationData={animation()} />} */}
          <Modal
            show={collapsed}
            onHide={() => {
              // closeModal(false);
              // defaultData();
            }}
            centered
          >
            <Modal.Body>
              <Lottie
                animationData={animation()}
                onMouseLeave={() => {
                  handleMouseLeave("");
                  setName("");
                }}
              />
            </Modal.Body>
          </Modal>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => {
              closeModal(false);
              defaultData();
              // setView(true);
            }}
          >
            Cancel
          </button>
          <button
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => {
              iseditable ? updateRewards(data) : createRewards(data);
            }}
            disabled={
              data.rewardAmt <= 0 ||
              data.rewardName.length === 0 ||
              data.rewardDesc.length === 0 ||
              data.rewardLogo.length === 0
            }
          >
            {actionState1 && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            {iseditable ? "Submit" : "Create"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewRewardPopup;
