import React, { useState } from "react";
import { DetaxBatchCountInfo } from "../../../../api/detax/model/detaxBatchCountInfo";
import { loadDashboardData } from "../../../../utils/dashboard.utils";
import HomeTrxHorizontalTabs from "../../../horizontal-tabs/home-transaction-tabs";
import TrxStatusCount from "./transaction-status-count";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import WhiteCard from "../../../white-card/white-card";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import { useAppSelector } from "../../../../hooks/hooks";

const Batch: React.FC<{
  monthlyTransactions: { [key: string]: number } | null;
  fba?: DetaxBatchCountInfo[] | [];
  ta?: DetaxBatchCountInfo[] | [];
  nps?: DetaxBatchCountInfo[] | [];
  ea?: DetaxBatchCountInfo[] | [];
  gc?: DetaxBatchCountInfo[] | [];
}> = ({
  fba = [],
  ta = [],
  nps = [],
  ea = [],
  gc = [],
  monthlyTransactions,
}) => {
  const [activeTrxTab, setActiveTrxTab] = useState("fba");
  const giftCardStatus = useAppSelector(AlphaReduxStore.selectGiftCardStatus);
  const npsStatus = useAppSelector(AlphaReduxStore.selectNpsStatus);
  return (
    <WhiteCard>
      <h4 className="page-sub-heading"> Batch</h4>
      <div onClick={async () => await loadDashboardData()}>
        <HomeTrxHorizontalTabs
          isNPS={npsStatus}
          isGC={giftCardStatus}
          tab={activeTrxTab}
          setTab={(tab) => setActiveTrxTab(tab || "")}
        />
      </div>

      {activeTrxTab === "fba" && (
        <TrxStatusCount tab={DetaxTypeKind.FBA} counts={fba} />
      )}
      {activeTrxTab === "ta" && (
        <TrxStatusCount tab={DetaxTypeKind.TA} counts={ta} />
      )}
      {activeTrxTab === "ea" && (
        <TrxStatusCount tab={DetaxTypeKind.EA} counts={ea} />
      )}
      {activeTrxTab === "nps" && (
        <TrxStatusCount tab={DetaxTypeKind.NPS} counts={nps} />
      )}
      {activeTrxTab === "gc" && (
        <TrxStatusCount tab={DetaxTypeKind.GC} counts={gc} />
      )}
    </WhiteCard>
  );
};

export default Batch;
