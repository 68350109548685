import { useState } from "react";
import { Button } from "react-bootstrap";
import { DetaxCardSubscriptionCountInfo } from "../../../../api/detax/model/detaxCardSubscriptionCountInfo";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { MembershipTabKind } from "../../../../models/membership-tab-kind";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import PageTitle from "../../../common/page-title";
import CustomSearchBar from "../../../custom-search/custom-search";
import WhiteCard from "../../../white-card/white-card";
import Heading from "../common/heading";
import DashboardDetax from "../detax/detax";
import DetaxTable from "../detax/detax-table";
import MembershipInvitation from "./membership-invitation";
import MembershipTab from "./membership-tab";
import MembershipTable from "./membership-table";
import MembershipBatchStatusCount from "./membershipbatch-status-count";
import MembershipBatchTable from "./membershipbatch-table";

// import DetaxExport from "./detax-export";
// import DetaxInvitation from "./detax-invitation";
// import DetaxStatusCount from "./detax-status-count";
// import DetaxTable from "./detax-table";
// import "./detax.scss";
const MembershipMain: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState("");
  const [reload, setReload] = useState(true);
  const [curTbl, setCurTbl] = useState<DetaxCardSubscriptionInfo | null>(null);

  // useEffect(() => {
  //   setReload(true)
  // }, [curTbl]);

  return (
    <>
      <PageTitle title="Memberships" />
      <Heading title="Memberships" />

      <PageData
        setTbl={setCurTbl}
        searchVal={searchVal}
        show={show}
        setShow={(val: boolean) => setShow(val)}
        setSearchVal={(val: string) => setSearchVal(val)}
        reload={reload}
        setReload={() => setReload(false)}
      />
    </>
  );
};
export default MembershipMain;

const PageData: React.FC<{
  searchVal?: string;
  reload?: boolean;
  show: boolean;
  setShow: (val: boolean) => void;
  setSearchVal: (val: string) => void;
  setReload: (flag: boolean) => void;
  setTbl: (tbl: TStatusCountInfo) => void;
}> = ({
  searchVal = "",
  reload = false,
  show,
  setShow,
  setSearchVal,
  setReload = () => {},
  setTbl = () => {},
}) => {
  const [activeTab, setActiveTab] = useState<MembershipTabKind>(
    MembershipTabKind.INVITEEMPLOYEES
  );
  const [curTbl, setCurTbl] = useState<TStatusCountInfo | null>(null);
  return (
    <>
      <MembershipTab
        activeTab={activeTab}
        setActiveTab={(tabType: MembershipTabKind) => setActiveTab(tabType)}
      />

      {activeTab === MembershipTabKind.INVITEEMPLOYEES && (
        <WhiteCard className="my-4 p-1 rounded">
          <div className="d-flex justify-content-between">
            <CustomSearchBar
              value={searchVal}
              onChange={(val: string) => setSearchVal(val)}
              boxShadow="none"
              height="auto"
              width="70%"
              text="Search by Employee Name"
            />
            <div className="d-flex align-items-center">
              {/* {curTbl && <DetaxExport curTbl={curTbl} />} */}
              <Button
                className="btn-gradiant-blue btn-lg text-white fs-small"
                onClick={() => setShow(true)}
              >
                Send Invitation
              </Button>
              <MembershipInvitation
                show={show}
                closeModal={() => setShow(false)}
                setInviteFlag={(flag) => setReload(flag)}
              />
            </div>
          </div>
        </WhiteCard>
      )}

      {activeTab === MembershipTabKind.INVITEEMPLOYEES && (
        <MembershipTable searchVal={searchVal} />
      )}

      {/* <Heading title="Membership Requests" detail={""} status={""} links={[]} /> */}

      {activeTab === MembershipTabKind.MEMBERSHIPBATCH && (
        <MembershipBatchStatusCount
          setCurTbl={(data) => {
            setCurTbl(data);
            setTbl(data);
          }}
          curTbl={curTbl}
          reload={reload}
          setReload={(flag) => setReload(flag)}
        />
      )}

      {curTbl && activeTab === MembershipTabKind.MEMBERSHIPBATCH && (
        <MembershipBatchTable curTbl={curTbl} />
      )}
    </>
  );
};
