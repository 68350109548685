import classNames from "classnames";
import React from "react";
import "../horizontal-tabs/horizontal-tabs.scss";

type TProps = {
  isFBA?: boolean;
  isTA?: boolean;
  isNPS?: boolean;
  isEA?: boolean;
  isGC?: boolean;
  setTab?: (tab?: string) => void;
  tab?: string;
};

const HomeTrxHorizontalTabs: React.FC<TProps> = ({
  isFBA = true,
  isTA = true,
  isNPS = true,
  isEA = true,
  isGC = true,
  setTab = () => {},
  tab = "",
}) => {
  return (
    <div id="exTab2" className="p-0 border-bottom mb-4">
      <ul className="nav">
        {isFBA && (
          <li className="nav-item" onClick={() => setTab("fba")}>
            <div
              className={classNames("nav-link text-secondary fw-bold fs-6", {
                active: tab === "fba",
              })}
            >
              FBA
            </div>
          </li>
        )}
        {isTA && (
          <li className="nav-item" onClick={() => setTab("ta")}>
            <div
              className={classNames("nav-link text-secondary fw-bold fs-6", {
                active: tab === "ta",
              })}
            >
              Claims
            </div>
          </li>
        )}

        {isEA && (
          <li className="nav-item" onClick={() => setTab("ea")}>
            <div
              className={classNames("nav-link text-secondary fw-bold fs-6", {
                active: tab === "ea",
              })}
            >
              Expense Advance
            </div>
          </li>
        )}
        {isNPS && (
          <li className="nav-item" onClick={() => setTab("nps")}>
            <div
              className={classNames("nav-link text-secondary fw-bold fs-6", {
                active: tab === "nps",
              })}
            >
              NPS
            </div>
          </li>
        )}
        {isGC && (
          <li className="nav-item" onClick={() => setTab("gc")}>
            <div
              className={classNames("nav-link text-secondary fw-bold fs-6", {
                active: tab === "gc",
              })}
            >
              Employee Rewards
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};
export default HomeTrxHorizontalTabs;
