import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NpsAccountResponse } from "../../../../../api/detax/model/npsAccountResponse";
import { NpsContributionResponse } from "../../../../../api/detax/model/npsContributionResponse";
import { NpsService } from "../../../../../services/nps.service";
import { currencyFormat } from "../../../../../utils/format.utils";
import SuccessPopup from "../../../../pages/dashboard/common/succes-popup";
import WhiteCard from "../../../../white-card/white-card";
import { Transactions } from "../employee/tabs";
const SalaryIcon = "/images/icons/icons/salary.svg";

export const PranAccount: React.FC<{
  uid?: string;
}> = ({ uid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<NpsAccountResponse | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState("");
  const [modalType, setModalType] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [actionState, setActionState] = useState(false);
  console.log("Test Data", data);

  useEffect(() => {
    const run = async () => {
      if (uid) {
        setIsLoading(true);
        const result = await NpsService.getUserAccount(uid);
        setIsLoading(false);
        if (isSuccess(result)) {
          if (result.value.successful) {
            console.log("Test", result.value);
            setData(result.value);
          }
        }
      }
    };
    run();
  }, [uid]);

  const updateAccount = async (status: boolean) => {
    if (uid) {
      setActionState(true);
      const result = await NpsService.updateAccount(uid, {
        status: modalType ? "ACTIVATED" : "DEACTIVATED",
        remarks: text,
      });
      setActionState(false);
      if (isSuccess(result)) {
        if (result.value.successful) {
          // setData((p) => result.value || p);
          handleClose();
          setText("");
          setShowSuccess(true);
        }
      }
    }
  };
  const handleClose = () => {
    setShow(false);
    setText("");
    setActionState(false);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <div id="1">
        <WhiteCard className="mt-0 rounded-10">
          <div className="row">
            <div className="col-10 card-title fw-bold">
              <img
                src={SalaryIcon}
                className="img-fluid p-1 rounded-6_i bg-green"
                alt="icon"
              />
              &nbsp;PRAN Details
            </div>
            <div className="col-1">
              {/* {data?.data?.status !== "DEACTIVATED" ? (
                <button
                  className="btn btn-outline-secondary naich-block text-dark  fw-bold fs-xxsmall"
                  onClick={() => {
                    handleShow();
                    setModalType(false);
                  }}
                >
                  Deactivate
                </button>
              ) : (
                <button
                  className="btn btn-outline-secondary naich-block text-dark  fw-bold fs-xxsmall"
                  onClick={() => {
                    handleShow();
                    setModalType(true);
                  }}
                >
                  Activate
                </button>
              )} */}
            </div>
          </div>
          <div className="row mt-2">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      PRAN Account
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data?.data.pranNo}
                    </h5>
                  </div>
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">CBO Code</p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data?.data.cboCode}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className="col-1"
                style={{ borderRight: "1px solid #efefef" }}
              ></div>
              <div className="col-5">
                <div className="row">
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Tier - 1 Status
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data?.data.tierType}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      POP - Code
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data?.data.popCode}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WhiteCard>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          className={
            modalType
              ? "bg-success d-flex flex-column justify-content-center"
              : "bg-danger d-flex flex-column justify-content-center"
          }
        >
          <div className="text-center  text-light fs-10"> &#9888;</div>
          <Modal.Title className="text-light">Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={classNames("text-light fw-bold text-center", {
            "ncaish-bg-danger": !modalType,
            "bg-success": modalType,
          })}
        >
          <p className="modal-title text-white">
            Are you sure to {modalType ? "Activate" : "Deactivate"}?
          </p>
          <div className="form-group">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              rows={2}
              className="form-control mt-2"
              placeholder="Enter remark"
            />
          </div>
        </Modal.Body>

        <Modal.Footer className=" d-flex justify-content-center">
          <Button
            className="btn-gradiant-gray"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>

          <Button
            disabled={actionState}
            variant={modalType ? "success" : "danger"}
            onClick={() => updateAccount(modalType)}
          >
            {actionState ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            ) : modalType ? (
              "Activate"
            ) : (
              "Deactivate"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <SuccessPopup
        message={`${modalType ? "Activated" : "Deactivated"} successfully!`}
        show={showSuccess}
        onClose={(flag) => setShowSuccess(flag)}
      />
    </>
  );
};

export const Contribution: React.FC<{
  uid?: string;
}> = ({ uid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<NpsAccountResponse | null>(null);

  useEffect(() => {
    const run = async () => {
      if (uid) {
        setIsLoading(true);
        const result = await NpsService.getUserAccount(uid);
        setIsLoading(false);
        if (isSuccess(result)) {
          if (result.value.successful) {
            console.log("Test", result.value);
            setData(result.value);
          }
        }
      }
    };
    run();
  }, [uid]);

  return (
    <>
      <div id="2">
        <WhiteCard className="mt-0 rounded-10">
          <div className="row">
            <div className="col-10 card-title fw-bold">
              <img
                src={SalaryIcon}
                className="img-fluid p-1 rounded-6_i bg-green"
                alt="icon"
              />
              &nbsp;Contribution
            </div>
            {/* <div className="col-1">
              <button className="btn btn-outline-secondary naich-block text-dark  fw-bold fs-xxsmall">
                Deactivate
              </button>
            </div> */}
          </div>
          <div className="row mt-2">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Type Of Contribution
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data?.data.contributionType}
                    </h5>
                  </div>
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Monthly Based Salary
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data && data.data?.monthlyBaseSalary
                        ? currencyFormat(data.data?.monthlyBaseSalary)
                        : null}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                className="col-1"
                style={{ borderRight: "1px solid #efefef" }}
              ></div>
              <div className="col-5">
                <div className="row">
                  <div
                    className="col-6 justify-content-end"
                    style={{ display: "inline-grid" }}
                  >
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Employee Pref Monthly
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data && data.data?.prefMonthly
                        ? currencyFormat(data.data?.prefMonthly)
                        : null}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <p className="mb-0 f-13 text-secondary fw-bold">
                      Max Employee Contribution
                    </p>
                    <h5 className="f-15 fw-bold mb-0 text-black">
                      {data && data.data?.maxContribution
                        ? currencyFormat(data.data?.maxContribution)
                        : null}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </WhiteCard>
        {uid && <Transactions id={uid} />}
      </div>
    </>
  );
};
