import React from "react";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../../utils/route.utils";

const GetStarted: React.FC = () => {
  return (
    <div
      className="round-less mb-2 p-3"
      style={{ marginTop: "-10px", backgroundColor: "#163d5c" }}
    >
      <div className="d-md-flex justify-content-between align-items-center">
        <div className="">
          <div className="font-12 font-weight-bold text-white ml-4">
            <div style={{ fontSize: "30px" }}>Welcome to Ncaish</div>
            <p style={{ fontSize: "14px" }}>
              Congratulations team for Signing up with Ncaish Platform. One-stop
              automation for all your employee wellness needs. To get started,
              kindly download the employee template and upload the list of all
              your employees. Once done you can send a broadcast invitation
              through a simple click to all of them at once. Don't forget to
              invite yourself :)
            </p>
            {/* <ul className="d-flex" style={{flexDirection: 'row'}}>
									<li className="text-uppercase" style={{paddingRight: '5%'}}>Create</li>
									<li className="text-uppercase" style={{paddingRight: '5%'}}>Manage</li>
									<li className="text-uppercase">Grow</li>
								</ul> */}
          </div>
          <div className="row pl-3 pr-3 mt-1 mb-2">
            {/* <div className="col-md-2">
									<a href="" className="btn text-white w-100" style={{backgroundColor: 'transparent'}}>Cancel</a>
								</div> */}
            <div className="col-md-4 ms-3">
              {/* <a href="" type="button" className="btn border text-light w-50">
                  {" "}
                  
                </a> */}
              <Link
                className="btn border text-light w-50"
                to={RoutePaths.DashboardEmployees}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-3 mt-md-0 p-3" style={{ textAlign: "right" }}>
          {/* <img src="resources/images/Icons/banner.svg" className="img-fluid w-75"> */}
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
