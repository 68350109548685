import classNames from "classnames";
import "../../verticaltabs.scss";

interface IProps {
  activeTab: string;
  onClick: (tab: string) => void;
}

const VerticalTabs = ({ activeTab = "card", onClick }: IProps) => {
  return (
    <ul className="nav flex-column verticalTab">
      <li className="nav-item" onClick={() => onClick("card")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "card",
          })}
        >
          Card Details
        </div>
      </li>
      <li className="nav-item" onClick={() => onClick("claims")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "claims",
          })}
        >
          Claims
        </div>
      </li>
      <li className="nav-item" onClick={() => onClick("allowance")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "allowance",
          })}
        >
          Flexi-Allowance
        </div>
      </li>
      {/* <li className="nav-item" onClick={() => onClick("salary")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "salary",
          })}
        >
          Salary Structure
        </div>
      </li> */}
      <li className="nav-item" onClick={() => onClick("transactions")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "transactions",
          })}
        >
          Transactions
        </div>
      </li>
    </ul>
  );
};
export default VerticalTabs;
