import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { TAuthData } from "../../types/auth-response";

import { fromPublic } from "../../utils/common.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { unWrapOption } from "../../utils/option.utils";

import { selectGoToPageAfterLogin } from "../app/app.slice";

const Image1 = fromPublic("/images/bg1-new2.svg");

const WelcomeBackPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gotoPage = useAppSelector(selectGoToPageAfterLogin);

  const [authData, setAuthData] = useState<TAuthData>();
  useEffect(() => {
    const authData = MyStorage.AuthUser.LoadData();
    if (authData) {
      setAuthData(authData);
    }
  }, []);

  const handleContinue = async () => {
    navigate(gotoPage);
  };

  return (
    <div className="container p-0">
      <div className="row mt-5 align-items-center">
        <div className="col-12">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-6 ">
                <MediumScreen>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mx-auto">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image1}
                      alt="Mobile"
                    />
                  </div>

                  <div className="textDetail  mt-5">
                    <Heading
                      className="mb-3 mx-5 mt-85"
                      text="Onboarding First Step"
                      variant="small"
                    />

                    <p className="fw-bolder mx-5 text-secondary">
                      Congratulations team for Signing up with Ncaish Platform.
                      We have taken utmost care to make your onboarding Journey
                      Simple, safe and secure. So go ahead signup in few simple
                      steps!
                    </p>
                  </div>
                </MediumScreen>
              </div>
              <div className="col-lg-4 col-md-6">
                <MediumScreen>
                  <div className="container p-0 m-0">
                    <div className="card  p-3 bg-grey shadow rounded-15 border-0">
                      <Heading
                        className="text-center mt-85"
                        text="Welcome Back"
                        variant="large"
                      />
                      <p className="d-none d-sm-none d-md-block text-center mb-0 fw-bold text-black mt-4 mb-1">
                        {authData && unWrapOption(authData.name, () => "")}
                      </p>

                      <div className="mt-4 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block text-center">
                        <span className="text-center fw-bold text-lightblue">
                          We knew you'll be back so we've <br />
                          kept your initial input Safe & Secure. Please
                          <br />
                          continue your Ncaish journey.
                        </span>
                        <div className="text-center ml-auto mr-auto mt-3 ">
                          <Button type="button" onClick={handleContinue}>
                            <>Continue</>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </MediumScreen>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <SmallScreen>
          <div className="container p-0 m-0">
            <div className="card bg-grey shadow border-0">
              <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                <img
                  className="img-fluid"
                  width="791"
                  height="741"
                  src={Image1}
                  alt="BG"
                />
              </div>
              <h1 className="fw-bold text-center mt-4">Welcome Back</h1>
              <p className="d-md-none d-lg-none d-xl-none d-xxl-none text-center mb-4 fw-bold text-black fs-small">
                {authData && unWrapOption(authData.name, () => "")}
              </p>

              <div className="card  inputCard p-2   d-md-none d-lg-none d-xl-none d-xxl-none">
                <span className="text-center fw-bold mt-2 mb-2 text-secondary fs-small">
                  We knew you'll be back so we've <br />
                  kept your initial input Safe & Secure. Please
                  <br />
                  continue your Ncaish journey.
                </span>
              </div>
              <div className="text-center ml-auto mr-auto mt-3">
                <Button type="button" onClick={handleContinue}>
                  <>Continue</>
                </Button>
              </div>
            </div>
          </div>
        </SmallScreen>
      </div>
    </div>
  );
};

export default WelcomeBackPage;
