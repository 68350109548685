/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DetaxUserBasicInfo } from "./detaxUserBasicInfo";
import { FBACategoryInfo } from "./fBACategoryInfo";
import { Timeline } from "./timeline";

export interface CardTransactionInfo {
  txnId?: string;
  categoryInfo?: FBACategoryInfo;
  mcc?: string;
  amt?: number;
  baseAmt?: number;
  appAmt?: number;
  type?: CardTransactionInfo.TypeEnum;
  source?: CardTransactionInfo.SourceEnum;
  remarks?: string;
  scope?: CardTransactionInfo.ScopeEnum;
  txnWallet?: CardTransactionInfo.TxnWalletEnum;
  txnStatus?: CardTransactionInfo.TxnStatusEnum;
  approvalStatus?: CardTransactionInfo.ApprovalStatusEnum;
  approvalDisplayStatus?: string;
  approvedBy?: DetaxUserBasicInfo;
  createdBy?: DetaxUserBasicInfo;
  claimRaised?: boolean;
  claimId?: string;
  createdAt?: string;
  billUploadedAt?: string;
  approvedAt?: string;
  approverRemarks?: string;
  bills?: Array<string>;
  timeline?: Timeline;
  merchant?: string;
  rrn?: string;
  manualTransaction: boolean;
  partnerTxnType?: string;
  surCharge?: boolean;
}
export namespace CardTransactionInfo {
  export type TypeEnum = "DEBIT" | "CREDIT" | "SEIZED";
  export const TypeEnum = {
    DEBIT: "DEBIT" as TypeEnum,
    CREDIT: "CREDIT" as TypeEnum,
    SEIZED: "SEIZED" as TypeEnum,
  };
  export type SourceEnum = "CARD" | "APP" | "SYSTEM";
  export const SourceEnum = {
    CARD: "CARD" as SourceEnum,
    APP: "APP" as SourceEnum,
    SYSTEM: "SYSTEM" as SourceEnum,
  };
  export type ScopeEnum = "PERSONAL" | "BUSINESS";
  export const ScopeEnum = {
    PERSONAL: "PERSONAL" as ScopeEnum,
    BUSINESS: "BUSINESS" as ScopeEnum,
  };
  export type TxnWalletEnum = "FBA" | "TA" | "GC";
  export const TxnWalletEnum = {
    FBA: "FBA" as TxnWalletEnum,
    TA: "TA" as TxnWalletEnum,
    GC: "GC" as TxnWalletEnum,
  };
  export type TxnStatusEnum = "SUCCESS" | "FAILED" | "CANCELLED";
  export const TxnStatusEnum = {
    SUCCESS: "SUCCESS" as TxnStatusEnum,
    FAILED: "FAILED" as TxnStatusEnum,
    CANCELLED: "CANCELLED" as TxnStatusEnum,
  };
  export type ApprovalStatusEnum =
    | "BILL_UPLOAD_PENDING"
    | "APPROVAL_PENDING"
    | "REVIEW_PENDING"
    | "APPROVED"
    | "REJECTED"
    | "FAILED"
    | "MANUAL_TRANSACTIONS";
  export const ApprovalStatusEnum = {
    BILLUPLOADPENDING: "BILL_UPLOAD_PENDING" as ApprovalStatusEnum,
    APPROVALPENDING: "APPROVAL_PENDING" as ApprovalStatusEnum,
    REVIEWPENDING: "REVIEW_PENDING" as ApprovalStatusEnum,
    APPROVED: "APPROVED" as ApprovalStatusEnum,
    REJECTED: "REJECTED" as ApprovalStatusEnum,
    FAILED: "FAILED" as ApprovalStatusEnum,
  };
}
