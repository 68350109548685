import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export interface initiateRequest {
  amount: string;
  modeOfPayment: string;
  date: string;
  referenceId: string;
}

// this popup is for initiate transaction
const InitiateTrasactionPopup: React.FC<{
  actionState: boolean;
  amount?: number;
  date: Date;
  show: boolean;
  closeModal: () => void;
  onSubmit: (req: initiateRequest) => void;
}> = ({ actionState, show, closeModal, onSubmit, ...props }) => {
  const [amount, setAmount] = useState<string>(props.amount?.toString() || "");
  // console.log("batch-initiate-transaction-amount", amount);
  const [modeOfPayment, setModeOfPayment] = useState<string>("NEFT");
  const [date, setDate] = useState<Date>(props.date);
  const [referenceId, setReferenceID] = useState<string>("");
  const crr = new Date();
  useEffect(() => {
    console.log("ren");
    setAmount(props.amount?.toString() || "");
  }, [props.amount]);
  //console.log(format(new Date(), "dd-MM-yyyy"));
  // console.log(
  //   //     amount + ",  " + modeOfPayment + ",  " + date &&
  //   format(parseISO(date || "10-05-2022"), "dd-MM-yyyy") + ",  " + referenceId
  // );
  console.log("Test amount", props.amount);
  const onSetDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDate(new Date(event.target.value));
  };

  const convertDate = (date?: string): string => {
    if (date) {
      const dArr = date.split("-");

      return `${dArr[2]}-${dArr[1]}-${dArr[0]}`;
    } else {
      return `${format(new Date(), "dd-MM-yyyy")}`;
    }
  };
  console.log("Test nft", modeOfPayment);
  return (
    <>
      <Modal show={show} onHide={() => closeModal()} centered size="lg">
        <Modal.Header>
          <Modal.Title> Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-md-6 mb-3">
                <input
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value.toString())}
                  type="number"
                  className="form-control"
                  placeholder="Amount"
                />
              </div>
              <div className="col-md-6">
                <select
                  value={modeOfPayment}
                  className="form-control"
                  onChange={(e) => setModeOfPayment(e.target.value)}
                >
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                  <option value="RTGS">RTGS</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control border-0"
                  value={date.toLocaleDateString("en-CA")}
                  onChange={onSetDate}
                />
              </div>
              <div className="col-md-6">
                <input
                  required
                  value={referenceId}
                  onChange={(e) => setReferenceID(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Reference ID"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            disabled={actionState}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() =>
              onSubmit({
                amount,
                modeOfPayment,
                date: format(date, "dd-MM-yyyy"),
                referenceId,
              })
            }
          >
            {actionState && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InitiateTrasactionPopup;
