import { pipe } from "fp-ts/lib/function";
import { getOrElse, map, toNullable } from "fp-ts/lib/Option";
import React from "react";
import { useAppSelector } from "../../hooks/hooks";
import { selectCompanyInfo } from "./company.slice";

const CompanyDetailsCard = () => {
  const companyInfoOpt = useAppSelector(selectCompanyInfo);

  return pipe(
    companyInfoOpt,
    map(({ name, cin, roc, regNo, address, doi }) => (
      <div className="card shadow-sm px-4 py-2 mt-2 bg-white rounded-15 border-0">
        <div className="form-group">
          <label className="fs-small text-secondary fw-bold">
            Company Name
          </label>
          <p className="label fs-small fw-bold mb-2 text-uppercase text-truncate">
            {pipe(
              name,
              getOrElse(() => "")
            )}
          </p>
        </div>
        <div className="form-group">
          <label className="fs-small text-secondary fw-bold mt-2">CIN</label>
          <p className="label fs-small fw-bold mb-3">
            {pipe(
              cin,
              getOrElse(() => "")
            )}
          </p>
        </div>
        <div className="form-group">
          <label className="fs-small text-secondary fw-bold mt-2">
            ROC Code
          </label>
          <p className="label fs-small fw-bold mb-2 ">
            {pipe(
              roc,
              getOrElse(() => "")
            )}
          </p>
        </div>
        <div className="form-group">
          <label className="fs-small text-secondary fw-bold mt-2">
            Registration No
          </label>
          <p className="label fs-small fw-bold mb-2">
            {pipe(
              regNo,
              getOrElse(() => "")
            )}
          </p>
        </div>
        <div className="form-group">
          <label className="fs-small text-secondary fw-bold mt-2">
            Date Of Incorporation
          </label>
          <p className="label fs-small fw-bold mb-2">
            {pipe(
              doi,
              getOrElse(() => "")
            )}
          </p>
        </div>
        <div className="form-group">
          <label className="fs-small text-secondary fw-bold mt-2">
            Registration Address
          </label>
          <p className="label fs-small fw-bold mb-1">
            {pipe(
              address,
              getOrElse(() => "")
            )}
          </p>
        </div>
      </div>
    )),
    toNullable
  );
};

export default CompanyDetailsCard;
