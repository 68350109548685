import { isSuccess } from "@devexperts/remote-data-ts";
import { FiuConsentInfo } from "../api/account-aggregator/model/fiuConsentInfo";
import { FiuProfileSummaryApiResponse } from "../api/account-aggregator/model/fiuProfileSummaryApiResponse";
import { FiuTransactionApiData } from "../api/account-aggregator/model/fiuTransactionApiData";
import { FiuTransactionApiResponse } from "../api/account-aggregator/model/fiuTransactionApiResponse";
import { FiuTransactionGraphData } from "../api/account-aggregator/model/fiuTransactionGraphData";
import { AAService } from "../services/account-aggregator.service";
import { endAAload, setAAdata } from "../state/slice/aa.slice";
import { store } from "../state/store";

export async function loadAAdata(userId: string) {
    if (userId) {
        let aaTransactions: FiuTransactionApiData | null = null
        let aaTransactionsGraph: FiuTransactionGraphData[] | null = null
        let aaOverview: Array<FiuConsentInfo> | [] = []
        let aaAccount: FiuProfileSummaryApiResponse | null = null

        let [aaTransactionsRes, aaTransactionsGraphRes, aaOverviewRes, aaAccountCountRes] = await Promise.all([
            await AAService.getFiuAccountTransactions(),
            await AAService.getFiuAccountTransactionsGraphData(),
            await AAService.getFIUConsents(userId),
            await AAService.getFIUProfileSummary(),
        ])

        if (isSuccess(aaTransactionsRes)) {
            if (aaTransactionsRes.value.successful) {
                aaTransactions = aaTransactionsRes.value.data || null
            }
        }

        if (isSuccess(aaOverviewRes)) {
            if (aaOverviewRes.value.successful) {
                aaOverview = aaOverviewRes.value.data || []
            }
        }

        if (isSuccess(aaAccountCountRes)) {
            if (aaAccountCountRes.value.successful) {
                aaAccount = aaAccountCountRes.value || null
            }
        }

        if (isSuccess(aaTransactionsGraphRes)) {
            if (aaTransactionsGraphRes.value.successful) {
                aaTransactionsGraph = aaTransactionsGraphRes.value.data || null
            }
        }

        store.dispatch(setAAdata({ aaOverview, aaAccount, aaTransactions, aaTransactionsGraph }));
        store.dispatch(endAAload());
    }
}