import { TAuthData } from "../types/auth-response";

export enum LocalStorageItemKeys {
  authToken = "authToken",
  currentScreenRoute = "currentScreenRoute",
  draftId = "draftId",
  loginId = "loginId",
  authUser = "authUser",
  fxAgreement = "fxAgreement",
  loginUserCid = "ncaish-cid",
}
class StorageAgent<T> {
  private key: LocalStorageItemKeys;

  constructor(key: LocalStorageItemKeys) {
    this.key = key;
  }

  LoadData(): T | null {
    const textData = localStorage.getItem(this.key);

    if (textData) {
      const realData: T = JSON.parse(textData);
      return realData;
    } else {
      return null;
    }
  }

  StoreData(data: T) {
    const text = JSON.stringify(data);
    localStorage.setItem(this.key, text);
  }

  ClearData() {
    localStorage.removeItem(this.key);
  }
}

export interface IToken {
  accessToken: string;
  refreshToken: string;
  tokenExpiry: number;
  expiresAt: number;
  scope: string;
}

export const MyStorage = {
  AuthToken: new StorageAgent<IToken>(LocalStorageItemKeys.authToken),

  DraftId: new StorageAgent<string>(LocalStorageItemKeys.draftId),

  CurrentScreenRoute: new StorageAgent<string>(
    LocalStorageItemKeys.currentScreenRoute
  ),

  LoginId: new StorageAgent<string>(LocalStorageItemKeys.loginId),
  AuthUser: new StorageAgent<TAuthData>(LocalStorageItemKeys.authUser),
  FxAgreement: new StorageAgent<boolean>(LocalStorageItemKeys.fxAgreement),
};
