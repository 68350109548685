import classnames from "classnames";
import { FieldHookConfig, useField } from "formik";
import React from "react";
import { useState } from "react";
import { images } from "../../../../utils/staticData";
import s from "./formik-input-group.module.scss";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

interface Props {
  icon?: keyof typeof images;
  color?: "bg-lightpink" | "bg-lightblue" | "bg-lightpurple";
  // onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const FormikInputGroupEye: React.FC<Props & FieldHookConfig<string> & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>> = ({
  icon = "building",
  color = "bg-lightblue",
  children,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);

  const className = classnames(s["p-2"], {
    [s["ncash_bg-lightpink"]]: color === "bg-lightpink",
    [s["ncash_bg-lightblue"]]: color === "bg-lightblue",
    [s["ncash_bg-lightpurple"]]: color === "bg-lightpurple",
  });

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <div>
      <div className="input-group border-1 rounded-15 ml-auto mr-auto">
        <span
          className="input-group-text ncash_w-group-wrapper input-field"
          id="basic-addon1"
        >
          <div className={className}>
            <img
              className="img-fluid icons w-50"
              src={images[icon]}
              alt="Building"
            />
          </div>
        </span>
        <input
          className={props.className}
          placeholder={props.placeholder}
          type={passwordType}
          {...field}
        //   {...props}

        />
        <span
          className="input-group-text ncash_w-group-wrapper input-field"
          id="basic-addon1"
        >
          <div 
          //className={className}
          >
            {/* <img
              className="img-fluid icons w-50"
              src={images[icon]}
              alt="Building"
            /> */}
             <button
                  className="password-eye"
                  onClick={togglePassword}
                  type="button"

                >
                  {passwordType === "password" ? (
                    <span>
                      <Icon icon={eyeOff} />
                    </span>
                  ) : (
                    <span>
                      <Icon icon={eye} />
                    </span>
                  )}
                </button>

          </div>
        </span>
      </div>

      {meta.error && meta.touched && (
        <div className="fs-small ncash_validate-error text-center mt-2">{meta.error}</div>
      )}
    </div>
  );
};

export default FormikInputGroupEye;


