//claimCount

import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishDetaxReportApiService } from "../api/detax/api/ncaishDetaxReportApi.service";

import { NcaishTransactionApiService } from "../api/detax/api/ncaishTransactionApi.service";
import { NcaishTransactionBatchApiService } from "../api/detax/api/ncaishTransactionBatchApi.service";
import { CardTransactionDetailResponse } from "../api/detax/model/cardTransactionDetailResponse";

import { DetaxBatchCountApiResponse } from "../api/detax/model/detaxBatchCountApiResponse";
import { DetaxCardTransactionApprovalRequest } from "../api/detax/model/detaxCardTransactionApprovalRequest";
import { TransactionApprovalStatusWiseCountApiResponse } from "../api/detax/model/transactionApprovalStatusWiseCountApiResponse";
import { TransactionHistoryResponse } from "../api/detax/model/transactionHistoryResponse";
import { NcaishRewardsApiService } from "../api/detax/api/ncaishRewards.service";
import {
  RewardsApiResponse,
  RewardsConfigApiResponse,
  RewardsConfigRes,
} from "../api/detax/model/rewardsApiResponse";
import { CreateRewardApiRequest } from "../api/detax/model/rewardsApiRequest";

export namespace RewardsService {
  export const getRewards = async () => {
    const result = await NcaishRewardsApiService.getRewards();
    return remoteData.map(result, (response: RewardsApiResponse) => response);
  };
  export const getRewardLogo = async () => {
    const result = await NcaishRewardsApiService.getRewardLogo();
    return remoteData.map(
      result,
      (response: RewardsConfigApiResponse) => response
    );
  };

  export const createRewards = async (body: CreateRewardApiRequest) => {
    const result = await NcaishRewardsApiService.createRewards(body);
    return remoteData.map(result, (response: RewardsApiResponse) => response);
  };
  export const deleteRewards = async (reward?: string) => {
    const result = await NcaishRewardsApiService.deleteRewards(reward);
    return remoteData.map(result, (response: RewardsApiResponse) => response);
  };
  export const updateRewards = async (body: CreateRewardApiRequest) => {
    const result = await NcaishRewardsApiService.updateRewards(body);
    return remoteData.map(result, (response: RewardsApiResponse) => response);
  };
}
