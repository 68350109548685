import classNames from "classnames";
import React from "react";

import "./card-count.scss";
import "./heading.scss";

const StatusText: React.FC<{
  text?: string;
  className?: string;
}> = ({ text = "", className = "" }) => {
  return (
    <span className={classNames("status-dot-card", className)}>
      <span className="status-dot p-2 rounded-1 position-relative" style={{ right: '0' }}>{text} </span>
    </span>
  );
};

export default StatusText;
