import React, { useState } from "react";
import "./search-box.scss";

type TSearchBox = {
  onChange: (searchText: string) => void;
  search: string;
  setSearch: (val: string) => void;

  placeHolder: string;
};

const SearchBox: React.FC<TSearchBox> = (props) => {
  // const [search, setSearch] = useState<string>("");

  // const handleOnSearch = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   props.onChange(search);
  // };

  return (
    <div className="mt-2" id="search-box">
      <input
        value={props.search}
        onChange={(e) => props.setSearch(e.currentTarget.value)}
        type="search"
        placeholder={props.placeHolder}
      />
      {/* <div className="btn-container">
        <button
          type="button"
          className="btn-gradiant-blue btn-lg text-white fs-small btn btn-primary"
          // onClick={(e) => handleOnSearch(e)}
        >
          Search
        </button>
      </div> */}
    </div>
  );
};

export default SearchBox;
