/**
 * Ncaish Auth API
 * Apis to manage Login,Logout,Change password,Forgot password,Remote access token verification,Application clients,Roles ,Permissions,etc
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { ChangePasswordRequest } from "../model/changePasswordRequest";
import { ResetPasswordApiRequest } from "../model/resetPasswordApiRequest";
import { ResetPasswordApiResponse } from "../model/resetPasswordApiResponse";
import { SendForgotPasswordOtpRequest } from "../model/sendForgotPasswordOtpRequest";
import { SendForgotPasswordOtpResponse } from "../model/sendForgotPasswordOtpResponse";
import { SwitchAccountApiRequest } from "../model/switchAccountApiRequest";
import { SwitchAccountTokenResponse } from "../model/switchAccountTokenResponse";
import { ValidateSwitchAccountTokenRequest } from "../model/validateSwitchAccountTokenRequest";
import { VerifyOtpApiRequest } from "../model/verifyOtpApiRequest";
import { VerifyOtpApiResponse } from "../model/verifyOtpApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserAccountAuthApiService {
  /**
   *
   * @param body -
   */
  export const changePassword = async (
    body: ChangePasswordRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ResetPasswordApiResponse>> => {
    const path = `/oauth/secure/change/password`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   */
  export const resetPassword = async (
    body: ResetPasswordApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ResetPasswordApiResponse>> => {
    const path = `/oauth/set/password`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   */
  export const revoke = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, string>> => {
    const path = `/oauth/logout`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   */
  export const sendOtp = async (
    body: SendForgotPasswordOtpRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, SendForgotPasswordOtpResponse>> => {
    const path = `/oauth/forgot/password/sendOtp`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   */
  export const switchAccount = async (
    body: ValidateSwitchAccountTokenRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, any>> => {
    const path = `/oauth/account/switch/token/validate`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   */
  export const switchAccountToken = async (
    body: SwitchAccountApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, SwitchAccountTokenResponse>> => {
    const path = `/oauth/secure/account/switch/token`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param body -
   */
  export const verifyForgotPasswordOtp = async (
    body: VerifyOtpApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, VerifyOtpApiResponse>> => {
    const path = `/oauth/forgot/password/verify/otp`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
