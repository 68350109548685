export enum ClaimApproverTabKind {
  APPROVERS = "APPROVERS",
  EMPLOYEES = "EMPLOYEES",
}

export type MTab = { tabLabel: string; tabValue: ClaimApproverTabKind };

export const tabData: MTab[] = [
  {
    tabLabel: "Approvers",
    tabValue: ClaimApproverTabKind.APPROVERS,
  },
  {
    tabLabel: "Employees",
    tabValue: ClaimApproverTabKind.EMPLOYEES,
  },
];
