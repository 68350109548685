/**
 * Ncaish Corporate apis
 * Api to manage corporate onboarding ,application and employee management
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "./api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "./errors";

//@ts-ignore
//import * as models from '../model/models';
import { CorpApplicationApiResponse } from "../model/corpApplicationApiResponse";
import { CorpServicesApiResponse } from "../model/corpServicesApiResponse";
import { CorpUpdateServiceApiResponse } from "../model/corpUpdateServiceApiResponse";
import { GetContractReceipientApiResponse } from "../model/getContractReceipientApiResponse";
import { SendBrResponse } from "../model/sendBrResponse";
import { SignupRequest } from "../model/signupRequest";
import { SignupResponse } from "../model/signupResponse";
import { SignupVerificationRequest } from "../model/signupVerificationRequest";
import { UpdateCorporateReceipientApiRequest } from "../model/updateCorporateReceipientApiRequest";
import { UpdateServiceApiRequest } from "../model/updateServiceApiRequest";
import { UpdateSignatoriesApiResponse } from "../model/updateSignatoriesApiResponse";
import { UserVerificationResponse } from "../model/userVerificationResponse";
import { ValidateApplicationApiResponse } from "../model/validateApplicationApiResponse";
import { ValidateCinRequest } from "../model/validateCinRequest";
import { CreateCorporateOnboardingRequest } from "../model/createCorporateOnboardingRequest";
import { CreateCorporateOnboardingResponse } from "../model/createCorporateOnboardingResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishCorporateOnboardingApiService {
  /**
   * Api to get already linked receipient(signatories and directors) for application
   */
  export const getContractReceipient = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetContractReceipientApiResponse>> => {
    const path = `/corporates/onboarding/contractRec`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param body -
   */
  export const login = async (
    body: SignupRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, SignupResponse>> => {
    const path = `/corporates/onboarding/register`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to fetch my application details
   */
  export const myApplication = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpApplicationApiResponse>> => {
    const path = `/corporates/onboarding/review`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to send BR to linked receipients through docusign
   */
  export const sendBr = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, SendBrResponse>> => {
    const path = `/corporates/onboarding/sendBr`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to link services with application
   * @param body -
   */
  export const services = async (
    body: UpdateServiceApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpUpdateServiceApiResponse>> => {
    const path = `/corporates/onboarding/services`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to get linked services with application
   */
  export const services1 = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CorpServicesApiResponse>> => {
    const path = `/corporates/onboarding/services`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to link receipient(signatories and directors) for application
   * @param body -
   */
  export const updateSignatories = async (
    body: UpdateCorporateReceipientApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UpdateSignatoriesApiResponse>> => {
    const path = `/corporates/onboarding/contractRec`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   * Api to validate company cin
   * @param request -
   */
  export const validateCin = async (
    request: ValidateCinRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ValidateApplicationApiResponse>> => {
    const path = `/corporates/onboarding/validate`.replace(/^\//, "");
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    // const queryParams = allQueryParams
    //   .map((param) => `${param.key}=${param.value}`)
    //   .join("&");

    const apiUrl = path.concat(`?cin=${request.cin}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
  /**
   *
   * @param body -
   */
  export const verifySignup = async (
    body: SignupVerificationRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, UserVerificationResponse>> => {
    const path = `/corporates/onboarding/register/verify`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const createCorporateOnboarding = async (
    body: FormData,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, CreateCorporateOnboardingResponse>> => {
    const path = `/corporates/onboarding/create`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "post",
      data: body,
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data; boundary=abcd",
      },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new NcaishCorporateOnboardingApiService()
