import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { DetaxDispatch } from "../../../../state/slice/detax.slice";

import { TabTypeKind } from "./create-batch-tab";

const CreateBatchButton = () => {
  return (
    <div className="d-flex align-items-center">
      <Button
        className="btn-gradiant-blue btn-lg text-white fs-small"
        onClick={() => {
          DetaxDispatch.clearSelectedEligibleUsers();
          DetaxDispatch.setTabType(TabTypeKind.SelectUser);
          DetaxDispatch.showPage("ELIGIBLE_USERS");
        }}
      >
        Create Batch
      </Button>
    </div>
  );
};

export default CreateBatchButton;
