import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { pipe } from "fp-ts/lib/function";
import { getOrElse, map, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, SmallScreen } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import PageLoader from "../../page-loader";
import { OnboardingService } from "../../services/onboarding.service";
import { selectAppToken } from "../../state/slice/token.slice";
import { TCorporateInfo } from "../../types/corporate-info.type";
import { TCorporateService } from "../../types/corporate-service.type";
import { fromPublic } from "../../utils/common.utils";
import { getCorpInfo } from "../../utils/company.utils";
import { setErrorMessages } from "../../utils/error.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import { selectDraftId } from "../app/app.slice";
import Loader from "../common/loader/loader";
import CompanyNameCard from "../company/company-name-card";
import DirectorListCard from "../company/director-list-card";
import SelectServiceCard from "../company/select-service-card";
import SignatoryCard from "../company/signatory-card";
import Stepper from "../stepper/stepper";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import BrandLogoHeader from "../brandlogo-header/brandlogo-header";
import BrandLogoHeaderNew from "../brandlogo-header/brandlogo-header";

const Image11 = fromPublic("/images/services.svg");

export const ReviewApplicationPageNew: React.FC = () => {
  const draftId = useAppSelector(selectDraftId);
  const appToken = useAppSelector(selectAppToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [corpInfo, setCorpInfo] = useState<Option<TCorporateInfo>>(none);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Review Application" });
    const run = async () => {
      const result = await OnboardingService.getReview();
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          setCorpInfo(result.value.data);
        } else {
          setError(unWrapOption(result.value.message, () => "Error found"));
        }
        setLoading(false);
      } else if (isFailure(result)) {
        setError("Review not found");
        setLoading(false);
      }
    };
    run();
  }, []);

  const handleSubmit = async () => {
    if (isLoading) return;

    mixpanel.track("Button click", { name: "Send Agreement" });

    setError("");
    setIsLoading(true);

    const result = await OnboardingService.sendBrApplication(draftId);
    if (isSuccess(result)) {
      setIsLoading(false);
      if (unWrapOption(result.value.successful, () => false) === true) {
        const path = pipe(
          result.value.nextAction,
          getOrElse(() => "/")
        );
        // navigate(path);
        navigate(RoutePaths.ApplicationSubmittedNew);
      } else {
        setError(unWrapOption(result.value.message, () => ""));
      }
    } else if (isFailure(result)) {
      setIsLoading(false);
      setError(setErrorMessages(result.error));
    }
  };

  const corpProp = getCorpInfo(corpInfo);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="container p-0">
        <BrandLogoHeaderNew />
        {/* <Stepper /> */}
        <div className="row">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block col-md-6 col-lg-7 col-xl-8 col-xxl-8">
            <div className="mt-4 mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <img
                className="img-fluid"
                width="350"
                height="350"
                src={Image11}
                alt="Mobile"
              />
            </div>
            <div className="mt-2 textDetail">
              <Heading
                className="mx-5 mb-3 mt-85"
                text="Review your application"
                variant="small"
              />
              <p className="mx-5 fw-bolder text-secondary">
                We have reached the final phase of onboarding! Take sometime to
                review your application.
              </p>
            </div>
          </div>
          <div className="mt-4 d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block col-md-6 col-lg-5 col-xl-4 col-xxl-4">
            <div className="container p-0 m-0">
              <div className="p-3 mb-5 border-0 shadow card bg-grey rounded-15">
                <BackButton
                  text="Review Application"
                  path={RoutePaths.SelectServicesNew}
                />
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}
                <div
                  className="custom-scroll"
                  style={{ height: "55vh", overflowY: "auto" }}
                >
                  <CompanyNameCard name={corpProp.name} cin={corpProp.cin} />
                  <SelectServiceCard services={corpProp.services} />
                  {corpProp.directors.length ? (
                    <DirectorListCard directors={corpProp.directors} />
                  ) : null}
                  {corpProp.receipients.length ? (
                    <SignatoryCard receipients={corpProp.receipients} />
                  ) : null}
                </div>

                <div className="mt-2 ml-auto mr-auto text-center ">
                  <Button type="button" onClick={handleSubmit}>
                    {isLoading ? <Loader color="white" /> : <>Send Agreement</>}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For Mobile */}
      <div className="col-md-12">
        <SmallScreen>
          <div className="container p-0 m-0">
            <div className="px-3 pt-3 border-0 card bg-grey">
              <BackButton
                text="Review Application"
                path={RoutePaths.SelectServicesNew}
              />
              {error && (
                <div
                  className="text-center alert alert-danger fw-bold"
                  role="alert"
                >
                  {error}
                </div>
              )}
              <div className=" d-none d-md-none d-lg-none d-xl-none d-xxl-none">
                <img
                  className="img-fluid"
                  width="791"
                  height="741"
                  src={Image11}
                  alt="BG"
                />
              </div>
              <CompanyNameCard name={corpProp.name} cin={corpProp.cin} />
              <SelectServiceCard services={corpProp.services} />
              {corpProp.directors.length ? (
                <DirectorListCard directors={corpProp.directors} />
              ) : null}
              {corpProp.receipients.length ? (
                <SignatoryCard receipients={corpProp.receipients} />
              ) : null}

              <div className="mt-4 ml-auto mr-auto text-center ">
                <Button type="button" onClick={handleSubmit}>
                  {isLoading ? <Loader color="white" /> : <>Send Agreement</>}
                </Button>
              </div>
            </div>
          </div>
        </SmallScreen>
      </div>
    </>
  );
};
