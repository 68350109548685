import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { dashboardMenus } from "../../config/dashboard-menu";
import { useAppSelector } from "../../hooks/hooks";
import { Dispatch } from "../../state/dispatch";
import { DetaxDispatch } from "../../state/slice/detax.slice";
import { TDashboardMenu } from "../../types/dashboard-menu.type";
import { isPermissionToDetaxCard } from "../../utils/auth.utils";
import { RoutePaths } from "../../utils/route.utils";
import { selectUserPermissions } from "../app/app.slice";
import "./sidebar.scss";
import { AlphaDispatch } from "../../state/slice/alpha.slice";

type TSidebarMenuProps = {
  menuItems?: TDashboardMenu[];
};

const SidebarMenu: React.FC<TSidebarMenuProps> = ({
  menuItems = dashboardMenus,
}) => {
  const userPermissions = useAppSelector(selectUserPermissions);
  const location = useLocation();
  const activeSidemenu = (path: string) => {
    let pathname = location.pathname;
    if (new RegExp(RoutePaths.EmployeesDetail + ".*", "i").test(pathname)) {
      pathname = RoutePaths.EmployeesAdd;
    }
    return pathname.includes(path);
  };

  const accessPath = (
    userPermissions: string[], // permissions user opted while onboarding
    accessPermissions: string[], // system define can access permission
    path: string
  ) => {
    const canAccess = accessPermissions.some((p) =>
      userPermissions.includes(p)
    );
    return canAccess ? path : RoutePaths.DashboardSubscribe;
  };

  // extract dahsboard menu when detaxt_card is not in permissions

  const menus = isPermissionToDetaxCard(userPermissions)
    ? menuItems
    : menuItems.filter((menu) => menu.path !== RoutePaths.DashboardHome);

  return (
    <div className="menu-list-container">
      <ul className="menu-list">
        {menus.map((menu: TDashboardMenu, index: any) => {
          return (
            <li key={index}>
              <Link
                to={accessPath(
                  userPermissions,
                  menu.accessPermissions,
                  menu.path
                )}
                className={classNames({
                  active: activeSidemenu(menu.path),
                })}
                onClick={() => {
                  if (menu.path === RoutePaths.DashboardBatch) {
                    DetaxDispatch.hideUploadTransaction();
                  }
                  AlphaDispatch.setActiveSettings(menu.label);
                }}
              >
                <img
                  src={menu.iconImagePath}
                  alt={menu.label}
                  width="24"
                  height="28"
                  className="pb-2 w-h-size"
                />
                <p> {menu.label} </p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SidebarMenu;
