import { isSuccess } from "@devexperts/remote-data-ts";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NpsService } from "../../../../services/nps.service";
import { StatusCountService } from "../../../../services/status-count.service";
import { selectDashboardRedirect } from "../../../../state/slice/dashboard.slice";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";
import CardLoader from "../common/card-loader";

//DetaxService
const NpsStatusCount: React.FC<{
  setCurTbl: (tbl: TStatusCountInfo) => void;
  curTbl: TStatusCountInfo | null;
  reload?: boolean;
  setReload: (flag: boolean) => void;
  setRemapState: (val: boolean) => void;
  remapState: boolean;
}> = ({
  setCurTbl,
  curTbl,
  reload = false,
  setReload = () => {},
  setRemapState,
  remapState,
}) => {
  const { page, card } = useSelector(selectDashboardRedirect);
  const [counts, setCounts] = useState<Array<TStatusCountInfo> | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const run = async () => {
      if (reload) {
        let status = curTbl?.status === "All" ? "" : curTbl?.status || "";
        setIsLoading(true);
        const npsStatusCount = await NpsService.getCardSubscriptionCount(
          status
        );
        setIsLoading(false);
        if (isSuccess(npsStatusCount)) {
          if (npsStatusCount.value.successful) {
            // if ((npsStatusCount.value.data || []).length > 0) {
            //   setCounts(npsStatusCount.value.data || []);
            //   if (card == "") setCurTbl((npsStatusCount.value.data || [])?.[0]);
            //   else {
            //     let cur = (npsStatusCount.value.data || []).filter(
            //       (i) => i.status === card
            //     )?.[0];
            //     if (cur) setCurTbl(cur);
            //   }
            // }
            const allCards = StatusCountService.addAllNpsCount(
              npsStatusCount.value.data || []
            );
            if (allCards.length > 0) {
              setCounts(allCards);
              if (card === "") setCurTbl(allCards?.[0]);
              else {
                let cur = allCards.filter((i) => i.status === card)?.[0];
                if (cur) setCurTbl(cur);
              }
            }
          }
        } else {
          setErr(true);
        }
      }
      setReload(false);
    };

    if (remapState) {
      const render = counts.filter((obj) => obj.status === "PRAN_REMAPPING")[0];
      console.log("Render", render);
      setCurTbl(render);
    }
    run();
  }, [reload]);

  return (
    <>
      {err ? (
        <div className="text-center">Something went wrong!</div>
      ) : (
        <>
          {isLoading ? (
            <CardLoader />
          ) : (
            <div className="card-counts my-4">
              {counts.map((item, key) => (
                <div
                  key={key}
                  onClick={() => setCurTbl(item)}
                  className="card-count"
                >
                  <WhiteCard
                    className={classNames("p-2 w-75 status-dot-card", {
                      color2: item.status === "ACCEPTANCE_PENDING",
                      color7: item.status === "ACTIVATED",
                      color6: item.status === "DEACTIVATED",
                      color8: item.status === "CANCELLED",
                      color1: item.status === "KYC_VERIFIED",
                      color10: item.status === "REGISTRATION_IN_PROGRESS",
                      color4: item.status === "BANK_VALIDATION_COMPLETED",
                      color5: item.status === "PAN_VALIDATION_COMPLETED",
                      color9: item.status === "ACCEPTED",
                      color0: item.status === "All",
                      color3: item.status === "PRAN_REMAPPING",
                      active:
                        card && card !== ""
                          ? curTbl?.status === card && card === item.status
                          : +(curTbl?.status === item.status),
                    })}
                  >
                    <div className="status-dot"></div>
                    <h2 className="text-black text-center mb-0 fw-bold">
                      {item.count}
                    </h2>
                    <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
                      {item.label}
                    </p>
                  </WhiteCard>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NpsStatusCount;
