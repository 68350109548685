import { format, parseISO } from "date-fns";
import toDate from "date-fns/toDate/index.js";
import { toError } from "fp-ts/lib/Either";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalDialog } from "react-bootstrap";
import { TransactionService } from "../../../../services/transaction.service";
import {
  isSuccess,
  RemoteData,
  RemoteInitial,
} from "@devexperts/remote-data-ts";
import { APIError } from "../../../../api/errors";
import { TransactionHistoryResponse } from "../../../../api/detax/model/transactionHistoryResponse";
import Select, { ActionMeta, MultiValue } from "react-select";
import OptionsType from "react-select";
import { CardTransactionInfo } from "../../../../api/detax/model/cardTransactionInfo";
import { EmployeeService } from "../../../../services/employee.service";
import { EmployeeInfo } from "../../../../api/employee/model/employeeInfo";
import axios, { AxiosResponse } from "axios";
import { NcaishDetaxSubscriptonApiService } from "../../../../api/detax/api/ncaishDetaxSubscriptonApi.service";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
// this popup is for select date range

const FilterPopup: React.FC<{
  actionState1: boolean;
  show: boolean;
  closeModal: () => void;
  onDownload: (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: Date | null,
    to: Date | null,
    typestr: string
  ) => void;
  onDownload1: (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: Date | null,
    to: Date | null,
    typestr: string
  ) => void;
  user: CardTransactionInfo[] | [];
}> = ({ show, closeModal, onDownload, user, actionState1, onDownload1 }) => {
  const [type, setType] = useState(false);
  const [category, setCategory] = useState(false);
  const [approval, setApproval] = useState(false);
  const [date, setDate] = useState(false);
  const [wallet, setWallet] = useState(false);
  const [filter, setFilter] = useState<string[]>([]);
  const [from, setFromDate] = useState<Date | null>(null);
  const [to, setToDate] = useState<Date | null>(null);
  const [typenew, setTypenew] = useState<string[]>([]);
  const [categorynew, setCategorynew] = useState<string[]>([]);
  const [walletnew, setWalletnew] = useState<string[]>([]);
  const [approvalnew, setApprovalnew] = useState<string[]>([]);
  const [employees, setEmployees] = useState<DetaxCardSubscriptionInfo[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option2[]>([]);
  useEffect(() => {
    const run = async () => {
      getData();
    };
    run();
    if (show === false) {
      defaultValue();
    }
  }, [show]);
  const defaultValue = () => {
    setType(false);
    setCategory(false);
    setApproval(false);
    setDate(false);
    setWallet(false);
    setSelectedOption([]);
    setEmployees([]);
    setTypenew([]);
    setCategorynew([]);
    setWalletnew([]);
    setFromDate(null);
    setToDate(null);
    setApprovalnew([]);
  };
  const getData = async () => {
    const statusString = "ACTIVATED";
    const result = await NcaishDetaxSubscriptonApiService.getSubscription(
      statusString,
      1,
      10000000
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        setEmployees(result.value.data || []);
      }
    }
  };
  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setTypenew([...typenew, value]);
    } else {
      setTypenew(typenew.filter((val) => val !== value));
    }
  };
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setCategorynew([...categorynew, value]);
    } else {
      setCategorynew(categorynew.filter((val) => val !== value));
    }
  };
  const handleWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setWalletnew([...walletnew, value]);
    } else {
      setWalletnew(walletnew.filter((val) => val !== value));
    }
  };
  const handleApprovalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setApprovalnew([...approvalnew, value]);
    } else {
      setApprovalnew(approvalnew.filter((val) => val !== value));
    }
  };
  interface Option2 {
    uid: string;
    name: string;
    value: string;
    label: string;
  }

  const handleMultiSelectChange = (
    newValue: MultiValue<Option2>,
    actionMeta: ActionMeta<Option2>
  ): void => {
    setSelectedOption(newValue as Option2[]);
  };

  const uid = selectedOption.map((obj) => obj.uid);
  var categorystr = categorynew.join();
  var approvalstr = approvalnew.join();
  var walletstr = walletnew.join();
  var uidstr = uid.join();
  var typestr = typenew.length === 2 ? "" : typenew.join();

  const option2: Option2[] = employees.map((employee) => ({
    uid: employee.uid || "",
    name: employee.name || "",
    value: employee.uid || "",
    label: employee.name || "",
  }));
  const isSubmitDisabled =
    (from !== null && to === null) || (from === null && to !== null);

  const disableCredit = typenew.includes("CREDIT");
  console.log(typestr.includes("CREDIT"));
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          closeModal();
          defaultValue();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Filter transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="pb-1">
              <h5>
                <b>Select users below:</b>
              </h5>
            </div>
            <Select
              value={selectedOption}
              onChange={handleMultiSelectChange}
              options={option2}
              isMulti
            />
            <div className="pt-3 pb-1">
              <h5>
                <b>Filter by other options below:</b>
              </h5>
            </div>
            <div>
              <div className="p-2 border-bottom pt-3">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button d-flex justify-content-between"
                    type="button"
                    onClick={() => setType(!type)}
                  >
                    <h5>
                      Transaction Type
                      <span style={{ color: "#ff0000" }}> *</span>
                    </h5>
                    <div>
                      <img src="/images/Vector.svg" />
                    </div>
                  </button>
                </h2>
                <div className="accordion-collapse collapse show">
                  <div className="accordion-body">
                    {type ? (
                      <div className="ps-5 pt-1">
                        <div className="p-1">
                          <label>
                            <input
                              value="CREDIT"
                              checked={typenew.includes("CREDIT")}
                              onChange={handleTypeChange}
                              className="form-check-input cardCheckbox me-3"
                              type="checkbox"
                            />
                            Credit
                          </label>
                        </div>
                        <div className="p-1">
                          <label>
                            <input
                              value="DEBIT"
                              checked={typenew.includes("DEBIT")}
                              onChange={handleTypeChange}
                              className="form-check-input cardCheckbox me-3"
                              type="checkbox"
                            />
                            Debit
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {!disableCredit ? (
                <div className="p-2 border-bottom">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button d-flex justify-content-between"
                      type="button"
                      onClick={() => setCategory(!category)}
                    >
                      <h5>Category</h5>
                      <div>
                        <img src="/images/Vector.svg" />
                      </div>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse show ">
                    {category ? (
                      <div className="accordion-body pt-1">
                        <div className="ps-5">
                          <div className="p-1">
                            <label>
                              <input
                                value="626a4fa4df010a2d9970f77a"
                                checked={categorynew.includes(
                                  "626a4fa4df010a2d9970f77a"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Books and Periodicals
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="62a3681da82670fc977ce686"
                                checked={categorynew.includes(
                                  "62a3681da82670fc977ce686"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Communication
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="626a4fa4df010a2d9970f76d"
                                checked={categorynew.includes(
                                  "626a4fa4df010a2d9970f76d"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Food/Provisions
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="626a4fa4df010a2d9970f76a"
                                checked={categorynew.includes(
                                  "626a4fa4df010a2d9970f76a"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Fuel & Travel
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="626a5587df010a2d99711b68"
                                checked={categorynew.includes(
                                  "626a5587df010a2d99711b68"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Gadgets
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="626a4fa4df010a2d9970f770"
                                checked={categorynew.includes(
                                  "626a4fa4df010a2d9970f770"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Health
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="626a4fa4df010a2d9970f775"
                                checked={categorynew.includes(
                                  "626a4fa4df010a2d9970f775"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Learning & Development
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="626a4fa4df010a2d9970f77d"
                                checked={categorynew.includes(
                                  "626a4fa4df010a2d9970f77d"
                                )}
                                onChange={handleCategoryChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Wellness
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="p-2 border-bottom pt-3">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button d-flex justify-content-between"
                    type="button"
                    onClick={() => setWallet(!wallet)}
                  >
                    <h5>Wallet Type</h5>
                    <div>
                      <img src="/images/Vector.svg" />
                    </div>
                  </button>
                </h2>
                <div className="accordion-collapse collapse show">
                  <div className="accordion-body">
                    {wallet ? (
                      <div className="ps-5 pt-1">
                        <div className="p-1">
                          <label>
                            <input
                              value="FBA"
                              checked={walletnew.includes("FBA")}
                              onChange={handleWalletChange}
                              className="form-check-input cardCheckbox me-3"
                              type="checkbox"
                            />
                            FBA
                          </label>
                        </div>
                        <div className="p-1">
                          <label>
                            <input
                              value="TA"
                              checked={walletnew.includes("TA")}
                              onChange={handleWalletChange}
                              className="form-check-input cardCheckbox me-3"
                              type="checkbox"
                            />
                            TA
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="p-2 border-bottom pt-3">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button d-flex justify-content-between"
                    type="button"
                    onClick={() => setDate(!date)}
                  >
                    <h5>Date</h5>
                    <div>
                      <img src="/images/Vector.svg" />
                    </div>
                  </button>
                </h2>
                <div className="accordion-collapse collapse show">
                  <div className="accordion-body">
                    {date ? (
                      <div className="pt-1">
                        <div className="row mt-3"></div>
                        <div className="row">
                          <div className="col-6">
                            <label>From</label>
                            <input
                              type="date"
                              className="form-control border-0"
                              // value={from.toLocaleDateString("en-CA")}
                              // disabled={btndisable}
                              onChange={(event) => {
                                if (!to || new Date(event.target.value) <= to) {
                                  setFromDate(new Date(event.target.value));
                                }
                              }}
                            />
                          </div>
                          <div className="col-6">
                            <label>To</label>
                            <input
                              type="date"
                              className="form-control border-0"
                              // value={to.toLocaleDateString("en-CA")}
                              // disabled={btndisable}
                              onChange={(event) => {
                                if (
                                  !from ||
                                  new Date(event.target.value) >= from
                                ) {
                                  setToDate(new Date(event.target.value));
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {!disableCredit ? (
                <div className="p-2 border-bottom pt-3">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button d-flex justify-content-between"
                      type="button"
                      onClick={() => setApproval(!approval)}
                    >
                      <h5>Approval Status</h5>
                      <div>
                        <img src="/images/Vector.svg" />
                      </div>
                    </button>
                  </h2>
                  <div className="accordion-collapse collapse show">
                    <div className="accordion-body">
                      {approval ? (
                        <div className="ps-5 pt-1">
                          <div className="p-1">
                            <label>
                              <input
                                value="BILL_UPLOAD_PENDING"
                                checked={approvalnew.includes(
                                  "BILL_UPLOAD_PENDING"
                                )}
                                onChange={handleApprovalChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Bill Upload Pending
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="APPROVAL_PENDING"
                                checked={approvalnew.includes(
                                  "APPROVAL_PENDING"
                                )}
                                onChange={handleApprovalChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Bill Submitted
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="REVIEW_PENDING"
                                checked={approvalnew.includes("REVIEW_PENDING")}
                                onChange={handleApprovalChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Under Review
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="APPROVED"
                                checked={approvalnew.includes("APPROVED")}
                                onChange={handleApprovalChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Approved
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="REJECTED"
                                checked={approvalnew.includes("REJECTED")}
                                onChange={handleApprovalChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Rejected
                            </label>
                          </div>
                          <div className="p-1">
                            <label>
                              <input
                                value="FAILED"
                                checked={approvalnew.includes("FAILED")}
                                onChange={handleApprovalChange}
                                className="form-check-input cardCheckbox me-3"
                                type="checkbox"
                              />
                              Failed
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-gradiant-blue px-3 py-2 font-14 mr-auto text-white"
            size="sm"
            type="button"
            onClick={() => {
              onDownload1(
                uidstr,
                categorystr,
                walletstr,
                approvalstr,
                from,
                to,
                typestr
              );
              setType(false);
              setCategory(false);
              setApproval(false);
              setWallet(false);
              setDate(false);
              setEmployees([]);
              setTypenew([]);
              setCategorynew([]);
              setApprovalnew([]);
              setWalletnew([]);
            }}
            disabled={actionState1 || isSubmitDisabled || typenew.length < 1}
          >
            {actionState1 && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Download CSV Report
          </Button>
          {/* ALREADY EXIST ONE  */}
          <Button
            className="btn-gradiant-blue px-3 py-2 font-14 mr-auto text-white"
            size="sm"
            type="button"
            onClick={() => {
              onDownload(
                uidstr,
                categorystr,
                walletstr,
                approvalstr,
                from,
                to,
                typestr
              );
              setType(false);
              setCategory(false);
              setApproval(false);
              setWallet(false);
              setDate(false);
              setEmployees([]);
              setTypenew([]);
              setCategorynew([]);
              setApprovalnew([]);
              setWalletnew([]);
            }}
            disabled={actionState1 || isSubmitDisabled || typenew.length < 1}
          >
            {actionState1 && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            Download PDF Report
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterPopup;
