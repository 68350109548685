import { isSuccess } from "@devexperts/remote-data-ts";
import { format, parseISO } from "date-fns";
import { pipe } from "fp-ts/lib/function";
import { fold, fromNullable, isSome, none } from "fp-ts/lib/Option";
import React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { SavingKind } from "../../../../../models/enum-convert-kind";
import { FxService } from "../../../../../services/fx.service";
import {
  selectRateTypeCurrency,
  selectSavingRequest,
  selectSavingRequestResultMessage,
  setSavingRequestData,
  updateSavingResponseInStore,
} from "../../../../../state/slice/fx.slice";
import { TCurrencyType } from "../../../../../types/fx/currency.type";
import { TFxDashboardResponse } from "../../../../../types/fx/fx-dashboard-response.type";
import { TRateTypeEnum } from "../../../../../types/fx/fx-saving-request.type";
import {
  AreSavingRequisitesReady,
  GetFromAndToCurrencyByRateType,
} from "../../../../../utils/fx.utils";
import { unWrapOption } from "../../../../../utils/option.utils";
import {
  CurrencySelectAmount,
  CurrencySelectField,
  CurrencySelectLabel,
} from "../fx-exporter/fx-exporter";

const SavingCalculator: React.FC<{ dashboard: TFxDashboardResponse }> = ({
  dashboard: { currencies, savingCalData, prices },
}) => {
  const {
    fromCurrency,
    toCurrency,
    amount,
    rateType,
    date,
    savingAmount,
    dayRange,
    bankCredited,
  } = useAppSelector(selectSavingRequest);
  const savingMessage = useAppSelector(selectSavingRequestResultMessage);
  const rateTypeCurrency = useAppSelector(selectRateTypeCurrency);

  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [potentialSaving, setPotentialSaving] = useState(false);

  const updateDataToStore = async (
    kind: SavingKind,
    value: number | string
  ) => {
    if (kind === SavingKind.RATE_TYPE) {
      dispatch(
        setSavingRequestData({
          rateType: value.toString(),
          fromCurrency,
          toCurrency,
          amount,
          savingAmount: none,
          date,
          dayRange: none,
          bankCredited,
        })
      );
    } else if (kind === SavingKind.FROM) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency: value.toString(),
          toCurrency,
          amount,
          savingAmount,
          date,
          dayRange,
          bankCredited,
        })
      );
    } else if (kind === SavingKind.TO) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency,
          toCurrency: value.toString(),
          amount,
          savingAmount,
          date,
          dayRange,
          bankCredited,
        })
      );
    } else if (kind === SavingKind.AMOUNT) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency,
          toCurrency,
          amount: fromNullable(Number(value)),
          savingAmount,
          date,
          dayRange,
          bankCredited,
        })
      );
    } else if (kind === SavingKind.SAVING_AMOUNT) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency,
          toCurrency,
          amount,
          savingAmount: fromNullable(Number(value)),
          date,
          dayRange,
          bankCredited,
        })
      );
    } else if (kind === SavingKind.DATE) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency,
          toCurrency,
          amount,
          savingAmount,
          date: fromNullable(String(value)),
          dayRange,
          bankCredited,
        })
      );
    } else if (kind === SavingKind.DAY_RANGE) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency,
          toCurrency,
          amount,
          savingAmount,
          date,
          dayRange: fromNullable(String(value)),
          bankCredited,
        })
      );
    } else if (kind === SavingKind.BANK_CREDITED) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency,
          toCurrency,
          amount,
          savingAmount,
          date,
          dayRange,
          bankCredited: fromNullable(Number(value)),
        })
      );
    }
  };

  const handleCalculateSaving = async () => {
    if (AreSavingRequisitesReady()) {
      setLoader(true);
      const request = {
        rateType: rateType as TRateTypeEnum,
        fromCurrency: fromCurrency as TCurrencyType,
        toCurrency: toCurrency as TCurrencyType,
        amount: unWrapOption(amount, () => 0),
        date: pipe(
          date,
          fold(
            () => format(new Date(), "dd-MM-yyyy"),
            (date) => format(parseISO(date), "dd-MM-yyyy")
          )
        ),
        bankCredited: unWrapOption(bankCredited, () => 0),
      };

      const result = await FxService.calculateSaving(request);

      if (isSuccess(result)) {
        setLoader(false);
        if (unWrapOption(result.value.successful, () => false) === true) {
          setPotentialSaving((preState) => (preState = true));
          dispatch(
            updateSavingResponseInStore({
              savingAmount: result.value.amount,
              dayRange: result.value.dayRange,
              message: none,
            })
          );
        } else {
          dispatch(
            updateSavingResponseInStore({
              savingAmount: result.value.amount,
              dayRange: result.value.dayRange,
              message: result.value.message,
            })
          );
        }
      } else {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    const rateTypeCurrency = GetFromAndToCurrencyByRateType(
      savingCalData,
      rateType
    );
    if (rateTypeCurrency) {
      dispatch(
        setSavingRequestData({
          rateType,
          fromCurrency: rateTypeCurrency.from[0].code,
          toCurrency: rateTypeCurrency.to[0].code,
          amount,
          savingAmount,
          date,
          dayRange,
          bankCredited,
        })
      );
    }
  }, [rateType]);

  return (
    <>
      <div className="card py-2 ps-3 pe-3 rounded-10 bg-white border-0 shadow-sm h-100">
        <div className="row justify-content-center justify-content-between">
          <div className="col-md-12 col-lg-6 col-xl-6">
            <div className="card-title h4">FX Saving Calculator</div>
          </div>
          <div className="btn-group col-12 col-md-12 col-lg-6 col-xl-6">
            <button
              type="button"
              className={
                rateType === "EXPORTER"
                  ? "btn btn-outline-primary primary-bg active"
                  : "btn btn-outline-primary"
              }
              onClick={() =>
                updateDataToStore(SavingKind.RATE_TYPE, "EXPORTER")
              }
            >
              {" "}
              Exporter Rate
            </button>
            <button
              type="button"
              className={
                rateType === "IMPORTER"
                  ? "btn btn-outline-primary primary-bg active"
                  : "btn btn-outline-primary"
              }
              onClick={() =>
                updateDataToStore(SavingKind.RATE_TYPE, "IMPORTER")
              }
            >
              Importer Rate
            </button>
          </div>
        </div>

        <div className="row gx-2 align-items-end justify-content-between">
          <div className="col-md-12 col-lg-12 col-xl">
            <CurrencySelectLabel label="From" />
            <CurrencySelectField
              currencies={rateTypeCurrency?.from || []}
              selectedCurrency={fromCurrency}
              onSelect={(value) => updateDataToStore(SavingKind.FROM, value)}
              className="border-0 form-select form-select-sm "
            />
          </div>

          <div className="col-md-12 col-lg-12 col-xl">
            <CurrencySelectLabel label="To" />
            <CurrencySelectField
              currencies={rateTypeCurrency?.to || []}
              selectedCurrency={toCurrency}
              onSelect={(value) => updateDataToStore(SavingKind.TO, value)}
              className="border-0 form-select form-select-sm "
            />
          </div>
          <div className="col-lg-12 mt-sm-3 mt-md-3" style={{ maxWidth: 170 }}>
            <div className="justify-content-center">
              <input
                type="date"
                className="form-control-sm border-0"
                value={pipe(
                  date,
                  fold(
                    () => format(new Date(), "dd-mm-yyyy"),
                    (date) => date //format(new Date(date), "dd-mm-yyyy")
                  )
                )}
                onChange={(e) =>
                  updateDataToStore(SavingKind.DATE, e.target.value)
                }
                max={pipe(format(new Date(), "dd-mm-yyyy"))}
                // min={
                //   savingCalData.lsDate
                //     ? format(parseISO(savingCalData.lsDate), "dd-mm-yyyy")
                //     : undefined
                // }
              />
            </div>
          </div>
        </div>
        <div className="row gx-2 my-2 pt-2 align-items-end">
          <div className="col-12 col-md col-lg-4 col-xl">
            <CurrencySelectLabel label={`${fromCurrency} Paid`} />
            <CurrencySelectAmount
              amount={amount}
              onChange={(value) => updateDataToStore(SavingKind.AMOUNT, value)}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-4 col-xl">
            <CurrencySelectLabel label={`${toCurrency} Bought`} />
            <CurrencySelectAmount
              amount={bankCredited}
              onChange={(value) =>
                updateDataToStore(SavingKind.BANK_CREDITED, value)
              }
            />
          </div>
          <div
            className="col-3 col-md-12 mt-sm-3 mt-md-3 col-lg-4 text-start"
            style={{ maxWidth: 170 }}
          >
            <button
              className="btn col-12 primary-bg py-2 text-white border-0"
              type="button"
              onClick={handleCalculateSaving}
            >
              {loader ? (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
        <div className="row mt-2 mb-2 align-items-end">
          <div className="col col-md">
            {/* Day's Range |{" "} */}
            {unWrapOption(dayRange, () => "") || savingCalData.dayRange
              ? `Day's Range | ${
                  unWrapOption(dayRange, () => "") || savingCalData.dayRange
                }`
              : ""}
          </div>

          <div className="col-4 col-md-5 text-center"></div>
        </div>
        <div className="row">
          <div className="col-12 text-center mx-auto">
            {isSome(savingMessage) ? (
              <h5 className="p-1 text-danger_i">
                <span className="fw-bold">{savingMessage.value}</span>
              </h5>
            ) : (
              <>
                {unWrapOption(savingAmount, () => 0) ? (
                  <h5 className="p-1 text-success">
                    <span className="fw-bold">
                      {pipe(
                        savingAmount,
                        fold(
                          () => "",
                          (v) =>
                            v < 0
                              ? "You have already got a best deal."
                              : `Potential Saving INR ${v}`
                        )
                      )}
                    </span>
                  </h5>
                ) : (
                  <h5 className="p-1 text-success">
                    <span className="fw-bold">
                      {pipe(
                        savingAmount,
                        fold(
                          () => "",
                          (v) => `You have already got a best deal.`
                        )
                      )}
                    </span>
                  </h5>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SavingCalculator);
