import React from "react";
import { ClaimInfo } from "../../../../api/detax/model/claimInfo";
import "./claims.scss";
import { titleCase } from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";

const getStatus = (status: ClaimInfo.StatusEnum): string => {
  if (status === ClaimInfo.StatusEnum.APPROVED) return `Claim is approved.`;
  if (status === ClaimInfo.StatusEnum.CANCELLED) return `Claim is cancelled.`;
  if (status === ClaimInfo.StatusEnum.INPROCESS)
    return `Claim reimbursement is in progress.`;
  if (status === ClaimInfo.StatusEnum.REIMBURSED) return `Claim is reimbursed.`;
  if (status === ClaimInfo.StatusEnum.REJECTED) return `Claim is rejected.`;
  if (status === ClaimInfo.StatusEnum.REVIEWPENDING)
    return `Claim is being reviewed by admin.`;
  if (status === ClaimInfo.StatusEnum.APPROVER_PENDING)
    return `Claim is being reviewed by approver.`;

  return `Claim has been ${titleCase(status || "").replaceAll("_", " ")}.`;
};

const ApprovedCard: React.FC<{ claim: ClaimInfo }> = ({ claim }) => {
  const statusText =
    claim.status === ClaimInfo.StatusEnum.APPROVED
      ? "Claim has been Approved"
      : `Claim has been ${titleCase(claim.status || "")}.`;
  return (
    <div
      className={
        claim.status === ClaimInfo.StatusEnum.REJECTED
          ? `card text-dark mt-3 reject-claim-box`
          : `card text-dark mt-3 success-claim-box`
      }
    >
      <div className="card-body text-color">
        <p>{getStatus(claim.status || "PENDING")}</p>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Approver Name</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.approver?.name || ""}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Approved Amount </p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p>
              {claim.appAmount || claim.appAmount === 0
                ? currencyFormat(claim.appAmount)
                : "N/A"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Approved At</p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.approvedAt || "-"}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            {" "}
            <p> Remarks </p>
          </div>
          <div className="col-12 col-md-6">
            {" "}
            <p> {claim.approverRemarks || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedCard;
