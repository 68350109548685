import { isFailure, isSuccess } from "@devexperts/remote-data-ts";

import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button, Heading, MediumScreen, SmallScreen } from "..";
import { useAppDispatch } from "../../hooks/hooks";
import { useLoginWithPassword } from "../../hooks/use-login-with-password";
import { Dispatch } from "../../state/dispatch";
import { setToken } from "../../state/slice/token.slice";
import { setLoginUser } from "../../state/slice/user.slice";
import { isPermissionToDetaxCard } from "../../utils/auth.utils";
import { fromPublic } from "../../utils/common.utils";
import { setErrorMessagesLogin } from "../../utils/error.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { unWrapOption } from "../../utils/option.utils";
import { RoutePaths } from "../../utils/route.utils";
import { setIsAuthenticated } from "../app/app.slice";
import { BrandHeader } from "../brandlogo-header/brandlogo-header";
import FormikComponent from "../common/formik/formik-component/formik-component";
import SpinnerComponent from "../common/loader/spinner";
import { LoginWithUsernameAndPassword } from "../form/login";

//const Image3 = fromPublic("/images/bg1-new.svg");
const Image3 = fromPublic("/images/bg1-new2.svg");

interface IValues {
  username: string;
  password: string;
}

const formInitialSchema: IValues = {
  username: "",
  password: "",
};

const validationSchema = yup.object().shape({
  username: yup.string().required("Enter your mobile number or email"),

  password: yup.string().required("Enter your password"),
});

const LoginUsingPasswordCore: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");

  const { mutateAsync: loginWithPassword, isLoading } = useLoginWithPassword();

  useEffect(() => {
    mixpanel.track("Page load", { name: "Login using password" });
  }, []);

  useEffect(() => {}, []);

  const handleFormSubmit = async (values: IValues) => {
    setError("");

    const result = await loginWithPassword({
      username: values.username,
      password: values.password,
    });

    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false)) {
        const roles = unWrapOption(result.value.roles, () => [""]);
        Dispatch.App.setAuthInfo(result.value); // set response in redux store
        Dispatch.App.setLoginId(result.value.id);
        Dispatch.App.setLoginUserCid(result.value.cid);
        Dispatch.App.setFullName(result.value.full_name);
        Dispatch.App.setPasswordChanged(result.value.initPassChanged);
        dispatch(setLoginUser({ mobile: values.username }));
        dispatch(
          setToken({
            accessToken: unWrapOption(result.value.access_token, () => ""),
            refreshToken: unWrapOption(result.value.refresh_token, () => ""),
            tokenExpiry: unWrapOption(result.value.expires_in, () => 0),
            scope: roles[0], // store roles into scope to check on app load
          })
        );
        dispatch(setIsAuthenticated(true));
        Dispatch.Token.setAppToken(result.value.appToken);

        if (roles[0] === "ROLE_CORPORATE_APPLICANT") {
          // send to ApplicationSubmitted when applicant

          navigate(RoutePaths.ApplicationSubmitted);
        } else if (
          roles[0] === "ROLE_CORPORATE_ADMIN" ||
          roles[0] === "ROLE_CORPORATE_ADMIN_MAKER"
        ) {
          // check if password must be changed
          const isPasswordChangeNotRequired = unWrapOption(
            result.value.initPassChanged,
            () => true
          ); // when return false then should go to password change
          if (isPasswordChangeNotRequired) {
            // go to dashbaord if DETAX_CARD permission added else send to FXOnline

            navigate(
              isPermissionToDetaxCard(result.value.permissions)
                ? RoutePaths.DashboardHome
                : RoutePaths.DashboardFxOnline
            );
          } else {
            // go to password change screen

            navigate(RoutePaths.MustChangePassword);
          }
        } else {
          console.log("Incorrect role : ", roles[0]);
        }
      } else {
        toast.error("Login Failed");
        setError(unWrapOption(result.value.message, () => ""));
      }
    } else if (isFailure(result)) {
      let errorMessage = "Invalid login credentials";

      errorMessage = setErrorMessagesLogin(result.error);

      setError(
        errorMessage.toLowerCase() === "bad credentials"
          ? "Invalid login credentials"
          : errorMessage
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Ncaish</title>
      </Helmet>
      <FormikComponent
        validation={validationSchema}
        initialValue={formInitialSchema}
        handleSubmit={handleFormSubmit}
      >
        <div className="container p-0">
          <BrandHeader />
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
            <div className="row align-items-center vh-100">
              <div className="col-lg-8 col-md-6 ">
                <MediumScreen>
                  <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                    <img
                      className="img-fluid"
                      width="791"
                      height="900"
                      src={Image3}
                      alt="Mobile"
                    />
                  </div>
                  <div className="mt-5 textDetail">
                    {/* <Heading
                      className="mb-3 mt-85"
                      text="Login"
                      variant="small"
                    />

                    <p className="fw-bolder text-secondary">
                      Being a Fintech we understand the importance of Multi
                      Factor authentication prescribed by Reserve Bank of India
                      !
                    </p> */}
                  </div>
                </MediumScreen>
              </div>
              <div className="col-lg-4 col-md-6">
                <MediumScreen>
                  <div className="container p-0 m-0">
                    <div className="p-3 border-0 shadow card bg-grey rounded-15">
                      {error && (
                        <div
                          className="text-center alert alert-danger fw-bold"
                          role="alert"
                        >
                          {error}
                        </div>
                      )}
                      <Heading
                        className="text-center mt-50"
                        text="Log In"
                        variant="large"
                      />
                      <p className="mt-4 mb-0 mb-4 text-center text-black d-none d-sm-none d-md-block fw-bold">
                        Please enter your credentials
                      </p>
                      <div className="text-center d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
                        <LoginWithUsernameAndPassword />
                        <div className="d-flex justify-content-end">
                          <Link
                            className="mt-2 ncash_nav-link"
                            to={RoutePaths.ForgotPassword}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className="mt-4 mb-3 ml-auto mr-auto text-center">
                          <Button type="submit">
                            {isLoading ? <SpinnerComponent /> : <>Continue</>}
                          </Button>
                        </div>
                      </div>

                      <p className="mt-1 mb-3 text-center fw-bolder text-secondary">
                        {/* Do not have an account?
                        <Link
                          className="ncash_nav-link"
                          to={RoutePaths.ValidateCompany}
                        >
                          Sign Up
                        </Link>
                        now */}
                      </p>
                    </div>
                  </div>
                </MediumScreen>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <SmallScreen>
              <div className="container p-0 m-0">
                <div className="p-3 border-0 shadow card bg-grey">
                  {error && (
                    <div
                      className="text-center alert alert-danger fw-bold"
                      role="alert"
                    >
                      {error}
                    </div>
                  )}
                  <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                    <img
                      className="img-fluid"
                      width="791"
                      height="741"
                      src={Image3}
                      alt="BG"
                    />
                  </div>

                  <h1 className="mt-3 mb-4 text-center fw-bold heading fs-5">
                    Log In
                  </h1>
                  <p className="mb-0 text-center text-black d-md-none d-lg-none d-xl-none d-xxl-none fw-bold fs-small">
                    Please enter your credentials
                  </p>

                  <div className="p-3 mt-4 mb-4 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                    <LoginWithUsernameAndPassword />
                    <div className="d-flex justify-content-end">
                      <Link
                        className="mt-2 ncash_nav-link"
                        to={RoutePaths.ForgotPassword}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="mb-5 ml-auto mr-auto text-center">
                    <Button type="submit">
                      {isLoading ? <SpinnerComponent /> : <>Continue</>}
                    </Button>
                  </div>

                  <p className="mt-1 text-center fw-bolder text-secondary ">
                    {/* Do not have an account?
                    <Link className="ncash_nav-link" to={RoutePaths.SignUpNew}>
                      Sign Up
                    </Link>
                    now */}
                  </p>
                </div>
              </div>
            </SmallScreen>
          </div>
        </div>
      </FormikComponent>
    </>
  );
};

const LoginUsingPasswordPage: React.FC = () => {
  const authToken = MyStorage.AuthToken.LoadData();

  if (authToken) {
    return <Navigate to={RoutePaths.DashboardHome} replace />;
  }
  return <LoginUsingPasswordCore />;
};

export default LoginUsingPasswordPage;
