import React from "react";

const SpinnerComponent = () => {
  return (
    <span
      className="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
  );
};

export default SpinnerComponent;
