import { Option } from "fp-ts/lib/Option";
import { store } from "../store";

import { setToken, tokenPayload, setAppToken } from "../slice/token.slice";

export const TokenDispatch = {
  setToken: (payload: tokenPayload) => store.dispatch(setToken(payload)),
  setAppToken: (payload: Option<string>) =>
    store.dispatch(setAppToken(payload)),
};
