import { remoteData } from "@devexperts/remote-data-ts";
import { NcaishCardApiService } from "../api/detax/api/ncaishCardApi.service";
import { NcaishDetaxSubscriptonApiService } from "../api/detax/api/ncaishDetaxSubscriptonApi.service";
import { NcaishTransactionApiService } from "../api/detax/api/ncaishTransactionApi.service";
import { CardTransactionsMonthlyCountResponse } from "../api/detax/model/cardTransactionsMonthlyCountResponse";
import { DetaxCardSubscriptionApiRequest } from "../api/detax/model/detaxCardSubscriptionApiRequest";
import { DetaxCardSubscriptionCountApiResponse } from "../api/detax/model/detaxCardSubscriptionCountApiResponse";
import { DetaxCardSubscriptionResponse } from "../api/detax/model/detaxCardSubscriptionResponse";
import { EmployeeListResponse } from "../api/detax/model/employeeListResponse";
import { FbaAllowanceResponse } from "../api/detax/model/fbaAllowanceResponse";
import { GetCardsApiResponse } from "../api/detax/model/getCardsApiResponse";
import { MembershipBatchCountApiRequest } from "../api/detax/model/membershipBatchCountApiRequest";
import { MembershipBatchTableResponse } from "../api/detax/model/membershipBatchTableResponse";
import { MembershipDetaxCountApiResponse } from "../api/detax/model/membershipDetaxCountApiResponse";
import { UpdateCardStatusApiRequest } from "../api/detax/model/updateCardStatusApiRequest";
import { NcaishCorporateEmployeeApiService } from "../api/employee/api/ncaishCorporateEmployeeApi.service";
import { unWrapOption } from "../utils/option.utils";

export namespace MembershipService {
  export const getMembershipDetaxplusCount = async (status?: string) => {
    const statusString = status === "All" ? "" : status;
    const result =
      await NcaishDetaxSubscriptonApiService.getMembershipDetaxplusCount(
        statusString
      );

    console.log("Membership detax + employees api response ::: ", result);
    return remoteData.map(
      result,
      (response: MembershipDetaxCountApiResponse) => response
    );
  };

  export const getMembershipDetaxCount = async (status?: string) => {
    const statusString = status === "All" ? "" : status;
    const result =
      await NcaishDetaxSubscriptonApiService.getMembershipDetaxCount(
        statusString
      );
    console.log("detax employee of Membership invitation table", result);
    return remoteData.map(
      result,
      (response: MembershipDetaxCountApiResponse) => response
    );
  };
  export const sendSubscriptionRequest = async (
    body: MembershipBatchCountApiRequest
  ) => {
    console.log("body", body);
    const result = await NcaishDetaxSubscriptonApiService.sendInvitationRequest(
      body
    );
    return remoteData.map(
      result,
      (response: DetaxCardSubscriptionResponse) => response
    );
  };

  // FOR Membership count status count
  export const getMembershipCount = async (status?: string) => {
    const statusString = status === "All" ? "" : status;
    const result =
      await NcaishDetaxSubscriptonApiService.getMembershipBatchCount(
        statusString
      );
    return remoteData.map(
      result,
      (response: MembershipDetaxCountApiResponse) => response
    );
  };

  // FOR BATCH TABLE
  export const getMembershipBatchTable = async (status?: string) => {
    const statusString = status === "All" ? "All" : status;
    const result =
      await NcaishDetaxSubscriptonApiService.getMembershipBatchTable(
        statusString
      );
    console.log("Membership batch table api respone ::: ", result);
    return remoteData.map(
      result,
      (response: MembershipBatchTableResponse) => response
    );
  };

  // Batch details
  export const getById = async (id: string) => {
    const result = await NcaishCorporateEmployeeApiService.getByIdCode(id);
    return remoteData.map(
      result,
      (
        response: MembershipBatchTableResponse
      ): MembershipBatchTableResponse => {
        return {
          successful: response.successful,
          message: response.message,
          data: response.data,
        };
      }
    );
    // return remoteData.map(
    //   result,
    //   (response: MembershipBatchTableResponse) => response
    // );
  };

  export const cancelBatchRequest = async (id: string) => {
    const result = await NcaishCorporateEmployeeApiService.cancelBatchRequest(
      id
    );
    return remoteData.map(
      result,
      (
        response: MembershipBatchTableResponse
      ): MembershipBatchTableResponse => {
        return {
          successful: response.successful,
          message: response.message,
          data: response.data,
        };
      }
    );
  };
}
