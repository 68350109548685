import classNames from "classnames";
import { pipe } from "fp-ts/lib/function";
import { fold, isNone, isSome, none, Option, some } from "fp-ts/lib/Option";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/hooks";
import { TDetaxBatchInfo } from "../../../../models/batches.models";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";
import {
  getDisplayValue,
  getTitleCase,
  getTitleCaseToStatus,
} from "../../../../utils/common.utils";
import { currencyFormat } from "../../../../utils/format.utils";
import { unWrapOption } from "../../../../utils/option.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import NoTableData from "../common/no-table-data";
import StatusText from "../common/status-text";
import TableLoader from "../common/table-loader";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import {
  useGetBatchesByStatus,
  useGetBatchesByStatusAll,
  useGetBatchesByStatusPageCount,
} from "./hooks/transaction.hooks.";

const TransactionTable: React.FC<{ searchVal?: string }> = ({
  searchVal = "",
}) => {
  const filter = useAppSelector(DetaxReduxStore.selectFilter);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const [items, setItems] = useState<TDetaxBatchInfo[]>([]);
  const [filteredData, setFilteredData] = useState<TDetaxBatchInfo[]>([]);

  const {
    data: detaxBasicInfos,
    isLoading,
    isSuccess,
    isFetching,
    isError,
  } = useGetBatchesByStatus(
    filter.type,
    getTitleCaseToStatus(filter.status),
    paging.page,
    paging.limit
  );
  const { data: detaxBasicInfosAll } = useGetBatchesByStatusAll(
    filter.type,
    getTitleCaseToStatus(filter.status),
    1,
    1000000
  );
  const { data: detaxBasicInfos1 } = useGetBatchesByStatusPageCount(
    filter.type,
    getTitleCaseToStatus(filter.status),
    paging.page,
    paging.limit
  );
  // console.log("Test page count", detaxBasicInfos1, paging);
  useEffect(() => {
    // console.log("Changed");
    setFilteredData([]);
    setPaging((p) => ({
      ...p,
      total: 100000,
      page: 1,
      limit: 10,
    }));
  }, [getTitleCaseToStatus(filter.status), filter.type]);

  useEffect(() => {
    if (isSuccess && detaxBasicInfos && detaxBasicInfos1) {
      setPaging((p) => ({
        ...p,
        total: detaxBasicInfos1 ?? 1,
        page: paging.page,
        limit: paging.limit,
      }));
      setItems(detaxBasicInfos);
      setFilteredData(detaxBasicInfos);
    }
  }, [
    detaxBasicInfos,
    detaxBasicInfos1,
    paging.limit,
    paging.page,
    paging.total,
  ]);

  // useEffect(() => filterData(items), [searchVal, filteredData]);
  // const filterData = (data: TDetaxBatchInfo[]) => {
  //   if (searchVal) {
  //     let nameRegEx = new RegExp(".*" + searchVal + ".*", "gi");
  //     let batchCodeRegEx = new RegExp(".*" + searchVal + ".*", "gi"); // will help to search as claim id
  //     //let mobRegEx = new RegExp(searchVal + ".*", "gi");
  //     let filteredData = data.filter(
  //       (item) =>
  //         batchCodeRegEx.test(unWrapOption(item.batchCode, () => "")) ||
  //         nameRegEx.test(
  //           unWrapOption(item.createdBy, () => ({ name: "" })).name || ""
  //         )
  //       //mobRegEx.test(item.initiator?.mobile || "")
  //     );
  //     setPaging((p) => ({ ...p, total: filteredData.length, page: 1 }));
  //     setFilteredData(filteredData);
  //   } else {
  //     setPaging((p) => ({ ...p, total: data.length, page: 1 }));
  //     setFilteredData(data);
  //   }
  // };

  const rows = (data: TDetaxBatchInfo[]) => {
    return data.length > 0
      ? data.map((item, index) => (
          <tr key={index}>
            <td className="text-start">
              <Link
                className="text-secondary ncash_nav-link"
                to={
                  RoutePaths.DashboardBatch +
                  "/" +
                  unWrapOption(item.batchId, () => "")
                }
              >
                {unWrapOption(item.batchCode, () => "")}
              </Link>
            </td>
            <td className="text-center text-nowrap">
              {isSome(item.createdAt) ? item.createdAt.value : ""}
            </td>
            {/* <td>{unWrapOption(getDisplayValue(item), () => "")}</td> */}
            <td className="text-center text-nowrap">
              {isSome(item.updatedAt) ? item.updatedAt.value : ""}
            </td>
            <td className="text-start">
              {isSome(item.createdBy) ? item.createdBy.value.name : ""}
            </td>
            <td>{unWrapOption(item.txnCount, () => 0)}</td>
            <td className="text-end">
              {currencyFormat(unWrapOption(item.amount, () => 0))}
            </td>

            <td className="text-center text-nowrap">
              <StatusText
                text={getTitleCase(unWrapOption(item.status, () => ""))}
                className={classNames({
                  color4: unWrapOption(item.status, () => "") == "All",
                  color2: unWrapOption(item.status, () => "") == "CREATED",
                  color1: unWrapOption(item.status, () => "") == "SUBMITTED",
                  color3:
                    unWrapOption(item.status, () => "") == "PAYMENT_CONFIRMED",
                  color7: unWrapOption(item.status, () => "") == "EXECUTED",
                  color9: unWrapOption(item.status, () => "") == "ON_HOLD",
                  color6: unWrapOption(item.status, () => "") == "CANCELLED",
                  color8: unWrapOption(item.status, () => "") == "REJECTED",
                  color12: unWrapOption(item.status, () => "") == "EXECUTING",
                  color13: unWrapOption(item.status, () => "") == "FAILED",
                  color14:
                    unWrapOption(item.status, () => "") == "PARTIALLY_EXECUTED",
                })}
              />
            </td>
          </tr>
        ))
      : [
          <tr>
            <td className="text-secondary" colSpan={6}>
              <img
                src={process.env.PUBLIC_URL + "/images/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
                height="150"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TDetaxBatchInfo[]) => {
    // let result = [];
    // let startFrom = (paging.page - 1) * paging.limit;
    // let endAt = paging.page * paging.limit;
    // result = tblData.slice(startFrom, endAt);
    return tblData;
  };

  if (isLoading || isFetching) {
    return <TableLoader />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  //console.log("detaxBasicInfos: ", detaxBasicInfos);

  return (
    <>
      {isSuccess && filteredData && filteredData.length > 0 ? (
        <TablePagination
          data={paging}
          changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
          changeLimit={(data) =>
            setPaging((p) => ({ ...p, limit: data, page: 1 }))
          }
        >
          <table>
            <thead>
              <tr>
                <th scope="col">Batch Code</th>
                <th scope="col">Created At</th>
                <th scope="col">Updated At</th>
                <th scope="col">Created By</th>
                <th scope="col">No. Of Employees</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>{filteredData && rows(tblData(filteredData))}</tbody>
          </table>
        </TablePagination>
      ) : (
        <NoTableData />
      )}
    </>
  );
};

export default TransactionTable;
