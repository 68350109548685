import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, store } from "../store";

export interface ReportsState {
  download: Download;
  checkExist: string;
  showNew: boolean;
}

export enum ReportDownloadState {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export interface Download {
  "Statement of Allowances": ReportDownloadState;
  "Statement of Perquisites": ReportDownloadState;
  "De'tax Status Report": ReportDownloadState;
  "FBA Utilisation Report": ReportDownloadState;
}
export const initialReportsState: ReportsState = {
  download: {
    "Statement of Allowances": ReportDownloadState.NOT_STARTED,
    "Statement of Perquisites": ReportDownloadState.NOT_STARTED,
    "De'tax Status Report": ReportDownloadState.NOT_STARTED,
    "FBA Utilisation Report": ReportDownloadState.NOT_STARTED,
  },
  checkExist: "",
  showNew: false,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialReportsState,
  reducers: {
    initializeReports: () => initialReportsState,

    setDownload: (state, { payload: status }: PayloadAction<Download>) => {
      state.download = status;
    },
    setCheckExist: (state, { payload: status }: PayloadAction<string>) => {
      state.checkExist = status;
    },
    setShowNew: (state, { payload: status }: PayloadAction<boolean>) => {
      state.showNew = status;
    },
  },
});

export namespace ReportsDispatch {
  export const setDownload = (payload: Download) =>
    store.dispatch(reportsSlice.actions.setDownload(payload));
  export const setCheckExist = (payload: string) =>
    store.dispatch(reportsSlice.actions.setCheckExist(payload));
  export const setShowNew = (payload: boolean) =>
    store.dispatch(reportsSlice.actions.setShowNew(payload));
}

export namespace ReportsReduxStore {
  export const selectDownload = (state: State) => state.reports.download;
  export const selectCheckExist = (state: State) => state.reports.checkExist;
  export const selectShowNew = (state: State) => state.reports.showNew;
}

export default reportsSlice.reducer;
