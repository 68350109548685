import { UserPermission } from "../model/auth/oauthLogin.model";
import { TDashboardMenu } from "../types/dashboard-menu.type";
import { RoutePaths } from "../utils/route.utils";

export const alphaDashboardMenus: TDashboardMenu[] = [
  {
    label: "Dashboard",
    iconImagePath: "/images/icons/menus/home-icon.svg",
    path: RoutePaths.DashboardHome,
    accessPermissions: [UserPermission.PermissionType.DETAX_CARD],
  },
  {
    label: "A A",
    iconImagePath: "/images/icons/menus/analysis-icon.svg",
    path: RoutePaths.DashboardAA,
    accessPermissions: [UserPermission.PermissionType.AA],
  },
  {
    label: "FX Online",
    iconImagePath: "/images/icons/menus/fx-online.svg",
    path: RoutePaths.DashboardFxOnline,
    accessPermissions: [UserPermission.PermissionType.FX],
  },
  {
    label: "Employees",
    iconImagePath: "/images/icons/menus/employees-icon.svg",
    path: RoutePaths.EmployeesAdd,
    accessPermissions: [
      UserPermission.PermissionType.DETAX_CARD,
      UserPermission.PermissionType.NPS,
    ],
  },
  {
    label: "De`tax",
    iconImagePath: "/images/icons/menus/detax-icon-new.svg",
    path: RoutePaths.DashboardDetax,
    accessPermissions: [UserPermission.PermissionType.DETAX_CARD],
  },
  {
    label: "Memberships",
    iconImagePath: "/images/icons/menus/detax-icon-new.svg",
    path: RoutePaths.DashboardMemberships,
    accessPermissions: [UserPermission.PermissionType.MEMBERSHIP],
  },
  // {
  //   label: "NPS",
  //   iconImagePath: "/images/icons/menus/nps-icon.svg",
  //   path: RoutePaths.DashboardNps,
  //   accessPermissions: [UserPermission.PermissionType.NPS],
  // },

  {
    label: "Batch",
    iconImagePath: "/images/icons/menus/transaction-icon.svg",
    path: RoutePaths.DashboardBatch,
    accessPermissions: [UserPermission.PermissionType.DETAX_CARD],
  },
  {
    label: "Claims",
    iconImagePath: "/images/icons/menus/claims-icon.svg",
    path: RoutePaths.DashboardClaims,
    accessPermissions: [UserPermission.PermissionType.DETAX_CARD],
  },

  // {
  //   label: "Analysis",
  //   iconImagePath: "/images/icons/menus/analysis-icon.svg",
  //   path: RoutePaths.DashboardAnalysis,
  // },

  {
    label: "Transactions",
    iconImagePath: "/images/icons/menus/transactions-icon.svg",
    path: RoutePaths.DashboardTransactions,
    accessPermissions: [UserPermission.PermissionType.DETAX_CARD],
  },
  {
    label: "Settings",
    iconImagePath: "/images/icons/menus/settings-icon.svg",
    path: RoutePaths.DashboardSettings,
    accessPermissions: [UserPermission.PermissionType.DETAX_CARD],
  },
];
