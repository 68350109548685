import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import OtpField from "react-otp-field";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, CardButton, Heading, MediumScreen, SmallScreen } from "../..";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import mixpanel from "mixpanel-browser";
import { AuthService } from "../../../services/auth.service";
import { Dispatch } from "../../../state/dispatch";
import {
  selectSignUp,
  selectSignupId,
  selectVerificationId,
} from "../../../state/slice/user.slice";
import { fromPublic, timerSeconds } from "../../../utils/common.utils";
import { setErrorMessages } from "../../../utils/error.utils";
import { MyStorage } from "../../../utils/local-storage.utils";
import { unWrapOption } from "../../../utils/option.utils";
import { RoutePaths } from "../../../utils/route.utils";
import { selectDraftId } from "../../app/app.slice";
import Loader from "../../common/loader/loader";
import BrandLogoHeader from "./brandlogo-header";

const Image1 = fromPublic("/images/otp.svg");

const SignUpVerificationPage: React.FC = () => {
  const draftId = useAppSelector(selectDraftId);
  const signupId = useAppSelector(selectSignupId);
  const verificationId = useAppSelector(selectVerificationId);
  const signUser = useAppSelector(selectSignUp);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    mixpanel.track("Page load", { name: "Signup Verification" });
  }, []);

  const handleSubmit = async () => {
    if (isLoading) return;

    setError("");
    setIsLoading(true);

    mixpanel.track("Button click", { name: "Verify OTP" });

    const result = await AuthService.verifySignup(
      verificationId,
      signupId,
      numberOtp,
      mailOtp
    );
    if (isSuccess(result)) {
      setIsLoading(false);
      if (unWrapOption(result.value.successful, () => false) === true) {
        Dispatch.Token.setToken({
          accessToken: unWrapOption(result.value.accessToken, () => ""),
          refreshToken: unWrapOption(result.value.refreshToken, () => ""),
          tokenExpiry: unWrapOption(result.value.expiry, () => 0),
          scope: unWrapOption(result.value.scope, () => ""),
        });

        Dispatch.Token.setAppToken(result.value.appToken);

        // dispatch(
        //   setToken({
        //     accessToken: unWrapOption(result.value.accessToken, () => ""),
        //     refreshToken: unWrapOption(result.value.refreshToken, () => ""),
        //     tokenExpiry: unWrapOption(result.value.expiry, () => 0),
        //     scope: unWrapOption(result.value.scope, () => ""),
        //   })
        // );
        MyStorage.AuthUser.StoreData(result.value.data);
        const path = unWrapOption(result.value.nextAction, () => "/");
        navigate(path);
      } else {
        setError(unWrapOption(result.value.message, () => "Invalid OTP"));
      }
    } else if (isFailure(result)) {
      setIsLoading(false);
      setError(setErrorMessages(result.error));
    }
  };

  const goBack = () => {
    navigate(RoutePaths.SignUp);
  };

  const [numberOtp, setNumberOtp] = useState("");
  // const[emailOtp,setMailOtp]=useState("")
  const [otpCounter, setOtpCounter] = useState(25);
  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [otpCounter]);
  const handleNumberOtpChange = (numberOtp: any) => {
    setNumberOtp(numberOtp);
  };

  const handleResendOtp = () => {
    mixpanel.track("Button click", { name: "Resend OTP" });
    setOtpCounter(25);
    setMailOtp("");
    setNumberOtp("");
  };

  const [mailOtp, setMailOtp] = useState("");
  const handleMailOtpChange = (mailOtp: any) => {
    setMailOtp(mailOtp);
  };

  const { state }: { state: any } = useLocation();

  return (
    <div>
      <div className="container p-0">
        <BrandLogoHeader />
        {/* <Stepper />  */}
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-6 ">
              <MediumScreen>
                <div className="mx-auto col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <img
                    className="img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="Mobile"
                  />
                </div>
                <div className="mt-5 textDetail">
                  <Heading
                    className="mx-5 mb-3 mt-85"
                    text="User verification"
                    variant="small"
                  />

                  <p className="mx-5 fw-bolder text-secondary">
                    At Figital, we put a premium on data security. OTP capture
                    will enable multi-factor authentication so that your
                    credentials are not harvested and misused. Please do not
                    share this OTP with anyone!
                  </p>
                </div>
              </MediumScreen>
            </div>
            <div className="mt-4 col-lg-4 col-md-6">
              <MediumScreen>
                <div className="container p-0 m-0">
                  <div className="px-3 py-3 border-0 shadow card bg-grey rounded-15">
                    {error && (
                      <div
                        className="text-center alert alert-danger fw-bold"
                        role="alert"
                      >
                        {error}
                      </div>
                    )}

                    <Heading
                      className="mt-4 text-center"
                      text="Verify your mobile number and email"
                      variant="small"
                    />

                    <span className="mt-2 mb-3 text-center text-black fw-bold">
                      Enter the OTP sent to your mobile and email address
                    </span>
                    <span className="mb-1 text-center text-black fw-bold fs-5">
                      +91-{signUser.mobile} &nbsp;
                      <CardButton icon={"edit"} onClick={goBack} />
                    </span>
                    <div
                      id="otp"
                      className="flex-row mt-0 mb-0 inputs d-flex justify-content-center"
                    >
                      <OtpField
                        classNames="d-flex "
                        value={numberOtp}
                        onChange={handleNumberOtpChange}
                        numInputs={3}
                        onChangeRegex={/^([0-9]{0,})$/}
                        autoFocus
                        inputProps={{
                          className:
                            "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                          disabled: false,
                        }}
                      />
                    </div>

                    <span className="mt-2 mb-1 text-center text-black fw-bold fs-5">
                      {signUser.email} &nbsp;
                      <CardButton icon={"edit"} onClick={goBack} />
                    </span>
                    <div
                      id="otp"
                      className="flex-row mt-0 mb-2 inputs d-flex justify-content-center"
                    >
                      <OtpField
                        classNames="d-flex "
                        value={mailOtp}
                        onChange={handleMailOtpChange}
                        numInputs={3}
                        onChangeRegex={/^([0-9]{0,})$/}
                        inputProps={{
                          className:
                            "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                          disabled: false,
                        }}
                      />
                    </div>

                    <span className="d-inline text-center">
                      <p className="mb-0 fw-bolder text-secondary d-inline">
                        Having trouble?
                      </p>

                      {/* <ResendButton  /> */}
                      <Link
                        to=""
                        className={
                          "p-1 fw-bolder text-darkblue nav-link d-inline " +
                          (otpCounter <= 0 ? " " : "disabled")
                        }
                        onClick={handleResendOtp}
                      >
                        Resend OTP{" "}
                        <span className="mt-2 text-danger">
                          {timerSeconds(otpCounter)}
                        </span>
                      </Link>
                    </span>
                    <div className="mt-1 mb-20 ml-auto mr-auto text-center">
                      <Button
                        type="button"
                        className={
                          numberOtp.length !== 3 || mailOtp.length !== 3
                            ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                            : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                        }
                        disabled={!numberOtp}
                        onClick={handleSubmit}
                      >
                        {isLoading ? <Loader color="white" /> : <>Continue</>}
                      </Button>
                    </div>
                  </div>
                </div>
              </MediumScreen>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <SmallScreen>
            <div className="container p-0 m-0">
              <div className="p-2 border-0 shadow card bg-grey">
                {error && (
                  <div
                    className="text-center alert alert-danger fw-bold"
                    role="alert"
                  >
                    {error}
                  </div>
                )}

                <div className="d-md-none d-lg-none d-xl-none d-xxl-none">
                  <img
                    className="p-2 img-fluid"
                    width="791"
                    height="741"
                    src={Image1}
                    alt="BG"
                  />
                </div>

                <div className="p-3 mt-2 mb-3 card inputCard d-md-none d-lg-none d-xl-none d-xxl-none">
                  <Heading
                    className="mt-0 text-center"
                    text="Verify your mobile number and email"
                    variant="small"
                  />
                  <span className="mt-0 text-center fs-small fw-bold text-secondary">
                    Enter the OTP sent to your mobile and email address
                  </span>
                  <span className="mt-2 text-center text-black fw-bold fs-5">
                    +91-{signUser.mobile} &nbsp;
                    <CardButton icon={"edit"} onClick={goBack} />
                  </span>

                  <div
                    id="otp"
                    className="flex-row mt-2 inputs d-flex justify-content-center"
                  >
                    <OtpField
                      classNames="d-flex "
                      value={numberOtp}
                      onChange={handleNumberOtpChange}
                      numInputs={3}
                      onChangeRegex={/^([0-9]{0,})$/}
                      autoFocus
                      inputProps={{
                        className:
                          "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                        disabled: false,
                      }}
                    />
                  </div>
                  <span className="mt-0 text-center text-black fw-bold fs-5">
                    {signUser.email} &nbsp;
                    <CardButton icon={"edit"} onClick={goBack} />
                  </span>
                  <div
                    id="otp"
                    className="flex-row mt-1 inputs d-flex justify-content-center"
                  >
                    <OtpField
                      classNames="d-flex "
                      value={mailOtp}
                      onChange={handleMailOtpChange}
                      numInputs={3}
                      onChangeRegex={/^([0-9]{0,})$/}
                      inputProps={{
                        className:
                          "otp-field__input w-25 m-1 text-center form-control rounded   ncash_otp-input",
                        disabled: false,
                      }}
                    />
                  </div>
                  <span className="d-inline text-center">
                    <p className="mb-0 fw-bolder text-secondary d-inline">
                      Having trouble?
                    </p>
                    {/* <ResendButton /> */}
                    <Link
                      to=""
                      className={
                        "p-1 fw-bolder text-darkblue nav-link d-inline" +
                        (otpCounter <= 0 ? " " : "disabled")
                      }
                      onClick={handleResendOtp}
                    >
                      Resend OTP{" "}
                      <span className="mt-2 text-danger">
                        {timerSeconds(otpCounter)}
                      </span>
                    </Link>
                  </span>
                </div>
                <div className="mb-3 ml-auto mr-auto text-center">
                  <Button
                    type="button"
                    className={
                      numberOtp.length !== 3 || mailOtp.length !== 3
                        ? "btn-disable btn-lg mt-2 text-dark fw-bold p-3 fs-6"
                        : "btn-darkblue btn-lg mt-2 text-white fw-bold p-3 fs-6"
                    }
                    disabled={!numberOtp}
                    onClick={handleSubmit}
                  >
                    {isLoading ? <Loader color="white" /> : <>Continue</>}
                  </Button>
                </div>
              </div>
            </div>
          </SmallScreen>
        </div>
      </div>
    </div>
  );
};

export default SignUpVerificationPage;
