import { pipe } from "fp-ts/lib/function";
import { getOrElse } from "fp-ts/lib/Option";
import React from "react";
import { TCorporateUser } from "../../../types/corporate-user.type";
import DirectorCheckbox from "./director-checkbox";

interface Props {
  img: string;
  title: string;
  size?: "md" | "sm";
  index: number;
  director: TCorporateUser;
  onSelect: (director: TCorporateUser) => void;
  onUnSelect: (userId: string) => void;
  isChecked: boolean;
}

const DirectorRow: React.FC<Props> = ({
  director,
  onSelect,
  onUnSelect,
  index,
  img,
  title,
  size = "md",
  isChecked,
}) => {
  const handleOnCheck = (checked: boolean) => {
    if (checked) {
      onSelect(director);
    } else {
      onUnSelect(
        pipe(
          director.userId,
          getOrElse(() => "")
        )
      );
    }
  };
  return (
    <>
      {size === "md" ? (
        <div className="row p-2">
          <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2 bg-pink  ">
            <img
              className="img-fluid  ncash_imageBackgroundicon w-100 "
              src={process.env.PUBLIC_URL + img}
              alt="User"
            />
          </div>

          <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9 p-0">
            <h5 className="fs-small text-left fw-bold mt-1">{title}</h5>
          </div>
          <DirectorCheckbox
            devicetype="desktop"
            onCheck={handleOnCheck}
            userId={director.userId}
            isChecked={isChecked}
          />
        </div>
      ) : (
        <div className="border-bottom border-light">
          <div className="row my-2">
            <div className="w-auto">
              <div className="rounded-15">
                <img
                  className="img-fluid p-2"
                  style={{ background: "#fff1f1", borderRadius: 10, width: 30 }}
                  src={process.env.PUBLIC_URL + img}
                  alt="User"
                />
              </div>
            </div>

            <div className="col ps-1">
              <h6 className="text-start font-14 mt-1">{title}</h6>
            </div>
            <DirectorCheckbox
              devicetype="desktop"
              onCheck={handleOnCheck}
              userId={director.userId}
              isChecked={isChecked}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DirectorRow;
