/**
 * Auth Server API
 * Apis to perform login / sign up and authentication stuff across the system
 *
 * OpenAPI spec version: 1.6.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import { ApiClient } from "../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../errors";

//@ts-ignore
//import * as models from '../model/models';
import { AuthResponse } from "../model/auth/authResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RefreshTokenApiService {
  /**
   *
   * @param token -
   */
  export const refreshToken = async (
    token: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, AuthResponse>> => {
    const path = `/auth/refreshToken/${encodeURIComponent(String(token))}`
      .replace(/^\//, "")
      .replace("{" + "token" + "}", String(token));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}

//export default new RefreshTokenApiService()
