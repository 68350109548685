import { isSuccess, RemoteData } from "@devexperts/remote-data-ts";
import { useMutation, useQuery } from "react-query";
import { DetaxBatchCountInfo } from "../../../../../api/detax/model/detaxBatchCountInfo";
import { APIError } from "../../../../../api/errors";
import {
  TDetaxBatchCountApiResponse,
  // TDetaxBatchCountPage,
  TDetaxBatchInfo,
  TDetaxTransactionBatcheListResponse,
  TDetaxTransactionBatchMembers,
  TEligibleUserinfo,
} from "../../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../../models/detax-type-kind";
import { BatchesService } from "../../../../../services/batches.service";
import { Option, none, isSome, some, fold } from "fp-ts/lib/Option";
import { unWrapOption } from "../../../../../utils/option.utils";
import { StatusCountService } from "../../../../../services/status-count.service";
import { TStatusCountInfo } from "../../../../../types/status-count-info.type";
import { option } from "fp-ts";
import { DetaxDispatch } from "../../../../../state/slice/detax.slice";

export function useGetDetaxBatchesCount(type: DetaxTypeKind) {
  return useQuery<Option<TStatusCountInfo[]>, Error>(
    ["GetDetaxBatchesCount", type],
    async () => {
      const result = await BatchesService.getDetaxBatchCounts(type);
      if (isSuccess(result)) {
        if (result.value.successful) {
          const counts = option.isSome(result.value.data)
            ? option.some(
                StatusCountService.addAllDetaxTransactionCount(
                  result.value.data.value
                )
              )
            : none;
          return counts;
        } else {
          return none;
        }
      } else {
        return none;
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetBatchesByStatus(
  type: DetaxTypeKind,
  batchStatus: string,
  pn?: number,
  rc?: number
) {
  return useQuery<TDetaxBatchInfo[], Error>(
    ["useGetBatchesByStatus", type, batchStatus, pn, rc],
    async () => {
      const result = await BatchesService.getCorporateBatchByStatus({
        type,
        batchStatus,
        pn,
        rc,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          const batches = BatchesService.parseDetaxBatchInfo(result.value.data);
          // DetaxDispatch.setBatches(batches);
          return batches;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetBatchesByStatusAll(
  type: DetaxTypeKind,
  batchStatus: string,
  pn?: number,
  rc?: number
) {
  return useQuery<TDetaxBatchInfo[], Error>(
    ["useGetBatchesByStatus", type, batchStatus, pn, rc],
    async () => {
      const result = await BatchesService.getCorporateBatchByStatus({
        type,
        batchStatus,
        pn,
        rc,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          const batches = BatchesService.parseDetaxBatchInfo(result.value.data);
          DetaxDispatch.setBatches(batches);
          return batches;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}
type TDetaxBatchCountPage = number;

export function useGetBatchesByStatusPageCount(
  type: DetaxTypeKind,
  batchStatus: string,
  pn?: number,
  rc?: number
) {
  return useQuery<number, Error>(
    ["useGetBatchesByStatusPageCount", type, batchStatus, pn, rc],
    async () => {
      const result = await BatchesService.getCorporateBatchByStatus({
        type,
        batchStatus,
        pn,
        rc,
      });
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          const batches = BatchesService.parseDetaxBatchInfo(result.value.data);
          const countOption = fold(
            () => none,
            (num: any) => some(num)
          )(result.value.recordCount);

          let count = 0;
          if (countOption._tag === "Some") {
            count = countOption.value;
          }

          // DetaxDispatch.setBatches(batches);
          return count;
        } else {
          return 0; // Return a default value when not successful
        }
      } else {
        return 0; // Return a default value when not successful
      }
    },
    { refetchOnWindowFocus: false }
  );
}

export function useGetEligibleUser(type: DetaxTypeKind) {
  console.log("In useGetEligibleUser");
  return useQuery<TEligibleUserinfo[], Error>(
    ["useGetEligibleUser", type],
    async () => {
      const result = await BatchesService.getEligibleUsers(type);
      if (isSuccess(result)) {
        return result.value;
      } else {
        return [];
      }
    },
    { refetchOnWindowFocus: false }
  );
}

//get Corporate Batch Detail By BatchId
export function useGetBatchDetailByBatchId(batchId: string) {
  return useQuery<TDetaxBatchInfo, Error>(
    ["useGetBatchDetailByBatchId", batchId],
    async () => {
      const result = await BatchesService.getCorporateBatchDetailByBatchId(
        batchId
      );
      if (isSuccess(result)) {
        if (unWrapOption(result.value.successful, () => false) === true) {
          return BatchesService.parseDetaxBatchDetailsInfo(result.value.data);
        } else {
          return {
            batchId: none,
            batchCode: none,
            type: none,
            txnCount: none,
            amount: none,
            status: none,
            createdAt: none,
            createdBy: none,
            paymentInfo: none,
          } as TDetaxBatchInfo;
        }
      } else {
        return {
          batchCode: none,
          type: none,
          txnCount: none,
          amount: none,
          status: none,
          createdAt: none,
          createdBy: none,
          paymentInfo: none,
        } as TDetaxBatchInfo;
      }
    },
    { refetchOnWindowFocus: false }
  );
}

//get Corporate Batch Detail By BatchId
export function useGetBatchEmployeesByBatchId(
  batchId: string,
  datatype: string
) {
  return useQuery<TDetaxTransactionBatchMembers, Error>(
    ["useGetBatchEmployeesByBatchId", batchId],
    async () => {
      const result =
        datatype === "NPS"
          ? await BatchesService.getCorporateBatchEmployeesByBatchIdNPS(batchId)
          : await BatchesService.getCorporateBatchEmployeesByBatchId(batchId);
      if (isSuccess(result)) {
        const { successful, type, fbaTxns, taTxns, npsTxns, eaTxns, gcTxns } =
          result.value;

        if (unWrapOption(successful, () => false)) {
          //return BatchesService.parseDetaxBatchDetailsInfo(result.value.data);
          return {
            type: isSome(type) ? type.value.toString() : DetaxTypeKind.FBA,
            FBA: isSome(fbaTxns)
              ? fbaTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            TA: isSome(taTxns)
              ? taTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            EA: isSome(eaTxns)
              ? eaTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
            NPS: isSome(npsTxns)
              ? npsTxns.value.map(BatchesService.parseNpsBatchMemberInfo)
              : [],
            GC: isSome(gcTxns)
              ? gcTxns.value.map(BatchesService.parseDetaxCardBatchMemberInfo)
              : [],
          };
        } else {
          return {} as TDetaxTransactionBatchMembers;
        }
      } else {
        return {} as TDetaxTransactionBatchMembers;
      }
    },
    { refetchOnWindowFocus: false, refetchOnMount: "always" }
  );
}

export const useCreateTransactionBatchMutation = () => {
  return useMutation(BatchesService.createTransactionBatch);
};

export const useInitiateTransactionMutation = () => {
  return useMutation(BatchesService.initiateTransaction);
};

export const useInitiateNpsTransactionMutation = () => {
  return useMutation(BatchesService.initiateNpsTransaction);
};

export const useExecuteTransactionMutation = () => {
  return useMutation(BatchesService.executeBatch);
};

export const useCreateTransactionBatchMutationNps = () => {
  return useMutation(BatchesService.createTransactionBatchNps);
};
