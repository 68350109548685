import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  validateEmail,
  validateMobile,
  validateName,
  validatePRAN,
} from "../../../../utils/common.utils";
import Select from "react-select";
interface IData {
  name: string;
  pran: string;
  email: string;
  amount: string;
  error: string[];
}

const BulkNPSModal: React.FC<{
  data: IData;
  setData: (data: IData) => void;
  close: () => void;
}> = ({ data, setData, close }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<IData>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      name: data.name,
      pran: data.pran,
      email: data.email,
      amount: data.amount,
      error: [],
    },
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => setIsLoaded(true), []);

  const closeModal = () => {
    close();
    setValue("name", "");
    setValue("pran", "");
    setValue("email", "");
    setValue("amount", "");
  };
  const onSubmit: SubmitHandler<IData> = (data) => {
    setData({ ...data });
  };

  return (
    <>
      {isLoaded ? (
        <Modal show={true} onHide={() => closeModal()} centered>
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title className=" fs-5 fw-bold">
                  Edit Employee
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="fw-bold">
                  Provide details of the employee as following
                </p>
                <div className="row d-flex justify-content-center">
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="fname" className="text-muted">
                      Name
                    </label>
                    <input
                      {...register("name", {
                        required: "Name is required!",
                        validate: (val: string) =>
                          validateName(val) || "Invalid name!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.name?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.name?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="" className="text-muted">
                      PRAN
                    </label>
                    <input
                      {...register("pran", {
                        required: "PRAN no. is required!",
                        validate: (val: string) =>
                          validatePRAN(val) || "PRAN no. must be 12 digits",
                        valueAsNumber: true,
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.pran?.message,
                      })}
                      maxLength={12}
                    />
                    <div className="invalid-feedback">
                      {errors?.pran?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="" className="text-muted">
                      Email ID
                    </label>
                    <input
                      {...register("email", {
                        required: "Email ID is required!",
                        validate: (val: string) =>
                          validateEmail(val) || "Invalid email ID!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.email?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label htmlFor="salary" className="text-muted">
                      Monthly Contribution
                    </label>
                    <input
                      // defaultValue={modal.data?.salary}
                      {...register("amount", {
                        required: "Monthly Contribution is required!",
                        validate: (val: string) =>
                          parseFloat(val.toString()) > 0 ||
                          "Monthly Contribution is required!",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.amount?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.amount?.message}
                    </div>
                  </div>
                </div>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className=" px-3 py-2 btn bg-dark bg-opacity-10 border-0"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="primary-bg btn text-white border-0 px-3 py-2"
                >
                  Submit
                </button>
              </Modal.Footer>
            </form>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default BulkNPSModal;
