import React from "react";
import PageLoader from "../../../../page-loader";
import { Option, isSome } from "fp-ts/lib/Option";
import { DetaxBatchCountInfo } from "../../../../api/detax/model/detaxBatchCountInfo";
import CountCard from "./count-card";
import { useAppSelector } from "../../../../hooks/hooks";
import { useGetDetaxBatchesCount } from "./hooks/transaction.hooks.";
import { DetaxReduxStore } from "../../../../state/slice/detax.slice";
import CreateBatchButton from "./create-batch-button";
import { TStatusCountInfo } from "../../../../types/status-count-info.type";

type Props = {
  isLoading: boolean;
  data?: Option<TStatusCountInfo[]>;
};

const StatusSection: React.FC<Props> = ({ isLoading, data }) => {
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const filter = useAppSelector(DetaxReduxStore.selectFilter);
  const {
    data: counts,
    isLoading: isLoadingCounts,
    isError: isErrorCounts,
  } = useGetDetaxBatchesCount(activeTab);

  if (isLoadingCounts) {
    return <PageLoader />;
  }

  if (counts && isSome(counts)) {
    return (
      <div className="card-counts">
        {counts.value.length > 0 ? (
          counts.value.map((item, index) => (
            <div key={index} className="mt-2">
              <CountCard
                count={item.count || 0}
                label={item.label || ""}
                isActive={Boolean(
                  item.label
                    ? item.label.toLowerCase() ===
                        filter.status.toString().toLowerCase()
                    : false
                )}
              />
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <div></div>;
};

export default StatusSection;
