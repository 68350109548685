import { isSuccess } from "@devexperts/remote-data-ts";
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom";
import { OAuthLoginApiService } from "../../api/auth/oauthLoginApi.service";
import { useAppDispatch } from "../../hooks/hooks";
import { setIsAuthenticated } from "../app/app.slice";

export function IdleTimeout () {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
    // Set timeout values
    const timeout = 1000 * 60 * 5 // logout prompt will appears in seconds
    const promptTimeout = 1000 * 300; // 5 mins
  
    // Modal open state
    const [open, setOpen] = useState(false)
  
    // Time before idle
    const [remaining, setRemaining] = useState(0)
  
    const handleLogout = async () => {
      const result = await OAuthLoginApiService.oAuthLogout();
      if (isSuccess(result)) {
        // MyStorage.AuthToken.ClearData();
        // MyStorage.AuthUser.ClearData();
        // MyStorage.LoginId.ClearData();
        // dispatch(resetProfile());
        localStorage.clear();
        dispatch(setIsAuthenticated(false));
        navigate("/login");
      } else {
        console.log("logout api call failed");
      }
    };
    
    const onPrompt = () => {
      // onPrompt will be called after the timeout value is reached
      // In this case 30 minutes. Here you can open your prompt. 
      // All events are disabled while the prompt is active. 
      // If the user wishes to stay active, call the `reset()` method.
      // You can get the remaining prompt time with the `getRemainingTime()` method,
      setOpen(true)
      setRemaining(promptTimeout)
    }
    
    const onIdle = () => {
      // onIdle will be called after the promptTimeout is reached.
      // In this case 300 seconds. Here you can close your prompt and 
      // perform what ever idle action you want such as log out your user.
      // Events will be rebound as long as `stopOnMount` is not set.
      setOpen(false)
      setRemaining(0)
      handleLogout()
    }
    
    const onActive = () => {
      // onActive will only be called if `reset()` is called while `isPrompted()` 
      // is true. Here you will also want to close your modal and perform
      // any active actions. 
      setOpen(false)
      setRemaining(0)
    }
  
    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
      timeout,
      promptTimeout,
      onPrompt,
      onIdle,
      onActive,
      events: ['mousemove', 
      'keydown']
    })
  
    const handleStillHere = () => {
      setOpen(false)
      activate()
    }
  
    useEffect(() => {
      const interval = setInterval(() => {
        
        if (isPrompted()) {
          setRemaining(Math.ceil(getRemainingTime() / 1000))
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [getRemainingTime, isPrompted])
    
    return (
      <>
      
      <Modal show={open} centered>
      <Modal.Header className="bg-info d-flex flex-column justify-content-center">
        <div className="text-center  text-light fs-10"> &#9888;</div>
        <div className="text-light text-center">Logging you out in {remaining} seconds</div>
      </Modal.Header>

      <Modal.Footer
        className="ncaish-bg-grey d-flex justify-content-center pointer"
        onClick={handleStillHere}
      >
        <p className="light fw-bold">I am Still Here</p>
      </Modal.Footer>
    </Modal>
      </>
    )
  }