/**
 * Detax apis
 * Apis to manage cards,nps etc
 *
 * OpenAPI spec version: 1.0
 * Contact: praveen@ncaish.ai
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

//@ts-ignore
import queryString from "query-string";
import { ApiClient } from "../../api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { RemoteData } from "@devexperts/remote-data-ts";
import { APIError } from "../../errors";

//@ts-ignore
//import * as models from '../model/models';
import { DetaxCardUpdateFbaCategoryLimitApiRequest } from "../model/detaxCardUpdateFbaCategoryLimitApiRequest";
import { FbaAllowanceResponse } from "../model/fbaAllowanceResponse";
import { GetCardsApiResponse } from "../model/getCardsApiResponse";
import { UpdateCardStatusApiRequest } from "../model/updateCardStatusApiRequest";
import { ReplaceCardApiResponse } from "../model/replaceCardApiResponse";

type TQueryParam = {
  key: string;
  value: string | string[] | boolean | number | Date | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NcaishCardApiService {
  /**
   * Api to get all cards by corporate
   */
  export const getAllCards = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetCardsApiResponse>> => {
    const path = `/secure/detax/cards`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get all cards by corporate
   */
  export const getAllCorporateCards = async (
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetCardsApiResponse>> => {
    const path = `/secure/detax/corporate/cards`.replace(/^\//, "");
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to get cards by user
   * @param uid -
   */
  export const getCards = async (
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetCardsApiResponse>> => {
    const path = `/secure/detax/cards/user/${encodeURIComponent(String(uid))}`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   */
  export const getCorporateEmployFbaAllowance = async (
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FbaAllowanceResponse>> => {
    const path = `/secure/detax/corporate/fba/user/${encodeURIComponent(
      String(uid)
    )}`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "get",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to change card status
   * @param body -
   * @param id -
   */
  export const updateCardStatus = async (
    body: UpdateCardStatusApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetCardsApiResponse>> => {
    const path = `/secure/detax/cards/${encodeURIComponent(String(id))}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   * Api to change card Unlock status
   * @param body -
   * @param id -
   */
  export const updateCardUnlockStatus = async (
    body: UpdateCardStatusApiRequest,
    id: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, GetCardsApiResponse>> => {
    const path = `/secure/corporates/employees/unblock/${encodeURIComponent(
      String(id)
    )}`
      .replace(/^\//, "")
      .replace("{" + "id" + "}", String(id));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    console.log("url link: ", apiUrl);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      data: body,
      headers: { ...headers },
    };
    console.log("body : ", body);

    return await ApiClient.request(axiosRequestConfig);
  };

  /**
   *
   * @param uid -
   * @param request -
   */
  export const updateUserFbaWalletLimit = async (
    uid: string,
    request: DetaxCardUpdateFbaCategoryLimitApiRequest,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, FbaAllowanceResponse>> => {
    const path =
      `/secure/detax/corporate/fba/subwallet/limit/user/${encodeURIComponent(
        String(uid)
      )}`
        .replace(/^\//, "")
        .replace("{" + "uid" + "}", String(uid));
    // const allQueryParams: TQueryParam[] = [
    //   {
    //     key: "request",
    //     value: request,
    //   },
    // ];
    const queryParams = queryString.stringify(request);

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };

  export const replaceCard = async (
    uid: string,
    customHeaders: AxiosRequestHeaders = {}
  ): Promise<RemoteData<APIError, ReplaceCardApiResponse>> => {
    const path = `/secure/corporates/${encodeURIComponent(
      String(uid)
    )}/replace/card`
      .replace(/^\//, "")
      .replace("{" + "uid" + "}", String(uid));
    const allQueryParams: TQueryParam[] = [];
    const queryParams = allQueryParams
      .map((param) => `${param.key}=${param.value}`)
      .join("&");

    const apiUrl = queryParams === "" ? path : path.concat(`?${queryParams}`);
    let headers = customHeaders;
    const axiosRequestConfig: AxiosRequestConfig = {
      url: apiUrl,
      method: "put",
      headers: { ...headers },
    };

    return await ApiClient.request(axiosRequestConfig);
  };
}
