import { Link } from "react-router-dom";
import { fromPublic } from "../../../utils/common.utils";
import { RoutePaths } from "../../../utils/route.utils";

const ArrowDown = fromPublic("/images/icons/arrow-down-fill.svg");
const AddSingleIcon = fromPublic("/images/icons/usericon.svg");
const MultiUserIcon = fromPublic("/images/icons/multiuser.svg");

type TDropdownButton = {
  className?: string;
  btnName?: string;
};

const DropdownButton: React.FC<TDropdownButton> = ({ className, btnName }) => {
  return (
    <div className="dropdown d-flex justify-content-end">
      <button
        className={`btn ${className} text-light`}
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span> {btnName} </span>
        <img src={ArrowDown} className="img-fluid w-7" alt="icon" />
      </button>

      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <button
            className=" btn btn-primary dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <div>
              <img src={AddSingleIcon} className="img-fluid" alt="icon" />
              <span>Add Single</span>
            </div>
          </button>
        </li>
        <li>
          <Link
            className="nav-link text-dark"
            to={RoutePaths.DashboardEmployees}
          >
            <div>
              <img src={MultiUserIcon} className="img-fluid" alt="icon" />
              <span> Add Multiple </span>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default DropdownButton;
