import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DetaxBatchCountInfo } from "../../../../api/detax/model/detaxBatchCountInfo";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { DetaxDispatch } from "../../../../state/slice/detax.slice";
import { titleCase } from "../../../../utils/common.utils";
import { RoutePaths } from "../../../../utils/route.utils";
import WhiteCard from "../../../white-card/white-card";
import "../common/card-count.scss";

const TrxStatusCount: React.FC<{
  counts?: DetaxBatchCountInfo[] | [];
  tab: DetaxTypeKind;
}> = ({ counts = [], tab }) => {
  const navigate = useNavigate();
  return (
    <div className={classNames("card-counts")}>
      {counts.map((item, key) => (
        <div
          key={key}
          className="card-count"
          onClick={() => {
            DetaxDispatch.setDetaxType(tab);
            DetaxDispatch.setFilterTypeStatus(item.label || "ALL");
            navigate(RoutePaths.DashboardBatch);
          }}
        >
          <WhiteCard
            className={classNames("p-2 w-75 status-dot-card", {
              color2: item.status == "CREATED",
              color1: item.status == "SUBMITTED",
              color3: item.status == "PAYMENT_CONFIRMED",
              color7: item.status == "EXECUTED",
              color9: item.status == "ON_HOLD",
              color6: item.status == "CANCELLED",
              color8: item.status == "REJECTED",
              color12: item.status == "EXECUTING",
              color13: item.status == "FAILED",
              color14: item.status == "PARTIALLY_EXECUTED",
            })}
          >
            <div className="status-dot"></div>
            <h2 className="text-black text-center mb-0 fw-bold">
              {item.count}
            </h2>
            <p className="text-black text-center mb-1 fw-bold fs-xxsmall">
              {item.label ? titleCase(item.label.split("_").join(" ")) : ""}
            </p>
          </WhiteCard>
        </div>
      ))}
    </div>
  );
};

export default TrxStatusCount;
