import classNames from "classnames";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { COUNTRIES } from "../../../config/countries.data";
import {
  validateEmail,
  validateMobile,
  validateName,
  validatePincode,
} from "../../../utils/common.utils";
import Select from "react-select";
import { unWrapOption } from "../../../utils/option.utils";
import { useAppSelector } from "../../../hooks/hooks";
import { selectSwitchAccountPermission } from "../../app/app.slice";
interface Iprops /* extends React.InputHTMLAttributes<HTMLInputElement> */ {
  title?: string;
  department: Array<string> | null | undefined;
  busiUnit: string;
  setBusiUnit: (data: any) => any;
  // modal: IEmployeeModal;
  closeModal: () => void;
  handleSubmit: (data: any) => React.FormEventHandler<HTMLFormElement>;
  onSubmit: (data: any) => void;
  register: any;
  getValues: any;
  setValue: any;
  errors: any;
  actionState?: boolean;
  isEditable?: boolean; // will be true when form will open as update employee
  membershipReadOnly?: boolean; // will be true when form will open as edit details
}

interface Option {
  value: string;
  label: string;
}

const SaveEmployeeForm: React.FC<Iprops> = ({
  title,
  department,
  busiUnit,
  setBusiUnit,
  // modal,
  closeModal,
  handleSubmit,
  onSubmit,
  register,
  getValues,
  setValue,
  errors,
  actionState = false,
  isEditable = false,
  membershipReadOnly = true,
}) => {
  let dept: Array<string> | null | undefined = department;
  const deptopt: Option[] = dept
    ? dept.map((obj) => ({
        value: obj || "",
        label: obj || "",
      }))
    : [];

  const isSwitchAccount = unWrapOption(
    useAppSelector(selectSwitchAccountPermission),
    () => false
  );

  const [business, setBusiness] = useState<Option | undefined>(() => {
    if (
      getValues("businessUnit") !== undefined &&
      getValues("businessUnit") !== null
    ) {
      return {
        value: getValues("businessUnit"),
        label: getValues("businessUnit"),
      };
    }
    return undefined;
  });

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (window.location.href.split("/")[4] == "add-employees") {
    membershipReadOnly = false;
  } else {
    membershipReadOnly = true;
  }
  const handleBusiness = (selectedOption: any) => {
    setBusiUnit(selectedOption?.value);
    setBusiness(selectedOption);
  };

  return (
    <>
      {isLoaded ? (
        <Modal show={true} onHide={() => closeModal()} size="lg" centered>
          {isLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title className=" fs-5 fw-bold">{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="fw-bold">
                  Provide details of the employee as follows
                </p>
                <div className="row">
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="code" className="text-muted">
                      Employee Code
                    </label>
                    <input
                      // defaultValue={modal.data.code}
                      {...register("code", {
                        required: "Employee Code is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.code?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.code?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="fname" className="text-muted">
                      First Name
                    </label>
                    <input
                      // defaultValue={modal.data.fname}
                      {...register("fname", {
                        required: "First name is required!",
                        validate: (val: string) =>
                          validateName(val) || "Invalid name!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.fname?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.fname?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="lname" className="text-muted">
                      Last Name
                    </label>
                    <input
                      {...register("lname", {
                        required: "Last name is required!",
                        validate: (val: string) =>
                          validateName(val) || "Invalid name!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.lname?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.lname?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Mobile
                    </label>
                    <input
                      {...register("mobile", {
                        required: "Mobile no. is required!",
                        validate: (val: string) =>
                          validateMobile(val) || "Mobile no. must be 10 digits",
                        valueAsNumber: true,
                        pattern: {
                          value: /^[1-9]{10}$/,
                        },
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.mobile?.message,
                      })}
                      maxLength={10}
                    />
                    <div className="invalid-feedback">
                      {errors?.mobile?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Email ID
                    </label>
                    <input
                      {...register("email", {
                        required: "Email ID is required!",
                        validate: (val: string) =>
                          validateEmail(val) || "Invalid email ID!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.email?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="gender" className="text-muted">
                      Gender
                    </label>
                    <select {...register("gender")} className="form-control">
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="TRANSGENDER">Transgender</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Date Of Birth
                    </label>
                    <input
                      // defaultValue={inputDate('2011-11-14')}
                      // defaultValue={format(new Date('2011-11-14'), "yyyy-MM-dd")}
                      // defaultValue={modal.data.dob != '' ? inputDate(modal.data.dob) : format(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), "yyyy-MM-dd")}
                      {...register("dob", {
                        required: "Date Of Birth is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.dob?.message,
                      })}
                      type={"date"}
                      max={format(
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 18)
                        ),
                        "yyyy-MM-dd"
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors?.dob?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="" className="text-muted">
                      Date Of Joining
                    </label>
                    <input
                      // defaultValue={inputDate(modal.data.doj != '' ? modal.data.doj : format(new Date(), "yyyy-MM-dd"))}
                      {...register("doj", {
                        required: "Date Of Joining is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.doj?.message,
                      })}
                      type={"date"}
                      max={format(
                        new Date(
                          new Date().setFullYear(new Date().getFullYear())
                        ),
                        "yyyy-MM-dd"
                      )}
                    />
                    <div className="invalid-feedback">
                      {errors?.doj?.message}
                    </div>
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="taxRegime" className="text-muted">
                      Tax Regime
                    </label>
                    <select
                      {...register("taxRegime")}
                      className="form-control"
                      disabled={isSwitchAccount ? false : isEditable}
                    >
                      <option value="OLD_REGIME">Old Regime</option>
                      <option value="NEW_REGIME">New Regime</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors?.taxRegime?.message}
                    </div>
                  </div>
                  {department && (
                    <div className="form-group col-md-4 mb-3">
                      <label htmlFor="lname" className="text-muted">
                        Department
                      </label>
                      <Select
                        value={business}
                        options={deptopt}
                        onChange={handleBusiness}
                        className="w-100"
                      />
                      <div className="invalid-feedback">
                        {errors?.lname?.message}
                      </div>
                    </div>
                  )}
                  {membershipReadOnly && (
                    <div className="form-group col-md-4 mb-3">
                      <label htmlFor="membershipType" className="text-muted">
                        Membership
                      </label>
                      <select
                        {...register("membershipType")}
                        className="form-control"
                        disabled={membershipReadOnly}
                      >
                        <option value="detax">Detax</option>
                        <option value="detax+">Detax+</option>
                      </select>
                    </div>
                  )}
                </div>
                <hr />
                <div className="row">
                  {/* <div
                    className="form-group col-md-4 mb-3"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="address.country">Country</label>
                    <select
                      // defaultValue={modal?.data?.address?.country}
                      {...register("address.country", {
                        required: "Country is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.address?.country?.message,
                      })}
                    >
                      <option value={"India"}>{"India"}</option>
                    </select>
                    <div className="invalid-feedback">
                      {errors?.address?.country?.message}
                    </div>
                  </div> */}
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="address.state">State</label>
                    <input
                      // defaultValue={modal?.data?.address?.state}
                      {...register("address.state", {
                        required: "State is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.address?.state?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.address?.state?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="address.city">City</label>
                    <input
                      // defaultValue={modal?.data?.address?.city}
                      {...register("address.city", {
                        required: "City is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.address?.city?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.address?.city?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="address.pinCode">Pin Code</label>
                    <input
                      // defaultValue={modal?.data?.address?.pinCode}
                      {...register("address.pinCode", {
                        required: "Pincode is required!",
                        validate: (val: string) =>
                          validatePincode(val) || "Pincode must be 6 digits",
                      })}
                      className={classNames("form-control number-input", {
                        "is-invalid": errors?.address?.pinCode?.message,
                      })}
                      maxLength={6}
                    />
                    <div className="invalid-feedback">
                      {errors?.address?.pinCode?.message}
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="address.address" className="text-muted">
                      Address
                    </label>
                    <input
                      // defaultValue={modal?.data?.address?.address}
                      {...register("address.address", {
                        required: "Address is required!",
                      })}
                      className={classNames("form-control", {
                        "is-invalid": errors?.address?.address?.message,
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors?.address?.address?.message}
                    </div>
                  </div>

                  {/* <div className="form-group col-md-4 mb-3">
                    <label htmlFor="address.location.lat">Latitude</label>
                    <input
                      defaultValue={modal?.data?.address.location.lat}
                      {...register("address.location.lat")}
                      className="form-control"
                      type={"number"}
                    />
                  </div>
                  <div className="form-group col-md-4 mb-3">
                    <label htmlFor="address.location.lng">Longitude</label>
                    <input
                      defaultValue={modal?.data?.address.location.lng}
                      {...register("address.location.lng")}
                      className="form-control"
                      type={"number"}
                    />
                  </div> */}
                </div>
                {isEditable === false ? (
                  <>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <p>Salary Information</p>
                      </div>
                      <div className="form-group col-md-4 mb-3">
                        <label
                          htmlFor="salaryInfo.salary"
                          className="text-muted"
                        >
                          Salary
                        </label>
                        <input
                          // defaultValue={modal.data?.salaryInfo?.salary}
                          {...register("salaryInfo.salary", {
                            required: "Salary is required!",
                            validate: (val: string) =>
                              parseFloat(val) > 0 || "Salary is required!",
                          })}
                          className={classNames("form-control number-input", {
                            "is-invalid": errors?.salaryInfo?.salary?.message,
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors?.salaryInfo?.salary?.message}
                        </div>
                      </div>
                      <div className="form-group col-md-4 mb-3">
                        <label
                          htmlFor="salaryInfo.basicSalary"
                          className="text-muted"
                        >
                          Basic Salary
                        </label>
                        <input
                          // defaultValue={modal.data?.salaryInfo?.basicSalary}
                          {...register("salaryInfo.basicSalary", {
                            required: "Basic salary is required!",
                            validate: (val: string) =>
                              parseFloat(val) > 0 ||
                              "Basic salary is required!",
                          })}
                          className={classNames("form-control number-input", {
                            "is-invalid":
                              errors?.salaryInfo?.basicSalary?.message,
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors?.salaryInfo?.basicSalary?.message}
                        </div>
                      </div>
                      <div className="form-group col-md-4 mb-3">
                        <label
                          htmlFor="salaryInfo.allowance"
                          className="text-muted"
                        >
                          Flexible Allowance
                        </label>
                        <input
                          // defaultValue={modal.data?.salaryInfo?.allowance}
                          {...register("salaryInfo.allowance", {
                            required: "Flexible Allowance is required!",
                            validate: (val: string) =>
                              parseFloat(val) > 0 ||
                              "Flexible allowance is required!",
                          })}
                          className={classNames("form-control number-input", {
                            "is-invalid":
                              errors?.salaryInfo?.allowance?.message,
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors?.salaryInfo?.allowance?.message}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className=" px-3 py-2 btn bg-dark bg-opacity-10 border-0"
                >
                  Cancel
                </button>
                <button
                  disabled={
                    actionState ||
                    (department && business?.value.length === 0 ? true : false)
                  }
                  type="submit"
                  className="primary-bg btn text-white border-0 px-3 py-2"
                >
                  {actionState && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    ></span>
                  )}
                  Submit
                </button>
              </Modal.Footer>
            </form>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default SaveEmployeeForm;
