import "./white-card.scss";
type TWhiteCard = {
  className?: string;
  id?: string;
};

const WhiteCard: React.FC<TWhiteCard> = ({ className, children, id }) => {
  return <div id={id || ''} className={`white-card ${className}`}>{children}</div>;
};

export default WhiteCard;
