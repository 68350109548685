type TCusomtomGraphs = {
  totalpercentage?: number;
  value?: string | number;
  color?: string;
  width?: string;
  height?: string;
  className?: string;
};

const CusomtomGraphs: React.FC<TCusomtomGraphs> = ({
  totalpercentage = 0,
  value = "",
  color = "#ddd",
  width = "102.784",
  height = "51.749",
  className,
}) => {
  return (
    <div className="position-relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 102.784 51.749"
      >
        <defs>
          <filter id="Path_6905">
            <feOffset dy="5" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" result="color" />
            <feComposite operator="out" in="SourceGraphic" in2="blur" />
            <feComposite operator="in" in="color" />
            <feComposite operator="in" in2="SourceGraphic" />
          </filter>
        </defs>
        <g data-type="innerShadowGroup">
          <path
            id="Path_6905-2"
            data-name="Path 6905"
            d="M268.787,2414.259h0a5.743,5.743,0,0,1-5.7-5.025,40.253,40.253,0,0,0-79.876,0,5.743,5.743,0,0,1-5.7,5.025h0a5.755,5.755,0,0,1-5.706-6.476,51.754,51.754,0,0,1,102.693,0A5.755,5.755,0,0,1,268.787,2414.259Z"
            transform="translate(-171.755 -2362.51)"
            fill={color}
          />
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_6905)">
            <path
              id="Path_6905-3"
              data-name="Path 6905"
              d="M268.787,2414.259h0a5.743,5.743,0,0,1-5.7-5.025,40.253,40.253,0,0,0-79.876,0,5.743,5.743,0,0,1-5.7,5.025h0a5.755,5.755,0,0,1-5.706-6.476,51.754,51.754,0,0,1,102.693,0A5.755,5.755,0,0,1,268.787,2414.259Z"
              transform="translate(-171.75 -2362.51)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
      {/* {totalpercentage ? ( */}
      <h4
        className={`position-absolute top-50 mt-0 text-center w-100 h6 ${className}`}
      >
        {" "}
        {/* {totalpercentage}% */}
      </h4>
      <h4 className={`position-absolute top-50 mt-2 pt-2 text-center w-100 h5 ${className}`}>{value}</h4>
    </div>
  );
};
export default CusomtomGraphs;
