import { isFailure, isSuccess } from "@devexperts/remote-data-ts";
import { format } from "date-fns";
import * as ExcelJS from "exceljs";
import { isSome } from "fp-ts/lib/Option";
import xlsx from "json-as-xlsx";
import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ClaimInfo } from "../../../../api/detax/model/claimInfo";
import { DetaxCardSubscriptionInfo } from "../../../../api/detax/model/detaxCardSubscriptionInfo";
import { APIError } from "../../../../api/errors";
import { useAppSelector } from "../../../../hooks/hooks";
import { EmployeeInfo } from "../../../../model/employeeInfo";
import { TDetaxBatchInfo } from "../../../../models/batches.models";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { BatchesService } from "../../../../services/batches.service";
import { ClaimsService } from "../../../../services/claims.service";
import { DetaxService } from "../../../../services/detax.service";
import { EmployeeService } from "../../../../services/employee.service";
import { TransactionService } from "../../../../services/transaction.service";
import { AlphaReduxStore } from "../../../../state/slice/alpha.slice";
import { convertToDoc } from "../../../../utils/common.utils";
import { unWrapOption } from "../../../../utils/option.utils";
import {
  selectLoginUserCid,
  selectSwitchAccountPermission,
} from "../../../app/app.slice";
import WhiteCard from "../../../white-card/white-card";
import DateRangeSelectionPopup from "../claims/DateRangeSelectionPopup";
import FilterPopup from "../claims/FilterPopup";
import ErrorPopup from "../common/error-popup";
import SuccessPopup from "../common/succes-popup";
import ReportHeader from "./reportHeader";
import {
  Download,
  ReportDownloadState,
  ReportsDispatch,
  ReportsReduxStore,
} from "../../../../state/slice/reports.slice";
import { toast } from "react-toastify";
interface FormData {
  title: string;
  formType: string;
}
const ReportsTab: React.FC<{}> = () => {
  const [show, setShow] = useState(false);
  const giftCardStatus = useAppSelector(AlphaReduxStore.selectGiftCardStatus);
  const downloadedReport = useAppSelector(ReportsReduxStore.selectDownload);
  const isSwitchAccount = unWrapOption(
    useAppSelector(selectSwitchAccountPermission),
    () => false
  );
  const [formData, setFormData] = useState<FormData>({
    title: "",
    formType: "",
  });
  const [load, setLoad] = useState({
    employees: false,
    detaxStatus: false,
    claims: false,
    FBA: false,
    TA: false,
    EA: false,
    GC: false,
  });

  const [filter, setFilter] = useState(false);
  const [actionState, setActionState] = useState(false);
  const [actionStateCat, setActionStateCat] = useState(false);
  const [actionStateUtil, setActionStateUtil] = useState(false);
  const [actionState1, setActionState1] = useState(false);
  const [showFba, setShowFba] = useState(false);
  const [error, setError] = useState<APIError>();
  const cid = useAppSelector(selectLoginUserCid);

  const viewReport = (data: string | DetaxTypeKind) => {
    switch (data) {
      case "Employees":
        getEmployees();
        break;

      case "Detax":
        getDetaxStatus();
        break;

      case "Claims":
        getClaims();
        break;

      case "Transactions":
        setFilter(true);
        break;

      case "FBAReports":
        setShowFba(true);
        break;

      case DetaxTypeKind.FBA:
        getBatchData(data);
        break;
      case DetaxTypeKind.TA:
        getBatchData(data);
        break;
      case DetaxTypeKind.EA:
        getBatchData(data);
        break;
      case DetaxTypeKind.NPS:
        getBatchData(data);
        break;
      case DetaxTypeKind.GC:
        getBatchData(data);
        break;
      // default:
      //   break;
    }
  };

  const Loading = (data: string | DetaxTypeKind) => {
    switch (data) {
      case "Employees":
        return load.employees;

      case "Detax":
        return load.detaxStatus;

      case "Claims":
        return load.claims;

      case DetaxTypeKind.FBA:
        return load.FBA;

      case DetaxTypeKind.TA:
        return load.TA;

      case DetaxTypeKind.EA:
        return load.EA;

      case DetaxTypeKind.GC:
        return load.GC;

      default:
        return false;
    }
  };

  const [showSuccess, setShowSuccess] = useState({ show: false, message: "" });
  const [err, setErr] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  });
  const [shouldCall, setShouldCall] = useState({
    "Statement of Allowances": false,
    "Statement of Perquisites": false,
    "De'tax Status Report": false,
    "FBA Utilisation Report": false,
  });

  const getEmployees = async () => {
    let data: EmployeeInfo[] | [] = [];
    setLoad({ ...load, employees: true });
    setError(undefined);
    const tblResult = await EmployeeService.getAllEmployeeData(
      // curTbl?.status,
      "",
      1,
      100000
    );

    if (isSuccess(tblResult)) {
      if (tblResult.value.successful) {
        setLoad({ ...load, employees: false });
        // data = tblResult.value.data || [];
        exportToExcelEmployee(tblResult.value.data || []);
      } else {
        setLoad({ ...load, employees: false });
      }
    } else if (isFailure(tblResult)) {
      setLoad({ ...load, employees: false });
      setError(tblResult.error);
    }
    return data;
  };

  const exportToExcelEmployee = async (data: EmployeeInfo[]) => {
    // let data = await getData();

    let cols: string[] = [
      "Employee Code",
      "Employee Name",
      "Mobile",
      "E-mail",
      "Status",
    ];

    let content: any[] = [];
    data.map((item, index) => {
      content = [
        {
          ["Employee Code"]: item.code || "-",
          ["Employee Name"]: item.name || "-",
          ["Mobile"]: item.mobile || "-",
          ["E-mail"]: item.email || "-",
          ["Status"]: item.status || "" || "-",
        },
        ...content,
      ];
    });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];
    xlsx(output, { fileName: "Employees" });
  };

  const handleBatchLoad = (key: DetaxTypeKind, data: boolean) => {
    switch (key) {
      case DetaxTypeKind.FBA:
        setLoad({ ...load, FBA: data });
        break;
      case DetaxTypeKind.TA:
        setLoad({ ...load, TA: data });
        break;
      case DetaxTypeKind.EA:
        setLoad({ ...load, EA: data });
        break;
      case DetaxTypeKind.GC:
        setLoad({ ...load, GC: data });
        break;

      default:
        break;
    }
  };
  const getBatchData = async (val: DetaxTypeKind) => {
    handleBatchLoad(val, true);
    const exportToExcelBatch = (
      data: TDetaxBatchInfo[],
      val: DetaxTypeKind
    ) => {
      let cols: string[] = [];
      cols.push("Batch Code");
      cols.push("Created At");
      cols.push("Updated At");
      cols.push("Created By");
      cols.push("No Of Employees");
      cols.push("Total Amount");
      cols.push("Status");

      let content: any[] = [];

      data.forEach((item: TDetaxBatchInfo, index) => {
        const ctx: { [key: string]: any } = {
          ["Batch Code"]: unWrapOption(item.batchCode, () => ""),
        };
        ctx["Created At"] = isSome(item.createdAt) ? item.createdAt.value : "";
        ctx["Updated At"] = isSome(item.updatedAt) ? item.updatedAt.value : "";

        ctx["Created By"] = isSome(item.createdBy)
          ? item.createdBy.value.name
          : "";
        ctx["No Of Employees"] = isSome(item.txnCount)
          ? item.txnCount.value
          : 0;

        ctx["Total Amount"] = isSome(item.amount) ? item.amount.value : 0;

        ctx["Status"] = isSome(item.status) ? item.status.value : "";

        content.push(ctx);
      });

      let output = [
        {
          sheet: "Sheet1",
          columns: cols.map((item) => ({ label: item, value: item })),
          content,
        },
      ];

      xlsx(output, {
        fileName: `${
          batches.filter((obj) => obj.keyWord === val)[0].name
        } Batch`,
      });
    };
    const result = await BatchesService.getCorporateBatchByStatus({
      type: val, // Assuming 'val' corresponds to the 'type' property
      batchStatus: "", // Fill in the actual value for 'batchStatus'
      pn: 1, // Fill in the actual value for 'pn'
      rc: 100000, // Fill in the actual value for 'rc'
    });
    if (isSuccess(result)) {
      if (unWrapOption(result.value.successful, () => false) === true) {
        const batches = BatchesService.parseDetaxBatchInfo(result.value.data);
        exportToExcelBatch(batches || [], val);
        handleBatchLoad(val, false);
      } else {
        handleBatchLoad(val, false);
        return [];
      }
    } else {
      handleBatchLoad(val, false);
      return [];
    }
  };

  const getDetax = async () => {
    let data: DetaxCardSubscriptionInfo[] | [] = [];
    setError(undefined);
    setLoad({ ...load, detaxStatus: true });
    const tblResult = await DetaxService.getSubscription("", 1, 100000);

    if (isSuccess(tblResult)) {
      if (tblResult.value.successful) {
        setLoad({ ...load, detaxStatus: false });
        // data = tblResult.value.data || [];
        exportToExcelDetax(tblResult.value.data || []);
      } else {
        setLoad({ ...load, detaxStatus: false });
      }
    } else if (isFailure(tblResult)) {
      setLoad({ ...load, detaxStatus: false });
      setError(tblResult.error);
    }
    return data;
  };

  const getDetaxStatus = async () => {
    let data: DetaxCardSubscriptionInfo[] | [] = [];
    setError(undefined);
    setLoad({ ...load, detaxStatus: true });
    const tblResult = await DetaxService.getSubscriptionReport("", 1, 100000);

    if (isSuccess(tblResult)) {
      if (tblResult.value.successful) {
        setLoad({ ...load, detaxStatus: false });
        setShouldCall({ ...shouldCall, "De'tax Status Report": true });
        setShowSuccess({
          show: true,
          message: tblResult.value.message ?? "Success",
        });
      } else {
        setLoad({ ...load, detaxStatus: false });
        setErr({ show: true, message: tblResult.value.message ?? "Failed" });
      }
    } else if (isFailure(tblResult)) {
      setLoad({ ...load, detaxStatus: false });
      setErr({ show: true, message: tblResult.error.outcome ?? "Failed" });
      setError(tblResult.error);
    } else {
      setLoad({ ...load, detaxStatus: false });
      setErr({ show: true, message: "Failed" });
    }
    return data;
  };

  const exportToExcelDetax = async (data: DetaxCardSubscriptionInfo[]) => {
    // let data = await getData();

    let cols: string[] = [
      "Employee Code",
      "Employee Name",
      "Department",
      "Mobile",
      "E-mail",
      "Salary",
      "Flexi Allowance",
      "Status",
    ];

    let content: any[] = [];
    data.map((item, index) => {
      content = [
        {
          ["Employee Code"]: item.personId === "" ? "-" : item.personId,
          ["Employee Name"]: item.name === "" ? "-" : item.name,
          ["Department"]: item.name === "" ? "-" : item.businessUnit,
          ["Mobile"]: item.mobile === "" ? "-" : item.mobile,
          ["E-mail"]: item.email === "" ? "-" : item.email,
          ["Salary"]: item.salary,
          ["Flexi Allowance"]: item.monthlyFba,
          ["Status"]: item.status || "",
        },
        ...content,
      ];
    });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];
    xlsx(output, { fileName: "Detax" });
  };

  const getClaims = async () => {
    let data: ClaimInfo[] | [] = [];
    setLoad({ ...load, claims: true });
    const tblResult = await ClaimsService.allClaim(
      // curTbl?.status || "",
      "",
      "",
      "",
      "",
      1,
      1000000
    );

    if (isSuccess(tblResult)) {
      if (tblResult.value.successful) {
        // data = tblResult.value.data || [];
        exportToExcelClaims(tblResult.value.data || []);
        setLoad({ ...load, claims: false });
      } else {
        setLoad({ ...load, claims: false });
      }
    } else if (isFailure(tblResult)) {
      setLoad({ ...load, claims: false });
      setError(tblResult.error);
    }
    return data;
  };

  const exportToExcelClaims = async (data: ClaimInfo[]) => {
    // let data = await getData();

    let cols: string[] = [
      "Claim Code",
      "Category",
      "Employee Name",
      "Mobile",
      "E-mail",
      "Initiated At",
      "Amount",
      "Approval Amount",
      "Status",
    ];

    let content: any[] = [];
    data.map((item, index) => {
      content = [
        {
          ["Claim Code"]: item.claimCode || "-",
          ["Category"]: item.category || "-",
          ["Employee Name"]: item.initiator?.name || "-",
          ["Mobile"]: item.initiator?.mobile || "-",
          ["Initiated At"]: item.initiatedAt || "-",
          ["E-mail"]: item.initiator?.email || "-",
          ["Amount"]: item?.amount && item?.amount >= 0 ? item.amount : 0,
          ["Approval Amount"]: (item?.appAmount && item?.appAmount >= 0) || 0,
          ["Status"]: item.status || "-",
        },
        ...content,
      ];
    });

    let output = [
      {
        sheet: "Sheet1",
        columns: cols.map((item) => ({ label: item, value: item })),
        content,
      },
    ];
    xlsx(output, { fileName: "Claims" });
  };

  const downloadPdf = async (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: Date | null,
    to: Date | null,
    typestr: string
  ) => {
    setActionState(true);
    const result = await TransactionService.getDownloadTransactionReport(
      uidstr,
      categorystr,
      walletstr,
      approvalstr,
      from && from !== new Date() ? format(new Date(from), "dd-MM-yyyy") : "",
      to && to !== new Date() ? format(new Date(to), "dd-MM-yyyy") : "",
      typestr
    );

    if (isSuccess(result)) {
      // const url = window.URL.createObjectURL(new Blob([result.value]));
      // const link = document.createElement("a");
      // link.href = url;
      // const fileName = `Transactions${+new Date()}.pdf`; // whatever your file name .
      // link.setAttribute("download", fileName);
      // document.body.appendChild(link);
      // link.click();
      // link.remove();

      convertToDoc(result.value, `Transactions${+new Date()}`, "pdf");
      setActionState(false);
    } else {
      setActionState(false);
    }
  };
  // MY DOWNLOAD CSV FUNCTION
  const downloadCsv = async (
    uidstr: string,
    categorystr: string,
    walletstr: string,
    approvalstr: string,
    from: Date | null,
    to: Date | null,
    typestr: string
  ) => {
    setActionState(true);
    const result = await TransactionService.getDownloadTransactionReportCsv(
      uidstr,
      categorystr,
      walletstr,
      approvalstr,
      from && from !== new Date() ? format(new Date(from), "dd-MM-yyyy") : "",
      to && to !== new Date() ? format(new Date(to), "dd-MM-yyyy") : "",
      typestr
    );

    if (isSuccess(result)) {
      // const url = window.URL.createObjectURL(new Blob([result.value]));
      // const link = document.createElement("a");
      // link.href = url;
      // const fileName = `Transactions ${+new Date()}.csv`; // whatever your file name .
      // link.setAttribute("download", fileName);
      // document.body.appendChild(link);
      // link.click();
      // link.remove();

      convertToDoc(result.value, `Transactions ${+new Date()}`, "csv");
      setActionState(false);
    } else {
      setActionState(false);
    }
  };

  const downloadFbaUtilReport = async (startDate: any, endDate: any) => {
    setActionStateUtil(true);

    const result = await TransactionService.getDownloadFbaUtilisationReport(
      format(new Date(startDate), "dd-MM-yyyy"),
      format(new Date(endDate), "dd-MM-yyyy")
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        setActionStateUtil(false);
        setShowFba(false);
        setShowSuccess({
          show: result.value.successful,
          message: result.value.message ?? "Success",
        });
        setShouldCall({ ...shouldCall, "FBA Utilisation Report": true });
      } else {
        setActionStateUtil(false);
        setErr({ show: true, message: result.value.message ?? "Failed" });
      }
    } else {
      setErr({ show: true, message: "Failed" });
      setActionStateUtil(false);
    }
  };

  const downloadFbaUtilReportBlob = async (startDate: any, endDate: any) => {
    setActionStateUtil(true);

    const result = await TransactionService.getDownloadFbaUtilisationReportBlob(
      format(new Date(startDate), "dd-MM-yyyy"),
      format(new Date(endDate), "dd-MM-yyyy")
    );

    if (isSuccess(result)) {
      setActionStateUtil(false);
      setShowFba(false);
      const url = window.URL.createObjectURL(new Blob([result.value]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Fba Utilization ${+new Date()}.csv`; // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      setErr({ show: true, message: "Failed" });
      setActionStateUtil(false);
    }
  };
  const downloadFbaCatReport = async (startDate: any, endDate: any) => {
    setActionStateCat(true);

    const result = await TransactionService.getDownloadFbaCategoryReport(
      format(new Date(startDate), "dd-MM-yyyy"),
      format(new Date(endDate), "dd-MM-yyyy")
    );

    if (isSuccess(result)) {
      setShowFba(false);
      // const url = window.URL.createObjectURL(new Blob([result.value]));
      // const link = document.createElement("a");
      // link.href = url;
      // const fileName = `Fba Category ${+new Date()}.csv`; // whatever your file name .
      // link.setAttribute("download", fileName);
      // document.body.appendChild(link);
      // link.click();
      // link.remove();

      convertToDoc(result.value, `Fba Category ${+new Date()}`, "csv");

      setActionStateCat(false);
    } else {
      setActionStateCat(false);
    }
  };
  const allowedCid = [
    "cb45dcf5-8919-46dc-8ed2-2f58b380788c", //Figital
    "6290afdb5cafda331e242582", //Infosys - UAT
    "43f89308-e085-49a7-b06b-29e9810c3185", //BigTapp
  ];

  const getFormBtnTxt = (
    obj: { formType: string },
    downloadedReport: Download
  ) => {
    if (obj.formType === "16") {
      // if (
      //   downloadedReport["Statement of Allowances"] ===
      //   ReportDownloadState.COMPLETED
      // ) {
      //   return "Generated";
      // } else
      if (
        downloadedReport["Statement of Allowances"] ===
        ReportDownloadState.IN_PROGRESS
      ) {
        return "Generating Report...";
      } else {
        return undefined;
      }
    } else if (obj.formType === "12") {
      // if (
      //   downloadedReport["Statement of Perquisites"] ===
      //   ReportDownloadState.COMPLETED
      // ) {
      //   return "Generated";
      // } else
      if (
        downloadedReport["Statement of Perquisites"] ===
        ReportDownloadState.IN_PROGRESS
      ) {
        return "Generating Report...";
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (
      shouldCall["De'tax Status Report"] === true ||
      shouldCall["FBA Utilisation Report"] === true ||
      shouldCall["Statement of Allowances"] === true ||
      shouldCall["Statement of Perquisites"] === true ||
      downloadedReport["De'tax Status Report"] ===
        ReportDownloadState.IN_PROGRESS ||
      downloadedReport["Statement of Allowances"] ===
        ReportDownloadState.IN_PROGRESS ||
      downloadedReport["Statement of Perquisites"] ===
        ReportDownloadState.IN_PROGRESS ||
      downloadedReport["FBA Utilisation Report"] ===
        ReportDownloadState.IN_PROGRESS
    ) {
      // Call the function immediately
      getReportStatus();

      // Set up the interval to call the function every 5 seconds
      intervalId = setInterval(() => {
        getReportStatus();
      }, 5000);
    }

    // Cleanup function to clear the interval when the component unmounts or the condition changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [shouldCall]);

  console.log("Reports", downloadedReport);

  const state = [
    ReportDownloadState.COMPLETED,
    ReportDownloadState.FAILED,
    ReportDownloadState.NOT_STARTED,
  ];

  const getReportStatus = async () => {
    const result = await TransactionService.getReportStatus();

    if (isSuccess(result)) {
      type ReportType =
        | "Statement of Allowances"
        | "Statement of Perquisites"
        | "FBA Utilisation Report"
        | "De'tax Status Report";

      const reports: ReportType[] = [
        "Statement of Allowances",
        "Statement of Perquisites",
        "FBA Utilisation Report",
        "De'tax Status Report",
      ];

      const state = [
        ReportDownloadState.COMPLETED,
        ReportDownloadState.FAILED,
        ReportDownloadState.NOT_STARTED,
      ];

      reports.forEach((report) => {
        if (shouldCall[report]) {
          const reportState = result.value[report];
          ReportsDispatch.setDownload({
            ...downloadedReport,
            [report]: reportState,
          });

          if (reportState === ReportDownloadState.COMPLETED) {
            toast.success(`${report} - Successfully Generated`);
          } else if (reportState === ReportDownloadState.FAILED) {
            toast.error(`${report} - Failed to Generate`);
          }

          if (state.includes(reportState)) {
            setShouldCall((prev) => ({ ...prev, [report]: false }));
          }
        }
      });

      // reports.forEach((report) => {
      //   if (shouldCall[report] === true) {
      //     ReportsDispatch.setDownload({
      //       ...downloadedReport,
      //       [report]: result.value[report],
      //     });
      //     if (result.value[report] === ReportDownloadState.COMPLETED) {
      //       console.log("Toast");
      //       toast.success(`${report} - Successfully Generated`);
      //       setShouldCall({ ...shouldCall, [report]: false });
      //     } else if (result.value[report] === ReportDownloadState.FAILED) {
      //       console.log("Toast");
      //       toast.error(`${report} - Failed to Generate`);
      //       setShouldCall({ ...shouldCall, [report]: false });
      //     }
      //   }
      // });

      // reports.forEach((report) => {
      //   if (
      //     state.includes(result.value[report]) &&
      //     shouldCall[report] === true
      //   ) {
      //     setShouldCall({ ...shouldCall, [report]: false });
      //   }
      // });
    } else {
      setShouldCall({
        "Statement of Allowances": false,
        "Statement of Perquisites": false,
        "De'tax Status Report": false,
        "FBA Utilisation Report": false,
      });
      console.log("Report Status Error");
    }
  };

  return (
    <div>
      <div>
        <ReportHeader title={"Form 16"} desc={""} />
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-start">
          {form16.map((obj) => (
            <WhiteBox
              name={obj.name}
              desc={obj.desc}
              onClick={() => {
                setShow(true);
                setFormData({
                  ...formData,
                  title: obj.name,
                  formType: obj.formType,
                });
              }}
              load={getFormBtnTxt(obj, downloadedReport) !== undefined}
              btnTxt={getFormBtnTxt(obj, downloadedReport)}
            />
          ))}
        </div>
      </div>

      <div>
        <ReportHeader title={"Flexible Benefit Allowances"} desc={""} />
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-start">
          {fba.map((obj) => (
            <WhiteBox
              name={obj.name}
              desc={obj.desc}
              onClick={() => {
                setShowFba(true);
              }}
              load={
                downloadedReport["FBA Utilisation Report"] ===
                ReportDownloadState.IN_PROGRESS
              }
              btnTxt={
                downloadedReport["FBA Utilisation Report"] ===
                ReportDownloadState.IN_PROGRESS
                  ? "Generating Report..."
                  : undefined
              }
            />
          ))}
        </div>
      </div>

      <div>
        <ReportHeader title={"Employees"} desc={""} />
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-start">
          {employees.map((obj) => (
            <WhiteBox
              name={obj.name}
              desc={obj.desc}
              onClick={() => {
                viewReport(obj.keyWord);
              }}
              // load={Loading(obj.keyWord)}
              load={
                obj.name === "De'tax Status"
                  ? downloadedReport["De'tax Status Report"] ===
                    ReportDownloadState.IN_PROGRESS
                  : Loading(obj.keyWord)
              }
              btnTxt={
                obj.name === "De'tax Status"
                  ? downloadedReport["De'tax Status Report"] ===
                    ReportDownloadState.IN_PROGRESS
                    ? "Generating Report..."
                    : undefined
                  : undefined
              }
            />
          ))}
        </div>
      </div>

      <div style={{ display: "none" }}>
        <ReportHeader title={"Batch"} desc={""} />
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-start">
          {batches
            .filter(
              (obj) => obj.keyWord !== (giftCardStatus ? "" : DetaxTypeKind.GC)
            )
            .map((obj) => (
              <WhiteBox
                name={obj.name}
                desc={obj.desc}
                onClick={() => {
                  viewReport(obj.keyWord);
                }}
                load={Loading(obj.keyWord)}
              />
            ))}
        </div>
      </div>

      <div>
        <ReportHeader title={"Transactions & Claims"} desc={""} />
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-start">
          {transactionClaims.map((obj) => (
            <WhiteBox
              name={obj.name}
              desc={obj.desc}
              onClick={() => {
                viewReport(obj.keyWord);
              }}
              load={Loading(obj.name)}
            />
          ))}
        </div>
      </div>

      {/* )} */}

      <Form16Modal
        show={show}
        formData={formData}
        closeModal={() => {
          setFormData({ ...formData, title: "", formType: "" });
          setShow(false);
        }}
        onSubmit={(val) => {
          if (val === "16") {
            setShouldCall({ ...shouldCall, "Statement of Allowances": true });
          }
          if (val === "12") {
            setShouldCall({ ...shouldCall, "Statement of Perquisites": true });
          }
        }}
        actionState={actionState}
      />
      <DateRangeSelectionPopup
        actionStateCat={actionStateCat}
        actionStateUtil={actionStateUtil}
        show={showFba}
        closeModal={() => setShowFba(false)}
        onDownloadUtil={(from, to) => {
          downloadFbaUtilReport(from, to);
        }}
        onDownloadCat={(from, to) => {
          downloadFbaCatReport(from, to);
        }}
      />
      <FilterPopup
        actionState1={actionState}
        show={filter}
        closeModal={() => setFilter(false)}
        onDownload={(
          employees,
          categorynew,
          walletnew,
          approvalnew,
          from,
          to,
          type
        ) => {
          downloadPdf(
            employees,
            categorynew,
            walletnew,
            approvalnew,
            from,
            to,
            type
          );
          setFilter(false);
        }}
        onDownload1={(
          employees,
          categorynew,
          walletnew,
          approvalnew,
          from,
          to,
          type
        ) => {
          downloadCsv(
            employees,
            categorynew,
            walletnew,
            approvalnew,
            from,
            to,
            type
          );
          setFilter(false);
        }}
        user={[]}
      />
      <SuccessPopup
        show={showSuccess.show}
        message={
          "Your report is currently being generated. We will notify you through email once the report is ready."
        }
        onClose={() => {
          setShowSuccess({ show: false, message: "" });
        }}
        txtSmall={true}
      />
      <ErrorPopup
        show={err.show}
        onClose={() => {
          setErr({ show: false, message: "" });
        }}
        message={err.message}
      />
    </div>
  );
};

export default ReportsTab;

const WhiteBox: React.FC<{
  name: string;
  desc: string;
  onClick: () => void;
  load?: boolean;
  style?: CSSProperties;
  btnTxt?: string;
}> = ({ name, desc, onClick, load = false, style = {}, btnTxt }) => {
  return (
    <div style={{ width: 370 }} className="m-2">
      <WhiteCard>
        <div>
          <p className="m-0" style={{ color: "#1E1E1E", fontWeight: "600" }}>
            {name}
          </p>
          <p
            className="m-0 mt-2"
            style={{
              color: "#898989",
              fontWeight: "400",
              fontSize: 14,
              lineHeight: 1.3,
              ...style,
            }}
          >
            {desc}
          </p>
        </div>
        <div className="mt-3">
          <Button
            className="btn-gradiant-blue btn-lg text-white fs-small"
            onClick={() => {
              onClick();
            }}
            disabled={load}
          >
            {load && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            {btnTxt ? btnTxt : "View Report"}
          </Button>
        </div>
      </WhiteCard>
    </div>
  );
};

const Form16Modal: React.FC<{
  show: boolean;
  formData: FormData;
  closeModal: () => void;
  actionState: boolean;
  onSubmit?: (val: string) => void;
}> = ({ show, formData, closeModal, actionState, onSubmit = () => {} }) => {
  const [fromNew, setFromDateNew] = useState<Date>(new Date());
  const [toNew, setToDateNew] = useState<Date>(new Date());
  const [load, setLoad] = useState(false);
  const [showSuccess, setShowSuccess] = useState({ show: false, message: "" });
  const [err, setErr] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  });
  let financialYearStart: any;
  let financialYearEnd: any;
  const [radioBtn, setRadioBtn] = useState(true);

  useEffect(() => {
    if (radioBtn) {
      if (currentMonth >= 3) {
        financialYearStart = new Date(`04-01-${currentYear}`);
        financialYearEnd = new Date(`03-31-${currentYear + 1}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      } else {
        financialYearStart = new Date(`04-01-${currentYear - 1}`);
        financialYearEnd = new Date(`03-31-${currentYear}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      }
    } else {
      if (currentMonth >= 3) {
        financialYearStart = new Date(`04-01-${currentYear - 1}`);
        financialYearEnd = new Date(`03-31-${currentYear + 1 - 1}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      } else {
        financialYearStart = new Date(`04-01-${currentYear - 1 - 1}`);
        financialYearEnd = new Date(`03-31-${currentYear - 1}`);
        setFromDateNew(financialYearStart);
        setToDateNew(financialYearEnd);
      }
    }
  }, [radioBtn]);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();
  let checkFinancialYear = currentDate.getFullYear();

  if (currentMonth >= 3) {
    checkFinancialYear += 1;
  }
  const financialYear = `FY ${
    checkFinancialYear - 1
  } - FY ${checkFinancialYear}`;

  const financialYearLast = `FY ${checkFinancialYear - 1 - 1} - FY ${
    checkFinancialYear - 1
  }`;

  const downloadCsv = async (
    formType: string,
    from: Date | null,
    to: Date | null
  ) => {
    setLoad(true);
    const result = await TransactionService.getFormReport(
      formType,
      from && from !== new Date() ? format(new Date(from), "dd-MM-yyyy") : "",
      to && to !== new Date() ? format(new Date(to), "dd-MM-yyyy") : ""
    );

    if (isSuccess(result)) {
      if (result.value.successful) {
        setShowSuccess({
          show: true,
          message:
            "Your report is currently being generated. We will notify you through email once the report is ready.",
        });
        setLoad(false);

        closeModal();
      } else {
        setLoad(false);
        setErr({ show: true, message: result.value.message ?? "Failed" });
      }
    } else {
      setErr({ show: true, message: "Failed" });
      setLoad(false);
      // setActionStatecsv1(false);
    }
  };

  const downloadCsvBlob = async (
    formType: string,
    from: Date | null,
    to: Date | null
  ) => {
    setLoad(true);
    const result = await TransactionService.getFormReportBlob(
      formType,
      from && from !== new Date() ? format(new Date(from), "dd-MM-yyyy") : "",
      to && to !== new Date() ? format(new Date(to), "dd-MM-yyyy") : ""
    );

    if (isSuccess(result)) {
      setLoad(false);
      const url = window.URL.createObjectURL(new Blob([result.value]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${
        formType === "16"
          ? "Statement of Allowances"
          : "Statement of Perquisites"
      } ${+new Date()}.csv`; // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      closeModal();
    } else {
      setErr({ show: true, message: "Failed" });
      setLoad(false);
      // setActionStatecsv1(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={() => {
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <b>{formData.title}</b>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="row align-items-center justify-content-center">
              <div className="container">
                <div
                  className=""
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="">
                    <label className="">Select the Financial Year</label>
                  </div>
                  <div className="" style={{ marginLeft: 95 }}>
                    <div className="" style={{ flexDirection: "row" }}>
                      <input
                        className="form-check-input"
                        type="radio"
                        value="option1"
                        // checked={selectedValue === "option1"}
                        checked={radioBtn}
                        // onChange={handleChange}
                        onClick={() => {
                          setRadioBtn(true);
                        }}
                      />
                      <label style={{ marginLeft: 5 }}>{financialYear}</label>
                    </div>
                    <div
                      className=""
                      style={{ flexDirection: "row", marginTop: 7 }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        value="option1"
                        // checked={selectedValue === "option1"}
                        checked={!radioBtn}
                        // onChange={handleChange}
                        onClick={() => {
                          setRadioBtn(false);
                        }}
                      />
                      <label style={{ marginLeft: 5 }}>
                        {financialYearLast}
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                <div className="col">
                  <label>Choose the custom date below:</label>
                </div>
              </div>
              <div className="row mt-3"></div>
              <div className="row">
                <div className="col-6">
                  <label>From</label>
                  <input
                    type="date"
                    className="form-control border-0"
                    // value={from.toLocaleDateString("en-CA")}

                    onChange={(event) => {
                      setFromDateNew(new Date(event.target.value));
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>To</label>
                  <input
                    type="date"
                    className="form-control border-0"
                    // value={to.toLocaleDateString("en-CA")}

                    onChange={(event) => {
                      setToDateNew(new Date(event.target.value));
                    }}
                  />
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => closeModal()}
            type="button"
            className=" px-3 py-2 btn bg-dark bg-opacity-10 border-0"
          >
            Cancel
          </button>
          <button
            disabled={actionState || load}
            type="submit"
            className="primary-bg btn text-white border-0 px-3 py-2"
            onClick={() => {
              downloadCsv(formData.formType, fromNew, toNew);
              onSubmit(formData.formType);
            }}
          >
            {(actionState || load) && (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
              ></span>
            )}
            {"Send Statement to Email"}
          </button>
        </Modal.Footer>
      </Modal>
      <SuccessPopup
        show={showSuccess.show}
        onClose={() => {
          setShowSuccess({ show: false, message: "" });
        }}
        message={showSuccess.message}
        txtSmall={true}
      />
      <ErrorPopup
        show={err.show}
        onClose={() => {
          setErr({ show: false, message: "" });
        }}
        message={err.message}
      />
    </>
  );
};

const form16 = [
  {
    name: "Statement of Allowances [Section 10 & VI A]",
    desc: "Allowances report (Spent Amount, Approved Amount etc) to easily fill in Section 10 & VI A for all your employees.",
    formType: "16",
  },
  {
    name: "Statement of Perquisites [Form 12BA]",
    desc: "Perquisites report (Spent Amount, Approved Amount etc) to easily fill in Form 12BA for all your employees.",
    formType: "12",
  },
];

const employees = [
  {
    name: "Employee Details",
    desc: "Details of all onboarded employees.",
    keyWord: "Employees",
  },
  {
    name: "De'tax Status",
    desc: "Status of the De'tax card for all employees",
    keyWord: "Detax",
  },
];

const batches = [
  {
    name: "Flexible Benefit Allowances",
    desc: "Details of all the Flexible Benefit batches for all employees.",
    keyWord: DetaxTypeKind.FBA,
  },
  {
    name: "Claims",
    desc: "Details of all the Travel and Expense Allowance batches for all employees.",
    keyWord: DetaxTypeKind.TA,
  },
  {
    name: "Expense Advance",
    desc: "Details of all the Expense Advance batches for all employees.",
    keyWord: DetaxTypeKind.EA,
  },
  // {
  //   name: "NPS",
  //   desc: "Lorem ipsum dolor sit amet consectetur. Aliquam ac urna orci egestas euismod sem bibendum. Mauris iaculis ut integ",
  //   keyWord: DetaxTypeKind.NPS,
  // },
  {
    name: "Employee Rewards",
    desc: "Details of all the Employee Rewards batch for all employees.",
    keyWord: DetaxTypeKind.GC,
  },
];

const transactionClaims = [
  {
    name: "Transactions",
    desc: "Transaction related information for all (or) specific employees.",
    keyWord: "Transactions",
  },
  {
    name: "Claims",
    desc: "Reimbursement claim related information for all employees.",
    keyWord: "Claims",
  },
];

const fba = [
  {
    name: "FBA Report",
    desc: "Information on amounts spent using the FBA wallet segregated by categories for all employees.",
    keyWord: "FBAReports",
  },
];

//  Disable column in excel while downloading

const getBatchData = async (val: DetaxTypeKind) => {
  const exportToExcelBatch = async (
    data: TDetaxBatchInfo[],
    val: DetaxTypeKind
  ) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    let cols: string[] = [
      "Batch Code",
      "Created At",
      "Updated At",
      "Created By",
      "No Of Employees",
      "Total Amount",
      "Status",
    ];

    worksheet.columns = cols.map((col) => {
      return {
        header: col,
        key: col,
        width: 15,
        editable: col !== "Created By",
      };
    });

    data.forEach((item: TDetaxBatchInfo) => {
      worksheet.addRow({
        "Batch Code": unWrapOption(item.batchCode, () => ""),
        "Created At": isSome(item.createdAt) ? item.createdAt.value : "",
        "Updated At": isSome(item.updatedAt) ? item.updatedAt.value : "",
        "Created By": isSome(item.createdBy) ? item.createdBy.value.name : "",
        "No Of Employees": isSome(item.txnCount) ? item.txnCount.value : 0,
        "Total Amount": isSome(item.amount) ? item.amount.value : 0,
        Status: isSome(item.status) ? item.status.value : "",
      });
    });

    // Unlock specific columns
    worksheet.protect("", {});
    const protect = [
      "Batch Code",
      "Created At",
      "Updated At",
      "Created By",
      "No Of Employees",
      "Total Amount",
    ];
    // Check if worksheet.columns is defined before using it
    if (worksheet.columns) {
      worksheet.columns.forEach((col) => {
        // Check if col.eachCell is defined before calling it
        if (col && col.eachCell) {
          if (
            Array.isArray(col.header)
              ? protect.includes(col.header[0])
              : protect.includes(col.header ?? "")
          ) {
            col.eachCell({ includeEmpty: true }, (cell) => {
              cell.protection = { locked: true };

              cell.style.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFCCCCCC" }, // Change this to the desired background color for locked cells
              };
            });
          } else {
            col.eachCell({ includeEmpty: true }, (cell) => {
              cell.protection = { locked: false };
              cell.style.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF0000" },
              };
            });
          }
        }
      });
    }

    const fileName = `${
      batches.find((obj) => obj.keyWord === val)?.name || "Batch"
    } Batch`;

    // Save the workbook to a file or send it to the client
    // await workbook.xlsx.writeFile(fileName + ".xlsx");
    await workbook.xlsx.writeBuffer().then((buffer) => {
      // Download the file
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}1.xlsx`;
      a.click();
    });
  };
};
