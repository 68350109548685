import classNames from "classnames";
import "../../verticaltabs.scss";

interface IProps {
  activeTab: string;
  onClick: (tab: string) => void;
}

const NPSverticalTabs = ({ activeTab = "PranAccount", onClick }: IProps) => {
  return (
    <ul className="nav flex-column verticalTab">
      <li className="nav-item" onClick={() => onClick("PranAccount")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "PranAccount",
          })}
        >
          Pran Account
        </div>
      </li>
      <li className="nav-item" onClick={() => onClick("Contribution")}>
        <div
          className={classNames("nav-link text-secondary fw-bold font-14 p-3", {
            active: activeTab == "Contribution",
          })}
        >
          Contribution
        </div>
      </li>
    </ul>
  );
};
export default NPSverticalTabs;
