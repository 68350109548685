import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setIsAuthenticated } from "../../components/app/app.slice";
import { State, store } from "../../state/store";
import { CalculateTokenExpiryTime } from "../../utils/auth.utils";
import { MyStorage } from "../../utils/local-storage.utils";
import { none, Option } from "fp-ts/lib/Option";
import { DetaxTypeKind } from "../../models/detax-type-kind";
import { DetaxBatchInfo } from "../../api/detax/model/detaxBatchInfo";
import {
  TDetaxBatchInfo,
  TEligibleUserinfo,
} from "../../models/batches.models";
import { getEq } from "fp-ts/Option";
import * as S from "fp-ts/string";
import { TabTypeKind } from "../../components/pages/dashboard/upload-txn/create-batch-tab";

import { fromNullable } from "fp-ts/lib/Option";
import { unWrapOption } from "../../utils/option.utils";

const E = getEq(S.Eq);

type TPage = "DEFAULT" | "ELIGIBLE_USERS" | "SELECTED_ELIGIBLE_USERS";

export interface ITransactionFilter {
  type: DetaxTypeKind;
  status: string;
}

export interface DetaxState {
  detaxType: DetaxTypeKind;
  tabType: TabTypeKind;
  showUploadTransaction: boolean; // open upload transaction component
  filter: ITransactionFilter;
  batchInfo: Option<DetaxBatchInfo>;
  eligibleUsers: TEligibleUserinfo[];
  selectedEligibleUsers: TEligibleUserinfo[];
  showSteps: TPage;
  isAllUserSelected: boolean;
  loading: boolean;
  batches: TDetaxBatchInfo[];
}

export const initialDetaxState: DetaxState = {
  detaxType: DetaxTypeKind.FBA,
  tabType: TabTypeKind.SelectUser,
  showUploadTransaction: false,
  filter: { type: DetaxTypeKind.FBA, status: "CREATED" }, // put ALL when needed
  batchInfo: none,
  eligibleUsers: [],
  selectedEligibleUsers: [],
  showSteps: "DEFAULT",
  loading: true,
  isAllUserSelected: false,
  batches: [],
};

const detaxSlice = createSlice({
  name: "detax",
  initialState: initialDetaxState,
  reducers: {
    initializeApp: () => initialDetaxState,

    setDetaxType: (state, { payload: type }: PayloadAction<DetaxTypeKind>) => {
      state.detaxType = type;
      state.filter.type = type;
      state.filter.status = "ALL";
    },

    setTabType: (state, { payload: type }: PayloadAction<TabTypeKind>) => {
      state.tabType = type;
    },

    showUploadTransaction: (state) => {
      state.showUploadTransaction = true;
      state.showSteps = "ELIGIBLE_USERS";
    },

    hideUploadTransaction: (state) => {
      state.showUploadTransaction = false;
      state.showSteps = "DEFAULT";
    },

    setFilterTypeStatus: (
      state,
      { payload: status }: PayloadAction<string>
    ) => {
      state.filter.status = status;
    },

    setBatchInfo: (
      state,
      { payload: batchInfo }: PayloadAction<Option<DetaxBatchInfo>>
    ) => {
      // console.log("detax-slice-batchinfo",batchInfo)
      state.batchInfo = batchInfo;
    },

    setBatches: (
      state,
      { payload: batches }: PayloadAction<TDetaxBatchInfo[]>
    ) => {
      state.batches = batches;
    },

    selectAllEligibleUsers: (state) => {
      state.isAllUserSelected = true;
      state.eligibleUsers.map((user) => {
        user.isChecked = true;
        return user;
      });

      state.eligibleUsers.forEach((user) => {
        if (
          state.selectedEligibleUsers.find((userS) =>
            E.equals(userS.uid, user.uid)
          )
        ) {
          // do nothing
        } else {
          state.selectedEligibleUsers.push(user);
        }
      });
    },

    unSelectAllEligibleUsers: (state) => {
      state.isAllUserSelected = false;
      state.eligibleUsers.map((user) => {
        user.isChecked = false;
        return user;
      });

      state.eligibleUsers.forEach((user) => {
        if (
          state.selectedEligibleUsers.find((userS) =>
            E.equals(userS.uid, user.uid)
          )
        ) {
          const filter = state.selectedEligibleUsers.filter(
            (u) => !E.equals(user.uid, u.uid)
          );
          state.selectedEligibleUsers = filter;
        } else {
          // do nothing
        }
      });
    },

    addSelectedEligibleUsers: (
      state,
      { payload: user }: PayloadAction<TEligibleUserinfo>
    ) => {
      state.selectedEligibleUsers = [...state.selectedEligibleUsers, user];
    },

    clearSelectedEligibleUsers: (state) => {
      state.selectedEligibleUsers = [];
    },
    // create batch save elibile users in redux
    setEligibleUsers: (
      state,
      { payload: users }: PayloadAction<TEligibleUserinfo[]>
    ) => {
      // is user already selected then mark then checked when new search happend
      users.forEach((user) => {
        if (
          state.selectedEligibleUsers.find((u) => E.equals(u.uid, user.uid))
        ) {
          user.isChecked = true;
        }
      });
      state.eligibleUsers = [...users];
    },

    checkUncheckEligibleUser: (
      state,
      { payload }: PayloadAction<{ check: boolean; item: TEligibleUserinfo }>
    ) => {
      state.eligibleUsers.forEach((user) => {
        if (E.equals(user.uid, payload.item.uid)) {
          user.isChecked = payload.check;
        }
      });

      // if checked then push into selected array
      if (payload.check) {
        state.selectedEligibleUsers.push({ ...payload.item, isChecked: true });
      } else {
        // if unchecked then pop from selected array
        const filter = state.selectedEligibleUsers.filter(
          (user) => !E.equals(user.uid, payload.item.uid)
        );
        state.selectedEligibleUsers = filter;
      }
    },

    setEligibleUserAmount: (
      state,
      {
        payload,
      }: PayloadAction<{ index: number; amt: number; item: TEligibleUserinfo }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = { ...userDetails, amountToAdd: fromNullable(payload.amt) };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },

    setEligibleUserCbo: (
      state,
      {
        payload,
      }: PayloadAction<{ index: number; cbo: string; item: TEligibleUserinfo }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = { ...userDetails, cboCode: fromNullable(payload.cbo) };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },
    setEligibleUserRewardName: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        rewardName: string;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = {
        ...userDetails,
        rewardName: fromNullable(payload.rewardName),
      };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },
    setEligibleUserRewardNameAmount: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        rewardName: string;
        rewardAmount: number;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = {
        ...userDetails,
        rewardName: fromNullable(payload.rewardName),
        rewardAmount: fromNullable(payload.rewardAmount),
      };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },
    setEligibleUserRewardMessage: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        rewardMessage: string;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = {
        ...userDetails,
        rewardMessage: fromNullable(payload.rewardMessage),
      };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },
    setEligibleUserRewardAmount: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        rewardAmount: number;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = {
        ...userDetails,
        rewardAmount: fromNullable(payload.rewardAmount),
      };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },

    setEligibleUserMonth: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        month: string;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = { ...userDetails, month: fromNullable(payload.month) };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },

    setEligibleUserArrear: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        arrear: string;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = {
        ...userDetails,
        arrearRemark: fromNullable(payload.arrear),
      };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },

    setEligibleUserCboArrear: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        cbo: string;
        arrear: string;
        item: TEligibleUserinfo;
      }>
    ) => {
      let userDetails = { ...payload.item };

      userDetails = {
        ...userDetails,
        cboCode: fromNullable(payload.cbo),
        arrearRemark: fromNullable(payload.arrear),
      };

      let userDetailsList = state.eligibleUsers.filter((user) => {
        return (
          unWrapOption(user.uid, () => "") !==
          unWrapOption(payload.item.uid, () => "")
        );
      });

      userDetailsList.splice(payload.index, 0, userDetails);

      state.eligibleUsers = userDetailsList;
    },

    resetIsAllUserSelected: (state) => {
      state.isAllUserSelected = false;
    },

    setSteps: (state, { payload }: PayloadAction<TPage>) => {
      state.showSteps = payload;
    },

    endLoad: (state) => {
      state.loading = false;
    },

    startLoad: (state) => {
      state.loading = true;
    },
  },
});

export namespace DetaxDispatch {
  export const setDetaxType = (payload: DetaxTypeKind) =>
    store.dispatch(detaxSlice.actions.setDetaxType(payload));

  export const setTabType = (payload: TabTypeKind) =>
    store.dispatch(detaxSlice.actions.setTabType(payload));

  export const showUploadTransaction = () =>
    store.dispatch(detaxSlice.actions.showUploadTransaction());

  export const hideUploadTransaction = () =>
    store.dispatch(detaxSlice.actions.hideUploadTransaction());

  export const setFilterTypeStatus = (payload: string) =>
    store.dispatch(detaxSlice.actions.setFilterTypeStatus(payload));

  export const setBatchInfo = (payload: Option<DetaxBatchInfo>) =>
    store.dispatch(detaxSlice.actions.setBatchInfo(payload));

  export const setEligibleUsers = (payload: TEligibleUserinfo[]) =>
    store.dispatch(detaxSlice.actions.setEligibleUsers(payload));

  export const addSelectedEligibleUsers = (payload: TEligibleUserinfo) =>
    store.dispatch(detaxSlice.actions.addSelectedEligibleUsers(payload));

  export const clearSelectedEligibleUsers = () =>
    store.dispatch(detaxSlice.actions.clearSelectedEligibleUsers());

  export const checkedAllEligibleUsers = () =>
    store.dispatch(detaxSlice.actions.selectAllEligibleUsers());

  export const unCheckedAllEligibleUsers = () =>
    store.dispatch(detaxSlice.actions.unSelectAllEligibleUsers());

  export const checkUncheckEligibleUser = (payload: {
    check: boolean;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.checkUncheckEligibleUser(payload));

  export const setEligibleUserAmount = (payload: {
    index: number;
    amt: number;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.setEligibleUserAmount(payload));

  export const setEligibleUserCbo = (payload: {
    index: number;
    cbo: string;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.setEligibleUserCbo(payload));

  export const setEligibleUserArrear = (payload: {
    index: number;
    arrear: string;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.setEligibleUserArrear(payload));

  export const setEligibleUserCboArrear = (payload: {
    index: number;
    cbo: string;
    arrear: string;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.setEligibleUserCboArrear(payload));

  export const setEligibleUserRewardName = (payload: {
    index: number;
    rewardName: string;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.setEligibleUserRewardName(payload));

  export const setEligibleUserRewardNameAmount = (payload: {
    index: number;
    rewardName: string;
    rewardAmount: number;
    item: TEligibleUserinfo;
  }) =>
    store.dispatch(detaxSlice.actions.setEligibleUserRewardNameAmount(payload));

  export const setEligibleUserRewardMessage = (payload: {
    index: number;
    rewardMessage: string;
    item: TEligibleUserinfo;
  }) =>
    store.dispatch(detaxSlice.actions.setEligibleUserRewardMessage(payload));

  export const setEligibleUserRewardAmount = (payload: {
    index: number;
    rewardAmount: number;
    item: TEligibleUserinfo;
  }) => store.dispatch(detaxSlice.actions.setEligibleUserRewardAmount(payload));

  export const resetIsAllUserSelected = () =>
    store.dispatch(detaxSlice.actions.resetIsAllUserSelected());

  export const showPage = (payload: TPage) =>
    store.dispatch(detaxSlice.actions.setSteps(payload));

  export const endLoad = () => store.dispatch(detaxSlice.actions.endLoad());
  export const startLoad = () => store.dispatch(detaxSlice.actions.startLoad());

  export const setBatches = (payload: TDetaxBatchInfo[]) =>
    store.dispatch(detaxSlice.actions.setBatches(payload));
}

export namespace DetaxReduxStore {
  export const selectLoading = (state: State) => state.detax.loading;
  export const selectDetaxType = (state: State) => state.detax.detaxType;
  export const selectTabType = (state: State) => state.detax.tabType;
  export const selectShowUploadTransaction = (state: State) =>
    state.detax.showUploadTransaction;

  export const selectFilter = (state: State) => state.detax.filter;
  export const selectFilterType = (state: State) => state.detax.filter.type;
  export const selectFilterStatus = (state: State) => state.detax.filter.status;
  export const selectBatchInfo = (state: State) => state.detax.batchInfo;
  export const selectEligibleUsers = (state: State) =>
    state.detax.eligibleUsers;
  export const reviewEligibleUsers = (state: State) =>
    state.detax.selectedEligibleUsers;
  export const selectPage = (state: State) => state.detax.showSteps;

  export const isSelectAllUser = (state: State) =>
    state.detax.isAllUserSelected;

  export const selectBatches = (state: State) => state.detax.batches;
}

export default detaxSlice.reducer;
