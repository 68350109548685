import { isSuccess } from "@devexperts/remote-data-ts";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { TEligibleUserinfo } from "../../../../models/batches.models";
import { option } from "fp-ts";

import {
  DetaxDispatch,
  DetaxReduxStore,
} from "../../../../state/slice/detax.slice";
import { unWrapOption } from "../../../../utils/option.utils";

import { getEq } from "fp-ts/Option";
import * as S from "fp-ts/string";

import TableLoader from "../common/table-loader";
import { adminTableHeight } from "../../../../utils/common.utils";
import { fold, isNone, none, Option, some } from "fp-ts/lib/Option";
import { selectedUsers } from "./txn.utils";
import { TabTypeKind } from "./create-batch-tab";
import { DetaxTypeKind } from "../../../../models/detax-type-kind";
import { ITableData, TablePagination } from "../common/table/table-pagination";
import NoTableData from "../common/no-table-data";
import { currencyFormat } from "../../../../utils/format.utils";
import { RewardsService } from "../../../../services/rewards.service";
import { CreateRewardApiResponse } from "../../../../api/detax/model/rewardsApiResponse";

const E = getEq(S.Eq);

const EligibleUserTable: React.FC<{
  search: string;
  selectUser: string;
  selected: TEligibleUserinfo[];
  setSelected: (val: TEligibleUserinfo[]) => void;
  month: string;
}> = ({ selectUser, selected, setSelected, month, search }) => {
  const activeTab = useAppSelector(DetaxReduxStore.selectDetaxType);
  const isAllUserSelected = useAppSelector(DetaxReduxStore.isSelectAllUser);

  const eligibleUsers = useAppSelector(DetaxReduxStore.selectEligibleUsers);
  const loading = useAppSelector(DetaxReduxStore.selectLoading);
  const [contributionType, setContributionType] = useState<string>();
  console.log("select", selected);
  const [paging, setPaging] = useState<ITableData>({
    total: 0,
    page: 1,
    limit: 10,
  });

  const handleUserSelection = (event: any) => {
    // setSelectedUser(fromNullable(JSON.parse(event.target.value)));
    console.log("value", event.target.value);
    setContributionType(event.target.value);
  };

  const handleAllCheck = (checked: boolean) => {
    if (checked) {
      DetaxDispatch.checkedAllEligibleUsers();
    } else {
      DetaxDispatch.unCheckedAllEligibleUsers();
    }
  };

  const handleCheck = (checked: boolean, item: TEligibleUserinfo) => {
    DetaxDispatch.checkUncheckEligibleUser({ check: checked, item });
  };

  const handleAmountChange = (
    index: number,
    amt: number,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserAmount({ index, amt, item });
  };

  const handlecboChange = (
    index: number,
    cbo: string,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserCbo({ index, cbo, item });
  };
  const handlerewardNameChange = (
    index: number,
    rewardName: string,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserRewardName({ index, rewardName, item });
  };

  const handlerewardNameAmountChange = (
    index: number,
    rewardName: string,
    rewardAmount: number,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserRewardNameAmount({
      index,
      rewardName,
      rewardAmount,
      item,
    });
  };
  const handlerewardMessageChange = (
    index: number,
    rewardMessage: string,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserRewardMessage({ index, rewardMessage, item });
  };
  const handlerewardAmountChange = (
    index: number,
    rewardAmount: number,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserRewardAmount({ index, rewardAmount, item });
  };

  const handlearrearChange = (
    index: number,
    arrear: string,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserArrear({ index, arrear, item });
  };
  const handlecboarrearChange = (
    index: number,
    cbo: string,
    arrear: string,
    item: TEligibleUserinfo
  ) => {
    DetaxDispatch.setEligibleUserCboArrear({ index, cbo, arrear, item });
  };
  const [amt, setAmt] = useState(0);
  function onChange(val: any) {
    setAmt(val);
    console.log("amt ", amt);
  }

  // console.log("Eligible", eligibleUsers);
  useEffect(() => {
    if (eligibleUsers) {
      setPaging((p) => ({
        ...p,
        total: eligibleUsers.filter((obj) =>
          unWrapOption(obj.name, () => "")
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ).length,
      }));
      // if (eligibleUsers.filter((obj) => obj.isChecked).length === 0) {
      //   eligibleUsers.map((obj, index) => {
      //     handlecboarrearChange(index, "REGULAR", "ARREARS", obj);
      //   });
      //   console.log("Test-in", eligibleUsers);
      // }
      // console.log("Test-out", eligibleUsers);
    }
  }, [eligibleUsers.length, selectUser, isAllUserSelected, search]);

  const [dropdown, setDropdown] =
    useState<Option<CreateRewardApiResponse[]>>(none);
  // useEffect(() => {
  //   if (selected) setPaging((p) => ({ ...p, total: selected.length, page: 1 }));
  // }, [selected.length]);
  useEffect(() => {
    // let cardStatus = page === RoutePaths.DashboardDetax ? card : curTbl?.status;
    const run = async () => {
      // if (reload) {

      const tblResult = await RewardsService.getRewards();
      if (isSuccess(tblResult)) {
        if (tblResult.value.successful) {
          setDropdown(some(tblResult.value.data || []));
        } else {
          // setErr(true);
        }
      } else {
        // setErr(true);
      }
      //   setReload(false);
      // }
    };
    if (activeTab === "GC") {
      run();
    }
  }, []);
  const rows = (data: TEligibleUserinfo[]) => {
    return data.length > 0
      ? data
          .filter((obj) =>
            unWrapOption(obj.name, () => "")
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
          .map((item, index) => (
            <tr key={index}>
              {activeTab === DetaxTypeKind.NPS && (
                <>
                  <td>{unWrapOption(item.pranNo, () => "")}</td>
                  <td>{unWrapOption(item.name, () => "")}</td>
                  <td>
                    {currencyFormat(unWrapOption(item.npsAmount, () => 0))}
                  </td>
                  <td>
                    <input
                      type="number"
                      readOnly={
                        selected.filter(
                          (obj) =>
                            // console.log("Test==>", obj.month, item.month);
                            unWrapOption(obj.uid, () => "") ===
                              unWrapOption(item.uid, () => "") &&
                            unWrapOption(obj.month, () => "") ===
                              unWrapOption(item.month, () => "")
                        ).length === 1
                          ? true
                          : false
                      }
                      style={
                        (
                          selected.filter(
                            (obj) =>
                              // console.log("Test==>", obj.month, item.month);
                              unWrapOption(obj.uid, () => "") ===
                                unWrapOption(item.uid, () => "") &&
                              unWrapOption(obj.month, () => "") ===
                                unWrapOption(item.month, () => "")
                          ).length === 1
                            ? true
                            : false
                        )
                          ? { backgroundColor: "#f2f2f2", width: 100 }
                          : { width: 100 }
                      }
                      value={unWrapOption(item.amountToAdd, () => 0)}
                      onChange={(e) => {
                        if (e.target.value.length === 2) {
                          if (e.target.value.charAt(0) === "0") {
                            e.target.value = e.target.value.substring(1);
                          }
                        }

                        handleAmountChange(
                          index + (paging.page - 1) * paging.limit,
                          Number(e.target.value),
                          item
                        );
                      }}
                    />
                  </td>
                  <td>
                    <select
                      className=" w-100 py-1 px-1"
                      disabled={
                        selected.filter(
                          (obj) =>
                            // console.log("Test==>", obj.month, item.month);
                            unWrapOption(obj.uid, () => "") ===
                              unWrapOption(item.uid, () => "") &&
                            unWrapOption(obj.month, () => "") ===
                              unWrapOption(item.month, () => "")
                        ).length === 1
                          ? true
                          : false
                      }
                      style={
                        (
                          selected.filter(
                            (obj) =>
                              // console.log("Test==>", obj.month, item.month);
                              unWrapOption(obj.uid, () => "") ===
                                unWrapOption(item.uid, () => "") &&
                              unWrapOption(obj.month, () => "") ===
                                unWrapOption(item.month, () => "")
                          ).length === 1
                            ? true
                            : false
                        )
                          ? { backgroundColor: "#f2f2f2" }
                          : {}
                      }
                      value={unWrapOption(item.cboCode, () => "")}
                      // onChange={handleUserSelection}

                      onChange={(e) => {
                        if (e.target.value.length === 2) {
                          if (e.target.value.charAt(0) === "0") {
                            e.target.value = e.target.value.substring(1);
                          }
                        }
                        handlecboChange(
                          index + (paging.page - 1) * paging.limit,
                          String(e.target.value),
                          item
                        );
                      }}
                    >
                      <option value="REGULAR">Regular</option>
                      <option value="ARREAR">Arrear</option>
                      <option value="MISC">Misc</option>
                      <option value="UNFREEZE TI">Unfreeze TI</option>
                      <option value="UNFREEZE TII">Unfreeze TII</option>
                    </select>
                  </td>
                  <td>
                    <select
                      className=" w-100 py-1 px-1"
                      defaultValue={""}
                      disabled={
                        selected.filter(
                          (obj) =>
                            // console.log("Test==>", obj.month, item.month);
                            unWrapOption(obj.uid, () => "") ===
                              unWrapOption(item.uid, () => "") &&
                            unWrapOption(obj.month, () => "") ===
                              unWrapOption(item.month, () => "")
                        ).length === 1
                          ? true
                          : false ||
                            unWrapOption(item.cboCode, () => "") === "REGULAR"
                      }
                      style={
                        (
                          selected.filter(
                            (obj) =>
                              // console.log("Test==>", obj.month, item.month);
                              unWrapOption(obj.uid, () => "") ===
                                unWrapOption(item.uid, () => "") &&
                              unWrapOption(obj.month, () => "") ===
                                unWrapOption(item.month, () => "")
                          ).length === 1
                            ? true
                            : false
                        )
                          ? { backgroundColor: "#f2f2f2" }
                          : {}
                      }
                      value={unWrapOption(item.arrearRemark, () => "")}
                      // onChange={handleUserSelection}
                      onChange={(e) => {
                        if (e.target.value.length === 2) {
                          if (e.target.value.charAt(0) === "0") {
                            e.target.value = e.target.value.substring(1);
                          }
                        }
                        handlearrearChange(
                          index + (paging.page - 1) * paging.limit,
                          String(e.target.value),
                          item
                        );
                      }}
                    >
                      <option value=""></option>
                      <option value="ARREARS">Arrears</option>
                      <option value="DA ARREARS">DA Arrears</option>
                      <option value="FESTIVAL ADVANCE">Festival Advance</option>
                      <option value="LEAVE ARREARS">Leave Arrears</option>
                      <option value="PAY ADVANCE">Pay Advance</option>
                      <option value="EARNED LEAVE">Earned Leave</option>
                      <option value="INTEREST">Interest</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </td>
                  <td>
                    {currencyFormat(unWrapOption(item.totalCharges, () => 0))}
                  </td>
                  <td>
                    {currencyFormat(
                      unWrapOption(item.totalCharges, () => 0) +
                        unWrapOption(item.amountToAdd, () => 0) +
                        unWrapOption(item.npsAmount, () => 0)
                    )}
                  </td>
                </>
              )}
              {activeTab === DetaxTypeKind.GC && (
                <>
                  <td>{unWrapOption(item.userCode, () => "")}</td>
                  <td>{unWrapOption(item.name, () => "")}</td>
                  <td>{unWrapOption(item.email, () => "")}</td>
                  <td>{unWrapOption(item.mobile, () => "")}</td>

                  <td>
                    <select
                      className=" w-100 py-1 px-1"
                      // disabled={}
                      style={{ width: 500 }}
                      value={unWrapOption(item.rewardName, () => "")}
                      // onChange={handleUserSelection}
                      disabled={item.isChecked}
                      onChange={(e) => {
                        if (e.target.value.length === 2) {
                          if (e.target.value.charAt(0) === "0") {
                            e.target.value = e.target.value.substring(1);
                          }
                        }
                        handlerewardNameAmountChange(
                          index + (paging.page - 1) * paging.limit,
                          String(e.target.value),
                          Number(
                            unWrapOption(dropdown, () => [])
                              .filter(
                                (obj) =>
                                  obj.rewardName === String(e.target.value)
                              )
                              .map((obj) => obj.rewardAmt)
                          ),
                          item
                        );
                      }}
                    >
                      <option value=""></option>
                      {unWrapOption(dropdown, () => []).map((obj) => (
                        <option value={obj.rewardName}>{obj.rewardName}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    ₹{" "}
                    <input
                      type="number"
                      disabled={item.isChecked}
                      readOnly={false}
                      style={{ width: 100 }}
                      value={unWrapOption(item.rewardAmount, () => 0)}
                      onChange={(e) => {
                        if (e.target.value.length === 2) {
                          if (e.target.value.charAt(0) === "0") {
                            e.target.value = e.target.value.substring(1);
                          }
                        }
                        handlerewardAmountChange(
                          index + (paging.page - 1) * paging.limit,
                          Number(e.target.value),
                          item
                        );
                      }}
                    />
                  </td>
                  <td>
                    <textarea
                      readOnly={false}
                      disabled={item.isChecked}
                      value={unWrapOption(item.rewardMessage, () => "")}
                      onChange={(e) => {
                        if (e.target.value.length === 2) {
                          if (e.target.value.charAt(0) === "0") {
                            e.target.value = e.target.value.substring(1);
                          }
                        }
                        if (e.target.value.length <= 120) {
                          handlerewardMessageChange(
                            index + (paging.page - 1) * paging.limit,
                            e.target.value,
                            item
                          );
                        } else {
                          alert(
                            "* The maximum allowed number of characters is 120"
                          );
                        }
                      }}
                    />
                  </td>
                </>
              )}
              {activeTab !== DetaxTypeKind.NPS &&
                activeTab !== DetaxTypeKind.GC && (
                  <>
                    <td>{unWrapOption(item.name, () => "")}</td>
                    <td>{unWrapOption(item.userCode, () => "")}</td>
                    <td>{unWrapOption(item.email, () => "")}</td>
                    <td>{unWrapOption(item.mobile, () => "")}</td>
                  </>
                )}
              {activeTab !== DetaxTypeKind.NPS &&
                activeTab !== DetaxTypeKind.GC && (
                  <>
                    <td>{unWrapOption(item.cardNo, () => "")}</td>
                    {activeTab !== DetaxTypeKind.EA && (
                      <td>{unWrapOption(item.limit, () => 0)}</td>
                    )}
                  </>
                )}
              {activeTab !== DetaxTypeKind.EA ? (
                <>
                  {activeTab !== DetaxTypeKind.NPS &&
                    activeTab !== DetaxTypeKind.GC && (
                      <td>{unWrapOption(item.amountToAdd, () => 0)}</td>
                    )}
                </>
              ) : (
                <td>
                  <input
                    type="number"
                    readOnly={item.isChecked}
                    style={item.isChecked ? { backgroundColor: "#f2f2f2" } : {}}
                    value={unWrapOption(item.amountToAdd, () => 0)}
                    onChange={(e) => {
                      if (e.target.value.length === 2) {
                        if (e.target.value.charAt(0) === "0") {
                          e.target.value = e.target.value.substring(1);
                        }
                      }
                      // console.log(
                      //   "Test index",
                      //   index + (paging.page - 1) * paging.limit
                      // );
                      handleAmountChange(
                        index + (paging.page - 1) * paging.limit,
                        Number(e.target.value),
                        item
                      );
                    }}
                  />
                </td>
              )}
              <td>
                {activeTab !== DetaxTypeKind.NPS &&
                  activeTab !== DetaxTypeKind.GC &&
                  (item.isChecked ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCheck(false, item);
                        // setSelected(
                        //   selected.filter(
                        //     (obj) =>
                        //       obj.isChecked == item.isChecked &&
                        //       obj.uid !== item.uid &&
                        //       obj.month !== item.month &&
                        //       obj.year !== item.year
                        //   )
                        // );
                        setSelected(
                          selected.filter(
                            (obj) =>
                              obj.isChecked == item.isChecked &&
                              obj.uid !== item.uid
                          )
                        );
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCheck(true, item);
                        setSelected([
                          ...selected,
                          { ...item, isChecked: true },
                        ]);
                      }}
                    >
                      Select
                    </button>
                  ))}

                {activeTab === DetaxTypeKind.GC &&
                  (item.isChecked ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCheck(false, item);
                        setSelected(
                          selected.filter((obj) => obj.uid !== item.uid)
                        );
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      disabled={
                        unWrapOption(item.rewardName, () => "").length === 0 ||
                        unWrapOption(item.rewardMessage, () => "").length ===
                          0 ||
                        unWrapOption(item.rewardAmount, () => 0) <= 0
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        handleCheck(true, item);
                        setSelected([
                          ...selected,
                          { ...item, isChecked: true },
                        ]);
                      }}
                    >
                      Select
                    </button>
                  ))}

                {activeTab === DetaxTypeKind.NPS &&
                  (selected.find(
                    (obj) =>
                      // console.log("Test==>", obj.month, item.month);
                      unWrapOption(obj.uid, () => "") ===
                        unWrapOption(item.uid, () => "") &&
                      unWrapOption(obj.month, () => "") ===
                        unWrapOption(item.month, () => "") &&
                      unWrapOption(obj.year, () => "") ===
                        unWrapOption(item.year, () => "")
                  ) ? (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={(e) => {
                        e.preventDefault();

                        handleCheck(false, item);
                        setSelected(
                          selected.filter(
                            (obj) =>
                              // !(obj.isChecked === item.isChecked &&
                              !(
                                unWrapOption(obj.uid, () => "") ===
                                  unWrapOption(item.uid, () => "") &&
                                unWrapOption(obj.month, () => "") ===
                                  unWrapOption(item.month, () => "") &&
                                unWrapOption(obj.year, () => "") ===
                                  unWrapOption(item.year, () => "")
                              )
                          )
                        );
                      }}
                    >
                      Remove
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCheck(true, item);
                        setSelected([
                          ...selected,
                          { ...item, isChecked: true },
                        ]);
                      }}
                    >
                      Select
                    </button>
                  ))}
              </td>
            </tr>
          ))
      : [
          <tr>
            <td
              className="text-secondary"
              colSpan={
                activeTab !== DetaxTypeKind.NPS &&
                activeTab !== DetaxTypeKind.GC
                  ? 8
                  : 7
              }
            >
              <img
                src={process.env.PUBLIC_URL + "/images/No-data-found.png"}
                alt="no data found"
                className="img-fluid"
                height="150"
              />
            </td>
          </tr>,
        ];
  };

  const tblData = (tblData: TEligibleUserinfo[]) => {
    let result = [];
    let startFrom = (paging.page - 1) * paging.limit;
    let endAt = paging.page * paging.limit;
    result = tblData.slice(startFrom, endAt);
    console.log("result ::", result);
    return result;
  };

  // console.log("Info: Active tab", activeTab);

  const isChecked = selectedUsers(eligibleUsers).length;

  if (loading) {
    return <TableLoader />;
  }

  return (
    <>
      {eligibleUsers && eligibleUsers.length > 0 ? (
        <TablePagination
          data={paging}
          changePage={(data) => setPaging((p) => ({ ...p, page: data }))}
          changeLimit={(data) =>
            setPaging((p) => ({ ...p, limit: data, page: 1 }))
          }
        >
          <table>
            <thead>
              <tr>
                {activeTab === DetaxTypeKind.NPS && (
                  <>
                    <th scope="col">PRAN Number</th>
                    <th scope="col">Subscriber Name</th>
                    <th scope="col">Employer Contribution</th>
                    <th scope="col">Subscriber's Contribution</th>
                    <th scope="col">Contribution Type</th>
                    <th scope="col">Arrear Remarks</th>
                    <th scope="col">Total Charges</th>
                    <th scope="col">Total Amount</th>
                  </>
                )}
                {activeTab !== DetaxTypeKind.NPS &&
                  activeTab !== DetaxTypeKind.GC && (
                    <>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Employee Code</th>
                      <th scope="col">E-mail</th>
                      <th scope="col">Mobile</th>
                    </>
                  )}

                {activeTab !== DetaxTypeKind.NPS &&
                  activeTab !== DetaxTypeKind.GC && (
                    <>
                      <th scope="col">Card No.</th>
                      {activeTab !== DetaxTypeKind.EA && (
                        <th scope="col">Card Limit</th>
                      )}
                    </>
                  )}
                {activeTab === DetaxTypeKind.GC && (
                  <>
                    <th scope="col">Employee Code</th>
                    <th scope="col">Employee Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Reward</th>
                    <th scope="col">Reward Amount</th>
                    <th scope="col">Personalized Message</th>
                  </>
                )}
                {activeTab !== DetaxTypeKind.NPS &&
                  activeTab !== DetaxTypeKind.GC && (
                    <th scope="col">Amount To be Added</th>
                  )}
                <th scope="col">
                  {activeTab !== DetaxTypeKind.NPS &&
                    activeTab !== DetaxTypeKind.GC &&
                    (isAllUserSelected ? (
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAllCheck(false);
                          setSelected([]);
                        }}
                      >
                        Remove All
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAllCheck(true);
                          setSelected(
                            eligibleUsers.map((obj) => ({
                              ...obj,
                              isChecked: true,
                            }))
                          );
                        }}
                      >
                        Select All
                      </button>
                    ))}

                  {
                    activeTab === DetaxTypeKind.GC && "Action"
                    // (isAllUserSelected ? (
                    //   <button
                    //     type="button"
                    //     className="btn btn-outline-danger"
                    //     onClick={(e) => {
                    //       e.preventDefault();
                    //       handleAllCheck(false);
                    //       setSelected([]);
                    //     }}
                    //   >
                    //     Remove All
                    //   </button>
                    // ) : (
                    //   <button
                    //     type="button"
                    //     className="btn btn-outline-success"
                    //     onClick={(e) => {
                    //       e.preventDefault();
                    //       handleAllCheck(true);
                    //       setSelected(
                    //         eligibleUsers.map((obj) => ({
                    //           ...obj,
                    //           isChecked: true,
                    //         }))
                    //       );
                    //     }}
                    //   >
                    //     Select All
                    //   </button>
                    // ))
                  }

                  {activeTab === DetaxTypeKind.NPS &&
                    (eligibleUsers.length ===
                    selected.filter(
                      (obj) => unWrapOption(obj.month, () => "") === month
                    ).length ? (
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAllCheck(false);
                          setSelected(
                            selected.filter(
                              (obj) =>
                                unWrapOption(obj.month, () => "") !== month
                            )
                          );
                        }}
                      >
                        Remove All
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAllCheck(true);
                          setSelected([
                            ...selected.filter(
                              (obj) =>
                                unWrapOption(obj.month, () => "") !== month
                            ),
                            ...eligibleUsers.map((obj) => ({
                              ...obj,
                              isChecked: true,
                            })),
                          ]);
                        }}
                      >
                        Select All
                      </button>
                    ))}
                </th>
              </tr>
            </thead>
            <tbody>
              {eligibleUsers &&
                rows(
                  tblData(
                    eligibleUsers.filter((obj) =>
                      unWrapOption(obj.name, () => "")
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    )
                  )
                )}
            </tbody>
          </table>
        </TablePagination>
      ) : (
        <NoTableData />
      )}

      {eligibleUsers && eligibleUsers.length > 0 && (
        <div className="mt-4" style={{ textAlign: "right" }}>
          <button
            className="btn-ncaish-light text-white px-3 py-2"
            onClick={() => DetaxDispatch.showPage("DEFAULT")}
          >
            Cancel
          </button>
          <button
            style={{ marginLeft: "10px" }}
            className="btn-gradiant-blue text-white px-3 py-2"
            onClick={() => DetaxDispatch.setTabType(TabTypeKind.ReviewUser)}
            disabled={
              activeTab === "NPS"
                ? selected.length === 0
                : !isChecked
                ? true
                : false
            }
          >
            Review
          </button>
        </div>
      )}
    </>
  );
};

export default EligibleUserTable;
